<ng-container *ngIf="vm$ | async as vm">
  <div *accentureLoaded="!vm.isLoading" class="template-preview-container">
    <div class="tt9-side-panel-header">
      <h6 class="tt9-h2">Preview</h6>
      <div class="spacer"></div>
      <button
        *ngIf="canEditTemplate"
        class="tt9-btn-icon-filled mr-8"
        [disabled]="isEditPending"
        (click)="editTemplate()"
      >
        <accenture-icon>icon-edit</accenture-icon>
      </button>

      <button
        *ngIf="canEditTemplate"
        class="tt9-btn-icon-filled mr-8"
        matTooltipClass="tt9-arrow-tooltip-above"
        [matTooltip]="tooltipTexts.manageTemplatesTeam"
        (click)="openTeamMembersPanel($event)"
      >
        <accenture-icon>icon-people-group</accenture-icon>
      </button>

      <button class="tt9-btn-icon-filled" (click)="closePanel()">
        <accenture-icon>icon-close</accenture-icon>
      </button>
    </div>

    <div class="template-preview">
      <div class="d-flex-column flex-gap-16">
        <div class="d-flex-column flex-gap-16">
          <div *ngIf="canEditTemplate && hasUnsavedChanges" class="template-preview-badges-bar d-flex flex-gap-8">
            <div class="tt9-badge unsaved">
              <accenture-icon class="mr-4" [size]="iconSize.Small"> icon-alert-triangle </accenture-icon>
              Unsaved changes
            </div>
          </div>

          <div class="d-flex flex-gap-8">
            <div class="tt9-badge" [ngClass]="vm.templateType">
              <ng-container *ngIf="vm.template.type">
                <accenture-icon class="mr-4" [size]="iconSize.Small">
                  {{ vm.template.type | displayActivityIcon : vm.activityConfigurationsMap }}
                </accenture-icon>
                {{ vm.template.type | displayActivityType : vm.activityConfigurationsMap }}
              </ng-container>
              {{ vm.templateType | displayParentType }}
            </div>

            <div class="tt9-caption-2 tt9-color-neutral-500">
              LAST UPDATED:
              {{ getTemplateUpdateDate(vm.template) | date : "MMM dd, yyyy" }}
            </div>
          </div>

          <div class="d-flex-column flex-gap-8">
            <h4 class="tt9-h4-medium">{{ vm.template.name }}</h4>

            <p *ngIf="vm.template.description" class="tt9-body-1">
              {{ vm.template.description }}
            </p>
          </div>
        </div>

        <div class="d-flex">
          <div class="d-flex template-preview-user">
            <div class="tt9-userpic mr-4">
              <img *ngIf="vm.template.creatorImage" [src]="vm.template.creatorImage" [alt]="vm.template.creatorName" />

              <ng-container *ngIf="!vm.template.creatorImage">
                {{ vm.template.creatorName | userInitials }}
              </ng-container>
            </div>

            <span class="tt9-body-medium-2 text-overflow-ellipsis-1">
              {{ vm.template.creatorName }}
            </span>
          </div>

          <div class="template-preview-usage-info">
            <accenture-icon [colorType]="iconColor.Secondary">icon-people-group</accenture-icon>
            <span class="tt9-body-2 tt9-color-neutral-500"
              >Used {{ vm.template.timesUsed || 0 }} time{{ vm.template.timesUsed > 1 ? "s" : "" }}</span
            >
          </div>
        </div>

        <div *ngIf="!!vm.template.imageUrl && !imageError" class="template-preview-image">
          <img [src]="vm.template.imageUrl" [alt]="vm.template.name" (error)="setImageError()" />

          <div *ngIf="vm.template.featured" class="tt9-badge featured template-preview-featured">Featured</div>
        </div>

        <div class="d-flex flex-gap-8">
          <img *ngIf="vm.template.approved" [src]="accentureApproveBtnImgPath" alt="Accenture Approved" />

          <button class="tt9-btn-default btn-icon" (click)="addToFavorites()">
            <accenture-icon>{{ isFavoriteTemplate ? "icon-star" : "icon-star-outline" }}</accenture-icon>
            {{ vm.template.favoritesCount || 0 }}
          </button>
        </div>
      </div>

      <div *ngIf="!isPublicActivityTemplate(vm.templateType)" class="d-flex-column">
        <p class="tt9-categories tt9-color-neutral-500 mb-4">CONTENT</p>
        <p class="tt9-body-1 mb-8">
          {{ getContentLength(vm) }}
        </p>

        <div class="template-preview-content-list">
          <div
            *ngFor="
              let contentItem of isPublicSessionTemplate(vm.templateType) ? vm.activities : vm.sessions;
              index as i;
              trackBy: trackById
            "
            class="template-preview-content-item"
          >
            <div class="content-item-number">{{ i + 1 }}</div>
            <div *ngIf="isPublicSessionTemplate(vm.templateType)" class="content-item-icon">
              <accenture-icon [colorType]="iconColor.Primary">
                {{ contentItem.type | displayActivityIcon : vm.activityConfigurationsMap }}
              </accenture-icon>
            </div>
            <div>
              <div class="content-item-name tt9-body-medium-1">
                {{ contentItem.name }}
              </div>
              <div *ngIf="contentItem.description" class="content-item-description tt9-body-1">
                {{ contentItem.description }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="vm.template.creatorNotes" class="d-flex-column flex-gap-8">
        <p class="tt9-categories tt9-color-neutral-500">CREATOR NOTES</p>
        <p class="tt9-body-1">{{ vm.template.creatorNotes }}</p>
      </div>

      <div *ngIf="(vm.template.tags | keyvalue)?.length" class="d-flex-column flex-gap-8">
        <p class="tt9-categories tt9-color-neutral-500">TAGS</p>
        <mat-chip-listbox hideSingleSelectionIndicator class="tt9-chip">
          <mat-chip-option *ngFor="let tag of vm.template.tags | keyvalue; trackBy: trackById" class="inactive">
            <span matTooltipPosition="above" matTooltipClass="tt9-arrow-tooltip-below-right" [matTooltip]="tag.value">
              {{ tag.value }}
            </span>
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
    </div>

    <div class="template-preview-footer">
      <button class="tt9-btn template-preview-action-btn" (click)="useTemplate()">Use Template</button>
      <button class="tt9-btn template-preview-action-btn" (click)="saveToMyTemplates()">Save To My Templates</button>
    </div>
  </div>
</ng-container>
