import { Timestamp } from 'firebase/firestore';

import { Activity, ActivityType } from './activities';
import { ActivityItem, TableColumn } from './activity-items';
import { Dictionary } from './dictionary';

export const defaultValue = 'defaultValue';

export enum DataFlowType {
    BrainstormToBrainstorm = 'BRAINSTORM_BRAINSTORM',
    BrainstormToVote = 'BRAINSTORM_VOTE',
    BrainstormToTable = 'BRAINSTORM_TABLE',
    VoteToVote = 'VOTE_VOTE',
    VoteToBrainstorm = 'VOTE_BRAINSTORM',
    VoteToTable = 'VOTE_TABLE',
}

export enum FilterProperty {
    All = 'all',
    TopX = 'top_x',
    BottomX = 'bottom_x',
    Bookmarked = 'bookmarked',
    NotBookmarked = 'not_bookmarked',
}

export const filterDefaultAmount = 5;

export type ActivityWithConnections = Activity & {
    isSource?: boolean;
    isSourceBetween?: boolean;
    isBetween?: boolean;
    isDestination?: boolean;
    isSourceUnderDestination?: boolean;
    isMiddle?: boolean;
    isTop?: boolean;
    filterProperty?: FilterProperty;
    connectionDescription?: string;
};

export const displayDataFlowType = {
    [DataFlowType.BrainstormToBrainstorm]: 'Brainstorm To Brainstorm',
    [DataFlowType.BrainstormToVote]: 'Brainstorm To Vote',
};

export interface DataFlowStatus {
    status?: string;
}

export enum DataFlowValidationMessage {
    Responses = 'No responses',
}

export enum FormFieldName {
    Source = 'activities.source',
    Destination = 'activities.destination',
    ActivityName = 'activities.activityName',
    ActivityType = 'activities.activityType',
    SourceSession = 'activities.sourceSessionId',
    DestinationSession = 'activities.destinationSessionId',
    DestinationActivityItem = 'activities.destinationActivityItem',
    TopParameter = 'options.top_parameter',
    BottomParameter = 'options.bottom_parameter',
    Filter = 'options.filter',
    Topics = 'options.topics',
    ContentToMove = 'options.contentToMove',
    TopX = 'options.top_x',
    BottomX = 'options.bottom_x',
    TransformInto = 'options.transformIntoResponses',
    DestinationColumn = 'options.destinationColumn',
}

export const dataFlowActivitiesTypeOptions: { [type: string]: { name: string; value: ActivityType }[] } = {
    [ActivityType.Brainstorm]: [
        {
            name: 'Brainstorm',
            value: ActivityType.Brainstorm,
        },
        {
            name: 'Vote',
            value: ActivityType.Vote,
        },
    ],
    [ActivityType.Vote]: [
        {
            name: 'Brainstorm',
            value: ActivityType.Brainstorm,
        },
        {
            name: 'Vote',
            value: ActivityType.Vote,
        },
    ],

    [defaultValue]: [
        {
            name: 'Brainstorm',
            value: ActivityType.Brainstorm,
        },
        {
            name: 'Vote',
            value: ActivityType.Vote,
        },
    ],
};

export class DataFlowConnection {
    id?: string;
    sourceIds: string[];
    destinationId: string;
    sourceType: ActivityType;
    destinationType: ActivityType;
    destinationName: string;
    destinationActivityItem?: Partial<ActivityItem>; // for table {id, name}
    dataFlowType: DataFlowType;
    sourceSessionId?: string;
    destinationSessionId?: string;
    created: Timestamp; // for sorting
    updated: Timestamp; // for sorting

    // options for source type BRAINSTORM
    moveResponses?: boolean;
    moveComments?: boolean;
    moveTopics?: boolean;
    filterProperty?: FilterProperty; // filter content
    filterValue?: number; // value for 'top_x' | 'bottom_x'

    // options for source type BRAINSTORM and VOTE
    transformToResponses?: boolean; // also default transformation state === Topics
    // for 'Destination Table Activity' - transform to columns - false, transform to column responses = true
    destinationColumn?: Partial<TableColumn>;
    topics?: Dictionary<string[] | string>; // { [activityId]: topics[] | AllTopicsValue }, if all = 'ALL' - AllTopicsValue
    filterParameterByActivityId?: Dictionary<string>; // { [activityId]: filterParameterId } for vote data flow filters

    constructor(data: Partial<DataFlowConnection>) {
        this.id = data.id;
        this.sourceIds = data.sourceIds;
        this.destinationId = data.destinationId;
        this.destinationName = data.destinationName;
        this.destinationActivityItem = data.destinationActivityItem;
        this.sourceType = data.sourceType;
        this.destinationType = data.destinationType;
        this.dataFlowType = `${data.sourceType}_${data.destinationType}` as DataFlowType;
        this.created = data.created;
        this.updated = data.updated;
        this.filterParameterByActivityId = data.filterParameterByActivityId;
        this.destinationColumn = data.destinationColumn;

        this.moveResponses = data.moveResponses ?? undefined;
        this.moveComments = data.moveComments ?? undefined;
        this.moveTopics = data.moveTopics ?? undefined;
        this.filterProperty = data.filterProperty ?? undefined;
        this.filterValue = data.filterValue ?? undefined;
        this.transformToResponses = data.transformToResponses ?? false;
        this.topics = data.topics ?? {};
        this.sourceSessionId = data.sourceSessionId ?? undefined;
        this.destinationSessionId = data.destinationSessionId ?? undefined;
    }

    get description(): string {
        switch (this.dataFlowType) {
            case DataFlowType.BrainstormToBrainstorm: {
                if (this.moveComments && this.moveResponses) {
                    return sourceDataFlowCommentsAndResponsesDescriptionMap[this.filterProperty](this.filterValue);
                }

                if (this.moveResponses) {
                    return sourceDataFlowResponsesDescriptionMap[this.filterProperty](this.filterValue);
                }

                return 'Comments';
            }

            case DataFlowType.BrainstormToVote: {
                if (this.moveResponses) {
                    return sourceDataFlowResponsesDescriptionMap[this.filterProperty](this.filterValue);
                }

                return 'Comments';
            }

            case DataFlowType.VoteToBrainstorm: {
                return voteDataFlowDescriptionMap[this.filterProperty](this.filterValue);
            }

            case DataFlowType.VoteToVote:
            case DataFlowType.VoteToTable: {
                return voteDataFlowDescriptionMap[this.filterProperty](this.filterValue);
            }

            default: {
                return '';
            }
        }
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            id: this.id,
            sourceIds: this.sourceIds,
            destinationId: this.destinationId,
            sourceType: this.sourceType,
            destinationType: this.destinationType,
            destinationName: this.destinationName,
            destinationActivityItem: this.destinationActivityItem,
            created: this.created,
            updated: this.updated,
            moveResponses: this.moveResponses,
            moveComments: this.moveComments,
            moveTopics: this.moveTopics,
            filterProperty: this.filterProperty,
            filterValue: this.filterValue,
            transformToResponses: this.transformToResponses,
            topics: this.topics,
            filterParameterByActivityId: this.filterParameterByActivityId,
            destinationSessionId: this.destinationSessionId,
            sourceSessionId: this.sourceSessionId,
            destinationColumn: this.destinationColumn,
        };
    }
}

export enum DataFlowErrorType {
    NoDataFlowConnections = 'NO_DATA_FLOW',
    NoResponses = 'NO_RESPONSES',
    NoBookmarkedResponses = 'NO_BOOKMARKED_RESPONSES',
    NoNotBookmarkedResponses = 'NO_NOT_BOOKMARKED_RESPONSES',
    NoComments = 'NO_COMMENTS',
    NoLikes = 'NO_LIKES',
    NoTopics = 'NO_TOPICS',
    NoVoteItems = 'NO_VOTE_ITEMS',
    NoBookmarkedVoteItems = 'NO_BOOKMARKED_VOTE_ITEMS',
    NoNotBookmarkedVoteItems = 'NO_NOT_BOOKMARKED_VOTE_ITEMS',
    NoCriteria = 'NO_CRITERIA', // No Parameters of the selected Type in Data destination
    NoVotedParameters = 'NO_VOTED_PARAMETERS',
    ValidateStep = 'VALIDATE_STEP', // table validate
    SummaryStep = 'SUMMARY_STEP', // table summary
}

export const DataFlowErrorMessages = {
    [DataFlowErrorType.NoDataFlowConnections]:
        'No Data Flow Connections are found. Please navigate to Connections to configure Data Flow between Activities!',
    [DataFlowErrorType.NoResponses]: 'There are no responses to carry over to',
    [DataFlowErrorType.NoBookmarkedResponses]: 'There are no bookmarked responses to carry over to',
    [DataFlowErrorType.NoNotBookmarkedResponses]: 'There are no Not bookmarked responses to carry over to',
    [DataFlowErrorType.NoComments]: 'There are no comments to carry over to',
    [DataFlowErrorType.NoLikes]: 'There are no likes to carry over to',
    [DataFlowErrorType.NoTopics]: 'There are no topics to move responses to',
    [DataFlowErrorType.NoVoteItems]: 'There are no ballot items to carry over to',
    [DataFlowErrorType.NoBookmarkedVoteItems]: 'There are no bookmarked ballot items to carry over to',
    [DataFlowErrorType.NoNotBookmarkedVoteItems]: 'There are no Not bookmarked ballot items to carry over to',
    [DataFlowErrorType.NoCriteria]: 'There is no criteria of',
    [DataFlowErrorType.NoVotedParameters]: 'There are no ballot items to carry over to',
    [DataFlowErrorType.ValidateStep]:
        'The destination table is in “Validation” step. Update the table status to “Setup” to be able to move data.',
    [DataFlowErrorType.SummaryStep]:
        'The destination table is in “Summary” step. Update the table status to “Setup” to be able to move data.',
};

export const dataFlowErrorMessage = 'Error while moving data based on Data Flow settings!';
export const dataFlowActivityTitleDialog = 'Get data from prior activity';

export class DataFlowError extends Error {
    type: DataFlowErrorType;

    constructor(type: DataFlowErrorType) {
        super(dataFlowErrorMessage);
        this.type = type;
    }
}

const sourceDataFlowCommentsAndResponsesDescriptionMap = {
    [FilterProperty.All]: () => 'All Responses and Comments',
    [FilterProperty.NotBookmarked]: () => 'Not Bookmarked Responses',
    [FilterProperty.Bookmarked]: () => 'Bookmarked Responses and Comments',
    [FilterProperty.TopX]: (filterValue: number) => `Top ${filterValue} Voted Responses and Comments`,
    [FilterProperty.BottomX]: (filterValue: number) => `Bottom ${filterValue} Voted Responses and Comments`,
};

const sourceDataFlowResponsesDescriptionMap = {
    [FilterProperty.All]: () => 'All Responses',
    [FilterProperty.Bookmarked]: () => 'Bookmarked Responses',
    [FilterProperty.NotBookmarked]: () => 'Not Bookmarked Responses',
    [FilterProperty.TopX]: (filterValue: number) => `Top ${filterValue} Voted Responses`,
    [FilterProperty.BottomX]: (filterValue: number) => `Bottom ${filterValue} Voted Responses`,
};

const voteDataFlowDescriptionMap = {
    [FilterProperty.All]: () => 'All Vote Items',
    [FilterProperty.Bookmarked]: () => 'Bookmarked Vote Items',
    [FilterProperty.NotBookmarked]: () => 'Not Bookmarked Vote Items',
    [FilterProperty.TopX]: (filterValue: number) => `Top ${filterValue} Voted Items`,
    [FilterProperty.BottomX]: (filterValue: number) => `Bottom ${filterValue} Voted Items`,
};

export const dataFlowDescriptionMap = {
    [FilterProperty.All]: () => 'All Items',
    [FilterProperty.Bookmarked]: () => 'Bookmarked Items',
    [FilterProperty.NotBookmarked]: () => 'Not Bookmarked Items',
    [FilterProperty.TopX]: (filterValue: number) => `Top ${filterValue} Items`,
    [FilterProperty.BottomX]: (filterValue: number) => `Bottom ${filterValue} Items`,
};
