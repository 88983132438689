<accenture-base-dialog [title]="data.title">
  <div class="d-flex-column">
    <div class="p-32">
      <p class="tt9-body-2 tt9-color-neutral-400 mb-8">
        Select the date, time and duration you want to hold this session.
      </p>
      <div class="d-flex">
        <div class="left-column">
          <kendo-calendar
            class="tt9-form-field"
            [value]="selectedDate"
            (valueChange)="setSelectedDate($event)"
          ></kendo-calendar>
        </div>
        <div class="right-column">
          <div id="time-picker-entry" class="tt9-form-field time-picker"></div>
        </div>
      </div>
      <div class="d-flex">
        <label>Meeting duration in minutes</label>
        <mat-form-field appearance="outline" class="tt9-form-field duration-input custom-width">
          <input
            matInput
            contenteditable="true"
            type="number"
            min="15"
            step="15"
            [value]="duration"
            [(ngModel)]="duration"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="footer d-flex-end">
      <div class="d-flex">
        <button class="tt9-btn" (click)="bookMeeting()">
          <span>Book Meeting</span>
        </button>
      </div>
    </div>
  </div>
</accenture-base-dialog>
