/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AbstractControl, ValidationErrors } from '@angular/forms';

// Regular expressions for detect and highlight video ID from url
const youtubePattern = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
const vimeoPattern =
    /^.*(vimeo\.com\/|vimeopro\.com\/)(([\w-]+\/[\w-]+\/video\/)|(channels\/[\w-])|(ondemand\/[\w-])|(groups\/[\w-]+\/videos\/)|(album\/[\w-]+\/video\/))?([0-9]+)/;

const videoUrlPattern =
    /* eslint-disable-next-line */
    /^https:\/\/(www.youtube.com|youtu.be|vimeo.com|vimeopro.com|player.vimeo.com){1}([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])?$/;

@Injectable()
export class VideoService {
    constructor(private sanitizer: DomSanitizer) {}

    transformVideoUrlToEmbed(url: string): SafeResourceUrl {
        let transformedUrl: string;
        let match: RegExpMatchArray;

        if (!url) {
            return this.sanitizer.bypassSecurityTrustResourceUrl('');
        }

        const urlType = url.includes('youtu') ? 'youtube' : 'vimeo';

        switch (urlType) {
            case 'youtube':
                match = url.match(youtubePattern);

                transformedUrl = match && match[2] ? `https://www.youtube.com/embed/${match[2]}` : url;
                break;
            case 'vimeo':
                match = url.match(vimeoPattern);

                transformedUrl = match && match[8] ? `https://player.vimeo.com/video/${match[8]}` : url;
                break;
            default:
                transformedUrl = url;
        }

        return this.sanitizer.bypassSecurityTrustResourceUrl(transformedUrl);
    }

    getVideoThumbnailImageUrl(url: string): string {
        let thumbnailUrl: string;
        let match: RegExpMatchArray;

        if (!url) {
            return '';
        }

        const urlType = url.includes('youtu') ? 'youtube' : 'vimeo';

        switch (urlType) {
            case 'youtube':
                match = url.match(youtubePattern);

                thumbnailUrl = match && match[2] ? `https://img.youtube.com/vi/${match[2]}/hqdefault.jpg` : null;
                break;
            case 'vimeo':
                thumbnailUrl = null;
                break;
            default:
                thumbnailUrl = url;
        }

        return thumbnailUrl;
    }

    validateVideoUrl(control: AbstractControl): ValidationErrors | null {
        return videoUrlPattern.test(control.value) ? null : { 'invalid-url': true };
    }

    validateVideoUrlAllowEmpty(control: AbstractControl): ValidationErrors | null {
        if (control.value == '') {
            return null;
        }
        return videoUrlPattern.test(control.value) ? null : { 'invalid-url': true };
    }
}
