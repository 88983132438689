import { Pipe, PipeTransform } from '@angular/core';

import { InAppNotificationType } from '@accenture/shared/data';

const displayNotificationsIcons: { [key in InAppNotificationType as string]: string } = {
    [InAppNotificationType.ProjectInvite]: 'icon-person-add',
    [InAppNotificationType.ProjectRoleChange]: 'icon-presenter',
    [InAppNotificationType.SessionInvite]: 'icon-person-add',
    [InAppNotificationType.SessionBulkInvite]: 'icon-person-add',
    [InAppNotificationType.SessionRoleChange]: 'icon-presenter',
    [InAppNotificationType.ActivityItemStepChange]: 'icon-move',
    [InAppNotificationType.TemplateInvite]: 'icon-person-add',
    [InAppNotificationType.TemplatePublish]: 'icon-person-add',
    [InAppNotificationType.UpdatePublishedTemplate]: 'icon-person-add',
    [InAppNotificationType.VirusDetectedInUpload]: 'icon-warning',
};

@Pipe({
    name: 'displayNotificationIcon',
    standalone: true,
})
export class DisplayNotificationIconPipe implements PipeTransform {
    transform(type: InAppNotificationType): string {
        return displayNotificationsIcons[type];
    }
}
