import { Timestamp } from 'firebase/firestore';

import { NodeTimestamp } from '../node-timestamp';
import { BrainstormResponse } from './brainstorm-response';
import { PollResponse } from './poll-response';
import { PresentResponse } from './present-response';
import { TableResponse } from './table-response';
import { VoteResponse } from './vote-response';

export interface OptionResponseValue {
    id: string;
    value: string | boolean | number;
}

export interface FileTypeResponse {
    [id: string]: FileType;
}

export interface TableRowValue {
    userId: string;
    value: ResponseValue;
}
export interface TableRowResponses {
    [columnId: string]: TableRowValue;
}

export interface ProomptResponses {
    color?: string;
    id: string;
    value: string;
    selected: boolean;
}

export interface TableRowResponsesView {
    [columnId: string]: ResponseValue;
}

export interface FileType {
    id: string;
    name?: string;
    url: string;
    field?: string;
    sequence: string;
}

export type ResponseValue =
    | string
    | string[]
    | boolean
    | number
    | Date
    | OptionResponseValue
    | OptionResponseValue[]
    | Timestamp
    | FileTypeResponse
    | TableRowResponses
    | ProomptResponses[];

export class BaseResponse extends NodeTimestamp {
    id?: string;
    activityId: string;
    activityItemId: string;
    sessionId?: string;
    value: ResponseValue;
    userId?: string; // TODO: change to ownerId?
    userDisplayName?: string;
    userImageUrl?: string;

    constructor(response: Partial<BaseResponse>) {
        super(response);

        this.id = response?.id ?? '';
        this.activityItemId = response?.activityItemId ?? '';
        this.activityId = response?.activityId ?? '';
        this.sessionId = response?.sessionId ?? '';
        this.userId = response?.userId ?? '';
        this.userDisplayName = response?.userDisplayName ?? '';
        this.userImageUrl = response?.userImageUrl ?? '';
        this.value = response?.value ?? '';
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            activityItemId: this.activityItemId,
            activityId: this.activityId,
            sessionId: this.sessionId,
            userId: this.userId,
            userDisplayName: this.userDisplayName,
            userImageUrl: this.userImageUrl,
            value: this.value,
            created: this.created,
            updated: this.updated,
        };
    }
}

export const UserResponse = 'USER_RESPONSE';

export type Response = BrainstormResponse | VoteResponse | PresentResponse | PollResponse | TableResponse;
