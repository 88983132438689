<div class="filter-container" [formGroup]="sortForm">
  <!-- sort by -->
  <div>
    <mat-form-field appearance="outline" floatLabel="always" class="tt9-form-field width-100">
      <mat-label>Sort by</mat-label>
      <mat-select
        formControlName="sortOption"
        ngDefaultControl
        [disableOptionCentering]="true"
        [panelClass]="['tt9-select-panel-custom']"
      >
        <mat-option *ngFor="let option of sortByOptions | keyvalue" [value]="option.key">
          {{ option.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <!-- /sort by -->

  <!-- Sort order -->
  <div>
    <mat-form-field appearance="outline" floatLabel="always" class="tt9-form-field width-100">
      <mat-label>Sort order</mat-label>
      <mat-select
        formControlName="sortOrder"
        ngDefaultControl
        [disableOptionCentering]="true"
        [panelClass]="['tt9-select-panel-custom']"
      >
        <mat-option *ngFor="let option of sortOrderOptions | keyvalue" [value]="option.key">
          {{ option.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <!-- /Sort order -->

  <!-- project optionsData chip list -->
  <div class="chip-list" *ngIf="optionsData?.length">
    <mat-chip-listbox hideSingleSelectionIndicator class="tt9-chip">
      <mat-chip-option
        *ngFor="let option of optionsData; trackBy: trackById"
        [class.selected]="isOptionSelected(option.id)"
        (click)="onSelectOption(option)"
      >
        <span matTooltipPosition="above" matTooltipClass="tt9-arrow-tooltip-below-large" [matTooltip]="option.name">
          {{ option.name | uppercase }}
        </span>
        ({{ getOptionUsageFrequency(option) }})
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
  <!-- /project optionsData chip list -->

  <!-- chips -->
</div>
