import { Injectable } from '@angular/core';
import { Timestamp } from 'firebase/firestore';

import { Activity, DBPathHelper, ParentType } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable({
    providedIn: 'root',
})
export class ActivityTemplateService {
    constructor(private firestoreService: FirestoreService) {
    }

    async markForDelete(
        parentType: ParentType,
        templateId: string
    ): Promise<void> {
        const data: Partial<Activity> = {
            markedForDelete: Timestamp.now()
        }

        await this.updateWithMerge(parentType, templateId, data);
    }

    async updateWithMerge(
        parentType: ParentType,
        templateId: string,
        data: Partial<Activity>,
    ): Promise<void> {
        await this.firestoreService.update(this.getPath(parentType, templateId), {
            ...this.firestoreService.replaceEmptyFields(data),
            updated: this.firestoreService.timestamp,
        });
    }

    private getPath(parentType: ParentType, activityId?: string): string {
        return DBPathHelper.getActivityPath(parentType, null, activityId);
    }
}
