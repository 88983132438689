import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { isNull, omitBy } from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

import { ConfigurationService, EmailService } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUser } from '@accenture/global-store';
import { EmailMessagesBatch, EmailMessageType,User } from '@accenture/shared/data';

import { ContactSupportDialogComponent } from './contact-support-dialog.component';

export interface ContactSupportDialogViewModel {
    contactSupportDialogTitle: string;
    contactSupportDialogSubTitle: string;
    isLoading: boolean;
}

const defaultViewModel: ContactSupportDialogViewModel = {
    contactSupportDialogTitle: '',
    contactSupportDialogSubTitle: '',
    isLoading: true,
};

@Injectable()
export class ContactSupportDialogFacade {
    private isLoading$ = new BehaviorSubject<boolean>(false);
    private user: User;
    private recipientEmail: string;

    vm$ = this.buildViewModel();

    constructor(
        private store: Store<AppState>,
        private dialogRef: MatDialogRef<ContactSupportDialogComponent>,
        private emailMessagesService: EmailService,
        private configurationService: ConfigurationService,
    ) {}

    closeDialog(): void {
        this.dialogRef.close();
    }

    async contactSupport(email: string, feedback: string): Promise<void> {
        this.closeDialog();

        const env = window.location.origin;
        const feedBackData = omitBy(
            {
                firstName: this.user.firstName || null,
                lastName: this.user.lastName || null,
                senderEmail: email || null,
                feedback: feedback || null,
            },
            result => isNull(result),
        ) as Partial<EmailMessagesBatch>;

        this.emailMessagesService.addEmailMessage({
            emails: [this.recipientEmail],
            messageType: EmailMessageType.FeedBack,
            data: {
                ...feedBackData,
                env,
            },
        });
    }

    private buildViewModel(): Observable<ContactSupportDialogViewModel> {
        return this.getUser().pipe(
            switchMap(user => {
                this.user = user;
                return combineLatest([this.configurationService.getSupportConfiguration(), this.isLoading$]);
            }),
            map(([{ contactSupportDialogTitle, contactSupportDialogSubTitle, email }, isLoading]) => {
                this.recipientEmail = email;

                return {
                    contactSupportDialogTitle,
                    contactSupportDialogSubTitle,
                    email,
                    isLoading,
                };
            }),
            startWith(defaultViewModel),
        );
    }

    private getUser(): Observable<User> {
        return this.store.pipe(select(selectAuthenticatedUser));
    }
}
