import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, startWith, tap } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

import {
    ActivityOptionsService,
    FiltersService,
    ProjectOptionsService,
    ProjectService,
    SessionOptionsService,
    TemplateService,
} from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    selectAuthenticatedUserId,
    selectRouterQueryParams,
} from '@accenture/global-store';
import {
    ActivityOptions,
    ActivityOptionsType,
    CollectionsSortObject,
    DefaultCollectionSortObject,
    initialDefaultFilterObject,
    NavigationTab,
    OptionsFiltersFields,
    OptionsForSortingByNumberOfUses,
    ParentType,
    ProjectOptions,
    ProjectOptionsType,
    SessionOptions,
    SessionOptionsType,
    templateOptionsFieldForSorting,
    TemplateTab,
    templateTabValue,
} from '@accenture/shared/data';

export interface DefaultOptionsFiltersChipsViewModel {
    optionsData: ProjectOptionsType[];
    optionType?: string;
    sortOption: CollectionsSortObject;
    selectedOptions: string[];
    parentType: TemplateTab | ParentType;
    isLoading: boolean;
}

export const defaultViewModel = {
    optionsData: [],
    sortOption: {},
    selectedOptions: [],
    parentType: TemplateTab.All,
    isLoading: false,
};

@Injectable()
export class DefaultOptionsFiltersChipsFacade {
    vm$ = this.buildViewModel();

    private userId: string;
    private parentType: ParentType | string;
    private optionType: string;

    constructor(
        private store: Store<AppState>,
        private templateService: TemplateService,
        private projectService: ProjectService,
        private filtersService: FiltersService,
        private projectOptionsService: ProjectOptionsService,
        private sessionOptionsService: SessionOptionsService,
        private activityOptionsService: ActivityOptionsService,
    ) {}

    updateCollectionSortOptions(sortObject: DefaultCollectionSortObject): void {
        if (this.parentType === ParentType.Projects) {
            this.projectService.updateProjectsFilters(this.userId, sortObject);
            return;
        }

        this.templateService.updateTemplatesFilters(this.userId, {
            [this.parentType]: sortObject,
        });
    }

    applyOptionsFilter(selectedOptions: string[], optionId: string, isSelected: boolean): void {
        if (this.parentType === ParentType.Projects) {
            this.projectService.updateOptionsFilters(this.userId, optionId, isSelected, this.optionType);
            return;
        }
        const newData = isSelected
            ? selectedOptions.filter(selectedId => selectedId !== optionId)
            : [...selectedOptions, optionId];

        this.templateService.updateOptionsFilters(this.userId, this.optionType, newData, this.parentType as ParentType);
    }

    private buildViewModel(): Observable<DefaultOptionsFiltersChipsViewModel> {
        return combineLatest([
            this.store.select(selectAuthenticatedUserId),
            this.store.select(selectRouterQueryParams),
            this.filtersService.myTemplatesViewTab$.pipe(distinctUntilChanged()),
            this.filtersService.optionType$.pipe(distinctUntilChanged()),
        ]).pipe(
            switchMap(([userId, routerQuery, templateTab, optionType]) => {
                this.userId = userId;
                this.parentType = this.getParentType(routerQuery.tab, templateTab);
                this.optionType = OptionsFiltersFields[optionType];
                const collection$ = this.getOptionsCollection(
                    optionType as SessionOptions | ProjectOptions | ActivityOptions,
                );

                const filtersAndSelected$
                    = routerQuery.tab === NavigationTab.Templates
                        ? this.getTemplateProjectsFilters()
                        : this.getProjectsFilters();

                return combineLatest([collection$, filtersAndSelected$]).pipe(
                    map(([collection, { sortOption, selectedOptions }]) => {
                        const isProjectsTab = routerQuery.tab === NavigationTab.Projects;
                        const optionForSortingByNumberOfUses = isProjectsTab ? OptionsForSortingByNumberOfUses.Projects : {
                            [TemplateTab.Projects]: templateOptionsFieldForSorting[ParentType.ProjectTemplates],
                            [TemplateTab.Sessions]: templateOptionsFieldForSorting[ParentType.Templates],
                            [TemplateTab.Activities]: templateOptionsFieldForSorting[ParentType.ActivityTemplates],
                        }[templateTab];

                        return {
                            sortOption,
                            optionType,
                            selectedOptions,
                            parentType: isProjectsTab ? ParentType.Projects : templateTab,
                            optionsData: this.filtersService.sortOptionsData(
                                collection,
                                sortOption,
                                optionForSortingByNumberOfUses,
                            ),
                            isLoading: false,
                        };
                    }),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    private getTemplateProjectsFilters(): Observable<{ sortOption: CollectionsSortObject; selectedOptions: string[] }> {
        return this.store.select(selectAuthenticatedUserId).pipe(
            tap(userId => (this.userId = userId)),
            switchMap(userId =>
                this.templateService.getCurrentUserAllTemplatesFilters(userId).pipe(
                    map(allFilters => {
                        const filtersAndSort = allFilters[this.parentType] || initialDefaultFilterObject;
                        return {
                            sortOption: {
                                sortOrder:
                                    filtersAndSort?.collectionsSortOrder
                                    || initialDefaultFilterObject.collectionsSortOrder,
                                sortOption:
                                    filtersAndSort?.collectionsSortOption
                                    || initialDefaultFilterObject.collectionsSortOption,
                            },
                            selectedOptions: filtersAndSort[this.optionType] || [],
                        };
                    }),
                ),
            ),
        );
    }

    private getProjectsFilters(): Observable<{ sortOption: CollectionsSortObject; selectedOptions: string[] }> {
        return this.store.select(selectAuthenticatedUserId).pipe(
            tap(userId => (this.userId = userId)),
            switchMap(userId =>
                this.projectService.getCurrentUserProjectsFilters(userId).pipe(
                    map(filtersAndSort => ({
                        sortOption: {
                            sortOrder: filtersAndSort.collectionsSortOrder,
                            sortOption: filtersAndSort.collectionsSortOption,
                        },
                        selectedOptions: filtersAndSort[this.optionType] || [],
                    })),
                ),
            ),
        );
    }

    private getParentType(routeTab: NavigationTab, templateTab?: TemplateTab): ParentType | string {
        switch (routeTab) {
            case NavigationTab.Projects:
                return ParentType.Projects;
            case NavigationTab.Templates:
                return templateTabValue[templateTab];
        }
    }

    private getOptionsCollection(
        optionType: SessionOptions | ProjectOptions | ActivityOptions,
    ): Observable<SessionOptionsType[]> | Observable<ProjectOptionsType[]> | Observable<ActivityOptionsType[]> {
        switch (this.parentType) {
            case ParentType.Templates:
                return this.sessionOptionsService.getOptions(optionType as SessionOptions);
            case ParentType.ActivityTemplates:
                return this.activityOptionsService.getOptions(ParentType.Activities);
            default: // project templates, projects
                return this.projectOptionsService.getOptions(optionType as ProjectOptions);
        }
    }
}
