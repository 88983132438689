<div class="dialog-container">
  <div
    *ngIf="headerVisible"
    class="accenture-dialog-header"
    [class.with-description]="!!titleDescription"
    [class.no-border]="!!noBorder"
  >
    <div class="header-text-wrapper">
      <p class="tt9-h2 text-overflow-ellipsis-1">{{ title }}</p>
      <div *ngIf="titleDescription" class="tt9-body-medium-2 tt9-color-neutral-500 text-overflow-ellipsis-1">
        <p>{{ titleDescription }}</p>
      </div>
      <p *ngIf="subTitle" class="tt9-color-neutral-500 tt9-body-medium-2">
        {{ subTitle }}
      </p>
    </div>

    <button *ngIf="closeButtonVisible" class="tt9-btn-icon-filled" (click)="closeDialog()">
      <accenture-icon>icon-close</accenture-icon>
    </button>
  </div>

  <ng-content></ng-content>
</div>
