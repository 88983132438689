<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading">
    <accenture-base-dialog [title]="getTitle(vm.currentOptionToDisplay)" [subTitle]="subTitle">
      <div class="content-container">
        <div class="session-creator-header" [formGroup]="sessionForm">
          <div *ngIf="!vm.currentOptionToDisplay" class="session-info-container mb-24">
            <div class="session-image mr-8 ml-12">
              <accenture-image-input
                [mode]="imageInputMode.Default"
                [imagePlaceholder]="imageInputPlaceholder"
                [file]="vm.sessionImage"
                (fileUploaded)="setSessionImage($event)"
                (fileDeleted)="deleteSessionImage()"
              ></accenture-image-input>
            </div>

            <div class="session-name mb-24">
              <mat-form-field appearance="outline" floatLabel="always" class="tt9-form-field no-margin width-100">
                <mat-label>Session name</mat-label>
                <input
                  #sessionName
                  matInput
                  required
                  formControlName="name"
                  placeholder="Name*"
                  [maxlength]="characterLimitMedium"
                />
                <mat-hint
                  class="mat-hint-limit"
                  [class]="determineHintClass(sessionName.value.length, characterLimitMedium)"
                >
                  {{ characterLimitText(sessionName.value.length, characterLimitMedium) }}
                </mat-hint>
                <mat-error>Name is required</mat-error>
              </mat-form-field>
            </div>

            <div class="session-description">
              <mat-form-field appearance="outline" floatLabel="always" class="tt9-form-field no-margin width-100">
                <input
                  #sessionDescription
                  matInput
                  formControlName="description"
                  placeholder="Description"
                  [maxlength]="descriptionCharacterLimit"
                />
                <mat-hint
                  class="mat-hint-limit"
                  [class]="determineHintClass(sessionDescription.value.length, descriptionCharacterLimit)"
                >
                  {{ characterLimitText(sessionDescription.value.length, descriptionCharacterLimit) }}
                </mat-hint>
              </mat-form-field>
            </div>
          </div>

          <div *ngIf="!vm.currentOptionToDisplay" class="session-dates">
            <!-- start date -->
            <mat-form-field appearance="outline" floatLabel="auto" class="tt9-form-field date-form-field">
              <mat-label>Start date</mat-label>
              <input
                matInput
                readonly
                formControlName="startDate"
                [placeholder]="inputPlaceholders.startDate"
                [matDatepicker]="datePickerFrom"
                (click)="datePickerFrom.open()"
              />
              <mat-datepicker-toggle matSuffix [for]="datePickerFrom">
                <accenture-icon matDatepickerToggleIcon> icon-calendar </accenture-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #datePickerFrom></mat-datepicker>
              <accenture-icon
                *ngIf="sessionStartDateControl?.value"
                matSuffix
                class="mat-select-icon date-reset"
                (click)="clearDateInput('startDate', $event)"
              >
                icon-close
              </accenture-icon>
            </mat-form-field>
            <!-- /start date -->

            <!-- end date -->
            <mat-form-field appearance="outline" floatLabel="auto" class="tt9-form-field date-form-field">
              <mat-label>End date</mat-label>
              <input
                matInput
                readonly
                formControlName="endDate"
                [placeholder]="inputPlaceholders.endDate"
                [matDatepicker]="datePickerTo"
                (click)="datePickerTo.open()"
              />
              <mat-datepicker-toggle matSuffix [for]="datePickerTo">
                <accenture-icon matDatepickerToggleIcon> icon-calendar </accenture-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #datePickerTo></mat-datepicker>
              <accenture-icon
                *ngIf="sessionEndDateControl?.value"
                matSuffix
                class="mat-select-icon date-reset"
                (click)="clearDateInput('endDate', $event)"
              >
                icon-close
              </accenture-icon>
              <mat-error *ngIf="isEndDateGreaterStartDateErrorVisibility">{{
                validationMessages.endDateGreaterStartDate
              }}</mat-error>
            </mat-form-field>
            <!-- /end date -->
          </div>

          <accenture-session-options></accenture-session-options>
        </div>

        <div class="footer d-flex-end">
          <button class="tt9-btn-default btn-icon" (click)="openUseTemplateModal(vm.currentOptionToDisplay)">
            <accenture-icon>icon-chevron-left-small</accenture-icon>
            <span>Back</span>
          </button>

          <button
            *ngIf="!vm.currentOptionToDisplay"
            class="tt9-btn ml-16"
            [disabled]="sessionForm.get('name')?.invalid"
            (click)="createSession()"
          >
            Save
          </button>
        </div>
      </div>
    </accenture-base-dialog>
  </ng-container>
</ng-container>
