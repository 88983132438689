import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SHA256 } from 'crypto-js';
import { combineLatest, firstValueFrom, Observable, of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

import { ActivityService } from '@accenture/activity/shared/domain';
import { AIGenerateService, LinkAccessService } from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    selectActivityConfigurations,
    selectActivityConfigurationsMap,
    selectActivityId,
    selectActivityIdAndParentIds,
    selectAuthenticatedUser,
    selectFeatureToggle,
    selectProjectAttributeClasses,
    selectSessionId,
    selectSessionTeamMemberData,
    selectSessionVisibleActivitiesData,
} from '@accenture/global-store';
import {
    Activity,
    ActivityConfiguration,
    ActivityConfigurationsMap,
    ActivityType,
    activityTypesWithAttributes,
    aiActivityGenerationErrorTitle,
    aiActivityGenerationInProgressMessage,
    aiActivityGenerationInProgressTitle,
    aiActivityGenerationSuccessMessage,
    aiActivityGenerationSuccessTitle,
    aiGenerationErrorStatus,
    aiGenerationInProgressStatus,
    aiGenerationSuccessStatus,
    AttributeClass,
    BotActions,
    Dictionary,
    errorSnackbarText,
    FeatureToggleName,
    generateRandomPassword,
    inputPlaceholders,
    ParentType,
} from '@accenture/shared/data';
import { DialogService, SnackbarService } from '@accenture/shared/ui';
import { SequenceGeneration } from '@accenture/shared/util';

import { AiPromptEntryDialogComponent } from '../dialogs';
import { AddActivityTemplatesDialogComponent } from '../dialogs/add-activity-templates-dialog/add-activity-templates-dialog.component';
import { SequenceData } from '../models/sequence-data';

export interface SessionActivityCreatorManagerModel {
    activityConfigurations: ActivityConfiguration[];
    activities: Activity[];
    parentType: ParentType;
    isActivityCreateFromPanelVisible: boolean;
    available: boolean;
}

const defaultModel: SessionActivityCreatorManagerModel = {
    activityConfigurations: [],
    activities: [],
    parentType: {} as ParentType,
    isActivityCreateFromPanelVisible: false,
    available: false,
};

@Injectable()
export class SessionActivityCreatorManagerFacade {
    userId?: string;
    projectId?: string;
    isParentTemplateType?: boolean;
    sessionId?: string;
    activityId?: string;
    vm$ = this.buildViewModel();

    constructor(
        private aiGenerateService: AIGenerateService,
        private store: Store<AppState>,
        private dialogService: DialogService,
        private activityService: ActivityService,
        private snackbarService: SnackbarService,
        private linkAccessService: LinkAccessService,
    ) {}

    async createActivity(activityType: ActivityType, activities: Activity[], sequence?: SequenceData): Promise<string> {
        return await this.addNewActivity(activityType, activities, sequence);
    }

    openCreateActivityByTemplateDialog(
        activities: Activity[],
        completeCallback: () => void,
        sequenceData?: SequenceData,
    ): void {
        this.dialogService.open(AddActivityTemplatesDialogComponent, {
            width: '768px',
            commonInsertion: true,
            panelClass: 'tt9-modal',
            sequenceData,
            activities,
            completeCallback,
        });
    }

    openGenerateByAiDialog(): void {
        this.dialogService.open(AiPromptEntryDialogComponent, {
            placeholder: inputPlaceholders.enterGptPromptActivity,
            header: inputPlaceholders.headerActivity,
            title: 'Generate activity using AI',
            width: '768px',
            panelClass: 'tt9-modal',
            confirm: 'Generate',
            onAccept: (prompt) => this.generate(prompt),
            isGenerateActivity: true,
        });
    }

    private async generate(prompt: string): Promise<void> {
        try {
            this.snackbarService.showInfoSnackBar(
                this.getSnackbarTitleForActivity(aiGenerationInProgressStatus),
                this.getSnackbarMessageForActivity(aiGenerationInProgressStatus, null),
                false,
            );
            const { userId, sessionId, activityId } = this;

            const result = await this.aiGenerateService.aiGenerateActivityData(
                prompt,
                BotActions.CreateActivity,
                sessionId,
                activityId,
                userId,
            );

            if (result && result.notification) {
                const { count } = result;

                this.snackbarService.showSuccessSnackBar(
                    this.getSnackbarTitleForActivity(aiGenerationSuccessStatus),
                    this.getSnackbarMessageForActivity(aiGenerationSuccessStatus, count),
                );
            } else {
                this.snackbarService.showErrorSnackBar(
                    this.getSnackbarTitleForActivity(aiGenerationErrorStatus),
                    this.getSnackbarMessageForActivity(aiGenerationErrorStatus, null),
                );
            }
        } catch (error) {
            console.error('Unable to generate activity', error);
            this.snackbarService.showErrorSnackBar(
                this.getSnackbarTitleForActivity(aiGenerationErrorStatus),
                this.getSnackbarMessageForActivity(aiGenerationErrorStatus, null),
            );
        }
    }

    private buildViewModel(): Observable<SessionActivityCreatorManagerModel> {
        return combineLatest({
            activityConfigurations: this.store.select(selectActivityConfigurations),
            activities: this.store.select(selectSessionVisibleActivitiesData),
            parentType: firstValueFrom(
                this.store
                    .select(selectActivityIdAndParentIds)
                    .pipe(map((activityIdAndParentIds) => activityIdAndParentIds.parentType)),
            ),
            enabled: this.store.select(selectFeatureToggle(FeatureToggleName.AiGenerateAvailable)),
            user: this.store.select(selectAuthenticatedUser),
            sessionAccess: this.store.select(selectSessionTeamMemberData),
            sessionId: this.store.select(selectSessionId),
            activityId: this.store.select(selectActivityId),
        }).pipe(
            switchMap(
                ({
                    activityConfigurations,
                    activities,
                    parentType,
                    enabled,
                    user,
                    sessionAccess,
                    sessionId,
                    activityId,
                }) => {
                    const templateParentTypes = [
                        ParentType.PublicActivityTemplates,
                        ParentType.PublicSessionTemplates,
                        ParentType.Templates,
                    ];

                    const isParentTemplateType = templateParentTypes.includes(parentType);

                    this.userId = user.id;

                    const { isSessionLeader } = sessionAccess;

                    this.sessionId = sessionId;
                    this.activityId = activityId;
                    this.isParentTemplateType = isParentTemplateType;

                    // TODO: Confirm Generate by AI role availability (replacement for Project Admin)
                    return of({
                        activityConfigurations,
                        activities,
                        parentType,
                        isActivityCreateFromPanelVisible: !isParentTemplateType,
                        available: enabled && isSessionLeader,
                    });
                },
            ),
            startWith(defaultModel),
        );
    }

    private async addNewActivity(
        activityType: ActivityType,
        activities: Activity[],
        sequenceData?: SequenceData,
    ): Promise<string> {
        const activityIdAndParentIds = await firstValueFrom(this.store.select(selectActivityIdAndParentIds));

        // TODO: Repoint source of attributes from projects
        const attributeClasses = await this.getProjectAttributesClasses(activityIdAndParentIds.parentType);
        const activityConfigurationsMap = await firstValueFrom<ActivityConfigurationsMap>(
            this.store.select(selectActivityConfigurationsMap),
        );

        const configuration = activityConfigurationsMap[activityType];
        const name = this.activityService.createActivityNameWithCounter(configuration.defaultOptions.name, activities);
        const sequence = this.getActivitySequence(activities, sequenceData);
        const useAttributes
            = ParentType.Projects === activityIdAndParentIds.parentType
            && activityTypesWithAttributes.includes(activityType)
            && !!attributeClasses?.length;

        const newActivity = {
            ...configuration.defaultOptions,
            name,
            sequence,
            useAttributes,
            type: activityType,
            sessionId: activityIdAndParentIds.sessionId || activityIdAndParentIds.templateId,
            selectedAttributes: useAttributes ? this.getSelectedAttributes(attributeClasses) : {},
        } as unknown as Activity;

        const activityId = await this.activityService.createActivity(
            activityIdAndParentIds.parentType,
            activityIdAndParentIds.parentType === ParentType.Sessions
                ? activityIdAndParentIds.sessionId
                : activityIdAndParentIds.parentId,
            activities,
            newActivity,
        );

        if (!this.isParentTemplateType) {
            await this.createLinkAccess(activityId, newActivity);
        }

        return activityId;
    }

    private async getProjectAttributesClasses(parentType: ParentType): Promise<AttributeClass[]> {
        const projectParentTypes = [
            ParentType.Projects,
            ParentType.ProjectTemplates,
            ParentType.PublicProjectTemplates,
        ];

        return projectParentTypes.includes(parentType)
            ? await firstValueFrom(this.store.select(selectProjectAttributeClasses))
            : [];
    }

    private getSelectedAttributes(attributeClasses: AttributeClass[]): Dictionary<string> {
        return attributeClasses.reduce((acc, attributeClass) => {
            return attributeClass.id ? { ...acc, [attributeClass.id]: true } : acc;
        }, {});
    }

    getActivitySequence(activities: Activity[], sequenceData?: SequenceData): string {
        if (activities.length === 0) {
            return SequenceGeneration.initial();
        }

        if (!sequenceData) {
            return SequenceGeneration.afterLast(activities[activities.length - 1].sequence);
        }

        const previousSequence = sequenceData.previousSequence ?? activities[activities.length - 1].sequence;
        if (previousSequence !== '') {
            return sequenceData.nextSequence
                ? SequenceGeneration.between(previousSequence, sequenceData.nextSequence)
                : SequenceGeneration.afterLast(previousSequence);
        }

        return SequenceGeneration.initial();
    }

    private getSnackbarTitleForActivity(status: string): string {
        switch (status) {
            case aiGenerationInProgressStatus:
                return aiActivityGenerationInProgressTitle;
            case aiGenerationSuccessStatus:
                return aiActivityGenerationSuccessTitle;
            case aiGenerationErrorStatus:
                return aiActivityGenerationErrorTitle;
            default:
                return '';
        }
    }

    private getSnackbarMessageForActivity(status: string, count: number): string {
        switch (status) {
            case aiGenerationInProgressStatus:
                return aiActivityGenerationInProgressMessage;
            case aiGenerationSuccessStatus:
                if (count && count > 1) {
                    return `${count} activities have successfully been created by AI`;
                } else {
                    return aiActivityGenerationSuccessMessage;
                }
            case aiGenerationErrorStatus:
                return errorSnackbarText;
            default:
                return '';
        }
    }

    private async createLinkAccess(activityId: string, activity: Activity): Promise<string> {
        const { name, type } = activity;
        const randomPassword = generateRandomPassword();

        const newLinkAccess = {
            activityId: activityId,
            activityName: name,
            activityType: type,
            activityVisible: true,
            password: SHA256(randomPassword).toString(),
            sessionId: this.sessionId,
        };
        const linkAccessId = await this.linkAccessService.addLinkAccess(newLinkAccess);

        await this.saveOriginalLinkPassword(linkAccessId, randomPassword);
        return linkAccessId;
    }

    private async saveOriginalLinkPassword(linkAccessId: string, newPassword: string): Promise<void> {
        // TODO: Update link access reference to projectId
        await this.linkAccessService.updateLinkAccessOriginalPasswordNew(this.sessionId, linkAccessId, newPassword);
    }
}
