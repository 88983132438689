import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, startWith, switchMap } from 'rxjs';

import { CollectionOptionsService, FiltersService, OptionsStore } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUserId, selectParentType } from '@accenture/global-store';
import {
    CollectionOptions,
    CollectionOptionsType,
    CollectionsSortObject,
    Dictionary,
    OptionsForSortingByNumberOfUses,
    ProjectOptions,
    SelectedCollectionOptions,
    SessionOptions,
} from '@accenture/shared/data';

export interface CollectionOptionsViewModel {
    selectedCollectionOptions: SelectedCollectionOptions;
    tags: CollectionOptionsType[];
    currentOptionToDisplay: CollectionOptions | ProjectOptions | SessionOptions | null;
    sortCollectionsData: CollectionsSortObject;
    isLoading: boolean;
}

const defaultCollectionOptionsModel = {
    selectedCollectionOptions: {} as SelectedCollectionOptions,
    tags: [],
    sortCollectionsData: {} as CollectionsSortObject,
    currentOptionToDisplay: null,
    isLoading: true,
};

@Injectable()
export class CollectionOptionsFacade {
    vm$ = this.buildViewModel();

    private userId!: string;
    private currentOptionToDisplay: CollectionOptions;
    // all options from db
    private collectionOptionsValues: { [name: string]: CollectionOptionsType[] } = {
        tags: [],
    };
    private collectionOptionsToCreateIds: { [name: string]: string[] } = {
        tags: [],
    };

    constructor(
        private store: Store<AppState>,
        private collectionOptionsService: CollectionOptionsService,
        private optionsStore: OptionsStore,
        private filtersService: FiltersService,
    ) {}

    showOptionChips(optionType: CollectionOptions): void {
        this.optionsStore.setCurrentOptionToDisplay(optionType);
    }

    updateCollectionOptions(option: Dictionary<string>, currentType?: CollectionOptions): void {
        const optionType = currentType || this.currentOptionToDisplay;
        const optionId = Object.keys(option)[0];

        this.optionsStore.setSelectedCollectionOption({ option, optionType });

        if (
            this.collectionOptionsToCreateIds[optionType]
            && this.collectionOptionsToCreateIds[optionType].includes(optionId)
        ) {
            this.optionsStore.removeCollectionOptionToCreate({ optionId, optionType });
        }
    }

    updateCollectionOptionsToCreate(option: CollectionOptionsType, optionType?: CollectionOptions): void {
        const currentOptionToDisplay = optionType || this.currentOptionToDisplay;
        const data = {
            option,
            optionType: currentOptionToDisplay,
        };
        this.optionsStore.setCollectionOptionToCreate(data);
    }

    getFilteredOptions(
        collectionOption: CollectionOptions,
        selectedOptions: Dictionary<string>,
        value: string,
    ): { filteredOptions: CollectionOptionsType[]; isOptionExist: boolean } {
        let isOptionExist = false;
        const filteredOptions = this.collectionOptionsValues[collectionOption].filter((option) => {
            const optionName = option.name?.toLowerCase();

            if (optionName === value) {
                isOptionExist = true;
            }

            return optionName?.includes(value) && !Object.keys(selectedOptions || {})?.includes(option.id);
        });

        return {
            isOptionExist,
            filteredOptions,
        };
    }

    async addNewOption(optionType: CollectionOptions, optionName: string): Promise<void> {
        const id = this.collectionOptionsService.getNewOptionId();
        const newOption = {
            [id]: optionName,
        };

        const optionToCreate = {
            id,
            name: optionName,
        } as CollectionOptionsType;

        this.updateCollectionOptions(newOption, optionType);
        this.updateCollectionOptionsToCreate(optionToCreate, optionType);
    }

    async updateFilters(filters: CollectionsSortObject): Promise<void> {
        await this.collectionOptionsService.updateCollectionsSorting(this.userId, filters);
    }

    private sortCollectionOptions(
        options: CollectionOptionsType[],
        sortOptions: CollectionsSortObject,
    ): CollectionOptionsType[] {
        return this.filtersService.sortOptionsData(options, sortOptions, OptionsForSortingByNumberOfUses.Collections);
    }

    private buildViewModel(): Observable<CollectionOptionsViewModel> {
        return this.store.select(selectAuthenticatedUserId).pipe(
            switchMap((userId) => {
                this.userId = userId;

                return combineLatest([
                    this.optionsStore.selectedCollectionOptions$,
                    this.collectionOptionsService.getOptions(CollectionOptions.Tag),
                    this.optionsStore.currentOptionToDisplay$,
                    this.optionsStore.collectionOptionsToCreateIds$,
                    this.optionsStore.collectionOptionsToCreate$,
                    this.collectionOptionsService.getCurrentUserCollectionsSorting(userId),
                ]).pipe(
                    map(
                        ([
                            selectedCollectionOptions,
                            tags,
                            currentOptionToDisplay,
                            collectionOptionsToCreateIds,
                            collectionOptionsToCreate,
                            sortCollectionsData,
                        ]) => {
                            this.currentOptionToDisplay = currentOptionToDisplay as CollectionOptions;
                            this.collectionOptionsToCreateIds = collectionOptionsToCreateIds;
                            this.collectionOptionsValues = {
                                tags: [...tags, ...(collectionOptionsToCreate.tags || [])],
                            };

                            let options = { ...this.collectionOptionsValues };

                            if (currentOptionToDisplay) {
                                options = this.sortDataToDisplay(sortCollectionsData, options.tags);
                            }

                            return {
                                sortCollectionsData,
                                currentOptionToDisplay,
                                selectedCollectionOptions,
                                tags: options.tags,
                                isLoading: false,
                            };
                        },
                    ),
                );
            }),
            startWith(defaultCollectionOptionsModel),
        );
    }

    private sortDataToDisplay(
        sortCollectionsData: CollectionsSortObject,
        tags: CollectionOptionsType[],
    ): Dictionary<CollectionOptionsType[]> {
        return {
            tags: this.sortCollectionOptions(tags, sortCollectionsData) || [],
        };
    }
}
