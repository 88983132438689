import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { inputPlaceholders } from '@accenture/activity/shared/domain';
import {
    Dictionary,
    emptyScreenImageUrlTemplates,
    emptyScreenTitles,
    noItemsFoundImageUrl,
    UserAccess,
} from '@accenture/shared/data';
import { isDisabledTooltip, trackById } from '@accenture/shared/util';

@Component({
    selector: 'accenture-add-session-table',
    templateUrl: './add-session-table.component.html',
    styleUrls: ['./add-session-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddSessionTableComponent implements OnChanges, OnDestroy {
    @Input() templates: UserAccess[];
    @Input() selectedTemplatesMap: Dictionary<boolean> = {};

    @Output() searchValue = new EventEmitter<string>();
    @Output() selectedTemplates = new EventEmitter<Dictionary<boolean>>();

    isAllTemplatesSelected = false;
    searchControl = new FormControl<string>('', { nonNullable: true });
    trackById = trackById;
    inputPlaceholders = inputPlaceholders;
    emptyScreenTitles = emptyScreenTitles;
    emptyScreenImageUrlTemplates = emptyScreenImageUrlTemplates;
    noItemsFoundImageUrl = noItemsFoundImageUrl;
    isDisabledTooltip = isDisabledTooltip;

    emitSearchValue(value: string): void {
        const searchValue = value.trim().toLowerCase();

        this.searchControl.patchValue(searchValue);
        this.searchValue.emit(searchValue);
    }

    resetSearchValue(): void {
        this.searchControl.patchValue('');
        this.emitSearchValue('');
    }

    toggleTemplatesSelection(template: UserAccess): void {
        const checked = !this.selectedTemplatesMap[template.templateId];

        this.selectedTemplates.emit({ [template.templateId]: checked });
    }

    toggleAllTemplatesSelection({ checked }: { checked: boolean }): void {
        const selectedTemplatesMap: Dictionary<boolean> = {};

        this.templates.forEach((template) => (selectedTemplatesMap[template.templateId] = checked));
        this.selectedTemplates.emit(selectedTemplatesMap);
    }

    ngOnChanges(): void {
        this.updateAllTemplatesSelectedStatus();
    }

    ngOnDestroy(): void {
        this.emitSearchValue('');
    }

    private updateAllTemplatesSelectedStatus(): void {
        this.isAllTemplatesSelected = !this.templates.filter(
            (template) => !this.selectedTemplatesMap[template.templateId],
        ).length;
    }
}
