import { Injectable } from '@angular/core';
import { DocumentReference } from '@angular/fire/compat/firestore';
import { FieldValue } from 'firebase/firestore';
import { map, Observable } from 'rxjs';

import {
    ActivityGroup,
    BrainstormGroupingType,
    DBPathHelper,
    Dictionary,
    getRandomGroupColor,
    ParentType,
    SessionStatus,
} from '@accenture/shared/data';
import { FanOutWrite, FirestoreService } from '@accenture/shared/data-access';
import { sortBySequenceAsc } from '@accenture/shared/util';

export interface GroupResponseCountModel {
    numberOfResponses: { [key: string]: FieldValue };
}

@Injectable({
    providedIn: 'root',
})
export class BrainstormGroupsService {
    constructor(private firestoreService: FirestoreService) {}

    getTemplateBrainstormGroups(templateId: string, activityId: string): Observable<ActivityGroup[]> {
        return this.firestoreService
            .getDocumentsByProperty<ActivityGroup>(
                DBPathHelper.getBrainstormGroupPath(ParentType.Templates, templateId),
                'activityId',
                activityId,
            )
            .pipe(
                map((items: ActivityGroup[]) =>
                    sortBySequenceAsc<ActivityGroup>(items as (ActivityGroup & { sequence: string })[]).map(
                        (item) => new ActivityGroup(item),
                    ),
                ),
            );
    }

    getBrainstormGroups(parentType: ParentType, parentId: string, activityId: string): Observable<ActivityGroup[]> {
        const hasParent = ![ParentType.ActivityTemplates, ParentType.PublicActivityTemplates].includes(parentType);
        return this.firestoreService
            .getDocumentsByMultipleProperties<ActivityGroup>(
                DBPathHelper.getBrainstormGroupPath(parentType, parentId),
                hasParent ? new Map([['activityId', activityId]]) : new Map(),
            )
            .pipe(
                map((items: ActivityGroup[]) =>
                    sortBySequenceAsc<ActivityGroup>(items as (ActivityGroup & { sequence: string })[]).map(
                        (item) => new ActivityGroup(item),
                    ),
                ),
            );
    }

    async addBrainstormGroup(
        parentType: ParentType,
        parentId: string,
        { activityId, activityItemId, sequence, name, description, color }: Partial<ActivityGroup>,
        sessionId?: string,
    ): Promise<string> {
        const newSourceGroup = new ActivityGroup({
            activityId,
            activityItemId,
            sequence,
            sessionId: sessionId ?? '',
            description: description ?? '',
            color: color,
            name: name ?? 'Group',
            created: this.firestoreService.timestamp,
            updated: this.firestoreService.timestamp,
        }).createSerializableObject();

        return await this.firestoreService.addDocument(
            DBPathHelper.getBrainstormGroupPath(parentType, parentId),
            newSourceGroup,
        );
    }

    async updateBrainstormGroup(
        parentType: ParentType,
        parentId: string,
        sourceGroupId: string,
        sourceGroupData: Partial<ActivityGroup>,
    ): Promise<void> {
        await this.firestoreService.update(
            DBPathHelper.getBrainstormGroupPath(parentType, parentId, sourceGroupId),
            this.firestoreService.replaceEmptyFields(sourceGroupData),
        );
    }

    async removeBrainstormGroup(parentType: ParentType, parentId: string, brainstormGroupId: string): Promise<void> {
        await this.firestoreService.delete(
            DBPathHelper.getBrainstormGroupPath(parentType, parentId, brainstormGroupId),
        );
    }

    async removeActivityBrainstormGroups(
        parentType: ParentType,
        parentId: string,
        brainstormGroupsIds: string[],
    ): Promise<void> {
        if (!brainstormGroupsIds.length) {
            return;
        }

        const batchData = brainstormGroupsIds.map((id: string) => ({
            path: DBPathHelper.getBrainstormGroupPath(parentType, parentId, id),
        }));

        await this.firestoreService.deleteBatch(batchData);
    }
    // TODO: Delete after project  deprecation
    getProjectBrainstormGroupsByActivityItemId(projectId: string, activityItemId: string): Observable<ActivityGroup[]> {
        return this.firestoreService
            .getDocumentsByProperty<ActivityGroup>(
                DBPathHelper.getBrainstormGroupPath(ParentType.Projects, projectId),
                'activityItemId',
                activityItemId,
            )
            .pipe(
                map((brainstormGroups: ActivityGroup[]) =>
                    sortBySequenceAsc<ActivityGroup>(
                        (brainstormGroups as (ActivityGroup & { sequence: string })[]).map(
                            (brainstormGroups) => new ActivityGroup(brainstormGroups),
                        ),
                    ),
                ),
            );
    }

    getSessionBrainstormGroupsByActivityItemId(session: string, activityItemId: string): Observable<ActivityGroup[]> {
        return this.firestoreService
            .getDocumentsByProperty<ActivityGroup>(
                DBPathHelper.getBrainstormGroupPath(ParentType.Sessions, session),
                'activityItemId',
                activityItemId,
            )
            .pipe(
                map((brainstormGroups: ActivityGroup[]) =>
                    sortBySequenceAsc<ActivityGroup>(
                        (brainstormGroups as (ActivityGroup & { sequence: string })[]).map(
                            (brainstormGroups) => new ActivityGroup(brainstormGroups),
                        ),
                    ),
                ),
            );
    }

    // TODO: Delete after project  deprecation
    getProjectBrainstormGroupsByActivityId(projectId: string, activityId: string): Observable<ActivityGroup[]> {
        return this.firestoreService
            .getDocumentsByMultipleProperties<ActivityGroup>(
                DBPathHelper.getBrainstormGroupPath(ParentType.Projects, projectId),
                new Map([['activityId', activityId]]),
            )
            .pipe(
                map((brainstormGroups: ActivityGroup[]) =>
                    sortBySequenceAsc<ActivityGroup>(
                        (brainstormGroups as (ActivityGroup & { sequence: string })[]).map(
                            (brainstormGroups) => new ActivityGroup(brainstormGroups),
                        ),
                    ),
                ),
            );
    }

    getSessionBrainstormGroupsByActivityId(sessionId: string, activityId: string): Observable<ActivityGroup[]> {
        return this.firestoreService
            .getDocumentsByMultipleProperties<ActivityGroup>(
                DBPathHelper.getBrainstormGroupPath(ParentType.Sessions, sessionId),
                new Map([['activityId', activityId]]),
            )
            .pipe(
                map((brainstormGroups: ActivityGroup[]) =>
                    sortBySequenceAsc<ActivityGroup>(
                        (brainstormGroups as (ActivityGroup & { sequence: string })[]).map(
                            (brainstormGroups) => new ActivityGroup(brainstormGroups),
                        ),
                    ),
                ),
            );
    }
    // TODO: Delete after project  deprecation
    getProjectBrainstormGroupsByGroupingType(
        groupingType: string,
        projectId: string,
        activityId: string,
        activityItemId: string,
    ): Observable<ActivityGroup[]> {
        return groupingType === BrainstormGroupingType.Topic
            ? this.getProjectBrainstormGroupsByActivityItemId(projectId, activityItemId)
            : this.getProjectBrainstormGroupsByActivityId(projectId, activityId);
    }

    getSessionBrainstormGroupsByGroupingType(
        groupingType: string,
        sessionId: string,
        activityId: string,
        activityItemId: string,
    ): Observable<ActivityGroup[]> {
        return groupingType === BrainstormGroupingType.Topic
            ? this.getSessionBrainstormGroupsByActivityItemId(sessionId, activityItemId)
            : this.getSessionBrainstormGroupsByActivityId(sessionId, activityId);
    }
    // TODO: Delete after project  deprecation
    getNewProjectBrainstormGroup(
        projectId: string,
        { activityId, activityItemId, sequence, name, description, color, sessionId }: Partial<ActivityGroup>,
    ): ActivityGroup {
        return new ActivityGroup({
            activityId,
            activityItemId,
            sequence,
            id: this.firestoreService.getPushId(),
            sessionId: sessionId,
            description: description ?? '',
            color: color ?? getRandomGroupColor(),
            name: name ?? 'Group',
            numberOfResponses: {},
            created: this.firestoreService.timestamp,
            updated: this.firestoreService.timestamp,
        });
    }
    getNewSessionBrainstormGroup(
        sessionId: string,
        { activityId, activityItemId, sequence, name, description, color }: Partial<ActivityGroup>,
    ): ActivityGroup {
        return new ActivityGroup({
            activityId,
            activityItemId,
            sequence,
            id: this.firestoreService.getPushId(),
            sessionId: sessionId,
            description: description ?? '',
            color: color ?? getRandomGroupColor(),
            name: name ?? 'Group',
            numberOfResponses: {},
            created: this.firestoreService.timestamp,
            updated: this.firestoreService.timestamp,
        });
    }
    // TODO: Delete after project  deprecation
    async updateProjectBrainstormGroups(projectId: string, groups: ActivityGroup[]): Promise<void> {
        const fanOutWrites: FanOutWrite[] = Array.from(groups).map((group) => {
            const path = DBPathHelper.getBrainstormGroupPath(ParentType.Projects, projectId, group.id);
            const data = group.createSerializableObject();

            return { path, data };
        });

        await this.firestoreService.updateBatch(fanOutWrites);
    }

    async updateSessionBrainstormGroups(sessionId: string, groups: ActivityGroup[]): Promise<void> {
        const fanOutWrites: FanOutWrite[] = Array.from(groups).map((group) => {
            const path = DBPathHelper.getBrainstormGroupPath(ParentType.Sessions, sessionId, group.id);
            const data = group.createSerializableObject();

            return { path, data };
        });

        await this.firestoreService.updateBatch(fanOutWrites);
    }
    // TODO: Delete after project  deprecation
    async deleteProjectBrainstormGroups(projectId: string, groupIds: string[]): Promise<void> {
        const fanOutWrites: FanOutWrite[] = Array.from(groupIds).map((groupId) => {
            const path = DBPathHelper.getBrainstormGroupPath(ParentType.Projects, projectId, groupId);
            return { path };
        });

        await this.firestoreService.deleteBatch(fanOutWrites);
    }

    async deleteSessionBrainstormGroups(sessionId: string, groupIds: string[]): Promise<void> {
        const fanOutWrites: FanOutWrite[] = Array.from(groupIds).map((groupId) => {
            const path = DBPathHelper.getBrainstormGroupPath(ParentType.Sessions, sessionId, groupId);
            return { path };
        });

        await this.firestoreService.deleteBatch(fanOutWrites);
    }
     // TODO: Delete after project  deprecation
    async addProjectBrainstormGroups(projectId: string, groups: ActivityGroup[]): Promise<void> {
        const fanOutWrites: FanOutWrite[] = Array.from(groups).map((group) => {
            const path = DBPathHelper.getBrainstormGroupPath(ParentType.Projects, projectId, group.id);
            const data = group.createSerializableObject();

            return { path, data };
        });

        await this.firestoreService.writeBatch(fanOutWrites);
    }

    async addSessionBrainstormGroupsNew(sessionId: string, groups: ActivityGroup[]): Promise<void> {
        const fanOutWrites: FanOutWrite[] = Array.from(groups).map((group) => {
            const path = DBPathHelper.getBrainstormGroupPath(ParentType.Sessions, sessionId, group.id);
            const data = group.createSerializableObject();

            return { path, data };
        });

        await this.firestoreService.writeBatch(fanOutWrites);
    }

    // TODO: Delete after project  deprecation
    async updateResponseGroupsByGroupId(projectId: string, groupId: string): Promise<void> {
        return this.firestoreService.cloudFunctionCallable('updateResponseGroupsByGroupId', {
            projectId,
            groupId,
        });
    }

    async updateResponseGroupsByGroupIdNew(sessionId: string, groupId: string): Promise<void> {
        return this.firestoreService.cloudFunctionCallable('updateResponseGroupsByGroupIdNew', {
            sessionId,
            groupId,
        });
    }
    // TODO: Delete after project  deprecation
    async resetGroupsCount(
        projectId: string,
        selectedQuestionId: string,
        brainstormGroupsIds: string[],
    ): Promise<void> {
        const batchData = [];

        brainstormGroupsIds.map((brainstormGroupId) =>
            batchData.push({
                path: DBPathHelper.getBrainstormGroupPath(ParentType.Projects, projectId, brainstormGroupId),
                data: {
                    numberOfResponses: {
                        [selectedQuestionId]: 0,
                    },
                },
            }),
        );

        await this.firestoreService.writeBatch(batchData);
    }

    async resetGroupsCountNew(
        sessionId: string,
        selectedQuestionId: string,
        brainstormGroupsIds: string[],
    ): Promise<void> {
        const batchData = [];

        brainstormGroupsIds.map((brainstormGroupId) =>
            batchData.push({
                path: DBPathHelper.getBrainstormGroupPath(ParentType.Sessions, sessionId, brainstormGroupId),
                data: {
                    numberOfResponses: {
                        [selectedQuestionId]: 0,
                    },
                },
            }),
        );

        await this.firestoreService.writeBatch(batchData);
    }
    // TODO: Delete after project  deprecation
    async updateGroupsCount(
        projectId: string,
        selectedQuestionId: string,
        brainstormGroupsIds: Dictionary<number>,
    ): Promise<void> {
        if (Object.keys(brainstormGroupsIds).length) {
            const batchData = [];

            for (const brainstormGroupId in brainstormGroupsIds) {
                batchData.push({
                    path: DBPathHelper.getBrainstormGroupPath(ParentType.Projects, projectId, brainstormGroupId),
                    data: {
                        numberOfResponses: {
                            [selectedQuestionId]: this.firestoreService.changeCounterValue(
                                brainstormGroupsIds[brainstormGroupId],
                            ),
                        },
                    },
                });
            }

            await this.firestoreService.writeBatch(batchData);
        }
    }
    async updateGroupsCountNew(
        sessionId: string,
        selectedQuestionId: string,
        brainstormGroupsIds: Dictionary<number>,
    ): Promise<void> {
        if (Object.keys(brainstormGroupsIds).length) {
            const batchData = [];

            for (const brainstormGroupId in brainstormGroupsIds) {
                batchData.push({
                    path: DBPathHelper.getBrainstormGroupPath(ParentType.Sessions, sessionId, brainstormGroupId),
                    data: {
                        numberOfResponses: {
                            [selectedQuestionId]: this.firestoreService.changeCounterValue(
                                brainstormGroupsIds[brainstormGroupId],
                            ),
                        },
                    },
                });
            }

            await this.firestoreService.writeBatch(batchData);
        }
    }
    // TODO: Delete after project  deprecation
    async updateGroupResponsesCount(
        projectId: string,
        selectedQuestionId: string,
        groupId: string,
        number = 0,
    ): Promise<void> {
        const groupRef = this.firestoreService.getDocumentRef(
            DBPathHelper.getBrainstormGroupPath(ParentType.Projects, projectId, groupId),
        );
        return groupRef.set(
            {
                numberOfResponses: {
                    [selectedQuestionId]: this.firestoreService.changeCounterValue(number),
                },
            },
            { merge: true },
        );
    }

    async updateGroupResponsesCountNew(
        sessionId: string,
        selectedQuestionId: string,
        groupId: string,
        number = 0,
    ): Promise<void> {
        const groupRef = this.firestoreService.getDocumentRef(
            DBPathHelper.getBrainstormGroupPath(ParentType.Sessions, sessionId, groupId),
        );
        return groupRef.set(
            {
                numberOfResponses: {
                    [selectedQuestionId]: this.firestoreService.changeCounterValue(number),
                },
            },
            { merge: true },
        );
    }

    getGroupResponsesCount(selectedQuestionId: string, number = 0): GroupResponseCountModel {
        return {
            numberOfResponses: {
                [selectedQuestionId]: this.firestoreService.changeCounterValue(number),
            },
        };
    }
    // TODO: Delete after project  deprecation
    async setGroupResponses(
        updateResponseCounts: FanOutWrite[],
        writeResponse: FanOutWrite,
        projectId: string,
        sessionId: string,
        updateSessionStatus?: boolean,
    ): Promise<string> {
        const batch = this.firestoreService.createBatch();

        updateResponseCounts.forEach((element) => {
            batch.set(
                this.firestoreService.getDocumentRef(
                    DBPathHelper.getBrainstormGroupPath(ParentType.Projects, projectId, element.path),
                ),
                element.data,
                { merge: true },
            );
        });

        const collectionRef = this.firestoreService.getCollectionRef(writeResponse.path);
        const manualID = this.firestoreService.getPushId();
        const newDocRef: DocumentReference = collectionRef.doc(manualID);

        batch.set(newDocRef, writeResponse.data);

        if (updateSessionStatus) {
            const sessionPath = DBPathHelper.getSessionPath(ParentType.Projects, projectId, sessionId);
            batch.update(this.firestoreService.getDocumentRef(sessionPath), { status: SessionStatus.Active });
        }

        await batch.commit();
        return manualID;
    }

    async setGroupResponsesNew(
        updateResponseCounts: FanOutWrite[],
        writeResponse: FanOutWrite,
        sessionId: string,
        updateSessionStatus?: boolean,
    ): Promise<string> {
        const batch = this.firestoreService.createBatch();

        updateResponseCounts.forEach((element) => {
            batch.set(
                this.firestoreService.getDocumentRef(
                    DBPathHelper.getBrainstormGroupPath(ParentType.Sessions, sessionId, element.path),
                ),
                element.data,
                { merge: true },
            );
        });

        const collectionRef = this.firestoreService.getCollectionRef(writeResponse.path);
        const manualID = this.firestoreService.getPushId();
        const newDocRef: DocumentReference = collectionRef.doc(manualID);

        batch.set(newDocRef, writeResponse.data);

        if (updateSessionStatus) {
            const sessionPath = DBPathHelper.getSessionPathNew(ParentType.Sessions, sessionId);
            batch.update(this.firestoreService.getDocumentRef(sessionPath), { status: SessionStatus.Active });
        }

        await batch.commit();
        return manualID;
    }
    // TODO: Delete after project  deprecation
    async updateGroupResponsesCountAfterUngrouped(
        projectId: string,
        selectedQuestionId: string,
        groupsMap: Dictionary<string[]>,
    ): Promise<void> {
        if (Object.keys(groupsMap).length) {
            const batchData = [];

            for (const groupId in groupsMap) {
                const num = groupsMap[groupId].length || 0;
                batchData.push({
                    path: DBPathHelper.getBrainstormGroupPath(ParentType.Projects, projectId, groupId),
                    data: {
                        numberOfResponses: {
                            [selectedQuestionId]: this.firestoreService.changeCounterValue(-num),
                        },
                    },
                });
            }

            await this.firestoreService.writeBatch(batchData);
        }
    }

    async updateGroupResponsesCountAfterUngroupedNew(
        sessionId: string,
        selectedQuestionId: string,
        groupsMap: Dictionary<string[]>,
    ): Promise<void> {
        if (Object.keys(groupsMap).length) {
            const batchData = Object.entries(groupsMap).map(([groupId, group]) => {
                const num = group.length || 0;
                return {
                    path: DBPathHelper.getBrainstormGroupPath(ParentType.Sessions, sessionId, groupId),
                    data: {
                        numberOfResponses: {
                            [selectedQuestionId]: this.firestoreService.changeCounterValue(-num),
                        },
                    },
                };
            });
    
            await this.firestoreService.writeBatch(batchData);
        }
    }
    // TODO: Delete after project  deprecation
    async updateBrainstormResponsesGroups(
        projectId: string,
        responseIdSet: Set<string>,
        groupsMap: Dictionary<string[]>,
    ): Promise<void> {
        const fanOutWrites: FanOutWrite[] = Array.from(responseIdSet).map((responseId) => {
            const path = `projects/${projectId}/responses/${responseId}`;
            const data = groupsMap[responseId] ? { groups: groupsMap[responseId] } : {};

            return { path, data };
        });

        await this.firestoreService.updateBatch(fanOutWrites);
    }

    async updateBrainstormResponsesGroupsNew(
        sessionId: string,
        responseIdSet: Set<string>,
        groupsMap: Dictionary<string[]>,
    ): Promise<void> {
        const fanOutWrites: FanOutWrite[] = Array.from(responseIdSet).map((responseId) => {
            const path = DBPathHelper.getResponsesPath(ParentType.Sessions, sessionId, responseId);
            const data = groupsMap[responseId] ? { groups: groupsMap[responseId] } : {};

            return { path, data };
        });

        await this.firestoreService.updateBatch(fanOutWrites);
    }
    // TODO: Delete after project  deprecation
    async deleteBrainstormResponsesGroups(projectId: string, responseIdSet: Set<string>): Promise<void> {
        const fanOutWrites: FanOutWrite[] = Array.from(responseIdSet).map((responseId) => {
            const path = `projects/${projectId}/responses/${responseId}`;
            const data = { groups: [] };

            return { path, data };
        });

        await this.firestoreService.updateBatch(fanOutWrites);
    }

    async deleteBrainstormResponsesGroupsNew(sessionId: string, responseIdSet: Set<string>): Promise<void> {
        const fanOutWrites: FanOutWrite[] = Array.from(responseIdSet).map((responseId) => {
            const path = DBPathHelper.getResponsesPath(ParentType.Sessions, sessionId, responseId);
            const data = { groups: [] };

            return { path, data };
        });

        await this.firestoreService.updateBatch(fanOutWrites);
    }
}
