import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Output, signal } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    emptyScreenInAppNotificationsDesign,
    emptyScreenTitles,
    InAppActivityItemStatusChangeNotification,
    InAppNotification,
    InAppNotificationStatusFilterType,
    InAppNotificationType,
    InAppProjectInviteNotification,
    InAppSessionInviteNotification,
    InAppTemplateInviteNotification,
    inputPlaceholders,
    NavigationTab,
    ParentType,
    publicParentTypes,
    routerLinksMap,
    TemplatesTab,
    TemplateTab,
    tooltipTexts,
    virusCheckMessages,
} from '@accenture/shared/data';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SharedMaterialModule } from '@accenture/shared/material';
import { IconColor, IconSize, LoaderSize, ScrollPosition, SharedUiModule } from '@accenture/shared/ui';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { trackById } from '@accenture/shared/util';

import { InAppNotificationsFacade, InAppNotificationsViewModel } from './in-app-notifications-facade';
import {
    DisplayNotificationFilterTypePipe,
    DisplayNotificationIconPipe,
    DisplayNotificationStatusPipe,
    DisplayNotificationStepPipe,
} from './pipes';

@Component({
    selector: 'accenture-in-app-notifications',
    standalone: true,
    templateUrl: './in-app-notifications.component.html',
    styleUrls: ['./in-app-notifications.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        // change SharedUiModule to empty screen, accenture-icon components when at changed to standalone
        SharedUiModule,
        SharedMaterialModule,
        DisplayNotificationStepPipe,
        DisplayNotificationStatusPipe,
        DisplayNotificationFilterTypePipe,
        DisplayNotificationIconPipe,
    ],
    providers: [InAppNotificationsFacade],
})
export class InAppNotificationsComponent {
    @Output() closePanel = new EventEmitter<void>();

    private facade = inject(InAppNotificationsFacade);
    private router = inject(Router);
    inAppNotificationType = InAppNotificationType;
    iconSize = IconSize;
    iconColor = IconColor;

    trackById = trackById;

    vm$: Observable<InAppNotificationsViewModel> = this.facade.vm$;

    filterMenuOpen = false;
    filterTooltipText = tooltipTexts.filter;

    notificationStatusFilterTypes = InAppNotificationStatusFilterType;
    emptyScreenInAppNotificationsDesign = emptyScreenInAppNotificationsDesign;
    searchPlaceholder = inputPlaceholders.search;

    virusCheckMessages = virusCheckMessages;
    isScrolled = signal<boolean>(false);

    getLoaderSize(haveNotifications: boolean): LoaderSize {
        return haveNotifications ? LoaderSize.MediumSmall : LoaderSize.Default;
    }

    getEmptyScreenTitle({ searchValue, filtersApplied }: InAppNotificationsViewModel): string {
        switch (true) {
            case filtersApplied: {
                return emptyScreenTitles.noInAppNotificationsFilterMatch;
            }
            case !!searchValue: {
                return emptyScreenTitles.noInAppNotificationsSearchMatch;
            }
            default: {
                return emptyScreenTitles.noInAppNotificationsYet;
            }
        }
    }

    showFiltersAndSearch({ notifications, searchValue, filtersApplied }: InAppNotificationsViewModel): boolean {
        return !!notifications.length || !!searchValue || filtersApplied;
    }

    emptyScreenVisible({ notifications, isLoading }: InAppNotificationsViewModel): boolean {
        return !notifications.length && !isLoading;
    }

    showListBottom({ allLoaded, isLoading, notifications }: InAppNotificationsViewModel): boolean {
        return allLoaded && !isLoading && !!notifications.length;
    }

    navigateTo(event: Event, notification: InAppNotification): void {
        event.stopPropagation();
        this.markNotificationAsRead(notification.id);

        switch (notification.type) {
            case InAppNotificationType.ProjectInvite:
            case InAppNotificationType.SessionBulkInvite:
            case InAppNotificationType.ProjectRoleChange: {
                const { projectId } = notification as InAppProjectInviteNotification;
                this.router.navigate(['project', projectId]);
                break;
            }
            case InAppNotificationType.SessionInvite:
            case InAppNotificationType.SessionRoleChange: {
                const { projectId, sessionId } = notification as InAppSessionInviteNotification;
                this.router.navigate(['project', projectId, 'session', sessionId]);
                break;
            }
            case InAppNotificationType.TemplateInvite: {
                const { templateId, templateType, activityType, templateName }
                    = notification as InAppTemplateInviteNotification;
                if (publicParentTypes.includes(templateType)) {
                    this.router.navigate(['home'], {
                        queryParams: {
                            tab: NavigationTab.Templates,
                            templatesTab: TemplatesTab.TemplateStore,
                            activeTemplateStoreTab: TemplateTab.All,
                            searchValue: templateName,
                        },
                    });
                } else if (templateType === ParentType.ActivityTemplates) {
                    this.router.navigate([
                        routerLinksMap[templateType],
                        `${activityType.toLowerCase()}-edit`,
                        templateId,
                    ]);
                } else {
                    this.router.navigate([routerLinksMap[templateType], templateId]);
                }
                break;
            }
            case InAppNotificationType.TemplatePublish:
            case InAppNotificationType.UpdatePublishedTemplate: {
                const { templateName } = notification as InAppTemplateInviteNotification;

                this.router.navigate(['home'], {
                    queryParams: {
                        tab: NavigationTab.Templates,
                        templatesTab: TemplatesTab.TemplateStore,
                        activeTemplateStoreTab: TemplateTab.All,
                        searchValue: templateName,
                    },
                });
                break;
            }
            case InAppNotificationType.ActivityItemStepChange: {
                const { projectId, sessionId, activityType, activityId, step, activityItemId }
                    = notification as InAppActivityItemStatusChangeNotification;
                this.router.navigate(
                    ['project', projectId, 'session', sessionId, activityType.toLowerCase(), activityId, step],
                    {
                        queryParams: {
                            tableId: activityItemId,
                        },
                    },
                );
                break;
            }
            default:
                break;
        }

        this.closePanel.emit();
    }

    getCustomDate(createdDate: Date): number {
        const currentDate = new Date();
        return Math.round((currentDate.getTime() - createdDate.getTime()) / (1000 * 3600 * 24));
    }

    markNotificationAsRead(notificationId: string): void {
        this.facade.markNotificationAsRead(notificationId);
    }

    deleteNotification(notificationId: string): void {
        this.facade.deleteNotification(notificationId);
    }

    loadMore(position: ScrollPosition): void {
        if (position === ScrollPosition.End) {
            this.facade.getNotifications();
        }
        this.isScrolled.set(position !== ScrollPosition.Start);
    }

    setSearchValue(event: Event, value: string): void {
        event?.stopPropagation();
        this.facade.setSearchValue(value.trim());
    }

    toggleFilterMenu() {
        this.filterMenuOpen = !this.filterMenuOpen;
    }

    updateFilters(
        event: Event,
        field: InAppNotificationStatusFilterType | InAppNotificationStatusFilterType,
        value: boolean,
    ) {
        event?.stopPropagation();
        this.facade.updateFilters({ [field]: value });
    }

    isPublicTemplate(templateType: ParentType): boolean {
        return publicParentTypes.includes(templateType);
    }
}
