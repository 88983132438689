import { ChangeDetectionStrategy, Component } from '@angular/core';

import { addProjectImageUrl, buttonLabels, createProjectImageUrl,DialogSelectionOption } from '@accenture/shared/data';
import { LoadedDescription } from '@accenture/shared/ui';

import { UseTemplateDialogFacade } from './use-template-dialog-facade';

@Component({
    selector: 'accenture-use-template-dialog',
    templateUrl: './use-template-dialog.component.html',
    styleUrls: ['./use-template-dialog.component.scss'],
    providers: [UseTemplateDialogFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UseTemplateDialogComponent {
    vm$ = this.facade.vm$;

    loaderText: LoadedDescription;
    createProjectImageUrl = createProjectImageUrl;
    addProjectImageUrl = addProjectImageUrl;
    buttonLabels = buttonLabels;

    constructor(private facade: UseTemplateDialogFacade) {}

    closeDialog(): void {
        this.facade.closeDialog();
    }

    openCreateDialog(selectedProjectId: string): void {
        this.facade.closeDialog();
        if (selectedProjectId) {
            this.facade.openCreateSessionDialog();
        } else {
            this.facade.openCreateProjectDialog();
        }
    }

    openSelectDialog(selectedProjectId: string): void {
        this.facade.closeDialog();
        if (selectedProjectId) {
            this.facade.openAddSessionDialog();
        } else {
            this.facade.openAddProjectDialog();
        }
    }

    getSelectionOptions(selectedProjectId: string): DialogSelectionOption[] {
        return [
            {
                name: selectedProjectId ? buttonLabels.createNewSession : buttonLabels.createNewProject,
                imageUrl: createProjectImageUrl,
                callBack: () => this.openCreateDialog(selectedProjectId),
            },
            {
                name: selectedProjectId ? buttonLabels.selectExistingSession : buttonLabels.selectExistingProject,
                imageUrl: addProjectImageUrl,
                callBack: () => this.openSelectDialog(selectedProjectId),
            },
        ];
    }
}
