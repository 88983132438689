import { MsGraphOnlineMeeting } from './msgraph';
import { User } from './user';

export enum RouteContexts {
    Channel = 'CHANNEL',
    Meeting = 'MEETING',
}

export enum TeamsAppRoutePaths {
    Channel = 'channels',
    Meeting = 'meetings',
}

export interface BotNotificationContent {
    title?: string;
    image?: string;
    description?: string;
    message: string;
    user?: string;
    avatar?: string;
    date?: string;
    fredUrl?: string;
    teamsUrl?: string;
}

export interface BotNotification {
    content: BotNotificationContent;
    recipients: User[];
    chatId?: string;
}

export enum ProjectEvents {
    AddUserAccess = 'ADD_PROJECT_USER_ACCESS',
    RemoveUserAccess = 'REMOVE_PROJECT_USER_ACCESS',
    SwitchUserAccess = 'SWITCH_PROJECT_USER_ACCESS',
}

export enum SessionEvents {
    AddUserAccess = 'ADD_SESSION_USER_ACCESS',
    RemoveUserAccess = 'REMOVE_SESSION_USER_ACCESS',
    SwitchUserAccess = 'SWITCH_SESSION_USER_ACCESS',
    AddSession = 'ADD_SESSION',
    RemoveSession = 'REMOVE_SESSION',
    EnableSession = 'ENABLE_SESSION',
    DisableSession = 'DISABLE_SESSION',
    FocusActivity = 'FOCUS_SESSION_ACTIVITY',
}

export enum ActivityEvents {
    AddActivity = 'ADD_ACTIVITY',
    RemoveActivity = 'REMOVE_ACTIVITY',
    EnableActivity = 'ENABLE_ACTIVITY',
    DisableActivity = 'DISABLE_ACTIVITY',
}

export const teamsBaseUrl = 'https://teams.microsoft.com/l/entity/';

export interface TeamsRoute {
    type: string;
    id: string;
    apiResults: {
        onlineMeeting?: MsGraphOnlineMeeting;
        //TODO: get the actual models from MSGraph.
        channel?: any;
        group?: any;
    };
    context: {
        chat: {
            id: string;
        };
        meeting?: {
            id: string;
        };
        channel?: {
            id: string;
        };
    };
}
