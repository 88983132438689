<div *ngIf="headerStates$ | async as headerState" class="app-layout" [class.login]="!headerState.isHeaderVisible">
  <accenture-maintenance *ngIf="headerState.maintenanceActive"></accenture-maintenance>

  <div *ngIf="!headerState.maintenanceActive" class="app-layout-wrapper">
    <accenture-top-navigation-bar
      *ngIf="!headerState.isHeaderHidden"
      [isLogin]="!headerState.isHeaderVisible"
      id="app-header"
      class="app-layout-header"
      (toggleSidebarEvent)="toggleChatBot()"
    >
    </accenture-top-navigation-bar>

    <accenture-coming-soon></accenture-coming-soon>

    <div class="app-layout-body-wrapper d-flex">
      <div
        class="app-layout-body"
        accentureScrollSpy
        [ngClass]="{
          'with-chat': isChatBotVisible,
          'collapsed-header': collapseHeader
        }"
        [accentureOverlayLoading]="showLoader$"
        [accentureScrollSpyDisabled]="headerState.isScrollSpyDisabled"
        (scrollSpyPositionChange)="onScrollPositionChange($event)"
      >
        <router-outlet></router-outlet>
      </div>
      <accenture-bot-chat *ngIf="isChatBotVisible" (click)="$event.stopPropagation()"></accenture-bot-chat>
    </div>
  </div>
</div>
