import { createAction, props } from '@ngrx/store';

import { Collection, CollectionsDisplayMode, CollectionSession, UserCollection } from '@accenture/shared/data';

export const getCollectionAndSessions = createAction(
    '[Collection] Get Collection, Collection Sessions Data',
    props<{ collectionId: string }>(),
);

export const getCollectionAndSessionsSuccess = createAction(
    '[Collection] Get Collection, Sessions',
    props<{ collection: Collection; sessions: CollectionSession[] }>(),
);

export const getCollectionAndSessionsError = createAction('[Collection] Get Collection, Sessions Data Error');

export const getCollection = createAction('[Collection] Get Collection Data', props<{ collectionId: string }>());

export const getCollectionSuccess = createAction(
    '[Collection] Get Collection Data Success',
    props<{ collection: Collection }>(),
);

export const getCollectionError = createAction('[Collection] Get User Collection Data Error');

export const getUserCollection = createAction(
    '[Collection] Get User Collection Data',
    props<{ collectionId: string }>(),
);

export const getUserCollectionSuccess = createAction(
    '[Collection] Get User Collection Data Success',
    props<{ userCollection: UserCollection }>(),
);

export const clearCollectionStore = createAction('[Collection] Clear Collection Store');

export const clearCollectionStoreSubscription = createAction('[Collection] Clear Collection Store Subscription');

export const setCollectionsDisplayMode = createAction(
    '[Collection] Set Collections Display Mode',
    props<{ displayMode: CollectionsDisplayMode }>(),
);
