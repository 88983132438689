import { TemplateRole } from '@accenture/shared/data';

export const templateRolesListForFilter: TemplateRole[] = [TemplateRole.Owner, TemplateRole.Collaborator];

export const confirmationDeletionMessages = (templateTypeName: string) => {
    return `Are you sure you want to delete this ${templateTypeName}? You will lose all associated data.`;
};

export const projectTemplateIsBeingDeletedSnackbarText = (templateTypeName: string) => {
    return `${templateTypeName} is being deleted`;
};
export const projectTemplateHasBeenDeletedSnackbarText = (templateTypeName: string) => {
    return `${templateTypeName} has been deleted successfully`;
};
