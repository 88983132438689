import { AccessControlRole, InvitedSessions } from '@accenture/shared/data';

export type ChipsObject =
    | {
          [role in AccessControlRole]: string[];
      }
    | Record<string, string[]>;

export interface InvitationObject {
    chipsObject: ChipsObject;
    message?: string;
    adminMessage?: string;
    memberMessage?: string;
    invitedUsersEmails: string[];
    invitedSessions?: InvitedSessions[];
}
