export class InvitedUser {
    invitingUserId: string;
    email: string;
}

export class InvitedSessions {
    email: string;
    sessionsIds: string[];
    role: string;
}
