import { ChangeDetectionStrategy, Component } from '@angular/core';

import { PublishTemplateConfirmationDialogFacade } from './publish-template-confirmation-dialog-facade';
import { trackById } from '@accenture/shared/util';

@Component({
    selector: 'accenture-publish-template-confirmation-dialog',
    templateUrl: './publish-template-confirmation-dialog.component.html',
    styleUrls: ['./publish-template-confirmation-dialog.component.scss'],
    providers: [PublishTemplateConfirmationDialogFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublishTemplateConfirmationDialogComponent {
    vm$ = this.facade.vm$;

    readonly trackById = trackById;

    constructor(
        private facade: PublishTemplateConfirmationDialogFacade
    ) {}

    closeDialog(): void {
        this.facade.closeDialog();
    }

    publish(): void {
        this.facade.onPublish();
    }
}
