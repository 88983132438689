import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FeatureToggleName } from '@accenture/shared/data';
import { TimeoutName } from '@accenture/shared/data-access';

import {
    APPLICATION_CONFIGURATION_FEATURE_KEY,
    ApplicationConfigurationState,
} from './application-configuration.reducer';

export const selectApplicationConfigurationState = createFeatureSelector<ApplicationConfigurationState>(
    APPLICATION_CONFIGURATION_FEATURE_KEY,
);

export const selectActivityConfigurations = createSelector(
    selectApplicationConfigurationState,
    applicationConfigurationState => applicationConfigurationState?.activityConfigurations || [],
);

export const selectActivityConfigurationsMap = createSelector(selectActivityConfigurations, activityConfigurations =>
    activityConfigurations.reduce((accumulator, activityConfiguration) => {
        accumulator[activityConfiguration.type] = activityConfiguration;
        return accumulator;
    }, {}),
);

export const selectActivityConfigurationsTypesList = createSelector(
    selectActivityConfigurations,
    activityConfigurations => activityConfigurations.map(activityConfiguration => activityConfiguration.type),
);

export const selectActivityConfigurationsVisibleTypesList = createSelector(
    selectActivityConfigurations,
    activityConfigurations =>
        activityConfigurations.reduce((typesList, activityConfiguration) => {
            if (activityConfiguration.visible) {
                typesList.push(activityConfiguration.type);
            }

            return typesList;
        }, []),
);

export const selectFeatureToggles = createSelector(
    selectApplicationConfigurationState,
    applicationConfigurationState => applicationConfigurationState?.featureToggles || {},
);

export const selectFeatureToggle = (toggleName: FeatureToggleName) =>
    createSelector(selectFeatureToggles, featureToggles => !!featureToggles[toggleName]);

export const selectTimeouts = createSelector(
    selectApplicationConfigurationState,
    applicationConfigurationState => applicationConfigurationState?.timeouts || {},
);

export const selectTimeout = (timeoutName: TimeoutName) =>
    createSelector(selectTimeouts, timeouts => timeouts[timeoutName] || 0);

export const selectMaintenanceConfig = createSelector(
    selectApplicationConfigurationState,
    applicationConfigurationState => applicationConfigurationState?.maintenanceConfig || {},
);
