import { AccessControlRole } from './access-control';

export enum EmailMessageType {
    ProjectInvite = 'PROJECT_INVITE',
    BulkProjectInvite = 'BULK_PROJECT_INVITE',
    TemplateInvite = 'TEMPLATE_INVITE',
    TemplatePublish = 'TEMPLATE_PUBLISH',
    UpdatePublishedTemplate = 'UPDATE_PUBLISHED_TEMPLATE',
    PublicTemplateInvite = 'PUBLIC_TEMPLATE_INVITE',
    BulkTemplateInvite = 'BULK_TEMPLATE_INVITE',
    SessionInvite = 'SESSION_INVITE',
    ChangeProjectRole = 'CHANGE_PROJECT_ROLE',
    ChangeSessionRole = 'CHANGE_SESSION_ROLE',
    BulkInviteSession = 'BULK_INVITE_SESSION',
    FeedBack = 'FEEDBACK',
    UserActivated = 'USER_ACTIVATED',
    UserDeactivated = 'USER_DEACTIVATED',
    VerifyEmail = 'VERIFY_EMAIL',
    MultipleSessionsInvite = 'MULTIPLE_SESSIONS_INVITE',
}

export interface EmailMessageData {
    env: string;
    senderName?: string;
    url?: string;
    name?: string;
    imageUrl?: string;
    previousRole?: string;
    newRole?: string;
    year?: number;
    comment?: string;
    role?: AccessControlRole;
    environmentName?: string;
    firstName?: string;
    lastName?: string;
    feedBack?: string;
    senderEmail?: string;
    isExternalUser?: boolean;
    isPublic?: boolean;
    loginUrl?: string;
}

export class EmailMessagesBatch {
    emails: string[];
    messageType: EmailMessageType;
    data: EmailMessageData;
}

export class EmailMessage {
    senderId: string;
}
