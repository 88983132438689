import { AccessControlRole } from '../access-control';
import { ParentType } from '../parent-type';

export interface ImportData {
    projectId?: string;
    sessionId?: string;
    templateId?: string;
    activityId?: string;
    activityItemId?: string;
    userId?: string;
    parentType?: ParentType;

    // emails
    env?: string;
    role?: AccessControlRole;

    // table item
    startCell?: string;
    endCell?: string;
    parentId?: string;
    sheetId?: number;
    sequence?: string;
    activityItemTitle?: string;
}
