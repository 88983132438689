import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { unset } from 'lodash';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import {
    BaseFilterAntSortOptions,
    BaseFilterOptions,
    DBPathHelper,
    initialTeamMemberFilterAntSortMap,
    initialTeamMemberFilters,
    lazyLoadingTeamMembersLimit,
    ParentType,
    SortOrders,
    TeamMember,
    TeamMemberFilters,
    TeamMemberFilterType,
    TeamMemberProjectFilters,
    TeamMemberSessionFilters,
    TeamMembersFilterSortBy,
    TeamMembersSessionFilterSortBy,
} from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

type FirebaseQuery = firebase.firestore.Query<firebase.firestore.DocumentData>;

@Injectable({
    providedIn: 'root',
})
export class TeamMemberFilterAndSortService {
    constructor(private firestoreService: FirestoreService) {}

    getTableValidationFeedbackSieve(
        userId: string,
        parentType: ParentType,
        parentId: string,
        sessionId: string,
    ): Observable<BaseFilterOptions> {
        return this.getFilterAndSort(
            userId,
            parentType,
            parentId,
            sessionId,
            TeamMemberFilterType.TableValidationFeedback,
        );
    }

    getTableValidationFeedbackSieveNew(
        userId: string,
        parentType: ParentType,
        parentId: string,
    ): Observable<BaseFilterOptions> {
        return this.getFilterAndSortNew(userId, parentType, parentId, TeamMemberFilterType.TableValidationFeedback);
    }

    async updateTableValidationFeedbackSieve(
        userId: string,
        data: BaseFilterAntSortOptions,
        parentType: ParentType,
        parentId: string,
        sessionId: string,
    ): Promise<void> {
        return this.updateFiltersAndSort(
            userId,
            parentType,
            parentId,
            sessionId,
            TeamMemberFilterType.TableValidationFeedback,
            data,
        );
    }

    async updateTableValidationFeedbackSieveNew(
        userId: string,
        data: BaseFilterAntSortOptions,
        parentType: ParentType,
        sessionId: string,
    ): Promise<void> {
        return this.updateFiltersAndSortNew(
            userId,
            parentType,
            sessionId,
            TeamMemberFilterType.TableValidationFeedback,
            data,
        );
    }

    private async updateFiltersAndSort(
        userId: string,
        parentType: ParentType,
        parentId: string,
        sessionId: string,
        filter: TeamMemberFilterType,
        data: BaseFilterAntSortOptions,
    ): Promise<void> {
        const dataPath = DBPathHelper.getTeamMemberFiltersPath(parentType, parentId, userId, sessionId, filter);

        await this.firestoreService.upsert(dataPath, data);
    }

    private async updateFiltersAndSortNew(
        userId: string,
        parentType: ParentType,
        sessionId: string,
        filter: TeamMemberFilterType,
        data: BaseFilterAntSortOptions,
    ): Promise<void> {
        const dataPath = DBPathHelper.getTeamMemberFiltersPathNew(parentType, sessionId, userId, filter);

        await this.firestoreService.upsert(dataPath, data);
    }

    private getFilterAndSort(
        userId: string,
        parentType: ParentType,
        parentId: string,
        sessionId: string,
        filter: TeamMemberFilterType,
    ): Observable<BaseFilterAntSortOptions> {
        const initialDefaultFilterObject = initialTeamMemberFilterAntSortMap[filter];

        if (parentType === ParentType.PublicProjectTemplates) {
            return of(initialDefaultFilterObject);
        }

        const filterPath = DBPathHelper.getTeamMemberFiltersPath(parentType, parentId, userId, sessionId, filter);

        return this.firestoreService.getDocument<BaseFilterAntSortOptions>(filterPath).pipe(
            map((filter: BaseFilterAntSortOptions) => {
                unset(filter, 'id');
                return filter
                    ? {
                          ...initialDefaultFilterObject,
                          ...filter,
                      }
                    : initialDefaultFilterObject;
            }),
        );
    }

    private getFilterAndSortNew(
        userId: string,
        parentType: ParentType,
        parentId: string,
        filter: TeamMemberFilterType,
    ): Observable<BaseFilterAntSortOptions> {
        const initialDefaultFilterObject = initialTeamMemberFilterAntSortMap[filter];

        if (parentType === ParentType.PublicProjectTemplates) {
            return of(initialDefaultFilterObject);
        }

        const filterPath = DBPathHelper.getTeamMemberFiltersPathNew(parentType, parentId, userId, filter);

        return this.firestoreService.getDocument<BaseFilterAntSortOptions>(filterPath).pipe(
            map((filter: BaseFilterAntSortOptions) => {
                unset(filter, 'id');
                return filter
                    ? {
                          ...initialDefaultFilterObject,
                          ...filter,
                      }
                    : initialDefaultFilterObject;
            }),
        );
    }

    async updateTeamMemberSessionFilterOptions(
        sessionId: string,
        userId: string,
        filterOptions: Partial<TeamMemberFilters>,
    ): Promise<void> {
        await this.firestoreService.upsert(
            DBPathHelper.getUserManagementSessionFilterPathNew(sessionId, userId),
            filterOptions,
        );
    }

    async updateTeamMemberProjectFilterOptions(
        projectId: string,
        userId: string,
        filterOptions: Partial<TeamMemberFilters>,
    ): Promise<void> {
        await this.firestoreService.upsert(
            DBPathHelper.getUserManagementProjectFilterPath(projectId, userId),
            filterOptions,
        );
    }

    getTeamMemberSessionFilterOptions(sessionId: string, userId: string): Observable<TeamMemberFilters> {
        const filterPath = DBPathHelper.getUserManagementSessionFilterPathNew(sessionId, userId);

        return this.firestoreService.getDocument<TeamMemberFilters>(filterPath).pipe(
            map((filter: TeamMemberFilters) => {
                unset(filter, 'id');
                return filter
                    ? {
                          ...initialTeamMemberFilters,
                          ...filter,
                      }
                    : initialTeamMemberFilters;
            }),
        );
    }

    getProjectTeamMembersByFiltersRealtime(
        projectId: string,
        filters: TeamMemberProjectFilters,
        lastPage: number,
        stopLoading: boolean,
    ): Observable<{ users: TeamMember[]; lastPage: number; stopLoading: boolean }> {
        const sortDirection = this.firestoreService.getSortingDirection(filters.sortOrder);
        const sortDirectionAsc = this.firestoreService.getSortingDirection(SortOrders.Asc);
        const path = DBPathHelper.getTeamMemberPath(ParentType.Projects, projectId);

        return this.firestoreService
            .getDocumentsByQuery(path, (ref) => {
                let query = ref
                    .orderBy(TeamMembersFilterSortBy.Role, sortDirectionAsc)
                    .orderBy(filters.sortBy, sortDirection);

                query = this.addProjectTeamMembersFiltersForQuery(query, filters);
                return stopLoading ? query : query.limit(lazyLoadingTeamMembersLimit * lastPage);
            })
            .snapshotChanges()
            .pipe(
                filter((snapshot) => snapshot.every(({ payload }) => payload.doc.metadata.fromCache === false)),
                map((usersSnapshots) => {
                    // check if we need more users to load
                    const increment = stopLoading ? 0 : 1;
                    return {
                        stopLoading,
                        users: usersSnapshots.map(({ payload }) => new TeamMember(payload.doc.data(), payload.doc.id)),
                        lastPage: lastPage + increment,
                    };
                }),
            );
    }

    getSessionTeamMembersByFiltersRealtime(
        sessionId: string,
        filters: TeamMemberSessionFilters,
        lastPage: number,
        stopLoading: boolean,
    ): Observable<{ users: TeamMember[]; lastPage: number; stopLoading: boolean }> {
        try {
            const sortDirection = this.firestoreService.getSortingDirection(filters.sortOrder);
            const sortDirectionAsc = this.firestoreService.getSortingDirection(SortOrders.Asc);
            const path = DBPathHelper.getTeamMemberPath(ParentType.Sessions, sessionId);

            return this.firestoreService
                .getDocumentsByQuery(path, (ref) => {
                    //If filter with an inequality (lessThan, lessThanOrEqual, greaterThan, or greaterThanOrEqual)
                    //on field 'lastLogin' therefore 'lastLogin' is the first queryOrderedBy field,
                    let query
                        = (filters['lastLoginFrom'] !== null || filters['lastLoginTo'] !== null)
                        && filters.sortBy !== TeamMembersSessionFilterSortBy.LastLogin
                            ? ref
                                  .orderBy(TeamMembersSessionFilterSortBy.LastLogin, sortDirectionAsc)
                                  .orderBy(TeamMembersFilterSortBy.Role, sortDirectionAsc)
                                  .orderBy(filters.sortBy, sortDirection)
                            : (filters['lastLoginFrom'] !== null || filters['lastLoginTo'] !== null)
                              && filters.sortBy === TeamMembersSessionFilterSortBy.LastLogin
                            ? ref.orderBy(TeamMembersSessionFilterSortBy.LastLogin, sortDirection)
                            : ref
                                  .orderBy(TeamMembersFilterSortBy.Role, sortDirectionAsc)
                                  .orderBy(filters.sortBy, sortDirection);

                    query = this.addSessionTeamMembersFiltersForQuery(query, filters);
                    return stopLoading ? query : query.limit(lazyLoadingTeamMembersLimit * lastPage);
                })
                .snapshotChanges()
                .pipe(
                    filter((snapshot) => snapshot.every(({ payload }) => payload.doc.metadata.fromCache === false)),
                    map((usersSnapshots) => {
                        // check if we need more users to load
                        const increment = stopLoading ? 0 : 1;
                        return {
                            stopLoading,
                            users: usersSnapshots.map(
                                ({ payload }) => new TeamMember(payload.doc.data(), payload.doc.id),
                            ),
                            lastPage: lastPage + increment,
                        };
                    }),
                );
        } catch (e) {
            console.error('getSessionTeamMembersByFiltersRealtime Error', e);
        }
    }

    getProjectTeamMembersByFilters(projectId: string, filters: Partial<TeamMemberFilters>): Observable<TeamMember[]> {
        const path = DBPathHelper.getTeamMemberPath(ParentType.Projects, projectId);
        const sortDirectionAsc = this.firestoreService.getSortingDirection(SortOrders.Asc);

        return this.firestoreService
            .getDocumentsByQuery(path, (ref) => {
                let query = ref.orderBy(TeamMembersFilterSortBy.Role, sortDirectionAsc);

                query = this.addTeamMembersFiltersForQuery(query, filters);

                return query;
            })
            .snapshotChanges()
            .pipe(
                map((snapshot) => {
                    return snapshot.map(({ payload }) => new TeamMember(payload.doc.data(), payload.doc.id));
                }),
            );
    }

    getTeamMembersByFiltersRealtime(
        sessionId: string,
        filters: TeamMemberFilters,
        lastPage: number,
        stopLoading: boolean,
    ): Observable<{ users: TeamMember[]; lastPage: number; stopLoading: boolean }> {
        const sortDirection = this.firestoreService.getSortingDirection(filters.sortOrder);
        const sortDirectionAsc = this.firestoreService.getSortingDirection(SortOrders.Asc);
        const path = DBPathHelper.getSessionTeamMemberPathNew(ParentType.Sessions, sessionId); //check
        return this.firestoreService
            .getDocumentsByQuery(path, (ref) => {
                let query = ref
                    .orderBy(TeamMembersFilterSortBy.Role, sortDirectionAsc)
                    .orderBy(filters.sortBy, sortDirection);

                query = this.addTeamMembersFiltersForQuery(query, filters);

                // remove limitation after whole loading and load all - to manage any changes (deleting/adding)
                return stopLoading ? query : query.limit(lazyLoadingTeamMembersLimit * lastPage);
            })
            .snapshotChanges()
            .pipe(
                filter((snapshot) => snapshot.every(({ payload }) => payload.doc.metadata.fromCache === false)),
                map((usersSnapshots) => {
                    // check if we need more users to load
                    const increment = stopLoading ? 0 : 1;
                    return {
                        stopLoading,
                        users: usersSnapshots.map(({ payload }) => new TeamMember(payload.doc.data(), payload.doc.id)),
                        lastPage: lastPage + increment,
                    };
                }),
            );
    }

    private addProjectTeamMembersFiltersForQuery(
        query: FirebaseQuery,
        filters: Partial<TeamMemberProjectFilters>,
    ): FirebaseQuery {
        const filterFieldMap: Partial<Record<keyof TeamMemberProjectFilters, string>> = {
            roles: 'role',
        };

        Object.keys(filterFieldMap).forEach((field) => {
            if (filters[field]?.length) {
                query = query.where(filterFieldMap[field], 'in', filters[field]);
            }
        });

        return query;
    }

    private addSessionTeamMembersFiltersForQuery(
        query: FirebaseQuery,
        filters: Partial<TeamMemberSessionFilters>,
    ): FirebaseQuery {
        try {
            const filterFieldMap: Partial<Record<keyof TeamMemberSessionFilters, string>> = {
                roles: 'role',
                lastLoginFrom: 'lastLogin',
                lastLoginTo: 'lastLogin',
            };
            Object.keys(filterFieldMap).forEach((field) => {
                if (field === 'roles' && filters[field]?.length) {
                    query = query.where(filterFieldMap[field], 'in', filters[field]);
                } else if (field === 'lastLoginFrom' && filters[field] !== null) {
                    query = query.where(filterFieldMap[field], '>=', filters[field].toDate());
                } else if (field === 'lastLoginTo' && filters[field] !== null) {
                    query = query.where(filterFieldMap[field], '<=', filters[field].toDate());
                }
            });

            return query;
        } catch (e) {
            console.error('addSessionTeamMembersFiltersForQuery Error', e);
        }
    }

    private addTeamMembersFiltersForQuery(query: FirebaseQuery, filters: Partial<TeamMemberFilters>): FirebaseQuery {
        const filterFieldMap: Partial<Record<keyof TeamMemberFilters, string>> = {
            sessionRoles: 'role',
            projectRoles: 'role',
            statuses: 'status',
            types: 'type',
        };

        Object.keys(filterFieldMap).forEach((field) => {
            if (filters[field]?.length) {
                query = query.where(filterFieldMap[field], 'in', filters[field]);
            }
        });

        return query;
    }
}
