<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading; description: loaderText">
    <accenture-base-dialog [title]="'Edit collection'">
      <div class="dialog-body d-flex-column">
        <div class="collection-edit-container">
          <ng-container *ngIf="!vm.currentOptionToDisplay">
            <div class="collection-edit-image">
              <accenture-image-input
                [imagePlaceholder]="imageInputPlaceholder"
                [file]="vm.collectionImage"
                (fileUploaded)="setCollectionImage($event)"
                (fileDeleted)="deleteCollectionImage()"
              />
            </div>

            <form class="collection-edit-form" [formGroup]="collectionEditForm">
              <mat-form-field appearance="outline" class="collection-edit-form-name tt9-form-field no-margin width-100">
                <mat-label>Collection name</mat-label>
                <input
                  #inputName
                  matInput
                  required
                  formControlName="name"
                  aria-label="Collection name"
                  [maxLength]="characterLimitMedium"
                />
                <mat-hint
                  class="mat-hint-limit"
                  [class]="determineHintClass(inputName.value.length, characterLimitMedium)"
                  >{{ characterLimitText(inputName.value.length, characterLimitMedium) }}</mat-hint
                >
                <mat-error>{{ validationMessages.required }}</mat-error>
              </mat-form-field>

              <mat-form-field
                appearance="outline"
                class="collection-edit-form-color no-min-width mat-form-field tt9-form-field width-100 no-margin"
              >
                <mat-label>Collection color</mat-label>
                <mat-select
                  formControlName="color"
                  [disableOptionCentering]="true"
                  [panelClass]="['tt9-select-panel-custom']"
                >
                  <mat-select-trigger>
                    <div class="color">
                      <div class="color-circle" [style.background-color]="selectedColor.value"></div>
                      <span>{{ displayCollectionColorsName[selectedColor.value] }}</span>
                    </div>
                  </mat-select-trigger>
                  <mat-option *ngFor="let color of collectionColors" [value]="color">
                    <div class="color">
                      <div class="color-circle" [style.background-color]="color"></div>
                      <span>{{ displayCollectionColorsName[color] }}</span>
                    </div>
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field
                appearance="outline"
                class="collection-edit-form-description tt9-form-field no-label no-margin width-100 mb-24"
              >
                <mat-label>Description</mat-label>
                <input
                  #inputDescription
                  matInput
                  formControlName="description"
                  aria-label="Description"
                  [maxLength]="descriptionCharacterLimit"
                />
                <mat-hint
                  class="mat-hint-limit"
                  [class]="determineHintClass(inputDescription.value.length, descriptionCharacterLimit)"
                  >{{ characterLimitText(inputDescription.value.length, descriptionCharacterLimit) }}</mat-hint
                >
              </mat-form-field>
            </form>
          </ng-container>

          <accenture-collection-options />
        </div>

        <div class="collection-edit-footer d-flex-end">
          <button class="tt9-btn-default btn-icon" (click)="closeDialog(!!vm.currentOptionToDisplay)">
            <ng-container *ngIf="!!vm.currentOptionToDisplay; else optionsClosed">
              <accenture-icon>icon-chevron-left-small</accenture-icon>
              <span>Back</span>
            </ng-container>
            <ng-template #optionsClosed>
              <span>Cancel</span>
            </ng-template>
          </button>

          <button
            *ngIf="!vm.currentOptionToDisplay"
            class="tt9-btn ml-16"
            [disabled]="collectionEditForm.invalid"
            (click)="updateCollection()"
          >
            Save
          </button>
        </div>
      </div>
    </accenture-base-dialog>
  </ng-container>
</ng-container>
