import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FileType } from '@accenture/shared/data';

import { IconSize } from '../../models';
import { DialogService } from '../../services';

export interface ImageModalModel {
    file: FileType | undefined | null;
    description?: string;
    optionData?: {
        percentage: string;
        count: number;
        color: string;
    };
    type?: ImageModalType;
}

export enum ImageModalType {
    QuickPollOption = 'QUICK_POLL_OPTION',
}

@Component({
    selector: 'accenture-image-modal',
    templateUrl: './image-modal.component.html',
    styleUrls: ['./image-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageModalComponent implements OnInit {
    vm: ImageModalModel = this.data.data;

    iconSize = IconSize;
    imageModalType = ImageModalType;
    quickPollOptionImageHeight = '';
    @ViewChild('quickPollHeader') quickPollHeaderRef!: ElementRef;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogService: DialogService) {}

    get isQuickPollOptionImage(): boolean {
        return this.vm.type === this.imageModalType.QuickPollOption;
    }

    ngOnInit(): void {
        const selection = window.getSelection();
        selection?.removeAllRanges();
    }

    setQuickPollOptionImageHeight(): void {
        this.quickPollOptionImageHeight = `${
            window.innerHeight - this.quickPollHeaderRef.nativeElement.offsetHeight
        }px`;
    }

    onClose(): void {
        this.dialogService.dialog.closeAll();
    }
}
