import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { groupBy } from 'lodash';

import { validationErrors, validationMessages } from '@accenture/activity/shared/domain';
import { DataFlowForm, DataFlowFormService } from '@accenture/erp-deployment/shared/domain';
import {
    Activity,
    ActivityItem,
    ActivityItemType,
    ActivityType,
    AllTopicsValue,
    dataFlowActivitiesTypeOptions,
    DataFlowConnection,
    DataFlowFilterOptions,
    DataFlowFilters,
    DataFlowFiltersContentToMove,
    DataFlowFiltersOptionsData,
    DataFlowFiltersTitles,
    DataFlowFiltersTransformInto,
    DataFlowOptionsConnectionForm,
    DataFlowType,
    defaultValue,
    Dictionary,
    FormFieldName,
    ParentType,
    Question,
    Session,
    Table,
    TableColumn,
    ValueRange,
} from '@accenture/shared/data';
import { compareSequences, trackById, trackByValue } from '@accenture/shared/util';

@Component({
    selector: 'accenture-data-flow-form',
    templateUrl: './data-flow-form.component.html',
    styleUrls: ['./data-flow-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataFlowFormComponent implements OnChanges {
    @Input() parentType!: ParentType;
    @Input() sessions!: Session[];
    @Input() groupedActivityItems!: Dictionary<ActivityItem[]>;
    @Input() activities!: Activity[];
    @Input() connectionData!: DataFlowConnection;
    @Input() connections!: DataFlowConnection[];
    @Input() currentActivity?: Activity;
    @Input() currentActivityItem?: ActivityItem;
    @Input() currentSession?: Session;
    @Input() groupedColumns?: Dictionary<TableColumn[]>;
    @Output() dataSave = new EventEmitter<{
        dataFlowArray: DataFlowConnection[];
        generatedDestinationId?: string;
        destinationType?: ActivityType | string | null;
        destinationName?: string | null;
    }>();

    topicsIdByActivityId: Dictionary<{ all?: string[]; checked?: string[] }> = {};
    topicsNameById: Dictionary<string> = {};
    sourceSessions!: Session[];
    destinationSessions!: Session[];
    sourceActivities!: Activity[];
    destinationActivities!: Activity[];
    topicOptionsIds: string[] = [];
    connectionsForm!: DataFlowForm;
    noParametersActivitiesNames: string[] = []; // activities names without parameters for error
    noSelectedParametersNames: string[] = []; // activities names without selected parameters for error

    activitiesById: Dictionary<Activity[]> = {};
    existingConnectionsDestinations: string[] = [];
    existingActivityItemConnectionDestinationIds: string[] = [];
    filterParameterIdsMap: Dictionary<string> = {}; // current control value for top/bottom X by activityId {[activityId]: parameterId}
    showExistingDestination = true;
    filterParametersMap: Dictionary<ActivityItem[]> = {}; // all parameters for top/bottom X by activityId {[activityId]: [{parameter}]}
    lastCheckedValue!: string;
    parentCheckedValue!: string | null;

    formFieldName = FormFieldName;
    validationErrors = validationErrors;
    validationMessages = validationMessages;
    activityType = ActivityType;
    allTopicsValue = AllTopicsValue;
    trackById = trackById;
    trackByValue = trackByValue;
    dataFlowFiltersContentToMove = DataFlowFiltersContentToMove;
    dataFlowFiltersOptionsData = DataFlowFiltersOptionsData;
    dataFlowFilters = DataFlowFilters;
    dataFlowFiltersTransformInto = DataFlowFiltersTransformInto;
    dataFlowFiltersTitles = DataFlowFiltersTitles;

    constructor(private dataFlowFormService: DataFlowFormService) {
        this.dataFlowFormService.buildDataFlowForm();
        this.connectionsForm = this.dataFlowFormService.dataFlowForm;
    }

    get isCommentsOnly(): boolean {
        return this.getFormControlValue<DataFlowFilters>(FormFieldName.ContentToMove) === DataFlowFilters.Comments;
    }

    get isTopicsOnly(): boolean {
        return this.getFormControlValue<DataFlowFilters>(FormFieldName.ContentToMove) === DataFlowFilters.Topics;
    }

    get isTopicsWithResponses(): boolean {
        return (
            this.getFormControlValue<DataFlowFilters>(FormFieldName.ContentToMove)
            === DataFlowFilters.TopicsWithResponses
        );
    }

    get filtersOptionsData(): DataFlowFilterOptions[] {
        return this.dataFlowFiltersOptionsData[this.getFormControlValue<Activity[]>(FormFieldName.Source)[0]?.type];
    }

    get sourceOptionsVisible(): boolean {
        return (
            (!!(this.getFormControlValue<Activity[]>(FormFieldName.Source) || []).length
                // destination and destination not table
                && ((!!(this.getFormControlValue<Activity[]>(FormFieldName.Destination) || []).length
                    && !this.isDestinationTable)
                    // destination is Table and have selected table item
                    || !!(
                        this.isDestinationTable
                        && (this.getFormControlValue<ActivityItem[]>(FormFieldName.DestinationActivityItem) || []).length
                    )))
            || (!!(this.getFormControlValue<Activity[]>(FormFieldName.ActivityName) || []).length
                && !!(this.getFormControlValue<Activity[]>(FormFieldName.Source) || []).length)
        );
    }

    // check if destination type === Source and has topics
    get isTransformToFiltersShown(): boolean {
        const destination = this.getFormControlValue<Activity[]>(FormFieldName.Destination) || [];
        const source = this.getFormControlValue<Activity[]>(FormFieldName.Source) || [];

        const activityName = this.getFormControlValue<string>(FormFieldName.ActivityName);
        const activityType = this.getFormControlValue<ActivityType>(FormFieldName.ActivityType);

        return !!(
            (!!source[0]
                && !!destination[0]
                && (destination[0]?.type === ActivityType.Brainstorm
                    || (destination[0]?.type === ActivityType.Table && !this.isTopicsWithResponses)))
            || (activityName?.length && activityType === ActivityType.Brainstorm && !this.emptyDestinationTopics)
        );
    }

    get isVoteToTable(): boolean {
        const sourceType = this.getFormControlValue<Activity[]>(FormFieldName.Source)?.[0]?.type;
        return this.isDestinationTable && sourceType === ActivityType.Vote;
    }

    get destinationActivityTypes(): { name: string; value: ActivityType }[] {
        const type: ActivityType = this.getFormControlValue<Activity[]>(FormFieldName.Source)[0]?.type;
        return dataFlowActivitiesTypeOptions[type] || dataFlowActivitiesTypeOptions[defaultValue];
    }

    // check if one of source destination doesn't have topics
    get emptyDestinationTopics(): boolean {
        return (this.getFormControlValue<Activity[]>(FormFieldName.Destination) || []).some(
            activity => !this.groupedActivityItems[activity.id as string]?.length,
        );
    }

    get destinationType(): ActivityType {
        return this.getFormControlValue<Activity[]>(FormFieldName.Destination)
            ? this.getFormControlValue<Activity[]>(FormFieldName.Destination)[0]?.type
            : this.getFormControlValue<ActivityType>(FormFieldName.ActivityType);
    }

    get isResetAllButtonDisabled(): boolean {
        return !!this.sessions?.length
            ? !this.getFormControlValue(FormFieldName.SourceSession)
                  && !this.getFormControlValue(FormFieldName.DestinationSession)
            : !this.getFormControlValue<Activity[]>(FormFieldName.Source)?.[0]
                  && !this.getFormControlValue<Activity[]>(FormFieldName.Destination)?.[0];
    }

    get selectedSourceSession(): Session {
        return this.getFormControlValue<Session>(FormFieldName.SourceSession) || ({} as Session);
    }

    get selectedSourceActivities(): Activity[] {
        return this.getFormControlValue<Activity[]>(FormFieldName.Source) || [];
    }

    get selectedDestinationActivities(): Activity[] {
        return this.getFormControlValue<Activity[]>(FormFieldName.Destination) || [];
    }

    // activity items for activity form part (not options)
    get destinationActivityItems(): ActivityItem[] {
        const destinationActivity = this.selectedDestinationActivities?.[0]?.id;

        return destinationActivity ? this.groupedActivityItems[destinationActivity] : [];
    }

    get transformIntoTitle(): string {
        return this.isDestinationTable ? 'Data will become' : 'Transform into';
    }

    get isDestinationTable(): boolean {
        return this.destinationType === ActivityType.Table;
    }

    isDisabledTooltip(elem: HTMLElement): boolean {
        return elem.clientWidth ? elem.scrollWidth <= elem.clientWidth : false;
    }

    // show TopX and BottomX filters for Vote activity
    showVoteAdditionalFilters(parameter: boolean): boolean {
        return !parameter || (parameter && !!Object.keys(this.filterParametersMap).length);
    }

    parameterDisabledState(activityId: string, activityItemId: string): boolean {
        return (
            !!this.filterParameterIdsMap?.[activityId]?.length
            && this.filterParameterIdsMap?.[activityId] !== activityItemId
        );
    }

    columnDisabledState(columnId: string): boolean {
        const selectedColumn = this.getFormControlValue<TableColumn[]>(FormFieldName.DestinationColumn)?.[0];

        return !!selectedColumn && selectedColumn.id !== columnId;
    }

    tableDisabledState(tableId: string): boolean {
        const selectedTable = this.getFormControlValue<ActivityItem[]>(FormFieldName.DestinationActivityItem)?.[0];

        return (
            (!!selectedTable && selectedTable.id !== tableId)
            || this.existingActivityItemConnectionDestinationIds.includes(tableId)
        );
    }

    isEqualActivityTypes(formField: string, activityType: ActivityType | ActivityType[]): boolean {
        return this.dataFlowFormService.isEqualActivityTypes(formField, activityType);
    }

    isActivitySelectDisabled(hasSessions: boolean, fieldName: FormFieldName): boolean {
        return !this.getFormControlValue(fieldName) && hasSessions;
    }

    showActivityParameter(parameter: boolean): boolean {
        return (
            (parameter && this.isEqualActivityTypes(FormFieldName.Source, ActivityType.Brainstorm))
            || (parameter && !!Object.keys(this.filterParametersMap).length)
        );
    }

    getFormControlValue<T>(type: string): T {
        return this.dataFlowFormService.getFormControlValue<T>(type);
    }

    getFormControl(type: string): AbstractControl {
        return this.dataFlowFormService.getFormControl(type);
    }

    isSessionInDropdownDisabled(sessionSequence: string): boolean {
        return this.selectedSourceSession.sequence
            ? compareSequences(this.selectedSourceSession.sequence, sessionSequence) > 0
            : false;
    }

    getColumnsBySelectedActivityItemValue(): TableColumn[] {
        const selectedActivityItemId = this.getFormControlValue<Table[]>(FormFieldName.DestinationActivityItem)?.[0].id;

        return (selectedActivityItemId && this.groupedColumns && this.groupedColumns[selectedActivityItemId]) || [];
    }

    getActivitiesById(ids: string[]): Activity[] {
        return (ids || []).reduce((acc, id) => {
            const activity = this.getActivityById(id);
            acc.push(activity);
            return acc;
        }, [] as Activity[]);
    }

    getActivityById(id: string): Activity {
        return this.activitiesById[id] ? this.activitiesById[id][0] : ({} as Activity);
    }

    getActivityName(id: string): string {
        return this.getActivityById(id)?.name;
    }

    getSourceActivitiesData(): Activity[] {
        const selectedSourceSession = this.getFormControlValue<Session>(FormFieldName.SourceSession) || null;
        const activities = selectedSourceSession
            ? this.activities.filter(
                  activity => activity.sessionId === selectedSourceSession.id && activity.type !== ActivityType.Table,
              )
            : this.activities;

        return [...activities];
    }

    getDestinationActivitiesData(): Activity[] {
        const selectedDestinationSession = this.getFormControlValue<Session>(FormFieldName.DestinationSession) || null;
        const filteredDestinationActivities = [...this.activities];
        const activities = selectedDestinationSession
            ? filteredDestinationActivities.filter(activity => activity.sessionId === selectedDestinationSession.id)
            : filteredDestinationActivities;

        return [...activities];
    }

    getActivityParameterTooltip(): string {
        const activityIds = Object.keys(this.filterParameterIdsMap || {}) || [];
        const activityItems = activityIds.map(activityId =>
            this.filterParametersMap[activityId].find(
                activityItem => activityItem.id === this.filterParameterIdsMap[activityId],
            ),
        );
        const activityItemsLabels: string[] = activityItems?.map(
            (activityItem: Question) => activityItem?.label?.default,
        );

        return activityItemsLabels?.join('\n');
    }

    // check if destinationId !== sourceId
    // check if destination - after source
    isDestinationMatch(item: Activity): boolean {
        return this.selectedSourceActivities.some(
            sourceItem =>
                item.id === sourceItem.id
                || (item.sessionId === sourceItem.sessionId && sourceItem.sequence
                    ? compareSequences(sourceItem.sequence, item.sequence) > 0
                    : false),
        );
    }

    // check if not the same and have correct sequences
    checkActivityIdsAvailability(item: Activity): boolean {
        const hasMatchingType = this.selectedSourceActivities[0] && item.type !== this.selectedSourceActivities[0].type;
        return (
            hasMatchingType
            || this.selectedDestinationActivities.some(
                destinationItem =>
                    item.id === destinationItem.id
                    || (item.sessionId === destinationItem.sessionId && destinationItem.sequence
                        ? compareSequences(item.sequence, destinationItem.sequence) > 0
                        : false),
            )
        );
    }

    isOneDestinationAllow(item: Activity): boolean {
        return (
            !!this.selectedDestinationActivities.length
            && ((this.isDestinationTable
                && !this.selectedDestinationActivities.find(destinationItem => item.id === destinationItem.id))
                || item.type !== this.selectedDestinationActivities[0].type)
        );
    }

    hasRequiredError(formFieldName: FormFieldName): boolean {
        return this.dataFlowFormService.getFormControl(formFieldName)?.hasError(validationErrors.required);
    }

    getTopics(activityId: string): string[] | string {
        const topics = this.getTopicsChecked(activityId);

        return topics.length === this.topicsIdByActivityId[activityId]?.all?.length ? this.allTopicsValue : topics;
    }

    getTopicsChecked(activityId: string): string[] {
        return this.topicsIdByActivityId[activityId]?.checked || [];
    }

    hasDestinationActivityConnection(id: string, type: ActivityType): boolean {
        if (type === ActivityType.Table) {
            return false;
        }

        return this.existingConnectionsDestinations.includes(id);
    }

    resetForm(): void {
        this.dataFlowFormService.resetForm();

        if (!!this.currentActivity) {
            this.setCurrentTemplateDestinationActivity(true);
        }

        this.setActualActivitiesData(true);
    }

    setActualSessionsData(formFieldName?: FormFieldName): void {
        if (formFieldName) {
            const resetFieldName
                = formFieldName === FormFieldName.DestinationSession ? FormFieldName.Destination : FormFieldName.Source;
            this.dataFlowFormService.resetControlValue(resetFieldName);
        }

        const selectedSourceSession = this.getFormControlValue<Session>(FormFieldName.SourceSession) || null;
        const selectedDestinationSession = this.getFormControlValue<Session>(FormFieldName.DestinationSession) || null;

        if (Object.keys(selectedSourceSession || {}).length && selectedDestinationSession) {
            const isInvalidSequences
                = compareSequences(selectedSourceSession.sequence, selectedDestinationSession.sequence) > 0;

            if (isInvalidSequences) {
                const isSourcePartForReset = formFieldName !== FormFieldName.SourceSession;

                this.dataFlowFormService.resetControlValue(
                    isSourcePartForReset ? FormFieldName.SourceSession : FormFieldName.DestinationSession,
                );
                this.dataFlowFormService.resetControlValue(
                    isSourcePartForReset ? FormFieldName.Source : FormFieldName.Destination,
                );
            }
        }

        if (selectedSourceSession) {
            this.destinationSessions = [...this.sessions];
            this.setActualActivitiesData(true);
            const filteredActivities = this.sourceActivities.filter(
                (activity: Activity) => activity.sessionId === selectedSourceSession?.id,
            );

            this.sourceActivities = filteredActivities;
        }

        if (selectedDestinationSession) {
            this.sourceSessions = [...this.sessions];
            this.setActualActivitiesData(true);
            this.destinationActivities = this.destinationActivities.filter(
                (activity: Activity) => activity.sessionId === selectedDestinationSession?.id,
            );
        }

        if (this.currentActivity) {
            this.sourceSessions = this.sessions.filter(
                (session: Session) => compareSequences(selectedDestinationSession.sequence, session.sequence) >= 0,
            );
        }

        this.setActualActivitiesData(true);
    }

    setInitialParametersData(): void {
        // activity item types with numeric answers - need for top_x|bottom_x filters
        const activityItemTypes = [
            ActivityItemType.Numeric,
            ActivityItemType.TopX,
            ActivityItemType.Slider,
            ActivityItemType.StarVoting,
        ];
        this.filterParametersMap = {};
        this.dataFlowFormService.updateFilterParametersMap(this.filterParametersMap);
        this.noParametersActivitiesNames = [];
        this.dataFlowFormService.updateNoParametersActivitiesNames(this.noParametersActivitiesNames);

        (this.getFormControlValue<Activity[]>(FormFieldName.Source) || []).forEach(activity => {
            if (!activity.id) {
                return;
            }
            if (!this.groupedActivityItems[activity.id]) {
                this.noParametersActivitiesNames.push(activity.name);
                this.dataFlowFormService.updateNoParametersActivitiesNames(this.noParametersActivitiesNames);
                return;
            }

            const filteredItems = (this.groupedActivityItems[activity.id] || []).filter((activityItem: ActivityItem) =>
                activityItemTypes.includes(activityItem.type),
            );

            if (filteredItems.length) {
                this.filterParametersMap[activity.id] = (this.groupedActivityItems[activity.id] || []).filter(
                    (activityItem: ActivityItem) => activityItemTypes.includes(activityItem.type),
                );
                this.dataFlowFormService.updateFilterParametersMap(this.filterParametersMap);
            } else {
                this.noParametersActivitiesNames.push(activity.name);
                this.dataFlowFormService.updateNoParametersActivitiesNames(this.noParametersActivitiesNames);
            }
        });

        this.setNoSelectedActivitiesName();
        this.dataFlowFormService.updateValueAndValidity(FormFieldName.Filter);
    }

    changeTransformInto(value: boolean): void {
        if (!this.isDestinationTable) {
            return;
        }

        // set the first column as the default value
        this.setDefaultDestinationColumnValue();

        this.dataFlowFormService.updateColumnsValidators(value);
    }

    setTopicOptions(): void {
        const controlValue = this.getFormControlValue<Activity[]>(FormFieldName.Source)?.length
            ? this.getFormControlValue<Activity[]>(FormFieldName.Source)
            : this.getActivitiesById(this.connectionData?.sourceIds);

        this.dataFlowFormService.clearValidators(FormFieldName.Topics);

        this.topicsNameById = {};
        this.topicOptionsIds = [];

        for (const activity of controlValue) {
            if (!activity?.id || !this.groupedActivityItems[activity.id]) {
                return;
            }

            this.topicOptionsIds.push(activity.id);
            this.topicsIdByActivityId[activity.id] = {
                all: this.getTopicsIdByActivityId(activity.id),
                checked: this.getTopicsIdByActivityId(activity.id),
            };
        }

        if (!this.topicOptionsIds.length) {
            this.dataFlowFormService.setTopicsValue([]);
            return;
        }

        this.dataFlowFormService.setTopicsValidators(this.topicOptionsIds, this.topicsIdByActivityId);
        this.setAllTopicsChecked();
    }

    setAllTopicsChecked(): void {
        if (!this.topicOptionsIds.length) {
            return;
        }

        const value: Set<string | undefined> = new Set();
        value.add(this.allTopicsValue); // add 'ALL' value

        this.topicOptionsIds.forEach(id => {
            value.add(id);

            if (this.groupedActivityItems[id]) {
                this.groupedActivityItems[id].forEach(activity => value.add(activity.id));
            }

            setTimeout(() => this.getCheckedTopicsItemsGroup(id));
        });

        this.dataFlowFormService.setTopicsValue(value);
    }

    getCheckedTopicsItemsGroup(activityId: string): void {
        const topicsControl = this.getFormControl(FormFieldName.Topics);
        const controlValue = topicsControl?.value || [];
        const allSelectedValues: string[] | undefined = this.topicsIdByActivityId[activityId]?.all;

        if (!allSelectedValues) {
            return;
        }

        this.topicsIdByActivityId[activityId].checked = allSelectedValues.filter(id => controlValue?.includes(id));

        this.dataFlowFormService.updateValueAndValidity(FormFieldName.Topics);
    }

    changeTopicSelection(data: string[] | string): void {
        const value: Set<string> = new Set(data);

        setTimeout(() => {
            const isLastCheckedValueAll = this.lastCheckedValue === this.allTopicsValue;

            if (isLastCheckedValueAll && !value?.has(this.lastCheckedValue)) {
                this.uncheckedAllTopics();

                return;
            }

            if (isLastCheckedValueAll && value?.has(this.lastCheckedValue)) {
                this.setAllTopicsChecked();

                return;
            }

            this.setCheckedTopicValues(value);

            this.getCheckedTopicsItemsGroup(this.parentCheckedValue || this.lastCheckedValue);
        });
    }

    setLastCheckedTopicValue(id: string, parentId?: string): void {
        this.lastCheckedValue = id;
        this.parentCheckedValue = parentId || null;
    }

    removeTag(id: string, type: string): void {
        const formControl = this.getFormControl(type);
        const values = formControl?.value.filter((item: Activity) => item.id !== id);
        formControl.setValue(values);

        if (type === FormFieldName.DestinationColumn) {
            formControl.markAsTouched();
            return;
        }

        this.resetTableSelectedTags(type);
        this.setActualActivitiesData(true);
    }

    resetTableSelectedTags(type: string): void {
        if (type === FormFieldName.Destination) {
            this.dataFlowFormService.resetColumnStateAndValidation();
            this.dataFlowFormService.resetDestinationActivityItemsStateAndValidation();
        }

        if (type === FormFieldName.DestinationActivityItem) {
            this.dataFlowFormService.resetColumnStateAndValidation();
        }
    }

    removeTopicTag(activityId: string, topicId: string): void {
        this.setLastCheckedTopicValue(topicId, activityId);
        const control = this.getFormControlValue<string[]>(FormFieldName.Topics).filter(option => option !== topicId);

        this.changeTopicSelection(control);
    }

    setActualActivitiesData(updateTopics?: boolean): void {
        const sourceActivityType = this.dataFlowFormService.getSourceActivityType();

        this.resetOptionsToDefaultState();
        this.sourceActivities = this.getSourceActivitiesData();
        this.destinationActivities = this.getDestinationActivitiesData();

        this.setInitialParametersData();

        if (updateTopics && sourceActivityType === ActivityType.Brainstorm) {
            this.setTopicOptions();
        }

        if (this.isDestinationTable) {
            // set the first table as the default value
            this.setDefaultDestinationActivityItemValue();
        }
    }

    onContentToMoveChange(value: DataFlowFilters): void {
        if (!this.isDestinationTable) {
            return;
        }

        this.dataFlowFormService.resetColumnStateAndValidation();
        this.dataFlowFormService.resetTransformInto();

        if (value === DataFlowFilters.Topics) {
            this.dataFlowFormService.resetFilterOptionToDefaultState();
        }
    }

    onFilterChange(value: DataFlowFilters): void {
        let option = '';

        if (value === DataFlowFilters.Bottom) {
            option = FormFieldName.BottomParameter;
        }

        if (value === DataFlowFilters.Top) {
            option = FormFieldName.TopParameter;
        }

        if (!option.length) {
            this.dataFlowFormService.updateValueAndValidity(FormFieldName.Filter);

            return;
        }

        const initialData = [];
        const optionToReset
            = option === FormFieldName.BottomParameter ? FormFieldName.TopParameter : FormFieldName.BottomParameter;

        if (optionToReset) {
            this.dataFlowFormService.setControlValue(FormFieldName.TopX, [ValueRange.ValueRange5]);
            this.dataFlowFormService.setControlValue(FormFieldName.BottomX, [ValueRange.ValueRange5]);
        }

        this.filterParameterIdsMap = {};
        this.dataFlowFormService.setControlValue(optionToReset, []);

        for (const id in this.filterParametersMap) {
            initialData.push(this.filterParametersMap[id][0].id);
            this.filterParameterIdsMap[id] = this.filterParametersMap[id][0].id as string;
        }

        this.dataFlowFormService.updateFilterParameterIdsMap(this.filterParameterIdsMap);
        this.dataFlowFormService.updateFilterParametersMap(this.filterParametersMap);
        this.dataFlowFormService.setControlValue(option, initialData);
        this.setNoSelectedActivitiesName();

        this.dataFlowFormService.updateValueAndValidity(FormFieldName.Filter);
    }

    setActualParametersData(event: Event, activityId: string, activityItemId: string): void {
        const currentParameterId = this.filterParameterIdsMap[activityId];

        if (currentParameterId?.length && currentParameterId !== activityItemId) {
            // fix for disable state inside select (for parameters multi select)
            event.preventDefault();
        } else {
            if (currentParameterId === activityItemId) {
                // remove parameterId (unchecked)
                delete this.filterParameterIdsMap[activityId];
            } else {
                // set checked parameter id
                this.filterParameterIdsMap[activityId] = activityItemId;
            }

            this.dataFlowFormService.updateFilterParameterIdsMap(this.filterParameterIdsMap);
        }

        this.setNoSelectedActivitiesName();

        this.dataFlowFormService.updateValueAndValidity(FormFieldName.Filter);
    }

    changeDestination(): void {
        const activity = this.getFormControl(FormFieldName.ActivityName);
        const destination = this.getFormControl(FormFieldName.Destination);
        this.showExistingDestination = !this.showExistingDestination;

        this.dataFlowFormService.updateValidators(activity, !this.showExistingDestination);
        this.dataFlowFormService.updateValidators(destination, this.showExistingDestination);
    }

    resetOptionsToDefaultState(): void {
        this.dataFlowFormService.resetFilterOptionToDefaultState();
        const contentToMoveState = this.isDestinationTable ? DataFlowFilters.Topics : DataFlowFilters.Responses;
        this.dataFlowFormService.resetContentToMoveToDefaultState(contentToMoveState);
        this.dataFlowFormService.resetColumnStateAndValidation();
        this.dataFlowFormService.resetTransformInto();

        if (!this.selectedDestinationActivities.length) {
            this.dataFlowFormService.resetDestinationActivityItemsStateAndValidation();
        }

        if (this.isDestinationTable) {
            this.dataFlowFormService.updateDestinationActivityItemValidators(true);
        }
    }

    async saveData(): Promise<void> {
        const source = this.getFormControlValue<Activity[]>(FormFieldName.Source);
        const destination = this.getFormControlValue<Activity[]>(FormFieldName.Destination) || [];
        const dataFlowArray: DataFlowConnection[] = [];
        let generatedDestinationId = null;
        let destinationType = null;
        let destinationName = null;

        // 'Create new activity' block
        if (!this.showExistingDestination) {
            destinationType = this.getFormControlValue<ActivityType>(FormFieldName.ActivityType).toString();
            destinationName = this.getFormControlValue<string>(FormFieldName.ActivityName).toString();
            generatedDestinationId = this.dataFlowFormService.getDestinationId();

            // add this new activity to destination array
            destination.push({ id: generatedDestinationId, type: destinationType, name: destinationName } as Activity);
        }

        // create data flow connection for each of destination
        destination.forEach((destinationActivity: Activity) => {
            const data = this.getDataForSave(source, destinationActivity);
            if (data) {
                dataFlowArray.push(data);
            }
        });

        this.dataSave.emit({
            dataFlowArray,
            generatedDestinationId: generatedDestinationId as string,
            destinationType,
            destinationName,
        });
    }

    isTransformIntoOptionDisabled(option: { value: boolean; title: string }): boolean {
        return (
            !this.getColumnsBySelectedActivityItemValue()?.length
            && option?.title === DataFlowFiltersTitles[DataFlowFilters.ColumnResponses]
        );
    }

    private getDataForSave(sourceActivities: Activity[], destinationActivity: Activity): DataFlowConnection | false {
        const options = this.dataFlowFormService.dataFlowForm.value.options;
        const sourceIds = sourceActivities.map(activity => activity.id);
        const activities = {
            sourceIds,
            sourceSessionId: sourceActivities[0].sessionId || null,
            destinationSessionId: destinationActivity.sessionId || null,
            destinationId: destinationActivity.id,
            sourceType: sourceActivities[0].type,
            destinationType: destinationActivity.type,
            destinationName: destinationActivity.name,
        };

        const topicsByActivities = sourceActivities.reduce((acc, activity) => {
            acc[activity.id as string] = this.getTopics(activity.id as string); // if checked all topics from current activity - 'all'
            return acc;
        }, {} as { [id: string]: string[] | string });
        const topics = {
            topics: topicsByActivities,
        };

        let contentToMove: { moveResponses?: boolean; moveComments?: boolean; moveTopics?: boolean } = {
            moveResponses:
                options?.contentToMove
                && [
                    DataFlowFilters.Responses,
                    DataFlowFilters.ResponsesAndComments,
                    DataFlowFilters.TopicsWithResponses,
                ].includes(options.contentToMove),
        };

        let activityItemData = {};
        if (this.isDestinationTable) {
            const activityItem = this.getFormControlValue<Table[]>(FormFieldName.DestinationActivityItem)?.[0];
            if (activityItem) {
                activityItemData = {
                    destinationActivityItem: {
                        id: activityItem.id,
                        title: activityItem.title,
                    },
                };
            }

            const column = this.getFormControlValue<TableColumn[]>(FormFieldName.DestinationColumn)?.[0];
            if (column) {
                activityItemData = {
                    ...activityItemData,
                    destinationColumn: {
                        id: column.id,
                        title: column.title,
                    },
                };
            }

            contentToMove = {
                ...contentToMove,
                moveTopics:
                    options?.contentToMove
                    && [DataFlowFilters.Topics, DataFlowFilters.TopicsWithResponses].includes(options.contentToMove),
            };
        } else {
            contentToMove = {
                ...contentToMove,
                moveComments:
                    options?.contentToMove
                    && [DataFlowFilters.Comments, DataFlowFilters.ResponsesAndComments].includes(options.contentToMove),
            };
        }

        const filterForResponses = {
            filterProperty: options?.filter || null, // 'top_x' | 'bottom_x' | 'bookmarked' | 'not_bookmarked'
            filterValue: (options?.filter && options[options.filter as 'top_x' | 'bottom_x']) || null, // number for 'top_x' | 'bottom_x'
            filterParameterByActivityId:
                options?.filter && this.dataFlowFormService.isVoteSourceAndTopBottomXFilter(options.filter)
                    ? this.filterParameterIdsMap
                    : null,
        };

        const transformInto = {
            transformToResponses: options?.transformIntoResponses ? options.transformIntoResponses : false,
        };
        const sourceType = sourceActivities[0].type;

        switch (`${sourceType}_${destinationActivity.type}`) {
            case DataFlowType.BrainstormToBrainstorm:
                return {
                    ...activities,
                    ...topics,
                    ...transformInto,
                    ...filterForResponses,
                    ...contentToMove,
                } as unknown as DataFlowConnection;

            case DataFlowType.BrainstormToVote:
                return {
                    ...activities,
                    ...topics,
                    ...filterForResponses,
                    ...contentToMove,
                } as unknown as DataFlowConnection;

            case DataFlowType.VoteToVote:
                return { ...activities, ...filterForResponses } as unknown as DataFlowConnection;

            case DataFlowType.VoteToBrainstorm:
                return { ...activities, ...transformInto, ...filterForResponses } as unknown as DataFlowConnection;

            case DataFlowType.BrainstormToTable:
                return {
                    ...activities,
                    ...topics,
                    ...transformInto,
                    ...contentToMove,
                    ...filterForResponses,
                    ...activityItemData,
                } as unknown as DataFlowConnection;
            case DataFlowType.VoteToTable:
                return {
                    ...activities,
                    ...transformInto,
                    ...filterForResponses,
                    ...activityItemData,
                } as unknown as DataFlowConnection;
            default:
                return false;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!!this.connectionData) {
            this.setInitialFormValue();
        }

        if (!!this.currentActivity) {
            this.setCurrentTemplateDestinationActivity();
        }

        if (!!this.currentSession) {
            this.setCurrentSourceDestinationSession();
        }

        if (changes['activities']) {
            this.sourceActivities = [...this.activities];
            this.destinationActivities = [...this.activities];
            this.activitiesById = groupBy(this.activities, 'id');
            this.setActualActivitiesData();
        }

        if (changes['connections'] && !!this.connections?.length) {
            this.existingConnectionsDestinations = [];
            this.existingActivityItemConnectionDestinationIds = [];

            this.connections.forEach(connection => {
                // we need value of the current for displaying
                if (connection.destinationId !== this.connectionData?.destinationId) {
                    const destinationId: string = (connection as DataFlowConnection)?.destinationId as string;
                    this.existingConnectionsDestinations.push(destinationId);
                }

                if (connection.destinationActivityItem && connection.destinationActivityItem.id) {
                    this.existingActivityItemConnectionDestinationIds.push(connection.destinationActivityItem.id);
                }
            });

            this.setActualActivitiesData();
        }

        if (changes['sessions'] && !!this.sessions?.length) {
            this.sourceSessions = [...this.sessions];
            this.destinationSessions = [...this.sessions];

            const destinationSession = this.getFormControl(FormFieldName.DestinationSession);
            const sourceSession = this.getFormControl(FormFieldName.SourceSession);
            this.dataFlowFormService.updateValidators(destinationSession, true);
            this.dataFlowFormService.updateValidators(sourceSession, true);
            this.setActualSessionsData();
        }
    }

    // set the first table as the default value
    private setDefaultDestinationActivityItemValue(): void {
        const destinationActivityItemValue
            = this.getFormControlValue<Activity[]>(FormFieldName.DestinationActivityItem) || null;

        if (this.selectedDestinationActivities.length && !destinationActivityItemValue) {
            const destination = this.getFormControlValue<Activity[]>(FormFieldName.Destination) || null;
            const destinationActivityId = destination?.[0]?.id as string;
            let destinationActivityItems = this.groupedActivityItems?.[destinationActivityId];

            if (this.existingActivityItemConnectionDestinationIds.length) {
                destinationActivityItems = destinationActivityItems?.filter(
                    activity => !this.existingActivityItemConnectionDestinationIds.includes(activity?.id as string),
                );
            }

            const destinationActivityItem = destinationActivityItems?.[0];
            if (destinationActivityItem) {
                this.dataFlowFormService.setControlValue(FormFieldName.DestinationActivityItem, [
                    destinationActivityItem,
                ]);
                this.dataFlowFormService.updateValueAndValidity(FormFieldName.DestinationActivityItem);
            }
        }
    }

    // set the first column as the default value
    private setDefaultDestinationColumnValue(): void {
        const filterColumnResponses = this.getFormControlValue<Activity[]>(DataFlowFilters.ColumnResponses);
        const destinationColumnValue = this.getFormControlValue<Session>(FormFieldName.DestinationColumn) || null;

        if (!destinationColumnValue || (!filterColumnResponses && destinationColumnValue)) {
            const firstColumn = this.getColumnsBySelectedActivityItemValue()?.[0];
            if (firstColumn) {
                this.dataFlowFormService.setControlValue(FormFieldName.DestinationColumn, [firstColumn]);
                this.dataFlowFormService.updateValueAndValidity(FormFieldName.DestinationColumn);
            }
        }
    }

    private getTopicsIdByActivityId(activityId: string): string[] {
        return this.groupedActivityItems[activityId]?.reduce((ids, topic) => {
            this.topicsNameById[topic.id as string] = (topic as Question)?.label?.['default'];
            const topicId: string = (topic as Question).id;
            ids.push(topicId);

            return ids;
        }, [] as string[]);
    }

    private setInitialSessionsValue(
        sourceSessionId: string | undefined,
        destinationSessionId: string | undefined,
    ): void {
        const sourceSession = this.sessions.find((session: Session) => session.id === sourceSessionId) || null;
        const destinationSession
            = this.sessions.find((session: Session) => session.id === destinationSessionId) || null;

        this.dataFlowFormService.updateFormData({
            activities: {
                sourceSessionId: sourceSession,
                destinationSessionId: destinationSession,
            },
        });

        this.setActualSessionsData();
    }

    private setInitialFormValue(): void {
        let source: Activity[];
        let destination: Activity;

        setTimeout(() => {
            source = this.getActivitiesById(this.connectionData.sourceIds);
            destination = this.getActivityById(this.connectionData.destinationId);
            let data: {
                activities: {
                    source: Activity[];
                    destination: Activity[];
                    destinationActivityItem?: Partial<ActivityItem>[];
                };
            } = {
                activities: {
                    source,
                    destination: destination ? [destination] : [],
                },
            };

            if (this.connectionData.destinationType === ActivityType.Table) {
                data = {
                    activities: {
                        ...data.activities,
                        destinationActivityItem: this.connectionData.destinationActivityItem
                            ? [this.connectionData.destinationActivityItem]
                            : [],
                    },
                };
            }

            this.dataFlowFormService.updateFormData(data);

            this.setActualActivitiesData();

            if (source[0]?.type === ActivityType.Brainstorm) {
                this.setTopicOptions();
            }

            if (this.sessions.length) {
                this.setInitialSessionsValue(
                    this.connectionData.sourceSessionId,
                    this.connectionData.destinationSessionId,
                );
            }

            if (this.parentType === ParentType.Templates) {
                this.dataFlowFormService.disabledFields([
                    FormFieldName.SourceSession,
                    FormFieldName.DestinationSession,
                ]);
            }

            this.setInitialOptionsFormValue();
        });
    }

    private setInitialOptionsFormValue(): void {
        const topicsIdsByActivityIds: Dictionary<string[]> = {};
        const sourceIds = this.connectionData.sourceIds || [];
        let columns;

        sourceIds.forEach(
            (sourceId: string) => (topicsIdsByActivityIds[sourceId] = this.getTopicsIdByActivityId(sourceId) || []),
        );

        if (this.connectionData?.destinationColumn && this.connectionData.destinationActivityItem?.id) {
            columns = this.groupedColumns?.[this.connectionData.destinationActivityItem?.id] as Partial<TableColumn>[];
        }

        const data = {
            options: new DataFlowOptionsConnectionForm(this.connectionData, topicsIdsByActivityIds, columns),
        };

        this.dataFlowFormService.updateFormData(data);

        for (const sourceId of sourceIds) {
            this.getCheckedTopicsItemsGroup(sourceId);
        }

        if (this.connectionData.sourceType === ActivityType.Brainstorm) {
            const topics = this.getFormControlValue<string[]>(FormFieldName.Topics) || [];
            const checkedTopics = topics.length ? this.getCheckedActivityTopicStatus(new Set(topics)) : topics;

            this.dataFlowFormService.setTopicsValue(checkedTopics);
        }

        this.setParametersValue();
    }

    private setParametersValue(): void {
        const activityId = Object.keys(this.filterParametersMap)[0];
        if (!activityId) {
            return;
        }

        this.filterParameterIdsMap = this.connectionData.filterParameterByActivityId as Dictionary<string>;
        this.dataFlowFormService.updateFilterParameterIdsMap(this.filterParameterIdsMap);
        this.dataFlowFormService.updateValueAndValidity(FormFieldName.Filter);
    }

    private setCheckedTopicValues(value: Set<string>): void {
        let topics = value;
        const parentActivity = this.groupedActivityItems[this.lastCheckedValue];

        // if we have unchecked value
        if (!topics?.has(this.lastCheckedValue) && this.lastCheckedValue) {
            // if it's parent element - remove child
            topics = this.getUncheckedChildTopics(topics, parentActivity);
        } else {
            //if it's parent element - add child
            if (parentActivity) {
                parentActivity.forEach((activity: ActivityItem) => {
                    if (activity.id) {
                        topics.add(activity.id);
                    }
                });
            } else {
                // if all children checked - add parent
                const isAllTopicsChecked = this.topicsIdByActivityId[this.parentCheckedValue as string]?.all?.every(
                    (id: string) => topics.has(id),
                );

                if (isAllTopicsChecked && this.parentCheckedValue) {
                    topics.add(this.parentCheckedValue);
                }
            }
        }

        topics = this.getCheckedActivityTopicStatus(topics);
        this.dataFlowFormService.setTopicsValue(topics);
    }

    private getCheckedActivityTopicStatus(value: Set<string>): Set<string> {
        const topics = value;
        const allParentsIds = this.topicOptionsIds.filter((id: string) => this.groupedActivityItems[id]);
        const isAllParentsChecked = allParentsIds.every(id => topics.has(id));

        // if all parent checked - add 'ALL'
        if (isAllParentsChecked) {
            topics.add(this.allTopicsValue);
            return topics;
        }

        if (topics.has(this.allTopicsValue)) {
            topics.delete(this.allTopicsValue);
        }

        return topics;
    }

    private getUncheckedChildTopics(value: Set<string>, parentActivity: ActivityItem[]): Set<string> {
        const topics = value;

        if (parentActivity) {
            parentActivity.forEach(activity => topics.delete(activity.id as string));
        }

        if (!parentActivity && this.parentCheckedValue) {
            topics.delete(this.parentCheckedValue);
        }

        return topics;
    }

    // need for displaying validation errors from filters
    private setNoSelectedActivitiesName(): void {
        this.noSelectedParametersNames = [];

        (this.getFormControlValue<Activity[]>(FormFieldName.Source) || []).forEach(activity => {
            if (
                activity.id
                && !Object.keys(this.filterParameterIdsMap || {}).includes(activity.id)
                && Object.keys(this.filterParametersMap || {}).includes(activity.id)
            ) {
                this.noSelectedParametersNames.push(activity.name);
            }
        });
    }

    private uncheckedAllTopics(): void {
        const value: Set<string> = new Set();
        this.dataFlowFormService.setTopicsValue(value);

        for (const topicKey in this.topicsIdByActivityId) {
            this.topicsIdByActivityId[topicKey].checked = [];
        }

        this.dataFlowFormService.updateValueAndValidity(FormFieldName.Topics);
    }

    private setCurrentTemplateDestinationActivity(skipSourceSessionControlValue?: boolean): void {
        const session = this.sessions.find(session => session.id === (this.currentActivity?.sessionId as string));

        this.dataFlowFormService.setControlValue(FormFieldName.Destination, [this.currentActivity]);
        this.dataFlowFormService.setControlValue(FormFieldName.DestinationSession, session);

        const sourceSessionControlValue = this.dataFlowFormService.getFormControlValue(FormFieldName.SourceSession);

        if (!skipSourceSessionControlValue && !sourceSessionControlValue) {
            this.dataFlowFormService.setControlValue(FormFieldName.SourceSession, session);
        }

        if (this.currentActivityItem) {
            this.dataFlowFormService.setControlValue(FormFieldName.DestinationActivityItem, [this.currentActivityItem]);
        }
    }

    private setCurrentSourceDestinationSession(): void {
        setTimeout(() => {
            this.dataFlowFormService.setControlValue(FormFieldName.SourceSession, this.currentSession);
            this.dataFlowFormService.setControlValue(FormFieldName.DestinationSession, this.currentSession);
            this.setActualSessionsData();
        });
    }
}
