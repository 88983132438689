import { Dictionary } from '../dictionary';
import { NodeTemplate, NodeType } from '../node-template';
import { UpdatedByUser } from '../user';
import { Activity } from './activity';
import { ActivityType } from './activity-type';

export class LiveShare extends NodeTemplate implements Activity {
    type = ActivityType.LiveShare;

    sessionId?: string;
    projectId?: string;
    templateId?: string;
    visible?: boolean;
    sequence: string;
    screenSharingUserId?: string;
    creatorId?: string;
    creatorName?: string;
    creatorImage?: string;
    favoritesCount?: number;
    tags?: Dictionary<string>;

    // for public activity templates
    timesUsed?: number;
    creatorNotes?: string;
    updatedBy?: UpdatedByUser;

    dndDisabled?: boolean;

    constructor(id: string | undefined, liveShare: Partial<LiveShare>) {
        super(id, liveShare, NodeType.Activity);

        this.sessionId = liveShare.sessionId ?? '';
        this.projectId = liveShare.projectId ?? '';
        this.templateId = liveShare.templateId ?? '';
        this.visible = liveShare.visible ?? false;
        this.sequence = liveShare.sequence ?? '';
        this.screenSharingUserId = liveShare.screenSharingUserId ?? '';
        this.creatorId = liveShare.creatorId ?? '';
        this.creatorImage = liveShare.creatorImage ?? '';
        this.creatorName = liveShare.creatorName ?? '';
        this.tags = (liveShare.tags as Dictionary<string>) ?? {};

        this.creatorNotes = liveShare.creatorNotes ?? '';
        this.timesUsed = liveShare.timesUsed ?? 0;
        this.updatedBy = liveShare.updatedBy ?? undefined;

        this.favoritesCount = liveShare.favoritesCount ?? 0;
        this.dndDisabled = !!liveShare.dndDisabled;
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            projectId: this.projectId,
            sessionId: this.sessionId,
            templateId: this.templateId,
            visible: this.visible,
            sequence: this.sequence,
            screenSharingUserId: this.screenSharingUserId,
            type: this.type,
            created: this.created,
            updated: this.updated,
            creatorId: this.creatorId,
            creatorImage: this.creatorImage,
            creatorName: this.creatorName,
            creatorNotes: this.creatorNotes,
            timesUsed: this.timesUsed,
            tags: this.tags,
        };
    }
}
