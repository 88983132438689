<ng-container *ngIf="vm$ | async as vm">
  <div *ngIf="vm.isAvailable">
    <div
      *ngIf="!vm.isLoading"
      [matTooltip]="tooltip()"
      [ngClass]="isRecording() ? 'tt9-btn-icon-filled btn-rounded mx-4 recording' : 'tt9-btn-icon mx-4 active'"
      (click)="record()"
    >
      <accenture-icon>icon-call-recording</accenture-icon>
    </div>

    <div *ngIf="vm.isLoading" [matTooltip]="tooltip()" class="tt9-btn-icon-filled btn-rounded mx-16 loading">
      <accenture-loading [loaderSize]="loaderSize"></accenture-loading>
    </div>
  </div>
</ng-container>
