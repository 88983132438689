import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, distinctUntilChanged, map, Observable, startWith, tap } from 'rxjs';

import { BotName } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUserId, selectFeatureToggle } from '@accenture/global-store';
import { BotActions, errorSnackbarText, FeatureToggleName, StoragePathHelper } from '@accenture/shared/data';
import { FileUploadService, FirestoreService } from '@accenture/shared/data-access';
import { SnackbarService } from '@accenture/shared/ui';

export interface AiNarrateButtonModel {
    isAvailable: boolean;
    isLoading: boolean;
}

const defaultViewModel: AiNarrateButtonModel = {
    isAvailable: false,
    isLoading: false,
};

@Injectable()
export class AiNarrateButtonFacade {
    isLoading$ = new BehaviorSubject<boolean>(false);
    userId!: string;

    vm$ = this.buildViewModel();

    constructor(
        private firestoreService: FirestoreService,
        private snackbarService: SnackbarService,
        private fileUploadService: FileUploadService,
        private store: Store<AppState>,
    ) {}

    async transcribeRecording(data: Blob): Promise<string> {
        this.isLoading$.next(true);
        const fileName = `talk_${new Date().toJSON().slice(0, 10)}.mp3`;

        const { userId } = this;

        const fileUrl = await this.fileUploadService.uploadFile(
            StoragePathHelper.getUserUploadsPath(userId, fileName),
            new File([data], fileName),
        );

        try {
            const { message } = await this.firestoreService.cloudFunctionCallable<any>('aiConciergeChat', {
                userId,
                fileUrl,
                action: BotActions.Write,
            });

            return message;
        } catch (e) {
            console.error('AI Concierge Call Error', e);
            this.snackbarService.showErrorSnackBar(BotName, errorSnackbarText);
        } finally {
            this.isLoading$.next(false);
        }
    }

    private buildViewModel(): Observable<AiNarrateButtonModel> {
        return combineLatest([
            this.store.select(selectAuthenticatedUserId),
            this.store.select(selectFeatureToggle(FeatureToggleName.ShowSpeechToText)),
            this.isLoading$.asObservable().pipe(distinctUntilChanged()),
        ]).pipe(
            tap(([userId, ,]) => {
                this.userId = userId;
            }),
            map(([, isAvailable, isLoading]) => ({
                isAvailable,
                isLoading,
            })),
            startWith(defaultViewModel),
        );
    }
}
