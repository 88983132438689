import { ValueRange } from '../constants';
import { Question } from '../question';
import { ActivityItemType } from './activity-item-type';

export class TopX extends Question {
    type = ActivityItemType.TopX;
    options: {
        valueRange?: ValueRange;
    };

    constructor(data: any) {
        super(data);

        const options = data.options || {};
        this.options = {
            valueRange: options.valueRange ?? ValueRange.ValueRange3,
        };
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            ...super.createSerializableObject(),
            options: {
                valueRange: this.options.valueRange,
            },
        };
    }
}
