import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';

import { RouterEffects } from './router.effects';
import { ROUTER_FEATURE_KEY } from './router.reducer';

@NgModule({
    declarations: [],
    imports: [StoreModule.forFeature(ROUTER_FEATURE_KEY, routerReducer), EffectsModule.forFeature([RouterEffects])],
    exports: [],
    providers: [],
})
export class RouterModule {}
