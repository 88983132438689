import { Injectable } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import {
    Activity,
    ActivityItem,
    ActivityType,
    DataFlowFilters,
    Dictionary,
    FormFieldName,
    TableColumn,
    validationErrors,
    ValueRange,
} from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

const noSelectedParametersError = { [validationErrors.noSelectedParameters]: true };
const noParametersError = { [validationErrors.noParameters]: true };

export type DataFlowForm = FormGroup<{
    activities?: FormGroup<{
        sourceSessionId: FormControl<string | null>;
        destinationSessionId: FormControl<string | null>;
        source: FormControl<Activity[]>;
        destination: FormControl<Activity[]>;
        activityName: FormControl<string>; // field to create destination activity
        activityType: FormControl<ActivityType>; // field to create destination activity
        destinationActivityItem: FormControl<Partial<ActivityItem[]> | null>;
    }>;
    options?: FormGroup<{
        topics: FormControl<string[]>;
        contentToMove: FormControl<DataFlowFilters | undefined>;
        filter: FormControl<DataFlowFilters | undefined>;
        top_x: FormControl<ValueRange>;
        bottom_x: FormControl<ValueRange>;
        transformIntoResponses: FormControl<boolean>;
        top_parameter: FormControl<string | null>;
        bottom_parameter: FormControl<string | null>;
        destinationColumn: FormControl<Partial<TableColumn[]> | null>;
    }>;
}>;

const topicValuesValidator
    = (
        topicOptionsIds: string[],
        topicsIdByActivityId: Dictionary<{ all?: string[]; checked?: string[] }>,
    ): ValidatorFn =>
    (control: FormControl<string[]>): ValidationErrors | null => {
        const value: string[] = control.value;
        let valid = true;

        if (!value) {
            return null;
        }

        for (let i = 0; i < topicOptionsIds.length; i++) {
            if (!topicsIdByActivityId[topicOptionsIds[i]]?.checked?.length) {
                valid = false;
                break;
            }
        }

        return valid ? null : { [validationErrors.noSelectedTopics]: true };
    };

@Injectable({
    providedIn: 'root',
})
export class DataFlowFormService {
    dataFlowForm: DataFlowForm = new FormGroup({});
    filterParameterIdsMap = new BehaviorSubject<Dictionary<string>>({});
    noParametersActivitiesNames = new BehaviorSubject<string[]>([]);
    filterParametersMap = new BehaviorSubject<Dictionary<ActivityItem[]>>({});

    constructor(private fb: FormBuilder, private firestoreService: FirestoreService) {}

    updateFilterParameterIdsMap(data: Dictionary<string>): void {
        this.filterParameterIdsMap.next(data);
    }

    updateNoParametersActivitiesNames(data: string[]): void {
        this.noParametersActivitiesNames.next(data);
    }

    updateFilterParametersMap(data: Dictionary<ActivityItem[]>): void {
        this.filterParametersMap.next(data);
    }

    getDestinationId(): string {
        return this.firestoreService.getPushId();
    }

    getFormControlValue<T>(type: string): T {
        return this.dataFlowForm.get(type)?.value;
    }

    getFormControl(type: string): AbstractControl {
        return this.dataFlowForm?.get(type);
    }

    getSourceActivityType(): ActivityType | undefined {
        const activityArray: Activity[] = this.getFormControlValue(FormFieldName.Source) || [];

        return activityArray[0]?.type;
    }

    resetControlValue(resetFieldName: string): void {
        this.dataFlowForm.get(resetFieldName)?.reset();
    }

    setControlValue<T>(fieldName: string, value: T): void {
        this.getFormControl(fieldName).setValue(value);
        this.dataFlowForm.get(fieldName).updateValueAndValidity();
    }

    resetFilterOptionToDefaultState(): void {
        this.setControlValue(FormFieldName.Filter, DataFlowFilters.All);
    }

    resetContentToMoveToDefaultState(state: DataFlowFilters): void {
        this.setControlValue(FormFieldName.ContentToMove, state);
    }

    resetColumnStateAndValidation(): void {
        this.getFormControl(FormFieldName.DestinationColumn).setValue(null);
        this.updateColumnsValidators(false);
    }

    resetDestinationActivityItemsStateAndValidation(): void {
        this.getFormControl(FormFieldName.DestinationActivityItem).setValue(null);
        this.updateDestinationActivityItemValidators(false);
    }

    resetTransformInto(): void {
        this.setControlValue(FormFieldName.TransformInto, false);
    }

    updateDestinationActivityItemValidators(setValidator: boolean): void {
        const control = this.getFormControl(FormFieldName.DestinationActivityItem);
        this.updateValidators(control, setValidator);
        control.markAsTouched();
    }

    updateColumnsValidators(setValidator: boolean): void {
        const control = this.getFormControl(FormFieldName.DestinationColumn);
        this.updateValidators(control, setValidator);
    }

    updateFormData<T>(data: T): void {
        this.dataFlowForm.patchValue(data);
        this.dataFlowForm.updateValueAndValidity();
    }

    resetForm(): void {
        const options = {
            topics: [],
            contentToMove: DataFlowFilters.Responses,
            filter: DataFlowFilters.All,
            top_x: ValueRange.ValueRange5,
            bottom_x: ValueRange.ValueRange5,
            transformIntoResponses: false,
            top_parameter: null,
            bottom_parameter: null,
            destinationColumn: null,
        };
        const activities = {
            sourceSessionId: '',
            destinationSessionId: '',
            source: [],
            destination: [],
            activityName: '',
            activityType: ActivityType.Brainstorm,
            destinationActivityItem: null,
        };
        this.dataFlowForm.reset({
            options,
            activities,
        });
    }

    setTopicsValue(value: Set<string | undefined> | string[] | string): void {
        const control = this.getFormControl(FormFieldName.Topics);
        control.setValue(Array.from(value));
    }

    setTopicsValidators(
        topicOptionsIds: string[],
        topicsIdByActivityId: Dictionary<{ all?: string[]; checked?: string[] }>,
    ): void {
        this.dataFlowForm
            .get(FormFieldName.Topics)
            .setValidators([Validators.required, topicValuesValidator(topicOptionsIds, topicsIdByActivityId)]);
    }

    updateValueAndValidity(fieldName: string): void {
        this.dataFlowForm.get(fieldName).updateValueAndValidity();
    }

    clearValidators(fieldName: string): void {
        this.dataFlowForm.get(fieldName).clearValidators();
    }

    buildDataFlowForm(): void {
        this.dataFlowForm = this.fb.group({
            activities: this.fb.group({
                sourceSessionId: new FormControl<string | null>(null),
                destinationSessionId: new FormControl<string | null>(null),
                source: new FormControl<Activity[]>([], { nonNullable: true, validators: Validators.required }),
                destination: new FormControl<Activity[]>([], { nonNullable: true, validators: Validators.required }),
                activityName: new FormControl<string>('', { nonNullable: true }),
                activityType: new FormControl<ActivityType>(ActivityType.Brainstorm, { nonNullable: true }),
                destinationActivityItem: new FormControl<Partial<ActivityItem[]> | null>(null),
            }),
            options: this.fb.group({
                topics: new FormControl<string[]>([], { nonNullable: true }),
                contentToMove: new FormControl<DataFlowFilters>(DataFlowFilters.Responses, { nonNullable: true }),
                filter: new FormControl<DataFlowFilters>(DataFlowFilters.All, {
                    nonNullable: true,
                    validators: [this.selectParametersValidator(), this.noParametersValidator()],
                }),
                top_x: new FormControl<ValueRange>(ValueRange.ValueRange5, { nonNullable: true }),
                bottom_x: new FormControl<ValueRange>(ValueRange.ValueRange5, { nonNullable: true }),
                transformIntoResponses: new FormControl<boolean>(false, { nonNullable: true }),
                top_parameter: new FormControl<null>(null),
                bottom_parameter: new FormControl<null>(null),
                destinationColumn: new FormControl<Partial<TableColumn[]> | null>(null),
            }),
        }) as DataFlowForm;
    }

    disabledFields(fields: FormFieldName[]): void {
        fields.forEach(formFieldName => {
            this.getFormControl(formFieldName)?.disable();
        });
    }

    updateValidators(control: AbstractControl, setValidity: boolean): void {
        control.reset(control.value);

        setValidity ? control.setValidators([Validators.required]) : control.clearValidators();

        control.updateValueAndValidity();
    }

    isVoteSourceAndTopBottomXFilter(value: DataFlowFilters): boolean {
        return (
            [DataFlowFilters.Bottom, DataFlowFilters.Top].includes(value)
            && this.isEqualActivityTypes(FormFieldName.Source, ActivityType.Vote)
        );
    }

    isEqualActivityTypes(formField: string, activityType: ActivityType | ActivityType[]): boolean {
        return activityType?.includes(this.getFormControlValue(formField)[0]?.type);
    }

    private selectParametersValidator = (): ValidatorFn => {
        return (control: AbstractControl) => {
            if (
                this.isVoteSourceAndTopBottomXFilter(control.value)
                && Object.values(this.filterParameterIdsMap.value || {})?.length
                    !== this.getFormControlValue<Activity[]>(FormFieldName.Source)?.length
            ) {
                return noSelectedParametersError;
            }

            return null;
        };
    };

    private noParametersValidator = (): ValidatorFn => {
        return (control: AbstractControl) => {
            if (
                this.isVoteSourceAndTopBottomXFilter(control.value)
                && this.noParametersActivitiesNames.value?.length
                && Object.keys(this.filterParametersMap.value)?.length
            ) {
                return noParametersError;
            }

            return null;
        };
    };
}
