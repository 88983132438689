import { ChangeDetectionStrategy, Component } from '@angular/core';

import {
    addProjectImageUrl,
    aiGenerateImageUrl,
    buttonLabels,
    createProjectImageUrl,
    DialogSelectionOption,
} from '@accenture/shared/data';

import { SelectSourceTypeDialogFacade } from './select-source-type-dialog-facade';

@Component({
    selector: 'accenture-select-source-type-dialog',
    templateUrl: './select-source-type-dialog.component.html',
    styleUrls: ['./select-source-type-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SelectSourceTypeDialogFacade],
})
export class SelectSourceTypeDialogComponent {
    vm$ = this.facade.vm$;

    selectionOptions: DialogSelectionOption[] = [
        {
            name: buttonLabels.createNew,
            imageUrl: createProjectImageUrl,
            callBack: () => this.createNew(),
        },
        {
            name: buttonLabels.selectTemplate,
            imageUrl: addProjectImageUrl,
            callBack: () => this.selectTemplate(),
        },
        {
            name: buttonLabels.generateNew,
            imageUrl: aiGenerateImageUrl,
            callBack: () => this.generateNew(),
        },
    ];

    constructor(private facade: SelectSourceTypeDialogFacade) {}

    createNew(): void {
        this.facade.createNewDialog();
    }

    selectTemplate(): void {
        this.facade.selectTemplateDialog();
    }

    generateNew(): void {
        this.facade.openAiGenerateDialog();
    }
}
