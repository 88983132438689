<accenture-base-snack-bar
  [title]="data.title"
  [iconClass]="data.iconClass"
  [iconName]="data.iconName"
  [actionButtonClass]="data.actionButtonClass"
  [hideCloseButton]="data.hideCloseButton"
  (snackbarClosed)="onClose()"
>
  <p class="tt9-body-2">{{ data.message }}</p>

  <div *ngIf="data.isVisibleNavigateHome" class="mt-8 d-flex">
    <button [class]="data.actionButtonClass" class="mr-0 ml-auto" (click)="onClose()">OK</button>
  </div>
</accenture-base-snack-bar>
