import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'userInitials',
})
export class UserInitialsPipe implements PipeTransform {
    transform(value: string| undefined): string {
        const letters = (value || '')
            .trim()
            .toUpperCase()
            .split(' ')
            .map(word => word[0]);
        return letters.length > 1 ? `${letters[0]}${letters[letters.length - 1]}` : letters[0];
    }
}
