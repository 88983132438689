import * as moment from 'moment';

import { Injectable } from '@angular/core';

import {
    MsGraphEvent,
    MsGraphOnlineMeeting,
    MsGraphUser,
} from '@accenture/shared/data';

const msGraphBeta = 'https://graph.microsoft.com/beta/';
const msGraph = 'https://graph.microsoft.com/v1.0/';

@Injectable({
    providedIn: 'root',
})
export class MsGraphService {
    async getPersonalDetails(accessToken: string): Promise<MsGraphUser> {
        try {
            const result = await fetch(`${msGraph}me`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    authorization: `bearer ${accessToken}`,
                },
            });

            const user = await result.json();
            return user;
        } catch (error) {
            console.error('error reading users');
            throw new Error(error);
        }
    }

    async getPersonalAvatar(accessToken: string): Promise<Blob | null> {
        try {
            const result = await fetch(`${msGraph}me/photos/240x240/$value`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    authorization: `bearer ${accessToken}`,
                },
            });

            if (result.status == 200) {
                //image available
                const imageBlob = await result.arrayBuffer();
                return new Blob([new Uint8Array(imageBlob)], { type: 'image/png' });
            }
            return null;
        } catch (error) {
            console.error('error reading users');
            throw new Error(error);
        }
    }

    async getUsers(accessToken: string): Promise<MsGraphUser[]> {
        try {
            const result = await fetch(`${msGraph}users`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    authorization: `bearer ${accessToken}`,
                },
            });

            const { value } = await result.json();

            return value;
        } catch (error) {
            console.error('error reading users');
            throw new Error(error);
        }
    }

    async readMeetingDetailsFromJoinWebUrl(accessToken: string, joinWebUrl: string): Promise<MsGraphOnlineMeeting> {
        try {
            const result = await fetch(`${msGraph}me/onlineMeetings?$filter=joinWebUrl eq '${joinWebUrl}'`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    authorization: `bearer ${accessToken}`,
                },
            });

            const { value } = await result.json();

            return value[0];
        } catch (error) {
            console.error('error reading meeting details');
            throw new Error(error);
        }
    }

    async getMeetingDetails(accessToken: string, meetingId: string): Promise<MsGraphOnlineMeeting> {
        try {
            const chatId = atob(meetingId).replace(/^0#|#0$/g, '');

            const result = await fetch(`${msGraphBeta}chats/${chatId}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    authorization: `bearer ${accessToken}`,
                },
            });

            const { joinWebUrl } = (await result.json()).onlineMeetingInfo;

            return this.readMeetingDetailsFromJoinWebUrl(accessToken, joinWebUrl);
        } catch (error) {
            console.error('error getting meeting details');
            throw new Error(error);
        }
    }

    async createMeeting(
        accessToken: string,
        subject: string,
        content: string,
        date: string,
        recipients: Array<string>,
        length = 30
    ): Promise<MsGraphEvent> {
        try {
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            const payload = {
                subject: subject,
                body: {
                    contentType: 'HTML',
                    content: content,
                },
                start: {
                    dateTime: date,
                    timeZone: timeZone,
                },
                end: {
                    dateTime: moment(date).add(length, 'm').format('YYYY-MM-DD HH:mm:ss'),
                    timeZone: timeZone,
                },
                location: {
                    displayName: 'MS Teams',
                },
                attendees: recipients.map((result) => ({
                    emailAddress: {
                        address: result,
                    },
                })),
                allowNewTimeProposals: true,
                isOnlineMeeting: true,
                onlineMeetingProvider: 'teamsForBusiness',
            };

            const result = await fetch(`${msGraph}me/events`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `bearer ${accessToken}`,
                },
                body: JSON.stringify(payload),
            });

            const { onlineMeeting, id } = await result.json();

            return {
                id,
                onlineMeeting: await this.readMeetingDetailsFromJoinWebUrl(accessToken, onlineMeeting.joinUrl),
            };
        } catch (error) {
            console.error('error creating meeting');
            throw new Error(error);
        }
    }

    async attachTeamsAppToMeeting(
        accessToken: string,
        chatId: string,
        meetingId: string,
        appStoreAppId: string,
        tabEntityId: string,
        teamsHost: string
    ): Promise<void> {
        try {
            const payload = {
                'teamsApp@odata.bind': `${msGraph}appCatalogs/teamsApps/${appStoreAppId}`,
            };

            await fetch(`${msGraph}chats/${chatId}/installedApps`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `bearer ${accessToken}`,
                },
                body: JSON.stringify(payload),
            });

            return this.attachTeamsTabToMeeting(accessToken, chatId, meetingId, appStoreAppId, tabEntityId, teamsHost);
        } catch (error) {
            console.error('error attaching teams app to meeting');
            throw new Error(error);
        }
    }

    async attachTeamsTabToMeeting(
        accessToken: string,
        chatId: string,
        meetingId: string,
        appStoreAppId: string,
        tabEntityId: string,
        teamsHost: string
    ): Promise<void> {
        try {
            const payload = {
                displayName: 'ThinkTank',
                'teamsApp@odata.bind': `${msGraph}appCatalogs/teamsApps/${appStoreAppId}`,
                configuration: {
                    entityId: tabEntityId,
                    websiteUrl: teamsHost,
                    contentUrl: `${teamsHost}/meeting/${meetingId}`,
                    removeUrl: `${teamsHost}/meeting/${meetingId}/removal`,
                },
            };

            const result = await fetch(`${msGraph}chats/${chatId}/tabs`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `bearer ${accessToken}`,
                },
                body: JSON.stringify(payload),
            });

            const { webUrl } = await result.json();

            return webUrl;
        } catch (error) {
            console.error('error attaching teams app to meeting');
            throw new Error(error);
        }
    }

    async isMeetingCancelled(accessToken: string, eventId: string): Promise<boolean> {
        try {
            const result = await fetch(`${msGraph}me/events/${eventId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `bearer ${accessToken}`,
                },
            });

            if (result.status === 404) {
                // most likely deleted
                return true;
            }

            const { isCancelled } = await result.json();

            return isCancelled;
        } catch (error) {
            console.error('error getting event status');
            throw new Error(error);
        }
    }

    async attachTt9ToMeeting(
        accessToken: string,
        chatId: string,
        tt9Host: string,
        sessionUrl: string
    ): Promise<string> {
        try {
            const payload = {
                displayName: 'ThinkTank',
                'teamsApp@odata.bind': `${msGraph}appCatalogs/teamsApps/com.microsoft.teamspace.tab.web`,
                configuration: {
                    entityId: 'ThinkTank',
                    websiteUrl: tt9Host,
                    contentUrl: sessionUrl,
                },
            };

            const result = await fetch(`${msGraph}chats/${chatId}/tabs`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `bearer ${accessToken}`,
                },
                body: JSON.stringify(payload),
            });

            const { webUrl } = await result.json();

            return webUrl;
        } catch (error) {
            console.error('error attaching tt9 tab to meeting');
            throw new Error(error);
        }
    }
}
