import { Dictionary } from '../dictionary';
import { NodeTemplate, NodeType } from '../node-template';
import { FileType } from '../responses';
import { UpdatedByUser } from '../user';
import { Activity } from './activity';
import { ActivityType } from './activity-type';

export class QuickPoll extends NodeTemplate implements Activity {
    type = ActivityType.QuickPoll;

    sessionId?: string;
    projectId?: string;
    templateId?: string;
    sequence: string;
    visible?: boolean;
    sectionId?: string;

    anonymous?: boolean;
    allowChangeAnswers?: boolean;
    multipleOptions: boolean;
    waitingScreenVisible: boolean;
    waitingScreenText?: string;
    waitingScreenImage?: FileType | null;
    statusScreenVisible: boolean;
    statusScreenText?: string;
    statusScreenImage?: FileType | null;
    descriptionVisible: boolean;
    descriptionText?: string;
    descriptionImage?: FileType | null;
    creatorId?: string;
    creatorName?: string;
    creatorImage?: string;
    favoritesCount?: number;
    tags?: Dictionary<string>;

    // for public activity templates
    timesUsed?: number;
    creatorNotes?: string;
    updatedBy?: UpdatedByUser;

    dndDisabled?: boolean;

    constructor(id: string | undefined, quickPoll: Partial<QuickPoll>) {
        super(id, quickPoll, NodeType.Activity);

        this.sessionId = quickPoll.sessionId ?? '';
        this.projectId = quickPoll.projectId ?? '';
        this.templateId = quickPoll.templateId ?? '';
        this.sequence = quickPoll.sequence ?? '';
        this.visible = quickPoll.visible ?? false;
        this.sectionId = quickPoll.sectionId;

        this.anonymous = quickPoll.anonymous ?? false;
        this.allowChangeAnswers = quickPoll.allowChangeAnswers ?? false;
        this.multipleOptions = quickPoll.multipleOptions ?? false;
        this.waitingScreenVisible = quickPoll.waitingScreenVisible ?? false;
        this.waitingScreenText = quickPoll.waitingScreenText ?? '';
        this.waitingScreenImage = quickPoll.waitingScreenImage ?? ({} as FileType);
        this.statusScreenVisible = quickPoll.statusScreenVisible ?? false;
        this.statusScreenText = quickPoll.statusScreenText ?? '';
        this.statusScreenImage = quickPoll.statusScreenImage ?? ({} as FileType);
        this.descriptionVisible = quickPoll.descriptionVisible ?? false;
        this.descriptionText = quickPoll.descriptionText ?? '';
        this.descriptionImage = quickPoll.descriptionImage ?? ({} as FileType);
        this.creatorId = quickPoll.creatorId ?? '';
        this.creatorImage = quickPoll.creatorImage ?? '';
        this.creatorName = quickPoll.creatorName ?? '';
        this.favoritesCount = quickPoll.favoritesCount ?? 0;
        this.tags = (quickPoll.tags as Dictionary<string>) ?? {};

        this.creatorNotes = quickPoll.creatorNotes ?? '';
        this.timesUsed = quickPoll.timesUsed ?? 0;
        this.updatedBy = quickPoll.updatedBy ?? undefined;

        this.dndDisabled = !!quickPoll.dndDisabled;
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            projectId: this.projectId,
            sessionId: this.sessionId,
            sectionId: this.sectionId,
            templateId: this.templateId,
            visible: this.visible,
            sequence: this.sequence,
            anonymous: this.anonymous,
            allowChangeAnswers: this.allowChangeAnswers,
            multipleOptions: this.multipleOptions,
            waitingScreenVisible: this.waitingScreenVisible,
            waitingScreenText: this.waitingScreenText,
            waitingScreenImage: this.waitingScreenImage,
            statusScreenVisible: this.statusScreenVisible,
            statusScreenText: this.statusScreenText,
            statusScreenImage: this.statusScreenImage,
            descriptionVisible: this.descriptionVisible,
            descriptionText: this.descriptionText,
            descriptionImage: this.descriptionImage,
            type: this.type,
            created: this.created,
            updated: this.updated,
            creatorId: this.creatorId,
            creatorImage: this.creatorImage,
            creatorName: this.creatorName,
            creatorNotes: this.creatorNotes,
            timesUsed: this.timesUsed,
            tags: this.tags,
        };
    }
}
