import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ApplicationConfigurationEffects } from './application-configuration.effects';
import {
    APPLICATION_CONFIGURATION_FEATURE_KEY,
    applicationConfigurationReducer,
} from './application-configuration.reducer';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(APPLICATION_CONFIGURATION_FEATURE_KEY, applicationConfigurationReducer),
        EffectsModule.forFeature([ApplicationConfigurationEffects]),
    ],
    exports: [],
    providers: [],
})
export class ApplicationConfigurationModule {}
