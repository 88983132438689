import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isNil } from 'lodash';

import { validationErrors } from './validation-errors';

const invalidValueFromRangeError = { [validationErrors.invalidValueFromRange]: true };

// if controlName is passed in options - validate by controlName in group
// if no controlName (min, max required) - validate control by min, max options
export const valueInRangeValidator
    = (options?: { controlName?: string; min?: number; max?: number; isBetweenMinMax?: boolean }): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
        let isStepOutOfRange = false;

        if (!options.controlName) {
            // validate control by options
            isStepOutOfRange
                = !isNil(control.value)
                && (options.min || options.min === 0)
                && (options.max || options.max === 0)
                && (options.isBetweenMinMax
                    ? +control.value > +options.max || +control.value < +options.min
                    : +control.value > +options.max - +options.min);
        } else {
            // validate form group by controlName
            const minControl = control.get('min');
            const maxControl = control.get('max');
            const valueInRangeControl = control.get(options.controlName);

            if (!minControl || !maxControl || !valueInRangeControl) {
                return null;
            }

            isStepOutOfRange
                = (minControl.value || minControl.value === 0)
                && (maxControl.value || maxControl.value === 0)
                && (options.isBetweenMinMax
                    ? +valueInRangeControl.value > +maxControl.value || +valueInRangeControl.value < +minControl.value
                    : +valueInRangeControl.value > +maxControl.value - +minControl.value);

            if (isStepOutOfRange) {
                valueInRangeControl.setErrors(invalidValueFromRangeError);
            }
        }

        control.markAllAsTouched();

        return isStepOutOfRange ? invalidValueFromRangeError : null;
    };
