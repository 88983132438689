import { ElementRef, Injectable } from '@angular/core';
import { ConnectionPositionPair, FlexibleConnectedPositionStrategy, Overlay } from '@angular/cdk/overlay';

import { positionMap, TooltipPosition } from '../models';

@Injectable({
    providedIn: 'root',
})
export class TooltipPositionService {
    constructor(private overlay: Overlay) {}

    getPositionStrategy(elementRef: ElementRef, startPosition: TooltipPosition): FlexibleConnectedPositionStrategy {
        return this.overlay
            .position()
            .flexibleConnectedTo(elementRef)
            .withPositions(this.getPosition(startPosition))
            .withPush(false);
    }

    private getPosition(startPosition: TooltipPosition): ConnectionPositionPair[] {
        const allOtherPositions: ConnectionPositionPair[] = Object.keys(positionMap)
            .filter(key => key !== startPosition)
            .map(key => positionMap[key as TooltipPosition]);

        return [positionMap[startPosition], ...allOtherPositions];
    }
}
