<ng-container *ngIf="vm$ | async as vm">
  <div class="collection-filters" [formGroup]="filtersAndSortForm">
    <div *accentureLoaded="!vm.isLoading" class="collection-filters-container">
      <h4 class="tt9-h4-medium">Filter</h4>

      <div class="collection-filters-dates">
        <!-- filter by date from -->
        <mat-form-field
          appearance="outline"
          class="tt9-form-field date-form-field custom-width min-height"
          floatLabel="always"
        >
          <mat-label>Date created from</mat-label>
          <input
            matInput
            readonly
            formControlName="fromDate"
            placeholder="{{ inputPlaceholders.chooseDateSecondary }}"
            [matDatepicker]="datePickerFrom"
            (click)="datePickerFrom.open()"
          />
          <mat-datepicker-toggle matSuffix [for]="datePickerFrom">
            <accenture-icon matDatepickerToggleIcon> icon-calendar </accenture-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #datePickerFrom></mat-datepicker>
          <accenture-icon
            matSuffix
            *ngIf="filtersAndSortForm.controls.fromDate.value"
            class="mat-select-icon"
            (click)="clearDateInput('fromDate', $event)"
          >
            icon-close
          </accenture-icon>
        </mat-form-field>
        <!-- filter by date from -->

        <!-- filter by date to -->
        <mat-form-field
          appearance="outline"
          class="tt9-form-field date-form-field custom-width min-height"
          floatLabel="always"
        >
          <mat-label>Date created to</mat-label>
          <input
            matInput
            readonly
            formControlName="toDate"
            placeholder="{{ inputPlaceholders.chooseDateSecondary }}"
            [matDatepicker]="datePickerTo"
            (click)="datePickerTo.open()"
          />
          <mat-datepicker-toggle matSuffix [for]="datePickerTo">
            <accenture-icon matDatepickerToggleIcon> icon-calendar </accenture-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #datePickerTo></mat-datepicker>
          <accenture-icon
            matSuffix
            *ngIf="filtersAndSortForm.controls.toDate.value"
            class="mat-select-icon date-reset"
            (click)="clearDateInput('toDate', $event)"
          >
            icon-close
          </accenture-icon>
          <mat-error *ngIf="isToDateGrFromDateErrorVisibility"
            >'Date Created To' must be equal or greater than 'Date Created From'.</mat-error
          >
        </mat-form-field>
        <!-- filter by date to -->
      </div>

      <h4 class="tt9-h4-medium">Sort</h4>

      <!-- sorting by -->
      <div>
        <mat-form-field class="tt9-form-field width-100" appearance="outline" floatLabel="always">
          <mat-label> Sort By </mat-label>
          <mat-select
            formControlName="sortOption"
            placeholder="Sort by"
            [disableOptionCentering]="true"
            [panelClass]="['tt9-select-panel-custom']"
          >
            <ng-container *ngFor="let sortOption of vm.sortOptionsValues | keyvalue">
              <mat-option [value]="sortOption.key">
                {{ sortOption.value }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- sorting by -->

      <!-- sorting order -->
      <div>
        <mat-form-field class="tt9-form-field width-100" appearance="outline" floatLabel="always">
          <mat-label> Sort Order </mat-label>
          <mat-select
            formControlName="sortOrder"
            placeholder="Sort order"
            [disableOptionCentering]="true"
            [panelClass]="['tt9-select-panel-custom']"
          >
            <ng-container *ngFor="let sortOrder of sortOrdersValues | keyvalue">
              <mat-option [value]="sortOrder.key">
                {{ sortOrder.value }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- sorting order -->
    </div>

    <!-- reset form controls values link -->
    <div class="collection-filters-footer">
      <button class="tt9-neutral-btn" [disabled]="resetAllLinkDisabled" (click)="resetFiltersAndSortForm()">
        Reset All
      </button>
    </div>
    <!-- reset form controls values link -->
  </div>
</ng-container>
