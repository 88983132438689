import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DBPathHelper, VisualFramework } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable({
    providedIn: 'root',
})
export class VisualFrameworkService {
    constructor(private firestoreService: FirestoreService) {}

    getFrameworks(): Observable<VisualFramework[]> {
        return this.firestoreService.getCollection<VisualFramework>(DBPathHelper.getVisualFrameworkPath());
    }
}
