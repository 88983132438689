export interface MsGraphUser {
    id: string;
    businessPhones: string[];
    displayName: string;
    givenName: string;
    jobTitle: string;
    mail: string;
    mobilePhone: string;
    officeLocation: string;
    preferredLanguage: string;
    surname: string;
    userPrincipalName: string;
}

interface MsGraphMeetingParticipant {
    upn: string;
    role: string;
    identity: {
        user: {
            id: string;
            displayName?: string;
            tenantId?: string;
            identityProvider?: string;
        };
    };
}

export interface MsGraphOnlineMeeting {
    id: string;
    creationDateTime: string;
    startDateTime: string;
    endDateTime: string;
    joinUrl: string;
    joinWebUrl: string;
    subject?: string;
    isEntryExitAnnounced?: boolean;
    allowedPresenters?: string;
    videoTeleconferenceId?: string;
    participants: {
        organizer: MsGraphMeetingParticipant;
        attendees: MsGraphMeetingParticipant[];
        producers: MsGraphMeetingParticipant[];
        contributors: MsGraphMeetingParticipant[];
    };
    lobbyBypassSettings: {
        scope: string;
        isDialInBypassEnabled: boolean;
    };
    joinMeetingIdSettings: {
        isPasscodeRequired: boolean;
        joinMeetingId: string;
        passcode?: string;
    };
    chatInfo: {
        threadId: string;
    };
}

export interface MsGraphEvent {
    id: string;
    onlineMeeting: MsGraphOnlineMeeting;
}

export const MsGraphScopes = [
    'User.Read',
    'User.ReadBasic.All',
    'Calendars.ReadWrite',
    'Calendars.ReadWrite.Shared',
    'OnlineMeetings.ReadWrite',
    'TeamsAppInstallation.ReadWriteForChat',
    'TeamsTab.ReadWrite.All',
];
