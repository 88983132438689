import { FormControl, FormGroup } from '@angular/forms';

import { FileType } from '../responses';

export type SessionForm = FormGroup<{
    name: FormControl<string>;
    description: FormControl<string>;
    startDate: FormControl<Date | null>;
    endDate: FormControl<Date | null>;
    image: FormControl<FileType | null>;
}>;
