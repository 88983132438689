import { isUndefined } from 'lodash';

export class DateTransformation {
    static getFormattedDateWithClientTimezone = (value: string, timezone: number): string => {
        const dateWithTimezone = DateTransformation.getDateObjectWithoutClientTimezone(value, timezone);

        return dateWithTimezone ? DateTransformation.getFormattedDate(dateWithTimezone) : '';
    };

    static getFormattedDateTimeWithClientTimezone = (value: string, timezone: number): string => {
        const dateWithTimezone = DateTransformation.getDateObjectWithoutClientTimezone(value, timezone);

        const hours = dateWithTimezone.getHours();
        const minutes = dateWithTimezone.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert hours to 12-hour format
        const formattedHours = hours % 12 || 12;

        return dateWithTimezone
            ? `${DateTransformation.getFormattedDate(dateWithTimezone)}, ${formattedHours}:${minutes
                  .toString()
                  .padStart(2, '0')} ${ampm}`
            : '';
    };

    static getFormattedDateWithoutSeparators(date: Date): string {
        return (
            (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1).toString()
            + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
            + date.getFullYear()
        );
    }

    static formatDate(date: Date): string {
        const yyyy = date.getFullYear();
        const mm = date.getMonth();
        const dd = date.getDate();
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return `${months[mm]} ${dd}, ${yyyy}`;
    }

    static getFormattedDateWithoutSlash(): string {
        const fileDate = new Date();
        const dd = String(fileDate.getDate()).padStart(2, '0');
        const mm = String(fileDate.getMonth() + 1).padStart(2, '0');
        const yyyy = fileDate.getFullYear();
        return `${mm}${dd}${yyyy}`;
    }

    static getFormattedDateAndTime(value: string, timezone: number): string {
        const dateWithoutTimezone = DateTransformation.getDateObjectWithoutClientTimezone(value, timezone);
        if (!dateWithoutTimezone) {
            return undefined;
        }
        const hours = dateWithoutTimezone.getHours();
        const minutes = dateWithoutTimezone.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert hours to 12-hour format
        const formattedHours = hours % 12 || 12;

        return `${this.formatDate(dateWithoutTimezone)}, ${formattedHours}:${minutes
            .toString()
            .padStart(2, '0')} ${ampm}`;
    }

    static getFormattedDate(date: Date): string | undefined {
        if (!date) {
            return undefined;
        }
        return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
    }

    static getDateObjectWithoutClientTimezone(value: string, timezone: number): Date {
        if (!value) {
            return null;
        }

        if (isUndefined(timezone)) {
            return new Date(value);
        }
        const dateInMilliseconds = Date.parse(value);
        return new Date(dateInMilliseconds - timezone * 60 * 1000);
    }

    static getDateObjectWithoutClientTimezoneAmol(value: string, timezone: number): Date {
        if (!value) {
            return null;
        }

        if (isUndefined(timezone)) {
            return new Date(value);
        }
        let dateInMilliseconds = Date.parse(value.replace(/[ap]m$/i, ''));
        if (value.match(/pm$/i)) {
            dateInMilliseconds += 12 * 60 * 60 * 1000; // Add 12 hours for PM.
        }
        return new Date(dateInMilliseconds - timezone * 60 * 1000);
    }

    static getUTCDate(date: Date, timezone?: number): Date | undefined {
        if (!date) {
            return undefined;
        }
        const timezoneOffset = timezone ? timezone : new Date().getTimezoneOffset();
        const dateInMilliseconds = date.getTime();

        return new Date(dateInMilliseconds - timezoneOffset * 60 * 1000);
    }

    static convertDateToUTCFormat(date: Date): number | undefined {
        if (!date) {
            return undefined;
        }
        return Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds(),
        );
    }
}
