import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { tap } from 'rxjs';

import {
    Activity,
    ActivityItem,
    ActivityType,
    DataFlowConnection,
    dataFlowDescriptionMap,
    Dictionary,
    emptyScreenDescriptions,
    emptyScreenImageUrlActivities,
    emptyScreenTitles,
    inputPlaceholders,
    Session,
    SessionFocus,
    tooltipTexts,
} from '@accenture/shared/data';
import { SequenceData } from '@accenture/shared/shared-session';
import { AccentureDndUtil, IconColor, IconSize, TooltipPosition } from '@accenture/shared/ui';
import { isDisabledTooltip, trackById } from '@accenture/shared/util';

import { ActivityDataFlowTooltipComponent } from '../activity-data-flow-tooltip/activity-data-flow-tooltip.component';
import { ActivitiesListFacade } from './activities-list-facade';
import { tooltipText } from './constants';

@Component({
    selector: 'accenture-activities-list',
    templateUrl: './activities-list.component.html',
    styleUrls: ['./activities-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ActivitiesListFacade],
})
export class ActivitiesListComponent extends AccentureDndUtil<Activity | ActivityItem> {
    @Output() onSearchActive = new EventEmitter<{ isSearchShown: boolean; y: number }>();

    vm$ = this.facade.vm$.pipe(
        tap(({ searchValue, sessionFocus }) => {
            this.searchControl.patchValue(searchValue);

            const hasFocusChanged = sessionFocus && sessionFocus.activityId !== this.focusedActivityId;
            if (hasFocusChanged) {
                this.focusedActivityId = sessionFocus.activityId;
                this.scrollToActivity(sessionFocus.activityId);
            }
        }),
    );

    protected readonly isDisabledTooltip = isDisabledTooltip;

    tooltipText = tooltipText;
    tooltipTexts = tooltipTexts;
    trackById = trackById;
    emptyScreenTitles = emptyScreenTitles;
    emptyScreenImageUrlActivities = emptyScreenImageUrlActivities;
    inputPlaceholders = inputPlaceholders;
    iconColor = IconColor;
    emptyScreenDescriptions = emptyScreenDescriptions;
    activityType = ActivityType;
    iconSize = IconSize;
    selectedActivityForPreview!: Activity;

    searchControl = new FormControl<string>('', { nonNullable: true });

    readonly dataFlowTooltipComponent = ActivityDataFlowTooltipComponent;
    readonly TooltipPosition = TooltipPosition;

    private readonly searchTopPadding: number = 16;
    private focusedActivityId: string;

    constructor(private facade: ActivitiesListFacade) {
        super();
    }

    get searchControlValue(): string {
        return this.searchControl.value;
    }

    isVisibleForDesktopsAndTablets(isAllHandsetsScreen: boolean, isSessionEditor: boolean): boolean {
        return !isAllHandsetsScreen || (isAllHandsetsScreen && isSessionEditor);
    }

    toggleShowSearch(element: HTMLDivElement, isSearchShown: boolean): void {
        this.facade.toggleShowSearch();

        const searchOffsetTop = element?.offsetTop - this.searchTopPadding;
        this.onSearchActive.emit({ isSearchShown: !isSearchShown, y: searchOffsetTop });
    }

    async addActivityBetween(
        previousSequence: string,
        nextSequence: string,
        nextActivityIndex: number,
        event?: Event,
    ): Promise<void> {
        event?.stopPropagation();
        this.toggleActivitiesPanel(true);
        this.facade.setDataForBetweenInsertion(previousSequence, nextSequence, nextActivityIndex);
    }

    isNavigationDisabled(selfNavigate: boolean, isLeader: boolean): boolean {
        return isLeader ? false : !selfNavigate;
    }

    navigateToActivity(
        event: Event,
        activity: Activity,
        session: Session,
        isSessionEditor: boolean,
        isPublicTemplate: boolean,
        isEdit?: boolean,
    ): void {
        if (event) {
            event.stopPropagation();
        }

        if (!session.selfNavigate && !isSessionEditor && !isPublicTemplate) {
            return;
        }

        this.facade.navigateToActivity(activity, isEdit);
    }

    getLink(event: Event, activity: Activity): void {
        event.stopPropagation();
        this.facade.getLinkAccessWithActivity(activity);
    }

    isDataTakenFromCurrentSession(connectionsByDestinationId: DataFlowConnection[]): boolean {
        return connectionsByDestinationId?.[0].destinationSessionId !== connectionsByDestinationId?.[0].sourceSessionId;
    }

    getConnectionSessionLabel(connections: DataFlowConnection[], sessionsNames: Dictionary<string>): string {
        const connection = connections?.[0];
        return connection.sourceSessionId ? sessionsNames[connection.sourceSessionId] : '';
    }

    getConnectionCriteriaLabel(connections: DataFlowConnection[]): string {
        const connection = connections?.[0];

        return dataFlowDescriptionMap[connection.filterProperty](connection.filterValue) || '';
    }

    getActivityName(activities: Activity[], activityId: string): string {
        const activity = activities.find(_ => _.id == activityId);

        return activity?.name;
    }

    getSequenceData(): SequenceData | undefined {
        return this.facade.getActivitySequence();
    }

    getIconColor(isDisabled: boolean, isFocused: boolean): IconColor {
        if (isDisabled) {
            return this.iconColor.Disabled;
        } else if (isFocused) {
            return this.iconColor.Focus;
        } else {
            this.iconColor.Primary;
        }
    }

    updateSequenceData(newActivityId: string, activities: Activity[]): void {
        this.facade.updateSequenceData(activities);
        this.scrollToActivity(newActivityId);
    }

    toggleActivitiesPanel(opened: boolean, event?: Event): void {
        event?.stopPropagation();
        this.facade.toggleActivitiesPanel(opened);
    }

    toggleActivityPreviewPanel(opened: boolean, activity?: Activity, event?: Event): void {
        event?.stopPropagation();

        if (activity) {
            this.selectedActivityForPreview = activity;
        }

        if (!opened) {
            this.selectedActivityForPreview = null;
        }

        this.facade.toggleActivityPreviewPanel(opened);
    }

    filterActivities(searchValue: string): void {
        this.facade.filterActivities(searchValue);
    }

    openAddConnectionDialog(): void {
        this.facade.openAddConnectionDialog();
    }

    deleteActivity(event: Event, activity: Activity): void {
        event.stopPropagation();
        this.facade.openDeleteConfirmationDialog(activity);
    }

    saveAsTemplate(event: Event, activityId: string): void {
        event.stopPropagation();
        this.facade.openSaveAsTemplateDialog(activityId);
    }

    updateActivityVisibility(event: Event, visible: boolean, activityId: string): void {
        event.stopPropagation();
        this.facade.updateActivityVisibility(activityId, visible);
    }

    duplicate(activity: Activity, nextSequence: string): void {
        this.facade.openDuplicateActivityDialog(activity, nextSequence);
    }

    openAiGenerateActivityDialog(): void {
        this.facade.openAiGenerateActivityDialog();
    }

    override afterDragStarted(activity: Activity): void {
        this.facade.updateActivity(activity.id, { dndDisabled: true });
    }

    override afterDragEnded(activity: Activity): void {
        this.facade.updateActivity(activity.id, { dndDisabled: false });
    }

    override afterDropped(activity: Activity, sequence: string): void {
        this.facade.updateActivity(activity.id, { sequence });
        this.facade.updateDataFlowConnections(activity, sequence);
    }

    private scrollToActivity(activityId: string): void {
        setTimeout(() => {
            const element = document?.getElementById(`activity${activityId}`);
            if (element) {
                element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        });
    }
}
