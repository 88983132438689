<ng-container *ngIf="vm$ | async as vm">
  <div class="filter-panel">
    <!-- sort by -->
    <div>
      <mat-form-field appearance="outline" floatLabel="always" class="tt9-form-field width-100">
        <mat-label>Sort By</mat-label>
        <mat-select
          [value]="vm.sortOption?.sortOption"
          [disableOptionCentering]="true"
          [panelClass]="['tt9-select-panel-custom']"
          (selectionChange)="updateCurrentSort({ collectionsSortOption: $event.value })"
        >
          <mat-option *ngFor="let option of sortByOptions | keyvalue" [value]="option.key">
            {{ option.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- / sort by -->

    <!-- Sort order -->
    <div>
      <mat-form-field appearance="outline" floatLabel="always" class="tt9-form-field width-100">
        <mat-label>Sort Order</mat-label>
        <mat-select
          [value]="vm.sortOption?.sortOrder"
          [disableOptionCentering]="true"
          [panelClass]="['tt9-select-panel-custom']"
          (selectionChange)="updateCurrentSort({ collectionsSortOrder: $event.value })"
        >
          <mat-option *ngFor="let option of sortOrderOptions | keyvalue" [value]="option.key">
            {{ option.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- / Sort order -->

    <!-- project optionsData chip list -->
    <div *ngIf="vm.optionsData?.length">
      <mat-chip-listbox hideSingleSelectionIndicator class="tt9-chip">
        <mat-chip-option
          *ngFor="let option of vm.optionsData; trackBy: trackById"
          [class.selected]="isOptionSelected(vm.selectedOptions, option.id)"
          (click)="onSelectOption(vm.selectedOptions, option)"
        >
          <span matTooltipPosition="above" matTooltipClass="tt9-arrow-tooltip-below-right" [matTooltip]="option.name">
            {{ option.name | uppercase }}
          </span>
          ({{ getOptionsUsage(vm.parentTypeTab, option) }})
        </mat-chip-option>
      </mat-chip-listbox>
    </div>
    <!-- / project optionsData chip list -->

    <!-- chips -->
  </div>
</ng-container>
