import { BaseResponse } from './response';

export class DoodleResponse extends BaseResponse {
    // add needed other fields here
    constructor(response: Partial<DoodleResponse>) {
        super(response);
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            activityItemId: this.activityItemId,
            activityId: this.activityId,
            sessionId: this.sessionId,
            userId: this.userId,
            value: this.value,
            created: this.created,
            updated: this.updated,
        };
    }
}
