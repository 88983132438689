export interface SnackbarDetails {
    title: string;
    description: string;
}

export interface SnackbarConfig {
    success: SnackbarDetails;
    inProgress: SnackbarDetails;
    error: SnackbarDetails;
}

export enum SnackBarTypes {
    Info = 'info',
    Success = 'success',
    Error = 'error',
    Warning = 'warning',
    Notification = 'notification',
    InfoWarning = 'infowarning',
}

export const errorMessageSnackbarText = 'Something went wrong. Please try again later';
export const addingTemplateInProgressSnackbarTitle = 'Adding template in progress';
export const templateIsBeingAddedToTheProjectSnackbarText = 'Session template is being added to the project';
export const templateAddedSnackbarTitle = 'Template added';
export const templateHasBeenAddedToTheProjectSnackbarText
    = 'Session template has been added to the project successfully';
export const addingTemplateErrorSnackbarTitle = 'Adding template error';
export const deletingTemplateInProgressSnackbarTitle = 'Deleting template in progress';
export const projectTemplateIsBeingDeletedSnackbarText = 'Project template is being deleted';
export const templateDeletedSnackbarTitle = 'Template deleted';
export const projectTemplateHasBeenDeletedSnackbarText = 'Project template has been deleted successfully';
export const deletingTemplateErrorSnackbarTitle = 'Deleting template error';
export const deletingSessionInProgressSnackbarTitle = 'Deleting session in progress';
export const sessionDeletedSnackbarTitle = 'Session deleted';
export const sessionHasBeenDeletedSnackbarText = 'Session has been deleted successfully';
export const creatingSessionErrorSnackbarTitle = 'Session creating error';
export const deletingSessionErrorSnackbarTitle = 'Deleting session error';
export const deletingActivityInProgressSnackbarTitle = 'Deleting activity in progress';
export const activityIsBeingDeletedSnackbarText = 'Activity is being deleted';
export const activityDeletedSnackbarTitle = 'Activity deleted';
export const activityHasBeenDeletedSnackbarText = 'Activity has been deleted successfully';
export const deletingActivityErrorSnackbarTitle = 'Deleting activity error';
export const sessionTemplateIsBeingDeletedSnackbarTitle = 'Session template is being deleted';
export const sessionTemplateHasBeenDeletedSnackbarText = 'Session template has been deleted successfully';
export const activityTemplateIsBeingDeletedSnackbarTitle = 'Activity template is being deleted';
export const activityTemplateHasBeenDeletedSnackbarText = 'Activity template has been deleted successfully';
export const templateCreationInProgressSnackbarTitle = 'Template creation in progress';
export const templateCreationErrorTitleSnackbarTitle = 'Template creation error';
export const deletingProjectInProgressSnackbarTitle = 'Deleting project in progress';
export const projectIsBeingDeletedSnackbarText = 'Project is being deleted';
export const projectHasBeenDeletedSnackbarTitle = 'Project has been deleted';
export const projectHasBeenDeletedSnackbarText = 'Project has been deleted successfully';
export const projectDeletedSnackbarTitle = 'Project deleted';
export const deletingProjectErrorSnackbarTitle = 'Deleting project error';
export const deletingCollectionInProgressSnackbarTitle = 'Deleting collection in progress';
export const collectionIsBeingDeletedSnackbarText = 'Collection is being deleted';
export const collectionHasBeenDeletedSnackbarTitle = 'Collection has been deleted';
export const collectionHasBeenDeletedSnackbarText = 'Collection has been deleted successfully';
export const collectionDeletedSnackbarTitle = 'Collection deleted';
export const deletingCollectionErrorSnackbarTitle = 'Deleting collection error';
export const deletingUserInProgressSnackbarTitle = 'Deleting user in progress';
export const deletingUserInProgressSnackbarText = (isSession: boolean): string =>
    `User is being deleted from the ${isSession ? 'session' : 'project'}`;
export const deletingUserDoneSnackbarTitle = 'User deleted';
export const deletingUserDoneSnackbarText = (isSession: boolean): string =>
    `User has been deleted from the ${isSession ? 'session' : 'project'} successfully`;
export const deletingUserFromTemplateInProgressText = 'User is being deleted from the template';
export const deletingUserFromTemplateDoneText = 'User has been deleted from the template successfully';
export const deletingUserErrorSnackbarTitle = 'Deleting user error';
export const templateCreatedSnackbarTitle = 'Template created';
export const activityDuplicatingInProgressSnackbarTitle = 'Activity duplicating in progress';
export const activityIsBeingDuplicatedSnackbarText = 'Activity is being duplicated';
export const activityDuplicatedSnackbarTitle = 'Activity duplicated';
export const activityHasBeenDuplicatedSnackbarText = 'Activity has been duplicated successfully';
export const activityDuplicatingErrorSnackbarTitle = 'Activity duplicating error';
export const invitingTeamMembersInProgressSnackbarTitle = 'Inviting team members in progress';
export const invitingTeamMembersInProgressSnackbarText = 'Session team members are being invited';
export const projectTeamMembersAreBeingInvitedSnackbarText = 'Project team members are being invited';
export const teamMembersInvitedSnackbarTitle = 'Team members invited';
export const teamMembersInvitedSnackbarText = 'Session team members have been invited successfully';
export const projectTeamMembersHaveBeenInvitedSnackbarText = 'Project team members have been invited successfully';
export const invitingUserInProgressTitle = 'Inviting user in progress';
export const invitingUserSuccessTitle = 'User invited';
export const invitingUserErrorTitle = 'Inviting user error';
export const invitingUserInProgressText = 'User is being invited to the session';
export const invitingUserSuccessText = 'User has been invited to the session successfully';
export const invitingTeamMembersErrorSnackbarTitle = 'Inviting team members error';
export const fileUploadErrorSnackBarTitle = 'File upload error';
export const fileUploadErrorMessageSnackbarText = 'Something went wrong. Please try again later or try another file';
export const suggestMergeInProgressSnackbarTitle = 'Merging responses in progress';
export const suggestMergeInProgressSnackbarText = 'Responses are being merged into new responses with comments';
export const suggestMergeDoneSnackbarTitle = 'Responses merged';
export const suggestMergeDoneSnackbarText = 'Responses have been merged into new responses with comments successfully';
export const suggestMergeErrorSnackbarTitle = 'Merging responses error';
export const autoGroupInProgressSnackbarTitle = 'Auto-grouping in progress';
export const autoGroupInProgressSnackbarText = 'Groups are automatically being assigned to the responses';
export const autoGroupDoneSnackbarTitle = 'Responses auto-grouped';
export const autoGroupDoneSnackbarText = 'Responses have been automatically grouped successfully';
export const autoGroupErrorSnackbarTitle = 'Auto-grouping error';
export const errorSnackbarText = 'Something went wrong. Please try again later';
export const clearGroupingInProgressSnackbarTitle = 'Clear grouping in progress';
export const clearGroupingInProgressSnackbarText = 'Grouping is being removed from all the responses';
export const clearGroupingDoneSnackbarTitle = 'Grouping cleared';
export const clearGroupingDoneSnackbarText = 'Grouping has been removed from all the responses successfully';
export const clearGroupingErrorSnackbarTitle = 'Clear grouping error';
export const downloadPresentationInProgressSnackbarTitle = 'Presentation download in progress';
export const downloadPresentationInProgressSnackbarText = 'Presentation will be downloaded soon';
export const downloadPresentationDoneSnackbarTitle = 'Presentation downloaded';
export const downloadPresentationDoneSnackbarText = 'Presentation has been downloaded successfully';
export const downloadPresentationErrorSnackbarTitle = 'Presentation download error';
export const reorderingSlideInProgressSnackbarTitle = 'Reordering slide in progress';
export const reorderingSlideInProgressSnackbarText = 'Slide is being reordered';
export const reorderingSlideDoneSnackbarTitle = 'Slide reordered';
export const reorderingSlideDoneSnackbarText = 'Slide has been reordered successfully';
export const reorderingSlideErrorSnackbarTitle = 'Reordering slide error';
export const deletingSlideInProgressSnackbarTitle = 'Deleting slide in progress';
export const deletingSlideInProgressSnackbarText = 'Slide is being deleted';
export const deletingSlideDoneSnackbarTitle = 'Slide deleted';
export const deletingSlideDoneSnackbarText = 'Slide has been deleted successfully';
export const deletingSlideErrorSnackbarTitle = 'Deleting slide error';
export const projectGenerationDoneSnackbarTitle = 'Project Creation';
export const projectGenerationDoneSnackbarText = 'Project created successfully';
export const sessionGenerationDoneSnackbarTitle = 'Session Creation';
export const sessionGenerationDoneSnackbarText = 'Session created successfully';
export const activityGenerationDoneSnackbarTitle = 'Activity Creation';
export const activityGenerationDoneSnackbarText = 'Activity created successfully';
export const removeUserInProgressSnackbarTitle = 'User removal in progress';
export const removeUserInProgressSnackbarText = 'User is being removed from the project';
export const removeUserDoneSnackbarTitle = 'User removal is completed';
export const removeUserDoneSnackbarText = 'User has been successfully removed from the project';
export const removeUserErrorSnackbarTitle = 'User removal error';
export const removeSessionUserInProgressSnackbarText = 'User is being removed from the session';
export const removeSessionUserDoneSnackbarText = 'User has been successfully removed from the session';
export const sessionThreadEnabledTitle = 'Session thread is turned ON';
export const sessionThreadEnabledText
    = 'You can find the Session thread by clicking the icon in the bottom right-hand corner of your screen. Collaborate with your team there!';
export const sessionThreadDisabledTitle = 'Session thread is turned OFF';
export const sessionThreadDisabledText = 'Session thread has been disabled by the Leader';
export const aiGenerationProjectSaveErrorTitle = 'Project creation error';
export const aiGenerationSessionSaveErrorTitle = 'Session creation error';
export const aiGenerationErrorTitle = 'Generation error';
export const inviteLinkTitle = 'Invite link';
export const inviteLinkGenerationInfoText = 'Generating invitation link...';
export const inviteLinkCopiedToClipBoardSuccessText = 'Invitation link copied to clipboard.';
export const inviteLinkActivityTitle = 'Invite access copied to clipboard';
export const inviteLinkActivitySuccess = 'You can now share the QR, link and password access to this activity';
export const templateLink = 'Template link';
export const templateLinkTitle = 'Template link copied to clipboard';
export const templateLinkCopiedToClipBoardSuccessText
    = 'You can now share the link and password to this project template.';
export const sessionTemplateLinkCopiedToClipBoardSuccessText
    = 'You can now share the link and password to this session template.';
export const templateTitleCopyToClipboardError = 'Template link error';
export const templateErrorMessageCopyToClipboard = 'Something went wrong. Please try again later';
export const templateLinkGenerationInfoText = 'Generating template link...';
export const templateCopyToClipboardErrorTitle = 'Invite link error';
export const templateCopyToClipboardErrorMessage = 'Failed to copy the invitation link to clipboard';
export const sessionInviteGenerationInfoTitle = 'Generating QR, link and password';
export const sessionInviteGenerationInfoMessage = 'Session invite link is being generated.';
export const sessionInviteCopytoClipboardTitle = 'QR, link and password';
export const sessionInviteCopiedtoClipboardSuccess = 'Session invite link copied to clipboard.';
export const aiActivityGenerationInProgressTitle = 'Activity creation in progress';
export const aiActivityGenerationSuccessTitle = 'Activity created';
export const aiActivityGenerationErrorTitle = 'Activity creation error';
export const aiActivityGenerationInProgressMessage = 'New activity is being created by AI';
export const aiActivityGenerationSuccessMessage = 'New activity has successfully been created by AI';
export const aiGenerationInProgressStatus = 'In Progress';
export const aiGenerationSuccessStatus = 'Success';
export const aiGenerationErrorStatus = 'Error';
export const fileUploadingTitle = 'File upload is in progress';
export const fileUploadingMessage
    = 'It may take some time as the file is going through the antivirus scanning. Please do not leave the page until the downloading is finished';
export const fileUploadingCancelBtn = 'Cancel Loading';

// collections
export const createCollectionErrorTitle = 'Collection creating error';
export const moveToCollectionTitle = 'Moving Session to another collection in progress';
export const moveToCollectionText = (collectionName: string): string =>
    `Session is being moved to another ${collectionName}`;
export const moveToCollectionDoneTitle = 'Session moved to another collection';
export const moveToCollectionDoneText = (collectionName: string): string =>
    `Session has been moved to another ${collectionName}`;
export const moveToCollectionErrorTitle = 'Moving Session error';
export const deleteCollectionInProgressSnackbar = {
    title: 'Collection deletion in progress',
    description: 'Deleting of collection is in progress',
};
export const deleteCollectionSuccessSnackbar = {
    title: 'Collection deleted',
    description: 'Collection has been deleted successfully',
};
export const deleteCollectionErrorSnackbar = {
    title: 'Collection deletion error',
    description: 'Something went wrong. Please try again later.',
};
export const ungroupCollectionInProgressSnackbar = {
    title: 'Collection ungrouping in progress',
    description: 'Ungrouping of sessions from collection is in progress',
};
export const ungroupCollectionSuccessSnackbar = {
    title: 'Collection ungrouped',
    description: 'Collection has been ungrouped successfully',
};
export const ungroupCollectionErrorSnackbar = {
    title: 'Collection ungrouping error',
    description: 'Something went wrong. Please try again later.',
};

// live share
export const deleteLiveShareResourceTitle = 'Resource deleted';
export const deleteLiveShareResourceText = 'LiveShare resource has been deleted successfully';
export const deleteLiveShareResourceError = 'Deleting resource error';
export const deleteLiveShareResourceInProgressTitle = 'Deleting resource in progress';
export const deleteLiveShareResourceInProgressText = 'LiveShare resource is being deleted';

export const getSnackBarTextForCollectionSession = (type: SnackBarTypes, isMultipleSessions: boolean) => {
    const titles = {
        info: `Adding ${isMultipleSessions ? 'sessions' : 'session'} to collection in progress`,
        success: `${isMultipleSessions ? 'Sessions' : 'Session'} added to collection`,
        error: `Adding ${isMultipleSessions ? 'sessions' : 'session'} error`,
    };

    const descriptions = {
        info: `${isMultipleSessions ? 'Sessions are' : 'Session is'} being added to `,
        success: `${isMultipleSessions ? 'Sessions have' : 'Session has'} been added to `,
        error: 'Something went wrong. Please try again later',
    };

    return {
        title: titles[type],
        description: descriptions[type],
    };
};
