<ng-container *ngIf="!!connectionsForm">
  <form class="dialog-content" [formGroup]="connectionsForm">
    <div class="dialog-content-item activities" formGroupName="activities">
      <!-- Source Session for project -->
      <p class="tt9-caption-2 tt9-color-neutral-500 mb-32">Data source</p>
      <mat-form-field *ngIf="sessions.length" appearance="outline" class="tt9-form-field width-100 mb-24">
        <mat-select
          formControlName="sourceSessionId"
          [disableOptionCentering]="true"
          [panelClass]="['tt9-select-panel-custom']"
          (selectionChange)="setActualSessionsData(formFieldName.SourceSession)"
        >
          <mat-option *ngFor="let session of sourceSessions; trackBy: trackById" [value]="session">
            <span
              #sessionName
              class="session-option text-overflow-ellipsis"
              matTooltipClass="tt9-arrow-tooltip-above"
              [matTooltip]="session.name"
              [matTooltipDisabled]="isDisabledTooltip(sessionName)"
            >
              {{ session.name }}
            </span>
          </mat-option>
        </mat-select>
        <mat-label>Source session</mat-label>

        <mat-error *ngIf="hasRequiredError(formFieldName.SourceSession)">{{ validationMessages.required }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="tt9-form-field width-100">
        <mat-select
          multiple
          formControlName="source"
          [accentureDisabledControl]="isActivitySelectDisabled(!!sessions.length, formFieldName.SourceSession)"
          [disableOptionCentering]="true"
          [panelClass]="['tt9-select-panel-with-checkboxes', 'tt9-select-panel-custom']"
          (selectionChange)="setActualActivitiesData(true)"
        >
          <mat-option
            *ngFor="let option of sourceActivities; trackBy: trackById"
            [value]="option"
            [disabled]="checkActivityIdsAvailability(option)"
          >
            <span
              #optionName
              class="source-option text-overflow-ellipsis"
              matTooltipClass="tt9-arrow-tooltip-above"
              [matTooltip]="option.name"
              [matTooltipDisabled]="isDisabledTooltip(optionName)"
            >
              {{ option.name }}
            </span>
          </mat-option>
        </mat-select>
        <mat-label>Source activity</mat-label>
        <mat-error *ngIf="hasRequiredError(formFieldName.Source)">{{ validationMessages.required }}</mat-error>
      </mat-form-field>

      <ng-container
        *ngTemplateOutlet="selectedActivityItems; context: { tagValue: formFieldName.Source }"
      ></ng-container>

      <ng-container *ngIf="showExistingDestination && !currentActivity">
        <!-- Destination Session for project -->
        <p class="tt9-caption-2 tt9-color-neutral-500 mt-32 mb-24">Data destination</p>
        <mat-form-field *ngIf="sessions.length" appearance="outline" class="tt9-form-field width-100 mb-32">
          <mat-select
            formControlName="destinationSessionId"
            [disableOptionCentering]="true"
            [panelClass]="['tt9-select-panel-custom']"
            (selectionChange)="setActualSessionsData(formFieldName.DestinationSession)"
          >
            <mat-option
              *ngFor="let session of destinationSessions; trackBy: trackById"
              [value]="session"
              [disabled]="isSessionInDropdownDisabled(session.sequence)"
            >
              {{ session.name }}
            </mat-option>
          </mat-select>
          <mat-label>Destination session</mat-label>

          <mat-error *ngIf="hasRequiredError(formFieldName.DestinationSession)">{{
            validationMessages.required
          }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="tt9-form-field width-100">
          <mat-select
            multiple
            formControlName="destination"
            [accentureDisabledControl]="isActivitySelectDisabled(!!sessions.length, formFieldName.DestinationSession)"
            [disableOptionCentering]="true"
            [panelClass]="['tt9-select-panel-with-checkboxes', 'tt9-select-panel-custom']"
            (selectionChange)="setActualActivitiesData()"
          >
            <mat-option
              *ngFor="let option of destinationActivities; trackBy: trackById"
              [value]="option"
              [disabled]="
                hasDestinationActivityConnection(option.id, option.type) ||
                isDestinationMatch(option) ||
                isOneDestinationAllow(option)
              "
            >
              <span
                #destinationOptionName
                class="destination-option text-overflow-ellipsis"
                matTooltipClass="tt9-arrow-tooltip-above"
                [matTooltip]="option.name"
                [matTooltipDisabled]="isDisabledTooltip(destinationOptionName)"
              >
                {{ option.name }}
              </span>
            </mat-option>
          </mat-select>

          <mat-label>Destination activity</mat-label>
          <mat-error *ngIf="hasRequiredError(formFieldName.Destination)">{{ validationMessages.required }}</mat-error>
        </mat-form-field>

        <ng-container
          *ngTemplateOutlet="selectedActivityItems; context: { tagValue: formFieldName.Destination }"
        ></ng-container>

        <ng-container *ngIf="isDestinationTable">
          <mat-form-field appearance="outline" class="tt9-form-field width-100 mt-24">
            <mat-select
              multiple
              formControlName="destinationActivityItem"
              [disableOptionCentering]="true"
              [panelClass]="['tt9-select-panel-with-checkboxes', 'tt9-select-panel-custom']"
              (selectionChange)="resetOptionsToDefaultState()"
            >
              <mat-option
                *ngFor="let table of destinationActivityItems; trackBy: trackById"
                [value]="table"
                [disabled]="tableDisabledState(table.id)"
              >
                <span
                  #destinationTableName
                  class="destination-option text-overflow-ellipsis"
                  matTooltipClass="tt9-arrow-tooltip-above"
                  [matTooltip]="table.title"
                  [matTooltipDisabled]="isDisabledTooltip(destinationTableName)"
                >
                  {{ table.title }}
                </span>
              </mat-option>
            </mat-select>

            <mat-label>Destination table</mat-label>
            <mat-error *ngIf="hasRequiredError(formFieldName.DestinationActivityItem)">{{
              validationMessages.required
            }}</mat-error>
          </mat-form-field>

          <ng-container
            *ngTemplateOutlet="selectedActivityItems; context: { tagValue: formFieldName.DestinationActivityItem }"
          ></ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!showExistingDestination && !sessions?.length">
        <mat-form-field appearance="outline" class="tt9-form-field mb-16 width-100 mt-32">
          <input formControlName="activityName" matInput placeholder="Destination Activity Name" />
          <mat-label>Destination Activity Name</mat-label>
          <mat-error *ngIf="hasRequiredError(formFieldName.ActivityName)">{{ validationMessages.required }}</mat-error>
        </mat-form-field>

        <mat-radio-group class="group mb-24" formControlName="activityType">
          <p class="tt9-caption-2 tt9-color-neutral-500 mb-16">Type</p>

          <mat-radio-button
            *ngFor="let activityType of destinationActivityTypes; let i = index"
            class="tt9-radio-button option"
            color="primary"
            [value]="activityType.value"
          >
            {{ activityType.name }}
          </mat-radio-button>
        </mat-radio-group>

        <button class="tt9-btn-default" (click)="changeDestination()">Select Existing Activity</button>
      </ng-container>
    </div>

    <!-- Here is Source Options View -->
    <div *ngIf="sourceOptionsVisible" class="dialog-content-item filters" formGroupName="options">
      <ng-container *ngIf="isEqualActivityTypes(formFieldName.Source, [activityType.Brainstorm])">
        <section class="mb-24">
          <p class="tt9-caption-2 tt9-color-neutral-500 mb-16">Topics</p>

          <mat-form-field appearance="outline" class="tt9-form-field width-100">
            <mat-label>Topic</mat-label>
            <mat-select
              multiple
              formControlName="topics"
              [disableOptionCentering]="true"
              [panelClass]="['tt9-select-panel-custom', 'tt9-select-panel-with-checkboxes']"
              (selectionChange)="changeTopicSelection($event.value)"
            >
              <mat-option
                [value]="allTopicsValue"
                [disabled]="!topicOptionsIds.length"
                (click)="setLastCheckedTopicValue(allTopicsValue)"
              >
                {{ dataFlowFiltersTitles[dataFlowFilters.All] }}
              </mat-option>

              <ng-container *ngIf="topicOptionsIds.length">
                <ng-container *ngFor="let activityId of topicOptionsIds; trackBy: trackByValue">
                  <mat-optgroup *ngIf="groupedActivityItems[activityId]" class="topic-optgroup">
                    <mat-option [value]="activityId" (click)="setLastCheckedTopicValue(activityId)">
                      <span
                        #activityName
                        class="topic-optgroup-option text-overflow-ellipsis"
                        matTooltipClass="tt9-arrow-tooltip-above"
                        [matTooltip]="getActivityName(activityId)"
                        [matTooltipDisabled]="isDisabledTooltip(activityName)"
                      >
                        {{ getActivityName(activityId) }}
                      </span>
                    </mat-option>

                    <mat-option
                      *ngFor="let topic of groupedActivityItems[activityId]; trackBy: trackById"
                      class="child-option"
                      [value]="topic.id"
                      (click)="setLastCheckedTopicValue(topic.id, activityId)"
                    >
                      <span
                        #topicLabel
                        class="child-option-label text-overflow-ellipsis"
                        matTooltipClass="tt9-arrow-tooltip-above"
                        [matTooltip]="topic.label.default"
                        [matTooltipDisabled]="isDisabledTooltip(topicLabel)"
                      >
                        {{ topic.label["default"] }}
                      </span>
                    </mat-option>
                  </mat-optgroup>
                </ng-container>
              </ng-container>
            </mat-select>

            <mat-error *ngIf="hasRequiredError(formFieldName.Topics)">{{ validationMessages.required }}</mat-error>
          </mat-form-field>

          <ng-container *ngIf="topicOptionsIds.length">
            <ng-container *ngFor="let activityId of topicOptionsIds; trackBy: trackByValue">
              <div class="mb-8">
                <p class="tt9-caption-1 topic-label">
                  {{ getActivityName(activityId) }}
                </p>

                <mat-chip-listbox
                  hideSingleSelectionIndicator
                  *ngIf="topicsIdByActivityId[activityId]?.checked?.length; else validation"
                  class="chip-list tt9-chip"
                >
                  <mat-chip-option
                    *ngFor="let topicId of getTopicsChecked(activityId); trackBy: trackByValue"
                    (removed)="removeTopicTag(activityId, topicId)"
                  >
                    <span
                      [matTooltip]="topicsNameById[topicId]"
                      matTooltipPosition="above"
                      matTooltipClass="tt9-arrow-tooltip-below-right"
                      >{{ topicsNameById[topicId] }}
                    </span>
                    <accenture-icon matChipRemove>icon-close</accenture-icon>
                  </mat-chip-option>
                </mat-chip-listbox>

                <ng-template #validation>
                  <mat-error
                    *ngIf="connectionsForm?.get(formFieldName.Topics)?.hasError(validationErrors.noSelectedTopics)"
                    class="tt9-mat-error"
                  >
                    {{ validationMessages.noSelectedTopics }}
                  </mat-error>
                </ng-template>
              </div>
            </ng-container>
          </ng-container>
        </section>

        <section class="mb-24">
          <p class="tt9-caption-2 tt9-color-neutral-500 mb-16">Content to Move</p>
          <mat-radio-group class="group" formControlName="contentToMove" (change)="onContentToMoveChange($event.value)">
            <mat-radio-button
              *ngFor="let option of dataFlowFiltersContentToMove[destinationType] || []; let i = index"
              color="primary"
              class="tt9-radio-button option"
              [value]="option.value"
            >
              {{ option.title }}
            </mat-radio-button>
          </mat-radio-group>
        </section>
      </ng-container>

      <section *ngIf="(!isCommentsOnly && !isTopicsOnly) || isVoteToTable" class="mb-24">
        <p class="tt9-caption-2 tt9-color-neutral-500 mb-16">
          Filter for
          {{ isEqualActivityTypes(formFieldName.Source, activityType.Vote) ? "Ballot Items" : "Responses" }}
        </p>

        <mat-radio-group class="group" formControlName="filter" (change)="onFilterChange($event.value)">
          <ng-container *ngFor="let option of filtersOptionsData; let i = index">
            <mat-radio-button
              *ngIf="showVoteAdditionalFilters(option.displayActivityParameter)"
              color="primary"
              class="tt9-radio-button option"
              [class.parameter-option]="option?.displayParameter"
              [value]="option.value"
            >
              {{ option.title }}

              <ng-container *ngIf="showActivityParameter(option.displayParameter)">
                <mat-form-field class="parameter tt9-form-field">
                  <input
                    matInput
                    type="number"
                    min="1"
                    [formControlName]="option.value"
                    [accenturePositiveNumbersOnly]="connectionsForm?.get('options')?.get(option.value)"
                    [accenturePositiveNumbersOnlyRange]="true"
                  />
                </mat-form-field>
                <span class="parameter-text">
                  {{
                    isEqualActivityTypes(formFieldName.Source, activityType.Vote) ? "voted items" : "liked responses"
                  }}</span
                >

                <ng-container *ngIf="option.displayActivityParameter">
                  <span>from</span>
                  <mat-select
                    #activityParameter
                    multiple
                    placeholder="Select Values"
                    class="parameter-items"
                    matTooltipPosition="above"
                    matTooltipClass="tt9-arrow-tooltip-below-formatted"
                    [matTooltip]="activityParameter.value ? getActivityParameterTooltip() : ''"
                    [formControlName]="option.parameterValue"
                    [disableOptionCentering]="true"
                    [panelClass]="[
                      'tt9-select-panel-custom',
                      'tt9-select-panel-with-checkboxes',
                      'data-flow-form-select-panel'
                    ]"
                  >
                    <ng-container
                      *ngFor="let activity of getFormControlValue(formFieldName.Source); trackBy: trackById"
                    >
                      <mat-optgroup
                        *ngIf="filterParametersMap[activity.id]"
                        class="parameter-optgroup"
                        [label]="activity?.name"
                      >
                        <mat-option
                          *ngFor="let activityItem of filterParametersMap[activity.id]; trackBy: trackById"
                          [value]="activityItem.id"
                          [disabled]="parameterDisabledState(activity.id, activityItem?.id)"
                          (click)="setActualParametersData($event, activity.id, activityItem?.id)"
                        >
                          <span
                            #activityItemLabel
                            class="parameter-optgroup-option text-overflow-ellipsis"
                            matTooltipClass="tt9-arrow-tooltip-above"
                            [matTooltip]="activityItem?.label?.default"
                            [matTooltipDisabled]="isDisabledTooltip(activityItemLabel)"
                          >
                            {{ activityItem?.label?.default }}
                          </span>
                        </mat-option>
                      </mat-optgroup>
                    </ng-container>
                  </mat-select>
                </ng-container>
              </ng-container>
            </mat-radio-button>
          </ng-container>

          <mat-error
            *ngIf="connectionsForm?.get(formFieldName.Filter)?.hasError(validationErrors.noParameters)"
            class="tt9-mat-error"
          >
            {{ validationMessages.noValidCriteria }} in
            <span *ngFor="let activityName of noParametersActivitiesNames"> {{ activityName }}</span>
          </mat-error>

          <mat-error
            *ngIf="
              connectionsForm?.get(formFieldName.Filter)?.hasError(validationErrors.noSelectedParameters) &&
              noSelectedParametersNames.length
            "
            class="tt9-mat-error"
          >
            {{ validationMessages.noSelectedCriteria }} from
            <span *ngFor="let activityName of noSelectedParametersNames"> {{ activityName }}</span>
          </mat-error>
        </mat-radio-group>
      </section>

      <section *ngIf="(isTransformToFiltersShown && !isTopicsOnly) || isVoteToTable" class="mb-24">
        <p class="tt9-caption-2 tt9-color-neutral-500 mb-16">
          {{ transformIntoTitle }}
        </p>

        <mat-radio-group
          class="group"
          formControlName="transformIntoResponses"
          (change)="changeTransformInto($event.value)"
        >
          <mat-radio-button
            *ngFor="let option of dataFlowFiltersTransformInto[destinationType]; let i = index"
            color="primary"
            class="tt9-radio-button option"
            [value]="option.value"
            [disabled]="isTransformIntoOptionDisabled(option)"
          >
            {{ option?.title }}
          </mat-radio-button>
        </mat-radio-group>

        <div *ngIf="getFormControlValue(formFieldName.TransformInto) && isDestinationTable" class="ml-32">
          <mat-form-field appearance="outline" class="tt9-form-field mt-24 width-100">
            <mat-select
              multiple
              formControlName="destinationColumn"
              [disableOptionCentering]="true"
              [panelClass]="['tt9-select-panel-with-checkboxes', 'tt9-select-panel-custom']"
            >
              <mat-option
                *ngFor="let column of getColumnsBySelectedActivityItemValue(); trackBy: trackById"
                [value]="column"
                [disabled]="columnDisabledState(column.id)"
              >
                <span
                  #columnId
                  class="destination-option text-overflow-ellipsis"
                  matTooltipClass="tt9-arrow-tooltip-above"
                  [matTooltip]="column.title"
                  [matTooltipDisabled]="isDisabledTooltip(columnId)"
                >
                  {{ column.title }}
                </span>
              </mat-option>
            </mat-select>

            <mat-label>Destination column</mat-label>
            <mat-error *ngIf="hasRequiredError(formFieldName.DestinationColumn)">{{
              validationMessages.required
            }}</mat-error>
          </mat-form-field>

          <ng-container
            *ngTemplateOutlet="selectedActivityItems; context: { tagValue: formFieldName.DestinationColumn }"
          ></ng-container>
        </div>
      </section>
    </div>
  </form>

  <div class="accenture-dialog-actions">
    <button class="tt9-btn-default mr-16" [disabled]="isResetAllButtonDisabled" (click)="resetForm()">Reset All</button>
    <button class="tt9-btn" [disabled]="connectionsForm.invalid" (click)="saveData()">Save</button>
  </div>

  <ng-template #selectedActivityItems let-tagValue="tagValue">
    <mat-chip-listbox hideSingleSelectionIndicator class="chip-list tt9-chip">
      <mat-chip-option
        *ngFor="let activityTag of getFormControlValue(tagValue); trackBy: trackById"
        (removed)="removeTag(activityTag.id, tagValue)"
      >
        <span
          [matTooltip]="activityTag?.name || activityTag?.title"
          matTooltipPosition="above"
          matTooltipClass="tt9-arrow-tooltip-below-right"
          >{{ activityTag?.name || activityTag?.title }}</span
        >
        <accenture-icon matChipRemove>icon-close</accenture-icon>
      </mat-chip-option>
    </mat-chip-listbox>
  </ng-template>
</ng-container>
