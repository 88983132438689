import { Action, createReducer, on } from '@ngrx/store';

import { Activity, Attribute, AttributeClass, Dictionary, Session, TeamMember, TeamMemberSnackBarsVisibilityOption, CollectionsDisplayMode } from '@accenture/shared/data';

import {
    clearSessionSubscriptions,
    getSessionActivitiesDataSuccess,
    getSessionDataSuccess,
    getSessionTeamMemberDataSuccess,
    setSessionsDisplayMode,
} from './session.actions';

export const SESSION_FEATURE_KEY = 'session';

export interface SessionState {
    attributes: Attribute[];
    attributeClasses: AttributeClass[];
    session: Session;
    sessionTeamMember: TeamMember;
    sessionActivities: Activity[];
    sessionTeamMemberSnackBarsVisibility: Dictionary<TeamMemberSnackBarsVisibilityOption>;
    displayMode: CollectionsDisplayMode;
}

const initialState: SessionState = {
    session: {} as Session,
    sessionTeamMember: {} as TeamMember,
    sessionActivities: [] as Activity[],
    attributes: [],
    sessionTeamMemberSnackBarsVisibility: {} as Dictionary<TeamMemberSnackBarsVisibilityOption>,
    displayMode: CollectionsDisplayMode.IconsView,
    attributeClasses: [],
};

const _sessionReducer = createReducer(
    initialState,

    on(getSessionDataSuccess, (state, { session, attributeClasses, attributes }) => ({
        ...state,
        session,
        attributeClasses,
        attributes,
    })),

    on(getSessionTeamMemberDataSuccess, (state, { teamMember }) => ({
        ...state,
        sessionTeamMember: teamMember,
    })),

    on(getSessionActivitiesDataSuccess, (state, { activities }) => ({
        ...state,
        sessionActivities: activities,
    })),

    on(clearSessionSubscriptions, (state) => ({
        ...state,
        session: {} as Session,
        sessionTeamMember: {} as TeamMember,
        sessionActivities: [] as Activity[],
        attributeClasses: [],
        attributes: [],
    })),

    on(setSessionsDisplayMode, (state, { displayMode }) => ({
        ...state,
        displayMode,
    })),
);

export function sessionReducer(state: any, action: Action) {
    return _sessionReducer(state, action);
}
