import { ImportResultType, ParentType } from '@accenture/shared/data';

export const tooltipText = {
    [ImportResultType.FormQuestions]: () =>
        'Only excel files can be uploaded. Please specify questions in the first column of the first sheet with their descriptions in the second column of the first sheet.',
    [ImportResultType.BrainstormResponses]: () =>
        'Only excel files can be uploaded. Please specify responses in the first column of the first sheet.',
    [ImportResultType.VoteItems]: () =>
        'Only excel files can be uploaded. Please specify ballot items in the first column of the first sheet.',
    [ImportResultType.Emails]: (parentType: ParentType): string =>
        parentType === ParentType.Projects
            ? 'Only excel files can be uploaded. Please specify emails of users to be invited in the first column of the first sheet with their project roles in the second column of the first sheet.'
            : 'Only excel files can be uploaded. Please specify emails of users to be invited in the first column of the first sheet with their session roles in the second column of the first sheet.',
    [ImportResultType.Attributes]: () =>
        'Only excel files can be uploaded. Please specify attributes in the first column of the first sheet.',
};
