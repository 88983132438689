import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap, take } from 'rxjs/operators';

import { InvitedUsersService, TeamMemberService } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectActivityIdAndParentIds, selectAuthenticatedUserId } from '@accenture/global-store';
import {
    errorMessageSnackbarText,
    InvitedUser,
    invitingTeamMembersErrorSnackbarTitle,
    invitingTeamMembersInProgressSnackbarTitle,
    ParentType,
    PublicAccess,
    TeamMember,
    teamMembersInvitedSnackbarTitle,
} from '@accenture/shared/data';
import { SnackbarService } from '@accenture/shared/ui';

import { TemplateInviteDialogComponent } from './template-invite-dialog.component';

export interface TemplateInviteDialogViewModel {
    teamMemberEmails: string[];
    isLoading: boolean;
}

const defaultViewModel: TemplateInviteDialogViewModel = {
    teamMemberEmails: [] as string[],
    isLoading: true,
};

@Injectable()
export class TemplateInviteDialogFacade {
    private publicTemplate$ = new BehaviorSubject<PublicAccess>({} as PublicAccess);
    private isLoading$ = new BehaviorSubject<boolean>(false);

    vm$ = this.buildViewModel();

    private parentType!: ParentType;
    private parentId!: string;
    private userId!: string;

    constructor(
        private store: Store<AppState>,
        private teamMemberService: TeamMemberService,
        private snackbarService: SnackbarService,
        private invitedUsersService: InvitedUsersService,
        public dialogRef: MatDialogRef<TemplateInviteDialogComponent>,
    ) {}

    setPublicData(publicTemplate?: PublicAccess): void {
        if (!publicTemplate) {
            return;
        }

        this.publicTemplate$.next(publicTemplate);
    }

    async setNewInvitedEmails(emailsToInvite: string[]): Promise<void> {
        const newInvitedEmails: InvitedUser[] = [];
        const invitedUsersEmailsInApplication = await firstValueFrom(
            this.invitedUsersService.getInvitedUsersEmails(this.userId).pipe(take(1)),
        );

        for (const currentInvitedUserEmail of emailsToInvite) {
            if (!invitedUsersEmailsInApplication.includes(currentInvitedUserEmail)) {
                const invitedUserEmail: InvitedUser = {
                    invitingUserId: this.userId,
                    email: currentInvitedUserEmail,
                };

                newInvitedEmails.push(invitedUserEmail);
            }
        }

        if (newInvitedEmails.length) {
            await this.invitedUsersService.setInvitedUsers(newInvitedEmails);
        }
    }

    async invite(emailsToInvite: string[], inviteMessage: string): Promise<void> {
        this.snackbarService.showInfoSnackBar(
            invitingTeamMembersInProgressSnackbarTitle,
            'Template team members are being invited',
        );
        this.dialogRef.close();
        try {
            await this.setNewInvitedEmails(emailsToInvite);
            await this.teamMemberService.inviteTeamMembersToTemplate(
                this.parentType,
                this.parentId,
                emailsToInvite,
                inviteMessage,
            );

            this.snackbarService.showSuccessSnackBar(
                teamMembersInvitedSnackbarTitle,
                'Template team members have been invited successfully',
            );
        } catch (e) {
            console.error(e);
            this.snackbarService.showErrorSnackBar(invitingTeamMembersErrorSnackbarTitle, errorMessageSnackbarText);
        }
    }

    private buildViewModel(): Observable<TemplateInviteDialogViewModel> {
        return combineLatest([
            this.store.select(selectActivityIdAndParentIds),
            this.store.select(selectAuthenticatedUserId),
            this.publicTemplate$.asObservable().pipe(distinctUntilChanged()),
        ]).pipe(
            switchMap(([{ parentType, parentId }, userId, publicTemplate]) => {
                this.parentType = !!publicTemplate?.templateType ? publicTemplate.templateType : parentType;
                this.parentId = !!publicTemplate?.templateId ? publicTemplate.templateId : parentId;
                this.userId = userId;

                return combineLatest([
                    this.getActiveTeamMembersEmails(this.parentType, this.parentId),
                    this.isLoading$.asObservable().pipe(distinctUntilChanged()),
                ]);
            }),
            map(([teamMemberEmails, isLoading]) => {
                return {
                    teamMemberEmails,
                    isLoading,
                };
            }),
            startWith(defaultViewModel),
        );
    }

    private getActiveTeamMembersEmails(parentType: ParentType, parentId: string): Observable<string[]> {
        return this.teamMemberService.getTeamMembersByParent(parentType, parentId).pipe(
            map((teamMembers: TeamMember[]) => {
                return (teamMembers || []).map(user => user.email);
            }),
        );
    }
}
