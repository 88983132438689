import { InAppActivityItemStatusChangeNotification } from './in-app-activity-status-change';
import {
    InAppNotification,
    InAppNotificationType,
} from './in-app-notification-type';
import { InAppProjectInviteNotification } from './in-app-project-invite-notification';
import { InAppProjectRoleChangeNotification } from './in-app-project-role-change-notification';
import { InAppSessionBulkInviteNotification } from './in-app-session-bulk-invite-notification';
import { InAppSessionInviteTT9Notification } from './in-app-session-invite-tt9-notification';
import { InAppSessionRecordingNotification } from './in-app-session-recording-notification';
import { InAppSessionRoleChangeNotification } from './in-app-session-role-change-notification';
import { InAppSessionThreadNotification } from './in-app-session-thread-notification';
import { InAppTemplateInviteNotification } from './in-app-template-invite-notification';
import { InAppTemplatePublishNotification } from './in-app-template-publish-notification';
import { InAppVirusDetectedInUploadNotification } from './in-app-virus-detected-in-upload-notification';

export class InAppNotificationFactory {
    static create(notification: any & { type: InAppNotificationType }): InAppNotification {
        switch (notification.type) {
            case InAppNotificationType.ProjectInvite: {
                return new InAppProjectInviteNotification(notification);
            }
            case InAppNotificationType.ProjectRoleChange: {
                return new InAppProjectRoleChangeNotification(notification);
            }
            case InAppNotificationType.SessionRoleChange: {
                return new InAppSessionRoleChangeNotification(notification);
            }
            case InAppNotificationType.SessionInvite: {
                return new InAppSessionInviteTT9Notification(notification);
            }
            case InAppNotificationType.SessionBulkInvite: {
                return new InAppSessionBulkInviteNotification(notification);
            }
            case InAppNotificationType.ActivityItemStepChange: {
                return new InAppActivityItemStatusChangeNotification(notification);
            }
            case InAppNotificationType.TemplateInvite: {
                return new InAppTemplateInviteNotification(notification);
            }
            case InAppNotificationType.TemplatePublish: {
                return new InAppTemplatePublishNotification(notification);
            }
            case InAppNotificationType.UpdatePublishedTemplate: {
                return new InAppTemplatePublishNotification(notification);
            }
            case InAppNotificationType.VirusDetectedInUpload: {
                return new InAppVirusDetectedInUploadNotification(notification);
            }

            case InAppNotificationType.SessionRecording: {
                return new InAppSessionRecordingNotification(notification);
            }

            case InAppNotificationType.SessionThread: {
                return new InAppSessionThreadNotification(notification);
            }
            default:
                throw 'Invalid in-app notification type';
        }
    }
}
