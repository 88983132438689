import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import firebase from 'firebase/compat/app';
import { Timestamp } from 'firebase/firestore';
import { isEqual } from 'lodash';
import { Observable, Subject, tap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fromToDateValidator } from '@accenture/erp-deployment/shared/domain';
import {
    CollectionFilters,
    CollectionOptions,
    displayRole,
    initialCollectionFilters,
    inputPlaceholders,
    optionsPanelTitle,
    ParentType,
    SortOptions,
    SortOrders,
    sortOrdersValues,
    TemplateTab,
    templateTabValue,
    tooltipTexts,
} from '@accenture/shared/data';
import { trackById, trackByValue } from '@accenture/shared/util';

import {
    CollectionFiltersAndSortFacade,
    CollectionsFilterAndSortViewModel,
} from './collection-filters-and-sort-facade';

type FiltersAndSortForm = FormGroup<{
    fromDate: FormControl<Date | null>;
    toDate: FormControl<Date | null>;
    sortOption: FormControl<SortOptions>;
    sortOrder: FormControl<SortOrders | null>;
}>;

@Component({
    selector: 'accenture-collection-filters-and-sort',
    templateUrl: './collection-filters-and-sort.component.html',
    styleUrls: ['./collection-filters-and-sort.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [CollectionFiltersAndSortFacade],
})
export class CollectionFiltersAndSortComponent implements OnInit, OnDestroy {
    vm$: Observable<CollectionsFilterAndSortViewModel> = this.facade.vm$.pipe(
        tap(({ filtersAndSort }) => this.updateFormValues(filtersAndSort)),
    );

    trackById = trackById;
    trackByValue = trackByValue;
    filtersAndSortForm: FiltersAndSortForm;
    collectionOptions = CollectionOptions;
    parentTypeList = ParentType;
    roleDisplayName = displayRole;
    sortOrdersValues = sortOrdersValues;
    inputPlaceholders = inputPlaceholders;
    tooltipTexts = tooltipTexts;
    optionPanelTitle: string;
    templateTabValue = templateTabValue;
    templateTab = TemplateTab;

    private pickedDate: Date;
    private onDestroy = new Subject<void>();

    constructor(private fb: FormBuilder, private facade: CollectionFiltersAndSortFacade) {}

    get isToDateGrFromDateErrorVisibility(): boolean {
        const toDateControl = this.filtersAndSortForm && this.filtersAndSortForm.get('toDate');

        return toDateControl && toDateControl.hasError('invalidDate');
    }

    get resetAllLinkDisabled(): boolean {
        return isEqual(this.filtersAndSortForm.value, initialCollectionFilters);
    }

    initializeFiltersAndSortForm(): void {
        this.filtersAndSortForm = this.fb.group(
            {
                fromDate: new FormControl(null),
                toDate: new FormControl(null),
                sortOption: new FormControl({ value: null, disabled: false }),
                sortOrder: new FormControl({ value: null, disabled: false }),
            },
            {
                updateOn: 'blur',
                validators: fromToDateValidator('fromDate', 'toDate'),
            },
        );
    }

    clearDateInput(controlName: string, event: Event): void {
        event.stopPropagation();
        this.filtersAndSortForm.get(controlName).reset();
    }

    resetFiltersAndSortForm(): void {
        const resetValue = initialCollectionFilters;

        this.filtersAndSortForm.reset({
            ...initialCollectionFilters,
            fromDate: resetValue.fromDate?.toDate() || null,
            toDate: resetValue.toDate?.toDate() || null,
        });
    }

    openFilterAndSortPanel(event: Event, optionType: CollectionOptions): void {
        this.optionPanelTitle = optionsPanelTitle[optionType];
        this.facade.setOptionType(optionType);

        if (event) {
            event.stopPropagation();
        }
    }

    ngOnInit(): void {
        this.initializeFiltersAndSortForm();
        this.filtersAndSortForm.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((formData) => {
            const pickedDate = formData.toDate ? new Date(formData.toDate) : null;
            const fromDate = formData.fromDate ? firebase.firestore.Timestamp.fromDate(formData.fromDate) : null;

            if (!isEqual(this.pickedDate, pickedDate)) {
                pickedDate?.setDate(pickedDate.getDate() + 1);
                pickedDate?.setSeconds(pickedDate.getSeconds() - 1);
                this.pickedDate = pickedDate;
            }

            const toDate = pickedDate ? firebase.firestore.Timestamp.fromDate(pickedDate) : null;
            const filtersData = {
                ...formData,
                toDate: toDate as Timestamp,
                fromDate: fromDate as Timestamp,
            };
            this.facade.applyFiltersAndSort(filtersData);
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    private updateFormValues(filtersAndSort: CollectionFilters): void {
        this.filtersAndSortForm.patchValue(
            {
                ...filtersAndSort,
                fromDate: filtersAndSort.fromDate?.toDate() ?? null,
                toDate: filtersAndSort.toDate?.toDate() ?? null,
            },
            { emitEvent: false },
        );
        this.pickedDate = filtersAndSort?.toDate?.toDate();
    }
}
