import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { DBPathHelper, ParentType, TeamMember } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable()
export class PublicTemplateDataService {
    constructor(private firestoreService: FirestoreService) {}

    getTeamMemberByParentType(parentType: ParentType, parentId: string, userId: string): Observable<TeamMember> {
        return this.firestoreService
            .getDocument(DBPathHelper.getTeamMemberPath(parentType, parentId, userId))
            .pipe(map(teamMember => (teamMember ? new TeamMember(teamMember) : null)));
    }

    updateParentTeamMember(
        parentId: string,
        userId: string,
        data: Partial<TeamMember>,
        parentType: ParentType,
    ): Promise<void> {
        const path = DBPathHelper.getTeamMemberPath(parentType, parentId, userId);
        return this.firestoreService.updateDoc(path, data);
    }
}
