import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, take } from 'rxjs/operators';

import {
    LinkAccessService,
    OptionsStore,
    SessionOptionsService,
    SessionService,
    TeamMemberService,
    TemplateAccessService,
    UserAccessService,
} from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    selectActivityIdAndParentIds,
    selectAuthenticatedUser,
    selectSession,
    selectSessions,
} from '@accenture/global-store';
import {
    CollectionOptions,
    DBPathHelper,
    FavoriteAccess,
    FileType,
    ParentType,
    ProjectOptions,
    ProjectRole,
    routerLinksMap,
    SelectedSessionOptions,
    Session,
    SessionOptions,
    sessionOptionsArray,
    SessionRole,
    SessionStatus,
    TemplateRole,
    User,
    UserAccess,
} from '@accenture/shared/data';
import { DialogService, LoadedDescription } from '@accenture/shared/ui';
import { SequenceGeneration } from '@accenture/shared/util';

import { SelectSourceTypeDialogComponent } from '../select-source-type-dialog/select-source-type-dialog.component';
import { EditSessionDialogComponent } from './edit-session-dialog.component';

export interface EditSessionDialogViewModel {
    session: Session;
    sessionImage: FileType;
    loaderDescription: string;
    parentType: ParentType;
    isProjectOrSessionTemplate: boolean;
    sessionHasUpdates: boolean;
    updateSessionEvent: boolean;
    currentOptionToDisplay: SessionOptions | ProjectOptions | CollectionOptions | null;
    isLoading: boolean;
}

const defaultCreateSessionModel: EditSessionDialogViewModel = {
    session: {} as Session,
    sessionImage: {} as FileType,
    loaderDescription: '',
    parentType: ParentType.Projects,
    isProjectOrSessionTemplate: false,
    sessionHasUpdates: false,
    updateSessionEvent: true,
    currentOptionToDisplay: null,
    isLoading: false,
};

//TODO DELETE WHEN COLLECTION FEATURE WILL BE FINISHED
@Injectable()
export class EditSessionDialogFacade {
    private isLoading$ = new BehaviorSubject<boolean>(false);
    private sessionImage$ = new BehaviorSubject<FileType>(undefined);
    private sessionHasUpdates$ = new BehaviorSubject<boolean>(false);
    private updateSessionEvent$ = new BehaviorSubject<boolean>(true);

    vm$ = this.buildViewModel();

    private parentType!: ParentType;
    private projectId!: string;
    private sessionId!: string;
    private user!: User;
    private savedSessionOptions!: SelectedSessionOptions;
    private hasSelectedOptions = false;

    constructor(
        private store: Store<AppState>,
        private sessionService: SessionService,
        private teamMemberService: TeamMemberService,
        private userAccessService: UserAccessService,
        private router: Router,
        private dialogRef: MatDialogRef<EditSessionDialogComponent>,
        private dialogService: DialogService,
        private optionsStore: OptionsStore,
        private sessionOptionsService: SessionOptionsService,
        private linkAccessService: LinkAccessService,
        private templateAccessService: TemplateAccessService,
    ) {}

    setSessionImage(sessionImage: FileType): void {
        this.sessionImage$.next(sessionImage);
    }

    updateSessionForm(): void {
        this.hasSelectedOptions = false;
        this.updateSessionEvent$.next(true);
        this.sessionHasUpdates$.next(false);
        this.sessionImage$.next(undefined);
        this.isLoading$.next(true);
    }

    sessionWasUpdated(): void {
        setTimeout(() => this.updateSessionEvent$.next(false));
        setTimeout(() => this.isLoading$.next(false));
    }

    createOrUpdateSession(formValue: { name: string; description?: string; startDate?: Date; endDate?: Date }): void {
        this.sessionId ? this.updateSession(formValue) : this.createSession(formValue);
    }

    closeDialog(backToSelectSourceTypeDialog?: boolean): void {
        this.dialogRef.close();

        if (backToSelectSourceTypeDialog) {
            this.dialogService.open(SelectSourceTypeDialogComponent, {
                width: '768px',
                panelClass: 'tt9-modal',
            });
        }
    }

    backToSessionDialog(): void {
        this.optionsStore.setCurrentOptionToDisplay(null);
    }

    resetOptions(): void {
        this.optionsStore.resetSessionOptions();
        this.optionsStore.resetSessionOptionsToCreate();
        this.optionsStore.setCurrentOptionToDisplay(null);
    }

    private buildViewModel(): Observable<EditSessionDialogViewModel> {
        return combineLatest([
            this.store.pipe(select(selectActivityIdAndParentIds)),
            this.store.select(selectAuthenticatedUser).pipe(filter(user => !!user)),
            this.sessionImage$.asObservable().pipe(distinctUntilChanged()),
            this.sessionHasUpdates$.asObservable().pipe(distinctUntilChanged()),
            this.updateSessionEvent$.asObservable().pipe(distinctUntilChanged()),
            this.optionsStore.currentOptionToDisplay$.pipe(distinctUntilChanged()),
            this.isLoading$.asObservable(),
        ]).pipe(
            switchMap(
                ([
                    { projectId, parentType, sessionId, templateId },
                    user,
                    sessionImage,
                    sessionHasUpdates,
                    updateSessionEvent,
                    currentOptionToDisplay,
                    isLoading,
                ]) => {
                    if (!projectId && !sessionId && !templateId) {
                        return of(defaultCreateSessionModel);
                    }

                    this.parentType = parentType;
                    this.projectId = projectId;
                    this.sessionId = sessionId || templateId;
                    this.user = user;

                    if (!this.sessionId) {
                        return of({
                            isLoading,
                            parentType,
                            sessionHasUpdates,
                            updateSessionEvent,
                            currentOptionToDisplay,
                            session: {} as Session,
                            sessionImage: sessionImage,
                            loaderDescription: LoadedDescription.SessionCreating.toUpperCase(),
                            isProjectOrSessionTemplate: [ParentType.ProjectTemplates, ParentType.Templates].includes(
                                parentType,
                            ),
                        });
                    }

                    return this.store.select(selectSession).pipe(
                        distinctUntilChanged((previousPayload: Session, currentPayload: Session) => {
                            if (isEqual(previousPayload, currentPayload)) {
                                return true;
                            }
                            this.sessionHasUpdates$.next(true);
                            return false;
                        }),
                        map(session => {
                            const oldSessionImage = {
                                url: session?.imageUrl,
                                id: session?.imageId,
                            } as FileType;

                            if (!this.hasSelectedOptions) {
                                const options = {
                                    phase: session?.phase || {},
                                    subPhase: session?.subPhase || {},
                                    tags: session?.tags || {},
                                };
                                this.optionsStore.setSelectedSessionOptions(options);
                                this.savedSessionOptions = options;

                                this.hasSelectedOptions = true;
                            }

                            return {
                                session,
                                isLoading,
                                parentType,
                                sessionHasUpdates,
                                updateSessionEvent,
                                currentOptionToDisplay,
                                sessionImage: sessionImage || oldSessionImage,
                                loaderDescription: LoadedDescription.SessionCreating.toUpperCase(),
                                isProjectOrSessionTemplate: [
                                    ParentType.ProjectTemplates,
                                    ParentType.Templates,
                                ].includes(parentType),
                            };
                        }),
                    );
                },
            ),
            startWith(defaultCreateSessionModel),
        );
    }

    private async createSession(formValue: {
        name: string;
        description?: string;
        startDate?: Date;
        endDate?: Date;
    }): Promise<void> {
        this.isLoading$.next(true);

        const sessionImage = this.sessionImage$.value;

        const projectSessions = await firstValueFrom(this.store.select(selectSessions).pipe(take(1)));
        const sessionOptions = await firstValueFrom(this.optionsStore.selectedSessionOptions$.pipe(take(1)));
        const lastSessionSequence = !!projectSessions[projectSessions.length - 1]
            ? projectSessions[projectSessions.length - 1].sequence
            : null;
        const sequence = lastSessionSequence
            ? SequenceGeneration.afterLast(lastSessionSequence)
            : SequenceGeneration.initial();

        const role = this.parentType === ParentType.ProjectTemplates ? TemplateRole.Owner : SessionRole.Leader;

        let teamMembersCount = 1;
        let teamMembers = [];
        if (this.parentType === ParentType.Projects) {
            teamMembers = await firstValueFrom(
                this.teamMemberService.getTeamMembersByRole(this.parentType, this.projectId, ProjectRole.Admin),
            );
            teamMembersCount = teamMembers.length;
        }

        const session = new Session('', {
            sequence,
            teamMembersCount,
            name: formValue.name,
            description: formValue.description || '',
            projectId: this.projectId,
            imageUrl: sessionImage?.url || '',
            imageId: sessionImage?.id || '',
            status: SessionStatus.Draft,
            creatorId: this.user.id,
            creatorName: this.user.displayName,
            creatorImage: this.user.imageUrl,
            phase: sessionOptions?.phase || {},
            subPhase: sessionOptions?.subPhase || {},
            tags: sessionOptions?.tags || {},
            access: {
                [this.user.id as string]: {
                    role,
                    displayName: this.user.displayName,
                },
            },
        });

        const sessionId = await this.sessionService.createSession(
            this.parentType,
            this.projectId,
            session,
            formValue.startDate,
            formValue.endDate,
        );
        const sessionData = {
            sessions: {
                [sessionId]: {
                    role,
                    name: formValue.name,
                    description: formValue.description || '',
                    imageUrl: sessionImage?.url || '',
                    creatorId: this.user.id,
                },
            },
        } as Partial<UserAccess>;

        if (this.parentType === ParentType.Projects) {
            await this.teamMemberService.addTeamMemberToSession(
                this.parentType,
                this.projectId,
                sessionId,
                this.user,
                role,
            );

            await this.userAccessService.updateProjectAssignmentByProjectId(
                this.projectId,
                this.user.id as string,
                sessionData,
            );

            // invite project admins to the session
            const adminEmails = teamMembers.reduce((acc, teamMember) => {
                if (teamMember.id !== this.user.id && teamMember.email) {
                    acc.push(teamMember.email);
                }
                return acc;
            }, []);
            if (adminEmails.length) {
                await this.teamMemberService.inviteTeamMembers(
                    this.projectId,
                    sessionId,
                    adminEmails,
                    SessionRole.Leader,
                );
            }
        }

        await this.updateOptions(sessionOptions, sessionId);

        this.isLoading$.next(false);
        const parentRoute = routerLinksMap[this.parentType];
        const sessionRoute = this.parentType === ParentType.Projects ? 'session' : 'session-template';
        this.router.navigate([parentRoute, this.projectId, sessionRoute, sessionId]);
        this.closeDialog();
    }

    private async updateSession(formValue: {
        name: string;
        description?: string;
        startDate?: Date;
        endDate?: Date;
    }): Promise<void> {
        this.isLoading$.next(true);
        const sessionOptions = await firstValueFrom(this.optionsStore.selectedSessionOptions$.pipe(take(1)));

        const sessionData = {
            name: formValue.name,
            description: formValue.description,
            phase: sessionOptions?.phase || {},
            subPhase: sessionOptions?.subPhase || {},
            tags: sessionOptions?.tags || {},
        } as Partial<Session>;

        const sessionImage = this.sessionImage$.getValue();
        if (sessionImage) {
            sessionData.imageUrl = sessionImage?.url || null;
            sessionData.imageId = sessionImage?.id || null;
        }

        await this.sessionService.updateSessionDocument(
            this.parentType,
            this.projectId,
            this.sessionId,
            sessionData,
            formValue.startDate,
            formValue.endDate,
        );

        if (this.parentType === ParentType.Templates) {
            await this.templateAccessService.updateTemplateAccess(
                DBPathHelper.getFavoriteAccessPath(),
                this.sessionId,
                {
                    name: sessionData.name,
                    description: sessionData.description,
                    phases: sessionData.phase,
                    subPhases: sessionData.subPhase,
                    tags: sessionData.tags,
                    imageUrl: sessionData.imageUrl,
                } as FavoriteAccess,
            );
        }
        await this.linkAccessService.updateLinkAccessSessionName(this.sessionId, sessionData.name);
        await this.updateOptions(sessionOptions, this.sessionId);

        this.isLoading$.next(false);
        this.closeDialog();
    }

    private async updateOptions(selectedSessionOptions: SelectedSessionOptions, sessionId: string): Promise<void> {
        const optionsToCreateIds = await firstValueFrom(this.optionsStore.sessionOptionsToCreateIds$.pipe(take(1)));

        const optionsToRemove = sessionOptionsArray.reduce((acc, optionType) => {
            acc[optionType] = Object.keys(this.savedSessionOptions?.[optionType] || {}).filter(
                id => !Object.keys(selectedSessionOptions[optionType] || {}).includes(id),
            );

            return acc;
        }, {});

        await this.sessionOptionsService.updateOptions(
            selectedSessionOptions,
            sessionId,
            optionsToRemove,
            optionsToCreateIds,
        );
    }
}
