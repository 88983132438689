<ng-container *ngIf="vm$ | async as vm">
  <div class="session-form-container">
    <ng-container *ngIf="!vm.currentOptionToDisplay">
      <div class="session-form-image">
        <accenture-image-input
          [file]="imageControl.value"
          [mode]="imageInputMode"
          [imagePlaceholder]="imageInputPlaceholder"
          (fileUploaded)="setSessionImage($event)"
          (fileDeleted)="deleteSessionImage()"
        />
      </div>

      <form class="session-form-form" [formGroup]="sessionForm">
        <mat-form-field appearance="outline" floatLabel="auto" class="tt9-form-field width-100">
          <mat-label>Session name</mat-label>
          <input
            #name
            matInput
            type="text"
            formControlName="name"
            aria-label="Session name"
            [maxlength]="characterLimitMedium"
          />
          <mat-hint class="mat-hint-limit" [class]="determineHintClass(name.value.length, characterLimitMedium)">{{
            characterLimitText(name.value.length, characterLimitMedium)
          }}</mat-hint>
          <mat-error *ngIf="nameControl?.hasError(validationErrors.required)">{{
            validationMessages.required
          }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="auto" class="tt9-form-field width-100">
          <mat-label>Description</mat-label>
          <input
            #description
            matInput
            type="text"
            formControlName="description"
            aria-label="Description"
            [maxlength]="descriptionCharacterLimit"
          />
          <mat-hint
            class="mat-hint-limit"
            [class]="determineHintClass(description.value.length, descriptionCharacterLimit)"
            >{{ characterLimitText(description.value.length, descriptionCharacterLimit) }}</mat-hint
          >
        </mat-form-field>

        <div *ngIf="!vm.isProjectOrSessionTemplate" class="session-form-dates">
          <!-- start date -->
          <mat-form-field appearance="outline" floatLabel="auto" class="tt9-form-field date-form-field">
            <mat-label>Start date</mat-label>
            <input
              matInput
              readonly
              formControlName="startDate"
              [placeholder]="inputPlaceholders.startDate"
              [matDatepicker]="datePickerFrom"
              aria-label="Start date"
              (click)="datePickerFrom.open()"
            />
            <mat-datepicker-toggle matSuffix [for]="datePickerFrom">
              <accenture-icon matDatepickerToggleIcon> icon-calendar </accenture-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #datePickerFrom></mat-datepicker>
            <accenture-icon
              *ngIf="startDateControl?.value"
              matSuffix
              class="mat-select-icon date-reset"
              (click)="clearDateInput('startDate', $event)"
            >
              icon-close
            </accenture-icon>
          </mat-form-field>
          <!-- /start date -->

          <!-- end date -->
          <mat-form-field appearance="outline" floatLabel="auto" class="tt9-form-field date-form-field">
            <mat-label>End date</mat-label>
            <input
              matInput
              readonly
              formControlName="endDate"
              [placeholder]="inputPlaceholders?.endDate"
              [matDatepicker]="datePickerTo"
              aria-label="End date"
              (click)="datePickerTo.open()"
            />
            <mat-datepicker-toggle matSuffix [for]="datePickerTo">
              <accenture-icon matDatepickerToggleIcon> icon-calendar </accenture-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #datePickerTo></mat-datepicker>
            <accenture-icon
              *ngIf="endDateControl?.value"
              matSuffix
              class="mat-select-icon date-reset"
              (click)="clearDateInput('endDate', $event)"
            >
              icon-close
            </accenture-icon>
            <mat-error *ngIf="isEndDateGreaterStartDateErrorVisibility">{{
              validationMessages.endDateGreaterStartDate
            }}</mat-error>
          </mat-form-field>
          <!-- /end date -->
        </div>
      </form>
    </ng-container>

    <accenture-session-options></accenture-session-options>
  </div>

  <div class="session-form-footer p-16">
    <div *ngIf="vm.sessionHasUpdates && !vm.isLoading">
      <span>Session edit data updates available</span>
      <span class="session-form-update ml-8" (click)="updateSessionForm()">Update</span>
    </div>

    <div class="d-flex buttons-container ml-auto">
      <button *ngIf="vm.currentOptionToDisplay" class="tt9-btn-default btn-icon" (click)="backToSessionForm()">
        <accenture-icon>icon-chevron-left-small</accenture-icon>
        <span>Back</span>
      </button>

      <button
        *ngIf="!vm.currentOptionToDisplay"
        class="tt9-btn-default mr-8"
        [class.btn-icon]="data.cancelButtonIcon"
        (click)="closeDialog()"
      >
        <accenture-icon *ngIf="data.cancelButtonIcon">
          {{ data.cancelButtonIcon }}
        </accenture-icon>
        <span>{{ data.cancelButtonText }}</span>
      </button>

      <button
        *ngIf="!vm.currentOptionToDisplay"
        class="tt9-btn"
        [disabled]="sessionForm.invalid"
        (click)="onSave.emit()"
      >
        Save
      </button>
    </div>
  </div>
</ng-container>
