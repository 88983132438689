<ng-container *ngIf="vm$ | async as vm">
  <div class="sessions">
    <div class="sessions-content">
      <ng-container *accentureLoaded="!vm.isLoading; description: loaderText">
        <div *ngIf="vm.sessions.length || vm.searchValue" class="sessions-header">
          <h2 class="tt9-h2 sessions-title">Sessions</h2>

          <div *ngIf="canCreateSession(vm.user)" class="pl-40">
            <button class="tt9-btn btn-icon" (click)="openCreateSessionDialog()">
              <accenture-icon>icon-add</accenture-icon>
              <span>Create Session</span>
            </button>
          </div>
        </div>

        <div *ngIf="vm.sessionsCount" class="sessions-panel">
          <p *ngIf="!vm.isSearchShown" class="tt9-body-1 sessions-number">
            {{ vm.sessionsCount }} Session{{ vm.sessionsCount > 1 ? "s" : "" }}
          </p>

          <div *ngIf="vm.isSearchShown" class="sessions-search">
            <mat-form-field
              appearance="outline"
              class="tt9-form-field no-label no-margin width-100"
              floatLabel="always"
            >
              <input
                #search
                matInput
                [formControl]="searchControl"
                [placeholder]="inputPlaceholders.search"
                (input)="filterSessions(search.value)"
              />
              <accenture-icon matPrefix>icon-search</accenture-icon>
              <accenture-icon *ngIf="vm.searchValue" matSuffix (click)="clearFilter($event)">icon-close</accenture-icon>
            </mat-form-field>
          </div>

          <button
            class="tt9-btn-icon-filled mr-8"
            matTooltipPosition="above"
            matTooltipClass="tt9-arrow-tooltip-below"
            [matTooltip]="tooltipTexts.sessionCards"
            [class.active]="vm.activeTab === sessionsViewTab.CardView"
            (click)="setActiveTab(sessionsViewTab.CardView)"
          >
            <accenture-icon>icon-grid-module</accenture-icon>
          </button>
          <button
            class="tt9-btn-icon-filled mr-16"
            matTooltipPosition="above"
            matTooltipClass="tt9-arrow-tooltip-below"
            [matTooltip]="tooltipTexts.sessionList"
            [class.active]="vm.activeTab === sessionsViewTab.ListView"
            (click)="setActiveTab(sessionsViewTab.ListView)"
          >
            <accenture-icon>icon-list</accenture-icon>
          </button>
          <button
            class="tt9-btn-icon-filled mr-8"
            matTooltipPosition="above"
            matTooltipClass="tt9-arrow-tooltip-below"
            [matTooltip]="tooltipTexts.search"
            [class.active]="vm.isSearchShown"
            (click)="toggleShowSearch()"
          >
            <accenture-icon>icon-search</accenture-icon>
          </button>

          <button
            class="tt9-btn-icon-filled tt9-btn-applied"
            matTooltipPosition="above"
            matTooltipClass="tt9-arrow-tooltip-below-right"
            [matTooltip]="tooltipTexts.sortSessions"
            (click)="toggleFiltersAndSortPanel(true, $event)"
          >
            <accenture-icon>icon-filter</accenture-icon>
            <div *ngIf="vm.isFiltersApplied" class="applied"></div>
          </button>
        </div>

        <div
          class="sessions-list"
          [ngClass]="{
            'list-view': vm.activeTab === sessionsViewTab.ListView,
            empty:
              isSessionsNotCreated(vm.sessions.length, vm.isSearchShown, vm.isFiltersApplied) ||
              isSessionsNotFound(vm.sessions.length, vm.isSearchShown, vm.isFiltersApplied)
          }"
        >
          <div
            *ngIf="isSessionsNotCreated(vm.sessions.length, vm.isSearchShown, vm.isFiltersApplied)"
            class="empty-container"
          >
            <accenture-empty-screen
              [title]="emptyScreenTitles.noSessionsYet"
              [description]="emptyScreenDescriptions.noSessionsYet"
              [imageUrl]="emptyScreenImageUrlSessions"
              [buttonText]="canCreateSession(vm.user) ? 'Create Session' : ''"
              [buttonIcon]="canCreateSession(vm.user) ? 'icon-add' : ''"
              (buttonClick)="openCreateSessionDialog()"
            ></accenture-empty-screen>
          </div>

          <div
            *ngIf="isSessionsNotFound(vm.sessions.length, vm.isSearchShown, vm.isFiltersApplied)"
            class="empty-container"
          >
            <accenture-empty-screen
              [title]="vm.isFiltersApplied ? emptyScreenTitles.noSessionsMatch : emptyScreenTitles.noSessionsFound"
              [imageUrl]="emptyScreenImageUrlSessions"
            ></accenture-empty-screen>
          </div>

          <ng-container *ngIf="vm.activeTab === sessionsViewTab.CardView">
            <div
              *ngFor="let session of vm.sessions; trackBy: trackById"
              class="tt9-navigation-card navigation-card"
              (click)="redirectToSession(session.id)"
            >
              <button
                *ngIf="isSessionEditor(vm.userSessionsAccess?.[session.id]?.role)"
                class="navigation-card-menu-btn sessions-cell-actions"
                [matMenuTriggerFor]="sessionOptions"
                (click)="$event.stopPropagation()"
              >
                <accenture-icon>icon-more</accenture-icon>
              </button>

              <mat-menu #sessionOptions="matMenu" class="tt9-menu project-sessions-list-session-info-menu">
                <ng-container *ngTemplateOutlet="sessionOptionMenu; context: { session: session }"></ng-container>
              </mat-menu>
              <div class="tt9-navigation-card-image" [style.background-image]="getImageUrl(session.imageUrl)">
                <img
                  *ngIf="!session.imageUrl"
                  alt="Session Image"
                  class="tt9-navigation-card-image-empty"
                  [src]="imageInputPlaceholdersUrl"
                />
                <div class="tt9-navigation-card-image-bottom-badges">
                  <div class="tt9-badge" [ngClass]="scssClassSessionStatus[session.status]">
                    {{ session.status | displaySessionStatus }}
                  </div>
                </div>
              </div>

              <div class="tt9-navigation-card-content">
                <p
                  #sessionName
                  class="tt9-navigation-card-title"
                  matTooltipClass="tt9-arrow-tooltip-above-left"
                  matTooltipPosition="below"
                  [matTooltip]="session.name"
                  [matTooltipDisabled]="isDisabledTooltip(sessionName)"
                >
                  {{ session.name }}
                </p>
                <p
                  #sessionDescription
                  *ngIf="session?.description"
                  class="tt9-navigation-card-description"
                  matTooltipClass="tt9-arrow-tooltip-above-left"
                  matTooltipPosition="below"
                  [matTooltip]="session.description"
                  [matTooltipDisabled]="isDisabledTooltip(sessionDescription)"
                >
                  {{ session.description }}
                </p>
              </div>

              <div
                class="tt9-navigation-card-footer"
                [ngClass]="{
                  'd-flex-between': isOptionsVisible(vm.userSessionsAccess?.[session.id]?.role),
                  'd-flex-end': !isOptionsVisible(vm.userSessionsAccess?.[session.id]?.role)
                }"
              >
                <ng-container
                  *ngTemplateOutlet="
                    phaseInfo;
                    context: {
                      session: this.session,
                      textClass: 'tt9-caption-1',
                      viewType: sessionsViewTab.CardView,
                      isOptionsVisible: isOptionsVisible(vm.userSessionsAccess?.[session.id]?.role)
                    }
                  "
                ></ng-container>

                <p class="date tt9-caption-3 tt9-color-neutral-500">
                  {{ getSessionDate(session, "updated") | date : "mediumDate" }}
                </p>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="vm.activeTab === sessionsViewTab.ListView">
            <div *ngIf="vm.sessions?.length" class="sessions-list-header tt9-input-text-small">
              <div class="sessions-list-cell header-cell wide-cell">Name & description</div>
              <div *ngIf="vm.hasSessionLeaderRole" class="sessions-list-cell header-cell text-center">
                Phase & Sub-Phase
              </div>
              <div class="sessions-list-cell header-cell text-center status-cell">Status</div>
              <div class="sessions-list-cell header-cell text-center">Last updated</div>
              <div *ngIf="vm.hasSessionLeaderRole" class="sessions-list-cell header-cell short-cell"></div>
            </div>
            <div
              *ngFor="let session of vm.sessions; trackBy: trackById"
              class="sessions-list-row tt9-input-text-small"
              (click)="redirectToSession(session.id)"
            >
              <div class="sessions-list-cell wide-cell d-flex">
                <div class="sessions-list-image-container">
                  <div
                    class="sessions-list-image d-flex-center"
                    [style.background-image]="getImageUrl(session.imageUrl || '')"
                  >
                    <img
                      *ngIf="!session.imageUrl"
                      alt="Session Image"
                      class="sessions-list-image-empty"
                      [src]="imageInputPlaceholdersUrl"
                    />
                  </div>
                </div>
                <div class="sessions-list-info">
                  <h4
                    #sessionName
                    accentureTooltip
                    class="tt9-button-text-large text-overflow-ellipsis-2"
                    matTooltipClass="tt9-arrow-tooltip-above-left"
                    matTooltipPosition="below"
                    [matTooltip]="session.name"
                    [matTooltipDisabled]="isDisabledTooltip(sessionName)"
                  >
                    {{ session.name }}
                  </h4>
                  <p
                    #sessionDescription
                    accentureTooltip
                    class="sessions-list-info-description tt9-input-text-small text-overflow-ellipsis-2"
                    matTooltipClass="tt9-arrow-tooltip-above-left"
                    matTooltipPosition="below"
                    [matTooltip]="session.description"
                    [matTooltipDisabled]="isDisabledTooltip(sessionDescription)"
                  >
                    {{ session.description }}
                  </p>
                </div>
              </div>
              <div *ngIf="vm.hasSessionLeaderRole" class="sessions-list-cell phase-cell d-flex-center">
                <ng-container
                  *ngTemplateOutlet="
                    phaseInfo;
                    context: {
                      session: session,
                      textClass: 'tt9-body-1',
                      viewType: sessionsViewTab.ListView,
                      isOptionsVisible: isOptionsVisible(vm.userSessionsAccess?.[session.id]?.role)
                    }
                  "
                ></ng-container>
              </div>
              <div class="sessions-list-cell status-cell d-flex-center tt9-caption-1">
                <div class="tt9-badge" [ngClass]="scssClassSessionStatus[session.status]">
                  {{ session.status | displaySessionStatus }}
                </div>
              </div>
              <div class="sessions-list-cell text-center tt9-body-1">
                {{ getSessionDate(session, "updated") | date : "mediumDate" }}
              </div>
              <div *ngIf="vm.hasSessionLeaderRole" class="sessions-list-cell d-flex-center short-cell">
                <button
                  *ngIf="isSessionEditor(vm.userSessionsAccess?.[session.id]?.role)"
                  class="tt9-btn-icon"
                  [matMenuTriggerFor]="sessionOptions"
                  (click)="$event.stopPropagation()"
                >
                  <accenture-icon>icon-more</accenture-icon>
                </button>
                <mat-menu #sessionOptions="matMenu" class="tt9-menu project-sessions-list-session-info-menu">
                  <ng-container *ngTemplateOutlet="sessionOptionMenu; context: { session: session }"></ng-container>
                </mat-menu>
              </div>
            </div>
          </ng-container>
        </div>

        <!-- Filter and Sort side panel -->
        <accenture-side-panel
          *ngIf="vm.isFiltersPanelOpened"
          #origin="cdkOverlayOrigin"
          cdkOverlayOrigin
          [panelTitle]="'Filter & Sort'"
          [panelTrigger]="origin"
          [panelOpened]="vm.isFiltersPanelOpened"
          (closePanel)="toggleFiltersAndSortPanel(false)"
        >
          <accenture-sessions-filters-and-sort-new></accenture-sessions-filters-and-sort-new>
        </accenture-side-panel>
        <!-- Filter and Sort side panel -->
      </ng-container>

      <!-- Session options menu -->
      <ng-template #sessionOptionMenu let-session="session">
        <button class="tt9-btn-icon" mat-menu-item (click)="moveToCollection(session)">
          <!-- TODO: update icons -->
          <accenture-icon>icon-log-in</accenture-icon>
          Move to collection
        </button>
        <button class="tt9-btn-icon" mat-menu-item (click)="saveAsTemplate($event, session.id)">
          <accenture-icon>icon-save</accenture-icon>
          Save session as template
        </button>
        <button class="tt9-btn-icon" mat-menu-item (click)="downloadAnExportReport($event, session.id)">
          <accenture-icon>icon-file-upload</accenture-icon>
          Export session report
        </button>
        <div class="actions-toggle-divider border-top" [ngClass]="{ 'border-bottom': vm.user.isAccenture }">
          <div class="py-8">
            <mat-slide-toggle
              class="tt9-toggle"
              color="primary"
              [checked]="session.displayAgenda"
              (click)="$event.stopPropagation()"
              (change)="updateDisplayAgenda($event.checked, session.id)"
              ><span
                class="tt9-body-1"
                matTooltipPosition="above"
                matTooltipClass="tt9-arrow-tooltip-below-right"
                [matTooltip]="'Display agenda to participants'"
                >Display agenda to participants</span
              ></mat-slide-toggle
            >
          </div>
          <div class="py-8">
            <mat-slide-toggle
              class="tt9-toggle"
              color="primary"
              [checked]="session.selfNavigate"
              [disabled]="!session.displayAgenda"
              (click)="$event.stopPropagation()"
              (change)="updateSelfNavigate($event.checked, session.id)"
              ><span
                class="tt9-body-1"
                matTooltipPosition="above"
                matTooltipClass="tt9-arrow-tooltip-below-right"
                [matTooltip]="'Allow participants to self-navigate'"
                >Allow participants to self-navigate</span
              ></mat-slide-toggle
            >
          </div>
          <div class="py-8">
            <mat-slide-toggle
              class="tt9-toggle"
              color="primary"
              [checked]="!session.sessionThreadDisabled"
              (click)="$event.stopPropagation()"
              (change)="updateSessionThreads(!$event.checked, session.id)"
            >
              <span
                class="tt9-body-1"
                matTooltipPosition="above"
                matTooltipClass="tt9-arrow-tooltip-below-right"
                matTooltip="Session thread"
              >
                Session thread
              </span>
            </mat-slide-toggle>
          </div>
          <div class="py-8">
            <mat-slide-toggle
              class="tt9-toggle"
              color="primary"
              [checked]="session.sessionThreadIsAnonymous"
              [disabled]="session.sessionThreadDisabled"
              (click)="$event.stopPropagation()"
              (change)="updateSessionThreadIsAnonymous($event.checked, session.id)"
            >
              <span
                class="tt9-body-1"
                matTooltipPosition="above"
                matTooltipClass="tt9-arrow-tooltip-below-right"
                matTooltip="Make thread anonymous"
              >
                Make thread anonymous
              </span>
            </mat-slide-toggle>
          </div>
        </div>
        <button
          *ngIf="vm.user.isAccenture"
          mat-menu-item
          (click)="openConfirmationDialog($event, session.id, session.name)"
          class="tt9-btn-ghost btn-icon btn-error"
        >
          <accenture-icon>icon-delete</accenture-icon>
          Delete session
        </button>
      </ng-template>
      <!-- Session options menu -->
    </div>
  </div>
</ng-container>

<ng-template
  #phaseInfo
  let-session="session"
  let-textClass="textClass"
  let-viewType="viewType"
  let-isOptionsVisible="isOptionsVisible"
>
  <ng-container *ngIf="isOptionsVisible">
    <div class="d-flex">
      <p
        *ngIf="(session.phase | keyvalue)?.length"
        #phase
        class="phase text-overflow-ellipsis-1 {{ textClass }}"
        matTooltipPosition="above"
        matTooltipClass="tt9-arrow-tooltip-below"
        [ngClass]="{
          'full-width': !(session.subPhase | keyvalue)?.length
        }"
        [matTooltipDisabled]="isDisabledTooltip(phase)"
        [matTooltip]="(session.phase | keyvalue)[0].value"
      >
        {{ (session.phase | keyvalue)[0].value }}
      </p>
      <span
        class="mr-4 ml-4"
        [innerHtml]="
          getFooterDivider((session.phase | keyvalue)?.length, (session.subPhase | keyvalue)?.length, viewType)
        "
      ></span>
      <p
        *ngIf="(session.subPhase | keyvalue)?.length"
        #subPhase
        class="phase text-overflow-ellipsis-1 {{ textClass }}"
        matTooltipPosition="above"
        matTooltipClass="tt9-arrow-tooltip-below"
        [ngClass]="{
          'full-width': !(session.phase | keyvalue)?.length
        }"
        [matTooltipDisabled]="isDisabledTooltip(subPhase)"
        [matTooltip]="(session.subPhase | keyvalue)[0].value"
      >
        {{ (session.subPhase | keyvalue)[0].value }}
      </p>
    </div>
  </ng-container>
</ng-template>
