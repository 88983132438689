import { ParentType } from '../parent-type';
import { ProjectQueryFilters } from '../project-filters';
import { UserQueryFilters } from '../user-filters';

interface BaseExportData {
    timezone?: number;
    env?: string;
}

export interface ExportData extends BaseExportData {
    projectId?: string;
    sessionId?: string;
    activityId?: string;
    templateId?: string;
    userId?: string;
    parentType?: ParentType;
    validateSessionWithoutError?: boolean;
}

export interface UsersSummaryExportData extends BaseExportData {
    filters: UserQueryFilters;
    selectAll: boolean;
    selected: string[];
    unselected: string[];
    isAllRecords: boolean;
}

export interface ProjectsSummaryExportData extends BaseExportData {
    filters: ProjectQueryFilters;
    areFiltersApplied: boolean;
    isAllRecords: boolean;
    selectAll: boolean;
    selected: string[];
    unselected: string[];
}

export interface SessionSummaryExportData extends BaseExportData {
    projectId?: string;
    sessionUserId?: string;
    isAllRecordsSelected: boolean;
    sessionIdsSelectedForExport: string[];
}
