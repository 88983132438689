import { MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MatSnackBarHorizontalPosition } from '@angular/material/snack-bar';

export enum SnackBarTypes {
    Info = 'info',
    Success = 'success',
    Error = 'error',
    Warning = 'warning',
    Notification = 'notification',
    InfoWarning = 'infowarning',
}

export interface SnackBarComponentData {
    panelClass: string;
    iconClass: string;
    actionButtonClass: string;
    verticalPosition: MatSnackBarVerticalPosition;
    horizontalPosition: MatSnackBarHorizontalPosition;
}
