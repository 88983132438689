import { Timestamp } from 'firebase/firestore';

export class Note {
    id?: string;
    activityId: string;
    activityItemId: string;
    sessionId?: string;
    userId: string;
    userDisplayName: string;
    value: string;
    created: Timestamp;
    updated: Timestamp;

    constructor(note: Partial<Note>) {
        this.id = note.id ?? '';
        this.activityId = note.activityId ?? '';
        this.activityItemId = note.activityItemId ?? '';
        this.sessionId = note.sessionId ?? '';
        this.userId = note.userId ?? '';
        this.userDisplayName = note.userDisplayName ?? '';
        this.value = note.value ?? '';
        this.created = note.created ?? Timestamp.now();
        this.updated = note.updated ?? Timestamp.now();
    }

    createSerializableObject(): Note {
        return {
            activityId: this.activityId,
            activityItemId: this.activityItemId,
            sessionId: this.sessionId,
            userId: this.userId,
            userDisplayName: this.userDisplayName,
            value: this.value,
            created: this.created,
            updated: this.updated,
        } as Note;
    }
}
