import { FieldValue, Timestamp } from 'firebase/firestore';

import { CollectionRole } from './access-control';
import { NodeTimestamp } from './node-timestamp';
import { ParentType } from './parent-type';

export interface UserCollection extends NodeTimestamp {
    id?: string;
    userId?: string;
    parentType?: ParentType;
    collectionId?: string;
    color?: string;
    creatorId?: string;
    creatorImage?: string;
    creatorName?: string;

    imageUrl?: string;
    name?: string; // collection name
    description?: string;
    role: CollectionRole; // collection role

    lastViewed?: Timestamp | FieldValue;

    sessionsCount: number;

    markedForDelete?: Timestamp;
    deletedByUserId?: string;
}
