import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
    Activity,
    Attribute,
    AttributeClass,
    CollectionsDisplayMode,
    ParentType,
    publicParentTypes,
    Session,
    SessionRole,
    TeamMember,
    TemplateRole,
} from '@accenture/shared/data';

import { AppState } from '../app.state';
import { selectParentType } from '../router/router.selectors';
import { SESSION_FEATURE_KEY, SessionState } from './session.reducer';

export const sessionState = createFeatureSelector<SessionState>(SESSION_FEATURE_KEY);

export const selectSessionAttributeClasses = createSelector<AppState, [SessionState], AttributeClass[]>(
    sessionState,
    (sessionStateData) => sessionStateData?.attributeClasses || [],
);

export const selectSessionAttributes = createSelector<AppState, [SessionState], Attribute[]>(
    sessionState,
    (sessionStateData) => sessionStateData?.attributes || [],
);

export const selectSessionData = createSelector<AppState, [SessionState], Session>(
    sessionState,
    (sessionStateData) => sessionStateData?.session,
);

export const selectSessionDataName = createSelector<AppState, [SessionState], string>(
    sessionState,
    (sessionStateData) => sessionStateData?.session?.name,
);

export const selectSessionTeamMemberData = createSelector(
    sessionState,
    (sessionStateData) => sessionStateData?.sessionTeamMember || ({} as TeamMember),
);

export const selectSessionTeamMemberDataCount = createSelector(
    sessionState,
    (sessionStateData) => sessionStateData?.session?.teamMembersCount || 0,
);

export const selectSessionActivitiesData = createSelector<AppState, [SessionState], Activity[]>(
    sessionState,
    (sessionStateData) => sessionStateData.sessionActivities || [],
);

export const canEditSession = createSelector<AppState, [TeamMember, ParentType], boolean>(
    selectSessionTeamMemberData,
    selectParentType,
    (teamMember, parentType) => {
        const isPublicTemplate = publicParentTypes.includes(parentType);
        return (
            isPublicTemplate
            || [SessionRole.Leader, TemplateRole.Owner, TemplateRole.Collaborator].includes(
                teamMember?.role as SessionRole,
            )
        );
    },
);

export const selectSessionActiveTeamMember = createSelector(selectSessionTeamMemberData, (sessionTeamMember) => {
    return sessionTeamMember;
});

export const selectIsSessionParticipantData = createSelector(
    selectSessionTeamMemberData,
    (sessionTeamMember) => sessionTeamMember.isSessionParticipant,
);

export const isSessionLeader = createSelector(
    selectSessionTeamMemberData,
    (teamMember) => !!teamMember && teamMember.isSessionLeader,
);

export const selectSessionVisibleActivitiesData = createSelector<AppState, [Activity[], boolean], Activity[]>(
    selectSessionActivitiesData,
    isSessionLeader,
    (sessionActivities, isSessionLeader) => {
        return sessionActivities.filter((activity: Activity) => activity.visible || isSessionLeader);
    },
);

export const selectSessionCurrentUserUpdateActivityDialogDisabled = createSelector<AppState, [TeamMember], boolean>(
    selectSessionTeamMemberData,
    (teamMember) => !!teamMember?.isUpdateActivityDialogDisabled,
);

export const selectSessionTeamMemberSnackBarsVisibilityNew = createSelector(
    sessionState,
    (sessionStateData) => sessionStateData?.sessionTeamMemberSnackBarsVisibility || {},
);

export const selectSessionsDisplayMode = createSelector<AppState, [SessionState], CollectionsDisplayMode>(
    sessionState,
    (sessionStateData) => sessionStateData?.displayMode,
);
