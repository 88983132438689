import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { difference, isEqual } from 'lodash';
import { Observable, pairwise, tap } from 'rxjs';
import { distinctUntilChanged, startWith } from 'rxjs/operators';

import { InvitationObject } from '@accenture/erp-deployment/shared/domain';
import {
    AccessControlRole,
    characterLimitLarge,
    characterLimitText,
    determineHintClass,
    inputPlaceholders,
    SessionRole,
} from '@accenture/shared/data';

import { InvitationFormFacade, InvitationFormViewModel } from './invitation-form-facade';

type AccessRoleControls = { [control in AccessControlRole]: FormControl<string[]> };

type InviteFormControls = { message: FormControl<string | null> } & Partial<AccessRoleControls>;

type InviteForm = FormGroup<InviteFormControls>;

@UntilDestroy()
@Component({
    selector: 'accenture-invitation-form',
    templateUrl: './invitation-form.component.html',
    styleUrls: ['./invitation-form.component.scss'],
    providers: [InvitationFormFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvitationFormComponent implements OnInit {
    @Input() isAdminPage = false;
    @Output() viewModelChange = new EventEmitter<Partial<InvitationObject>>();

    inviteForm!: InviteForm;
    vm$: Observable<InvitationFormViewModel>;

    rolesToAdd = [SessionRole.Leader, SessionRole.Participant];
    sessionRole = SessionRole;
    inputPlaceholders = inputPlaceholders;
    characterLimitLarge = characterLimitLarge;
    characterLimitText = characterLimitText;
    determineHintClass = determineHintClass;

    constructor(private facade: InvitationFormFacade) {
        this.vm$ = this.facade.vm$.pipe(tap(this.emitViewModelChange.bind(this)));
    }

    ngOnInit(): void {
        this.inviteForm = this.setInviteForm();
    }

    openMemberPanel(role: SessionRole.Leader | SessionRole.Participant): void {
        this.facade.openMemberPanel(role);
    }

    setMessageValue(value: string): void {
        this.facade.setMessageValue(value);
    }

    private setInviteForm(): InviteForm {
        const controls: InviteFormControls = {
            message: new FormControl(''),
        };

        this.rolesToAdd.forEach((role: SessionRole.Leader | SessionRole.Participant) => {
            const control = this.createEmailsControl(role);
            const controlWithDataFlow = this.addEmailsControlDataFlow(control, role);

            controls[role] = controlWithDataFlow;
        });

        return new FormGroup(controls);
    }

    private emitViewModelChange(): void {
        const chipsObject = {
            [SessionRole.Leader]: this.inviteForm.value[SessionRole.Leader],
            [SessionRole.Participant]: this.inviteForm.value[SessionRole.Participant],
        };

        this.viewModelChange.emit({
            chipsObject,
            message: this.inviteForm.controls.message.value,
        });
    }

    private createEmailsControl(role: SessionRole.Leader | SessionRole.Participant): FormControl<string[]> {
        const control = new FormControl<string[]>([]);

        control.valueChanges
            .pipe(
                startWith<string[]>([]),
                pairwise(),
                untilDestroyed(this),
                tap(([oldEmails, currentEmails]) => {
                    const isEmailsRemoved = currentEmails.length < oldEmails.length;

                    if (isEmailsRemoved) {
                        this.facade.removeEmails(difference(oldEmails, currentEmails));
                    }

                    this.facade.setEmails(currentEmails, role);
                }),
            )
            .subscribe();

        return control;
    }

    private addEmailsControlDataFlow(
        control: FormControl<string[]>,
        role: SessionRole.Leader | SessionRole.Participant,
    ): FormControl<string[]> {
        this.facade
            .getEmailsByRole(role)
            .pipe(
                distinctUntilChanged(isEqual),
                untilDestroyed(this),
                tap((emails) => control.setValue(emails, { emitEvent: true })),
            )
            .subscribe();

        return control;
    }
}
