import { Injectable } from '@angular/core';
import { Route, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { environment } from '../environments/environment';

@Injectable()
export class DevEnvGuard {
    canLoad(
        route: Route,
        segments: UrlSegment[],
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return !environment.production;
    }
}
