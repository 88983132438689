import { Dictionary } from './dictionary';

export type FeatureToggles = Dictionary<boolean> | Record<string, never>;

export enum FeatureToggleName {
    SummarizeBrainstorm = 'summarizeBrainstorm',
    ShowRegisterLink = 'showRegisterLink',
    ShowConnections = 'showConnections',
    LoadTestEnabled = 'loadTestEnabled',
    ApiV2tokenValidation = 'apiV2tokenValidation',
    SuggestGroupsInBrainstorm = 'suggestGroupsInBrainstorm',
    AutoGroupInBrainstorm = 'autoGroupInBrainstorm',
    SuggestMergesInBrainstorm = 'suggestMergesInBrainstorm',
    AiConciergeAvailable = 'aiConciergeAvailable',
    AutoBuilderImageSource = 'autoBuilderImageSource',
    AiGenerateAvailable = 'aiGenerateAvailable',
    AiSummarizeAvailable = 'aiSummarizeAvailable',
    AiMakeNotesAvailable = 'aiMakeNotesAvailable',
    FocusLogs = 'focusLogs',
    BrainstormAIToolPanel = 'brainstormAIToolPanel',
    SessionThreadsPanel = 'sessionThreadsPanel',
    ShowDeepLinks = 'showDeepLinks',
    ShowPromptLibrary = 'showPromptLibrary',
    HelpMenu = 'helpMenu',
    BookSessionMeeting = 'bookSessionMeeting',
    ShowGetLinks = 'showGetLinks',
    ShowJiraTickets = 'showJiraTickets',
    ShowExportSession = 'showExportSession',
    ShowAiTools = 'showAITools',
    ShowPresentZoom = 'showPresentZoom',
    SessionRecorder = 'sessionRecorder',
    AiConciergeAvailableInThread = 'aiConciergeAvailableInThread',
    Release10 = 'release10',
    ActivityTimerAvailable = 'activityTimerAvailable',
    ShowAnonymousToggle = 'showAnonymousToggle',
    ShowSpeechToText = 'showSpeechToText',
}
