import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { loaderImageUrls, LoaderSize, LoaderType } from '../../models';

@Component({
    selector: 'accenture-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent {
    @Input() loaderType: LoaderType = LoaderType.SpinnerDefault;
    @Input() loaderSize = LoaderSize.Default;

    loaderImageUrls = loaderImageUrls;
}
