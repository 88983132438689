/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import {
    AngularFirestore,
    AngularFirestoreCollection,
    CollectionReference,
    DocumentReference,
    Query,
    QueryFn,
    QueryGroupFn,
} from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FirebaseError } from 'firebase/app';
import firebase from 'firebase/compat/app';
import { Timestamp } from 'firebase/firestore';
import { chunk, flatten, isEqual } from 'lodash';
import { combineLatest, filter, firstValueFrom, Observable, of, zip } from 'rxjs';
import { catchError, distinctUntilChanged, map, take } from 'rxjs/operators';

import { SortOrders } from '@accenture/shared/data';
import { sortByNumericPropertiesAsc, sortByNumericPropertiesDesc } from '@accenture/shared/util';

import 'firebase/compat/firestore';

import OrderByDirection = firebase.firestore.OrderByDirection;
import WhereFilterOp = firebase.firestore.WhereFilterOp;

export interface FanOutWrite {
    path: string;
    data?: any;
}

const MAX_EXECUTION_TIME = 3600000; // Timeout value in milliseconds. This value matches timeout in cloud functions.
export const httpsCallableOptions = {
    timeout: MAX_EXECUTION_TIME,
};

@Injectable({
    providedIn: 'root',
})
export class FirestoreService {
    readonly batchMaxlength = 500;

    constructor(private firestore: AngularFirestore, private functions: AngularFireFunctions) {}

    get timestamp(): firebase.firestore.FieldValue {
        return firebase.firestore.FieldValue.serverTimestamp();
    }

    get currentTimestamp(): firebase.firestore.FieldValue {
        return Timestamp.now();
    }

    get deleteField() {
        return firebase.firestore.FieldValue.delete();
    }

    get firestoreRef(): AngularFirestore {
        return this.firestore;
    }

    getPushId(): string {
        return this.firestore.createId();
    }

    changeCounterValue(value: number): firebase.firestore.FieldValue {
        return firebase.firestore.FieldValue.increment(value);
    }

    arrayUnion(value: any): firebase.firestore.FieldValue {
        return firebase.firestore.FieldValue.arrayUnion(value);
    }

    arrayRemove(value: string): firebase.firestore.FieldValue {
        return firebase.firestore.FieldValue.arrayRemove(value);
    }

    runTransaction<T>(updateFunction: (transaction) => Promise<T>): Promise<T> {
        return this.firestore.firestore.runTransaction(updateFunction);
    }

    createBatch() {
        return this.firestore.firestore.batch();
    }

    delete(ref: string): Promise<void> {
        return this.firestore.doc(ref).delete();
    }

    getSortingDirection(sortOrder: SortOrders): OrderByDirection {
        return {
            [SortOrders.Asc]: 'asc',
            [SortOrders.Dsc]: 'desc',
        }[sortOrder] as OrderByDirection;
    }

    getDocumentsByQuery<T>(path: string, query?: QueryFn): AngularFirestoreCollection<T> {
        return this.firestore.collection<T>(path, query);
    }

    replaceEmptyFields(object: any): any {
        return Object.keys(object).reduce((acc, field) => {
            const value = object[field];
            acc[field] = value || value === 0 || value === false ? value : this.deleteField;
            return acc;
        }, {});
    }

    async deleteDocumentObjectValue(
        collectionPath: string,
        documentId: string,
        dataField: string,
        dataId: string,
    ): Promise<void> {
        return await this.firestore
            .collection(collectionPath)
            .doc(documentId)
            .set(
                {
                    [dataField]: {
                        [dataId]: firebase.firestore.FieldValue.delete(),
                    },
                },
                { merge: true },
            );
    }

    dateToFirestoreTimestamp(date: Date): firebase.firestore.Timestamp {
        return firebase.firestore.Timestamp.fromDate(date);
    }

    async addDocument<T>(collectionPath: string, data: T): Promise<string> {
        return (
            await this.firestore
                .collection<T>(collectionPath)
                .add(data)
                .catch(this.logFirebaseError(`addDocument ${collectionPath}`))
        ).id;
    }

    addDocumentWithKey<T>(collectionPath: string, key: string, data: T): Promise<void> {
        return this.set(`${collectionPath}/${key}`, data);
    }

    cloudFunctionCallable<R>(name: string, data: Record<string, unknown>): Promise<R> {
        return firstValueFrom(this.functions.httpsCallable<any, R>(name, httpsCallableOptions)(data).pipe(take(1)));
    }

    getDocumentByKey<T>(collectionPath: string, key: string): Observable<T> {
        return this.firestore
            .doc<T>(`${collectionPath}/${key}`)
            .snapshotChanges()
            .pipe(
                map((snapshot) => {
                    return snapshot.payload.exists
                        ? {
                              ...(snapshot.payload.data() as T),
                              key: snapshot.payload.id,
                              id: snapshot.payload.id,
                          }
                        : null;
                }),
                this.logFirebaseObservableError(`${collectionPath}/${key}`),
            );
    }

    getDocumentsByProperty<T>(
        path: string,
        property: string,
        value: any,
        sortByField?: string,
        sortDirection: OrderByDirection = 'asc',
    ): Observable<T[]> {
        const collectionRef = this.getCollectionRefByProperty<T>(path, property, value, sortByField, sortDirection);
        return collectionRef.valueChanges({ idField: 'id' }).pipe(this.logFirebaseObservableError(path));
    }

    getDocumentsByPropertyWithoutCaching<T>(
        path: string,
        property: string,
        value: any,
        sortByField?: string,
        sortDirection: OrderByDirection = 'asc',
    ): Observable<T[]> {
        const collectionRef = this.getCollectionRefByProperty<T>(path, property, value, sortByField, sortDirection);
        return collectionRef.snapshotChanges().pipe(
            filter((snapshot) => snapshot.every(({ payload }) => payload.doc.metadata.fromCache === false)),
            map((snapshot) => {
                return snapshot.length
                    ? snapshot.map(({ payload }) => ({
                          ...payload.doc.data(),
                          id: payload.doc.id,
                      }))
                    : [];
            }),
            this.logFirebaseObservableError(path),
        );
    }

    getDocumentsByArrayProperty<T>(path: string, property: string, value: any): Observable<T[]> {
        return this.firestore
            .collection<T>(path, (ref) => ref.where(property, 'in', value))
            .valueChanges({ idField: 'id' })
            .pipe(this.logFirebaseObservableError(path));
    }

    private getCollectionRefByMultipleProperties<T>(
        path: string,
        propertyValues: Map<string, string | number | boolean>,
        sortByField?: string,
        sortDirection: OrderByDirection = 'asc',
    ): AngularFirestoreCollection<T> {
        return this.firestore.collection<T>(path, (ref) => {
            let refBuilder = ref as Query;
            propertyValues.forEach((value, key) => {
                if (value) {
                    refBuilder = refBuilder.where(key, '==', value);
                }
            });

            // NOTE: if you use the sortByField with a different field then the property field you will have to create a composite index
            if (sortByField) {
                refBuilder = refBuilder.orderBy(sortByField, sortDirection);
            }
            return refBuilder;
        });
    }

    getDocumentsByMultipleProperties<T>(
        path: string,
        propertyValues: Map<string, string | number | boolean>,
        sortByField?: string,
        sortDirection: OrderByDirection = 'asc',
    ): Observable<T[]> {
        const collectionRef = this.getCollectionRefByMultipleProperties<T>(
            path,
            propertyValues,
            sortByField,
            sortDirection,
        );
        return collectionRef.valueChanges({ idField: 'id' }).pipe(this.logFirebaseObservableError(path));
    }

    getDocumentsByMultiplePropertiesWithoutCaching<T>(
        path: string,
        propertyValues: Map<string, string>,
        sortByField?: string,
        sortDirection: OrderByDirection = 'asc',
    ): Observable<T[]> {
        const collectionRef = this.getCollectionRefByMultipleProperties<T>(
            path,
            propertyValues,
            sortByField,
            sortDirection,
        );
        return collectionRef.snapshotChanges().pipe(
            filter((snapshot) => snapshot.every(({ payload }) => payload.doc.metadata.fromCache === false)),
            map((snapshot) => {
                return snapshot.length
                    ? snapshot.map(({ payload }) => ({
                          ...payload.doc.data(),
                          id: payload.doc.id,
                      }))
                    : [];
            }),
            this.logFirebaseObservableError(path),
        );
    }

    getCollectionDocumentsCount(path: string, query?: QueryFn): Observable<number> {
        return this.firestore
            .collection(path, query)
            .snapshotChanges()
            .pipe(map((snapshot) => snapshot.length));
    }

    getDocumentsExist(path: string, propertyValues: Map<string, string>): Observable<boolean> {
        const collectionRef = this.firestore.collection(path, (ref) => {
            let refBuilder = ref as Query;
            propertyValues.forEach((value, key) => {
                if (value) {
                    refBuilder = refBuilder.where(key, '==', value).limit(1);
                }
            });

            return refBuilder;
        });

        return collectionRef.valueChanges().pipe(
            distinctUntilChanged(isEqual),
            map((documents) => !!documents.length),
            this.logFirebaseObservableError(path),
        );
    }

    getDocumentsExistByCombinedProperty(
        path: string,
        propertyValues: { property: string; operatorValue: WhereFilterOp; value: any }[],
    ): Observable<boolean> {
        const collectionRef = this.firestore.collection(path, (ref) => {
            let refBuilder = ref as Query;
            propertyValues.forEach(({ property, operatorValue, value }) => {
                if (property) {
                    refBuilder = refBuilder.where(property, operatorValue, value).limit(1);
                }
            });

            return refBuilder;
        });

        return collectionRef.valueChanges().pipe(
            distinctUntilChanged(isEqual),
            map((documents) => !!documents.length),
            this.logFirebaseObservableError(path),
        );
    }

    getDocumentsByArrayContains<T>(path: string, property: string, value: any): Observable<T[]> {
        return this.firestore
            .collection<T>(path, (ref) => ref.where(property, 'array-contains', value))
            .valueChanges({ idField: 'id' })
            .pipe(this.logFirebaseObservableError(path));
    }

    getCollection<T>(ref: string, sortByField?: string, sortDirection: OrderByDirection = 'asc'): Observable<T[]> {
        const collectionRef = sortByField
            ? this.firestore.collection<T>(ref, (ref) => ref.orderBy(sortByField, sortDirection))
            : this.firestore.collection<T>(ref);
        return collectionRef.valueChanges({ idField: 'id' }).pipe(this.logFirebaseObservableError(ref));
    }

    getCollectionWithoutCaching<T>(
        ref: string,
        sortByField?: string,
        sortDirection: OrderByDirection = 'asc',
    ): Observable<T[]> {
        const collectionRef = sortByField
            ? this.firestore.collection<T>(ref, (ref) => ref.orderBy(sortByField, sortDirection))
            : this.firestore.collection<T>(ref);
        return collectionRef.snapshotChanges().pipe(
            filter((snapshot) => snapshot.every(({ payload }) => payload.doc.metadata.fromCache === false)),
            map((snapshot) => {
                return snapshot.length
                    ? snapshot.map(({ payload }) => ({
                          ...payload.doc.data(),
                          id: payload.doc.id,
                      }))
                    : [];
            }),
            this.logFirebaseObservableError(ref),
        );
    }

    getCollectionWithQuery<T>(
        collectionName: string,
        orderField: string,
        startAtValue: any,
        endAtValue: any,
    ): Observable<T[]> {
        return this.firestore
            .collection<T>(collectionName, (ref) => {
                let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
                query = query.orderBy(orderField);
                query = startAtValue !== null ? query.startAt(startAtValue) : query;
                query = endAtValue !== null ? query.endAt(endAtValue + '\uf8ff') : query;
                return query;
            })
            .valueChanges({ idField: 'id' })
            .pipe(this.logFirebaseObservableError(collectionName));
    }

    getCollectionGroup<T>(path: string, ref: QueryGroupFn<T>): Observable<T[]> {
        return this.firestore
            .collectionGroup<T>(path, ref)
            .valueChanges({ idField: 'id' })
            .pipe(this.logFirebaseObservableError(path));
    }

    getDocumentsByIds<T>(
        path: string,
        ids: string[],
        sortByField?: string,
        sortDirection: OrderByDirection = 'asc',
    ): Observable<T[]> {
        if (!ids.length) {
            return of([]);
        }

        const chunkSize = 10;
        const idsChunks = chunk(ids, chunkSize);
        const butchByChunk: Observable<T[]>[] = [];

        idsChunks.forEach((chunkIds) => {
            const collectionRef = this.firestore.collection<T>(path, (ref) => {
                return ref.where(firebase.firestore.FieldPath.documentId(), 'in', chunkIds);
            });
            const valueChanges = collectionRef.valueChanges({ idField: 'id' }) as unknown as Observable<T[]>;

            butchByChunk.push(valueChanges);
        });

        return zip(butchByChunk).pipe(
            map((data) => {
                const valueChanges = flatten(data);

                if (!sortByField) {
                    return valueChanges;
                }

                return sortDirection === 'asc'
                    ? sortByNumericPropertiesAsc(valueChanges, sortByField)
                    : sortByNumericPropertiesDesc(valueChanges, sortByField);
            }),
            this.logFirebaseObservableError(path),
        );
    }

    // modified version of getDocumentsByIds which uses combineLatest instead of zip
    // waits for all observables to complete and then emits their results to ensure all chunks are present.
    getDocumentsByIdsCombineLatest<T>(
        path: string,
        ids: string[],
        sortByField?: string,
        sortDirection: OrderByDirection = 'asc',
    ): Observable<T[]> {
        if (!ids.length) {
            return of([]);
        }

        const chunkSize = 10;
        const idsChunks = chunk(ids, chunkSize);
        const butchByChunk: Observable<T[]>[] = [];

        idsChunks.forEach((chunkIds) => {
            const collectionRef = this.firestore.collection<T>(path, (ref) => {
                return ref.where(firebase.firestore.FieldPath.documentId(), 'in', chunkIds);
            });
            const valueChanges = collectionRef.valueChanges({ idField: 'id' }) as unknown as Observable<T[]>;

            butchByChunk.push(valueChanges);
        });

        return combineLatest(butchByChunk).pipe(
            map((data) => {
                const valueChanges = flatten(data);

                if (!sortByField) {
                    return valueChanges;
                }

                return sortDirection === 'asc'
                    ? sortByNumericPropertiesAsc(valueChanges, sortByField)
                    : sortByNumericPropertiesDesc(valueChanges, sortByField);
            }),
            this.logFirebaseObservableError(path),
        );
    }

    getDocumentRef<T>(ref: string): DocumentReference {
        return this.firestore.doc<T>(ref).ref;
    }

    getCollectionRef<T>(ref: string): CollectionReference {
        return this.firestore.collection<T>(ref).ref;
    }

    getDocument<T>(ref: string): Observable<T> {
        return this.firestore
            .doc<T>(ref)
            .snapshotChanges()
            .pipe(
                map((snapshot) => {
                    return snapshot.payload.exists
                        ? {
                              ...(snapshot.payload.data() as T),
                              id: snapshot.payload.id,
                          }
                        : null;
                }),
                this.logFirebaseObservableError(ref),
            );
    }

    getDocumentWithoutCaching<T>(ref: string): Observable<T> {
        return this.firestore
            .doc<T>(ref)
            .snapshotChanges()
            .pipe(
                filter((snapshot) => snapshot.payload.metadata.fromCache === false),
                map((snapshot) => {
                    return snapshot.payload.exists
                        ? {
                              ...(snapshot.payload.data() as T),
                              id: snapshot.payload.id,
                          }
                        : null;
                }),
                this.logFirebaseObservableError(ref),
            );
    }

    getDocumentWithoutId<T>(ref: string): Observable<T> {
        return this.firestore
            .doc<T>(ref)
            .snapshotChanges()
            .pipe(
                map((snapshot) => {
                    return snapshot.payload.exists
                        ? {
                              ...(snapshot.payload.data() as T),
                          }
                        : null;
                }),
                this.logFirebaseObservableError(ref),
            );
    }

    update<T>(ref: string, data: T): Promise<void> {
        return this.firestore
            .doc(ref)
            .set(data, { merge: true })
            .catch(this.logFirebaseError(`update ${ref}`));
    }

    updateDoc<T>(ref: string, data: Partial<T>): Promise<void> {
        return this.firestore
            .doc(ref)
            .update(data)
            .catch(this.logFirebaseError(`updateDoc ${ref}`));
    }

    updateDocumentUpdatedField(ref: string): Promise<void> {
        return this.update(ref, { updated: this.timestamp });
    }

    async safeUpdate<T>(ref: string, data: T): Promise<void> {
        const existingDocument = await firstValueFrom(this.firestore.doc(ref).snapshotChanges().pipe(take(1)));
        return existingDocument.payload.exists ? this.update(ref, data) : undefined;
    }

    async upsert<T>(ref: string, data: T): Promise<void> {
        const existingDocument = await firstValueFrom(this.firestore.doc(ref).snapshotChanges().pipe(take(1)));
        return existingDocument.payload.exists ? this.update(ref, data) : this.set(ref, data);
    }

    async writeBatch(fanOutWrites: FanOutWrite[]): Promise<void> {
        while (fanOutWrites.length > 0) {
            const batchDataLimit = fanOutWrites.splice(0, this.batchMaxlength);
            const batch = this.createBatch();

            batchDataLimit.forEach((fanOutWrite) => {
                batch.set(this.firestore.firestore.doc(fanOutWrite.path), fanOutWrite.data, { merge: true });
            });

            await batch.commit().catch(this.logFirebaseError('writeBatch'));
        }
    }

    async setBatch(fanOutWrites: FanOutWrite[]): Promise<void> {
        const batch = this.createBatch();
        fanOutWrites.forEach((fanOutWrite) => {
            batch.set(this.firestore.firestore.doc(fanOutWrite.path), fanOutWrite.data);
        });
        await batch.commit().catch(this.logFirebaseError('setBatch'));
    }

    async updateBatch(fanOutWrites: FanOutWrite[]): Promise<void> {
        while (fanOutWrites.length > 0) {
            const batchDataLimit = fanOutWrites.splice(0, this.batchMaxlength);
            const batch = this.createBatch();

            batchDataLimit.forEach((fanOutWrite) => {
                const documentRef = this.getDocumentRef(fanOutWrite.path);

                batch.update(documentRef, fanOutWrite.data);
            });

            await batch.commit().catch(this.logFirebaseError('updateBatch'));
        }
    }

    deleteBatch(fanOutDeletes: FanOutWrite[]): Promise<void> {
        const batch = this.createBatch();
        fanOutDeletes.forEach((fanOutDelete) => {
            if (fanOutDelete.data) {
                const propertyToDelete = { [fanOutDelete.data]: this.deleteField };
                batch.update(this.firestore.firestore.doc(fanOutDelete.path), propertyToDelete);
            } else {
                batch.delete(this.firestore.firestore.doc(fanOutDelete.path));
            }
        });
        return batch.commit().catch(this.logFirebaseError('deleteBatch'));
    }

    set<T>(ref: string, data: T): Promise<void> {
        return this.firestore
            .doc(ref)
            .set(data)
            .catch(this.logFirebaseError(`set ${ref}`));
    }

    getDocumentsByCombinedProperty<T>(
        path: string,
        queries: [property: string, operatorsValue: WhereFilterOp, value: any][],
    ): Observable<T[]> {
        const collectionRef = this.firestore.collection<T>(path, (ref) => {
            let refBuilder = ref as Query;
            queries.forEach((item) => {
                refBuilder = refBuilder.where(...item);
            });

            return refBuilder;
        });
        return collectionRef.valueChanges({ idField: 'id' });
    }

    private getCollectionRefByProperty<T>(
        path: string,
        property: string,
        value: any,
        sortByField?: string,
        sortDirection: OrderByDirection = 'asc',
    ): AngularFirestoreCollection<T> {
        return this.firestore.collection<T>(path, (ref) => {
            let refBuilder;
            refBuilder = ref.where(property, '==', value);

            // NOTE: if you use the sortByField with a different field then the property field you will have to create a composite index
            if (sortByField) {
                refBuilder = refBuilder.orderBy(sortByField, sortDirection);
            }
            return refBuilder;
        });
    }

    private logFirebaseError(requestPath: string): (reason: any) => any {
        return (error) => {
            if ((error as FirebaseError)?.code === 'permission-denied') {
                console.info(`Missing or insufficient permissions when querying ${requestPath}`);
            }
            throw error;
        };
    }

    private logFirebaseObservableError(requestPath: string): (observable: Observable<any>) => Observable<any> {
        return (observable) => {
            return observable.pipe(
                catchError((error) => {
                    if ((error as FirebaseError)?.code === 'permission-denied') {
                        console.info(`Missing or insufficient permissions when querying ${requestPath}`);
                    }
                    throw error;
                }),
            );
        };
    }
}
