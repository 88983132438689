import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';
import {
    Client,
    init as initFilestackClient,
    PickerFileMetadata,
    PickerOptions,
    PickerResponse,
    TransformOptions,
} from 'filestack-js';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { FredEnvironment } from '@accenture/shared/data';
import { decrypt } from '@accenture/shared/util';

import {
    excelFilesUploadConfig,
    excelFileUploadConfig,
    filesUploadConfig,
    fileUploadConfig,
    imagesUploadConfig,
    multipleImagesUploadConfig,
    presentationFilesUploadConfig,
} from '../filestack.config';
import { AntiVirusScanResult } from '../models/anti-virus-scan-result';
import {
    FilestackIntitializationOptions,
    FilestackStorage,
    FilestackUploadOptions,
    FilestackUploadResult,
    FilestackUploadType,
} from '../models/filestack.model';
import { TimeoutName } from '../models/timeout-name';
import { FileUploadService } from './file-upload.service';
import { FirestoreService } from './firestore.service';

@Injectable()
export class FilestackService {
    private inTransitReason = 'Still has the InTransit status';
    private inTransitInterval = 1000;
    private filestackClient: Client;
    private virusCheckWorkflowId: string;
    private virusCheckStatusTimeout: number;

    isFilestackDialogOpened$ = new BehaviorSubject<boolean>(false);

    constructor(
        @Inject('environment') private environment: FredEnvironment,
        private firebaseFileUploadService: FileUploadService,
        private http: HttpClient,
        private firestoreService: FirestoreService,
    ) {}

    getPushId(): string {
        return this.firestoreService.getPushId();
    }

    async uploadDroppedFile(file: File, url: string, userId: string): Promise<void> {
        await this.ensureFilestackClient(userId);
        const uploadResults = await this.filestackClient.upload(
            file,
            {},
            {
                workflows: [this.virusCheckWorkflowId],
            },
        );
        const jobId = this.getJobId(uploadResults);
        const filestackUploadResult = {
            url,
            filename: uploadResults.filename,
        };
        await this.createFileUploadsDocument([filestackUploadResult], jobId, userId);
    }

    async uploadFiles(options: FilestackUploadOptions): Promise<void> {
        await this.ensureFilestackClient(options.userId);
        return new Promise(resolve => {
            const defaultOptions = this.getPickerOptions(options);
            const storageOptions = this.getStorageOptions(options);
            this.filestackClient
                .picker({
                    ...defaultOptions,
                    ...storageOptions,
                    onOpen: () => this.isFilestackDialogOpened$.next(true),
                    onUploadStarted: () => {
                        console.info('FilestackService::uploadFiles - onUploadStarted');
                        if (options.onUploadStarted) {
                            options.onUploadStarted();
                        }
                    },
                    onFileUploadStarted: () => {
                        console.info('FilestackService::onFileUploadStarted - onFileUploadStarted');
                        if (options.onFileUploadStarted) {
                            options.onFileUploadStarted();
                        }
                    },

                    onFileUploadFinished: () => {
                        console.info('FilestackService::onFileUploadFinished - onFileUploadFinished');
                        if (options.onFileUploadFinished) {
                            options.onFileUploadFinished();
                        }
                    },
                    onUploadDone: async response => {
                        if (response.filesUploaded && response.filesUploaded.length !== 0) {
                            // Watch for the Filestack upload status document to appear in Firestore. It has the antivirus scan results
                            const jobId = this.getJobId(response.filesUploaded[0]);
                            await this.onUploadDone(options, response, jobId);

                            if (!!options?.oldFile) {
                                try {
                                    await this.firebaseFileUploadService.deleteFile(options.oldFile);
                                } catch (e) {
                                    console.error(e.message);
                                }
                            }
                        } else {
                            // No files were uploaded, call the onUploadFailed callback
                            this.handleOnUploadFailed(options, { response });
                        }
                        resolve();
                    },
                    onCancel: () => {
                        if (options.onCancel) {
                            options.onCancel();
                        }
                        resolve();
                    },
                    onClose: () => this.isFilestackDialogOpened$.next(false),
                })
                .open();
        });
    }

    async getSlidesCount(fileUrl: string): Promise<number> {
        await this.ensureFilestackClient();
        const docInfoUrl = this.filestackClient.transform(fileUrl, {
            output: {
                docinfo: true,
            },
        } as TransformOptions);
        return (await firstValueFrom(this.http.post(docInfoUrl, null)))['numpages'];
    }

    private async createFileUploadsDocument(
        filestackUploadResults: FilestackUploadResult[],
        jobId: string,
        userId: string,
    ): Promise<void> {
        console.info('FilestackService::createFileUploadsDocument - jobId', jobId);
        const fileUploadsDocument = {
            jobId,
            userId,
            filestackUploadResults,
            created: this.firestoreService.currentTimestamp,
        };
        await this.firestoreService.set(`fileUploads/${jobId}`, fileUploadsDocument);
    }

    private getJobId(fileUploadedMetaData: PickerFileMetadata): string {
        return fileUploadedMetaData['workflows'][this.virusCheckWorkflowId].jobid;
    }

    private getMetadata(client: Client, handle: string): Promise<any> {
        // build random params to prevent IE caching for requests with the same parameters
        const unnecessaryParams = [
            'size',
            'mimetype',
            'filename',
            'uploaded',
            'writeable',
            'cloud',
            'sourceUrl',
            'location',
        ];
        const timeStampBinary = Date.now().toString(2).slice(-unnecessaryParams.length);
        const randomUnnecessaryParams = timeStampBinary.split('').reduce((result, currentFlag, index) => {
            const currentField = unnecessaryParams[index];
            const currentValue = !!parseInt(currentFlag, 10);
            result[currentField] = currentValue;

            return result;
        }, {});

        return client.metadata(handle, {
            ...randomUnnecessaryParams,
            container: true,
            path: true,
        });
    }

    private handleOnUploadFailed(options: FilestackUploadOptions, antiVirusScanResult: AntiVirusScanResult): void {
        if (options.onUploadFailed) {
            options.onUploadFailed(antiVirusScanResult);
        }
    }

    private async onUploadDone(
        options: FilestackUploadOptions,
        response: PickerResponse,
        jobId: string,
    ): Promise<void> {
        const filestackUploadResults = await (options.storeTo === FilestackStorage.FirebaseStorage
            ? this.uploadFilesToFirebaseStorage(response, options)
            : this.getGoogleStorageFileUrl(response, options));

        // Create the fileUpload document for the background antivirus scan
        await this.createFileUploadsDocument(filestackUploadResults, jobId, options.userId);
    }

    private async uploadFilesToFirebaseStorage(
        response: PickerResponse,
        options: FilestackUploadOptions,
    ): Promise<FilestackUploadResult[]> {
        const files = await Promise.all(
            response.filesUploaded.map(async (filestackFile: PickerFileMetadata) => {
                const fileName = `${Date.now()}_${filestackFile.filename}`;
                const url = await this.firebaseFileUploadService.uploadFile(
                    `${options.fbFolder}${fileName}`,
                    filestackFile.originalFile as File,
                    options.customMetadata,
                );
                return {
                    url,
                    fileName: filestackFile.filename,
                    filestackFileId: filestackFile.handle,
                    filestackFileUrl: filestackFile.url,
                };
            }),
        );

        if (options.onUploadDone) {
            await options.onUploadDone(files);
        }
        return files;
    }

    private async getGoogleStorageFileUrl(
        response: PickerResponse,
        options: FilestackUploadOptions,
    ): Promise<FilestackUploadResult[]> {
        const promises: Promise<FilestackUploadResult>[] = response.filesUploaded.map((file: PickerFileMetadata) => {
            const filestackFileId = file.handle;
            const filestackFileUrl = file.url;
            const fileName = file.filename;

            return new Promise((resolve, reject) => {
                const intervalID = setInterval(() => {
                    this.getMetadata(this.filestackClient, filestackFileId)
                        .then(metaRes => {
                            const path = metaRes.path;
                            const container = metaRes.container;

                            if (path && container) {
                                return Promise.resolve(`https://storage.googleapis.com/${container}/${path}`);
                            } else {
                                return Promise.reject(this.inTransitReason);
                            }
                        })
                        .then((downloadURL: string) => {
                            resolve({
                                fileName,
                                filestackFileId,
                                filestackFileUrl,
                                url: downloadURL,
                            });
                            clearInterval(intervalID);
                        })
                        .catch(reason => {
                            if (reason !== this.inTransitReason) {
                                reject(reason);
                                clearInterval(intervalID);
                            }
                        });
                }, this.inTransitInterval);
            });
        });

        const results = await Promise.all(promises);
        if (options.onUploadDone) {
            await options.onUploadDone(results);
        }

        await this.completeUpload(results.map(({ filestackFileId }) => filestackFileId));
        return results;
    }

    private async completeUpload(filestackFileIds: string[]): Promise<void> {
        for (const filestackFileId of filestackFileIds) {
            await this.filestackClient.removeMetadata(filestackFileId);
        }
    }

    private getPickerOptions(options: FilestackUploadOptions): PickerOptions {
        switch (options.uploadType) {
            case FilestackUploadType.File:
                return fileUploadConfig;
            case FilestackUploadType.Images:
                return imagesUploadConfig;
            case FilestackUploadType.MultipleImages:
                return multipleImagesUploadConfig;
            case FilestackUploadType.EXCELFiles:
                return excelFilesUploadConfig;
            case FilestackUploadType.EXCELFile:
                return excelFileUploadConfig;
            case FilestackUploadType.PresentationFiles:
                return presentationFilesUploadConfig;
            default:
                return filesUploadConfig;
        }
    }

    private getStorageOptions(options: FilestackUploadOptions): PickerOptions {
        const workflows = [this.virusCheckWorkflowId];
        return options.storeTo === FilestackStorage.FirebaseStorage
            ? { storeTo: { workflows } }
            : {
                  storeTo: {
                      location: 'gcs',
                      path: options.gsFolder ? `/${options.gsFolder}/` : '/uploads/',
                      workflows,
                  },
              };
    }

    private async ensureFilestackClient(userId?: string): Promise<void> {
        const iv = CryptoJS.enc.Hex.parse(userId);

        if (!this.filestackClient) {
            const [encryptedResponse, timeouts] = await Promise.all([
                this.firestoreService.cloudFunctionCallable<string>('getFilestackInitializationOptions', {}),
                firstValueFrom(this.firestoreService.getDocument<Record<string, number>>('configuration/TIMEOUTS')),
            ]);
            const decryptedData = decrypt(encryptedResponse, this.environment.encryptionKey, iv);

            const { apiKey, security, virusCheckWorkflowId }: FilestackIntitializationOptions
                = JSON.parse(decryptedData);

            this.virusCheckWorkflowId = virusCheckWorkflowId;
            this.virusCheckStatusTimeout = timeouts[TimeoutName.VirusCheckStatus] ?? 300_000;
            this.filestackClient = initFilestackClient(apiKey, { security });
        }
    }
}
