import { CdkDragDrop, CdkDragStart, moveItemInArray } from '@angular/cdk/drag-drop';
import { isNil } from 'lodash';

import { SequenceGeneration } from '@accenture/shared/util';

export class AccentureDndUtil<T extends { sequence: string }> {
    private dragIndex: number | undefined;
    private dragHoverIndex: number | undefined;
    private containerId: string | undefined;
    private hoverContainerId: string | undefined;

    public setDragHoverIndex(index: number | undefined, containerId?: string): void {
        if (!isNil(this.containerId)) {
            this.hoverContainerId = containerId;
        }
        if (!isNil(this.dragIndex) && this.hoverContainerId == this.containerId) {
            this.dragHoverIndex = index;
        }
    }

    public showPlaceholderBefore(index: number, containerId: string): boolean {
        return (
            !isNil(this.containerId)
            && containerId === this.containerId
            && !isNil(this.hoverContainerId)
            && containerId === this.hoverContainerId
            && !isNil(this.dragIndex)
            && this.dragHoverIndex === index
            && this.dragIndex > index
        );
    }

    public showPlaceholderAfter(index: number, containerId: string): boolean {
        return (
            !isNil(this.containerId)
            && containerId === this.containerId
            && !isNil(this.hoverContainerId)
            && containerId === this.hoverContainerId
            && !isNil(this.dragIndex)
            && this.dragHoverIndex === index
            && this.dragIndex < index
        );
    }

    // [cdkDropListData]="items" is required
    public dragStarted(event: CdkDragStart, index: number): void {
        this.dragIndex = index;
        this.containerId = event.source.dropContainer.id;
        this.afterDragStarted(event.source.dropContainer.data[index]);
    }

    // [cdkDropListData]="items" is required
    public dragEnded(event: CdkDragStart, index: number): void {
        this.afterDragEnded(event.source.dropContainer.data[index]);
    }

    // [cdkDropListData]="items" is required
    // [cdkDragData]="item" is required
    public dropped(event: CdkDragDrop<T[], any, any>): void {
        const previousIndex = this.dragIndex as number;
        const currentIndex = this.dragHoverIndex as number;
        const {
            item: { data },
            previousContainer: { data: sortingArray },
        } = event;

        if (previousIndex === currentIndex || isNil(previousIndex) || isNil(currentIndex)) {
            this.reset();
            return;
        }

        let sequence = sortingArray[previousIndex].sequence;
        switch (true) {
            case currentIndex == 0: {
                sequence = SequenceGeneration.beforeFirst(sortingArray[0].sequence);
                break;
            }
            case currentIndex === sortingArray.length - 1: {
                sequence = SequenceGeneration.afterLast(sortingArray[currentIndex].sequence);
                break;
            }
            case previousIndex > currentIndex: {
                sequence = SequenceGeneration.between(
                    sortingArray[currentIndex].sequence,
                    sortingArray[currentIndex - 1].sequence,
                );
                break;
            }
            case previousIndex < currentIndex: {
                sequence = SequenceGeneration.between(
                    sortingArray[currentIndex].sequence,
                    sortingArray[currentIndex + 1].sequence,
                );
                break;
            }
            default:
                break;
        }

        const mutableSortingArray = [...sortingArray];

        moveItemInArray(mutableSortingArray, previousIndex, currentIndex);
        this.afterDropped(data, sequence);

        this.reset();
    }

    protected afterDragStarted(item: T): void {
        // can be overwritten in child class
    }

    protected afterDragEnded(item: T): void {
        // can be overwritten in child class
    }

    protected afterDropped(item: T, newSequence: string): void {
        // can be overwritten in child class
    }

    private reset(): void {
        this.dragIndex = undefined;
        this.dragHoverIndex = undefined;
        this.containerId = undefined;
        this.hoverContainerId = undefined;
    }
}
