import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CollectionEffects } from './collection.effects';
import { COLLECTION_FEATURE_KEY, collectionReducer } from './collection.reducer';
import { CollectionDataService } from './collection-data.service';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(COLLECTION_FEATURE_KEY, collectionReducer),
        EffectsModule.forFeature([CollectionEffects]),
    ],
    exports: [],
    providers: [CollectionDataService],
})
export class CollectionModule {}
