import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Store } from '@ngrx/store';
import { isNil, omitBy } from 'lodash';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { AppState, selectActivityIdAndParentIds, selectAuthenticatedUserId } from '@accenture/global-store';
import { FileType, StoragePathHelper } from '@accenture/shared/data';
import { Dictionary } from '@accenture/shared/data';
import {
    FilestackService,
    FilestackStorage,
    FilestackUploadResult,
    FilestackUploadType,
    FirestoreService,
} from '@accenture/shared/data-access';
import {
    DialogService,
    IconColor,
    ImageInputMode,
    ImageInputPlaceholdersTypes,
    imageInputPlaceholdersUrl,
    ImageModalComponent,
    ImageModalModel,
    LoaderSize,
} from '@accenture/shared/ui';

@Component({
    selector: 'accenture-image-input',
    templateUrl: './image-input.component.html',
    styleUrls: ['./image-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageInputComponent {
    @Input() file: FileType | undefined | null;
    @Input() data?: Dictionary<string | undefined>;
    @Input() descriptionText?: string;
    @Input() readonly?: boolean = false;
    @Input() mode?: ImageInputMode = ImageInputMode.Default;
    // TODO: remove default and the dependencies in the components when there is a redesign
    @Input() imagePlaceholder?: ImageInputPlaceholdersTypes | string = ImageInputPlaceholdersTypes.Default;
    @Input() isBallotImage?: boolean = false;
    @Input() canEditVoteActivity?: boolean = true;
    @Input() isBarChart?: boolean = false;
    @Input() isVoteActivity?: boolean = false;

    @Output() fileUploaded = new EventEmitter<FileType>();
    @Output() fileDeleted = new EventEmitter<void>();

    @ViewChild(MatMenuTrigger) menuOptions: MatMenuTrigger;

    imageInputPlaceholdersUrl = imageInputPlaceholdersUrl;

    private isLoading = new BehaviorSubject<boolean>(false);
    private isDeleteLoading = new BehaviorSubject<boolean>(false);

    isLoading$ = this.isLoading.asObservable();
    isDeleteLoading$ = this.isDeleteLoading.asObservable();
    iconColor = IconColor;
    isOverlayActive = false;

    constructor(
        private store: Store<AppState>,
        private filestackService: FilestackService,
        private firestoreService: FirestoreService,
        private dialogService: DialogService,
    ) {}

    get imageUrl(): string {
        return this.file?.url ? `url("${this.file.url}")` : '';
    }

    get isDefaultMode(): boolean {
        return this.mode === ImageInputMode.Default;
    }

    // TODO: remove it and the dependencies in the components when there is a redesign
    get isCircularMode(): boolean {
        return this.mode === ImageInputMode.Circular;
    }

    get isIconMode(): boolean {
        return this.mode === ImageInputMode.Icon;
    }

    get loaderSize(): LoaderSize {
        switch (this.mode) {
            case ImageInputMode.Icon:
                return LoaderSize.Small;
            default:
                return LoaderSize.Default;
        }
    }

    get hasPlaceholder(): boolean {
        return !this.imageUrl && (this.isDefaultMode || this.isCircularMode);
    }

    onOverlayClick(): void {
        if ((this.imageUrl && this.readonly) || !this.canEditVoteActivity) {
            return;
        }

        this.uploadImage();
    }

    toggleIconMore(active?: boolean): void {
        this.isOverlayActive = active;
    }

    disableHover(): boolean {
        return (!this.imageUrl && this.readonly) || (!this.canEditVoteActivity && !this.imageUrl && !this.readonly);
    }

    showIconMore(): boolean {
        return this.isVoteActivity && this.canEditVoteActivity && this.imageUrl && !this.readonly;
    }

    openPanel(event: Event): void {
        event.stopPropagation();
        this.menuOptions?.closeMenu();
        const data: ImageModalModel = {
            file: this.file,
            description: this.descriptionText,
        };
        this.dialogService.open(ImageModalComponent, {
            data,
            backdropClass: 'image-modal-component-backdrop',
            panelClass: 'image-modal-component-dialog-container',
        });
    }

    async uploadImage(trigger?: boolean): Promise<void> {
        if (this.readonly || (this.isVoteActivity && this.imageUrl && !trigger)) {
            return;
        }

        const { parentType, parentId } = await firstValueFrom(this.store.select(selectActivityIdAndParentIds));
        const userId = await firstValueFrom(this.store.select(selectAuthenticatedUserId));

        this.filestackService.uploadFiles({
            userId,
            storeTo: FilestackStorage.FirebaseStorage,
            uploadType: FilestackUploadType.Images,
            fbFolder: `${StoragePathHelper.getParentPath(parentType, parentId)}/`,
            onUploadStarted: () => this.isLoading.next(true),
            onUploadFailed: () => {
                this.isLoading.next(false);
            },
            // onFileUploadFinished: () => this.isLoadingFile.next(true),
            onUploadDone: async (files: FilestackUploadResult[]) => {
                const { url, fileName: name } = files[0];
                const id = await this.addFileRef(url);
                this.fileUploaded.emit({
                    id,
                    url,
                    name,
                } as FileType);

                // this.isLoadingFile.next(true);
                this.isLoading.next(false);
            },
            oldFile: this.file?.url?.split('?alt')[0] || undefined,
        });
    }

    async deleteImage(event?: Event): Promise<void> {
        event?.stopPropagation();
        this.isDeleteLoading.next(true);
        this.menuOptions?.closeMenu();
        await this.deleteFileRef();
        this.fileDeleted.emit();
        this.isDeleteLoading.next(false);
    }

    private addFileRef(url: string): Promise<string> {
        return this.firestoreService.addDocument(
            'filesRefs',
            omitBy(
                {
                    ...(this.data ?? {}),
                    url,
                    count: 1,
                },
                isNil,
            ),
        );
    }

    private async deleteFileRef(): Promise<void> {
        if (this.file?.id) {
            await this.firestoreService.update(`filesRefs/${this.file.id}`, {
                count: 0,
            });
        }
    }
}
