<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading">
    <accenture-base-dialog [title]="title">
      <div *ngIf="vm.userCollections?.length; else emptyCollections" class="move-to-dialog-container py-32 px-16">
        <p class="tt9-color-neutral-500">Session Name</p>
        <!-- TODO: tooltip -->
        <p class="tt9-body-1 pb-16">{{ vm.sessionName }}</p>

        <ng-container *ngIf="!!vm.collectionSession?.collectionId">
          <p class="tt9-color-neutral-500">Current Collection</p>
          <!-- TODO: tooltip -->
          <p class="tt9-body-1 pb-16">{{ vm.collectionSession.collectionName || "" }}</p>
        </ng-container>

        <p class="tt9-color-neutral-500">Move to</p>
        <mat-form-field appearance="outline" class="tt9-form-field hint" floatLabel="auto">
          <mat-label>Destination Collection</mat-label>

          <input
            #collectionsInput
            matInput
            [placeholder]="'Destination Collection'"
            [matAutocomplete]="auto"
            [formControl]="collectionInputControl"
            [maxlength]="characterLimitMedium"
            (input)="updateAutocompleteValue($event.target['value'])"
            (keyup.enter)="addChip()"
            (blur)="addChip()"
          />

          <div matSuffix>
            <ng-content select="input-suffix"></ng-content>
          </div>

          <mat-autocomplete #auto="matAutocomplete">
            <ng-container>
              <mat-option
                *ngFor="let collection of vm.filteredCollections; trackBy: trackById"
                [value]="collection.name"
                (click)="setCollection(collection)"
                (mousedown)="$event.preventDefault()"
              >
                {{ collection.name }}
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>

        <mat-chip-listbox class="tt9-chip">
          <mat-chip-option *ngIf="!!collectionControl?.value?.name?.trim()">
            <span>{{ collectionControl?.value?.name }}</span>
            <accenture-icon matChipRemove (click)="removeChip(chip)">icon-close</accenture-icon>
          </mat-chip-option>
        </mat-chip-listbox>
      </div>

      <div class="footer d-flex-between px-16 pt-16">
        <div *ngIf="vm.userCollections?.length">
          <button class="tt9-btn-default btn-icon mr-8" (click)="createNewCollection()">
            <span>Create Collection</span>
          </button>
        </div>
        <div class="d-flex">
          <button
            class="tt9-btn-default btn-icon mr-8"
            [disabled]="!vm.userCollections?.length || !collectionControl?.value?.name"
            (click)="closeDialog()"
          >
            <span>Cancel</span>
          </button>
          <button class="tt9-btn" (click)="moveToCollection()">Move</button>
        </div>
      </div>

      <ng-template #emptyCollections>
        <!-- TODO: styles -->
        <accenture-empty-screen
          class="m-auto"
          [title]="emptyScreenTitle"
          [description]="emptyScreenDescription"
          [isButtonVisible]="true"
          [buttonText]="emptyScreenButton"
          (buttonClick)="createNewCollection()"
        ></accenture-empty-screen>
      </ng-template>
    </accenture-base-dialog>
  </ng-container>
</ng-container>
