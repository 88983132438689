import { Pipe, PipeTransform } from '@angular/core';

import { NodeType, ParentType, parentTypeToDisplay } from '@accenture/shared/data';

@Pipe({
    name: 'displayParentType',
})
export class DisplayParentTypePipe implements PipeTransform {
    transform(type: ParentType | NodeType): string {
        return parentTypeToDisplay[type] || '';
    }
}
