import { PickerOptions } from 'filestack-js';

import {
    allAcceptedEXCELFileTypes,
    allAcceptedFileTypes,
    allAcceptedImageTypes,
    allAcceptedPresentationTypes,
} from './models/accepted-types';

export const filestackFilesSources = ['local_file_system'];

const maxFilesPerUpload = 5;

const defaultConfig = {
    dropPane: {},
    exposeOriginalFile: true,
    fromSources: filestackFilesSources,
    minFiles: 1,
};

export const imagesUploadConfig: PickerOptions = {
    ...defaultConfig,
    accept: allAcceptedImageTypes,
    maxFiles: 1,
};

export const multipleImagesUploadConfig: PickerOptions = {
    ...defaultConfig,
    accept: allAcceptedImageTypes,
    maxFiles: maxFilesPerUpload,
};

export const filesUploadConfig: PickerOptions = {
    ...defaultConfig,
    accept: allAcceptedFileTypes,
    maxFiles: maxFilesPerUpload,
};

export const fileUploadConfig: PickerOptions = {
    ...defaultConfig,
    accept: allAcceptedFileTypes,
    maxFiles: 1,
};

export const excelFilesUploadConfig: PickerOptions = {
    ...defaultConfig,
    accept: allAcceptedEXCELFileTypes,
    maxFiles: maxFilesPerUpload,
};

export const excelFileUploadConfig: PickerOptions = {
    ...defaultConfig,
    accept: allAcceptedEXCELFileTypes,
    maxFiles: 1,
};

export const presentationFilesUploadConfig: PickerOptions = {
    ...defaultConfig,
    accept: allAcceptedPresentationTypes,
    maxFiles: 1,
};
