import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { SessionRecordingConsentResponse } from '@accenture/shared/data';
import { SharedMaterialModule } from '@accenture/shared/material';
import { DialogService, SharedUiModule } from '@accenture/shared/ui';
import { trackById } from '@accenture/shared/util';

import { SessionRecorderReplyToDisclaimerFacade } from './session-recorder-reply-to-disclaimer-facade';

@Component({
    selector: 'accenture-session-recorder-reply-to-disclaimer',
    standalone: true,
    templateUrl: './session-recorder-reply-to-disclaimer.component.html',
    styleUrls: ['./session-recorder-reply-to-disclaimer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SessionRecorderReplyToDisclaimerFacade],
    imports: [CommonModule, SharedMaterialModule, SharedUiModule],
})
export class SessionRecorderReplyToDisclaimerComponent {
    vm$ = this.facade.vm$;
    loaderText!: string;

    trackById = trackById;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private facade: SessionRecorderReplyToDisclaimerFacade,
        private dialogService: DialogService,
    ) {}

    title = 'Disclaimer';

    async accept(): Promise<void> {
        this.dialogService.close();
        this.data.onRespond(SessionRecordingConsentResponse.Accepted);
    }

    async decline(): Promise<void> {
        this.dialogService.close();
        this.data.onRespond(SessionRecordingConsentResponse.Declined);
    }

    closeDialog() {
        this.dialogService.close();
    }
}
