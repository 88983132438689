import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'accenture-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogComponent implements OnInit {
    textAsHtml!: SafeHtml;
    private isToggleChecked = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Record<string, any>,
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        private sanitizer: DomSanitizer,
    ) {
        if (!('closeButton' in this.data)) {
            this.data['closeButton'] = true;
        }

        if (!('confirmBtn' in this.data)) {
            this.data['confirmBtn'] = true;
        }
    }

    closeDialog(): void {
        const cancelFn = this.data?.['closeButton'];
        if (typeof cancelFn === 'function') {
            cancelFn();
        }

        this.dialogRef.close();
    }

    confirmDialog(): void {
        const confirmFn = this.data?.['confirm'];
        if (typeof confirmFn === 'function') {
            confirmFn(this.isToggleChecked);
        }
        this.dialogRef.close();
    }

    cancel(): void {
        const cancelFn = this.data?.['closeButton'];
        if (typeof cancelFn === 'function') {
            cancelFn();
        }

        this.dialogRef.close();
    }

    changeToggle(value: boolean): void {
        this.isToggleChecked = value;
    }

    ngOnInit(): void {
        if (this.data['isDisplayLikeHtml']) {
            this.textAsHtml = this.sanitizer.bypassSecurityTrustHtml(this.data['text'] as string);
        }
    }
}
