import { ActivityType } from './activity-type';
import { BrainstormGroupingType } from './brainstorm';

export interface ActivityConfiguration {
    id?: string;
    sequence: string;
    name: string;
    shortName: string;
    type: ActivityType;
    description: string;
    icon: string;
    visible: boolean;
    defaultOptions: ActivityDefaultOptions;
    disabled?: boolean; // to Validate activity for not showing on create panel
}

export interface ActivityDefaultOptions {
    name: string;
    visible: boolean;
    showNameTags?: boolean;
    viewAll?: boolean;
    displayNotes?: boolean;
    displaySlides?: boolean;
    allowDownloads?: boolean;
    allowChangeAnswers?: boolean;
    enableResponses?: boolean;
    additionalText?: string;
    waitingScreenText?: string;
    statusScreenText?: string;
    displayOnlyIndividualLikes?: boolean;
    anonymous?: boolean;
    groupingType?: BrainstormGroupingType;
}

export type ActivityConfigurationsMap = Record<ActivityType, ActivityConfiguration> | Record<string, never>;
