import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ParentType, Session, Template } from '@accenture/shared/data';
import { trackById } from '@accenture/shared/util';

@Component({
    selector: 'accenture-parent-options',
    templateUrl: './parent-options.component.html',
    styleUrls: ['./parent-options.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParentOptionsComponent {
    @Input() data: Template | Session;
    @Input() parentType?: ParentType;
    @Output() updateData = new EventEmitter<Partial<Template | Session>>();

    trackById = trackById;

    updateSelfNavigate(selfNavigate: boolean): void {
        return this.updateData.emit({ selfNavigate } as Partial<Session | Template>);
    }

    updateDisplayAgenda(displayAgenda: boolean): void {
        if (!displayAgenda) {
            return this.updateData.emit({ displayAgenda, selfNavigate: false } as Partial<Session | Template>);
        }

        return this.updateData.emit({ displayAgenda } as Partial<Session | Template>);
    }
}
