import { ActivityItemType } from './activity-item-type';
import { Question } from '../question';

export class Text extends Question {
    type = ActivityItemType.Text;

    constructor(data: any) {
        super(data);
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            ...super.createSerializableObject(),
        };
    }
}
