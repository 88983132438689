export interface FredEnvironment {
    name: string;
    production: boolean;
    encryptionKey: string;
    firebase: FredFirebaseEnvironment;
    pendo: {
        APIKey: string;
        sendData: boolean;
    };
    sso: FredSsoEnvironment;
    teams: FredTeamsEnvironment;
    featureToggles?: {
        api?: boolean;
    };
}

export interface FredFirebaseEnvironment {
    cloudFunctionsURL: string;
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
}

export interface FredSsoEnvironment {
    clientId: string;
    authority: string;
    redirectUri: string;
    clientSecret?: string;
}

interface FredTeamsEnvironment extends FredSsoEnvironment {
    appStoreAppId: string;
    tabEntityId: string;
    host: string;
}
