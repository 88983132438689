import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { groupBy, mapValues } from 'lodash';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, tap } from 'rxjs/operators';

import { ActivityService } from '@accenture/activity/shared/domain';
import {
    DataFlowService,
    duplicateActivity,
    duplicateTableActivity,
    FeatureToggleName,
    LinkAccessService,
    saveActivityAsTemplateConfirmation,
    saveActivityProjectAsTemplateInfoSnackBar,
    saveActivityProjectAsTemplateSuccessSnackBar,
    saveActivityWithTableAsTemplateConfirmation,
    SessionFocusService,
    SessionService,
} from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    selectActivityConfigurations,
    selectActivityIdAndParentIds,
    selectAuthenticatedUser,
    selectFeatureToggle,
    selectSessionActivitiesData,
    selectSessionData,
    selectSessionTeamMemberData,
    selectUserSessions,
} from '@accenture/global-store';
import {
    Activity,
    ActivityConfiguration,
    ActivityConfigurationsMap,
    activityDeletedSnackbarTitle,
    activityDuplicatedSnackbarTitle,
    activityDuplicatingErrorSnackbarTitle,
    activityDuplicatingInProgressSnackbarTitle,
    activityHasBeenDeletedSnackbarText,
    activityHasBeenDuplicatedSnackbarText,
    activityIsBeingDeletedSnackbarText,
    activityIsBeingDuplicatedSnackbarText,
    ActivityType,
    BotActions,
    breakpoints,
    DataFlowConnection,
    deletingActivityErrorSnackbarTitle,
    deletingActivityInProgressSnackbarTitle,
    Dictionary,
    errorMessageSnackbarText,
    ParentType,
    routerLinksMap,
    Session,
    SessionFocus,
    SessionStatus,
    templateCreatedSnackbarTitle,
    templateCreationErrorTitleSnackbarTitle,
    templateCreationInProgressSnackbarTitle,
    UserSession,
} from '@accenture/shared/data';
import {
    AiGenerateModalTt9Component,
    SequenceData,
    SessionsDataFlowConnectionDialogComponent,
} from '@accenture/shared/shared-session';
import { ConfirmationDialogComponent, DialogService, SnackbarService, SnackBarTypes } from '@accenture/shared/ui';
import { compareSequences, SequenceGeneration } from '@accenture/shared/util';

import { confirmationDeletionMessage } from './constants';

export interface ActivitiesListViewModel {
    activityConfigurations: ActivityConfiguration[];
    activityConfigurationsMap: ActivityConfigurationsMap;
    activities: Activity[];
    activitiesCount: number;
    connections: DataFlowConnection[];
    sessionStatus: SessionStatus;
    searchValue: string;
    isUserTemplateCreator: boolean;
    isActivitiesPanelOpened: boolean;
    isActivityPreviewOpened: boolean;
    connectionsByDestinationId: Dictionary<DataFlowConnection[]>;
    activitiesById: Dictionary<Activity>;
    sessionNamesById: Dictionary<string>;
    isSessionParticipant: boolean;
    isSessionEditor: boolean;
    session?: Session;
    showDataFlowConnectionsBtn: boolean;
    isSearchShown: boolean;
    isTemplate: boolean;
    isPublicTemplate: boolean;
    isAllHandsetsScreen: boolean;
    sessionFocus: SessionFocus | null;
    isShowGetLinks: boolean;
    isLoading: boolean;
}

const defaultViewModel = {
    activityConfigurations: [],
    activityConfigurationsMap: {},
    activities: [],
    activitiesCount: 0,
    connections: [],
    sessionStatus: SessionStatus.Draft,
    searchValue: '',
    isUserTemplateCreator: false,
    isActivitiesPanelOpened: false,
    isActivityPreviewOpened: false,
    connectionsByDestinationId: {} as Dictionary<DataFlowConnection[]>,
    activitiesById: {} as Dictionary<Activity>,
    isSessionParticipant: false,
    isSessionEditor: false,
    sessionNamesById: {} as Dictionary<string>,
    showDataFlowConnectionsBtn: false,
    isSearchShown: false,
    isTemplate: false,
    isPublicTemplate: false,
    isAllHandsetsScreen: false,
    sessionFocus: null,
    isShowGetLinks: false,
    isLoading: true,
} as ActivitiesListViewModel;

@Injectable()
export class ActivitiesListFacade {
    private isLoading$ = new BehaviorSubject<boolean>(false);
    private isActivitiesPanelOpened$ = new BehaviorSubject<boolean>(false);
    private isActivityPreviewPanelOpened$ = new BehaviorSubject<boolean>(false);
    private searchValue$ = new BehaviorSubject<string>('');
    private isSearchShown$ = new BehaviorSubject<boolean>(false);

    private parentType!: ParentType;
    private parentId!: string;
    private projectId!: string;
    private sessionId!: string;
    private sessionName!: string;
    private activityId!: string;
    private userId!: string;
    private previousSequence!: string;
    private nextSequence!: string;
    private nextActivityIndex!: number;
    private sessionFocus!: SessionFocus | null;
    private activitiesById!: Dictionary<Activity>;
    private connections!: DataFlowConnection[];
    private linkAccessId: string;

    private readonly projectParentTypes = [
        ParentType.Projects,
        ParentType.ProjectTemplates,
        ParentType.PublicProjectTemplates,
    ];
    private readonly templatesParentTypes = [ParentType.Templates, ParentType.PublicSessionTemplates];
    private readonly publicParentTypes = [ParentType.PublicProjectTemplates, ParentType.PublicSessionTemplates];

    vm$ = this.buildViewModel();

    constructor(
        private store: Store<AppState>,
        private router: Router,
        private activityService: ActivityService,
        private dialogService: DialogService,
        private dataFlowService: DataFlowService,
        private snackbarService: SnackbarService,
        private sessionFocusService: SessionFocusService,
        private breakpointObserver: BreakpointObserver,
        private linkAccessService: LinkAccessService,
        private sessionService: SessionService,
    ) {}

    openAiGenerateActivityDialog(): void {
        this.dialogService.open(AiGenerateModalTt9Component, {
            title: 'Create activity',
            panelClass: 'tt9-modal',
            width: '768px',
            disableClose: true,
            botAction: BotActions.CreateActivity,
        });
    }

    toggleShowSearch(): void {
        if (this.isSearchShown$.getValue()) {
            this.searchValue$.next('');
        }
        this.isSearchShown$.next(!this.isSearchShown$.getValue());
    }

    toggleActivitiesPanel(opened: boolean): void {
        this.isActivitiesPanelOpened$.next(opened);

        if (!opened) {
            this.clearDataForInsertion();
        }
    }

    toggleActivityPreviewPanel(opened: boolean): void {
        this.isActivityPreviewPanelOpened$.next(opened);
    }

    filterActivities(searchValue: string): void {
        this.searchValue$.next(searchValue);
    }

    setDataForBetweenInsertion(
        previousActivitySequence: string,
        nextActivitySequence: string,
        nextActivityIndex: number,
    ): void {
        this.previousSequence = previousActivitySequence;
        this.nextSequence = nextActivitySequence;
        this.nextActivityIndex = nextActivityIndex;
    }

    navigateToActivity(activity: Activity, isEdit?: boolean): void {
        const type = activity.type.toLowerCase();
        const parentName = routerLinksMap[this.parentType];
        const sessionParams = [parentName, this.sessionId];
        const isTemplate = [ParentType.Templates, ParentType.PublicSessionTemplates].includes(this.parentType);
        const routeType = isEdit && !isTemplate ? `${type}-edit` : type;

        this.router.navigate([...sessionParams, routeType, activity.id]);
    }

    updateActivity(activityId: string, activityFields: Partial<Activity>): Promise<void> {
        return this.activityService.updateActivity(this.parentType, this.parentId, activityId, activityFields);
    }

    openAddConnectionDialog(): void {
        this.dialogService.open(SessionsDataFlowConnectionDialogComponent, {
            title: 'Create connection',
            panelClass: 'tt9-modal',
        });
    }

    openDeleteConfirmationDialog(activityOrSection: Activity): void {
        this.dialogService.open(ConfirmationDialogComponent, {
            width: '444px',
            panelClass: 'tt9-modal',
            title: `Delete ${activityOrSection.name} activity`,
            confirmBtnText: 'Delete',
            cancelBtnText: 'Cancel',
            text: confirmationDeletionMessage.activity,
            isWarning: true,
            confirm: () => this.deleteActivity(activityOrSection as Activity),
        });
    }

    getActivitySequence(): SequenceData | undefined {
        if (this.previousSequence && this.nextSequence) {
            return {
                previousSequence: this.previousSequence,
                nextSequence: this.nextSequence,
            };
        }

        return undefined;
    }

    updateSequenceData(activities: Activity[]): void {
        if (!this.nextActivityIndex) {
            return;
        }

        this.nextSequence = activities[this.nextActivityIndex].sequence;
    }

    async openSaveAsTemplateDialog(activityId: string): Promise<void> {
        const canSaveResponses = await this.canSaveResponses(activityId);

        this.dialogService.open(ConfirmationDialogComponent, {
            title: 'Save as Template',
            cancelBtnText: 'Cancel',
            width: '600px',
            confirmBtnText: 'Save',
            text: canSaveResponses ? saveActivityWithTableAsTemplateConfirmation : saveActivityAsTemplateConfirmation,
            isToggleVisible: canSaveResponses,
            confirm: async (saveResponses: boolean) => this.saveAsTemplate(activityId, saveResponses),
        });
    }

    async openDuplicateActivityDialog({ id, sequence, type }: Activity, nextSequence: string): Promise<void> {
        const newSequence = nextSequence
            ? SequenceGeneration.between(sequence, nextSequence)
            : SequenceGeneration.afterLast(sequence);

        this.dialogService.open(ConfirmationDialogComponent, {
            title: 'Duplicate activity',
            cancelBtnText: 'Cancel',
            width: '600px',
            confirmBtnText: 'Duplicate',
            text: type === ActivityType.Table ? duplicateTableActivity : duplicateActivity,
            isToggleVisible: type === ActivityType.Table,
            confirm: async (saveResponses: boolean) => this.duplicateActivity(id, newSequence, saveResponses),
        });
    }

    async updateActivityVisibility(activityId: string, visible: boolean): Promise<void> {
        await this.updateActivity(activityId, { visible } as Partial<Activity>);

        await this.updateLinkAccessActivity(activityId, visible);
        if (!visible && activityId === this.sessionFocus?.activityId) {
            await this.sessionFocusService.deleteSessionFocus(this.sessionId);
        }
    }

    async updateDataFlowConnections(activity: Activity, newSequence: string): Promise<void> {
        const sourceConnections = this.connections.filter(({ sourceIds }) => sourceIds?.includes(activity.id)) || [];
        const destinationConnection
            = this.connections.find((connection) => activity.id === connection.destinationId)
            || ({} as DataFlowConnection);
        let connectionsIdsToDelete = [];

        if (destinationConnection?.sourceIds) {
            for (const sourceId of destinationConnection.sourceIds) {
                connectionsIdsToDelete = this.compareSequencesAndIncludingData(
                    this.activitiesById[sourceId]?.sequence,
                    newSequence,
                    destinationConnection.id,
                    connectionsIdsToDelete,
                );
            }
        }

        for (const connection of sourceConnections) {
            connectionsIdsToDelete = this.compareSequencesAndIncludingData(
                newSequence,
                this.activitiesById[connection.destinationId].sequence,
                connection.id,
                connectionsIdsToDelete,
            );
        }

        if (connectionsIdsToDelete.length) {
            this.dataFlowService.deleteDataFlowConnections(connectionsIdsToDelete, this.parentType, this.parentId);
        }
    }

    private buildViewModel(): Observable<ActivitiesListViewModel> {
        return combineLatest({
            ids: this.store.pipe(
                select(selectActivityIdAndParentIds),
                tap(({ parentType, parentId, sessionId, templateId }) => {
                    this.parentType = parentType;
                    this.parentId = parentId;
                    this.sessionId = sessionId || templateId;
                }),
            ),
            user: this.store.pipe(
                select(selectAuthenticatedUser),
                tap((user) => {
                    this.userId = user.id;
                }),
            ),
        }).pipe(
            switchMap(({ user }) => {
                const isPublicTemplate = this.publicParentTypes.includes(this.parentType);

                const activities$ = this.store.pipe(select(selectSessionActivitiesData));
                const sessionTeamMember$ = this.store.pipe(select(selectSessionTeamMemberData));

                return combineLatest({
                    activityConfigurations: this.store.select(selectActivityConfigurations),
                    session: this.getSession(),
                    connections: this.getDataFlowConnections(),
                    userSessions: this.getUserSessions(),
                    sessionFocus: this.sessionFocusService.getSessionFocusNew(this.sessionId, this.parentType),
                    isActivitiesPanelOpened: this.isActivitiesPanelOpened$.asObservable().pipe(distinctUntilChanged()),
                    isActivityPreviewOpened: this.isActivityPreviewPanelOpened$
                        .asObservable()
                        .pipe(distinctUntilChanged()),
                    isSearchShown: this.isSearchShown$.asObservable().pipe(distinctUntilChanged()),
                    searchValue: this.searchValue$.asObservable().pipe(
                        map((value: string) => value.trim().toLowerCase()),
                        distinctUntilChanged(),
                    ),
                    activities: activities$,
                    sessionTeamMember: sessionTeamMember$,
                    isAllHandsetsScreen: this.breakpointObserver.observe(breakpoints.screenAllHandsets),
                    isTabletPortrait: this.breakpointObserver.observe(breakpoints.screenTabletPortrait),
                    deletingActivityId: this.activityService.deletingActivityIds$,
                    showGetLinks: this.store.select(selectFeatureToggle(FeatureToggleName.ShowGetLinks)),
                    isLoading: this.isLoading$,
                }).pipe(
                    map(
                        ({
                            activityConfigurations,
                            session,
                            connections,
                            userSessions,
                            sessionFocus,
                            isActivitiesPanelOpened,
                            isActivityPreviewOpened,
                            isSearchShown,
                            searchValue,
                            activities,
                            sessionTeamMember,
                            isAllHandsetsScreen,
                            isTabletPortrait,
                            deletingActivityId,
                            showGetLinks,
                            isLoading,
                        }) => {
                            const activityConfigurationsMap = activityConfigurations.reduce(
                                (accumulator, activityConfiguration) => {
                                    accumulator[activityConfiguration.type] = activityConfiguration;
                                    return accumulator;
                                },
                                {},
                            );
                            const isSessionParticipant = sessionTeamMember?.isSessionParticipant;
                            const isSessionEditor = sessionTeamMember?.isSessionEditor || isPublicTemplate;
                            const sessionNamesById = mapValues(
                                groupBy(userSessions, 'sessionId'),
                                (userSession) => userSession[0].name,
                            );
                            const activitiesToDisplay = activityConfigurations.filter((activity) => !activity.disabled);
                            const activitiesById = mapValues(groupBy(activities, 'id'), (activity) => activity[0]);
                            const filteredActivities = activities.filter((activity) => {
                                return (
                                    !deletingActivityId.includes(activity.id)
                                    && activity.name.toLowerCase().includes(searchValue)
                                    && (activity.visible || isSessionEditor || isPublicTemplate)
                                );
                            });

                            const isShowGetLinks
                                = showGetLinks
                                && ![ParentType.Templates, ParentType.PublicSessionTemplates].includes(this.parentType);

                            this.sessionFocus = sessionFocus || null;
                            this.activitiesById = activitiesById;
                            this.connections = connections;
                            this.sessionName = session.name;

                            return {
                                connections,
                                searchValue,
                                isActivitiesPanelOpened,
                                isActivityPreviewOpened,
                                sessionNamesById,
                                session,
                                isLoading,
                                isSessionParticipant,
                                isSessionEditor,
                                isSearchShown,
                                activityConfigurationsMap,
                                isPublicTemplate,
                                activitiesById,
                                sessionFocus,
                                isShowGetLinks,
                                isTemplate: [ParentType.Templates, ParentType.PublicSessionTemplates].includes(
                                    this.parentType,
                                ),
                                isUserTemplateCreator: user.isTemplateCreator,
                                activitiesCount: filteredActivities?.length || 0,
                                activities: filteredActivities,
                                activityConfigurations: activitiesToDisplay,
                                sessionStatus: session?.status,
                                connectionsByDestinationId: groupBy(connections, 'destinationId'),
                                showDataFlowConnectionsBtn: ParentType.Sessions === this.parentType,
                                isAllHandsetsScreen: isAllHandsetsScreen.matches,
                                isTabletPortrait: isTabletPortrait.matches,
                            };
                        },
                    ),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    private compareSequencesAndIncludingData(
        firstSequence: string,
        secondSequence: string,
        connectionId: string,
        existingIds: string[],
    ): string[] {
        const ids = existingIds;

        if (compareSequences(firstSequence, secondSequence) >= 0 && !ids.includes(connectionId)) {
            ids.push(connectionId);
        }

        return ids;
    }

    private showSaveAsTemplateInfoSnackBar(): void {
        this.snackbarService.showSnackBar(
            templateCreationInProgressSnackbarTitle,
            saveActivityProjectAsTemplateInfoSnackBar,
            SnackBarTypes.Info,
            true,
        );
    }

    private showSaveAsTemplateSuccessSnackBar(): void {
        this.snackbarService.showSnackBar(
            templateCreatedSnackbarTitle,
            saveActivityProjectAsTemplateSuccessSnackBar,
            SnackBarTypes.Success,
            true,
        );
    }

    private showSaveAsTemplateErrorSnackBar(): void {
        this.snackbarService.showSnackBar(
            templateCreationErrorTitleSnackbarTitle,
            errorMessageSnackbarText,
            SnackBarTypes.Error,
            true,
        );
    }

    private getSession(): Observable<Session> {
        return this.store.select(selectSessionData).pipe(filter(Boolean));
    }

    private getUserSessions(): Observable<UserSession[]> {
        return this.store.select(selectUserSessions);
    }

    private getDataFlowConnections(): Observable<DataFlowConnection[]> {
        return this.templatesParentTypes.includes(this.parentType)
            ? this.dataFlowService.getTemplateDataFlowConnections(this.parentType, this.parentId)
            : this.dataFlowService.getDataFlowConnectionsByProperty(
                  this.parentType,
                  this.parentId,
                  'destinationSessionId',
                  this.sessionId,
              );
    }

    private async deleteActivity(activity: Activity): Promise<void> {
        this.activityService.updateDeletingActivityIds(activity.id);
        this.snackbarService.showSnackBar(
            deletingActivityInProgressSnackbarTitle,
            activityIsBeingDeletedSnackbarText,
            SnackBarTypes.Info,
            false,
        );
        try {
            await this.activityService.deleteActivity(this.parentType, this.parentId, activity.id);
            this.snackbarService.showSnackBar(
                activityDeletedSnackbarTitle,
                activityHasBeenDeletedSnackbarText,
                SnackBarTypes.Success,
                true,
            );
        } catch (e) {
            console.error(e);
            this.snackbarService.showSnackBar(
                deletingActivityErrorSnackbarTitle,
                errorMessageSnackbarText,
                SnackBarTypes.Error,
                true,
            );
        }

        this.activityService.removeActivityIdFromDeletingActivityIds(activity.id);
    }

    private clearDataForInsertion(): void {
        this.previousSequence = undefined;
        this.nextSequence = undefined;
    }

    private async canSaveResponses(activityId: string): Promise<boolean> {
        const activity = await firstValueFrom(
            this.activityService.getActivity(this.parentType, this.parentId, activityId),
        );
        return activity?.type === ActivityType.Table;
    }

    private async saveAsTemplate(activityId: string, saveResponses: boolean): Promise<void> {
        try {
            this.showSaveAsTemplateInfoSnackBar();
            await this.activityService.saveAsTemplateNew(this.parentId, activityId, this.parentType, saveResponses);
            this.showSaveAsTemplateSuccessSnackBar();
        } catch (e) {
            console.error('Save as template method has error');
            this.showSaveAsTemplateErrorSnackBar();
        }
    }

    private async duplicateActivity(id: string, newSequence: string, saveResponses: boolean): Promise<void> {
        try {
            this.snackbarService.showSnackBar(
                activityDuplicatingInProgressSnackbarTitle,
                activityIsBeingDuplicatedSnackbarText,
                SnackBarTypes.Info,
                false,
            );
            await this.activityService.duplicateNew(
                this.parentType,
                this.parentId,
                id,
                this.sessionId,
                newSequence,
                saveResponses,
            );
            this.snackbarService.showSnackBar(
                activityDuplicatedSnackbarTitle,
                activityHasBeenDuplicatedSnackbarText,
                SnackBarTypes.Success,
                true,
            );
        } catch (e) {
            console.error('Duplicate activity has error');
            this.snackbarService.showSnackBar(
                activityDuplicatingErrorSnackbarTitle,
                errorMessageSnackbarText,
                SnackBarTypes.Error,
                true,
            );
        }
    }

    private async updateLinkAccessActivity(activityId: string, activityVisible: boolean): Promise<void> {
        const linkAccessId = await firstValueFrom(this.linkAccessService.getLinkAccessByActivity(activityId));
        return await this.linkAccessService.updateLinkAccessActivityVisibility(linkAccessId.id, activityVisible);
    }

    getLinkAccessWithActivity(activity: Activity): void {
        this.linkAccessService.copyActivityLinkAccessToClipboard(activity, this.sessionName);
    }
}
