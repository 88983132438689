import { Pipe, PipeTransform } from '@angular/core';

import { InAppNotificationStatusFilterType } from '@accenture/shared/data';

export const displayInAppNotificationFilterStatus: { [key in InAppNotificationStatusFilterType as string]: string } = {
    [InAppNotificationStatusFilterType.Read]: 'Read',
    [InAppNotificationStatusFilterType.Unread]: 'Unread',
};

@Pipe({
    name: 'displayNotificationStatus',
    standalone: true,
})
export class DisplayNotificationStatusPipe implements PipeTransform {
    transform(filterStatus: InAppNotificationStatusFilterType): unknown {
        return displayInAppNotificationFilterStatus[filterStatus];
    }
}
