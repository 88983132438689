import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of, Subscription, timer } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';

import { SessionRecordingsService } from '@accenture/activity/shared/domain';
import {
    AppState,
    selectActivityId,
    selectActivityIdAndParentIds,
    selectAuthenticatedUserId,
    selectSessionId,
    selectSessionTeamMemberData,
} from '@accenture/global-store';
import { SessionRecording, SessionRecordingConsent, SessionRecordingConsentResponse } from '@accenture/shared/data';
import {
    FilestackService,
    FilestackUploadResult,
    FilestackUploadType,
    FirestoreService,
} from '@accenture/shared/data-access';
import { DialogService, SnackbarService } from '@accenture/shared/ui';

import { SessionRecorderCreateDisclaimerComponent } from './session-recorder-create-disclaimer/session-recorder-create-disclaimer.component';
import { SessionRecorderReplyToDisclaimerComponent } from './session-recorder-reply-to-disclaimer/session-recorder-reply-to-disclaimer.component';
import { SessionRecorderSummarizeComponent } from './session-recorder-summarize/session-recorder-summarize.component';
import { SessionRecorderStore, SessionRecorderTimer } from './session-recorder-store';

enum RecorderStates {
    Recording = 'recording',
    Paused = 'paused',
}

export interface SessionRecorderModel {
    consents: SessionRecordingConsent[];
    recordings: SessionRecording[];
    sessionId: string;
    isAvailable: boolean;
    isLoading: boolean;
    isPanelOpened: boolean;
    isRecording: boolean;
    isPaused: boolean;
    isProcessing: boolean;
    isUploading: boolean;
    timer: SessionRecorderTimer;
}

export const initialState: SessionRecorderModel = {
    consents: [],
    recordings: [],
    sessionId: '',
    isAvailable: false,
    isLoading: true,
    isPanelOpened: false,
    isRecording: false,
    isPaused: true,
    isProcessing: false,
    isUploading: false,
    timer: {} as SessionRecorderTimer,
};

@Injectable()
export class SessionRecorderFacade {
    private isLoading$ = new BehaviorSubject<boolean>(false);
    private isPanelOpened$ = new BehaviorSubject<boolean>(false);
    private isRecording$ = new BehaviorSubject<boolean>(false);
    private isPaused$ = new BehaviorSubject<boolean>(false);
    private isProcessing$ = new BehaviorSubject<boolean>(false);
    private isUploading$ = new BehaviorSubject<boolean>(false);

    private recorder;
    private recordingData = [];
    private recorderStream;
    vm$ = this.buildViewModel();

    userId?: string;
    sessionId?: string;
    disclaimerId!: string;

    saveRecording = false;

    format = 'video/webm';

    timerObservable!: Observable<number>;
    timerSubscription$: Subscription;

    timer!: number;
    actionEvents = [];

    constructor(
        private store: Store<AppState>,
        private sessionRecordingsService: SessionRecordingsService,
        private dialogService: DialogService,
        private firestoreService: FirestoreService,
        private sessionRecorderStore: SessionRecorderStore,
        private snackbarService: SnackbarService,
        private filestackService: FilestackService,
    ) {}

    togglePanel(value: boolean): void {
        this.isPanelOpened$.next(value);
    }

    openSummarizeModal(): void {
        this.dialogService.open(SessionRecorderSummarizeComponent, {
            title: 'Summarize',
            panelClass: 'tt9-modal',
            minHeight: '960px',
        });
    }

    async createDisclaimer(): Promise<void> {
        this.isProcessing$.next(true);
        const { sessionId, userId } = this;
        const { disclaimerId, count } = await this.firestoreService.cloudFunctionCallable<any>(
            'initializeSessionRecording',
            {
                sessionId,
                userId,
            },
        );

        this.setDisclaimer(disclaimerId);

        this.isProcessing$.next(false);

        if (count > 0) {
            this.snackbarService.showSuccessSnackBar(
                `Consent sent out`,
                `Recording disclaimer sent to ${count} users.`,
                true,
            );

            this.dialogService.open(SessionRecorderCreateDisclaimerComponent, {
                panelClass: 'tt9-modal',
                minHeight: '960px',
                disableClose: true,
                onAccept: () => {
                    this.start();
                },
                onCancel: () => {
                    this.unsetDisclaimer();
                },
            });
        } else {
            this.start();
        }
    }

    setDisclaimer(disclaimerId: string): void {
        this.saveRecording = false;
        this.disclaimerId = disclaimerId;
        this.sessionRecorderStore.setDisClaimerId(this.disclaimerId);
    }

    async unsetDisclaimer(): Promise<void> {
        const { disclaimerId, saveRecording } = this;

        if (!saveRecording) {
            await this.sessionRecordingsService.scrapDisclaimer(this.sessionId, disclaimerId);
        }
        this.disclaimerId = null;
        this.sessionRecorderStore.resetState();
    }

    replyToDisclaimer(): void {
        this.dialogService.open(SessionRecorderReplyToDisclaimerComponent, {
            panelClass: 'tt9-modal',
            minHeight: '960px',
            disableClose: true,
            onRespond: async (response: SessionRecordingConsentResponse) => {
                const consents = await firstValueFrom(this.getPendingConsents());

                await Promise.all(
                    consents.map((consent) =>
                        this.sessionRecordingsService.replyToConsent(
                            this.sessionId,
                            consent.id,
                            response,
                            consent.disclaimerId,
                        ),
                    ),
                );
            },
        });
    }

    async start(): Promise<void> {
        let microphoneStream, screenStream;
        this.recordingData = [];

        try {
            microphoneStream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
            screenStream = await navigator.mediaDevices.getDisplayMedia({
                video: { displaySurface: 'monitor', width: 1280, height: 720, frameRate: 30 },
                audio: { channelCount: 2 },
            });

            // start timer

            this.timer = 0;
            this.timerObservable = timer(0, 1000);
            this.actionEvents = [];
            this.timerSubscription$ = this.timerObservable.subscribe(() => {
                if (this.recorder.state === RecorderStates.Recording) {
                    const toSeconds = this.timer % 60;
                    const toMinutes = Math.floor(this.timer / 60);

                    this.sessionRecorderStore.setTimer({
                        seconds: (toSeconds < 10 ? '0' : '') + toSeconds,
                        minutes: (toMinutes < 10 ? '0' : '') + Math.floor(this.timer / 60),
                    });
                    this.timer += 1;
                }
            });
        } catch (e) {
            this.unsetDisclaimer();
            console.error('capture failure', e);
            return;
        }

        this.recorderStream = microphoneStream ? this.mux(microphoneStream, screenStream) : screenStream;
        this.recorder = new MediaRecorder(this.recorderStream, { mimeType: this.format });

        this.recorder.ondataavailable = (e) => {
            if (e.data && e.data.size > 0) {
                this.recordingData.push(e.data);
            }
        };

        this.recorder.onstop = async () => {
            this.recorderStream.getTracks().forEach((track) => track.stop());
            microphoneStream.getTracks().forEach((track) => track.stop());
            screenStream.getTracks().forEach((track) => track.stop());
            console.info('tracks stopped');
            if (this.saveRecording) {
                await this.save();
            }

            this.timerSubscription$.unsubscribe();
            this.unsetDisclaimer();
        };

        this.recorderStream.addEventListener('inactive', () => {
            console.info('Capture stream inactive');
            stop();
        });

        this.recorder.start();
        this.isRecording$.next(true);
        this.isPaused$.next(false);
        this.togglePanel(false);
        console.info('started recording');
    }

    stop(saveRecording: boolean): void {
        this.saveRecording = saveRecording;
        this.isRecording$.next(false);
        this.isPaused$.next(false);
        console.info('Stopping recording');
        this.recorder.stop();
    }

    async save(): Promise<void> {
        const { sessionId, disclaimerId, userId, recordingData, format } = this;
        this.snackbarService.showInfoSnackBar(
            `Saving Capture`,
            `Please wait while the recording is being uploaded.`,
            true,
        );

        const { actionEvents } = this;
        await this.sessionRecordingsService.saveCapture(
            sessionId,
            disclaimerId,
            userId,
            recordingData,
            format,
            actionEvents,
        );
        this.snackbarService.showSuccessSnackBar(
            `Transcription in progress`,
            `The recording will appear in the list once transcription is done.`,
            true,
        );
    }

    upload(): Promise<void> {
        const { sessionId, userId } = this;
        return new Promise((resolve, reject) => {
            this.filestackService.uploadFiles({
                userId: this.userId,
                uploadType: FilestackUploadType.MediaFile,
                onUploadStarted: () => this.isUploading$.next(true),
                onFileUploadFinished: () => {
                    this.snackbarService.showSuccessSnackBar(
                        `Transcription in progress`,
                        `The upload will appear in the list once transcription is done.`,
                        true,
                    );
                },
                onUploadFailed: () => {
                    this.snackbarService.showErrorSnackBar(`Upload Failed`, `The upload failed`, true);
                    this.isUploading$.next(false);
                },
                onUploadDone: async (uploadResult: FilestackUploadResult[]) => {
                    const fileUrl = uploadResult[0].filestackFileUrl;
                    const fileName = uploadResult[0].fileName;

                    try {
                        if (fileName && fileUrl) {
                            await this.sessionRecordingsService.pipeFile(sessionId, userId, fileUrl, fileName);
                            this.isUploading$.next(false);
                            resolve();
                        }
                        this.isUploading$.next(false);
                        reject();
                    } catch (e) {
                        console.error('Import failed or completed with warnings');
                        this.isUploading$.next(false);
                        reject(e);
                    }
                },
            });
        });
    }

    pause(): void {
        if (this.recorder.state === RecorderStates.Paused) {
            this.recorder.resume();
            this.isPaused$.next(false);
        } else if (this.recorder.state === RecorderStates.Recording) {
            this.recorder.pause();
            this.isPaused$.next(true);
        } else {
            console.error(`recorder in unhandled state: ${this.recorder.state}`);
        }
        console.info(`recorder ${this.recorder.state === RecorderStates.Paused ? 'paused' : 'recording'}`);
    }

    private mux(stream1, stream2): MediaStream {
        const ctx = new AudioContext();
        const dest = ctx.createMediaStreamDestination();

        if (stream1.getAudioTracks().length > 0) ctx.createMediaStreamSource(stream1).connect(dest);

        if (stream2.getAudioTracks().length > 0) ctx.createMediaStreamSource(stream2).connect(dest);

        let tracks = dest.stream.getTracks();
        tracks = tracks.concat(stream1.getVideoTracks()).concat(stream2.getVideoTracks());

        return new MediaStream(tracks);
    }

    private buildViewModel(): Observable<SessionRecorderModel> {
        const preload$ = combineLatest([
            this.store.select(selectAuthenticatedUserId),
            this.store.select(selectSessionId),
            this.store.select(selectActivityId),
            this.isRecording$.asObservable(),
        ]).pipe(
            tap(([userId, sessionId, , isRecording]) => {
                if (!!this.sessionId && !sessionId && isRecording) {
                    // navigated out of session. stop recording
                    this.pause();
                    this.snackbarService.showInfoSnackBar(
                        `Recording Paused`,
                        `You have navigated out of the session. The recording has been paused.`,
                        true,
                    );
                }
                this.sessionId = sessionId;
                this.userId = userId;
            }),
            tap(([, , activityId]) => {
                if (this.recorder?.state === RecorderStates.Recording) {
                    const start = moment.utc(this.timer * 1000).format('HH:mm:ss,SSS');
                    const end = moment.utc(this.timer + 1 * 1000).format('HH:mm:ss,SSS');

                    this.actionEvents.push({
                        start,
                        end,
                        ...(activityId && { activityId }),
                    });
                }
            }),
            distinctUntilChanged(),
            switchMap(() =>
                combineLatest([this.getPendingConsents(), this.getRecordings()]).pipe(
                    tap(() => {
                        this.isLoading$.next(false);
                    }),
                ),
            ),
        );

        return combineLatest([
            preload$,
            this.store.select(selectSessionTeamMemberData),
            this.isLoading$.asObservable().pipe(distinctUntilChanged()),
            this.isPanelOpened$.asObservable().pipe(distinctUntilChanged()),
            this.isRecording$.asObservable().pipe(distinctUntilChanged()),
            this.isPaused$.asObservable().pipe(distinctUntilChanged()),
            this.isProcessing$.asObservable().pipe(distinctUntilChanged()),
            this.isUploading$.asObservable().pipe(distinctUntilChanged()),
            this.sessionRecorderStore.timer$,
            this.store.select(selectActivityIdAndParentIds),
        ]).pipe(
            map(
                ([
                    [consents, recordings],
                    teamMember,
                    isLoading,
                    isPanelOpened,
                    isRecording,
                    isPaused,
                    isProcessing,
                    isUploading,
                    timer,
                    { isActivityEdit },
                ]) => ({
                    consents,
                    recordings,
                    isLoading,
                    isPanelOpened,
                    isRecording,
                    isPaused,
                    isProcessing,
                    isUploading,
                    isAvailable: !!this.sessionId && teamMember.isSessionLeader && !isActivityEdit,
                    sessionId: this.sessionId,
                    timer,
                }),
            ),
            startWith(initialState),
        );
    }

    private getPendingConsents(): Observable<SessionRecordingConsent[]> {
        return !!this.sessionId ? this.sessionRecordingsService.getConsentsByUser(this.sessionId, this.userId) : of([]);
    }

    private getRecordings(): Observable<SessionRecording[]> {
        return !!this.sessionId
            ? this.sessionRecordingsService
                  .getRecordings(this.sessionId)
                  .pipe(map((recordings) => recordings.filter((recording) => !!recording.transcription)))
            : of([]);
    }
}
