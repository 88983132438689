import { Attribute } from './attribute';

export class AttributeClass {
    id?: string;
    name: string;
    sequence: string;
    attributes?: Attribute[];

    constructor(data: Partial<AttributeClass>) {
        this.id = data.id ?? '';
        this.name = data.name ?? '';
        this.sequence = data.sequence ?? '';
        this.attributes = data.attributes ? [...data.attributes] : [];
    }
}
