export enum SessionStatus {
    Draft = 'DRAFT',
    Active = 'ACTIVE',
}

export const sessionStatusToDisplay = {
    [SessionStatus.Draft]: 'Draft',
    [SessionStatus.Active]: 'Active',
};

export const StatusMatTooltip = {
    [SessionStatus.Active]: 'In “Active” status Leaders and Participants can collaborate and give their Responses.',
    [SessionStatus.Draft]: 'In “Draft” status Session Leader can create and edit Activities.',
};

export const scssClassSessionStatus = {
    [SessionStatus.Draft]: 'draft',
    [SessionStatus.Active]: 'active',
};
