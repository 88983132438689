import { Action, createReducer, on } from '@ngrx/store';

import { Collection, CollectionsDisplayMode, CollectionSession, UserCollection } from '@accenture/shared/data';

import {
    clearCollectionStore,
    clearCollectionStoreSubscription,
    getCollectionAndSessionsSuccess,
    getCollectionSuccess,
    getUserCollectionSuccess,
    setCollectionsDisplayMode,
} from './collection.actions';

export const COLLECTION_FEATURE_KEY = 'collection';

export interface CollectionState {
    collection: Collection;
    userCollection: UserCollection;
    sessions: CollectionSession[];
    displayMode: CollectionsDisplayMode;
}

const initialState: CollectionState = {
    collection: {} as Collection,
    userCollection: {} as UserCollection,
    sessions: [],
    displayMode: CollectionsDisplayMode.IconsView,
};

const _collectionReducer = createReducer(
    initialState,

    on(getCollectionAndSessionsSuccess, (state, { collection, sessions }) => ({
        ...state,
        collection,
        sessions,
    })),

    on(getCollectionSuccess, (state, { collection }) => ({
        ...state,
        collection,
    })),

    on(getUserCollectionSuccess, (state, { userCollection }) => ({
        ...state,
        userCollection,
    })),

    on(clearCollectionStore, (state) => ({
        ...initialState,
        displayMode: state.displayMode,
    })),

    on(clearCollectionStoreSubscription, (state) => ({
        ...state,
    })),

    on(setCollectionsDisplayMode, (state, { displayMode }) => ({
        ...state,
        displayMode,
    })),
);

export function collectionReducer(state: any, action: Action) {
    return _collectionReducer(state, action);
}
