import { Dictionary } from '../dictionary';
import { NodeTemplate, NodeType } from '../node-template';
import { FileType } from '../responses';
import { SortOptions } from '../sort-options';
import { SortOrders } from '../sort-orders';
import { UpdatedByUser } from '../user';
import { Activity, ActivityType } from './';

export class Present extends NodeTemplate implements Activity {
    type = ActivityType.Present;

    presentationFile: FileType;
    presentationDownloadFile: FileType;
    sessionId?: string;
    projectId?: string;
    templateId?: string;
    visible?: boolean;
    sequence: string;
    sectionId?: string;

    displayNotes?: boolean;
    allowDownloads?: boolean;
    enableResponses?: boolean;
    displaySlides?: boolean;
    responseSortOrder?: SortOrders;
    responseSortOption?: SortOptions;
    isResponseOnSlideMode?: boolean;
    isExpandResponses?: boolean;
    creatorId?: string;
    creatorName?: string;
    creatorImage?: string;
    favoritesCount?: number;
    tags?: Dictionary<string>;

    // for public activity templates
    timesUsed?: number;
    creatorNotes?: string;
    updatedBy?: UpdatedByUser;

    dndDisabled?: boolean;

    constructor(id: string | undefined, present: Partial<Present>) {
        super(id, present, NodeType.Activity);

        this.presentationFile = {
            id: present.presentationFile?.id ?? '',
            url: present.presentationFile?.url ?? '',
            name: present.presentationFile?.name ?? '',
        } as FileType;
        this.presentationDownloadFile = {
            id: present.presentationDownloadFile?.id ?? '',
            url: present.presentationDownloadFile?.url ?? '',
            name: present.presentationDownloadFile?.name ?? '',
        } as FileType;
        this.sessionId = present.sessionId ?? '';
        this.projectId = present.projectId ?? '';
        this.templateId = present.templateId ?? '';
        this.visible = present.visible ?? false;
        this.sequence = present.sequence ?? '';
        this.sectionId = present.sectionId;
        this.responseSortOrder = present.responseSortOrder || SortOrders.Asc;
        this.responseSortOption = present.responseSortOption || SortOptions.Created;

        this.displayNotes = present.displayNotes ?? false;
        this.displaySlides = present.displaySlides ?? false;
        this.allowDownloads = present.allowDownloads ?? false;
        this.enableResponses = present.enableResponses ?? false;
        this.isResponseOnSlideMode = present.isResponseOnSlideMode ?? false;
        this.isExpandResponses = present.isExpandResponses ?? false;
        this.creatorId = present.creatorId ?? '';
        this.creatorImage = present.creatorImage ?? '';
        this.creatorName = present.creatorName ?? '';
        this.favoritesCount = present.favoritesCount ?? 0;
        this.tags = (present.tags as Dictionary<string>) ?? {};

        this.creatorNotes = present.creatorNotes ?? '';
        this.timesUsed = present.timesUsed ?? 0;
        this.updatedBy = present.updatedBy ?? undefined;

        this.dndDisabled = !!present.dndDisabled;
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            presentationFile: this.presentationFile,
            presentationDownloadFile: this.presentationDownloadFile,
            projectId: this.projectId,
            sessionId: this.sessionId,
            sectionId: this.sectionId,
            templateId: this.templateId,
            visible: this.visible,
            sequence: this.sequence,
            responseSortOrder: this.responseSortOrder,
            responseSortOption: this.responseSortOption,
            displayNotes: this.displayNotes,
            displaySlides: this.displaySlides,
            allowDownloads: this.allowDownloads,
            enableResponses: this.enableResponses,
            isResponseOnSlideMode: this.isResponseOnSlideMode,
            isExpandResponses: this.isExpandResponses,
            type: this.type,
            created: this.created,
            updated: this.updated,
            creatorId: this.creatorId,
            creatorImage: this.creatorImage,
            creatorName: this.creatorName,
            creatorNotes: this.creatorNotes,
            timesUsed: this.timesUsed,
            tags: this.tags,
        };
    }
}
