import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable, tap } from 'rxjs';

import { validationMessages } from '@accenture/activity/shared/domain';
import { trackById } from '@accenture/shared/util';

import { LinkAccessFacade, LinkAccessModel } from './link-access-facade';

@Component({
    selector: 'accenture-link-access',
    templateUrl: './link-access.component.html',
    styleUrls: ['./link-access.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LinkAccessFacade],
})
export class LinkAccessComponent {
    vm$: Observable<LinkAccessModel> = this.facade.vm$.pipe(
        tap(({ originalPassword }) => {
            this.passwordControl.patchValue(originalPassword);
        }),
    );

    trackById = trackById;
    validationMessages = validationMessages;
    passwordControl = new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(100)]);

    constructor(private facade: LinkAccessFacade) {}

    toggleLinkAccess(linkAccessEnabled: boolean): void {
        this.facade.toggleLinkAccess(linkAccessEnabled);
        if (!linkAccessEnabled) {
            this.passwordControl.reset();
        }
    }

    updatePassword(): void {
        if (this.passwordControl.invalid && this.passwordControl.value) {
            return;
        }
        this.facade.updatePassword(this.passwordControl.value);
    }

    togglePasswordVisibility(): void {
        this.facade.togglePasswordVisibility();
    }

    copyInvite(): void {
        this.facade.copyInviteToClipboard();
    }
}
