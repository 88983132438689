import { Activity, ActivityItem, Project, Session } from '@accenture/shared/data';

export interface ProjectBluePrint extends Partial<Project> {
    client: string;
    sessionInstructions: string;
    activityInstructions: string;
    sessions: Partial<SessionBluePrint>[];
}

export interface SessionBluePrint extends Partial<Session> {
    instructions: string;
    activities: Partial<ActivityBluePrint>[];
}

export interface ActivityBluePrint extends Partial<Activity> {
    instructions: string;
    topics: Partial<ActivityItemBluePrint>[];
    poll: Partial<ActivityItemBluePrint>;
    items: Partial<ActivityItemBluePrint>[];
    parameters: Partial<ActivityItemBluePrint>[];
    tables: Partial<ActivityItemBluePrint>[];
}

export interface ActivityItemBluePrint extends Partial<ActivityItem> {
    name: string;
    question?: string;
    choices?: {
        name?: string;
    }[];
    columns: {
        name?: string;
        type?: string;
        sequence?: string;
    }[];
}
