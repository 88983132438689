import { Injectable } from '@angular/core';
import { Timestamp } from 'firebase/firestore';
import { map, Observable } from 'rxjs';

import { Activity, DBPathHelper, ParentType, Project } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import { sortByDateDesc, sortBySequenceAsc } from '@accenture/shared/util';

@Injectable({
    providedIn: 'root',
})
export class ProjectTemplateService {
    constructor(private firestoreService: FirestoreService) {}

    getProjectTemplatePath(projectTemplateId?: string): string {
        return `${ParentType.ProjectTemplates}` + (projectTemplateId ? `/${projectTemplateId}` : '');
    }

    getProjects(): Observable<Project[]> {
        return this.firestoreService
            .getCollection(this.getProjectTemplatePath())
            .pipe(map((projects: Project[]) => projects.sort(sortByDateDesc('updated'))));
    }

    getProjectActivities(projectId: string): Observable<Activity[]> {
        return this.firestoreService
            .getCollection(`${this.getProjectTemplatePath(projectId)}/activities`)
            .pipe(map((activities: Activity[]) => sortBySequenceAsc(activities)));
    }

    getProjectById(projectId: string): Observable<Project> {
        return this.firestoreService.getDocument<Project>(this.getProjectTemplatePath(projectId));
    }

    async markForDelete(parentType: ParentType, templateId: string): Promise<void> {
        const data: Partial<Project> = {
            markedForDelete: Timestamp.now(),
        };

        await this.updateWithMerge(parentType, templateId, data);
    }

    async addLikeToProjectTemplate(userAccessId: string, projectId: string, userId: string): Promise<void> {
        const data = {
            likes: {
                [userId]: true,
            },
        };
        await this.firestoreService.update(`/userAccess/${userAccessId}`, data);
        await this.firestoreService.update(`/projectTemplates/${projectId}`, data);
    }

    async removeLikeFromProjectTemplate(userAccessId: string, projectId: string, userId: string): Promise<void> {
        const data = {
            likes: {
                [userId]: this.firestoreService.deleteField,
            },
        };
        await this.firestoreService.update(`/userAccess/${userAccessId}`, data);
        await this.firestoreService.update(`/projectTemplates/${projectId}`, data);
    }

    async deleteProject(projectId: string): Promise<void> {
        await this.firestoreService.cloudFunctionCallable('deleteProject', {
            projectId,
            parentType: ParentType.ProjectTemplates,
        });
    }

    async updateWithMerge(parentType: ParentType, templateId: string, data: Partial<Project>): Promise<void> {
        await this.firestoreService.update(DBPathHelper.getParentPath(parentType, templateId), {
            ...this.firestoreService.replaceEmptyFields(data),
            updated: this.firestoreService.timestamp,
        });
    }
}
