import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Timestamp } from 'firebase/firestore';
import { Observable, tap } from 'rxjs';

import { noClientSelected } from '@accenture/erp-deployment/shared/domain';
import {
    dashboardSortOptionsValues,
    DefaultFilterObject,
    Dictionary,
    emptyScreenDescriptions,
    emptyScreenImage,
    emptyScreenTitles,
    inputPlaceholders,
    NavigationTab,
    ParentType,
    ProjectRole,
    SortOptions,
    SortOrders,
    sortOrdersValues,
    tooltipTexts,
    User,
    UserAccess,
} from '@accenture/shared/data';
import { IconSize, ImageInputPlaceholdersTypes, imageInputPlaceholdersUrl } from '@accenture/shared/ui';
import { isDisabledTooltip, preventKeyValueOrder, trackById, trackByKey } from '@accenture/shared/util';

import { ProjectsListFacade, ProjectsListViewModel, ProjectsViewTab } from './projects-list-facade';

type SortForm = FormGroup<{
    sortOption: FormControl<SortOptions>;
    sortOrder: FormControl<SortOrders>;
}>;

@Component({
    selector: 'accenture-projects-list',
    templateUrl: './projects-list.component.html',
    styleUrls: ['./projects-list.component.scss'],
    providers: [ProjectsListFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectsListComponent implements OnInit {
    vm$: Observable<ProjectsListViewModel> = this.facade.vm$.pipe(
        tap(({ searchValue, filtersAndSort }) => this.updateFormValues(filtersAndSort, searchValue)),
    );

    protected readonly isDisabledTooltip = isDisabledTooltip;

    sortMenuOpen!: boolean;

    trackById = trackById;
    trackByKey = trackByKey;
    emptyScreenTitles = emptyScreenTitles;
    emptyScreenDescriptions = emptyScreenDescriptions;
    parentType = ParentType;
    ProjectsViewTab = ProjectsViewTab;
    emptyScreenImage = emptyScreenImage;
    inputPlaceholders = inputPlaceholders;
    tooltipTexts = tooltipTexts;
    iconSize = IconSize;
    sortForm: SortForm;
    sortOptionsValues = dashboardSortOptionsValues;
    sortOrdersValues = sortOrdersValues;
    noClientSelected = noClientSelected;
    imageInputPlaceholdersUrl = imageInputPlaceholdersUrl[ImageInputPlaceholdersTypes.Project];
    preventKeyValueOrder = preventKeyValueOrder;

    searchControl = new FormControl<string>('');

    constructor(private facade: ProjectsListFacade, private formBuilder: FormBuilder) {}

    isProjectAdmin(project: UserAccess): boolean {
        return project.role === ProjectRole.Admin;
    }

    canCreateProject(user: User): boolean {
        return user.roles?.projectCreator || user.roles?.admin;
    }

    getImageUrl(imageUrl?: string): string {
        return imageUrl ? `url(${imageUrl})` : '';
    }

    filterProjects(searchValue: string): void {
        this.facade.filterProjects(searchValue);
    }

    clearFilter(event: Event): void {
        event.stopPropagation();
        this.filterProjects('');
    }

    toggleFilterAndSortPanel(opened: boolean, event?: Event): void {
        this.facade.toggleFilterAndSortPanel(opened);

        if (event) {
            event.stopPropagation();
        }
    }

    toggleShowSearch(): void {
        this.facade.toggleShowSearch();
    }

    setActiveTab(tab: ProjectsViewTab): void {
        this.facade.setActiveTab(tab);
    }

    navigateToProjectsPage(): void {
        this.facade.navigateHome(NavigationTab.Projects);
    }

    getProjectDate(project: UserAccess): Date {
        const updated = (project.updated || project.created) as Timestamp;
        return updated?.toDate ? updated.toDate() : new Date();
    }

    getClientName(client: Dictionary<string>): string {
        const [clientId] = Object.keys(client || {});
        return client?.[clientId] ?? noClientSelected;
    }

    changeSortOption(userId: string, fieldName: string, value): void {
        this.facade.applyFiltersAndSort(userId, { [fieldName]: value });
    }

    isSortOptionSelected(filtersAndSort: DefaultFilterObject, fieldName: string, option: string): boolean {
        if (filtersAndSort[fieldName]) {
            return filtersAndSort[fieldName] === option;
        }

        return this.sortForm.value[fieldName].value === option;
    }

    initializeFiltersAndSortForm(): void {
        this.sortForm = this.formBuilder.group({
            sortOption: new FormControl(SortOptions.LastViewed, { nonNullable: true }),
            sortOrder: new FormControl(SortOrders.Asc, { nonNullable: true }),
        });
    }

    openCreateProjectDialog(): void {
        this.facade.openCreateProjectDialog();
    }

    ngOnInit(): void {
        this.initializeFiltersAndSortForm();
    }

    private updateFormValues(filtersAndSort: DefaultFilterObject, searchValue: string): void {
        this.sortForm.patchValue(filtersAndSort);
        this.searchControl.patchValue(searchValue);
    }
}
