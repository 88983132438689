<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading">
    <accenture-base-dialog [title]="data.title">
      <div class="dialog-member-invitations">
        <accenture-invitation-form-bulk-invite
          (viewModelChange)="setInvitationObject($event)"
        ></accenture-invitation-form-bulk-invite>
      </div>

      <div class="accenture-dialog-actions">
        <button class="tt9-btn" [disabled]="vm.isInviteButtonDisabled" (click)="invite()">Invite</button>
      </div>

      <accenture-loading *ngIf="vm.isUIBlocked"></accenture-loading>
    </accenture-base-dialog>
  </ng-container>
</ng-container>
