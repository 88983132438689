import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { AppState, selectFeatureToggle } from '@accenture/global-store';

import { FeatureToggleName } from '../constants';

@Injectable()
export class FeatureToggleGuard {
    constructor(private store: Store<AppState>) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const { featureType } = route.data;

        if (featureType) {
            return this.checkFeatureToggle(featureType);
        }

        return of(true);
    }

    private checkFeatureToggle(featureType: FeatureToggleName): Observable<boolean> {
        return this.store.select(selectFeatureToggle(featureType));
    }
}
