import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { marked } from 'marked';
import { Observable } from 'rxjs';

import { characterLimitText, characterLimitXL, determineHintClass, validationMessages } from '@accenture/shared/data';
import { SharedMaterialModule } from '@accenture/shared/material';
import { SharedUiModule } from '@accenture/shared/ui';

import { SessionRecorderSummarizeFacade, SessionRecorderSummarizeViewModel } from './session-recorder-summarize-facade';

interface SessionTranscriptionModel {
    title: string;
    sessionId: string;
}

@Component({
    selector: 'accenture-session-recorder-summarize',
    standalone: true,
    templateUrl: './session-recorder-summarize.component.html',
    styleUrls: ['./session-recorder-summarize.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SessionRecorderSummarizeFacade],
    imports: [CommonModule, SharedMaterialModule, SharedUiModule],
})
export class SessionRecorderSummarizeComponent {
    vm$: Observable<SessionRecorderSummarizeViewModel> = this.facade.vm$;

    constructor(
        @Inject(MAT_DIALOG_DATA) public sessionTranscription: SessionTranscriptionModel,
        private facade: SessionRecorderSummarizeFacade,
        private sanitizer: DomSanitizer,
    ) {}

    loaderText!: string;
    title = this.sessionTranscription.title;
    characterLimitLarge = characterLimitXL;
    validationMessages = validationMessages;
    characterLimitText = characterLimitText;
    determineHintClass = determineHintClass;
    placeholderPromptText = '(OPTIONAL) Additional Prompt';

    summary!: string;

    async summarize(event: Event, prompt: string): Promise<void> {
        event.stopPropagation();
        this.loaderText = 'Summarizing...';
        this.summary = await this.facade.summarize(prompt);
    }

    async download(event: Event): Promise<void> {
        event.stopPropagation();
        this.loaderText = 'Preparing PDF...';
        await this.facade.download(this.summary);
        this.close();
    }

    close(): void {
        this.facade.close();
    }

    rendered(): string {
        return this.sanitizer.bypassSecurityTrustHtml(marked(this.summary)) as string;
    }
}
