import { FieldValue, Timestamp } from 'firebase/firestore';

import { AccessControlRole, SessionRole } from './access-control';
import { ActivityType } from './activities';
import { Dictionary } from './dictionary';
import { NodeTimestamp } from './node-timestamp';
import { ParentType } from './parent-type';
import { TemplateStatus } from './template';

export interface UserAccessSession {
    id?: string;
    name: string;
    description: string;
    imageUrl: string;
    sequence: string;
    role: SessionRole;
    creatorId?: string;
    tags?: Dictionary<string>;
    phase?: Dictionary<string>;
    subPhase?: Dictionary<string>;
    metadata?: Dictionary<string>;
}

export interface UserAccess extends NodeTimestamp {
    id?: string;
    userId?: string;
    templateId?: string;
    templateType?: ParentType;
    projectId?: string;
    sessionId?: string;
    imageUrl?: string;
    name?: string;
    description?: string;
    role: AccessControlRole;
    sessions?: Dictionary<UserAccessSession>;
    tags?: Dictionary<string>;
    clients?: Dictionary<string>;
    phases?: Dictionary<string>;
    subPhases?: Dictionary<string>;
    likes?: Dictionary<boolean>;
    hidden?: boolean;
    activityType?: ActivityType;
    metadata?: Dictionary<string>;
    status?: TemplateStatus;
    creatorId?: string;
    creatorImage?: string;
    creatorName?: string;
    ownerDisplayName?: string;
    ownerImageUrl?: string;
    lastViewed?: Timestamp | FieldValue;
    ownerId?: string;
    sequence?: string;
    collectionId?: string;
    color?: string;

    // temporary for release 1
    // TODO: remove
    public?: boolean;
}
