export const newAttributeGroupText = {
    title: 'Add attribute group',
    name: 'New attribute group',
};

export const editAttributeGroupText = {
    name: 'Edit attribute group',
};

export const deleteAttributeGroup = {
    title: 'Delete attribute group',
    text: 'Are you sure you want to delete this attribute group? You will lose all the associated data.',
};

export const addGroup = 'Add New Group';
