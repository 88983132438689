import { Injectable } from '@angular/core';
import { SHA256 } from 'crypto-js';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    Activity,
    DBPathHelper,
    errorMessageSnackbarText,
    inviteLinkActivitySuccess,
    inviteLinkActivityTitle,
    LinkAccess,
    LinkAccessAndPassword,
    LinkAccessPassword,
    sessionTemplateLinkCopiedToClipBoardSuccessText,
    templateCopyToClipboardErrorTitle,
} from '@accenture/shared/data';
import {
    copyLinkAccessToClipboardText,
    TemplateAccessType,
    templateErrorMessageCopyToClipboard,
    templateLinkCopiedToClipBoardSuccessText,
    templateLinkTitle,
    templateTitleCopyToClipboardError,
} from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { QrCodeGenerator, SnackbarService } from '@accenture/shared/ui';
import { formatToHTMLInClipboard } from '@accenture/shared/util';

@Injectable({
    providedIn: 'root',
})
export class LinkAccessService {
    constructor(private firestoreService: FirestoreService, private snackbarService: SnackbarService) {}

    getLinkAccess(linkAccessId: string): Observable<LinkAccess | null> {
        return this.firestoreService.getDocument<LinkAccess | null>(`linkAccess/${linkAccessId}`);
    }

    //Only retrieves link access designated for session
    getLinkAccessBySession(sessionId: string): Observable<LinkAccess | undefined> {
        return this.firestoreService
            .getDocumentsByProperty<LinkAccess | undefined>('linkAccess', 'sessionId', sessionId, 'updated')
            .pipe(map((accesses: LinkAccess[]) => accesses.filter((item) => !item.activityId)[0]));
    }

    getLinkAccessByActivity(activityId: string): Observable<LinkAccess | undefined> {
        return this.firestoreService
            .getDocumentsByProperty<LinkAccess | undefined>('linkAccess', 'activityId', activityId, 'updated')
            .pipe(map((accesses: LinkAccess[]) => accesses[0]));
    }

    getLinkAccessByTemplate(templateId: string): Observable<LinkAccess | undefined> {
        return this.firestoreService
            .getDocumentsByPropertyWithoutCaching<LinkAccess | undefined>('linkAccess', 'templateId', templateId)
            .pipe(map((accesses: LinkAccess[]) => accesses[0]));
    }

    // TODO: Delete after project deprecation
    getLinkAccessOriginalPassword(projectId: string, linkAccessId: string): Observable<string | undefined> {
        return this.firestoreService
            .getDocument<LinkAccessPassword | undefined>(`projects/${projectId}/linkAccessPasswords/${linkAccessId}`)
            .pipe(map((data) => data?.password));
    }

    getLinkAccessOriginalPasswordNew(sessionId: string, linkAccessId: string): Observable<string | undefined> {
        return this.firestoreService
            .getDocument<LinkAccessPassword | undefined>(
                DBPathHelper.getLinkAccessPasswordPathNew(sessionId, linkAccessId),
            )
            .pipe(map((data) => data?.password));
    }

    getLinkAccessOriginalPasswordForPublicAccess(
        publicAccessId: string,
        linkAccessId: string,
    ): Observable<string | undefined> {
        return this.firestoreService
            .getDocument<LinkAccessPassword | undefined>(
                `${DBPathHelper.getPublicAccessPath(publicAccessId)}/linkAccessPasswords/${linkAccessId}`,
            )
            .pipe(map((data) => data?.password));
    }

    async addLinkAccess(linkAccessData: Partial<LinkAccess>): Promise<string> {
        const linkAccess = {
            ...linkAccessData,
            created: this.firestoreService.timestamp,
            updated: this.firestoreService.timestamp,
        };
        return await this.firestoreService.addDocument('linkAccess', linkAccess);
    }

    async updateLinkAccessPassword(linkAccessId: string, password: string): Promise<void> {
        const linkAccess = {
            password: SHA256(password).toString(),
            updated: this.firestoreService.timestamp,
        };
        await this.firestoreService.update(`linkAccess/${linkAccessId}`, linkAccess);
    }

    async updateLinkAccessTemplateData(linkAccessId: string, data: any): Promise<void> {
        const linkAccess = {
            ...data,
            updated: this.firestoreService.timestamp,
        };
        await this.firestoreService.update(`linkAccess/${linkAccessId}`, linkAccess);
    }

    async updateLinkAccessActivityVisibility(linkAccessId: string, activityVisible: boolean): Promise<void> {
        const linkAccess = {
            activityVisible: activityVisible,
            updated: this.firestoreService.timestamp,
        };
        await this.firestoreService.update(`linkAccess/${linkAccessId}`, linkAccess);
    }

    async deleteLinkAccess(linkAccessId: string): Promise<void> {
        await this.firestoreService.delete(`linkAccess/${linkAccessId}`);
    }

    async updateLinkAccessProjectName(projectId: string, projectName: string): Promise<void> {
        const linkAccessesToUpdate = await firstValueFrom(
            this.firestoreService.getDocumentsByProperty<LinkAccess>('linkAccess', 'projectId', projectId, 'updated'),
        );
        const batchData = linkAccessesToUpdate.map(({ id }) => ({
            path: `linkAccess/${id}`,
            data: { projectName },
        }));
        await this.firestoreService.writeBatch(batchData);
    }

    async updateLinkAccessSessionName(sessionId: string, sessionName: string): Promise<void> {
        const linkAccessesToUpdate = await firstValueFrom(this.getLinkAccessBySession(sessionId));
        if (!linkAccessesToUpdate) {
            return;
        }
        if (sessionName && sessionName !== linkAccessesToUpdate.sessionName) {
            const linkAccess = {
                sessionName,
                updated: this.firestoreService.timestamp,
            };
            await this.firestoreService.update(`linkAccess/${linkAccessesToUpdate.id}`, linkAccess);
        }
    }

    async updateLinkAccessActivityName(activityId: string, activityName: string): Promise<void> {
        const linkAccessesToUpdate = await firstValueFrom(this.getLinkAccessByActivity(activityId));

        if (!linkAccessesToUpdate) {
            return;
        }
        if (activityId && activityName !== linkAccessesToUpdate.activityName) {
            const linkAccess = {
                activityName,
                updated: this.firestoreService.timestamp,
            };
            await this.firestoreService.update(`linkAccess/${linkAccessesToUpdate.id}`, linkAccess);
        }
    }

    // TODO: Delete after project deprecation
    async updateLinkAccessOriginalPassword(projectId: string, linkAccessId: string, password: string): Promise<void> {
        const data: LinkAccessPassword = {
            password,
            created: this.firestoreService.timestamp,
            updated: this.firestoreService.timestamp,
        };
        await this.firestoreService.update(`projects/${projectId}/linkAccessPasswords/${linkAccessId}`, data);
    }

    async updateLinkAccessOriginalPasswordNew(
        sessionId: string,
        linkAccessId: string,
        password: string,
    ): Promise<void> {
        const data: LinkAccessPassword = {
            password,
            created: this.firestoreService.timestamp,
            updated: this.firestoreService.timestamp,
        };
        await this.firestoreService.update(DBPathHelper.getLinkAccessPasswordPathNew(sessionId, linkAccessId), data);
    }

    async addLinkAccessOriginalPasswordForPublicAccess(
        publicAccessId: string,
        linkAccessId: string,
        password: string,
    ): Promise<void> {
        const data: LinkAccessPassword = {
            password,
            created: this.firestoreService.timestamp,
            updated: this.firestoreService.timestamp,
        };
        await this.firestoreService.addDocumentWithKey(
            `${DBPathHelper.getPublicAccessPath(publicAccessId)}/linkAccessPasswords`,
            linkAccessId,
            data,
        );
    }

    async deleteLinkAccessOriginalPassword(projectId: string, linkAccessId: string): Promise<void> {
        await this.firestoreService.delete(`projects/${projectId}/linkAccessPasswords/${linkAccessId}`);
    }

    async deleteLinkAccessOriginalPasswordNew(sessionId: string, linkAccessId: string): Promise<void> {
        await this.firestoreService.delete(DBPathHelper.getLinkAccessPasswordPathNew(sessionId, linkAccessId));
    }

    // TODO: delete when project is no longer used
    copyProjectTemplateLinkAccessToClipboard(template: TemplateAccessType): void {
        try {
            const name = `${copyLinkAccessToClipboardText} ${template.name} project template.`;

            const container = new ClipboardItem({
                'text/html': this.getLinkAccessAndPassword(template.templateId, true).then((response) => {
                    const container = formatToHTMLInClipboard(
                        name,
                        undefined,
                        response.path,
                        `Password: ${response.linkAccessPassword || ''}`,
                    );
                    return new Blob([container.outerHTML], { type: 'text/html' });
                }),
                'text/plain': this.getLinkAccessAndPassword(template.templateId, true).then((response) => {
                    const containerText = `${name}\nLink: ${response.path}\nPassword: ${
                        response.linkAccessPassword || ''
                    }`;
                    return new Blob([containerText], { type: 'text/plain' });
                }),
            });
            navigator.clipboard.write([container]);

            this.snackbarService.showSuccessSnackBar(templateLinkTitle, templateLinkCopiedToClipBoardSuccessText, true);
        } catch (e) {
            console.error(e.message);

            this.snackbarService.showErrorSnackBar(
                templateTitleCopyToClipboardError,
                templateErrorMessageCopyToClipboard,
            );
        }
    }

    copySessionTemplateLinkAccessToClipboard(template: TemplateAccessType): void {
        try {
            const name = `${copyLinkAccessToClipboardText} ${template.name} session template.`;

            const container = new ClipboardItem({
                'text/html': this.getLinkAccessAndPasswordNew(template.templateId, true).then((response) => {
                    const container = formatToHTMLInClipboard(
                        name,
                        response.qrCodeImageUrl,
                        response.path,
                        `Password: ${response.linkAccessPassword || ''}`,
                    );
                    return new Blob([container.outerHTML], { type: 'text/html' });
                }),
                'text/plain': this.getLinkAccessAndPasswordNew(template.templateId, true).then((response) => {
                    const containerText = `${name}\nLink: ${response.path}\nPassword: ${
                        response.linkAccessPassword || ''
                    }`;
                    return new Blob([containerText], { type: 'text/plain' });
                }),
            });
            navigator.clipboard.write([container]);

            this.snackbarService.showSuccessSnackBar(
                templateLinkTitle,
                sessionTemplateLinkCopiedToClipBoardSuccessText,
                true,
            );
        } catch (e) {
            console.error(e.message);

            this.snackbarService.showErrorSnackBar(
                templateTitleCopyToClipboardError,
                templateErrorMessageCopyToClipboard,
            );
        }
    }

    // TODO: Delete after project deprecation
    async getLinkAccessAndPassword(id: string, isProjectTemplate: boolean): Promise<LinkAccessAndPassword> {
        const currentLinkAccess = isProjectTemplate
            ? await firstValueFrom(this.getLinkAccessByTemplate(id))
            : await firstValueFrom(this.getLinkAccessByActivity(id));
        const linkAccessId = currentLinkAccess.id;
        const linkAccessPassword = isProjectTemplate
            ? await firstValueFrom(
                  this.getLinkAccessOriginalPasswordForPublicAccess(currentLinkAccess.publicAccessId, linkAccessId),
              )
            : await firstValueFrom(this.getLinkAccessOriginalPassword(currentLinkAccess.projectId, linkAccessId));
        const path = isProjectTemplate
            ? `${window.location.protocol}//${window.location.host}/authentication/create-project/${linkAccessId}`
            : `${window.location.protocol}//${window.location.host}/authentication/join-activity/${linkAccessId}`;
        const qrCodeImageUrl = await QrCodeGenerator.generateQRCodeImage(path);
        return { linkAccessId, linkAccessPassword, path, qrCodeImageUrl };
    }

    async getLinkAccessAndPasswordNew(id: string, isSessionTemplate: boolean): Promise<LinkAccessAndPassword> {
        const currentLinkAccess = isSessionTemplate
            ? await firstValueFrom(this.getLinkAccessByTemplate(id))
            : await firstValueFrom(this.getLinkAccessByActivity(id));
        const linkAccessId = currentLinkAccess.id;
        const linkAccessPassword = isSessionTemplate
            ? await firstValueFrom(
                  this.getLinkAccessOriginalPasswordForPublicAccess(currentLinkAccess.publicAccessId, linkAccessId),
              )
            : await firstValueFrom(this.getLinkAccessOriginalPasswordNew(currentLinkAccess.sessionId, linkAccessId));
        const path = isSessionTemplate
            ? `${window.location.protocol}//${window.location.host}/authentication/create-session/${linkAccessId}`
            : `${window.location.protocol}//${window.location.host}/authentication/join-activity/${linkAccessId}`;
        const qrCodeImageUrl = await QrCodeGenerator.generateQRCodeImage(path);
        return { linkAccessId, linkAccessPassword, path, qrCodeImageUrl };
    }

    copyActivityLinkAccessToClipboard(activity: Activity, session: string): void {
        const sessionName = `Session: ${session}`;
        const activityName = `Activity: ${activity.name}`;
        const linkContainer = new ClipboardItem({
            'text/html': this.getLinkAccessAndPasswordNew(activity.id, false)
                .then((response) => {
                    const container = formatToHTMLInClipboard(
                        sessionName,
                        response.qrCodeImageUrl,
                        response.path,
                        `Password: ${response.linkAccessPassword || ''}`,
                        activityName,
                    );
                    return new Blob([container.outerHTML], { type: 'text/html' });
                })
                .catch((e) => {
                    console.error(e);
                    this.snackbarService.showErrorSnackBar(templateCopyToClipboardErrorTitle, errorMessageSnackbarText);
                    return Promise.reject();
                }),
            'text/plain': this.getLinkAccessAndPasswordNew(activity.id, false)
                .then((response) => {
                    const containerText = `${sessionName}\n${activityName}\nLink: ${response.path}\nPassword: ${
                        response.linkAccessPassword || ''
                    }`;
                    return new Blob([containerText], { type: 'text/plain' });
                })
                .catch((e) => {
                    console.error(e);
                    this.snackbarService.showErrorSnackBar(templateCopyToClipboardErrorTitle, errorMessageSnackbarText);
                    return Promise.reject();
                }),
        });

        navigator.clipboard.write([linkContainer]);
        this.snackbarService.showSuccessSnackBar(inviteLinkActivityTitle, inviteLinkActivitySuccess, true);
    }
}
