<ng-container *ngIf="vm$ | async as vm">
  <div class="templates-container">
    <div class="templates-content">
      <div class="templates-header">
        <div class="tt9-tabs d-flex-center-around">
          <div class="tt9-tabs-content d-flex">
            <ng-container *ngFor="let tab of templatesTabs; trackBy: trackByValue">
              <div
                *ngIf="isTabVisible(tab, vm.user.roles)"
                class="tt9-tabs-tab header-tab"
                [class.active]="tab === vm.activeTemplateTab"
                (click)="setTemplatesTab(tab)"
              >
                <p class="tt9-h3">
                  <span>{{ tab }}</span>
                  <span class="label tt9-color-neutral-500">{{
                    getTemplatesCount(vm.user, tab, vm.publicTemplatesLength, vm.favoriteTemplatesLength)
                  }}</span>
                </p>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <ng-container *accentureLoaded="!vm.isLoading">
        <div class="templates-body" [ngSwitch]="vm.activeTemplateTab">
          <ng-container *ngSwitchCase="templatesTab.MyTemplates">
            <div class="templates-list">
              <accenture-templates-list></accenture-templates-list>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="templatesTab.Favorites">
            <div class="templates-favorite">
              <accenture-favorite-templates></accenture-favorite-templates>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="templatesTab.TemplateStore">
            <div *ngIf="vm.user.roles.templateCreator || vm.user.roles.admin" class="templates-store">
              <accenture-templates-store></accenture-templates-store>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
