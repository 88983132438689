import { ProjectOptions } from '@accenture/shared/data';

export enum OptionsPanelTitle {
    Client = 'All clients',
    Phase = 'All phases',
    SubPhase = 'All sub-Phases',
    Tag = 'All tags',
}

export const optionPlaceholderText = {
    [ProjectOptions.Client]: 'Start typing client name',
    [ProjectOptions.Tag]: 'Start typing tag name',
};
