// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    name: 'dev2',
    production: false,
    encryptionKey: 'PyYuroNk2wK4688j8R9e7WUyRqYJAF8U',
    firebase: {
        cloudFunctionsURL: 'https://us-central1-thinktank-hi-q-dev.cloudfunctions.net',
        apiKey: 'AIzaSyC_mkQmc2bLk97ywCcknIRNx837DvNxXpg',
        authDomain: 'thinktank-hi-q-dev.firebaseapp.com',
        databaseURL: 'https://thinktank-hi-q-dev.firebaseio.com',
        projectId: 'thinktank-hi-q-dev',
        storageBucket: 'thinktank-hi-q-dev.appspot.com',
        messagingSenderId: '725636178278',
        appId: '1:725636178278:web:b59959a756c43f4e9228a7',
        measurementId: 'G-ERD2GXP6KF',
    },
    pendo: {
        APIKey: '74a70058-f5cd-4d2b-7ce2-a620e5a27033',
        sendData: false,
    },
    sso: {
        clientId: '7f69d819-baeb-4adb-bee3-02dfb7919756',
        authority: 'https://login.microsoftonline.com/e0793d39-0939-496d-b129-198edd916feb',
        redirectUri: 'https://thinktank-hi-q-dev.web.app/authentication/callback',
    },
    teams: {
        clientId: '67c15d54-df65-43be-bf30-756f7c37e494',
        clientSecret: 'zra8Q~Ji5NoMm5lGZEqxReG.yAZDWihn.acM0a92',
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
