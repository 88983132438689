import { NodeTimestamp } from '../node-timestamp';
import { ActivityItem } from './activity-item';
import { ActivityItemType } from './activity-item-type';

export class Prompt extends NodeTimestamp implements ActivityItem {
    id?: string;
    title?: string;
    sequence: string;
    type = ActivityItemType.Prompt;
    sessionId?: string;
    activityId?: string;
    default?: string;

    label?: any;
    description?: string;

    constructor(data: Partial<Prompt>) {
        super(data);
        this.id = data.id ?? '';
        this.title = data.title ?? '';
        this.sequence = data.sequence ?? '';
        this.sessionId = data.sessionId ?? '';
        this.activityId = data.activityId ?? '';
        this.default = data.default ?? '';
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            id: this.id,
            title: this.title,
            sequence: this.sequence,
            type: this.type,
            sessionId: this.sessionId,
            activityId: this.activityId,
            default: this.default,
            created: this.created,
            updated: this.updated,
        };
    }
}
