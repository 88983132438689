import { Dictionary } from '../dictionary';

export enum ActivityType {
    Brainstorm = 'BRAINSTORM',
    Present = 'PRESENT',
    Vote = 'VOTE',
    QuickPoll = 'POLL',
    Table = 'TABLE',
    LiveShare = 'LIVESHARE',
    Proompt = 'PROOMPT',
    Spark = 'SPARK',
    Whiteboard = 'WHITEBOARD',
}

export const activityTypesToDisplay = {
    [ActivityType.Vote]: 'Vote Activity',
    [ActivityType.Table]: 'Table Activity',
    [ActivityType.QuickPoll]: 'Quick Poll',
    [ActivityType.LiveShare]: 'LiveShare',
    [ActivityType.Brainstorm]: 'Brainstorm Activity',
    [ActivityType.Present]: 'Present Activity',
    [ActivityType.Proompt]: 'Transform Activity',
    [ActivityType.Spark]: 'Spark Activity',
    [ActivityType.Whiteboard]: 'Doodle Activity',
};

export const activityTypesWithAttributes = [ActivityType.Vote];

export const activityTypes = [
    ActivityType.Vote,
    ActivityType.Brainstorm,
    ActivityType.Present,
    ActivityType.QuickPoll,
    ActivityType.Table,
    ActivityType.LiveShare,
    ActivityType.Proompt,
    ActivityType.Spark,
    ActivityType.Whiteboard,
];

export const activityTypeImages: Dictionary<string> = {
    [ActivityType.Brainstorm]: '/assets/shared-ui/svg/activity-brainstorm.svg',
    [ActivityType.Present]: '/assets/shared-ui/svg/activity-present.svg',
    [ActivityType.Vote]: '/assets/shared-ui/svg/activity-vote.svg',
    [ActivityType.QuickPoll]: '/assets/shared-ui/svg/activity-quick-poll.svg',
    [ActivityType.LiveShare]: '/assets/shared-ui/svg/activity-live-share.svg',
    [ActivityType.Proompt]: '',
    [ActivityType.Spark]: '',
    [ActivityType.Whiteboard]: '',
    default: '/assets/shared-ui/svg/activity-others.svg',
};

export const activityTypeIcons: Dictionary<string> = {
    [ActivityType.Brainstorm]: 'icon-brainstorms-activity',
    [ActivityType.Present]: 'icon-present-activity',
    [ActivityType.Vote]: 'icon-vote-activity',
    [ActivityType.QuickPoll]: 'icon-quick-poll',
    [ActivityType.LiveShare]: 'icon-share',
    [ActivityType.Table]: 'icon-table-view',
    [ActivityType.Proompt]: '',
    [ActivityType.Spark]: '',
    [ActivityType.Whiteboard]: '',
    default: 'icon-form-activity',
};
