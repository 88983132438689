<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading; description: vm.loaderDescription">
    <accenture-base-dialog [title]="data?.title">
      <accenture-data-flow-form
        [sessions]="vm.sessions"
        [groupedActivityItems]="vm.activityItems"
        [activities]="vm.activities"
        [connectionData]="data?.connectionData"
        [connections]="vm.connections"
        [currentSession]="vm.currentSession"
        [groupedColumns]="vm.columns"
        (dataSave)="saveData($event)"
      ></accenture-data-flow-form>
    </accenture-base-dialog>
  </ng-container>
</ng-container>
