import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    DBPathHelper,
    OptionsFiltersFields,
    ParentType,
    SelectedSessionOptions,
    SessionOptions,
    sessionOptionsArray,
    SessionOptionsType,
} from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable({
    providedIn: 'root',
})
export class SessionOptionsService {
    constructor(private firestoreService: FirestoreService) {}

    getNewOptionId(): string {
        return this.firestoreService.getPushId();
    }

    getOptionPath(type: SessionOptions, id?: string): string {
        switch (type) {
            case SessionOptions.Phase:
                return DBPathHelper.getPhasesPath(id);
            case SessionOptions.SubPhase:
                return DBPathHelper.getSubPhasesPath(id);
            case SessionOptions.Tag:
                return DBPathHelper.getTagsPath(id);
            default:
                return DBPathHelper.getTagsPath(id);
        }
    }

    async updateOptions(
        selectedSessionOptions: SelectedSessionOptions,
        sessionId: string,
        optionsToRemoveIds?: { [key: string]: string[] },
        optionsToCreateIds?: { [key: string]: string[] },
    ): Promise<void> {
        const batchToUpdate = [];

        sessionOptionsArray.forEach(type => {
            let option = {
                sessions: [sessionId],
                created: this.firestoreService.timestamp,
                updated: this.firestoreService.timestamp,
            } as SessionOptionsType;

            if (SessionOptions.Tag === type) {
                option = {
                    ...option,
                    type: ParentType.Sessions,
                };
            }

            Object.keys(selectedSessionOptions[type] || {}).forEach(optionId => {
                // create new option
                if (optionsToCreateIds && optionsToCreateIds[type].includes(optionId)) {
                    option = {
                        ...option,
                        name: selectedSessionOptions[type][optionId],
                    };

                    batchToUpdate.push({
                        path: this.getOptionPath(type, optionId),
                        data: option,
                    });
                } else {
                    // update option
                    batchToUpdate.push({
                        path: this.getOptionPath(type, optionId),
                        data: {
                            sessions: this.firestoreService.arrayUnion(sessionId),
                            updated: this.firestoreService.timestamp,
                        },
                    });
                }
            });

            if (optionsToRemoveIds) {
                (optionsToRemoveIds[type] || []).forEach(optionId => {
                    batchToUpdate.push({
                        path: this.getOptionPath(type, optionId),
                        data: {
                            sessions: this.firestoreService.arrayRemove(sessionId),
                            updated: this.firestoreService.timestamp,
                        },
                    });
                });
            }
        });

        await this.firestoreService.writeBatch(batchToUpdate);
    }

    async updateOptionsFilters(
        userId: string,
        parentType: ParentType,
        parentId: string,
        optionId: string,
        isSelected: boolean,
        optionType: SessionOptions,
    ): Promise<void> {
        const optionFilterField = OptionsFiltersFields[optionType];
        await this.firestoreService.upsert(`${parentType}/${parentId}/teamMembers/${userId}/filters/sessionsFilters`, {
            [optionFilterField]: isSelected
                ? this.firestoreService.arrayRemove(optionId)
                : this.firestoreService.arrayUnion(optionId),
        });
    }

    getOptions(type: SessionOptions): Observable<SessionOptionsType[]> {
        return this.firestoreService.getDocumentsByMultipleProperties<SessionOptionsType>(
            this.getOptionPath(type),
            new Map<string, any>([['type', type === SessionOptions.Tag ? ParentType.Sessions : '']]),
            'name',
        );
    }
}
