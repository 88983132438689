import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { batchLoadingRowsLimit, DBPathHelper, ParentType, TableRow } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import { SequenceGeneration } from '@accenture/shared/util';

@Injectable({
    providedIn: 'root',
})
export class TableRowService {
    constructor(private firestoreService: FirestoreService) {}

    getRows(parentType: ParentType, parentId: string, tableId?: string): Observable<TableRow[]> {
        const path = DBPathHelper.getTableRowPath(parentType, parentId);
        const tableRowObjects$ = tableId
            ? this.firestoreService.getDocumentsByProperty<TableRow>(path, 'activityItemId', tableId, 'sequence')
            : this.firestoreService.getCollection<TableRow>(path);

        return tableRowObjects$.pipe(
            map((tableRowObjects) => {
                return tableRowObjects.map((row) => new TableRow(row));
            }),
        );
    }

    getRowsBatch(
        parentType: ParentType,
        parentId: string,
        tableId?: string,
        lastRowDocument?: any,
    ): Observable<{ tableRowObjects: TableRow[]; lastDocument?: any; isLastBatch: boolean }> {
        const path = DBPathHelper.getTableRowPath(parentType, parentId);

        return this.firestoreService
            .getDocumentsByQuery<TableRow>(path, (ref) => {
                let query = ref.orderBy('sequence');

                if (tableId) {
                    query = query.where('activityItemId', '==', tableId);
                }

                if (lastRowDocument) {
                    query = query.startAfter(lastRowDocument);
                }

                return query.limit(batchLoadingRowsLimit + 1); // Fetch extra document to check for last batch
            })
            .snapshotChanges()
            .pipe(
                map((snapshot) => {
                    const tableRowObjects = snapshot.map(
                        (doc) => new TableRow({ ...doc.payload.doc.data(), id: doc.payload.doc.id }),
                    );

                    const isLastBatch = snapshot.length <= batchLoadingRowsLimit;
                    const lastDocument = snapshot[snapshot.length - (isLastBatch ? 1 : 2)]?.payload.doc || null;

                    const limitedTableRowObjects = tableRowObjects.slice(0, batchLoadingRowsLimit);

                    return { tableRowObjects: limitedTableRowObjects, lastDocument, isLastBatch };
                }),
            );
    }

    async addRows(parentType: ParentType, parentId: string, rows: Partial<TableRow>[]): Promise<void> {
        const batchData = [];
        let sequence = SequenceGeneration.initial();
        rows.forEach((row) => {
            batchData.push({
                path: DBPathHelper.getTableRowPath(parentType, parentId, this.firestoreService.getPushId()),
                data: {
                    ...row,
                    sequence,
                    created: this.firestoreService.timestamp,
                    updated: this.firestoreService.timestamp,
                },
            });
            sequence = SequenceGeneration.afterLast(sequence);
        });
        await this.firestoreService.setBatch(batchData);
    }

    async addRow(parentType: ParentType, parentId: string, data: Partial<TableRow>): Promise<string> {
        return await this.firestoreService.addDocument(DBPathHelper.getTableRowPath(parentType, parentId), {
            ...data,
            created: this.firestoreService.timestamp,
            updated: this.firestoreService.timestamp,
        });
    }

    async updateRow(
        parentType: ParentType,
        parentId: string,
        rowId: string,
        rowFields: Partial<TableRow>,
    ): Promise<void> {
        await this.firestoreService.update(
            DBPathHelper.getTableRowPath(parentType, parentId, rowId),
            this.firestoreService.replaceEmptyFields(rowFields),
        );
    }

    async deleteRow(parentType: ParentType, parentId: string, rowId: string): Promise<void> {
        return this.firestoreService.delete(DBPathHelper.getTableRowPath(parentType, parentId, rowId));
    }
}
