import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { IconsModule } from '@progress/kendo-angular-icons';
import { FormFieldModule } from '@progress/kendo-angular-inputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import { LabelModule } from '@progress/kendo-angular-label';

import { SharedMaterialModule } from '@accenture/shared/material';
import { SharedUiModule } from '@accenture/shared/ui';

import { ActivityFocusNotificationComponent } from './activity-focus-notification/activity-focus-notification.component';
import { AddActivityTemplatesTableComponent } from './add-activity-templates-table/add-activity-templates-table.component';
import { AddExistingSessionDialogComponent } from './add-existing-session-dialog/add-existing-session-dialog.component';
import { AddExistingSessionTableComponent } from './add-existing-session-table/add-existing-session-table.component';
import { AiGenerateModalTt9Component } from './ai-generate-modal-tt9/ai-generate-modal-tt9.component';
import { BookMeetingButtonComponent } from './book-meeting-button/book-meeting-button.component';
import { DataFlowFormComponent } from './data-flow-form/data-flow-form.component';
import { InvitationFormComponent } from './dialogs/session-invite-dialog/components/invitation-form/invitation-form.component';
import { LinkAccessComponent } from './dialogs/session-invite-dialog/components/link-access/link-access.component';
import { SessionInviteTabsComponent } from './dialogs/session-invite-dialog/components/session-invite-tabs/session-invite-tabs.component';
import { EmailInvitationFormComponent } from './email-invitation-form/email-invitation-form.component';
import { EmailsImportWarningSnackbarComponent } from './emails-import-warning-snackbar/emails-import-warning-snackbar.component';
import { ImageInputComponent } from './image-input/image-input.component';
import { SessionActivityCreatorManagerComponent } from './session-activity-creator-manager/session-activity-creator-manager.component';
import { TemplateEditorActionsPanelComponent } from './template-editor-actions-panel/template-editor-actions-panel.component';
import {
    AddActivityTemplatesDialogComponent,
    AiPromptEntryDialogComponent,
    EmailsImportWarningDialogComponent,
    PublishTemplateConfirmationDialogComponent,
    SelectAttributesDialogComponent,
    SessionBookMeetingDialogComponent,
    SessionInviteDialogComponent,
    SessionsDataFlowConnectionDialogComponent,
} from './dialogs';

@NgModule({
    imports: [
        CommonModule,
        SharedUiModule,
        FormsModule,
        ReactiveFormsModule,
        SharedMaterialModule,
        RouterModule.forChild([
            /* {path: '', pathMatch: 'full', component: InsertYourComponentHere} */
        ]),
        IntlModule,
        DateInputsModule,
        LabelModule,
        FormFieldModule,
        ButtonsModule,
        IconsModule,
        EmailInvitationFormComponent,
    ],
    declarations: [
        AddActivityTemplatesDialogComponent,
        AddActivityTemplatesTableComponent,
        AddExistingSessionDialogComponent,
        AddExistingSessionTableComponent,
        AiGenerateModalTt9Component,
        AiPromptEntryDialogComponent,
        BookMeetingButtonComponent,
        DataFlowFormComponent,
        EmailsImportWarningDialogComponent,
        EmailsImportWarningSnackbarComponent,
        ImageInputComponent,
        InvitationFormComponent,
        LinkAccessComponent,
        SelectAttributesDialogComponent,
        SessionBookMeetingDialogComponent,
        SessionInviteDialogComponent,
        SessionInviteTabsComponent,
        SessionsDataFlowConnectionDialogComponent,
        TemplateEditorActionsPanelComponent,
        ActivityFocusNotificationComponent,
        PublishTemplateConfirmationDialogComponent,
        SessionActivityCreatorManagerComponent,
    ],
    exports: [
        AddActivityTemplatesDialogComponent,
        AddExistingSessionDialogComponent,
        AddExistingSessionTableComponent,
        AiPromptEntryDialogComponent,
        AiGenerateModalTt9Component,
        BookMeetingButtonComponent,
        DataFlowFormComponent,
        EmailsImportWarningDialogComponent,
        EmailsImportWarningSnackbarComponent,
        ImageInputComponent,
        SelectAttributesDialogComponent,
        SessionsDataFlowConnectionDialogComponent,
        TemplateEditorActionsPanelComponent,
        ActivityFocusNotificationComponent,
        PublishTemplateConfirmationDialogComponent,
        SessionActivityCreatorManagerComponent,
        SessionInviteDialogComponent,
    ],
})
export class SharedSessionModule {}
