import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, startWith, tap } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

import {
    ActivityOptionsService,
    FavoriteTemplateService,
    FiltersService,
    ProjectOptionsService,
    SessionOptionsService,
} from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    selectAuthenticatedUserId,
} from '@accenture/global-store';
import {
    ActivityOptions,
    ActivityOptionsType,
    CollectionsSortObject,
    DefaultCollectionSortObject,
    initialDefaultFilterObject,
    OptionsFiltersFields,
    OptionsForSortingByNumberOfUses,
    ParentType,
    ProjectOptions,
    ProjectOptionsType,
    SessionOptions,
    SessionOptionsType,
    templateTabValue,
} from '@accenture/shared/data';

export interface FavoriteTemplatesOptionsFiltersChipsViewModel {
    optionsData: ProjectOptionsType[];
    optionType?: string;
    sortOption: CollectionsSortObject;
    selectedOptions: string[];
    isTemplateParentType: boolean;
    isLoading: boolean;
}

export const defaultViewModel = {
    optionsData: [],
    sortOption: {},
    selectedOptions: [],
    isTemplateParentType: false,
    isLoading: false,
};

@Injectable()
export class FavoriteTemplatesOptionsFiltersChipsFacade {
    vm$ = this.buildViewModel();

    private userId: string;
    private parentTypeTab: ParentType | string;
    private optionType: string;

    constructor(
        private store: Store<AppState>,
        private favoriteTemplateService: FavoriteTemplateService,
        private filtersService: FiltersService,
        private projectOptionsService: ProjectOptionsService,
        private sessionOptionsService: SessionOptionsService,
        private activityOptionsService: ActivityOptionsService,
    ) {}

    updateCollectionSortOptions(sortObject: DefaultCollectionSortObject): void {
        if (!this.userId) {
            return;
        }

        this.favoriteTemplateService.updateFavoriteFilters(this.userId, {
            [this.parentTypeTab]: sortObject,
        });
    }

    applyOptionsFilter(selectedOptions: string[], optionId: string, isSelected: boolean): void {
        if (!this.userId || !this.optionType) {
            return;
        }
        const newData = isSelected
            ? selectedOptions.filter(selectedId => selectedId !== optionId)
            : [...selectedOptions, optionId];

        this.favoriteTemplateService.updateFavoriteTemplatesOptionsFilters(
            this.userId,
            this.optionType,
            newData,
            this.parentTypeTab as ParentType,
        );
    }

    private buildViewModel(): Observable<FavoriteTemplatesOptionsFiltersChipsViewModel> {
        return combineLatest([
            this.store.select(selectAuthenticatedUserId),
            this.filtersService.favoriteTemplatesViewTab$.pipe(distinctUntilChanged()),
            this.filtersService.optionType$.pipe(distinctUntilChanged()),
        ]).pipe(
            switchMap(([userId, templateTab, optionType]) => {
                this.userId = userId;
                this.parentTypeTab = templateTabValue[templateTab];
                this.optionType = OptionsFiltersFields[optionType];

                const collection$ = this.getOptionsCollection(
                    optionType as SessionOptions | ProjectOptions | ActivityOptions,
                );
                const filtersAndSelected$ = this.getAllFavoriteTemplatesFilters();

                return combineLatest([collection$, filtersAndSelected$]).pipe(
                    map(([collection, { sortOption, selectedOptions }]) => {
                        const isTemplateParentType = this.isSessionTemplateParentType();
                        const optionForSortingByNumberOfUses = isTemplateParentType
                            ? OptionsForSortingByNumberOfUses.Sessions
                            : OptionsForSortingByNumberOfUses.Projects;

                        return {
                            sortOption,
                            optionType,
                            selectedOptions,
                            isTemplateParentType,
                            optionsData: this.filtersService.sortOptionsData(
                                collection,
                                sortOption,
                                optionForSortingByNumberOfUses,
                            ),
                            isLoading: false,
                        };
                    }),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    private getAllFavoriteTemplatesFilters(): Observable<{
        sortOption: CollectionsSortObject;
        selectedOptions: string[];
    }> {
        return this.store.select(selectAuthenticatedUserId).pipe(
            tap(userId => (this.userId = userId)),
            switchMap(userId =>
                this.favoriteTemplateService.getCurrentUserAllFavoriteTemplatesFilters(userId).pipe(
                    map(allFilters => {
                        const filtersAndSort = allFilters[this.parentTypeTab] || initialDefaultFilterObject;
                        return {
                            sortOption: {
                                sortOrder:
                                    filtersAndSort?.collectionsSortOrder
                                    || initialDefaultFilterObject.collectionsSortOrder,
                                sortOption:
                                    filtersAndSort?.collectionsSortOption
                                    || initialDefaultFilterObject.collectionsSortOption,
                            },
                            selectedOptions: filtersAndSort[this.optionType] || [],
                        };
                    }),
                ),
            ),
        );
    }

    private isSessionTemplateParentType(): boolean {
        return this.parentTypeTab === ParentType.Templates;
    }

    private getOptionsCollection(
        optionType: SessionOptions | ProjectOptions | ActivityOptions,
    ): Observable<SessionOptionsType[]> | Observable<ProjectOptionsType[]> | Observable<ActivityOptionsType[]> {
        switch (this.parentTypeTab) {
            case ParentType.Templates:
                return this.sessionOptionsService.getOptions(optionType as SessionOptions);
            case ParentType.ProjectTemplates:
                return this.projectOptionsService.getOptions(optionType as ProjectOptions);
            case ParentType.ActivityTemplates:
                return this.activityOptionsService.getOptions(ParentType.Activities);
        }
    }
}
