import { Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';

import { FilesService } from '@accenture/activity/shared/domain';
import { CollectionOptionsService, CollectionsService, OptionsStore } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUser } from '@accenture/global-store';
import {
    Collection,
    CollectionOptions,
    createCollectionErrorTitle,
    errorSnackbarText,
    FileType,
    ProjectOptions,
    SelectedCollectionOptions,
    SessionOptions,
    User,
} from '@accenture/shared/data';
import { DialogService, SnackbarService } from '@accenture/shared/ui';
import { removeNoValuesKeys } from '@accenture/shared/util';

import { CreateCollectionDialogComponent } from './create-collection-dialog.component';

export interface CreateCollectionDialogViewModel {
    user: User;
    collectionImage: FileType;
    currentOptionToDisplay: ProjectOptions | CollectionOptions | SessionOptions | null;
    isLoading: boolean;
}

export const defaultModelValues = {
    user: {} as User,
    collectionImage: {} as FileType,
    currentOptionToDisplay: null,
    isLoading: false,
};

@Injectable()
export class CreateCollectionDialogFacade {
    private isLoading = signal(false);
    private collectionImage$ = new BehaviorSubject<FileType>({} as FileType);
    private isCollectionCreated = false;

    vm$ = this.buildViewModel();

    constructor(
        private store: Store<AppState>,
        private collectionService: CollectionsService,
        private collectionOptionsService: CollectionOptionsService,
        private router: Router,
        private filesService: FilesService,
        private dialogRef: MatDialogRef<CreateCollectionDialogComponent>,
        private optionsStore: OptionsStore,
        private dialogService: DialogService,
        private snackbarService: SnackbarService,
    ) {}

    closeDialog(isChipsOpen?: boolean): void {
        isChipsOpen
            ? this.dialogService.open(CreateCollectionDialogComponent, {
                  panelClass: 'tt9-modal',
                  width: '768px',
              })
            : this.dialogRef.close();
    }

    backToCollectionData(): void {
        this.optionsStore.setCurrentOptionToDisplay(null);
    }

    setCollectionImage(collectionImage: FileType): void {
        this.collectionImage$.next(collectionImage);
    }

    deleteUnusedData(): void {
        this.resetOptions();
        if (this.isCollectionCreated) {
            return;
        }

        this.deleteCollectionImage();
    }

    async createCollection(
        collectionDetails: Partial<Collection>,
        user: User,
        collectionImage: FileType,
    ): Promise<void> {
        this.isLoading.set(true);
        const collectionOptions = await firstValueFrom(this.optionsStore.selectedCollectionOptions$.pipe(take(1)));

        const collectionData = removeNoValuesKeys<Collection>(
            new Collection('', {
                name: collectionDetails.name,
                description: collectionDetails.description,
                color: collectionDetails.color,
                tags: collectionOptions.tags || {},
                creatorId: user.id,
                creatorName: user.displayName,
                creatorImage: user.imageUrl,
                imageUrl: collectionImage?.url || null,
                imageId: collectionImage?.id || null,
            }),
        );

        try {
            const collectionId = await this.collectionService.createCollection(collectionData, user);
            await this.updateOptions(collectionOptions, collectionId);

            this.isCollectionCreated = true;
            this.closeDialog();

            this.router.navigate(['/dashboard/collections', collectionId]);
            return;
        } catch (e) {
            this.snackbarService.showErrorSnackBar(createCollectionErrorTitle, errorSnackbarText);
            console.error(e);
        } finally {
            this.isLoading.set(false);
        }
    }

    private buildViewModel(): Observable<CreateCollectionDialogViewModel> {
        return combineLatest({
            user: this.store.select(selectAuthenticatedUser),
            collectionImage: this.collectionImage$.asObservable(),
            currentOptionToDisplay: this.optionsStore.currentOptionToDisplay$,
            isLoading: toObservable(this.isLoading).pipe(startWith(this.isLoading())),
        }).pipe(
            map(({ user, collectionImage, currentOptionToDisplay, isLoading }) => ({
                user,
                collectionImage,
                currentOptionToDisplay,
                isLoading,
            })),
            startWith(defaultModelValues),
        );
    }

    private deleteCollectionImage(): void {
        const collectionImage = this.collectionImage$.getValue();

        this.filesService.decrementFilesCount(collectionImage?.id);
    }

    private async updateOptions(
        selectedCollectionOptions: SelectedCollectionOptions,
        collectionId: string,
    ): Promise<void> {
        const optionsToCreateIds = await firstValueFrom(this.optionsStore.collectionOptionsToCreateIds$.pipe(take(1)));
        await this.collectionOptionsService.updateOptions(
            selectedCollectionOptions,
            collectionId,
            {},
            optionsToCreateIds,
        );
    }

    private resetOptions(): void {
        this.optionsStore.resetCollectionOptions();
        this.optionsStore.resetCollectionOptionsToCreate();
        this.optionsStore.setCurrentOptionToDisplay(null);
    }
}
