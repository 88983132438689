import { Question } from '../question';
import { ActivityItemType } from './activity-item-type';

export class Date extends Question {
    type = ActivityItemType.Date;

    constructor(data: any) {
        super(data);
    }
}
