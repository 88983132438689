import { Dictionary } from '../dictionary';
import { Question } from '../question';
import { OptionsListItem } from './options-list-item';

export interface ChoicesObject {
    [attributeId: string]: Dictionary<OptionsListItem>;
}

export interface ChoicesList {
    [attributeId: string]: OptionsListItem[];
}

export const otherOption = {
    value: 'Other',
    id: 'OTHER',
};

export class OptionsListOptions {
    other?: boolean;

    constructor(data: any) {
        this.other = data.other ?? false;
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            other: this.other,
        };
    }
}

export class OptionsList extends Question {
    options: OptionsListOptions;
    choices: ChoicesObject | ChoicesList | { default: string[] };

    constructor(data: any) {
        super(data);

        const options = data.options || {};
        this.options = new OptionsListOptions(options);

        if (!data.choices) {
            this.choices = { default: [] };
        } else {
            this.choices = Object.keys(data.choices).reduce((acc, attributeId) => {
                acc[attributeId] = sortBySequenceAsc(
                    Object.keys(data.choices[attributeId] || {}).map(
                        optionsId => new OptionsListItem({ id: optionsId, ...data.choices[attributeId][optionsId] }),
                    ),
                );

                return acc;
            }, {});
        }
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            ...super.createSerializableObject(),
            options: this.options.createSerializableObject(),
            choices: Object.keys(this.choices as ChoicesList).reduce((acc, attributeId) => {
                acc[attributeId] = (this.choices[attributeId] as OptionsListItem[]).reduce((acc, { id, ...option }) => {
                    acc[id] = option as OptionsListItem;
                    return acc;
                }, {} as { [optionId: string]: OptionsListItem });

                return acc;
            }, {}),
        };
    }
}

const sortBySequenceAsc = <T>(items: (T & { sequence: string })[]): T[] => {
    return (items || []).sort((a, b) => (a.sequence || '').toString().localeCompare(b.sequence || ''));
};
