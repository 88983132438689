import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import {
    CollectionSession,
    emptyScreenButtonText,
    emptyScreenDescriptions,
    emptyScreenTitles,
    imageUrl,
    UserAccess,
    UserCollection,
} from '@accenture/shared/data';
import { trackById } from '@accenture/shared/util';

import { MoveToCollectionDialogFacade } from './move-to-collection-dialog-facade';

@Component({
    selector: 'accenture-move-to-collection-dialog',
    templateUrl: './move-to-collection-dialog.component.html',
    styleUrls: ['./move-to-collection-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [MoveToCollectionDialogFacade],
})
export class MoveToCollectionDialogComponent {
    vm$ = this.facade.vm$;
    title = 'Move to collection';
    collectionInputControl = new FormControl<string>('', { nonNullable: true });
    collectionControl = new FormControl<UserCollection>({} as UserCollection);
    trackById = trackById;
    emptyScreenImage = imageUrl; // TODO: update with designs
    emptyScreenTitle = emptyScreenTitles.noCollections;
    emptyScreenDescription = emptyScreenDescriptions.noCollections;
    emptyScreenButton = emptyScreenButtonText.noCollections;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { session: CollectionSession | UserAccess; isCollectionPage?: boolean },
        private facade: MoveToCollectionDialogFacade,
    ) {}

    async addChip(value: UserCollection): Promise<void> {
        // Will be removed
        console.info(value);
        // CHECK FOR THE ERRORS!!
        // this.collectionControl.setValue(value);
        // this.updateAutocompleteValue('');
    }

    async removeChip(): Promise<void> {
        this.collectionControl.setValue(null);
        this.collectionInputControl.enable();
        this.updateAutocompleteValue('');
    }

    async moveToCollection(): Promise<void> {
        await this.facade.moveToCollection();
        this.closeDialog();
    }

    setCollection(collection: UserCollection): void {
        this.collectionControl.setValue(collection);
        this.collectionInputControl.disable();
        this.facade.setSelectedCollection(collection);
    }

    updateAutocompleteValue(value: string): void {
        this.facade.autocompleteValue$.next(value);
    }

    createNewCollection(): void {
        this.facade.createNewCollection();
    }

    closeDialog(): void {
        this.facade.closeDialog();
    }

    ngOnInit(): void {
        this.facade.updateSessionData(this.data);
    }
}
