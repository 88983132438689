<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isProcessing; description: loaderText">
    <div *ngIf="!vm.isLoading && vm.isAvailable">
      <accenture-side-panel
        #origin="cdkOverlayOrigin"
        cdkOverlayOrigin
        [secondaryHeader]="true"
        [panelTitle]="'Session Recorder'"
        [panelTrigger]="origin"
        [panelOpened]="vm.isPanelOpened"
        (closePanel)="close()"
      >
        <div *ngIf="!vm.isRecording" class="summary-container">
          <button class="tt9-btn-ghost btn-icon" (click)="start($event)">
            <mat-icon>videocam</mat-icon>
            <p class="tt9-body-1 tt9-color-primary link-btn">Capture</p>
          </button>

          <button *ngIf="!vm.isUploading" class="tt9-btn-ghost btn-icon" (click)="upload()">
            <mat-icon>upload</mat-icon>
            <p class="tt9-body-1 tt9-color-primary">Upload</p>
          </button>

          <ng-container *ngIf="vm.recordings.length > 0">
            <accenture-session-recorder-list
              [sessionId]="vm.sessionId"
              [recordings]="vm.recordings"
            ></accenture-session-recorder-list>
          </ng-container>

          <button *ngIf="vm.recordings.length > 0" class="tt9-btn-ghost btn-icon summary-btn" (click)="summarizeAll()">
            <mat-icon>summarize</mat-icon>
            <p class="tt9-body-1 tt9-color-primary link-btn">Summarize</p>
          </button>
        </div>

        <!-- Recording in progress -->
        <div *ngIf="vm.isRecording">
          <button class="tt9-btn-ghost btn-icon" (click)="stop($event)">
            <mat-icon>cancel_presentation</mat-icon>
            <p class="tt9-body-1 tt9-color-error link-btn">Abort</p>
          </button>

          <button class="tt9-btn-ghost btn-icon" (click)="stop($event, true)">
            <mat-icon>check_circle</mat-icon>
            <p class="tt9-body-1 tt9-color-success link-btn">Done</p>
          </button>

          <button *ngIf="vm.isPaused" class="tt9-btn-ghost btn-icon" (click)="pause($event)">
            <mat-icon>play_circle</mat-icon>
            <p class="tt9-body-1 tt9-color-secondary link-btn">Resume</p>
          </button>

          <button *ngIf="!vm.isPaused" class="tt9-btn-ghost btn-icon" (click)="pause($event)">
            <mat-icon>pause</mat-icon>
            <p class="tt9-body-1 tt9-color-warning link-btn">Pause</p>
          </button>

          <div class="recorder-status">
            <h2 class="tt9-h2">
              {{ timer(vm.timer.minutes, vm.timer.seconds) }}
            </h2>
          </div>
        </div>
      </accenture-side-panel>

      <div class="recorder-btn" (click)="open($event)">
        <button
          [matTooltip]="timer(vm.timer.minutes, vm.timer.seconds)"
          [matTooltipDisabled]="!vm.isRecording"
          [ngClass]="
            vm.isRecording
              ? vm.isPaused
                ? 'tt9-btn-icon-filled btn-rounded active ml-8 paused'
                : 'tt9-btn-icon-filled btn-rounded active ml-8 recording'
              : 'tt9-btn-icon-filled btn-rounded active ml-8 active'
          "
        >
          <accenture-icon>icon-video</accenture-icon>
        </button>
      </div>
    </div>
  </ng-container>
</ng-container>
