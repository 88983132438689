<ng-container *ngIf="vm$ | async as vm">
  <ng-container *ngIf="!vm.isLoading">
    <div *ngIf="vm.available">
      <ng-template #titleBar let-win>
        <span class="k-window-title">Facilitation Notes</span>
        <button kendoButton (click)="makeNotes()" [svgIcon]="arrowRotateCwIcon" fillMode="flat"></button>
        <button kendoWindowCloseAction [window]="win"></button>
      </ng-template>

      <ng-template #content>
        <div class="notes-container">
          <div [innerHTML]="renderMarkdown(vm.notes)"></div>
        </div>
      </ng-template>

      <button
        class="tt9-btn-icon-filled"
        matTooltipPosition="left"
        matTooltipClass="tt9-arrow-tooltip-right"
        [matTooltip]="tooltip(vm.notes)"
        (click)="vm.notes ? showNotes(titleBar, content, vm.notes) : makeNotes()"
      >
        <accenture-icon>icon-facilitate-notes</accenture-icon>
      </button>

      <div kendoWindowContainer></div>
    </div>
  </ng-container>
  <div *ngIf="vm.isLoading" class="loading">
    <accenture-loading [loaderSize]="loaderSize"></accenture-loading>
  </div>
</ng-container>
