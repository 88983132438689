import { Timestamp } from 'firebase/firestore';
import { Dictionary } from 'lodash';

import { removeEmptyKeys } from '@accenture/shared/util';

import { ActivityType } from './activities';
import { NodeTimestamp } from './node-timestamp';
import { FileType } from './responses';

export class SessionThreadMessage {
    id?: string;
    activityId?: string;
    activityName?: string;
    activityType?: ActivityType;
    sessionId?: string;
    userId?: string;
    userDisplayName?: string;
    userImageUrl?: string;
    value: string;
    file?: FileType;
    isBotMessage?: boolean;
    type: SessionThreadMessageType;
    likes: Dictionary<string>; // { [userId]: reactionId }
    reactions: SessionThreadMessageReaction;
    commentsCount: number;
    parentId?: string;
    created: Timestamp;
    updated: Timestamp;
    reactionsCount?: SessionThreadMessageReactionRender[];
    responseId?: string;
    activityItemId?: string;
    activityItemName?: string;
    responseIndex?: number;
    presentCommentId?: string;

    constructor(message: Partial<SessionThreadMessage>) {
        this.id = message.id ?? '';
        this.sessionId = message.sessionId ?? '';
        this.activityId = message.activityId ?? '';
        this.activityName = message.activityName ?? '';
        this.activityType = message.activityType ?? null;
        this.userId = message.userId ?? '';
        this.userDisplayName = message.userDisplayName ?? '';
        this.userImageUrl = message.userImageUrl ?? '';
        this.value = message.value ?? '';
        this.type = message.type ?? SessionThreadMessageType.Default;
        this.file = message.file ?? null;
        this.isBotMessage = message.isBotMessage ?? false;
        this.likes = message.likes ?? {};
        this.reactions = message.reactions ?? {};
        this.commentsCount = message.commentsCount ?? 0;
        this.parentId = message.parentId ?? '';
        this.created = message.created ?? Timestamp.now();
        this.updated = message.updated ?? Timestamp.now();
        this.reactionsCount = message.reactionsCount ?? [];
        this.responseId = message.responseId ?? '';
        this.activityItemId = message.activityItemId ?? '';
        this.activityItemName = message.activityItemName ?? '';
        this.responseIndex = message.responseIndex ?? 0;
        this.presentCommentId = message.presentCommentId ?? '';
    }

    get likesCount(): number {
        return Object.keys(this.likes).length;
    }

    createSerializableObject(): Record<string, unknown> {
        return removeEmptyKeys({
            userId: this.userId,
            sessionId: this.sessionId,
            activityId: this.activityId,
            activityName: this.activityName,
            activityType: this.activityType,
            userDisplayName: this.userDisplayName,
            userImageUrl: this.userImageUrl,
            value: this.value,
            type: this.type,
            file: this.file,
            isBotMessage: this.isBotMessage,
            likes: this.likes,
            commentsCount: this.commentsCount,
            parentId: this.parentId,
            created: this.created,
            updated: this.updated,
            reactionsCount: this.reactionsCount,
            responseId: this.responseId,
            activityItemId: this.activityItemId,
            activityItemName: this.activityItemName,
            responseIndex: this.responseIndex,
            presentCommentId: this.presentCommentId,
        });
    }
}

export class SessionThreadReaction {
    id?: string;
    sessionId: string;
    sessionThreadMessageId: string;
    userId?: string;
    userDisplayName?: string;
    userImageUrl?: string;
    type: SessionThreadReactionType;
    created: Timestamp;
    updated: Timestamp;

    constructor(message: Partial<SessionThreadReaction>) {
        this.id = message.id ?? '';
        this.sessionId = message.sessionId ?? '';
        this.sessionThreadMessageId = message.sessionThreadMessageId ?? '';
        this.userId = message.userId ?? '';
        this.userDisplayName = message.userDisplayName ?? '';
        this.userImageUrl = message.userImageUrl ?? '';
        this.type = message.type;
        this.created = message.created ?? Timestamp.now();
        this.updated = message.updated ?? Timestamp.now();
    }

    createSerializableObject(): Record<string, unknown> {
        return removeEmptyKeys({
            sessionId: this.sessionId,
            sessionThreadMessageId: this.sessionThreadMessageId,
            userId: this.userId,
            userDisplayName: this.userDisplayName,
            userImageUrl: this.userImageUrl,
            type: this.type,
            created: this.created,
            updated: this.updated,
        });
    }
}

export enum SessionThreadReactionType {
    IDEA = 'IDEA',
    HEART = 'HEART',
    FIRE = 'FIRE',
    WOW = 'WOW',
    TADA = 'TADA',
}

export const SessionThreadReactionTypeIconsMap = {
    [SessionThreadReactionType.HEART]: 'hearts',
    [SessionThreadReactionType.FIRE]: 'fire',
    [SessionThreadReactionType.WOW]: 'open_mouth',
    [SessionThreadReactionType.TADA]: 'tada',
    [SessionThreadReactionType.IDEA]: 'bulb',
};

export const SessionThreadReactionTypeIconsOrder = [
    SessionThreadReactionType.HEART,
    SessionThreadReactionType.FIRE,
    SessionThreadReactionType.WOW,
    SessionThreadReactionType.TADA,
    SessionThreadReactionType.IDEA,
];

export enum SessionThreadMessageType {
    Default = 'SESSION_THREADS_MESSAGE',
    AnonymousON = 'SESSION_THREADS_ANONYMOUS_ON',
    AnonymousOFF = 'SESSION_THREADS_ANONYMOUS_OFF',
    CopyStart = 'SESSION_THREADS_COPY_START',
    CopySuccess = 'SESSION_THREADS_COPY_SUCCESS',
    CopyError = 'SESSION_THREADS_COPY_ERROR',
}

export const sessionThreadNotifications = {
    [SessionThreadMessageType.AnonymousON]:
        'Anonymous mode has been enabled. Starting from now, all the new messages will be marked as "Anonymous"',
    [SessionThreadMessageType.AnonymousOFF]: `Anonymous mode has been disabled. Starting from now, all the new messages will be marked with users' names`,
    [SessionThreadMessageType.CopyStart]: 'Message is being copied to [activityName]',
    [SessionThreadMessageType.CopySuccess]: 'Message was copied to [activityName]',
    [SessionThreadMessageType.CopyError]: 'Something went wrong on copying the message. Please try again later',
};

export interface SessionThreadUserConfiguration {
    panelWidth: number;
    panelMinHeight: boolean;
}

export interface SessionThreadMessageReaction {
    [userId: string]: SessionThreadMessageUserReaction;
}

export interface SessionThreadMessageUserReaction extends NodeTimestamp {
    type: string;
    reactionId: string;
}

export interface SessionThreadMessageReactionRender {
    type: string;
    count: number;
    userIds: string[];
    latestCreatedAt?: Timestamp | string;
}
