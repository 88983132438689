<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading">
    <accenture-base-dialog [title]="title">
      <div class="publish-dialog-container">
        <div class="publish-dialog-content px-32">
          <div class="template-image py-32">
            <accenture-image-input [file]="{ url: vm.template?.imageUrl }" [readonly]="true"></accenture-image-input>
          </div>

          <div class="d-flex-column mb-24">
            <p class="tt9-body-1 text-neutral pb-8">{{ titleName[vm.template?.templateType] }}:</p>
            <p class="tt9-body-1">{{ vm.template?.name }}</p>
          </div>

          <div *ngIf="!!vm.template?.description" class="d-flex-column mb-24">
            <p class="tt9-body-1 text-neutral pb-8">Description:</p>
            <p class="tt9-body-1">{{ vm.template?.description }}</p>
          </div>

          <div *ngIf="isTagsLength(vm.template?.tags)" class="d-flex-wrap mb-24 tt9-caption-2">
            <p class="text-neutral pr-8">TAGS:</p>
            <span *ngFor="let tag of vm.template?.tags | keyvalue; trackBy: trackById; let last = last">
              {{ tag.value }}<ng-container *ngIf="!last">,&nbsp;</ng-container>
            </span>
          </div>

          <mat-form-field
            class="tt9-form-field textarea textarea-auto-size width-100 mb-24"
            color="primary"
            appearance="outline"
            floatLabel="never"
          >
            <textarea
              #creatorNote
              matInput
              cdkTextareaAutosize
              cdkAutosizeMinRows="3"
              cdkAutosizeMaxRows="6"
              placeholder="Creator notes"
              [maxLength]="characterLimitXLarge"
            ></textarea>
            <mat-hint
              class="mat-hint-limit"
              [class]="determineHintClass(creatorNote.value.length, characterLimitXLarge)"
              >{{ characterLimitText(creatorNote.value.length, characterLimitXLarge) }}</mat-hint
            >
          </mat-form-field>
        </div>
        <div class="actions d-flex-end p-16">
          <button class="tt9-btn-default mr-8" (click)="closeDialog()">Cancel</button>
          <button class="tt9-btn" [disabled]="publishBtnDisabled" (click)="onPublish(creatorNote.value)">
            Publish
          </button>
        </div>
      </div>
    </accenture-base-dialog>
  </ng-container>
</ng-container>
