<accenture-base-snack-bar
  [title]="data.title"
  [iconClass]="data.iconClass"
  [iconName]="data.iconName"
  [isVisibleDoNotShowAgain]="data.isVisibleDoNotShowAgain"
  [actionButtonClass]="data.actionButtonClass"
  (snackbarClosed)="onClose()"
  (doNotShowAgain)="onDoNotShowAgain($event)"
>
  <ul class="tt9-snack-bar-list tt9-body-2 pl-8">
    <li>Here you can edit project details, sessions, activities, and data in your template.</li>
    <li>
      All changes will be saved as draft and not visible to users until you click
      <span class="tt9-body-medium-2">"Publish Changes"</span>.
    </li>
  </ul>
  <p class="tt9-body-2">
    <span class="tt9-body-medium-2">Note:</span> After publishing the changes, the previous version of the template will
    <span class="text-underline">no longer be available</span> in the Template Store. If you want to save the current
    version, please save it to My Templates before publishing any changes.
  </p>
</accenture-base-snack-bar>
