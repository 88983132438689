<div *ngIf="vm$ | async as vm" class="container">
  <div
    *ngIf="showFiltersAndSearch(vm)"
    class="search-and-filters"
    [class.scrolled]="isScrolled()"
  >
    <!-- search -->
    <div class="flex-grow-1">
      <mat-form-field
        appearance="outline"
        class="tt9-form-field no-label no-margin width-100"
        floatLabel="always"
      >
        <input
          #search
          matInput
          [placeholder]="searchPlaceholder"
          [value]="vm.searchValue"
          (input)="setSearchValue($event, $event.target.value)"
        />
        <accenture-icon matPrefix>icon-search</accenture-icon>
        <accenture-icon
          *ngIf="search.value?.trim()"
          matSuffix
          (click)="setSearchValue($event, '')"
          >icon-close</accenture-icon
        >
      </mat-form-field>
    </div>
    <!-- /search -->

    <!-- filters -->
    <button
      class="tt9-btn-icon-filled tt9-btn-applied"
      matTooltipClass="tt9-arrow-tooltip-below"
      matTooltipPosition="above"
      [matTooltip]="filterTooltipText"
      [matMenuTriggerFor]="filters"
      [class.active]="filterMenuOpen"
      (click)="toggleFilterMenu()"
    >
      <accenture-icon>icon-filter</accenture-icon>
      <div *ngIf="vm.filtersApplied" class="applied"></div>
    </button>

    <mat-menu
      #filters="matMenu"
      class="tt9-menu in-app-notifications-filters-panel prevent-outside-click"
      [xPosition]="'before'"
      (closed)="toggleFilterMenu()"
    >
      <section class="filters group prevent-outside-click">
        <div class="group prevent-outside-click">
          <p
            class="tt9-caption-3 tt9-color-neutral-500 mb-12 prevent-outside-click"
          >
            Notification Status
          </p>
          <ng-container
            *ngFor="let filter of notificationStatusFilterTypes | keyvalue"
          >
            <mat-checkbox
              color="primary"
              class="tt9-checkbox mb-12"
              [checked]="vm.filters?.[filter.value]"
              (click)="updateFilters($event, filter.value, !vm.filters?.[filter.value])"
            >
              {{ filter.value | displayNotificationStatus }}
            </mat-checkbox>
          </ng-container>
        </div>

        <div class="group prevent-outside-click">
          <p
            class="tt9-caption-3 tt9-color-neutral-500 mb-12 prevent-outside-click"
          >
            Notification Type
          </p>
          <ng-container
            *ngFor="
              let filterType of vm.notificationTypeFilterTypes;
              let last = last
            "
          >
            <mat-checkbox
              color="primary"
              class="tt9-checkbox"
              [class.mb-12]="!last"
              [checked]="vm.filters?.[filterType]"
              (click)="updateFilters($event, filterType, !vm.filters?.[filterType])"
            >
              {{ filterType | displayNotificationFilterType }}
            </mat-checkbox>
          </ng-container>
        </div>
      </section>
    </mat-menu>
    <!-- /filters -->
  </div>

  <!-- list -->
  <div
    class="notifications-list d-flex-column"
    accentureScrollSpy
    (scrollSpyPositionChange)="loadMore($event)"
  >
    <div
      *ngFor="let notification of vm.notifications; trackBy: trackByFn"
      class="notification"
      [ngClass]="{ active: notification.unread }"
    >
      <div class="notification-dot"></div>

      <div class="notification-context d-flex-start">
        <div class="notification-image d-flex-center">
          <accenture-icon
            [size]="iconSize.SmallMedium"
            [colorType]="
              notification.unread ? iconColor.Primary : iconColor.Secondary
            "
            class="notification-icon"
          >
            {{
              notification.type === inAppNotificationType.SessionThread
                ? displayNotificationIcon
                : (notification.type | displayNotificationIcon)
            }}
          </accenture-icon>
        </div>

        <div>
          <div
            [ngSwitch]="getCustomDate(notification.created.toDate())"
            class="notification-date tt9-body-2"
          >
            <span *ngSwitchCase="0">Today</span>
            <span *ngSwitchCase="1">Yesterday</span>
            <span *ngSwitchDefault>{{
              notification.created.toDate() | date : 'MMM. dd, yyyy hh:mm a'
            }}</span>
          </div>

          <div [ngSwitch]="notification.type">
            <!-- Session invite -->
            <div *ngSwitchCase="inAppNotificationType.SessionInvite">
              {{ notification.invitingUserDisplayName }}
              has invited you to
              <a
                class="notification-url"
                (click)="navigateTo($event, notification)"
                >{{ notification.sessionName }}</a
              >
              session
            </div>
            <!-- /Session invite -->

            <!-- Session role change -->
            <div *ngSwitchCase="inAppNotificationType.SessionRoleChange">
              Your role in the
              <a
                class="notification-url"
                (click)="navigateTo($event, notification)"
                >{{ notification.sessionName }}</a
              >
              session has been changed from
              {{ notification.role | displayUserAccessRole }} to
              {{ notification.newRole | displayUserAccessRole }}
            </div>
            <!-- /Session role change -->

            <!-- Template invite -->
            <div *ngSwitchCase="inAppNotificationType.TemplateInvite">
              {{ notification.invitingUserDisplayName }}
              has invited you to
              <span
                class="notification-url"
                (click)="navigateTo($event, notification)"
                >{{ notification.templateName }}</span
              >
              <span *ngIf="isPublicTemplate(notification.templateType)"
                >community</span
              >
              template
            </div>
            <!-- /Template invite -->

            <!-- Template publish -->
            <div *ngSwitchCase="inAppNotificationType.TemplatePublish">
              {{ notification.userNameWhoPublishedTemplate }}
              has published
              <span
                class="notification-url"
                (click)="navigateTo($event, notification)"
                >{{ notification.templateName }}</span
              >
              template to Template Store
            </div>
            <!-- /Template publish -->

            <!-- Update published template-->
            <div *ngSwitchCase="inAppNotificationType.UpdatePublishedTemplate">
              New version of
              <span
                class="notification-url"
                (click)="navigateTo($event, notification)"
              >
                {{ notification.templateName }}</span
              >
              template has been published in Template Store by
              {{ notification.userNameWhoPublishedTemplate }}
            </div>
            <!-- /Template publish -->

            <!-- Table activity step -->
            <div *ngSwitchCase="inAppNotificationType.ActivityItemStepChange">
              <span
                class="notification-url"
                (click)="navigateTo($event, notification)"
                >{{ notification.activityItemTitle }}</span
              >
              {{ notification.activityItemType | lowercase }} from
              {{ notification.activityName }}
              {{ notification.activityType | lowercase }} activity has been
              moved to {{ notification.step | displayNotificationStep }} step
            </div>
            <!-- /Table activity step -->

            <!-- Virus Detected -->
            <div *ngSwitchCase="inAppNotificationType.VirusDetectedInUpload">
              {{
                virusCheckMessages.virusDetectedOnUpload(notification.filename)
              }}
            </div>
            <!-- /Virus Detected -->

            <!-- Session Recorder -->
            <div
              *ngSwitchCase="inAppNotificationType.SessionRecording"
              (click)="navigateTo($event, notification)"
            >
              {{ notification.message }}
            </div>
            <!-- /Session Recorder -->
            <!-- Session invite -->
            <div *ngSwitchCase="inAppNotificationType.SessionThread">
              You were mentioned in
              <a
                class="notification-url"
                (click)="navigateTo($event, notification)"
                >{{ notification.sessionName }}</a
              >:
              {{ notification.message }}
            </div>
            <!-- /Session invite -->
          </div>
        </div>
      </div>

      <div class="notification-menu">
        <accenture-icon
          [matMenuTriggerFor]="notificationOptions"
          (click)="$event.stopPropagation()"
          >icon-more</accenture-icon
        >
        <mat-menu #notificationOptions="matMenu" class="tt9-menu">
          <button
            mat-menu-item
            (click)="markNotificationAsRead(notification.id)"
          >
            <accenture-icon>icon-double-check</accenture-icon>
            Mark as read
          </button>
          <button
            class="tt9-btn-ghost btn-icon btn-error"
            mat-menu-item
            (click)="deleteNotification(notification.id)"
          >
            <accenture-icon>icon-delete</accenture-icon>
            Delete
          </button>
        </mat-menu>
      </div>
    </div>

    <!-- loading -->
    <accenture-loading
      *ngIf="vm.isLoading"
      [loaderSize]="getLoaderSize(!!vm.notifications.length)"
      [class.static]="!!vm.notifications.length"
    ></accenture-loading>
    <!-- /loading -->

    <!-- list bottom -->
    <div *ngIf="showListBottom(vm)" class="list-bottom pt-8">
      <img
        class="list-bottom-icon"
        src="/assets/erp-shared-ui/svg/notifications-list-bottom.svg"
        alt="That's all your notifications from the last 30 days"
      />
      <p class="list-bottom-text tt9-body-2">
        That's all your notifications from the last 30 days
      </p>
    </div>
    <!-- /list bottom -->
  </div>
  <!-- /list -->

  <!-- empty screen -->
  <accenture-empty-screen
    *ngIf="emptyScreenVisible(vm)"
    class="empty"
    [class.filters-applied]="!!vm.searchValue || vm.filtersApplied"
    [imageUrl]="emptyScreenInAppNotificationsDesign"
    [title]="getEmptyScreenTitle(vm)"
  ></accenture-empty-screen>
  <!-- /empty screen -->
</div>
