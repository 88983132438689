import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, map, Observable, startWith, tap } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

import {
    ActivityOptionsService,
    FiltersService,
    ProjectOptionsService,
    SessionOptionsService,
    TemplateService,
} from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    selectActivityConfigurationsMap,
    selectActivityConfigurationsVisibleTypesList,
    selectAuthenticatedUserId,
} from '@accenture/global-store';
import {
    ActivityConfigurationsMap,
    ActivityOptionsType,
    ActivityType,
    DefaultFilterObject,
    Dictionary,
    initialDefaultFilterObject,
    initialTemplatesFilterObject,
    optionsFieldValue,
    OptionsFiltersFields,
    ParentType,
    ProjectOptions,
    ProjectOptionsType,
    SessionOptions,
    SessionOptionsType,
    sortOptionsValues,
    Tag,
    templatesStoreQuickSortOptionsValues,
    templateTabValue,
} from '@accenture/shared/data';
import { compareNumbers } from '@accenture/shared/util';

export interface TemplateStoreQuickFiltersViewModel {
    filtersAndSort: DefaultFilterObject;
    filteredTags: Tag[];
    activityTypesList: ActivityType[];
    activityConfigurationsMap: ActivityConfigurationsMap;
    parentTypeTab: ParentType | string;
    sortOptionsValues: Dictionary<string>;
    isAllTagsPanelOpened: boolean;
    isFiltersApplied: boolean;
    isLoading: boolean;
}

export const templatesStoreViewModel = {
    filtersAndSort: initialDefaultFilterObject,
    filteredTags: [],
    activityTypesList: [],
    activityConfigurationsMap: {},
    parentTypeTab: ParentType.ProjectTemplates,
    sortOptionsValues: sortOptionsValues,
    isAllTagsPanelOpened: false,
    isFiltersApplied: false,
    isLoading: true,
};

@Injectable()
export class TemplateStoreQuickFiltersFacade {
    vm$ = this.buildViewModel();

    private isAllTagsPanelOpened$ = new BehaviorSubject<boolean>(false);

    private userId: string;
    private parentTypeTab: ParentType;

    constructor(
        private store: Store<AppState>,
        private templateService: TemplateService,
        private filtersService: FiltersService,
        private projectOptionsService: ProjectOptionsService,
        private sessionOptionsService: SessionOptionsService,
        private activityOptionsService: ActivityOptionsService,
    ) {}

    applyFiltersAndSort(filters: DefaultFilterObject): void {
        this.templateService.updateTemplatesStoreFilters(this.userId, { [this.parentTypeTab]: filters });
    }

    resetFiltersAndSort(): void {
        this.templateService.resetTemplatesStoreFilters(this.userId, this.parentTypeTab);
    }

    applyOptionsFilter(selectedOptions: string[], optionId: string, isSelected: boolean): void {
        const newData = isSelected
            ? selectedOptions.filter(selectedId => selectedId !== optionId)
            : [...selectedOptions, optionId];

        this.templateService.updateTemplatesStoreOptionsFilters(
            this.userId,
            OptionsFiltersFields[SessionOptions.Tag],
            newData,
            this.parentTypeTab,
        );
    }

    openAllTagsPanel(): void {
        this.filtersService.optionType.next(SessionOptions.Tag);
        this.isAllTagsPanelOpened$.next(true);
    }

    closeAllTagsPanel(): void {
        this.isAllTagsPanelOpened$.next(false);
    }

    private buildViewModel(): Observable<TemplateStoreQuickFiltersViewModel> {
        return combineLatest([
            this.store.select(selectAuthenticatedUserId),
            this.store.select(selectActivityConfigurationsVisibleTypesList),
            this.store.select(selectActivityConfigurationsMap),
            this.filtersService.templatesStoreViewTab$.pipe(distinctUntilChanged()),
        ]).pipe(
            switchMap(([userId, activityTypesList, activityConfigurationsMap, templateTab]) => {
                this.userId = userId;
                this.parentTypeTab = templateTabValue[templateTab] as ParentType;

                return combineLatest([
                    this.getAllTemplatesStoreFilters(this.parentTypeTab),
                    this.getTags(),
                    this.isAllTagsPanelOpened$,
                ]).pipe(
                    map(([filtersAndSort, tags, isAllTagsPanelOpened]) => {
                        return {
                            filtersAndSort,
                            activityConfigurationsMap,
                            isAllTagsPanelOpened,
                            activityTypesList,
                            filteredTags: tags,
                            sortOptionsValues: templatesStoreQuickSortOptionsValues,
                            parentTypeTab: this.parentTypeTab,
                            isFiltersApplied: this.filtersService.isFiltersApplied(
                                filtersAndSort,
                                initialTemplatesFilterObject,
                            ),
                            isLoading: false,
                        };
                    }),
                );
            }),
            startWith(templatesStoreViewModel),
        );
    }

    private getAllTemplatesStoreFilters(parentTypeTab: ParentType | string): Observable<DefaultFilterObject> {
        return this.store.select(selectAuthenticatedUserId).pipe(
            tap(userId => (this.userId = userId)),
            switchMap(userId =>
                this.templateService
                    .getCurrentUserAllTemplatesStoreFilters(userId)
                    .pipe(map(allFilters => (allFilters ? allFilters[parentTypeTab] : {}))),
            ),
            map(filtersAndSort => ({
                ...initialTemplatesFilterObject,
                ...filtersAndSort,
            })),
        );
    }

    private getTags(): Observable<ProjectOptionsType[] | SessionOptionsType[]> {
        const tags: Observable<ProjectOptionsType[] | SessionOptionsType[]> = this.getTagsData();

        return tags.pipe(map(tags => this.sortTags(tags || [])));
    }

    private getTagsData(): Observable<ProjectOptionsType[] | SessionOptionsType[] | ActivityOptionsType[]> {
        switch (this.parentTypeTab) {
            case ParentType.ProjectTemplates:
                return this.projectOptionsService.getOptions(ProjectOptions.Tag);
            case ParentType.Templates:
                return this.sessionOptionsService.getOptions(SessionOptions.Tag);
            case ParentType.ActivityTemplates:
                return this.activityOptionsService.getOptions(ParentType.Activities);
            default:
                return this.projectOptionsService.getOptions(ProjectOptions.Tag);
        }
    }

    private sortTags(
        tags: ProjectOptionsType[] | SessionOptionsType[] | ActivityOptionsType[],
    ): ProjectOptionsType[] | SessionOptionsType[] | ActivityOptionsType[] {
        const field = optionsFieldValue[this.parentTypeTab];

        return tags.sort((a, b) => compareNumbers(b?.[field]?.length, a?.[field]?.length));
    }
}
