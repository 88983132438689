import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { trackByValue } from '@accenture/shared/util';

@Component({
    selector: 'accenture-no-emails-dialog',
    templateUrl: './no-emails-dialog.component.html',
    styleUrls: ['./no-emails-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoEmailsDialogComponent {
    trackByValue = trackByValue;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { listItems: string[] },
        public dialogRef: MatDialogRef<NoEmailsDialogComponent>,
    ) {}

    closeDialog(): void {
        this.dialogRef.close();
    }
}
