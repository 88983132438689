import { ActivityType, ChartType, VoteSummaryTab } from './activities';
import { Dictionary } from './dictionary';
import { NodeTimestamp } from './node-timestamp';
import { SessionFocusStepType } from './session-focus-step-type';

export class SessionFocus extends NodeTimestamp {
    id?: string;
    sessionId: string;
    userId: string;
    userName: string;
    activityId: string;
    activityName: string;
    activityType: ActivityType;
    focusMapIds?: Dictionary<string | number | null>;
    backupMapIds?: Dictionary<string | number | null>;

    // Vote and Quick Poll Summary
    activeTab?: VoteSummaryTab | ChartType;
    focusStep?: SessionFocusStepType;
}
