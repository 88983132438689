import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';

import {
    ActivityOptionsType,
    collectionSortOptionsValues,
    DefaultCollectionSortObject,
    ParentType,
    ProjectOptionsType,
    SessionOptionsType,
    sortOrdersValues,
    TemplateTab,
} from '@accenture/shared/data';
import { trackById } from '@accenture/shared/util';

import {
    DefaultOptionsFiltersChipsFacade,
    DefaultOptionsFiltersChipsViewModel,
} from './default-options-filters-chips-facade';

@Component({
    selector: 'accenture-default-options-filters-chips',
    templateUrl: './default-options-filters-chips.component.html',
    styleUrls: ['./default-options-filters-chips.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DefaultOptionsFiltersChipsFacade],
})
export class DefaultOptionsFiltersChipsComponent {
    vm$: Observable<DefaultOptionsFiltersChipsViewModel> = this.facade.vm$;

    sortOrderOptions = sortOrdersValues;
    sortByOptions = collectionSortOptionsValues;

    trackById = trackById;

    constructor(private facade: DefaultOptionsFiltersChipsFacade) {}

    getOptionCount(option: ProjectOptionsType | SessionOptionsType | ActivityOptionsType, parentType: TemplateTab | ParentType): number {
        switch (parentType) {
            case ParentType.Projects:
            case TemplateTab.Projects:
                return ((option as ProjectOptionsType).projects || []).length;
            case TemplateTab.Sessions:
                return ((option as SessionOptionsType).sessions || []).length;
            case TemplateTab.Activities:
                return ((option as ActivityOptionsType).activities || []).length;
            default:
                return 0;
        }
    }

    isOptionSelected(selectedOptions: string[], id: string): boolean {
        return Array.isArray(selectedOptions) ? selectedOptions.includes(id) : !!selectedOptions?.[id];
    }

    onSelectOption(selectedOptions: string[], option: ProjectOptionsType | SessionOptionsType): void {
        const isSelected = this.isOptionSelected(selectedOptions, option.id);
        this.facade.applyOptionsFilter(selectedOptions, option.id, isSelected);
    }

    updateCurrentSort(sortObject: DefaultCollectionSortObject): void {
        this.facade.updateCollectionSortOptions(sortObject);
    }
}
