import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { PromptType } from '@accenture/shared/data';
import { PromptLibrary } from '@accenture/shared/data';

import { PromptLibraryDialogFacade } from './prompt-library-dialog-facade';

interface InputData {
    title: string;
    placeholder: string;
    promptType: string;
    onSelectPrompt: (promptText) => void;
}

@Component({
    selector: 'accenture-prompt-library-dialog',
    templateUrl: './prompt-library-dialog.component.html',
    styleUrls: ['./prompt-library-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [PromptLibraryDialogFacade],
})
export class PromptLibraryDialogComponent {
    searchControl = new FormControl<string>('', { nonNullable: true });
    vm$ = this.facade.vm$;
    selectedPromptId = '';
    selectedPromptText = '';

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: InputData,
        private facade: PromptLibraryDialogFacade,
        private dialogRef: MatDialogRef<PromptLibraryDialogComponent>,
    ) {
        this.facade.filterPromptLibraryByType(PromptType[this.data.promptType]);
    }

    filterPromptLibrary(searchValue: string): void {
        this.facade.filterPromptLibrary(searchValue);
    }

    clearSearch(event: Event): void {
        event.stopPropagation();
        this.facade.filterPromptLibrary('');
        this.searchControl.patchValue('');
    }

    selectedPrompt(prompt: PromptLibrary): void {
        this.selectedPromptId = prompt.id;
        this.selectedPromptText = prompt.promptText;
    }

    closePromptLibraryDialog(): void {
        this.dialogRef.close();
    }

    confirmSelectedPrompt(): void {
        this.data.onSelectPrompt(this.selectedPromptText);
        this.dialogRef.close();
    }
}
