import { PickerResponse } from 'filestack-js';
import { Timestamp } from 'firebase/firestore';

export interface AntiVirusScanResult {
    created?: Timestamp;
    jobId?: string;
    infected?: boolean;
    infections?: string[];
    timeout?: boolean;
    error?: string;
    response?: PickerResponse;
}
