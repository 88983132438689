import { Dictionary } from './dictionary';
import { Tag } from './tag';

export enum ActivityOptions {
    Tag = 'tags',
}

export interface SelectedActivityOptions {
    tags: Dictionary<string>;
}

export type ActivityOptionsType = Tag;

export const activityOptionsTitle = {
    [ActivityOptions.Tag]: 'Tags',
};

export const optionPlaceholderText = {
    [ActivityOptions.Tag]: 'Start typing tag name',
};

export const newActivityOptionText = {
    [ActivityOptions.Tag]: '(New tag)',
};

export const activityOptionsArray = [ActivityOptions.Tag];
