<div class="no-emails-dialog p-16">
  <div class="mr-16" style="position: relative">
    <p class="tt9-body-medium-1 text-overflow-ellipsis-1 mb-16">Some invites have not been sent!</p>

    <div class="tt9-body-2 pr-16">
      The invites to the following sessions have not been sent as the sessions do not exist any more:
    </div>

    <div class="tt9-body-2 pl-16 mt-4">
      <ul class="tt9-snack-bar-list tt9-body-2">
        <li *ngFor="let item of data.listItems; trackBy: trackByValue">
          {{ item }}
        </li>
      </ul>
    </div>
  </div>

  <button class="tt9-btn-icon no-emails-dialog-close" (click)="closeDialog()">
    <accenture-icon>icon-close</accenture-icon>
  </button>
  <div class="d-flex-end mt-16">
    <button class="tt9-btn-default" (click)="closeDialog()">OK</button>
  </div>
</div>
