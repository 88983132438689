import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

import { trackByValue } from '@accenture/shared/util';

@Component({
    selector: 'accenture-list-snack-bar',
    templateUrl: './list-snack-bar.component.html',
    styleUrls: ['./list-snack-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListSnackBarComponent {
    trackByValue = trackByValue;

    constructor(
        public snackBarRef: MatSnackBarRef<ListSnackBarComponent>,
        @Inject(MAT_SNACK_BAR_DATA)
        public data: {
            listItems: string[];
            iconClass: string;
            iconName: string;
            actionButtonClass: string;
            title?: string;
            isVisibleDoNotShowAgain?: boolean;
            onClickDoNotShowAgain?: (value: boolean) => void;
        },
    ) {}

    onDoNotShowAgain(value: boolean): void {
        this.data.onClickDoNotShowAgain?.(value);
    }

    onClose(): void {
        this.snackBarRef.dismiss();
    }
}
