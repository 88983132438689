import { Timestamp } from 'firebase/firestore';

import { NodeTimestamp } from './node-timestamp';

export enum UserClaim {
    Api = 'api',
    Admin = 'admin',
    TemplateCreator = 'templateCreator',

    //TODO DELETE WHEN COLLECTION FEATURE WILL BE FINISHED
    ProjectCreator = 'projectCreator',
    SessionCreator = 'sessionCreator',
    CollectionCreator = 'collectionCreator',
}

export const userClaims: UserClaim[] = [UserClaim.Admin, UserClaim.ProjectCreator, UserClaim.TemplateCreator];
export const userClaimsAllowedToSwitch: UserClaim[] = [UserClaim.Admin];

export const displayUserClaim: { [key in UserClaim]: string } = {
    [UserClaim.Api]: 'Api',
    [UserClaim.Admin]: 'Admin',
    [UserClaim.TemplateCreator]: 'Template Creator',
    [UserClaim.ProjectCreator]: 'Project Creator',
    [UserClaim.SessionCreator]: 'Session Creator',
    [UserClaim.CollectionCreator]: 'Collection Creator',
};

export enum UserSearchByOption {
    Email = 'email',
}

export enum UserStatus {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
    Deactivated = 'DEACTIVATED',
}

export const userStatusesMap = {
    [UserStatus.Active]: 'Active',
    [UserStatus.Inactive]: 'Inactive',
    [UserStatus.Deactivated]: 'Deactivated',
};

export const scssClassUserStatus = {
    [UserStatus.Active]: 'active-user',
    [UserStatus.Inactive]: 'inactive-user',
    [UserStatus.Deactivated]: 'deactivated-user',
};

export enum UserType {
    Guest = 'GUEST',
    AccentureUser = 'ACCENTURE_USER',
    GeneralUser = 'GENERAL_USER',
}

export const userTypesMap = {
    [UserType.Guest]: 'Guest',
    [UserType.AccentureUser]: 'Accenture user',
    [UserType.GeneralUser]: 'External user',
};

export enum UserRole {
    GlobalAdmin = 'GLOBAL_ADMIN',
    Admin = 'ADMIN',
    Leader = 'LEADER',
    Participant = 'PARTICIPANT',
    Member = 'MEMBER',
    Empty = 'EMPTY',
}

export const userRolesMap = {
    [UserRole.GlobalAdmin]: 'Global Admin',
    [UserRole.Leader]: 'Leader',
    [UserRole.Participant]: 'Participant',
    [UserRole.Empty]: 'No role assigned',
};

export class User extends NodeTimestamp {
    id?: string;
    uid?: string;
    email: string;
    firstName?: string;
    lastName?: string;
    birthday?: Timestamp;
    roles?: Record<string, boolean>;
    guest?: boolean;
    imageUrl?: string;
    globalAdmin?: boolean;
    globalRole?: UserRole;
    lastLogin?: Timestamp;
    title?: string;
    phone?: string;
    location?: string;
    about?: string;
    sessionsCount?: number;
    activityTemplatesCount?: number;
    templatesCount?: number;
    projectTemplatesCount?: number;
    projectsCount?: number;

    aadObjectId?: string;
    status: UserStatus;
    emailVerified?: boolean;
    type?: UserType;

    // notifications
    disableNotifications?: boolean; // email notifications
    lastViewedNotificationDateCreated?: Timestamp;

    constructor(id: string, data: Partial<User>) {
        super(data);

        this.id = id;
        this.email = typeof data.email === 'string' ? data.email : '';
        this.firstName = typeof data.firstName === 'string' ? data.firstName : '';
        this.lastName = typeof data.lastName === 'string' ? data.lastName : '';
        this.birthday = data.birthday ?? null;
        this.roles = this.isRole(data.roles) ? data.roles : {};
        this.guest = typeof data.guest === 'boolean' ? data.guest : false;
        this.imageUrl = typeof data.imageUrl === 'string' ? data.imageUrl : '';
        this.globalAdmin = typeof data.globalAdmin === 'boolean' ? data.globalAdmin : false;
        this.globalRole = data.globalRole ?? UserRole.Empty;
        this.lastLogin = data.lastLogin ?? null;

        this.title = typeof data.title === 'string' ? data.title : '';
        this.phone = typeof data.phone === 'string' ? data.phone : '';
        this.location = typeof data.location === 'string' ? data.location : '';
        this.about = typeof data.about === 'string' ? data.about : '';

        this.aadObjectId = typeof data.aadObjectId === 'string' ? data.aadObjectId : '';
        this.sessionsCount = typeof data.sessionsCount === 'number' ? data.sessionsCount : 0;
        this.activityTemplatesCount = typeof data.activityTemplatesCount === 'number' ? data.activityTemplatesCount : 0;
        this.templatesCount = typeof data.templatesCount === 'number' ? data.templatesCount : 0;
        this.projectTemplatesCount = typeof data.projectTemplatesCount === 'number' ? data.projectTemplatesCount : 0;
        this.projectsCount = typeof data.projectsCount === 'number' ? data.projectsCount : 0;
        this.emailVerified = typeof data.emailVerified === 'boolean' ? data.emailVerified : false;
        this.status = data.status ?? UserStatus.Inactive;
        this.type = data.type || UserType.Guest;

        this.disableNotifications = typeof data.disableNotifications === 'boolean' ? data.disableNotifications : false;
        this.lastViewedNotificationDateCreated = data.lastViewedNotificationDateCreated;
    }

    get displayName(): string {
        return (
            ((this.firstName && this.lastName && `${this.firstName.trim()} ${this.lastName.trim()}`) || this.email)
            ?? 'No display name'
        );
    }

    get createDate(): Date {
        const created = this.created as Timestamp;
        return created?.toDate ? created.toDate() : new Date();
    }

    get avatar(): string {
        return this.imageUrl;
    }

    get isAccenture(): boolean {
        return this.email.endsWith('@accenture.com');
    }

    get isTemplateCreator(): boolean {
        return this.roles[UserClaim.Admin] || this.roles[UserClaim.TemplateCreator];
    }

    get isProjectCreator(): boolean {
        return this.roles[UserClaim.Admin] || this.roles[UserClaim.ProjectCreator];
    }

    get isSessionCreator(): boolean {
        return this.roles[UserClaim.Admin] || (this.roles[UserClaim.SessionCreator] && !this.guest);
    }

    get isGlobalAdmin(): boolean {
        return this.globalAdmin;
    }

    private isRole(value: unknown): value is Record<string, boolean> {
        return typeof value === 'object' && value !== null;
    }
}

export class UserSummary {
    id: string;
    displayName: string;
    email: string;
    emailVerified: boolean;
    status: UserStatus;
    globalRole: UserRole;
    type: UserType;
    lastLogin: number;
    imageUrl?: string;
    firstName: string;
    lastName: string;

    constructor(userId: string, userData: any) {
        const user = new User(userId, userData);

        this.id = userId;
        this.displayName = user.displayName;
        this.email = user.email;
        this.emailVerified = user.emailVerified;
        this.status = user.status;
        this.globalRole = user.globalRole;
        this.type = user.type;
        this.imageUrl = user.imageUrl;
        this.lastLogin = +user.lastLogin?.toDate() || undefined;
        this.firstName = user.firstName || '';
        this.lastName = user.lastName || '';
    }
}

export interface BotContext {
    projectId?: string;
    sessionId?: string;
    activityId?: string;
    activityItemId?: string;
}

export interface UpdatedByUser {
    userId: string;
    displayName: string;
    published?: boolean;
}
