import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, tap } from 'rxjs';

import { emptyScreenTitles, PublicAccess, TemplateRole } from '@accenture/shared/data';
import { SharedMaterialModule } from '@accenture/shared/material';
import { SharedUiModule } from '@accenture/shared/ui';
import { isTruncated } from '@accenture/shared/util';

import { TemplateTeamMembersPanelFacade } from './template-team-members-facade';

@UntilDestroy()
@Component({
    selector: 'accenture-template-team-members-panel',
    templateUrl: './template-team-members-panel.component.html',
    styleUrls: ['./template-team-members-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedMaterialModule, SharedUiModule],
    providers: [TemplateTeamMembersPanelFacade],
})
export class TemplateTeamMembersPanelComponent implements OnInit, OnChanges {
    @Input() showBackArrow!: boolean;
    @Input() publicAccess!: PublicAccess;
    @Input() isActivity!: boolean;
    @Output() closePanel = new EventEmitter<void>();
    @Output() navigateBack = new EventEmitter<void>();

    vm$ = this.facade.vm$;
    isTooltipHidden = false;

    readonly templateRoles = TemplateRole;

    searchControl = new FormControl<string>('');

    constructor(private facade: TemplateTeamMembersPanelFacade) {}
    get emptyScreenTitle(): string {
        return this.searchControl.value ? emptyScreenTitles.noUsersMatch : emptyScreenTitles.noUsersYet;
    }

    addMembers(): void {
        this.facade.addMembers();
    }

    deleteTeamMember(userId: string): void {
        this.facade.openDeleteConfirmationDialog(userId);
    }

    clearSearch(event: MouseEvent): void {
        event.stopPropagation();
        this.searchControl.setValue('');
    }

    ngOnInit(): void {
        this.searchControl.valueChanges
            .pipe(
                debounceTime(200),
                untilDestroyed(this),
                tap(searchValue => {
                    this.facade.setSearchValue(searchValue);
                }),
            )
            .subscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.publicAccess && !!this.publicAccess) {
            this.facade.setPublicTemplate(this.publicAccess);
        }
    }

    isDisabledTooltip(event: Event): void {
        this.isTooltipHidden = isTruncated(event);
    }
}
