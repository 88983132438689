export class FilesRefs {
    id?: string;
    projectId?: string;
    collectionId?: string;
    sessionId?: string;
    templateId?: string;
    activityId?: string;
    activityItemId?: string;
    commentId?: string;
    fileId?: string;
    url: string;
    count: number;
}
