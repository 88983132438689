<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading; description: loaderText">
    <accenture-base-dialog [title]="'Select activity templates'">
      <div class="content-container">
        <accenture-add-activity-templates-table
          [templates]="vm.templates"
          [selectedTemplatesMap]="vm.selectedTemplatesMap"
          (selectedTemplates)="updateSelectedTemplates($event)"
          (searchValue)="updateSearchValue($event)"
        ></accenture-add-activity-templates-table>
      </div>
      <div class="footer d-flex-end">
        <button class="tt9-btn-default" (click)="closeDialog()">Cancel</button>
        <button class="tt9-btn ml-16" [disabled]="!vm.selectedTemplates.length" (click)="addActivities()">
          Select
        </button>
      </div>
    </accenture-base-dialog>
  </ng-container>
</ng-container>
