import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { Activity, Dictionary } from '@accenture/shared/data';
import { IconColor, IconSize, TOOLTIP_DATA } from '@accenture/shared/ui';
import { trackById } from '@accenture/shared/util';

@Component({
    selector: 'accenture-activity-data-flow-tooltip',
    templateUrl: './activity-data-flow-tooltip.component.html',
    styleUrls: ['./activity-data-flow-tooltip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityDataFlowTooltipComponent {
    readonly iconSize = IconSize;
    readonly iconColor = IconColor;
    readonly trackById = trackById;

    constructor(
        @Inject(TOOLTIP_DATA)
        public data: {
            sourceSession: string;
            connectionsIds: string[];
            activitiesById: Dictionary<Activity>;
            connectionCriteria: string;
        },
    ) {}
}
