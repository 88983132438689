import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
    Attribute,
    AttributeClass,
    characterLimitMedium,
    characterLimitText,
    determineHintClass,
    emptyScreenTitles,
    ImportResultType,
    ImportType,
    noItemsFoundImageUrl,
    validationErrors,
    validationMessages,
} from '@accenture/shared/data';
import { trackById } from '@accenture/shared/util';

import { AttributeClassEditDialogModel, AttributesClassEditDialogFacade } from './attributes-edit-dialog-facade';
import { newAttributeGroupText } from './constants';

type AttributesClassForm = FormGroup<{
    attributesClassName: FormControl<string>;
}>;

@Component({
    selector: 'accenture-attributes-edit-dialog',
    templateUrl: './attributes-edit-dialog.component.html',
    styleUrls: ['./attributes-edit-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [AttributesClassEditDialogFacade],
})
export class AttributesClassEditDialogComponent implements OnInit {
    @ViewChild('attributesContainer') private attributesContainer?: ElementRef;
    @ViewChildren('attributeElement') private attributeElementsList?: QueryList<ElementRef>;

    vm$: Observable<AttributeClassEditDialogModel> = this.facade.vm$.pipe(
        tap(({ attributeClass, updateAttributesEvent }) => {
            if (!updateAttributesEvent) {
                setTimeout(() => {
                    this.cdr.detectChanges();
                }, 0);

                return;
            }

            this.initForm(attributeClass);
            this.updateAttributesEventValue = updateAttributesEvent;
        }),
    );

    attributesClassForm: AttributesClassForm = this.formBuilder.group({
        attributesClassName: new FormControl(newAttributeGroupText.name, {
            nonNullable: true,
            validators: Validators.required,
        }),
    });
    searchControl = new FormControl<string>('');
    updateAttributesEventValue: boolean;
    focusedAttributeId = '';

    validationErrors = validationErrors;
    validationMessages = validationMessages;
    importType = ImportType.Excel;
    importResultType = ImportResultType.Attributes;
    emptyScreenTitles = emptyScreenTitles;
    noAttributesFindImageUrl = noItemsFoundImageUrl;
    characterLimitMedium = characterLimitMedium;
    characterLimitText = characterLimitText;
    determineHintClass = determineHintClass;
    trackById = trackById;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string;
            attributeClassId: string | null;
            lastSequence: string | undefined;
        },
        private formBuilder: FormBuilder,
        private facade: AttributesClassEditDialogFacade,
        private cdr: ChangeDetectorRef,
    ) {}

    get attributesClassNameControl(): FormControl<string> {
        return this.attributesClassForm.controls.attributesClassName;
    }

    isShowSeparateLine(): boolean {
        return (
            this.attributesContainer?.nativeElement.scrollHeight > this.attributesContainer?.nativeElement.clientHeight
        );
    }

    updateSearchValue(value: string): void {
        this.facade.updateSearchValue(value.toLowerCase());
    }

    resetSearchValue(): void {
        this.searchControl.patchValue('');
        this.updateSearchValue('');
    }

    addAttribute(event: Event): void {
        event.stopPropagation();
        if (this.searchControl.value?.length) {
            this.resetSearchValue();
        }

        this.facade.addAttribute();

        // need to wait new attribute in list
        setTimeout(() => {
            if (this.attributeElementsList) {
                this.attributeElementsList.last.nativeElement.scrollIntoView({ behavior: 'smooth' });
            }
        });
    }

    updateAttribute(attributeId: string, attributeName: string): void {
        this.facade.updateAttribute(attributeId, attributeName);
    }

    updateSequence({ previousIndex, currentIndex, item: { data } }: CdkDragDrop<Attribute>): void {
        this.facade.updateSequence(previousIndex, currentIndex, data);
    }

    submitForm(): void {
        if (!this.attributesClassForm.valid) {
            return;
        }

        const attributeClassName = this.attributesClassForm.get('attributesClassName').value;

        this.facade.saveAttributeClass(attributeClassName, this.data.lastSequence);
    }

    removeAttribute(attribute: Attribute): void {
        this.facade.removeAttribute(attribute);
    }

    isSaveFormDisabled(): boolean {
        return this.attributesClassForm.invalid;
    }

    importAttributes(attributeNames: string[]): void {
        if (this.searchControl.value?.length) {
            this.resetSearchValue();
        }

        this.facade.importAttributes(attributeNames);
    }

    cancelForm(): void {
        this.facade.closeDialog();
    }

    updateAttributeValue(): void {
        this.facade.updateAttributeValue();
    }

    onFocusAttribute(attributeId: string): void {
        this.focusedAttributeId = attributeId;
    }

    onBlurAttribute(): void {
        this.focusedAttributeId = '';
    }

    ngOnInit(): void {
        this.facade.setAttributeClassId(this.data.attributeClassId);
    }

    private initForm(attributeClass?: AttributeClass): void {
        if (this.updateAttributesEventValue) {
            this.facade.attributesWasUpdated();
        }

        if (!attributeClass) {
            return;
        }

        this.attributesClassForm.patchValue({
            attributesClassName: attributeClass?.name || newAttributeGroupText.name,
        });
    }
}
