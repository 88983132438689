<ng-container *ngIf="vm$ | async as vm">
  <ng-container *ngIf="!vm.isLoading">
    <button
      *ngIf="!showButtonText"
      class="tt9-btn-icon-filled"
      [matTooltip]="tooltipText[importResultType]"
      [matTooltipPosition]="tooltipPosition"
      [matTooltipClass]="tooltipClassName"
      (click)="import()"
    >
      <accenture-icon>{{ iconName || "icon-file" }}</accenture-icon>
    </button>

    <button
      *ngIf="showButtonText && !isMatMenu"
      class="{{ buttonClassName }} btn-icon"
      [matTooltip]="tooltipText[importResultType]"
      [matTooltipPosition]="tooltipPosition"
      [matTooltipClass]="tooltipClassName"
      [matTooltipDisabled]="!tooltipText[importResultType]"
      (click)="import()"
    >
      <accenture-icon>{{ iconName || "icon-file" }}</accenture-icon>
      {{ importTitle }}
    </button>

    <button
      *ngIf="showButtonText && isMatMenu"
      mat-menu-item
      class="{{ buttonClassName }} btn-icon"
      [matTooltip]="tooltipText[importResultType]?.(parentType)"
      [matTooltipPosition]="tooltipPosition"
      [matTooltipClass]="tooltipClassName"
      [matTooltipDisabled]="!tooltipText[importResultType]?.(parentType)"
      (click)="import()"
    >
      <accenture-icon>{{ iconName || "icon-file" }}</accenture-icon>
      {{ importTitle }}
    </button>
  </ng-container>

  <div *ngIf="vm.isLoading" class="loading">
    <accenture-loading [loaderSize]="loaderSize"></accenture-loading>
  </div>
</ng-container>
