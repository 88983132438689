<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading">
    <div *ngIf="!vm.currentOptionToDisplay" [formGroup]="optionsFormGroup">
      <div class="session-options-container">
        <ng-container
          *ngTemplateOutlet="
            sessionOptionTemplate;
            context: {
              sessionOption: sessionOptions.Phase
            }
          "
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="
            sessionOptionTemplate;
            context: {
              sessionOption: sessionOptions.SubPhase
            }
          "
        ></ng-container>
      </div>
      <div class="session-tags">
        <ng-container
          *ngTemplateOutlet="
            sessionOptionTemplate;
            context: {
              sessionOption: sessionOptions.Tag
            }
          "
        ></ng-container>
      </div>
    </div>

    <accenture-options-filters-chips
      *ngIf="vm.currentOptionToDisplay"
      [sortCollectionsData]="vm.sortCollectionsData"
      [optionsData]="getOptionsData(vm)"
      [selectedOptions]="vm.selectedSessionOptions[vm.currentOptionToDisplay]"
      [collectionToCount]="collectionToCount.Sessions"
      (onSelectedOptionsChanged)="updateSelectedOptions($event)"
      (onSortValuesChanged)="updateFilters($event)"
    ></accenture-options-filters-chips>
  </ng-container>

  <ng-template #sessionOptionTemplate let-sessionOption="sessionOption">
    <div [class.phase]="[sessionOptions.Phase, sessionOptions.SubPhase].includes(sessionOption)">
      <mat-form-field
        ngDefaultControl
        appearance="outline"
        class="tt9-form-field has-hint width-100"
        [formControl]="getControl(sessionOption)"
      >
        <mat-label>{{ sessionOptionsTitle[sessionOption] }}</mat-label>
        <input
          #optionsInput
          matInput
          type="text"
          [placeholder]="sessionOptionsTitle[sessionOption]"
          [disabled]="hasMultiplyValues(vm.selectedSessionOptions, sessionOption)"
          [matAutocomplete]="optionAutocomplete"
          [attr.maxLength]="characterLimitMedium"
        />
        <mat-hint
          class="mat-hint-limit"
          [class]="determineHintClass(optionsInput.value.length, characterLimitMedium)"
          >{{ characterLimitText(optionsInput.value.length, characterLimitMedium) }}</mat-hint
        >

        <accenture-icon matSuffix (click)="showOptionChips(sessionOption, $event)"> icon-label-outline </accenture-icon>

        <mat-autocomplete #optionAutocomplete="matAutocomplete" [displayWith]="displayEmptyOption">
          <ng-container *ngIf="optionsInput.value.trim()">
            <mat-option
              *ngIf="isNewOptionShown(sessionOption, vm.selectedSessionOptions, optionsInput.value)"
              (click)="addNewOption(sessionOption)"
            >
              {{ optionsInput.value }} {{ newOptionText[sessionOption] }}
            </mat-option>

            <mat-option
              *ngFor="let filteredOption of filteredOptions; trackBy: trackById"
              [value]="filteredOption.name"
              (click)="updateOptionValue(filteredOption, sessionOption)"
            >
              {{ filteredOption.name }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>

      <div class="chips-list">
        <div class="width-100">
          <mat-chip-listbox hideSingleSelectionIndicator class="tt9-chip">
            <mat-chip-option
              *ngFor="
                let option of vm.selectedSessionOptions[sessionOption] | keyvalue : alphabeticalKeyValueOrder;
                trackBy: trackById
              "
              (removed)="removeOptionValue(option, sessionOption)"
            >
              <span
                matTooltipPosition="above"
                matTooltipClass="tt9-arrow-tooltip-below-right"
                [matTooltip]="option.value"
                >{{ option.value | uppercase }}</span
              >
              <accenture-icon matChipRemove>icon-close</accenture-icon>
            </mat-chip-option>
          </mat-chip-listbox>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
