import { FeatureToggleName } from '@accenture/shared/data';

/* eslint-disable */
export const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/* eslint-enable */

export const saveSessionExportReportInfoSnackBar = 'Session report will be downloaded soon';
export const saveSessionExportReportInfoSnackBarTitle = 'Export report in progress';
export const saveSessionExportReportSuccessSnackBar = 'Session report has been downloaded successfully';
export const saveSessionExportReportSuccessSnackBarTitle = 'Export report downloaded';
export const saveSessionExportReportErrorSnackBarTitle = 'Export report error';

export const saveSessionWithTableAsTemplateConfirmation
    = 'Session settings, activities, data flow settings will be duplicated into the new template.';
export const saveSessionAsTemplateConfirmation
    = 'Session settings, activities, data flow settings will be duplicated into the new template. Responses from the activities will not be copied to the template.';
export const saveSessionAsTemplateInfoSnackBarText = 'Session is being saved as a template';
export const saveSessionAsTemplateSuccessSnackBarText = 'Session has been saved as a template successfully';

export const saveUserExportReportInfoSnackBar = 'User report will be downloaded soon';
export const saveUserExportReportInfoSnackBarTitle = 'Export report in progress';
export const saveUserExportReportSuccessSnackBar = 'User report has been downloaded successfully';
export const saveUserExportReportSuccessSnackBarTitle = 'Export report downloaded';
export const saveUserExportReportErrorSnackBarTitle = 'Export report error';

export const saveActivityWithTableAsTemplateConfirmation
    = 'All the activity settings will be duplicated into the new template.';
export const saveActivityAsTemplateConfirmation
    = 'All the activity settings will be duplicated into the new template. Responses from this activity will not be copied to the template.';
export const saveActivityProjectAsTemplateInfoSnackBar = 'Activity is being saved as a template';
export const saveActivityProjectAsTemplateSuccessSnackBar = 'Activity has been saved as a template successfully';

export const saveActivityToSessionInfoSnackBar = 'Activity template is being added to the project';
export const saveActivityToSessionSuccessSnackBar = 'Activity template has been added to the project successfully';

export const saveSessionToProjectInfoSnackBar = 'Sessions are being added to the Project';
export const saveSessionToProjectSuccessSnackBar = 'Sessions have been added to the Project successfully';

export const saveProjectWithTableAsTemplateConfirmation
    = 'Project settings, sessions with their activities will be duplicated into the new template.';
export const saveProjectAsTemplateConfirmation
    = 'Project settings, sessions with their activities will be duplicated into the new template. Responses from the activities will not be copied to the template.';
export const saveProjectAsTemplateInfoSnackBar = 'Project is being saved as a template';
export const saveProjectAsTemplateSuccessSnackBar = 'Project has been saved as a template successfully';

export const deleteSessionInfoSnackbar = 'Session is being deleted';

export const defaultSessionImage = '/assets/shared-ui/svg/empty-image-input.svg';
export const defaultWaitingSessionImage = '/assets/shared-ui/svg/session-waiting-screen.svg';

//edit template
export const publishEditTemplateChanges
    = 'Are you sure you want to publish <span style="text-decoration: underline; font-weight: bold">all the changes</span> made in the template? You will no longer have access to the previously published template, the update template will be displayed in Template Store';
export const cancelEditTemplateAction
    = 'Are you sure you want to cancel template editing? All the changes will not be saved, only the previous template will be available.';
export const updateTemplateDoneTitle = 'Template updated';
export const updateTemplateDoneMessage = 'Community template has been updated successfully';
export const updateByUserTemplateDoneMessage = (userName: string, templateName: string) =>
    `${templateName} template has been updated by ${userName}`;
export const updateTemplateInProgressTitle = 'Updating template in progress';
export const updateTemplateInProgressMessage = 'Community template is being updated';
export const updatingCancelledTitle = 'Updating template cancelled';
export const updatingCancelledMessage = (userName: string, templateName: string) =>
    `The editing of ${templateName} template has been cancelled by ${userName}`;
export const updateTemplateErrorTitle = 'Updating template error';
export const updateTemplateErrorMessage = 'Something went wrong. Please try again later';

// using template
export const projectCreateProgressFromTemplateTitle = 'Project creation in progress';
export const projectCreateProgressFromTemplateMessage = 'New project is being created from the template';
export const projectCreateSuccessFromTemplateTitle = 'Project created';
export const projectCreateSuccessFromTemplateMessage = 'New project has been created from the template successfully';
export const projectCreateFromTemplateErrorTitle = 'Project creation error';
export const projectCreateFromTemplateErrorMessage = 'Failed to create new project from the template';

// TT9-6801
export const sessionCreateProgressFromTemplateTitle = 'Session creation in progress';
export const sessionCreateProgressFromTemplateMessage = 'New session is being created from the template';
export const sessionCreateSuccessFromTemplateTitle = 'Session created';
export const sessionCreateSuccessFromTemplateMessage = 'New session has been created from the template successfully';
export const sessionCreateFromTemplateErrorTitle = 'Session creation error';
export const sessionCreateFromTemplateErrorMessage = 'Something went wrong. Please try again later';

// TT9-6999: Adding session in Sessions Tab using a Template
export const sessionAddProgressFromTemplateTitle = 'Adding template in progress';
export const sessionAddProgressFromTemplateMessage = 'Session template is being added';
export const sessionAddSuccessFromTemplateTitle = 'Template added';
export const sessionAddSuccessFromTemplateMessage = 'Session template has been added successfully';
export const sessionAddFromTemplateErrorTitle = 'Adding template error';
export const sessionAddFromTemplateErrorMessage = 'Something went wrong. Please try again later';

// saving from Public Templates to My Templates
export const savePublicTemplateTitleProgress = 'Template saving in progress';
export const savePublicTemplateTitleSuccess = 'Template saved';
export const savePublicTemplateTitleError = 'Template saving error';

export const savePublicActivityTmpAsTemplateInProgress = 'Activity template is being saved to My Templates';
export const savePublicActivityTmpAsTemplateSuccessfully
    = 'Activity template has been saved to My Templates successfully';

export const savePublicSessionTmpAsTemplateInProgress = 'Session template is being saved to My Templates';
export const savePublicSessionTmpAsTemplateSuccess = 'Session template has been saved to My Templates successfully';

export const savePublicProjectTmpAsTemplateInProgress = 'Project template is being saved to My Templates';
export const savePublicProjectTmpAsTemplateSuccess = 'Project template has been saved to My Templates successfully';

// template copying (for edit public template)
export const copyTemplateProgressTitle = 'Template copying is in progress';
export const copyTemplateErrorTitle = 'Template copying error';

export const copyTemplateProgressMessage = 'It may take some time';

export const deleteSessionConfirmation
    = 'Are you sure you want to delete this session? You will lose all the associated data.';
export const deleteSessionTemplateConfirmation
    = 'Are you sure you want to delete this session template? You will lose all the associated data.';
export const deleteProjectConfirmation
    = 'Are you sure you want to delete this project? You will lose all the associated data.';
export const deleteProjectTemplateConfirmation
    = 'Are you sure you want to delete this project template? You will lose all the associated data.';
export const deleteCollectionConfirmation
    = 'Are you sure you want to delete this collection? You will lose all the associated data.';

export const deleteUserFromTemplateConfirmation
    = 'Are you sure you want to delete this user from the template? This user will no longer have access to the template.';

// duplicate activity
export const duplicateTableActivityItem
    = 'Are you sure you want to duplicate this table? All the table settings will be copied into the new table.';
export const duplicateTableActivity
    = 'Are you sure you want to duplicate this activity? All the activity settings will be duplicated into the new activity.';
export const duplicateActivity
    = 'Are you sure you want to duplicate this activity? Only activity settings without responses will be duplicated into the new activity.';

export const deleteSessionTeamMemberConfirmation
    = 'Are you sure you want to delete this user from the session? This user will no longer have access to the session.';

export const deleteProjectTeamMemberConfirmation
    = 'Are you sure you want to delete this user from the project? This user will no longer have access to the project and all the associated sessions.';

export { FeatureToggleName };

export const noClientSelected = 'N/A';
