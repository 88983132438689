<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading; description: loaderText">
    <accenture-base-dialog [title]="title">
      <div class="tt9-add-template-dialog">
        <div class="tt9-add-template-dialog-body d-flex-column">
          <div class="template-search">
            <!-- search -->
            <div class="width-100 mt-16">
              <mat-form-field
                appearance="outline"
                floatLabel="always"
                class="tt9-form-field no-label no-margin width-100"
              >
                <input
                  #search
                  matInput
                  [placeholder]="inputPlaceholders.search"
                  [formControl]="searchControl"
                  (input)="updateSearchValue(search.value)"
                />
                <accenture-icon matPrefix>icon-search</accenture-icon>
                <accenture-icon *ngIf="search.value" matSuffix (click)="resetSearchValue()">icon-close</accenture-icon>
              </mat-form-field>
            </div>
            <!-- /search -->
          </div>

          <!--table-->
          <div *ngIf="vm.sessions.length" class="tt9-select-table-container">
            <table class="tt9-select-table width-100">
              <thead>
                <tr class="row-sticky horizontal-shadow">
                  <th class="cell-select"></th>
                  <th class="tt9-body-2 short-cell">Session name</th>
                  <th class="tt9-body-2 short-cell">Description</th>
                  <th class="tt9-body-2 cell-date">Date created</th>
                </tr>
              </thead>

              <tbody>
                <ng-container *ngFor="let session of vm.sessions; trackBy: trackById">
                  <ng-container *ngTemplateOutlet="rowTemplate; context: { template: session }"></ng-container>
                </ng-container>
              </tbody>
            </table>
          </div>
          <!--/table-->

          <!-- empty screen -->
          <ng-container *ngIf="!vm.sessions.length">
            <ng-container *ngTemplateOutlet="emptyScreen"></ng-container>
          </ng-container>
          <!-- /empty screen -->

          <!-- row template -->
          <ng-template #rowTemplate let-template="template">
            <tr (click)="updateSelectedSession(template.id)">
              <td class="cell-select">
                <mat-radio-group [(ngModel)]="selectedTemplateId">
                  <mat-radio-button
                    class="mat-radio-button tt9-radio-button"
                    color="primary"
                    [value]="template.id"
                  ></mat-radio-button>
                </mat-radio-group>
              </td>

              <td class="short-cell">
                <span
                  class="tt9-body-1 text-overflow-ellipsis-2"
                  matTooltipPosition="above"
                  matTooltipClass="tt9-arrow-tooltip-below-right"
                  [matTooltip]="template.name"
                  >{{ template.name }}</span
                >
              </td>

              <td class="short-cell">
                <span
                  class="tt9-body-1 text-overflow-ellipsis-2"
                  matTooltipPosition="above"
                  matTooltipClass="tt9-arrow-tooltip-below-right"
                  [matTooltip]="template.description"
                  >{{ template.description }}</span
                >
              </td>

              <td class="tt9-body-1 resizable-width">
                {{ template.created.toMillis() | date : "mediumDate" }}
              </td>
            </tr>
          </ng-template>
          <!-- /row template -->

          <!-- empty screen template -->
          <ng-template #emptyScreen>
            <accenture-empty-screen
              [title]="emptyScreenTitles.noSessionsFound"
              [imageUrl]="emptyScreenPicture"
            ></accenture-empty-screen>
          </ng-template>
          <!-- /empty screen template -->
        </div>

        <div class="tt9-add-template-dialog-footer d-flex-center-between">
          <button class="tt9-btn-default" (click)="openCreateSessionModal()">
            <accenture-icon>icon-add</accenture-icon>
            Create New Session
          </button>
          <div class="tt9-add-template-dialog-footer-buttons d-flex">
            <button class="tt9-btn-default" (click)="openUseTemplateModal()">
              <accenture-icon>icon-chevron-left-small</accenture-icon>
              Back
            </button>
            <button
              class="tt9-btn"
              [disabled]="!vm.selectedSessionId"
              (click)="addTemplateToSession(vm.selectedSessionId, vm.templateType)"
            >
              Select
            </button>
          </div>
        </div>
      </div>
    </accenture-base-dialog>
  </ng-container>
</ng-container>
