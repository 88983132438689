<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading">
    <accenture-base-dialog [title]="getTitle(vm, data.title)">
      <ng-container [ngSwitch]="vm.activeTab">
        <ng-container *ngSwitchCase="invitationsTab.ByEmail">
          <ng-container [ngSwitch]="vm.inviteByEmailActiveStep">
            <ng-container *ngSwitchCase="emailSteps.Invite">
              <div class="dialog-member-invitations">
                <accenture-invitation-form (viewModelChange)="setInvitationObject($event)"></accenture-invitation-form>
              </div>

              <div class="accenture-dialog-actions">
                <button class="tt9-btn" [disabled]="vm.isInviteButtonDisabled" (click)="invite()">Invite</button>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="emailSteps.SelectMembers">
              <accenture-project-members></accenture-project-members>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="invitationsTab.ByLink">
          <accenture-link-access></accenture-link-access>
        </ng-container>
      </ng-container>
    </accenture-base-dialog>
  </ng-container>
</ng-container>
