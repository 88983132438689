<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading">
    <accenture-base-dialog [title]="'Attributes'">
      <div class="attributes-container">
        <ng-container *ngIf="!!vm.attributeClasses.length; else noAttributeClassesBlock">
          <!-- Attribute classes -->
          <div #attributesListWrapper>
            <div class="attributes-tabs">
              <button
                *ngIf="isBtnVisible"
                [disabled]="scrollToAttributeIndex === 0"
                class="tt9-btn-icon attributes-arrows attributes-arrows-left"
                (click)="scrollToAttribute('prev')"
              >
                <accenture-icon>icon-chevron-left</accenture-icon>
              </button>

              <!-- Attributes class list -->
              <div #attributesListContainer class="navigation">
                <div
                  *ngFor="let attributeClass of vm.attributeClasses; let i = index; trackBy: trackById"
                  class="tt9-navigation-tab tt9-body-2 class-name-container"
                  matTooltipClass="tt9-arrow-tooltip-below"
                  matTooltipPosition="above"
                  [matTooltip]="attributeClass.name"
                  [class.active]="vm.activeAttributeClassId === attributeClass.id"
                  (click)="setActiveAttributeClass(attributeClass.id, vm.activeAttributeClassId)"
                >
                  <span class="class-name text-overflow-ellipsis-1">{{ attributeClass.name }}</span>
                </div>
              </div>

              <div *ngIf="isBtnVisible" class="d-flex attributes-arrows attributes-arrows-right">
                <button
                  class="tt9-btn-icon"
                  [disabled]="scrollToAttributeIndex === vm.attributeClasses.length - 1"
                  (click)="scrollToAttribute('next')"
                >
                  <accenture-icon>icon-chevron-right-small</accenture-icon>
                </button>
              </div>
            </div>
          </div>
          <!-- /Attribute classes -->

          <div class="attributes-tab-content mt-32">
            <p class="tt9-body-2 tt9-color-neutral-500 mt-16">Multiple attributes may be selected</p>
            <div
              *ngIf="vm.groupedAttributes?.[vm.activeAttributeClassId]?.length || vm.searchValue"
              class="d-flex mt-32"
            >
              <div class="attributes-search mr-16">
                <mat-form-field
                  appearance="outline"
                  class="tt9-form-field no-label width-100 no-margin"
                  floatLabel="always"
                >
                  <input
                    matInput
                    placeholder="Search"
                    [value]="vm.searchValue"
                    (change)="filterAttributes($event.target.value)"
                  />
                  <accenture-icon matPrefix>icon-search</accenture-icon>
                  <accenture-icon *ngIf="vm.searchValue" matSuffix (click)="filterAttributes('')">
                    icon-close
                  </accenture-icon>
                </mat-form-field>
              </div>
            </div>

            <ng-container *ngIf="!!vm.groupedAttributes?.[vm.activeAttributeClassId]?.length; else noAttributesBlock">
              <!-- Attributes -->
              <div class="attributes-selection mt-32">
                <mat-chip-listbox hideSingleSelectionIndicator class="tt9-chip">
                  <mat-chip-option
                    *ngFor="let attribute of vm.groupedAttributes?.[vm.activeAttributeClassId]; trackBy: trackById"
                    class="text-overflow-ellipsis"
                    [ngClass]="{
                      selected: isAttributeSelected(vm.activeAttributeClassId, attribute.id),
                      disabled: isAttributeDisabled(vm.activeAttributeClassId, attribute.id)
                    }"
                    (click)="setUserAttribute(vm.activeAttributeClassId, attribute.id)"
                  >
                    {{ attribute.name }}
                  </mat-chip-option>
                </mat-chip-listbox>
              </div>
              <!-- /Attributes -->
            </ng-container>

            <ng-template #noAttributesBlock>
              <accenture-empty-screen
                [title]="vm.searchValue ? noAttributesFind : noAttributesAddedYet"
                [description]="vm.searchValue ? noAttributesFindSubtitle : noAttributesAddedYetSubtitle"
                [imageUrl]="vm.searchValue ? noAttributesFindImageUrl : noAttributesYetImage"
              ></accenture-empty-screen>
            </ng-template>
          </div>
        </ng-container>

        <!-- No attributes defined in the parent -->
        <ng-template #noAttributeClassesBlock>
          <div class="no-attribute-classes mt-16 ml-36">There are no Attribute Classes yet</div>
        </ng-template>
        <!-- /No attributes defined in the parent -->
      </div>

      <!-- Footer -->
      <div class="footer d-flex-center-end">
        <div class="d-flex">
          <button class="tt9-btn-default" (click)="closeDialog()">Cancel</button>
          <button
            class="tt9-btn ml-8"
            [disabled]="isSelectButtonDisabled(vm.attributeClassesIds)"
            (click)="saveUserAttributes()"
          >
            Apply
          </button>
        </div>
      </div>
      <!-- /Footer -->
    </accenture-base-dialog>
  </ng-container>
</ng-container>
