<ng-container *ngIf="vm$ | async as vm">
  <ng-container *ngIf="!vm.isLoading && !vm.isThinking">
    <button
      [ngClass]="
        isRecording() ? 'tt9-btn-icon-filled btn-rounded ml-8 recording' : 'tt9-btn-icon-filled btn-rounded ml-8 active'
      "
      (click)="record()"
    >
      <accenture-icon>icon-concierge-figma</accenture-icon>
    </button>
  </ng-container>
  <div *ngIf="vm.isThinking" class="tt9-btn-icon-filled btn-rounded ml-16 loading">
    <accenture-loading [loaderSize]="loaderSize"></accenture-loading>
  </div>
</ng-container>
