import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    ActivityItem,
    ActivityItemFactory,
    ActivityType,
    DBPathHelper,
    defaultQuestionLabels,
    defaultQuestionTypes,
    DuplicateType,
    FileType,
    optionsFilesFieldName,
    ParentType,
    Question,
    Slide,
} from '@accenture/shared/data';
import { FanOutWrite, FirestoreService } from '@accenture/shared/data-access';
import { sortBySequenceAsc } from '@accenture/shared/util';

import { FilesService } from './files.service';

@Injectable({
    providedIn: 'root',
})
export class ActivityItemService {
    constructor(private firestoreService: FirestoreService, private filesService: FilesService) {}

    timestamp = this.firestoreService.timestamp;

    getActivityItems<T extends ActivityItem>(
        parentType: ParentType,
        parentId: string,
        activityId: string,
    ): Observable<T[]> {
        const hasParent = ![ParentType.ActivityTemplates, ParentType.PublicActivityTemplates].includes(parentType);
        return this.firestoreService
            .getDocumentsByMultipleProperties<T>(
                DBPathHelper.getActivityItemPath(parentType, parentId || activityId),
                hasParent ? new Map([['activityId', activityId]]) : new Map(),
            )
            .pipe(
                map((items: T[]) =>
                    sortBySequenceAsc<ActivityItem>(items as (T & { sequence: string })[]).map(
                        (item) => ActivityItemFactory.create(item) as T,
                    ),
                ),
            );
    }

    getTemplateAllActivityItems(templateId: string): Observable<ActivityItem[]> {
        return this.firestoreService
            .getCollection<ActivityItem>(`templates/${templateId}/activityItems`)
            .pipe(
                map((items: ActivityItem[]) =>
                    sortBySequenceAsc<ActivityItem>(items as (ActivityItem & { sequence: string })[]).map((item) =>
                        ActivityItemFactory.create(item),
                    ),
                ),
            );
    }

    getAllActivityItems(parentType: ParentType, parentId: string): Observable<ActivityItem[]> {
        return this.firestoreService
            .getCollection<ActivityItem>(DBPathHelper.getActivityItemPath(parentType, parentId))
            .pipe(
                map((items: ActivityItem[]) =>
                    sortBySequenceAsc<ActivityItem>(items as (ActivityItem & { sequence: string })[]).map((item) =>
                        ActivityItemFactory.create(item),
                    ),
                ),
            );
    }

    getAllParentActivityItems(parentType: ParentType, parentId: string): Observable<ActivityItem[]> {
        return this.firestoreService
            .getCollection<ActivityItem>(`${parentType}/${parentId}/activityItems`)
            .pipe(
                map((items: ActivityItem[]) =>
                    sortBySequenceAsc<ActivityItem>(items as (ActivityItem & { sequence: string })[]).map(
                        (item: ActivityItem) => ActivityItemFactory.create(item),
                    ),
                ),
            );
    }

    // TODO: Delete after project deprecation
    getSessionActivityItems(projectId: string, sessionId: string): Observable<ActivityItem[]> {
        return this.firestoreService
            .getDocumentsByMultipleProperties<ActivityItem>(
                `projects/${projectId}/activityItems`,
                new Map([['sessionId', sessionId]]),
            )
            .pipe(
                map((items: ActivityItem[]) =>
                    sortBySequenceAsc<ActivityItem>(items as (ActivityItem & { sequence: string })[])
                        .filter((slide) => !!slide.activityId)
                        .map((item: ActivityItem) => ActivityItemFactory.create(item)),
                ),
            );
    }

    getSessionActivityItemsNew(sessionId: string): Observable<ActivityItem[]> {
        return this.firestoreService
            .getDocumentsByMultipleProperties<ActivityItem>(
                DBPathHelper.getActivityItemPath(ParentType.Sessions, sessionId),
                new Map([['sessionId', sessionId]]),
            )
            .pipe(
                map((items: ActivityItem[]) =>
                    sortBySequenceAsc<ActivityItem>(items as (ActivityItem & { sequence: string })[])
                        .filter((slide) => !!slide.activityId)
                        .map((item: ActivityItem) => ActivityItemFactory.create(item)),
                ),
            );
    }

    getProjectActivityItem(projectId: string, activityItemId: string): Observable<ActivityItem> {
        return this.firestoreService.getDocument<ActivityItem>(`projects/${projectId}/activityItems/${activityItemId}`);
    }
    // TODO: Delete after project deprecation
    getProjectActivityItemsByActivityId<T extends ActivityItem>(
        projectId: string,
        activityId: string,
    ): Observable<T[]> {
        return this.firestoreService
            .getDocumentsByProperty<T>(`projects/${projectId}/activityItems`, 'activityId', activityId)
            .pipe(
                map((items: T[]) =>
                    sortBySequenceAsc<T>(items as (T & { sequence: string })[]).map(
                        (item: T) => ActivityItemFactory.create(item) as T,
                    ),
                ),
            );
    }

    getSessionActivityItemsByActivityId<T extends ActivityItem>(
        sessionsId: string,
        activityId: string,
    ): Observable<T[]> {
        return this.firestoreService
            .getDocumentsByProperty<T>(
                DBPathHelper.getActivityItemPath(ParentType.Sessions, sessionsId),
                'activityId',
                activityId,
            )
            .pipe(
                map((items: T[]) =>
                    sortBySequenceAsc<T>(items as (T & { sequence: string })[]).map(
                        (item: T) => ActivityItemFactory.create(item) as T,
                    ),
                ),
            );
    }

    addTemplateQuestion(
        templateId: string,
        activityType: string,
        { activityId, sequence, voteType, sectionId, label }: Partial<Question>,
    ): Promise<string> {
        const defaultLabel = {
            default: voteType ? defaultQuestionLabels[activityType][voteType] : defaultQuestionLabels[activityType],
        };
        const defaultType = defaultQuestionTypes[activityType];
        const defaultQuestion = {
            activityId,
            sequence,
            label: label ? label : defaultLabel,
            type: defaultType,
            visible: { default: true },
            created: this.firestoreService.timestamp,
            updated: this.firestoreService.timestamp,
        } as Record<string, unknown>;
        if (voteType) defaultQuestion.voteType = voteType;
        if (sectionId) defaultQuestion.sectionId = sectionId;

        const question = ActivityItemFactory.create(defaultQuestion).createSerializableObject();
        return this.firestoreService.addDocument(`templates/${templateId}/activityItems`, question);
    }

    addQuestion(
        parentType: ParentType,
        parentId: string,
        activityType: string,
        { activityId, sequence, voteType, sectionId, label }: Partial<Question>,
        sessionId?: string,
    ): Promise<string> {
        const defaultLabel = {
            default: voteType ? defaultQuestionLabels[activityType][voteType] : defaultQuestionLabels[activityType],
        };
        const defaultType = voteType
            ? defaultQuestionTypes[activityType][voteType]
            : defaultQuestionTypes[activityType];
        const defaultQuestion = {
            activityId,
            sequence,
            label: label ? label : defaultLabel,
            type: defaultType,
            visible: { default: true },
            created: this.firestoreService.timestamp,
            updated: this.firestoreService.timestamp,
        } as Record<string, unknown>;
        if (voteType) defaultQuestion.voteType = voteType;
        if (sectionId) defaultQuestion.sectionId = sectionId;

        const question = ActivityItemFactory.create(defaultQuestion).createSerializableObject();

        if (sessionId) question.sessionId = sessionId;
        const activityItemId = this.firestoreService.addDocument(
            DBPathHelper.getActivityItemPath(parentType, parentId || activityId),
            question,
        );

        return activityItemId;
    }

    addActivityItem(parentType: ParentType, parentId: string, data: Record<string, unknown>): Promise<string> {
        const activityItemId = this.firestoreService.addDocument(
            DBPathHelper.getActivityItemPath(parentType, parentId),
            {
                ...data,
                created: this.firestoreService.timestamp,
                updated: this.firestoreService.timestamp,
            },
        );

        return activityItemId;
    }

    hasActivityItemsByActivityId(parentType: ParentType, projectId: string, activityId: string): Observable<boolean> {
        const activityItemsPath = DBPathHelper.getActivityItemPath(parentType, projectId);

        return this.firestoreService.getDocumentsExist(activityItemsPath, new Map([['activityId', activityId]]));
    }

    hasActivityItemsByActivityIdNew(sessionId: string, activityId: string): Observable<boolean> {
        const activityItemsPath = DBPathHelper.getActivityItemPath(ParentType.Sessions, sessionId);

        return this.firestoreService.getDocumentsExist(activityItemsPath, new Map([['activityId', activityId]]));
    }

    async removePollOption(
        parentType: ParentType,
        parentId: string,
        activityItemId: string,
        optionId: string,
    ): Promise<void> {
        await this.firestoreService.update(DBPathHelper.getActivityItemPath(parentType, parentId, activityItemId), {
            choices: {
                [optionId]: this.firestoreService.deleteField,
            },
        });
    }

    async updateActivityItem(
        parentType: ParentType,
        parentId: string,
        activityItemId: string,
        activityItemFields: Partial<ActivityItem>,
    ): Promise<void> {
        await this.firestoreService.update(
            DBPathHelper.getActivityItemPath(parentType, parentId, activityItemId),
            this.firestoreService.replaceEmptyFields(activityItemFields),
        );
    }

    async updateSlideItem(projectId: string, slideItemId: string, slideItemFields: Partial<Slide>): Promise<void> {
        await this.firestoreService.update(`projects/${projectId}/activityItems/${slideItemId}`, slideItemFields);
    }

    async updateSlideItemNew(
        parentType: ParentType,
        parentId: string,
        slideItemId: string,
        slideItemFields: Partial<Slide>,
    ): Promise<void> {
        await this.firestoreService.update(
            DBPathHelper.getActivityItemPath(parentType, parentId, slideItemId),
            slideItemFields,
        );
    }

    async updateActivityItems<T>(
        parentType: ParentType,
        parentId: string,
        activityId: string,
        data: Partial<T>,
    ): Promise<void> {
        const activityItems = await firstValueFrom(this.getActivityItems(parentType, parentId, activityId));
        const dataForUpdate: FanOutWrite[] = [];

        activityItems.forEach((activityItem) => {
            dataForUpdate.push({
                path: this.getActivityItemsPath(parentType, parentId, activityItem.id),
                data: this.firestoreService.replaceEmptyFields({
                    ...data,
                    updated: this.firestoreService.timestamp,
                }),
            });
        });

        await this.firestoreService.updateBatch(dataForUpdate);
    }

    async deleteActivityItems(parentType: ParentType, parentId: string, activityId: string): Promise<void> {
        const activityItems = await firstValueFrom(this.getActivityItems(parentType, parentId, activityId));
        const dataForDelete = [];

        activityItems.forEach((activityItem) => {
            dataForDelete.push({ path: this.getActivityItemsPath(parentType, parentId, activityItem.id) });
        });

        await this.firestoreService.deleteBatch(dataForDelete);
    }

    // TODO: Delete after project deprecation
    async deleteActivityItem(
        parentType: ParentType,
        parentId: string,
        activityItemId: string,
        activityType: ActivityType,
        skipDocRemove = false,
    ): Promise<void> {
        await this.firestoreService.cloudFunctionCallable('deleteActivityItem', {
            skipDocRemove,
            activityType,
            activityItemId,
            parentType,
            parentId,
        });
    }

    async deleteActivityItemNew(
        parentType: ParentType,
        parentId: string,
        activityItemId: string,
        activityType: ActivityType,
        skipDocRemove = false,
    ): Promise<void> {
        await this.firestoreService.cloudFunctionCallable('deleteActivityItemNew', {
            skipDocRemove,
            activityType,
            activityItemId,
            parentType,
            parentId,
        });
    }

    async createInitialRepeats(parentType: ParentType, parentId: string, activityItemId: string): Promise<void> {
        await this.firestoreService.cloudFunctionCallable('createInitialRepeats', {
            activityItemId,
            parentType,
            parentId,
        });
    }

    // TODO: Delete after project deprecation
    async duplicateActivityItem<T>(
        parentType: ParentType,
        parentId: string,
        { id: activityItemId, activityId }: Question,
        sequence: string,
        saveResponses = false,
        sessionId?: string,
    ): Promise<T> {
        return this.firestoreService.cloudFunctionCallable('duplicateData', {
            saveResponses,
            from: {
                parentType,
                parentId,
                activityId,
                activityItemId,
            },
            to: {
                parentType,
                parentId,
                activityId,
                sessionId,
            },
            activityItem: {
                sequence,
            },
            type: DuplicateType.ActivityItem,
        });
    }

    async duplicateActivityItemNew<T>(
        parentType: ParentType,
        parentId: string,
        { id: activityItemId, activityId }: Question,
        sequence: string,
        saveResponses = false,
        sessionId?: string,
    ): Promise<T> {
        return this.firestoreService.cloudFunctionCallable('duplicateDataTt9', {
            saveResponses,
            from: {
                parentType,
                parentId,
                activityId,
                activityItemId,
            },
            to: {
                parentType,
                parentId,
                activityId,
                sessionId,
            },
            activityItem: {
                sequence,
            },
            type: DuplicateType.ActivityItem,
        });
    }

    async removeActivityItemOption(
        parentType: ParentType,
        parentId: string,
        activityItemId: string,
        optionId: string,
    ): Promise<void> {
        await this.firestoreService.update(DBPathHelper.getActivityItemPath(parentType, parentId, activityItemId), {
            choices: {
                default: {
                    [optionId]: this.firestoreService.deleteField,
                },
            },
        });
    }

    async addActivityItemOptionsFiles(
        parentType: ParentType,
        parentId: string,
        activityId: string,
        activityItem: ActivityItem,
        files: FileType[],
    ): Promise<void> {
        const filesReferences = {};
        await Promise.all(
            files.map((file) => {
                const fileId = file.id || this.firestoreService.getPushId();
                filesReferences[fileId] = {
                    ...file,
                    id: fileId,
                };

                return this.filesService.createFileReference(fileId, {
                    activityId,
                    fileId,
                    templateId: parentType === ParentType.Templates ? parentId : '',
                    projectId: parentType === ParentType.Projects ? parentId : '',
                    activityItemId: activityItem.id,
                    url: file.url,
                });
            }),
        );

        const fileFieldName = optionsFilesFieldName[activityItem.type];
        await this.firestoreService.update(DBPathHelper.getActivityItemPath(parentType, parentId, activityItem.id), {
            options: { [fileFieldName]: filesReferences },
        });
    }

    async removeActivityItemOptionsFile(
        parentType: ParentType,
        parentId: string,
        activityItem: ActivityItem,
        fileId: string,
    ): Promise<void> {
        const fileFieldName = optionsFilesFieldName[activityItem.type];
        await this.firestoreService.update(DBPathHelper.getActivityItemPath(parentType, parentId, activityItem.id), {
            options: {
                [fileFieldName]: {
                    [fileId]: this.firestoreService.deleteField,
                },
            },
        });

        await this.filesService.decrementFilesCount(fileId);
    }

    async removeActivityItemOptionsFilesAll(activityItem: ActivityItem): Promise<void> {
        const fileFieldName = optionsFilesFieldName[activityItem.type];
        const fileIds = Object.keys((activityItem as Question).options[fileFieldName]);
        if (fileIds.length) {
            const batchData = [];
            for (const fileId of fileIds) {
                batchData.push({
                    path: DBPathHelper.getFilesRefsPath(fileId),
                    data: { count: this.firestoreService.changeCounterValue(-1) },
                });
            }
            await this.firestoreService.writeBatch(batchData);
        }
    }

    async updateActivityItemVideoReference(
        parentType: ParentType,
        parentId: string,
        activityItemId: string,
        videoReference: FileType,
    ): Promise<void> {
        await this.firestoreService.update(DBPathHelper.getActivityItemPath(parentType, parentId, activityItemId), {
            options: {
                videos: {
                    [videoReference.id]: videoReference,
                },
            },
        });
    }

    async updateVoteItemBookmark(projectId: string, voteItemId: string, bookmarked: boolean): Promise<void> {
        await this.firestoreService.update(`projects/${projectId}/activityItems/${voteItemId}`, {
            bookmarked,
        });
    }

    async updateVoteItemBookmarkNew(sessionId: string, voteItemId: string, bookmarked: boolean): Promise<void> {
        await this.firestoreService.update(`sessions/${sessionId}/activityItems/${voteItemId}`, {
            bookmarked,
        });
    }

    async removeActivityItemVideoReference(
        parentType: ParentType,
        parentId: string,
        activityItemId: string,
        referenceId: string,
    ): Promise<void> {
        await this.firestoreService.update(DBPathHelper.getActivityItemPath(parentType, parentId, activityItemId), {
            options: {
                videos: {
                    [referenceId]: this.firestoreService.deleteField,
                },
            },
        });
    }

    async removeActivityItemChoices(parentType: ParentType, parentId: string, activityItemId: string): Promise<void> {
        await this.firestoreService.update(DBPathHelper.getActivityItemPath(parentType, parentId, activityItemId), {
            choices: this.firestoreService.deleteField,
        });
    }

    private getActivityItemsPath(parentType: ParentType, parentId: string, activityItemId: string): string {
        return DBPathHelper.getActivityItemPath(parentType, parentId, activityItemId);
    }
}
