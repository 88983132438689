import { ChangeDetectionStrategy, Component } from '@angular/core';

import { characterLimitText, characterLimitXL, determineHintClass, Dictionary } from '@accenture/shared/data';
import { trackById } from '@accenture/shared/util';

import { title, titleName } from './constants';
import { PublishTemplateDialogFacade } from './publish-template-dialog-facade';

@Component({
    selector: 'accenture-publish-template-dialog',
    templateUrl: './publish-template-dialog.component.html',
    styleUrls: ['./publish-template-dialog.component.scss'],
    providers: [PublishTemplateDialogFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublishTemplateDialogComponent {
    vm$ = this.facade.vm$;

    title = title;
    titleName = titleName;
    trackById = trackById;
    characterLimitXLarge = characterLimitXL;
    determineHintClass = determineHintClass;
    characterLimitText = characterLimitText;
    publishBtnDisabled = false;

    constructor(private facade: PublishTemplateDialogFacade) {}

    isTagsLength(data: Dictionary<string>): boolean {
        return !!Object.keys(data || {}).length;
    }

    closeDialog(): void {
        this.facade.closeDialog();
    }

    async onPublish(creatorNote?: string): Promise<void> {
        this.publishBtnDisabled = true;
        await this.facade.onPublish(creatorNote);
        this.publishBtnDisabled = false;
        this.closeDialog();
    }
}
