import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { tap } from 'rxjs';

// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    Dictionary,
    emptyScreenDescriptions,
    emptyScreenImageUrlAttributes,
    emptyScreenTitles,
    noItemsFoundImageUrl,
} from '@accenture/shared/data';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { trackById } from '@accenture/shared/util';

import { AttributesFromOtherSessions } from './select-attributes-dialog.models';
import { SelectAttributesDialogFacade } from './select-attributes-dialog-facade';

@Component({
    selector: 'accenture-select-attributes-dialog',
    templateUrl: './select-attributes-dialog.component.html',
    styleUrls: ['./select-attributes-dialog.component.scss'],
    providers: [SelectAttributesDialogFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectAttributesDialogComponent implements AfterViewInit {
    @ViewChild('attributesListWrapper') private attributesListWrapper!: ElementRef;
    @ViewChild('attributesListContainer') private attributesListContainer!: ElementRef;

    vm$ = this.facade.vm$.pipe(
        tap((vm) => {
            if (!Object.keys(this.userSelectedAttributes).length) {
                this.userSelectedAttributes = cloneDeep(vm.userAttributes);
            }
        }),
    );
    selectCopyAttributesControl = new FormControl<AttributesFromOtherSessions | null>(null);
    isAttributeCopyingSelected = false;
    userSelectedAttributes: Dictionary<string[]> = {};
    unselectAttributesIds: Set<string> = new Set();
    trackById = trackById;
    noAttributesAddedYet = emptyScreenTitles.noAttributesAddedYet;
    noAttributesAddedYetSubtitle = emptyScreenDescriptions.noAttributesAddedYet;
    noAttributesYetImage = emptyScreenImageUrlAttributes;
    noAttributesFind = emptyScreenTitles.noAttributesMatch;
    noAttributesFindSubtitle = emptyScreenDescriptions.noAttributesMatch;
    noAttributesFindImageUrl = noItemsFoundImageUrl;

    scrollToAttributeIndex = 0;
    isBtnVisible = true;

    constructor(private facade: SelectAttributesDialogFacade, private changeDetector: ChangeDetectorRef) {}

    isSelectButtonDisabled(attributeClassesIds: string[]): boolean {
        return !Object.keys(this.userSelectedAttributes).reduce((acc, classId) => {
            if (attributeClassesIds.includes(classId)) {
                acc += this.userSelectedAttributes[classId].length;
            }

            return acc;
        }, 0);
    }

    isAttributeSelected(selectedClassId: string | undefined, attributeId: string): boolean {
        if (!selectedClassId) {
            return false;
        }

        return (this.userSelectedAttributes[selectedClassId] || []).includes(attributeId);
    }

    isAttributeDisabled(selectedClassId: string | undefined, attributeId: string): boolean {
        // TODO: will be updated in another story
        return false;
    }

    attributesControlsVisibility(): void {
        setTimeout(() => {
            const attributesListWrapperClientWidth = this.attributesListWrapper?.nativeElement?.clientWidth;
            const attributesListScrollWidth = this.attributesListContainer?.nativeElement?.scrollWidth;
            this.isBtnVisible = attributesListWrapperClientWidth - attributesListScrollWidth <= 185; // 185 - Add attribute group btn width
            this.changeDetector.detectChanges();
        }, 300);
    }

    setUserAttribute(classId: string | undefined, attributeId: string): void {
        if (this.selectCopyAttributesControl.value) {
            this.selectCopyAttributesControl.reset();
        }

        if (!classId) {
            return;
        }

        // remove if exists
        if (!!this.isAttributeSelected(classId, attributeId)) {
            const currentData = this.userSelectedAttributes[classId];
            const filteredData = currentData.filter((id) => id !== attributeId);
            this.userSelectedAttributes[classId] = filteredData;
            this.unselectAttributesIds.add(attributeId);

            return;
        }

        // add to the current
        if (!!this.userSelectedAttributes[classId]) {
            this.userSelectedAttributes[classId].push(attributeId);

            if (this.unselectAttributesIds.has(attributeId)) {
                this.unselectAttributesIds.delete(attributeId);
            }

            return;
        }

        this.userSelectedAttributes[classId] = [attributeId];
    }

    async saveUserAttributes(): Promise<void> {
        await this.facade.saveUserAttributesConfirmation(this.userSelectedAttributes, this.unselectAttributesIds);
    }

    filterAttributes(searchValue: string): void {
        this.facade.filterAttributes(searchValue);
    }

    closeDialog(): void {
        this.facade.closeDialog();
    }

    setActiveAttributeClass(classId: string, activeAttributeClassId: string | undefined): void {
        if (activeAttributeClassId === classId) {
            return;
        }

        this.isAttributeCopyingSelected = false;

        this.selectCopyAttributesControl.reset();
        this.facade.setActiveAttributeClass(classId);
    }

    scrollToAttribute(direction: string): void {
        const itemAttributesList = this.attributesListContainer.nativeElement.children;
        if (direction === 'prev' && !this.scrollToAttributeIndex) {
            return;
        }

        const attributesListClientRect = this.attributesListContainer.nativeElement.getBoundingClientRect();
        const attributesListWrapperClientRect = this.attributesListWrapper.nativeElement.getBoundingClientRect();
        if (
            direction === 'next'
            && attributesListWrapperClientRect.right
                > itemAttributesList[itemAttributesList.length - 1].getBoundingClientRect().right
        ) {
            return;
        }

        direction === 'prev' ? (this.scrollToAttributeIndex -= 1) : (this.scrollToAttributeIndex += 1);

        this.attributesListContainer.nativeElement.style.transform = `translateX(-${
            itemAttributesList[this.scrollToAttributeIndex].getBoundingClientRect().left - attributesListClientRect.left
        }px)`;

        this.attributesControlsVisibility();
    }

    ngAfterViewInit(): void {
        this.attributesControlsVisibility();
    }

    private scrollToStart(): void {
        if (this.attributesListContainer) {
            this.attributesListContainer.nativeElement.style.transform = 'translateX(0px)';
            this.scrollToAttributeIndex = 0;
        }
    }
}
