<ng-container *ngIf="vm$ | async as vm">
  <div class="default-filters" [formGroup]="filtersAndSortForm">
    <div *accentureLoaded="!vm.isLoading" class="default-filters-container">
      <h6 class="tt9-body-medium-1">Filter</h6>

      <!-- filter by role -->
      <div *ngIf="isAbleFiltersByRole(vm.parentType)">
        <mat-form-field appearance="outline" class="tt9-form-field width-100" floatLabel="always">
          <mat-label> Role </mat-label>
          <mat-select
            formControlName="roles"
            placeholder="Choose role"
            [multiple]="true"
            [panelClass]="['tt9-select-panel-custom']"
          >
            <mat-option *ngFor="let role of getRolesList(vm.parentType); trackBy: trackById" [value]="role">
              {{ roleDisplayName[role] }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-chip-listbox hideSingleSelectionIndicator class="tt9-chip">
          <mat-chip-option *ngFor="let role of filtersAndSortForm?.controls.roles.value">
            <span>{{ roleDisplayName[role] }}</span>
            <accenture-icon matChipRemove (click)="removeSelectedRole(role)">icon-close</accenture-icon>
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
      <!-- filter by role -->

      <!-- filter for projects -->
      <ng-container
        *ngIf="vm.parentType === parentTypeList.Projects || vm.parentType === parentTypeList.ProjectTemplates"
      >
        <!-- filter by client -->
        <div>
          <mat-form-field appearance="outline" class="tt9-form-field width-100" floatLabel="always">
            <mat-label>Clients</mat-label>
            <input
              type="text"
              matInput
              placeholder="Choose client"
              [matAutocomplete]="client"
              (input)="updateClientOptions($event.target?.value)"
            />
            <accenture-icon
              matSuffix
              matTooltipPosition="above"
              matTooltipClass="tt9-arrow-tooltip-below-right"
              [matTooltip]="tooltipTexts.filterAllClients"
              (click)="openFilterAndSortPanel($event, projectOptions.Client)"
            >
              icon-label-outline
            </accenture-icon>

            <mat-autocomplete #client="matAutocomplete">
              <mat-option *ngFor="let option of vm.filteredClients; trackBy: trackById" (click)="setClient(option)">
                {{ option.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-chip-listbox hideSingleSelectionIndicator class="tt9-chip">
            <mat-chip-option *ngFor="let clientId of filtersAndSortForm?.controls.clients.value">
              <span>{{ vm.clientsMap[clientId] | uppercase }}</span>
              <accenture-icon matChipRemove (click)="removeSelectedClient(clientId)">icon-close</accenture-icon>
            </mat-chip-option>
          </mat-chip-listbox>
        </div>
        <!-- filter by client -->
      </ng-container>
      <!-- filter for projects -->

      <!-- filter for sessions -->
      <ng-container *ngIf="vm.parentType === parentTypeList.Templates">
        <!-- filter by Phase -->
        <div>
          <mat-form-field appearance="outline" class="tt9-form-field width-100" floatLabel="always">
            <mat-label>Phases</mat-label>
            <input
              type="text"
              matInput
              placeholder="Choose phase"
              [matAutocomplete]="phase"
              (input)="updatePhaseOptions($event.target?.value)"
            />

            <accenture-icon
              matSuffix
              matTooltipPosition="above"
              matTooltipClass="tt9-arrow-tooltip-below-right"
              [matTooltip]="tooltipTexts.filterAllPhases"
              (click)="openFilterAndSortPanel($event, sessionOptions.Phase)"
            >
              icon-label-outline</accenture-icon
            >

            <mat-autocomplete #phase="matAutocomplete">
              <mat-option *ngFor="let option of vm.filteredPhases; trackBy: trackById" (click)="setPhase(option)">
                {{ option.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-chip-listbox hideSingleSelectionIndicator class="tt9-chip">
            <mat-chip-option *ngFor="let phasesId of filtersAndSortForm?.controls.phases.value">
              <span>{{ vm.phasesMap[phasesId] | uppercase }}</span>
              <accenture-icon matChipRemove (click)="removeSelectedPhase(phasesId)">icon-close</accenture-icon>
            </mat-chip-option>
          </mat-chip-listbox>
        </div>
        <!-- /filter by Phase -->

        <!-- filter by Sub-Phase -->
        <div>
          <mat-form-field appearance="outline" class="tt9-form-field width-100" floatLabel="always">
            <mat-label>Sub-Phases</mat-label>
            <input
              type="text"
              matInput
              placeholder="Choose sub-phase"
              [matAutocomplete]="subPhase"
              (input)="updateSubPhaseOptions($event.target?.value)"
            />
            <accenture-icon
              matSuffix
              matTooltipPosition="above"
              matTooltipClass="tt9-arrow-tooltip-below-right"
              [matTooltip]="tooltipTexts.filterAllSubPhases"
              (click)="openFilterAndSortPanel($event, sessionOptions.SubPhase)"
            >
              icon-label-outline
            </accenture-icon>

            <mat-autocomplete #subPhase="matAutocomplete">
              <mat-option *ngFor="let option of vm.filteredSubPhases; trackBy: trackById" (click)="setSubPhase(option)">
                {{ option.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-chip-listbox hideSingleSelectionIndicator class="tt9-chip">
            <mat-chip-option *ngFor="let subPhasesId of filtersAndSortForm?.controls.subPhases.value">
              <span>{{ vm.subPhasesMap[subPhasesId] | uppercase }}</span>
              <accenture-icon matChipRemove (click)="removeSelectedSubPhase(subPhasesId)">icon-close</accenture-icon>
            </mat-chip-option>
          </mat-chip-listbox>
        </div>
        <!-- /filter by Sub-Phase -->
      </ng-container>
      <!-- filter for sessions -->

      <!-- filter for activity -->
      <ng-container *ngIf="vm.parentType === parentTypeList.ActivityTemplates">
        <!-- filter by activity type -->
        <div>
          <mat-form-field appearance="outline" class="tt9-form-field width-100" floatLabel="always">
            <mat-label> Activity Type </mat-label>
            <mat-select
              formControlName="activityTypes"
              placeholder="Choose activity type"
              [multiple]="true"
              [panelClass]="['tt9-select-panel-custom']"
            >
              <mat-option *ngFor="let activityType of vm.activityTypesList; trackBy: trackById" [value]="activityType">
                {{ activityType | displayActivityType : vm.activityConfigurationsMap }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-chip-listbox hideSingleSelectionIndicator class="tt9-chip">
            <mat-chip-option
              *ngFor="let activityType of filtersAndSortForm?.controls.activityTypes.value; trackBy: trackByValue"
            >
              <span>{{ activityType | displayActivityType : vm.activityConfigurationsMap }}</span>
              <accenture-icon matChipRemove (click)="removeSelectedActivityType(activityType)"
                >icon-close</accenture-icon
              >
            </mat-chip-option>
          </mat-chip-listbox>
        </div>
        <!-- filter by activity type -->
      </ng-container>
      <!-- filter for activity -->

      <!-- filter by tag -->
      <div *ngIf="vm.parentType !== templateTabValue[templateTab.All]">
        <mat-form-field appearance="outline" class="tt9-form-field width-100" floatLabel="always">
          <mat-label>Tags</mat-label>
          <input
            type="text"
            matInput
            placeholder="Choose tag"
            [matAutocomplete]="tags"
            (input)="updateTagOptions($event.target?.value)"
          />
          <accenture-icon
            matSuffix
            matTooltipPosition="above"
            matTooltipClass="tt9-arrow-tooltip-below-right"
            [matTooltip]="tooltipTexts.filterAllTags"
            (click)="openFilterAndSortPanel($event, projectOptions.Tag)"
          >
            icon-label-outline
          </accenture-icon>

          <mat-autocomplete #tags="matAutocomplete">
            <mat-option *ngFor="let option of vm.filteredTags; trackBy: trackById" (click)="setTag(option)">
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-chip-listbox hideSingleSelectionIndicator class="tt9-chip">
          <mat-chip-option *ngFor="let tagId of filtersAndSortForm?.controls.tags.value">
            <span>{{ vm.tagsMap[tagId] | uppercase }}</span>
            <accenture-icon matChipRemove (click)="removeSelectedTag(tagId)">icon-close</accenture-icon>
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
      <!-- / filter by tag -->

      <div class="default-filters-dates">
        <!-- filter by date from -->
        <mat-form-field appearance="outline" class="tt9-form-field date-form-field custom-width" floatLabel="always">
          <mat-label>Date created from</mat-label>
          <input
            matInput
            readonly
            formControlName="fromDate"
            [placeholder]="inputPlaceholders.chooseDateSecondary"
            [matDatepicker]="datePickerFrom"
            (click)="datePickerFrom.open()"
          />
          <mat-datepicker-toggle matSuffix [for]="datePickerFrom">
            <accenture-icon matDatepickerToggleIcon> icon-calendar </accenture-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #datePickerFrom></mat-datepicker>
          <accenture-icon
            matSuffix
            *ngIf="filtersAndSortForm.controls.fromDate.value"
            class="mat-select-icon"
            (click)="clearDateInput('fromDate', $event)"
          >
            icon-close
          </accenture-icon>
        </mat-form-field>
        <!-- filter by date from -->

        <!-- filter by date to -->
        <mat-form-field appearance="outline" class="tt9-form-field date-form-field custom-width" floatLabel="always">
          <mat-label>Date created to</mat-label>
          <input
            matInput
            readonly
            formControlName="toDate"
            [placeholder]="inputPlaceholders.chooseDateSecondary"
            [matDatepicker]="datePickerTo"
            (click)="datePickerTo.open()"
          />
          <mat-datepicker-toggle matSuffix [for]="datePickerTo">
            <accenture-icon matDatepickerToggleIcon> icon-calendar </accenture-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #datePickerTo></mat-datepicker>
          <accenture-icon
            matSuffix
            *ngIf="filtersAndSortForm.controls.toDate.value"
            class="mat-select-icon date-reset"
            (click)="clearDateInput('toDate', $event)"
          >
            icon-close
          </accenture-icon>
          <mat-error *ngIf="isToDateGrFromDateErrorVisibility"
            >'Date Created To' must be equal or greater than 'Date Created From'.</mat-error
          >
        </mat-form-field>
        <!-- filter by date to -->
      </div>

      <h6 class="tt9-body-medium-1">Sort</h6>

      <!-- sorting by -->
      <div>
        <mat-form-field class="tt9-form-field width-100" appearance="outline" floatLabel="always">
          <mat-label> Sort By </mat-label>
          <mat-select
            formControlName="sortOption"
            placeholder="Sort by"
            [disableOptionCentering]="true"
            [panelClass]="['tt9-select-panel-custom']"
          >
            <ng-container *ngFor="let sortOption of vm.sortOptionsValues | keyvalue">
              <mat-option [value]="sortOption.key">
                {{ sortOption.value }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- sorting by -->

      <!-- sorting order -->
      <div>
        <mat-form-field class="tt9-form-field width-100" appearance="outline" floatLabel="always">
          <mat-label> Sort Order </mat-label>
          <mat-select
            formControlName="sortOrder"
            placeholder="Sort order"
            [disableOptionCentering]="true"
            [panelClass]="['tt9-select-panel-custom']"
          >
            <ng-container *ngFor="let sortOrder of sortOrdersValues | keyvalue">
              <mat-option [value]="sortOrder.key">
                {{ sortOrder.value }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- sorting order -->
    </div>

    <!-- reset form controls values link -->
    <div class="default-filters-footer">
      <button
        class="tt9-neutral-btn"
        [disabled]="resetAllLinkDisabled"
        (click)="resetFiltersAndSortForm(vm.navigationTab)"
      >
        Reset All
      </button>
    </div>
    <!-- reset form controls values link -->
  </div>

  <accenture-side-panel
    *ngIf="vm.isAllCollectionsPanelOpened"
    cdkOverlayOrigin
    #origin="cdkOverlayOrigin"
    [panelTrigger]="origin"
    [panelTitle]="optionPanelTitle"
    [panelOpened]="vm.isAllCollectionsPanelOpened"
    [showBackArrow]="true"
    (closePanel)="toggleOptionsPanel(false)"
    (navigateBack)="navigateToFilterPanel()"
  >
    <accenture-default-options-filters-chips
      (click)="$event.stopPropagation()"
    ></accenture-default-options-filters-chips>
  </accenture-side-panel>
</ng-container>
