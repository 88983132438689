import { Timestamp } from 'firebase/firestore';

import { CollectionSortOptions } from './default-filters-and-sort';
import { SortOrders } from './sort-orders';

export const lazyLoadingSessionLimit = 20;

export enum SessionFilterSortBy {
    Name = 'name',
    Updated = 'updated',
    Created = 'created',
}

export const sessionsSortByMap = {
    [SessionFilterSortBy.Name]: 'Name',
    [SessionFilterSortBy.Created]: 'Date created',
    [SessionFilterSortBy.Updated]: 'Last updated',
};

export interface SessionFilters {
    tags?: string[];
    statuses?: string[];
    fromDate?: Timestamp;
    toDate?: Timestamp;
    startDate?: Timestamp;
    endDate?: Timestamp;
    sortBy?: SessionFilterSortBy;
    sortOrder?: SortOrders;
}

export interface SessionFilters {
    tags?: string[];
    fromDate?: Timestamp;
    toDate?: Timestamp;
    startDate?: Timestamp;
    endDate?: Timestamp;
    sortBy?: SessionFilterSortBy;
    sortOrder?: SortOrders;
    collectionsSortOption?: CollectionSortOptions;
    collectionsSortOrder?: SortOrders;
}

export const initialSessionFilters: SessionFilters = {
    tags: [],
    fromDate: null,
    toDate: null,
    startDate: null,
    endDate: null,
    sortBy: SessionFilterSortBy.Name,
    sortOrder: SortOrders.Asc,
    collectionsSortOption: CollectionSortOptions.Name,
    collectionsSortOrder: SortOrders.Asc,
};

export interface SessionQueryFilters extends SessionFilters {
    search: string;
    searchOption: string;
    globalSearch: boolean;
    lastSessionId?: string;
}
