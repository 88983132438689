import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { isUndefined } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map, skipWhile, switchMap } from 'rxjs/operators';

import { AppState, selectAuthenticatedUserId } from '@accenture/global-store';
import { Collection, notFoundUrl, ParentType, TeamMember } from '@accenture/shared/data';

import { CollectionsService } from '../services/collections.service';
import { TeamMemberService } from '../services/team-member.service';

//TO DO: Delete in clean up phase as this was already obsolete
@Injectable()
export class CollectionsGuard {
    constructor(
        private router: Router,
        private store: Store<AppState>,
        private teamMemberService: TeamMemberService,
        private collectionsService: CollectionsService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const { collectionId } = route.params;
        const { parentType } = route.data;

        return this.verifyUserAccessByParentId(parentType, collectionId);
    }

    private verifyUserAccessByParentId(parentType: ParentType, parentId: string): Observable<boolean> {
        return this.store.pipe(
            select(selectAuthenticatedUserId),
            skipWhile((userId) => isUndefined(userId)),
            switchMap((userId: string | undefined) => {
                if (!userId) {
                    return this.preventNavigation();
                }
                return this.checkIsOwner(parentType, parentId, userId);
            }),
        );
    }

    private checkIsOwner(parentType: ParentType, parentId: string, userId: string): Observable<boolean> {
        const collection$ = this.collectionsService.getCollectionById(parentId);
        return collection$.pipe(
            catchError(() => this.router.navigateByUrl(notFoundUrl)),
            switchMap((collection: Collection) => {
                if (!collection) {
                    this.router.navigateByUrl(notFoundUrl);
                    throw 'Collection not found';
                }

                if (collection.creatorId === userId) {
                    return of(true);
                }

                return this.checkTeamMember(parentType, parentId, userId);
            }),
            catchError(() => {
                return this.preventNavigation();
            }),
        );
    }

    private checkTeamMember(parentType: ParentType, parentId: string, userId: string): Observable<boolean> {
        const teamMember$ = this.teamMemberService.getTeamMemberByParentType(parentType, parentId, userId);
        return teamMember$.pipe(
            catchError(() => this.router.navigateByUrl(notFoundUrl)),
            map((teamMember: TeamMember) => {
                if (!teamMember) {
                    this.router.navigateByUrl(notFoundUrl);
                    throw 'Team Member not found';
                }

                return true;
            }),
            catchError(() => {
                return this.preventNavigation();
            }),
        );
    }

    private preventNavigation(): Observable<boolean> {
        this.router.navigateByUrl(notFoundUrl);
        return of(false);
    }
}
