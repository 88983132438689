import { Injectable } from '@angular/core';
import { FieldValue } from 'firebase/firestore';
import { groupBy, mapValues, unset } from 'lodash';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    BaseResponse,
    BrainstormResponse,
    Comment,
    DBPathHelper,
    Note,
    ParentType,
    PresentResponse,
    Response,
    SessionStatus,
    SparkAction,
    SparkResponse,
    TableResponse,
} from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import { sortByDateDesc } from '@accenture/shared/util';

import { BrainstormFilterObject, BrainstormWorldCloudFilterObject } from '../models';
import { ActivityCommentsService } from './activity-comments.service';
import { ActivityUpvoteService } from './activity-upvote.service';
import { BrainstormGroupsService } from './brainstorm-groups.service';

@Injectable({
    providedIn: 'root',
})
export class ActivityResponsesService {
    constructor(
        private firestoreService: FirestoreService,
        private activityCommentsService: ActivityCommentsService,
        private activityUpvoteService: ActivityUpvoteService,
        private brainstormGroupsService: BrainstormGroupsService,
    ) {}
 // TODO: Delete after project  deprecation
    getResponsePath(parentId: string, responseId?: string, parentType = ParentType.Projects): string {
        return `${parentType}/${parentId}/responses` + (responseId ? `/${responseId}` : '');
    }

    getResponsePathNew(parentId: string, responseId?: string, parentType = ParentType.Sessions): string {
        return `${parentType}/${parentId}/responses` + (responseId ? `/${responseId}` : '');
    }
     // TODO: Delete after project  deprecation
    deleteResponse(projectId: string, responseId: string): Promise<void> {
        return this.firestoreService.delete(this.getResponsePath(projectId, responseId));
    }

    deleteResponseNew(sessionId: string, responseId: string): Promise<void> {
        return this.firestoreService.delete(this.getResponsePathNew(sessionId, responseId));
    }

// TODO: Delete after project deprecation
    getBrainstormResponses(projectId: string, activityItemId: string): Observable<BrainstormResponse[]> {
        return this.firestoreService
            .getDocumentsByProperty<BrainstormResponse>(
                this.getResponsePath(projectId),
                'activityItemId',
                activityItemId,
                'created',
            )
            .pipe(
                map((responses: BrainstormResponse[]) =>
                    responses.map((response: BrainstormResponse) => new BrainstormResponse(response)),
                ),
            );
    }

    getBrainstormResponsesNew(sessionId: string, activityItemId: string): Observable<BrainstormResponse[]> {
        return this.firestoreService
            .getDocumentsByProperty<BrainstormResponse>(
                this.getResponsePathNew(sessionId),
                'activityItemId',
                activityItemId,
                'created',
            )
            .pipe(
                map((responses: BrainstormResponse[]) =>
                    responses.map((response: BrainstormResponse) => new BrainstormResponse(response)),
                ),
            );
    }


    getPresentResponses(projectId: string, activityItemId: string): Observable<PresentResponse[]> {
        return this.firestoreService
            .getDocumentsByProperty<PresentResponse>(
                this.getResponsePath(projectId),
                'activityItemId',
                activityItemId,
                'created',
            )
            .pipe(
                map((responses: PresentResponse[]) =>
                    responses.map((response: PresentResponse) => new PresentResponse(response)),
                ),
            );
    }

    getSlideNotes(parentType: ParentType, parentId: string, activityItemId: string): Observable<Note[]> {
        return this.firestoreService
            .getDocumentsByProperty<Note>(
                DBPathHelper.getNotePath(parentType, parentId),
                'activityItemId',
                activityItemId,
                'updated',
            )
            .pipe(map((responses: Note[]) => responses.map((response: Note) => new Note(response))));
    }
 // TODO: Delete after project  deprecation
    getIsActivityHasResponse(projectId: string, activityId: string): Observable<boolean> {
        const responsesPath = DBPathHelper.getResponsesPath(ParentType.Projects, projectId);

        return this.firestoreService.getDocumentsExist(responsesPath, new Map([['activityId', activityId]]));
    }

    getIsActivityHasResponseNew(sessionId: string, activityId: string): Observable<boolean> {
        const responsesPath = DBPathHelper.getResponsesPath(ParentType.Sessions, sessionId);

        return this.firestoreService.getDocumentsExist(responsesPath, new Map([['activityId', activityId]]));
    }

    getIsUserHasResponse(projectId: string, activityId: string, userId: string): Observable<boolean> {
        const responsesPath = DBPathHelper.getResponsesPath(ParentType.Projects, projectId);

        return this.firestoreService.getDocumentsExist(
            responsesPath,
            new Map([
                ['activityId', activityId],
                ['userId', userId],
            ]),
        );
    }
 // TODO: Delete after project  deprecation
    getIsUserHasResponseNew(sessionId: string, activityId: string, userId: string): Observable<boolean> {
        const responsesPath = DBPathHelper.getResponsesPath(ParentType.Sessions, sessionId);

        return this.firestoreService.getDocumentsExist(
            responsesPath,
            new Map([
                ['activityId', activityId],
                ['userId', userId],
            ]),
        );
    }

    // TODO: Delete after project deprecation
    getIfActivityHasResponse(projectId: string, activityId: string): Observable<boolean> {
        const responsesPath = DBPathHelper.getResponsesPath(ParentType.Projects, projectId);

        return this.firestoreService.getDocumentsExist(responsesPath, new Map([['activityId', activityId]]));
    }

    getIfActivityHasResponseNew(sessionId: string, activityId: string): Observable<boolean> {
        const responsesPath = DBPathHelper.getResponsesPath(ParentType.Sessions, sessionId);

        return this.firestoreService.getDocumentsExist(responsesPath, new Map([['activityId', activityId]]));
    }

    getIfSessionActivityHasResponse(sessionId: string, activityId: string): Observable<boolean> {
        const responsesPath = DBPathHelper.getResponsesPath(ParentType.Sessions, sessionId);

        return this.firestoreService.getDocumentsExist(responsesPath, new Map([['activityId', activityId]]));
    }

    writeNote(parentType: ParentType, parentId: string, note: Note): Promise<string> {
        return this.firestoreService.addDocument(`${parentType}/${parentId}/notes`, note.createSerializableObject());
    }

    updateNote(parentType: ParentType, parentId: string, noteId: string, value: string): Promise<void> {
        return this.firestoreService.updateDoc<Note>(`${parentType}/${parentId}/notes/${noteId}`, {
            value,
        } as Note);
    }

    deleteNote(parentType: ParentType, parentId: string, noteId: string): Promise<void> {
        return this.firestoreService.delete(`${parentType}/${parentId}/notes/${noteId}`);
    }
    // TODO: Delete after project  deprecation
    getTableResponseHistory(
        projectId: string,
        activityItemId: string,
        rowId: string,
        columnId: string,
    ): Observable<TableResponse[]> {
        return this.firestoreService
            .getDocumentsByMultiplePropertiesWithoutCaching<TableResponse>(
                this.getResponsePath(projectId),
                new Map<string, any>([
                    ['activityItemId', activityItemId],
                    ['rowId', rowId],
                    ['columnId', columnId],
                ]),
                'created',
            )
            .pipe(map((responses) => responses.map((response) => new TableResponse(response))));
    }

    getTableResponseHistoryNew(
        sessionId: string,
        activityItemId: string,
        rowId: string,
        columnId: string,
    ): Observable<TableResponse[]> {
        return this.firestoreService
            .getDocumentsByMultiplePropertiesWithoutCaching<TableResponse>(
                this.getResponsePathNew(sessionId),
                new Map<string, any>([
                    ['activityItemId', activityItemId],
                    ['rowId', rowId],
                    ['columnId', columnId],
                ]),
                'created',
            )
            .pipe(map((responses) => responses.map((response) => new TableResponse(response))));
    }

    getTableNotCurrentResponsesByActivityId(
        projectId: string,
        activityId: string,
        parentType: ParentType = ParentType.Projects,
    ): Observable<TableResponse[]> {
        return this.firestoreService
            .getDocumentsByMultiplePropertiesWithoutCaching<TableResponse>(
                this.getResponsePath(projectId, '', parentType),
                new Map<string, any>([['activityId', activityId]]),
            )
            .pipe(
                map((responses) =>
                    responses.map((response) => new TableResponse(response)).filter((response) => !response.current),
                ),
            );
    }

    getTableNotCurrentResponsesByActivityIdNew(
        sessionId: string,
        activityId: string,
        parentType: ParentType = ParentType.Sessions,
    ): Observable<TableResponse[]> {
        return this.firestoreService
            .getDocumentsByMultiplePropertiesWithoutCaching<TableResponse>(
                this.getResponsePathNew(sessionId, '', parentType),
                new Map<string, any>([['activityId', activityId]]),
            )
            .pipe(
                map((responses) =>
                    responses.map((response) => new TableResponse(response)).filter((response) => !response.current),
                ),
            );
    }

    getActivityResponses<T extends Response>(projectId: string, activityId: string): Observable<T[]> {
        return this.firestoreService.getDocumentsByProperty(this.getResponsePath(projectId), 'activityId', activityId);
    }

    getActivityResponsesNew<T extends Response>(sessionId: string, activityId: string): Observable<T[]> {
        return this.firestoreService.getDocumentsByProperty(
            this.getResponsePathNew(sessionId),
            'activityId',
            activityId,
        );
    }

    getActivityResponsesByFieldId<T extends BaseResponse>(
        parentId: string,
        activityId: string,
        fieldName: string,
        fieldId: string,
        parentType = ParentType.Projects,
    ): Observable<T[]> {
        return this.firestoreService.getDocumentsByMultiplePropertiesWithoutCaching<T>(
            this.getResponsePath(parentId, undefined, parentType),
            new Map([
                ['activityId', activityId],
                [fieldName, fieldId],
            ]),
        );
    }

    getActivityResponsesByFieldIdNew<T extends BaseResponse>(
        parentId: string,
        activityId: string,
        fieldName: string,
        fieldId: string,
        parentType = ParentType.Sessions,
    ): Observable<T[]> {
        return this.firestoreService.getDocumentsByMultiplePropertiesWithoutCaching<T>(
            this.getResponsePathNew(parentId, undefined, parentType),
            new Map([
                ['activityId', activityId],
                [fieldName, fieldId],
            ]),
        );
    }

 // TODO: Delete after project  deprecation
    getResponsesByActivityItemId<T extends Response>(projectId: string, activityItemId: string): Observable<T[]> {
        return this.firestoreService.getDocumentsByPropertyWithoutCaching(
            this.getResponsePath(projectId),
            'activityItemId',
            activityItemId,
        );
    }

    getResponsesByActivityItemIdNew<T extends Response>(sessionId: string, activityItemId: string): Observable<T[]> {
        return this.firestoreService.getDocumentsByPropertyWithoutCaching(
            this.getResponsePathNew(sessionId),
            'activityItemId',
            activityItemId,
        );
    }
 // TODO: Delete after project  deprecation
    getActivityResponsesByUser<T extends Response>(
        projectId: string,
        activityId: string,
        userId: string,
    ): Observable<T[]> {
        return this.firestoreService.getDocumentsByMultiplePropertiesWithoutCaching(
            this.getResponsePath(projectId),
            new Map([
                ['activityId', activityId],
                ['userId', userId],
            ]),
        );
    }

    getActivityResponsesByUserNew<T extends Response>(
        sessionId: string,
        activityId: string,
        userId: string,
    ): Observable<T[]> {
        return this.firestoreService.getDocumentsByMultiplePropertiesWithoutCaching(
            this.getResponsePathNew(sessionId),
            new Map([
                ['activityId', activityId],
                ['userId', userId],
            ]),
        );
    }
 // TODO: Delete after project  deprecation
    updateBookmark(projectId: string, responseId: string, bookmarkActive: boolean): Promise<void> {
        return this.firestoreService.updateDoc<BrainstormResponse>(this.getResponsePath(projectId, responseId), {
            bookmarkActive,
        } as BrainstormResponse);
    }


    updateBookmarkNew(sessionId: string, responseId: string, bookmarkActive: boolean): Promise<void> {
        return this.firestoreService.updateDoc<BrainstormResponse>(this.getResponsePathNew(sessionId, responseId), {
            bookmarkActive,
        } as BrainstormResponse);
    }
 // TODO: Delete after project  deprecation
    updateResponseValue(projectId: string, responseId: string, value: unknown): Promise<void> {
        return this.firestoreService.updateDoc<Response>(this.getResponsePath(projectId, responseId), {
            value,
        } as Response);
    }

    updateResponseValueNew(sessionId: string, responseId: string, value: unknown): Promise<void> {
        return this.firestoreService.updateDoc<Response>(this.getResponsePathNew(sessionId, responseId), {
            value,
        } as Response);
    }

    updateSparkActions(projectId: string, responseId: string, action: SparkAction | FieldValue): Promise<void> {
        return this.firestoreService.updateDoc<Response>(this.getResponsePath(projectId, responseId), {
            action,
        } as SparkResponse);
    }
 // TODO: Delete after project  deprecation
    updateResponseInDatabase(
        parentId: string,
        responseUpdate: Partial<BaseResponse>,
        parentType = ParentType.Projects,
    ): Promise<void> {
        return this.firestoreService.update<Partial<BaseResponse>>(
            this.getResponsePath(parentId, responseUpdate.id, parentType),
            {
                ...responseUpdate,
            },
        );
    }

    updateResponseInDatabaseNew(
        parentId: string,
        responseUpdate: Partial<BaseResponse>,
        parentType = ParentType.Sessions,
    ): Promise<void> {
        return this.firestoreService.update<Partial<BaseResponse>>(
            this.getResponsePathNew(parentId, responseUpdate.id, parentType),
            {
                ...responseUpdate,
            },
        );
    }
 // TODO: Delete after project  deprecation
    async writeResponse(
        parentId: string,
        sessionId: string,
        response: Response,
        updateSessionStatus?: boolean,
        parentType = ParentType.Projects,
    ): Promise<string> {
        const id = await this.firestoreService.addDocument(
            this.getResponsePath(parentId, undefined, parentType),
            response.createSerializableObject(),
        );
        if (updateSessionStatus) {
            this.setSessionStatus(parentId, sessionId);
        }
        return id;
    }

    async writeResponseNew(
        sessionId: string,
        response: Response,
        updateSessionStatus?: boolean,
        parentType = ParentType.Sessions,
    ): Promise<string> {
        const id = await this.firestoreService.addDocument(
            this.getResponsePathNew(sessionId, undefined, parentType),
            response.createSerializableObject(),
        );
        if (updateSessionStatus) {
            this.setSessionStatusNew(sessionId);
        }
        return id;
    }

    getCurrentUserBrainstormResponsesFilters(
        userId: string | undefined,
        topicId: string | undefined,
    ): Observable<BrainstormFilterObject> {
        const path = `/users/${userId}/filters/brainstormResponsesFilters/topicFilters/${topicId}`;
        return this.firestoreService.getDocument<BrainstormFilterObject>(path).pipe(
            map((filter: BrainstormFilterObject) => {
                unset(filter, 'id');
                return filter;
            }),
        );
    }

    async updateBrainstormResponsesFilters(
        userId: string | undefined,
        topicId: string,
        data: BrainstormFilterObject,
    ): Promise<void> {
        const path = `/users/${userId}/filters/brainstormResponsesFilters/topicFilters/${topicId}`;
        await this.firestoreService.upsert(path, data);
    }

    getCurrentUserBrainstormWorldCloudFilters(
        userId: string | undefined,
        topicId: string | undefined,
    ): Observable<BrainstormWorldCloudFilterObject> {
        const path = `/users/${userId}/filters/brainstormWorldCloud/topicFilters/${topicId}`;
        return this.firestoreService.getDocument<BrainstormWorldCloudFilterObject>(path).pipe(
            map((filter: BrainstormWorldCloudFilterObject) => {
                unset(filter, 'id');
                return filter;
            }),
        );
    }

    async updateSourceWorldCloudFilters(
        userId: string | undefined,
        topicId: string,
        data: BrainstormWorldCloudFilterObject,
    ): Promise<void> {
        const path = `/users/${userId}/filters/sourceWorldCloud/topicFilters/${topicId}`;
        await this.firestoreService.upsert(path, data);
    }

    async updateBrainstormWorldCloudFilters(
        userId: string | undefined,
        topicId: string,
        data: BrainstormWorldCloudFilterObject,
    ): Promise<void> {
        const path = `/users/${userId}/filters/brainstormWorldCloud/topicFilters/${topicId}`;
        await this.firestoreService.upsert(path, data);
    }

    async createResponses(
        parentId: string,
        responsesToBeCreated: Partial<TableResponse>[],
        parentType = ParentType.Projects,
    ): Promise<void> {
        const batchData = responsesToBeCreated.map((response) => ({
            path: DBPathHelper.getResponsesPath(parentType, parentId, this.firestoreService.getPushId()),
            data: {
                ...response,
                created: this.firestoreService.timestamp,
                updated: this.firestoreService.timestamp,
            },
        }));
        await this.firestoreService.writeBatch(batchData);
    }

      async createResponsesNew(
        parentId: string,
        responsesToBeCreated: Partial<TableResponse>[],
        parentType = ParentType.Sessions,
    ): Promise<void> {
        const batchData = responsesToBeCreated.map((response) => ({
            path: DBPathHelper.getResponsesPath(parentType, parentId, this.firestoreService.getPushId()),
            data: {
                ...response,
                created: this.firestoreService.timestamp,
                updated: this.firestoreService.timestamp,
            },
        }));
        await this.firestoreService.writeBatch(batchData);
    }

 // TODO: Delete after project  deprecation
    async setBatchResponses<T>(
        responses: { path: string; data: T }[],
        projectId?: string,
        sessionId?: string,
        shouldSetSessionStatus?: boolean,
    ): Promise<void> {
        await this.firestoreService.writeBatch(responses);

        if (shouldSetSessionStatus) {
            this.setSessionStatus(projectId, sessionId);
        }
    }

    async setBatchResponsesNew<T>(
        responses: { path: string; data: T }[],
        sessionId?: string,
        shouldSetSessionStatus?: boolean,
    ): Promise<void> {
        await this.firestoreService.writeBatch(responses);

        if (shouldSetSessionStatus) {
            this.setSessionStatusNew(sessionId);
        }
    }

    async decrementFilesCount(fileId: string): Promise<void> {
        await this.firestoreService.update(`/filesRefs/${fileId}`, {
            count: this.firestoreService.changeCounterValue(-1),
        });
    }
 // TODO: Delete after project  deprecation
    async mergeResponses(
        responseId: string,
        selectedQuestionId: string,
        projectId: string,
        responsesSetToMerge: Set<string>,
    ): Promise<void> {
        // get all needed data
        const topicResponses
            = (await firstValueFrom(
                this.getResponsesByActivityItemId<BrainstormResponse>(projectId, selectedQuestionId),
            )) || [];

        const responsesToMerge = mapValues(groupBy(topicResponses, 'id'), (values) => values[0]);
        const response = responsesToMerge[responseId];
        if (!response) {
            console.error('No response target');
            return;
        }

        const topicComments
            = (await firstValueFrom(
                this.activityCommentsService.getCommentsByActivityItemIdWithoutCaching(projectId, selectedQuestionId),
            )) || [];
        const commentsToMerge = groupBy(topicComments, 'responseId');

        const transformedData = [];
        const dataToDelete = [];
        const groupsToUpdate = {};
        // check count of all the new comments data to write
        let commentsCount = 0;

        // transform responses to comments
        responsesSetToMerge.forEach((responseToMergeId) => {
            const response = responsesToMerge[responseToMergeId] || ({} as BrainstormResponse);
            const responseToMerge = new BrainstormResponse(response);
            const data = {
                responseId,
                updated: this.firestoreService.currentTimestamp,
                created: this.firestoreService.currentTimestamp,
                activityId: responseToMerge.activityId,
                value: responseToMerge.value,
                userId: responseToMerge.userId,
                userDisplayName: responseToMerge.userDisplayName,
                userImageUrl: responseToMerge.userImageUrl,
                activityItemId: selectedQuestionId,
                sessionId: responseToMerge.sessionId,
            } as Comment;

            // write response to comments collection
            const dataToWrite = {
                data,
                path: DBPathHelper.getCommentPath(ParentType.Projects, projectId, responseToMerge.id),
            };
            transformedData.push(dataToWrite);
            commentsCount++;

            // need for deleting initial responses
            const pathToDelete = DBPathHelper.getResponsesPath(ParentType.Projects, projectId, responseToMerge.id);
            dataToDelete.push({ path: pathToDelete });

            // update groups counter when groups assigned to moved response
            if (responseToMerge.groups?.length) {
                responseToMerge.groups.forEach((groupId) => {
                    const count = groupsToUpdate[groupId] || 0;
                    groupsToUpdate[groupId] = count - 1;
                });
            }

            // transform comments
            // comments will be replaced with new data (no need to delete)
            const responseCommentsToMerge = commentsToMerge[responseToMergeId]?.sort(sortByDateDesc()) || [];
            responseCommentsToMerge.forEach((comment: Comment) => {
                const data = {
                    ...comment,
                    responseId,
                    created: this.firestoreService.currentTimestamp,
                    updated: this.firestoreService.currentTimestamp,
                    activityItemId: selectedQuestionId,
                } as Comment;
                const dataToWrite = {
                    data,
                    path: DBPathHelper.getCommentPath(ParentType.Projects, projectId, comment.id),
                };

                transformedData.push(dataToWrite);
                commentsCount++;
            });
        });

        // write new comments
        if (transformedData.length) {
            await this.firestoreService.setBatch(transformedData);
        }

        // create likes for source response
        await this.updateLikesForBrainstormResponse(projectId, responseId, selectedQuestionId, responsesSetToMerge);

        // delete moved responses
        if (dataToDelete.length) {
            await this.firestoreService.deleteBatch(dataToDelete);
        }

        if (commentsCount > 0) {
            await this.firestoreService.updateDoc<BrainstormResponse>(
                DBPathHelper.getResponsesPath(ParentType.Projects, projectId, responseId),
                {
                    commentsCount: this.firestoreService.changeCounterValue(commentsCount) as any,
                },
            );
        }

        if (Object.keys(groupsToUpdate).length > 0) {
            await this.brainstormGroupsService.updateGroupsCount(projectId, response.activityItemId, groupsToUpdate);
        }
    }


    async mergeResponsesNew(
        responseId: string,
        selectedQuestionId: string,
        sessionId: string,
        responsesSetToMerge: Set<string>,
    ): Promise<void> {
        // get all needed data
        const topicResponses
            = (await firstValueFrom(
                this.getResponsesByActivityItemIdNew<BrainstormResponse>(sessionId, selectedQuestionId),
            )) || [];

        const responsesToMerge = mapValues(groupBy(topicResponses, 'id'), values => values[0]);
        const response = responsesToMerge[responseId];
        if (!response) {
            console.error('No response target');
            return;
        }

        const topicComments
            = (await firstValueFrom(
                this.activityCommentsService.getCommentsByActivityItemIdWithoutCachingNew(sessionId, selectedQuestionId),
            )) || [];
        const commentsToMerge = groupBy(topicComments, 'responseId');

        const transformedData = [];
        const dataToDelete = [];
        const groupsToUpdate = {};
        // check count of all the new comments data to write
        let commentsCount = 0;

        // transform responses to comments
        responsesSetToMerge.forEach(responseToMergeId => {
            const response = responsesToMerge[responseToMergeId] || ({} as BrainstormResponse);
            const responseToMerge = new BrainstormResponse(response);
            const data = {
                responseId,
                updated: this.firestoreService.currentTimestamp,
                created: this.firestoreService.currentTimestamp,
                activityId: responseToMerge.activityId,
                value: responseToMerge.value,
                userId: responseToMerge.userId,
                userDisplayName: responseToMerge.userDisplayName,
                userImageUrl: responseToMerge.userImageUrl,
                activityItemId: selectedQuestionId,
                sessionId: responseToMerge.sessionId,
            } as Comment;

            // write response to comments collection
            const dataToWrite = {
                data,
                path: DBPathHelper.getCommentPath(ParentType.Sessions, sessionId, responseToMerge.id),
            };
            transformedData.push(dataToWrite);
            commentsCount++;

            // need for deleting initial responses
            const pathToDelete = DBPathHelper.getResponsesPath(ParentType.Sessions, sessionId, responseToMerge.id);
            dataToDelete.push({ path: pathToDelete });

            // update groups counter when groups assigned to moved response
            if (responseToMerge.groups?.length) {
                responseToMerge.groups.forEach(groupId => {
                    const count = groupsToUpdate[groupId] || 0;
                    groupsToUpdate[groupId] = count - 1;
                });
            }

            // transform comments
            // comments will be replaced with new data (no need to delete)
            const responseCommentsToMerge = commentsToMerge[responseToMergeId]?.sort(sortByDateDesc()) || [];
            responseCommentsToMerge.forEach((comment: Comment) => {
                const data = {
                    ...comment,
                    responseId,
                    created: this.firestoreService.currentTimestamp,
                    updated: this.firestoreService.currentTimestamp,
                    activityItemId: selectedQuestionId,
                } as Comment;
                const dataToWrite = {
                    data,
                    path: DBPathHelper.getCommentPath(ParentType.Sessions, sessionId, comment.id),
                };

                transformedData.push(dataToWrite);
                commentsCount++;
            });
        });

        // write new comments
        if (transformedData.length) {
            await this.firestoreService.setBatch(transformedData);
        }

        // create likes for source response
        await this.updateLikesForBrainstormResponseNew(sessionId, responseId, selectedQuestionId, responsesSetToMerge);

        // delete moved responses
        if (dataToDelete.length) {
            await this.firestoreService.deleteBatch(dataToDelete);
        }

        if (commentsCount > 0) {
            await this.firestoreService.updateDoc<BrainstormResponse>(
                DBPathHelper.getResponsesPath(ParentType.Sessions, sessionId, responseId),
                {
                    commentsCount: this.firestoreService.changeCounterValue(commentsCount) as any,
                },
            );
        }

        if (Object.keys(groupsToUpdate).length > 0) {
            await this.brainstormGroupsService.updateGroupsCountNew(sessionId, response.activityItemId, groupsToUpdate);
        }
    }
 // TODO: Delete after project  deprecation
    async moveResponses(
        projectId: string,
        responsesIds: string[],
        moveComments: boolean,
        selectedQuestionId: string,
        topicIdToMove: string,
    ): Promise<void> {
        await this.firestoreService.cloudFunctionCallable('moveBrainstormResponses', {
            projectId,
            responsesIds,
            moveComments,
            fromTopicId: selectedQuestionId,
            toTopicId: topicIdToMove,
        });
    }


    async moveResponsesNew(
        sessionId: string,
        responsesIds: string[],
        moveComments: boolean,
        selectedQuestionId: string,
        topicIdToMove: string,
    ): Promise<void> {
        await this.firestoreService.cloudFunctionCallable('moveBrainstormResponsesNew', {
            sessionId,
            responsesIds,
            moveComments,
            fromTopicId: selectedQuestionId,
            toTopicId: topicIdToMove,
        });
    }
 // TODO: Delete after project  deprecation
    async deleteBrainstormResponses(
        projectId: string,
        ids: string[],
        responses: BrainstormResponse[],
        activityItemId: string,
    ): Promise<void> {
        const responsesMap = groupBy(responses, 'id');
        const deleteData = [];
        let groupsToUpdate = {};

        ids.forEach((id) => {
            const currentsGroupsIds = responsesMap[id][0]?.groups;

            deleteData.push({ path: this.getResponsePath(projectId, id) });
            if (currentsGroupsIds.length) {
                currentsGroupsIds.forEach((groupId) => {
                    groupsToUpdate = {
                        ...groupsToUpdate,
                        [groupId]: groupsToUpdate[groupId] - 1 || -1,
                    };
                });
            }
        });

        await this.brainstormGroupsService.updateGroupsCount(projectId, activityItemId, groupsToUpdate);
        await this.firestoreService.deleteBatch(deleteData);
    }


    async deleteBrainstormResponsesNew(
        sessionId: string,
        ids: string[],
        responses: BrainstormResponse[],
        activityItemId: string,
    ): Promise<void> {
        const responsesMap = groupBy(responses, 'id');
        const deleteData = [];
        let groupsToUpdate = {};

        ids.forEach(id => {
            const currentsGroupsIds = responsesMap[id][0]?.groups;

            deleteData.push({ path: this.getResponsePathNew(sessionId, id) });
            if (currentsGroupsIds.length) {
                currentsGroupsIds.forEach(groupId => {
                    groupsToUpdate = {
                        ...groupsToUpdate,
                        [groupId]: groupsToUpdate[groupId] - 1 || -1,
                    };
                });
            }
        });

        await this.brainstormGroupsService.updateGroupsCountNew(sessionId, activityItemId, groupsToUpdate);
        await this.firestoreService.deleteBatch(deleteData);
    }
 // TODO: Delete after project  deprecation
    private async setSessionStatus(projectId: string, sessionId: string): Promise<void> {
        const sessionPath = DBPathHelper.getSessionPath(ParentType.Projects, projectId, sessionId);
        await this.firestoreService.update(sessionPath, { status: SessionStatus.Active });
    }

    private async setSessionStatusNew(sessionId: string): Promise<void> {
        const sessionPathNew = DBPathHelper.getSessionPathNew(ParentType.Sessions, sessionId);
        await this.firestoreService.update(sessionPathNew, { status: SessionStatus.Active });
    }
 // TODO: Delete after project  deprecation
    private async updateLikesForBrainstormResponse(
        projectId: string,
        responseId: string,
        selectedQuestionId: string,
        responsesSetToMerge: Set<string>,
    ): Promise<void> {
        const allLikes
            = (await firstValueFrom(
                this.activityUpvoteService.getUpvotesForActivityItem(projectId, selectedQuestionId),
            )) || [];
        const responseLikes = allLikes.filter((like) => responseId === like.responseId);
        const responsesSetToMergeLikes = allLikes.filter((like) => responsesSetToMerge.has(like.responseId));

        const transformedLikesData = [];
        const groupedLikes = mapValues(groupBy(responseLikes, 'userId'));

        responsesSetToMergeLikes.forEach((like) => {
            // if no like from the same user - need to write + 1 like
            const isUpvoteExist = !!groupedLikes?.[like?.userId];
            if (isUpvoteExist) {
                return;
            }

            // build new likes
            const path = DBPathHelper.getUpvotePath(ParentType.Projects, projectId, `${responseId}_${like.userId}`);
            const data = {
                projectId,
                responseId,
                userId: like.userId,
                activityItemId: selectedQuestionId,
            };
            transformedLikesData.push({ path, data });
            groupedLikes[like.userId] = [like];
        });

        if (transformedLikesData.length > 0) {
            await this.firestoreService.setBatch(transformedLikesData);

            await this.firestoreService.updateDoc(
                DBPathHelper.getResponsesPath(ParentType.Projects, projectId, responseId),
                {
                    upvotesCount: this.firestoreService.changeCounterValue(transformedLikesData.length),
                },
            );
        }
    }

    private async updateLikesForBrainstormResponseNew(
        sessionId: string,
        responseId: string,
        selectedQuestionId: string,
        responsesSetToMerge: Set<string>,
    ): Promise<void> {
        const allLikes
            = (await firstValueFrom(
                this.activityUpvoteService.getUpvotesForActivityItemNew(sessionId, selectedQuestionId),
            )) || [];
        const responseLikes = allLikes.filter(like => responseId === like.responseId);
        const responsesSetToMergeLikes = allLikes.filter(like => responsesSetToMerge.has(like.responseId));

        const transformedLikesData = [];
        const groupedLikes = mapValues(groupBy(responseLikes, 'userId'));

        responsesSetToMergeLikes.forEach(like => {
            // if no like from the same user - need to write + 1 like
            const isUpvoteExist = !!groupedLikes?.[like?.userId];
            if (isUpvoteExist) {
                return;
            }

            // build new likes
            const path = DBPathHelper.getUpvotePath(ParentType.Sessions, sessionId, `${responseId}_${like.userId}`);
            const data = {
                sessionId,
                responseId,
                userId: like.userId,
                activityItemId: selectedQuestionId,
            };
            transformedLikesData.push({ path, data });
            groupedLikes[like.userId] = [like];
        });

        if (transformedLikesData.length > 0) {
            await this.firestoreService.setBatch(transformedLikesData);

            await this.firestoreService.updateDoc(
                DBPathHelper.getResponsesPath(ParentType.Sessions, sessionId, responseId),
                {
                    upvotesCount: this.firestoreService.changeCounterValue(transformedLikesData.length),
                },
            );
        }
    }
}
