import { FieldValue, Timestamp } from 'firebase/firestore';

import { AccessControlRole } from './access-control';
import { ActivityType } from './activities';
import { Dictionary } from './dictionary';
import { NodeTimestamp } from './node-timestamp';
import { ParentType } from './parent-type';
import { TemplateStatus } from './template';
import { UserAccessSession } from './user-access';

export interface UserTemplate extends NodeTimestamp {
    id?: string;
    userId?: string;
    templateId?: string;
    templateType?: ParentType;
    sessionId?: string;
    imageUrl?: string;
    name?: string;
    description?: string;
    role: AccessControlRole;
    sessions?: Dictionary<UserAccessSession>;
    tags?: Dictionary<string>;
    clients?: Dictionary<string>;
    phases?: Dictionary<string>;
    subPhases?: Dictionary<string>;
    likes?: Dictionary<boolean>;
    hidden?: boolean;
    activityType?: ActivityType;
    metadata?: Dictionary<string>;
    status?: TemplateStatus;
    creatorId?: string;
    creatorImage?: string;
    creatorName?: string;
    ownerDisplayName?: string;
    ownerImageUrl?: string;
    lastViewed?: Timestamp | FieldValue;
    ownerId?: string;
    sequence?: string;
    collectionId?: string;
    color?: string;
    markedForDelete?: boolean;

    // temporary for release 1
    // TODO: remove
    public?: boolean;
}
