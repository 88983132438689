import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { isEqual } from 'lodash';
import { catchError, combineLatest, distinctUntilChanged, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ActivityConfiguration, Dictionary, FeatureToggles, MaintenanceConfig } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

import * as applicationConfigurationActions from './application-configuration.actions';

@Injectable()
export class ApplicationConfigurationEffects {
    getApplicationConfiguration = createEffect(() =>
        this.actions$.pipe(
            ofType(applicationConfigurationActions.getApplicationConfiguration),
            switchMap(() =>
                combineLatest([
                    this.getActivityConfigurations$(),
                    this.getFeatureToggles$(),
                    this.getTimeouts$(),
                    this.getMaintenanceConfig$(),
                ]).pipe(
                    map(([activityConfigurations, featureToggles, timeouts, maintenanceConfig]) =>
                        applicationConfigurationActions.getApplicationConfigurationSuccess({
                            activityConfigurations,
                            featureToggles,
                            timeouts,
                            maintenanceConfig,
                        }),
                    ),
                    catchError(error =>
                        of(applicationConfigurationActions.getApplicationConfigurationError({ error })),
                    ),
                ),
            ),
        ),
    );

    constructor(private actions$: Actions, private firestoreService: FirestoreService) {}

    private getActivityConfigurations$(): Observable<ActivityConfiguration[]> {
        return this.firestoreService
            .getDocumentsByProperty<ActivityConfiguration>('activityConfigurations', 'visible', true, 'sequence')
            .pipe(distinctUntilChanged((previous, current) => isEqual(previous, current)));
    }

    private getFeatureToggles$(): Observable<FeatureToggles> {
        return this.firestoreService
            .getDocumentWithoutId<FeatureToggles>('configuration/FEATURE_TOGGLES')
            .pipe(distinctUntilChanged((previous, current) => isEqual(previous, current)));
    }

    private getTimeouts$(): Observable<Dictionary<number>> {
        return this.firestoreService
            .getDocumentWithoutId<Dictionary<number>>('configuration/TIMEOUTS')
            .pipe(distinctUntilChanged((previous, current) => isEqual(previous, current)));
    }

    private getMaintenanceConfig$(): Observable<MaintenanceConfig> {
        return this.firestoreService
            .getDocumentWithoutId<MaintenanceConfig>('configuration/MAINTENANCE')
            .pipe(distinctUntilChanged((previous, current) => isEqual(previous, current)));
    }
}
