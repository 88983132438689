import { Injectable } from '@angular/core';
import { Timestamp } from 'firebase/firestore';
import { isNull, isUndefined, omitBy, unset } from 'lodash';
import { BehaviorSubject, firstValueFrom, Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import {
    Activity,
    CollectionRole,
    CollectionSession,
    DateTransformation,
    DBPathHelper,
    DefaultFilterObject,
    DuplicateType,
    ExportType,
    FileType,
    initialDefaultFilterObject,
    initialSessionFilters,
    initialUserSessionFilters,
    ParentType,
    Project,
    Session,
    SessionFilters,
    SessionQueryFilters,
    SessionRole,
    SessionStatus,
    SessionSummary,
    SessionSummaryExportData,
    UserCollection,
    UserSession,
    UserSessionFilters,
} from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import {
    getTimestampFromMilliseconds,
    removeEmptyKeys,
    sortAlphabetically,
    sortBySequenceAsc,
} from '@accenture/shared/util';

import { CollectionsService } from './collections.service';
import { UserTemplateService } from './user-template.service';

@Injectable({
    providedIn: 'root',
})
export class SessionService {
    private deletingSessionIds = new BehaviorSubject<string[]>([]);
    readonly deletingSessionIds$ = this.deletingSessionIds.asObservable();

    constructor(
        private firestoreService: FirestoreService,
        private collectionsService: CollectionsService,
        private userTemplateService: UserTemplateService,
    ) {}

    updateDeletingSessionsIds(sessionId: string): void {
        this.deletingSessionIds.next([...this.deletingSessionIds.getValue(), sessionId]);
    }

    removeSessionIdFromDeletingSessionsIds(removedSessionId: string): void {
        this.deletingSessionIds.next(
            this.deletingSessionIds.getValue().filter((sessionId) => sessionId !== removedSessionId),
        );
    }

    // TODO: Delete after project deprecation
    getSessionPath(parentType: ParentType, parentId: string, sessionId?: string): string {
        return [ParentType.Templates, ParentType.PublicSessionTemplates].includes(parentType)
            ? DBPathHelper.getParentPath(parentType, sessionId)
            : DBPathHelper.getSessionPath(parentType, parentId, sessionId);
    }

    getSessionPathNew(parentType: ParentType, sessionId?: string): string {
        return [ParentType.Templates, ParentType.PublicSessionTemplates].includes(parentType)
            ? DBPathHelper.getParentPath(parentType, sessionId)
            : DBPathHelper.getSessionPathNew(parentType, sessionId);
    }

    // TODO: Delete after project deprecation
    async deleteSession(parentType: ParentType, parentId?: string, sessionId?: string): Promise<void> {
        if (parentType === ParentType.Templates) {
            await this.firestoreService.cloudFunctionCallable('deleteSessionTemplate', { templateId: sessionId });
        } else {
            const softDelete = ![ParentType.ProjectTemplates, ParentType.PublicProjectTemplates].includes(parentType);
            await this.firestoreService.cloudFunctionCallable('deleteSession', {
                sessionId,
                parentType,
                parentId,
                softDelete,
            });
        }
    }

    async deleteSessionNew(parentType: ParentType, parentId: string, collectionId?: string): Promise<void> {
        if (parentType === ParentType.Templates) {
            await this.firestoreService.cloudFunctionCallable('deleteSessionTemplate', { templateId: parentId });
        } else {
            const softDelete = ![ParentType.ProjectTemplates, ParentType.PublicProjectTemplates].includes(parentType);
            await this.firestoreService.cloudFunctionCallable('deleteSession', {
                parentId,
                parentType,
                collectionId,
                softDelete,
            });
        }
    }

    // TODO: Delete after project  deprecation
    async downloadAnExportReport(
        sessionId: string,
        projectId: string,
        env: string,
        timezone: number,
    ): Promise<FileType> {
        return await this.firestoreService.cloudFunctionCallable('exportData', {
            sessionId,
            projectId,
            env,
            timezone,
            parentType: ParentType.Sessions,
            exportType: ExportType.SessionReport,
        });
    }

    async downloadAnExportReportNew(
        sessionId: string,
        env: string,
        timezone: number,
        collectionId?: string,
    ): Promise<FileType> {
        return await this.firestoreService.cloudFunctionCallable('exportDataTt9', {
            sessionId,
            collectionId,
            env,
            timezone,
            parentType: ParentType.Sessions,
            exportType: ExportType.SessionReport,
        });
    }

    // TODO: Delete in TT9-7615
    async downloadExportSessionReport({
        isAllRecordsSelected,
        sessionUserId,
        sessionIdsSelectedForExport,
        timezone,
    }: SessionSummaryExportData): Promise<FileType> {
        return await this.firestoreService.cloudFunctionCallable('exportData', {
            isAllRecordsSelected,
            sessionUserId,
            sessionIdsSelectedForExport,
            timezone,
            parentType: ParentType.Sessions,
            exportType: ExportType.SessionSummaryReport,
        });
    }

    async downloadExportSessionReportNew({
        isAllRecordsSelected,
        sessionUserId,
        sessionIdsSelectedForExport,
        timezone,
    }: SessionSummaryExportData): Promise<FileType> {
        return await this.firestoreService.cloudFunctionCallable('exportDataTt9', {
            isAllRecordsSelected,
            sessionUserId,
            sessionIdsSelectedForExport,
            timezone,
            parentType: ParentType.Sessions,
            exportType: ExportType.SessionSummaryReport,
        });
    }

    getSession(parentType: ParentType, parentId: string, sessionId: string): Observable<Session> {
        return this.firestoreService.getDocument<Session>(this.getSessionPath(parentType, parentId, sessionId)).pipe(
            map((data: Partial<Session>) => this.constructSession(data)),
            catchError(() => of(null)),
        );
    }

    getSessionNew(parentType: ParentType, sessionId: string): Observable<Session> {
        return this.firestoreService.getDocument<Session>(this.getSessionPathNew(parentType, sessionId)).pipe(
            map((data: Partial<Session>) => this.constructSession(data)),
            catchError(() => of(null)),
        );
    }

    getSessionsByIds(parentType: ParentType, parentId: string, ids: string[]): Observable<Session[]> {
        const sessionPath = this.getSessionPath(parentType, parentId);

        return this.firestoreService
            .getDocumentsByIds<Session>(sessionPath, ids)
            .pipe(
                map((sessions) =>
                    sessions
                        .map((session: Session) => new Session(session.id, session as any))
                        .sort(sortAlphabetically()),
                ),
            );
    }

    getSessionsByIdsNew(parentType: ParentType, ids: string[]): Observable<Session[]> {
        const sessionPath = DBPathHelper.getSessionPathNew(parentType);

        return this.firestoreService
            .getDocumentsByIds<Session>(sessionPath, ids)
            .pipe(
                map((sessions) =>
                    sessions
                        .map((session: Session) => new Session(session.id, session as any))
                        .sort(sortAlphabetically()),
                ),
            );
    }

    getSessionsByIdsCombineLatest(parentType: ParentType, ids: string[]): Observable<Session[]> {
        const sessionPath = DBPathHelper.getSessionPathNew(parentType);

        return this.firestoreService
            .getDocumentsByIdsCombineLatest<Session>(sessionPath, ids)
            .pipe(
                map((sessions) =>
                    sessions
                        .map((session: Session) => new Session(session.id, session as any))
                        .sort(sortAlphabetically()),
                ),
            );
    }

    formatDate(date: Timestamp): { _seconds: number; _nanoseconds: number } {
        const convertedTimestamp = {
            _seconds: date?.seconds,
            _nanoseconds: date?.nanoseconds,
        };
        return convertedTimestamp;
    }

    getSessionsSummaryByIds(ids: string[]): Observable<SessionSummary[]> {
        const sessionPath = DBPathHelper.getSessionPathNew(ParentType.Sessions);
        const sessionList = this.firestoreService.getDocumentsByIds<Session>(sessionPath, ids).pipe(
            map((sessions) =>
                sessions.map(
                    (session) =>
                        ({
                            sessionId: session.id,
                            name: session.name,
                            tags: session.tags,
                            created: this.formatDate(session.created as Timestamp),
                            updated: this.formatDate(session.updated as Timestamp),
                        } as unknown as SessionSummary),
                ),
            ),
        );

        return sessionList;
    }

    getUserSessionsById(userId: string): Observable<UserSession[]> {
        const userSessionPath = DBPathHelper.getUserSessionsPath();

        return this.firestoreService.getDocumentsByProperty<UserSession>(userSessionPath, 'userId', userId).pipe(
            map((userSessions) =>
                (userSessions || [])
                    .filter((userSessions) => !userSessions?.markedForDelete)
                    .map((userSessions) => {
                        if (!userSessions.updated) {
                            userSessions.updated = userSessions.created;
                        }

                        if (!userSessions.lastViewed) {
                            userSessions.lastViewed = userSessions.updated;
                        }
                        return userSessions;
                    }),
            ),
        );
    }

    // TODO delete in https://thinktankco.atlassian.net/browse/TT9-6456
    getCurrentUserSessionsFilters(
        userId: string | undefined,
        parentType: ParentType,
        parentId: string,
    ): Observable<DefaultFilterObject> {
        if (parentType === ParentType.PublicProjectTemplates) {
            return of(initialDefaultFilterObject);
        }

        const sessionFilterPath = DBPathHelper.getSessionFilterPath(parentType, parentId, userId);

        return this.firestoreService.getDocument<DefaultFilterObject>(sessionFilterPath).pipe(
            map((filter: DefaultFilterObject) => {
                unset(filter, 'id');
                return filter
                    ? {
                          ...initialDefaultFilterObject,
                          ...filter,
                      }
                    : initialDefaultFilterObject;
            }),
        );
    }

    getUserSessionsFilters(userId: string | undefined): Observable<DefaultFilterObject> {
        return this.firestoreService
            .getDocument<DefaultFilterObject>(DBPathHelper.getUserSessionFilterPath(userId))
            .pipe(
                map((filter: DefaultFilterObject) => {
                    unset(filter, 'id');

                    return filter
                        ? {
                              ...initialDefaultFilterObject,
                              ...filter,
                          }
                        : initialDefaultFilterObject;
                }),
            );
    }

    getUserSessionsFiltersSort(userId: string | undefined): Observable<UserSessionFilters> {
        return this.firestoreService
            .getDocument<UserSessionFilters>(DBPathHelper.getUserSessionFilterPath(userId))
            .pipe(
                map((filter: UserSessionFilters) => {
                    unset(filter, 'id');

                    return filter
                        ? {
                              ...initialUserSessionFilters,
                              ...filter,
                          }
                        : initialUserSessionFilters;
                }),
            );
    }

    getDashboardUserSessionsFiltersSort(userId: string | undefined): Observable<UserSessionFilters> {
        return this.firestoreService
            .getDocument<UserSessionFilters>(DBPathHelper.getDashboardSessionsFilterPath(userId))
            .pipe(
                map((filter: UserSessionFilters) => {
                    unset(filter, 'id');

                    return filter
                        ? {
                              ...initialUserSessionFilters,
                              ...filter,
                          }
                        : initialUserSessionFilters;
                }),
            );
    }

    getSessionActivities(
        parentType = ParentType.Projects,
        parentId: string,
        sessionId: string,
    ): Observable<Activity[]> {
        return this.firestoreService.getDocumentsByProperty<Activity>(
            DBPathHelper.getActivityPath(parentType, parentId),
            'sessionId',
            sessionId,
        );
    }

    getSessionActivitiesNew(sessionId: string): Observable<Activity[]> {
        return this.firestoreService
            .getCollection(`/sessions/${sessionId}/activities`)
            .pipe(map((activities: Activity[]) => sortBySequenceAsc(activities)));
    }

    // TODO delete in https://thinktankco.atlassian.net/browse/TT9-6456
    async updateCurrentSessionsFilters(
        userId: string | undefined,
        data: DefaultFilterObject,
        parentType: ParentType,
        parentId: string,
    ): Promise<void> {
        await this.firestoreService.upsert(
            `${parentType}/${parentId}/teamMembers/${userId}/filters/sessionsFilters`,
            data,
        );
    }

    async updateSessionsFilters(
        userId: string | undefined,
        data: DefaultFilterObject,
        isHomepageFilter?: boolean,
    ): Promise<void> {
        await this.firestoreService.upsert(
            isHomepageFilter
                ? DBPathHelper.getDashboardSessionsFilterPath(userId)
                : DBPathHelper.getUserSessionFilterPath(userId),
            data,
        );
    }

    updateSessionStatus(
        parentType: ParentType,
        parentId: string,
        sessionId: string,
        status: SessionStatus,
    ): Promise<void> {
        return this.firestoreService.upsert(this.getSessionPath(parentType, parentId, sessionId), { status });
    }

    getSessions(parentType: ParentType, parentId: string): Observable<Session[]> {
        return this.firestoreService
            .getCollection<Record<string, unknown>>(DBPathHelper.getSessionPath(parentType, parentId))
            .pipe(map((sessionData) => sortBySequenceAsc(sessionData.map((data) => this.constructSession(data)))));
    }

    // get all sessions that not deleted (soft deletion feature)
    getAvailableSessions(parentType: ParentType, parentId: string): Observable<Session[]> {
        return this.firestoreService
            .getCollection<Record<string, unknown>>(DBPathHelper.getSessionPath(parentType, parentId))
            .pipe(
                map((sessionData) =>
                    sortBySequenceAsc(
                        sessionData
                            .map((data) => this.constructSession(data))
                            .filter((session: Session) => !session.markedForDelete),
                    ),
                ),
            );
    }

    updateDescription(parentType: ParentType, parentId: string, sessionId: string, value: string): Promise<void> {
        return this.firestoreService.upsert(this.getSessionPath(parentType, parentId, sessionId), {
            description: value,
            updated: this.firestoreService.timestamp,
        });
    }

    updateName(parentType: ParentType, parentId: string, sessionId: string, value: string): Promise<void> {
        return this.firestoreService.upsert(this.getSessionPath(parentType, parentId, sessionId), {
            name: value,
            updated: this.firestoreService.timestamp,
        });
    }

    async updateSessionIdsInProjectsCollection(
        isUpdate: boolean,
        projectId?: string,
        sessionId?: string,
    ): Promise<void> {
        const projectPath = DBPathHelper.getParentPath(ParentType.Projects, projectId);
        await this.firestoreService.upsert(projectPath, {
            sessionIds: isUpdate
                ? this.firestoreService.arrayUnion(sessionId)
                : this.firestoreService.arrayRemove(sessionId),
        });
    }

    async updateSessionNamesInProjectsCollection(
        projectId: string,
        sessionId: string,
        sessionName: string,
    ): Promise<void> {
        const projectPath = DBPathHelper.getParentPath(ParentType.Projects, projectId);
        const projectData = await firstValueFrom(this.firestoreService.getDocument<Project>(projectPath).pipe(take(1)));
        let sessionNames = projectData?.sessionNames || {};
        sessionNames = { ...sessionNames, [sessionId]: sessionName };
        await this.firestoreService.upsert(projectPath, { sessionNames });
    }

    async removeSessionNamesInProjectsCollection(projectId: string, sessionId: string): Promise<void> {
        const projectPath = DBPathHelper.getParentPath(ParentType.Projects);
        await this.firestoreService.deleteDocumentObjectValue(projectPath, projectId, 'sessionNames', sessionId);
    }

    async createSessionNew(
        parentType: ParentType,
        session: Session,
        startDate?: Date,
        endDate?: Date,
    ): Promise<string> {
        const UTCStartDate = startDate ? DateTransformation.convertDateToUTCFormat(startDate) : null;
        const UTCEndDate = endDate ? DateTransformation.convertDateToUTCFormat(endDate) : null;
        const fromDate = UTCStartDate ? getTimestampFromMilliseconds(UTCStartDate) : null;
        const toDate = UTCEndDate ? getTimestampFromMilliseconds(UTCEndDate) : null;

        const sessionId = await this.firestoreService.addDocument(
            DBPathHelper.getSessionPathNew(parentType),
            removeEmptyKeys({
                ...session,
                updated: this.firestoreService.timestamp,
                created: this.firestoreService.timestamp,
                startDate: fromDate || null,
                endDate: toDate || null,
            }),
        );

        return sessionId;
    }

    async createSessionEntry(
        session: Session,
        startDate?: Date,
        endDate?: Date,
        userCollection?: UserCollection, // passed when session is created inside a collection
    ): Promise<string> {
        const UTCStartDate = startDate ? DateTransformation.convertDateToUTCFormat(startDate) : null;
        const UTCEndDate = endDate ? DateTransformation.convertDateToUTCFormat(endDate) : null;
        const fromDate = UTCStartDate ? getTimestampFromMilliseconds(UTCStartDate) : null;
        const toDate = UTCEndDate ? getTimestampFromMilliseconds(UTCEndDate) : null;

        const sessionId = this.firestoreService.getPushId();

        await this.firestoreService.set(
            DBPathHelper.getSessionPathNew(ParentType.Sessions, sessionId),
            removeEmptyKeys({
                ...session,
                updated: this.firestoreService.timestamp,
                created: this.firestoreService.timestamp,
                startDate: fromDate || null,
                endDate: toDate || null,
            }),
        );

        const batchWrites = [];
        const userSessionCollectionDetails = userCollection
            ? ({
                  collectionId: userCollection.collectionId,
                  collectionName: userCollection.name,
                  color: userCollection?.color || '',
              } as Partial<UserSession>)
            : {};

        const userSessionData = omitBy(
            {
                sessionId: sessionId,
                parentType: ParentType.Sessions,
                userId: session.creatorId,
                role: SessionRole.Leader,
                name: session.name,
                description: session.description || '',
                imageUrl: session.imageUrl || '',
                created: this.firestoreService.timestamp,
                updated: this.firestoreService.timestamp,
                startDate: fromDate,
                endDate: toDate,
                creatorId: session.creatorId,
                creatorImage: session.creatorImage || '',
                creatorName: session.creatorName || '',
                phase: session?.phase,
                subPhase: session?.subPhase,
                tags: session?.tags,
                clientId: Object.keys(session?.client)?.[0] || '',
                projectId: Object.keys(session?.project)?.[0] || '',
                ...userSessionCollectionDetails,
            } as UserSession,
            (result) => isUndefined(result) || isNull(result),
        );

        const userSessionCreate = {
            path: DBPathHelper.getUserSessionsPath(this.firestoreService.getPushId()),
            data: userSessionData,
        };

        batchWrites.push(userSessionCreate);

        await this.firestoreService.writeBatch(batchWrites);

        // Create collectionSession if created inside a collection
        if (!!userCollection) {
            const userId = userCollection.userId;
            const collectionSession = new CollectionSession(
                removeEmptyKeys({
                    sessionId,
                    userId,
                    imageUrl: session?.imageUrl || '',
                    imageId: session?.imageId || '',
                    collectionId: userCollection.collectionId,
                    collectionName: userCollection.name,
                    sessionRole: session?.access[userId]?.role,
                    sessionPhase: session?.phase,
                    sessionSubPhase: session?.subPhase,
                    role: CollectionRole.Owner,
                    sessionName: session.name,
                    sessionDescription: session.description || '',
                    tags: session?.tags || {},
                }),
            );

            Promise.all([
                await this.collectionsService.addSessionToCollection(collectionSession),
                await this.collectionsService.updateUserCollectionsSessionsCount(userCollection.collectionId, 1),
            ]);
        }

        return sessionId;
    }

    // TT9-6801
    async createSessionFromSessionTemplate(
        parentType: ParentType.PublicSessionTemplates | ParentType.Templates,
        parentId: string,
        userId: string,
        saveResponses = false,
        publicAccessId?: string,
    ): Promise<string> {
        const dictionary = await this.firestoreService.cloudFunctionCallable('duplicateDataTt9', {
            userId,
            saveResponses,
            from: {
                publicAccessId,
                parentType,
                parentId,
            },
            to: {
                parentType: ParentType.Sessions,
            },
            type: DuplicateType.Session,
        });
        return dictionary[parentId];
    }

    async createSessionUserAccess(session: Session, sessionId: string, creatorId: string): Promise<void> {
        const batchWrites = [];
        const userAccess = omitBy(
            {
                sessionId,
                creatorId,
                userId: creatorId,
                role: SessionRole.Leader,
                sequence: session.sequence || '',
                name: session.name,
                description: session.description || '',
                imageUrl: session?.imageUrl || '',
                phase: session?.phase || {},
                subPhase: session?.subPhase || {},
                tags: session?.tags || {},
                created: this.firestoreService.timestamp,
                updated: this.firestoreService.timestamp,
            },
            (result) => isUndefined(result) || isNull(result),
        );

        const userAccessCreate = {
            path: `userAccess/${this.firestoreService.getPushId()}`,
            data: userAccess as unknown as UserSession,
        };
        batchWrites.push(userAccessCreate);

        const userSessionsCountUpdate = {
            path: DBPathHelper.getUserPath(creatorId),
            data: {
                sessionsCounter: this.firestoreService.changeCounterValue(1),
            },
        };
        batchWrites.push(userSessionsCountUpdate);

        await this.firestoreService.writeBatch(batchWrites);
    }

    //TODO DELETE WHEN COLLECTION FEATURE WILL BE FINISHED
    async createSession(
        parentType: ParentType,
        parentId: string,
        session: Session,
        startDate?: Date,
        endDate?: Date,
    ): Promise<string> {
        const UTCStartDate = startDate ? DateTransformation.convertDateToUTCFormat(startDate) : null;
        const UTCEndDate = endDate ? DateTransformation.convertDateToUTCFormat(endDate) : null;
        const fromDate = UTCStartDate ? getTimestampFromMilliseconds(UTCStartDate) : null;
        const toDate = UTCEndDate ? getTimestampFromMilliseconds(UTCEndDate) : null;

        const sessionId = await this.firestoreService.addDocument(
            DBPathHelper.getSessionPath(parentType, parentId),
            removeEmptyKeys({
                ...session,
                updated: this.firestoreService.timestamp,
                created: this.firestoreService.timestamp,
                startDate: fromDate || null,
                endDate: toDate || null,
            }),
        );

        if (parentType === ParentType.Projects) {
            await this.firestoreService.cloudFunctionCallable('userUpdate', {
                userId: session.creatorId,
                sessionsCounter: 1,
            });

            await this.updateSessionIdsInProjectsCollection(true, parentId, sessionId);
            await this.updateSessionNamesInProjectsCollection(parentId, sessionId, session.name);
        }

        return sessionId;
    }

    async updateSessionVotedActivityCount(
        parentType: ParentType,
        parentId: string,
        sessionId: string,
        activityOrActivityItemId: string,
        changeCounterValue: number,
    ): Promise<void> {
        await this.firestoreService.update(DBPathHelper.getSessionPath(parentType, parentId, sessionId), {
            votedActivityCount: {
                [activityOrActivityItemId]: this.firestoreService.changeCounterValue(changeCounterValue),
            },
        });
    }

    async updateSessionVotedActivityCountNew(
        parentType: ParentType,
        sessionId: string,
        activityOrActivityItemId: string,
        changeCounterValue: number,
    ): Promise<void> {
        await this.firestoreService.update(DBPathHelper.getSessionPathNew(parentType, sessionId), {
            votedActivityCount: {
                [activityOrActivityItemId]: this.firestoreService.changeCounterValue(changeCounterValue),
            },
        });
    }

    async resetSessionVotedActivityCount(
        parentType: ParentType,
        parentId: string,
        sessionId: string,
        activityOrActivityItemId: string,
    ): Promise<void> {
        await this.firestoreService.update(DBPathHelper.getSessionPath(parentType, parentId, sessionId), {
            votedActivityCount: {
                [activityOrActivityItemId]: 0,
            },
        });
    }

    async resetSessionVotedActivityCountNew(
        parentType: ParentType,
        parentId: string,
        sessionId: string,
        activityOrActivityItemId: string,
    ): Promise<void> {
        await this.firestoreService.update(DBPathHelper.getSessionPathNew(parentType, sessionId), {
            votedActivityCount: {
                [activityOrActivityItemId]: 0,
            },
        });
    }

    // TODO: Delete after project  deprecation
    async resetSessionVotedTableActivityCount(
        parentType: ParentType,
        parentId: string,
        sessionId: string,
        activityId: string,
    ): Promise<void> {
        await this.firestoreService.update(DBPathHelper.getSessionPath(parentType, parentId, sessionId), {
            votedActivityCount: {
                [activityId]: 0,
            },
        });
    }

    async resetSessionVotedTableActivityCountNew(
        parentType: ParentType,
        sessionId: string,
        activityId: string,
    ): Promise<void> {
        await this.firestoreService.update(DBPathHelper.getSessionPathNew(parentType, sessionId), {
            votedActivityCount: {
                [activityId]: 0,
            },
        });
    }

    // TODO: Delete after project  deprecation
    async resetAllVotedActivityCount(
        parentType: ParentType,
        parentId: string,
        sessionId: string,
        activityItemIds: string[],
    ): Promise<void> {
        for (const activityItemId of activityItemIds) {
            await this.firestoreService.update(DBPathHelper.getSessionPath(parentType, parentId, sessionId), {
                votedActivityCount: {
                    [activityItemId]: 0,
                },
            });
        }
    }

    async resetAllVotedActivityCountNew(
        parentType: ParentType,
        parentId: string,
        sessionId: string,
        activityItemIds: string[],
    ): Promise<void> {
        for (const activityItemId of activityItemIds) {
            await this.firestoreService.update(DBPathHelper.getSessionPathNew(parentType, sessionId), {
                votedActivityCount: {
                    [activityItemId]: 0,
                },
            });
        }
    }

    async updateSessionTeamMembersCount(
        parentType: ParentType,
        parentId: string,
        sessionId: string,
        changeCounterValue: number,
    ): Promise<void> {
        await this.firestoreService.update(DBPathHelper.getSessionPath(parentType, parentId, sessionId), {
            teamMembersCount: this.firestoreService.changeCounterValue(changeCounterValue),
        });
    }

    async updateSessionDocument(
        parentType: ParentType,
        parentId: string,
        sessionId: string,
        data: Partial<Session>,
        startDate?: Date,
        endDate?: Date,
    ): Promise<void> {
        const UTCStartDate = startDate ? DateTransformation.convertDateToUTCFormat(startDate) : null;
        const UTCEndDate = endDate ? DateTransformation.convertDateToUTCFormat(endDate) : null;
        const fromDate = UTCStartDate ? getTimestampFromMilliseconds(UTCStartDate) : this.firestoreService.deleteField;
        const toDate = UTCEndDate ? getTimestampFromMilliseconds(UTCEndDate) : this.firestoreService.deleteField;

        await this.firestoreService.updateDoc(this.getSessionPath(parentType, parentId, sessionId), {
            ...this.firestoreService.replaceEmptyFields(data),
            updated: this.firestoreService.timestamp,
            ...(startDate !== undefined && { startDate: fromDate }),
            ...(endDate !== undefined && { endDate: toDate }),
        });

        if (parentType === ParentType.Projects && data?.name) {
            await this.updateSessionNamesInProjectsCollection(parentId, sessionId, data.name);
        }
    }

    async updateSessionDocumentNew(
        parentType: ParentType,
        sessionId: string,
        data: Partial<Session>,
        startDate?: Date,
        endDate?: Date,
    ): Promise<void> {
        const UTCStartDate = startDate ? DateTransformation.convertDateToUTCFormat(startDate) : null;
        const UTCEndDate = endDate ? DateTransformation.convertDateToUTCFormat(endDate) : null;
        const fromDate = UTCStartDate ? getTimestampFromMilliseconds(UTCStartDate) : this.firestoreService.deleteField;
        const toDate = UTCEndDate ? getTimestampFromMilliseconds(UTCEndDate) : this.firestoreService.deleteField;

        const sessionData = {
            ...this.firestoreService.replaceEmptyFields(data),
            updated: this.firestoreService.timestamp,
            ...(startDate !== undefined && { startDate: fromDate }),
            ...(endDate !== undefined && { endDate: toDate }),
            ...(data.client && { client: data?.client }),
            ...(data.project && { project: data?.project }),
        };

        await this.firestoreService.updateDoc(this.getSessionPathNew(parentType, sessionId), sessionData);

        if (!('sessionThreadIsAnonymous' in data)) {
            // Update userSession
            await this.updateSessionDetailsInUserSessions(sessionId, sessionData);

            // Update collectionSession
            await this.updateSessionDetailsInCollectionSessions(sessionId, sessionData);
        }

        if ([ParentType.Templates, ParentType.PublicSessionTemplates].includes(parentType)) {
            await this.userTemplateService.updateUserTemplatesBySessionTemplateId(sessionId, sessionData);
        }
    }

    async updateSessionDocumentWithMerge(
        parentType: ParentType,
        parentId: string,
        sessionId: string,
        data: Partial<Session>,
    ): Promise<void> {
        await this.firestoreService.update(this.getSessionPath(parentType, parentId, sessionId), {
            ...this.firestoreService.replaceEmptyFields(data),
            updated: this.firestoreService.timestamp,
        });
    }

    async updateSessionDocumentWithMergeNew(
        parentType: ParentType,
        sessionId: string,
        data: Partial<Session>,
    ): Promise<void> {
        await this.firestoreService.update(this.getSessionPathNew(parentType, sessionId), {
            ...this.firestoreService.replaceEmptyFields(data),
            updated: this.firestoreService.timestamp,
        });
    }

    // TODO: Delete after project  deprecation
    async saveAsTemplate(
        parentId: string,
        sessionId: string,
        toParentType: ParentType,
        fromParentType: ParentType,
        saveResponses = false,
    ): Promise<string> {
        const dictionary = await this.firestoreService.cloudFunctionCallable('duplicateData', {
            saveResponses,
            from: {
                parentId,
                sessionId,
                parentType: fromParentType,
            },
            to: {
                parentType: toParentType,
            },
            type: DuplicateType.Session,
        });
        return dictionary[parentId];
    }

    async saveAsTemplateNew(
        parentId: string,
        toParentType: ParentType = ParentType.Templates,
        fromParentType: ParentType,
        saveResponses = false,
    ): Promise<string> {
        const dictionary = await this.firestoreService.cloudFunctionCallable('duplicateDataTt9', {
            saveResponses,
            from: {
                parentId,
                parentType: fromParentType,
            },
            to: {
                parentType: toParentType,
            },
            type: DuplicateType.Session,
        });
        return dictionary[parentId];
    }

    async inviteSessionLeaderToProject(projectId: string, sessionId: string, userId: string): Promise<void> {
        await this.firestoreService.cloudFunctionCallable('provideAccessByUid', {
            userId,
            projectId,
            sessionId: sessionId,
            sessionRole: SessionRole.Leader,
        });
    }

    async addTemplatesToSession(
        templateId: string,
        userId: string,
        publicAccessId?: string,
        collectionDestination?: UserCollection,
        saveResponses = false,
    ): Promise<void> {
        await this.firestoreService.cloudFunctionCallable('duplicateDataTt9', {
            userId,
            saveResponses,
            from: {
                publicAccessId,
                parentId: templateId,
                parentType: ParentType.Templates,
            },
            to: {
                parentType: ParentType.Sessions,
            },
            type: DuplicateType.Session,
            userCollection: collectionDestination,
        });
    }

    async getSessionsSummaryByFilters(
        filters: SessionQueryFilters,
        areFiltersApplied: boolean,
    ): Promise<{ sessions: SessionSummary[]; sessionIds: string[] }> {
        const timezone = new Date().getTimezoneOffset();
        try {
            return this.firestoreService.cloudFunctionCallable('getSessionsSummaryByFilters', {
                filters,
                areFiltersApplied,
                timezone,
            });
        } catch {
            return {
                sessions: [],
                sessionIds: [],
            };
        }
    }

    getAdminSessionsFilters(userId: string | undefined): Observable<SessionFilters> {
        return this.firestoreService.getDocument(DBPathHelper.getAdminSessionsFilterPath(userId)).pipe(
            map((filter: SessionFilters) => {
                unset(filter, 'id');
                return filter
                    ? {
                          ...initialSessionFilters,
                          ...filter,
                      }
                    : initialSessionFilters;
            }),
        );
    }

    async updateAdminSessionsFilters(userId: string | undefined, data: SessionFilters): Promise<void> {
        await this.firestoreService.upsert(DBPathHelper.getAdminSessionsFilterPath(userId), data);
    }

    private constructSession(data: Record<string, unknown>): Session {
        if (data) {
            const id = typeof data.id === 'string' ? data.id : '';
            return new Session(id, data);
        }
        return null;
    }

    private async updateSessionDetailsInUserSessions(sessionId: string, sessionData: Partial<Session>) {
        const batchUpdates = [];

        // We need to update all instances of the session in userSession regardless of userId
        const userSessions
            = (await firstValueFrom(
                this.firestoreService.getDocumentsByProperty<UserSession>(
                    DBPathHelper.getUserSessionsPath(),
                    'sessionId',
                    sessionId,
                ),
            )) || [];

        if (userSessions.length) {
            const data = {
                name: sessionData.name,
                description: sessionData.description,
                imageUrl: sessionData.imageUrl,
                startDate: sessionData.startDate,
                endDate: sessionData.endDate,
                tags: sessionData.tags,
                phase: sessionData.phase,
                subPhase: sessionData.subPhase,
                updated: this.firestoreService.timestamp,
                clientId: Object.keys(sessionData?.client)?.[0] || '',
                projectId: Object.keys(sessionData?.project)?.[0] || '',
            } as UserSession;

            userSessions.forEach((userSession) => {
                batchUpdates.push({
                    data,
                    path: DBPathHelper.getUserSessionsPath(userSession.id),
                });
            });

            await this.firestoreService.updateBatch(batchUpdates);
        }
    }

    private async updateSessionDetailsInCollectionSessions(sessionId: string, sessionData: Partial<Session>) {
        const batchUpdates = [];

        // We need to update all instances of the session in collectionSession regardless of collectionId and userId
        const collectionSessions
            = (await firstValueFrom(
                this.firestoreService.getDocumentsByProperty<CollectionSession>(
                    DBPathHelper.getCollectionSessionsPath(),
                    'sessionId',
                    sessionId,
                ),
            )) || [];

        if (collectionSessions.length && sessionData.id) {
            const data = {
                sessionName: sessionData.name,
                sessionDescription: sessionData.description,
                imageId: sessionData.imageId,
                imageUrl: sessionData.imageUrl,
                tags: sessionData.tags,
                sessionPhase: sessionData.phase,
                sessionSubPhase: sessionData.subPhase,
                updated: this.firestoreService.timestamp,
            } as CollectionSession;

            collectionSessions.forEach((collectionSession) => {
                batchUpdates.push({
                    data,
                    path: DBPathHelper.getCollectionSessionsPath(collectionSession.id),
                });
            });

            await this.firestoreService.updateBatch(batchUpdates);
        }
    }

    hasClientInSession(parentType: ParentType, sessionId: string): Observable<boolean> {
        return this.getSessionNew(parentType, sessionId).pipe(map((res) => !!Object.values(res.client).length));
    }
}
