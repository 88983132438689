import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { FilestackService, FileUploadService, FirestoreService, UserService, VideoService } from './services';

@NgModule({
    imports: [CommonModule, HttpClientModule],
})
export class SharedDataAccessModule {
    static forRoot(): ModuleWithProviders<SharedDataAccessModule> {
        return {
            ngModule: SharedDataAccessModule,
            providers: [FirestoreService, FileUploadService, UserService, VideoService, FilestackService],
        };
    }
}
