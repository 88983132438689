import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Timestamp } from 'firebase/firestore';
import { Observable, tap } from 'rxjs';

import {
    ActivityType,
    activityTypeImages,
    emptyScreenImageUrlFavorite,
    emptyScreenTitles,
    FavoriteAccess,
    inputPlaceholders,
    navigationCardImageUrl,
    noTemplatesYetMap,
    ParentType,
    PublicAccess,
    TemplateAccessType,
    TemplateTab,
    tooltipTexts,
} from '@accenture/shared/data';
import { ImageInputPlaceholdersTypes, imageInputPlaceholdersUrl } from '@accenture/shared/ui';
import { trackById, trackByValue } from '@accenture/shared/util';

import { FavoriteTemplatesFacade, FavoriteTemplatesViewModel } from './favorite-templates-facade';

@Component({
    selector: 'accenture-favorite-templates',
    templateUrl: './favorite-templates.component.html',
    styleUrls: ['./favorite-templates.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [FavoriteTemplatesFacade],
})
export class FavoriteTemplatesComponent {
    vm$: Observable<FavoriteTemplatesViewModel> = this.facade.vm$.pipe(
        tap((vm: FavoriteTemplatesViewModel) => {
            this.updateSearchValue(vm.searchValue, vm.favoriteTemplatesOfCurrentTab);
            this.updateEmptyTemplateListStatus(vm.favoriteTemplatesOfCurrentTab);
        }),
    );

    trackById = trackById;
    trackByValue = trackByValue;
    emptyScreenImageUrlFavorite = emptyScreenImageUrlFavorite;
    inputPlaceholders = inputPlaceholders;
    tooltipTexts = tooltipTexts;
    favoritesTabs = [TemplateTab.All, TemplateTab.Projects, TemplateTab.Sessions, TemplateTab.Activities];
    parentType = ParentType;
    hoveredCardIndex?: number;
    menuOpened = false;
    hasVisibleTemplates = false;

    searchControl = new FormControl<string | null>(null);
    templateTab = TemplateTab;

    constructor(private facade: FavoriteTemplatesFacade) {}

    showActions(templatesLength: boolean, isSearchValue: boolean): boolean {
        return templatesLength || (!templatesLength && isSearchValue);
    }

    isTemplateOwnerOrCollaborator(previewedTemplate: PublicAccess | FavoriteAccess, userId: string): boolean {
        return previewedTemplate.ownerId === userId || (previewedTemplate.teamMembersIds || []).includes(userId);
    }

    hoverTemplateCard(index: number): void {
        this.hoveredCardIndex = index;
    }

    unhoverTemplateCard(index: number): void {
        if (this.hoveredCardIndex === index && !this.menuOpened) {
            this.resetTemplateCardHover();
        }
    }

    getEmptyScreenTitle(vm: FavoriteTemplatesViewModel): string {
        switch (true) {
            case !vm.areThereAnyFavoriteTemplates:
                return noTemplatesYetMap[TemplateTab.All];
            case !vm.favoriteTemplatesOfCurrentTab?.length:
                return noTemplatesYetMap[vm.activeFavoriteTab];
            case !!vm.searchValue && vm.isFiltersApplied:
                return emptyScreenTitles.noTemplatesMatchAndFound;
            case !vm.searchValue && vm.isFiltersApplied:
                return emptyScreenTitles.noTemplatesMatch;
            case !!vm.searchValue && !vm.isFiltersApplied:
                return emptyScreenTitles.noTemplatesFound;
            case !vm.searchValue && !vm.isFiltersApplied:
                return noTemplatesYetMap[vm.activeFavoriteTab];
        }
    }

    getTemplateUpdateDate(template: PublicAccess): Date {
        const updated = template.updated as Timestamp;
        return updated?.toDate ? updated.toDate() : new Date();
    }

    getImageUrl(imageUrl?: string): string {
        return imageUrl ? `url(${imageUrl})` : '';
    }

    getEmptyImage(templateType: ParentType, activityType?: ActivityType | undefined): string {
        switch (templateType) {
            case ParentType.ProjectTemplates:
            case ParentType.PublicProjectTemplates:
                return imageInputPlaceholdersUrl[ImageInputPlaceholdersTypes.Project];
            case ParentType.Templates:
            case ParentType.PublicSessionTemplates:
                return imageInputPlaceholdersUrl[ImageInputPlaceholdersTypes.Session];
            case ParentType.ActivityTemplates:
            case ParentType.PublicActivityTemplates:
                return activityTypeImages[activityType] || '/assets/shared-ui/svg/activity-others.svg';
            default:
                return navigationCardImageUrl.path;
        }
    }

    setSearchValue(value: string): void {
        this.facade.setSearchValue(value);
    }

    toggleFilterAndSortPanel(opened: boolean, event?: Event): void {
        this.facade.toggleFilterAndSortPanel(opened);
        if (event) {
            event.stopPropagation();
        }
    }

    clearSearchValue(event: Event): void {
        event.stopPropagation();
        this.setSearchValue('');
    }

    setFavoriteTab(tab: TemplateTab): void {
        this.facade.setFavoriteTab(tab);
        this.resetTemplateCardHover();
    }

    openPublishDialog(template: FavoriteAccess): void {
        this.facade.openPublishDialog(template);
    }

    deleteTemplate(template: FavoriteAccess): void {
        this.facade.deleteTemplateConfirm(template);
    }

    removeFromFavorite(template: FavoriteAccess): void {
        this.facade.removeFromFavorite(template, template.publicAccessId);
        this.closePreviewPanel();
    }

    useTemplate(template: FavoriteAccess): void {
        this.facade.useTemplate(template.templateType, template.templateId, template.publicAccessId);
    }

    saveAsTemplate(template: FavoriteAccess): void {
        this.facade.saveAsTemplate(template);
    }

    editTemplate(template: FavoriteAccess, isEditPending?: boolean): void {
        if (isEditPending) {
            return;
        }
        this.facade.editTemplate(template);
    }

    openForPreview(data: { template: FavoriteAccess; event?: Event }): void {
        this.facade.openForPreview(data.template);
        if (data.event) {
            data.event.stopPropagation();
        }
    }

    closePreviewPanel(): void {
        this.facade.openForPreview(null);
    }

    copyLinkAccessToClipboard(template: TemplateAccessType): void {
        this.facade.copyLinkAccessToClipboard(template);
    }

    openTeamMembersPanel(event: Event): void {
        event?.stopPropagation();
        this.facade.openTeamMembersPanel();
    }

    closeTeamMembersPanel(event: Event): void {
        event?.stopPropagation();
        this.facade.closeTeamMembersPanel();
    }

    private resetTemplateCardHover(): void {
        this.hoveredCardIndex = undefined;
    }

    private updateSearchValue(searchValue: string, templatesOfCurrentTab: FavoriteAccess[]): void {
        this.searchControl.patchValue(searchValue);
        this.searchControl[templatesOfCurrentTab?.length ? 'enable' : 'disable']();
    }

    private updateEmptyTemplateListStatus(templates: FavoriteAccess[]): void {
        this.hasVisibleTemplates = !!templates.length;
    }
}
