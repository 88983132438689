import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    Output,
} from '@angular/core';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';

import { ScrollPosition } from '../../models';
import { ScrollSpyMode } from './scroll-spy-mode';

@Directive({
    selector: '[accentureScrollSpy]',
})
export class ScrollSpyDirective implements AfterViewInit, OnDestroy {
    @Input('accentureScrollSpyDisabled') disabled?: boolean;
    @Input('accentureScrollSpyMode') scrollMod: ScrollSpyMode = ScrollSpyMode.Vertical;

    @Output() scrollSpyPositionChange = new EventEmitter<ScrollPosition>();

    private mousewheelSubject = new Subject<void | Event>();
    private resultSubject = new Subject<ScrollPosition>();
    private destroySubject = new Subject<void>();
    private nativeElement: HTMLElement;

    constructor(private element: ElementRef) {
        this.nativeElement = this.element.nativeElement;

        this.resultSubject
            .asObservable()
            .pipe(
                takeUntil(this.destroySubject),
                distinctUntilChanged((a, b) => a === b),
            )
            .subscribe(position => this.scrollSpyPositionChange.emit(position));
    }

    @HostListener('scroll', ['$event'])
    @HostListener('mousewheel', ['$event'])
    onEventHandler() {
        if (this.disabled) {
            return;
        }

        setTimeout(() => {
            this.mousewheelSubject.next();
        }, 0);
    }

    ngAfterViewInit(): void {
        this.mousewheelSubject.subscribe(() => this.checkScrollState());
    }

    ngOnDestroy(): void {
        this.mousewheelSubject.next();
        this.mousewheelSubject.complete();

        this.destroySubject.next();
        this.destroySubject.complete();

        this.resultSubject.complete();
    }

    private checkScrollState(): void {
        const isNotInTheMiddle = this.checkReachStart() || this.checkReachEnd();

        if (!isNotInTheMiddle) {
            this.resultSubject.next(ScrollPosition.Center);
        }
    }

    private checkReachStart(): boolean {
        const scrollModeCheckMap: { [key in ScrollSpyMode]: () => boolean } = {
            [ScrollSpyMode.Vertical]: this.checkScrollStartVertical.bind(this),
            [ScrollSpyMode.Horizontal]: this.checkScrollStartHorizontal.bind(this),
        };
        const shouldEmitReachStart = scrollModeCheckMap[this.scrollMod]();

        if (shouldEmitReachStart) {
            this.resultSubject.next(ScrollPosition.Start);
        }

        return shouldEmitReachStart;
    }

    private checkScrollStartVertical(): boolean {
        const el = this.nativeElement;
        const scrollPosition = el.scrollHeight - el.offsetHeight - el.scrollTop;

        return scrollPosition >= el.scrollHeight - el.offsetHeight;
    }

    private checkScrollStartHorizontal(): boolean {
        const el = this.nativeElement;
        const scrollPosition = el.scrollWidth - el.offsetWidth - el.scrollLeft;

        return scrollPosition >= el.scrollWidth - el.offsetWidth;
    }

    private checkReachEnd(): boolean {
        const scrollModeCheckMap: { [key in ScrollSpyMode]: () => boolean } = {
            [ScrollSpyMode.Vertical]: this.checkScrollEndVertical.bind(this),
            [ScrollSpyMode.Horizontal]: this.checkScrollEndHorizontal.bind(this),
        };
        const shouldEmitReachEnd = scrollModeCheckMap[this.scrollMod]();

        if (shouldEmitReachEnd) {
            this.resultSubject.next(ScrollPosition.End);
        }

        return shouldEmitReachEnd;
    }

    private checkScrollEndVertical(): boolean {
        const el = this.nativeElement;
        const scrollPosition = el.scrollHeight - el.offsetHeight - el.scrollTop;

        return scrollPosition < 5;
    }

    private checkScrollEndHorizontal(): boolean {
        const el = this.nativeElement;
        const scrollPosition = el.scrollWidth - el.offsetWidth - el.scrollLeft;

        return scrollPosition < 5;
    }
}
