import { ActivityType } from '../activities';
import { Dictionary } from '../dictionary';
import { activityItemsDBFilePaths } from './activity-item';

export const activityItemAnonymousName = 'Anonymous';

export enum ActivityItemType {
    Section = 'SECTION',
    Text = 'TEXT',
    ShortText = 'SHORT_TEXT',
    LongText = 'LONG_TEXT',
    SingleSelect = 'SINGLE_SELECT',
    MultiSelect = 'MULTI_SELECT',
    Number = 'NUMBER', // for Forms
    Numeric = 'NUMERIC', // For Vote
    Date = 'DATE',
    Video = 'VIDEO',
    Image = 'IMAGE',
    Attachment = 'ATTACHMENT',
    File = 'FILE',
    Slide = 'SLIDE',
    Slider = 'SLIDER',
    StarVoting = 'STAR_VOTING',
    TopX = 'TOP_X',
    SparkPrompt = 'SPARK_PROMPT',
    BrainstormTopic = 'SOURCE_TEXT', // topic for brainstorm activity
    PollQuestion = 'POLL_QUESTION',
    Table = 'TABLE',
    Prompt = 'PROMPT',
    LiveShareResource = 'LIVE_SHARE_RESOURCE',
    Slate = 'SLATE',
    Unknown = 'UNKNOWN',
}

export const activityItemsTypesWithoutSection = [
    ActivityItemType.Text,
    ActivityItemType.ShortText,
    ActivityItemType.LongText,
    ActivityItemType.SingleSelect,
    ActivityItemType.MultiSelect,
    ActivityItemType.Number,
    ActivityItemType.Numeric,
    ActivityItemType.Date,
    ActivityItemType.Video,
    ActivityItemType.Image,
    ActivityItemType.Attachment,
    ActivityItemType.File,
    ActivityItemType.Slide,
    ActivityItemType.Slider,
    ActivityItemType.StarVoting,
    ActivityItemType.TopX,
    ActivityItemType.BrainstormTopic,
    ActivityItemType.PollQuestion,
    ActivityItemType.Table,
    ActivityItemType.Prompt,
];

export enum VoteActivityItemType {
    VoteItem = 'VOTE_ITEM',
    Parameter = 'PARAMETER',
}

export const getActivityItemsTypesByActivityType = (activityType: ActivityType): ActivityItemType[] => {
    switch (activityType) {
        case ActivityType.Vote:
            return [
                ActivityItemType.Slider,
                ActivityItemType.StarVoting,
                ActivityItemType.MultiSelect,
                ActivityItemType.SingleSelect,
                ActivityItemType.Text,
                ActivityItemType.TopX,
                ActivityItemType.Numeric,
            ];
        case ActivityType.Brainstorm:
            return [ActivityItemType.BrainstormTopic];
        case ActivityType.Present:
            return [ActivityItemType.Slide];
        case ActivityType.QuickPoll:
            return [ActivityItemType.PollQuestion];
        case ActivityType.Table:
            return [
                ActivityItemType.MultiSelect,
                ActivityItemType.SingleSelect,
                ActivityItemType.Text,
                ActivityItemType.Number,
                ActivityItemType.Date,
                ActivityItemType.Attachment,
            ];
        case ActivityType.Proompt:
            return [ActivityItemType.Text];
        case ActivityType.Spark:
            return [ActivityItemType.SparkPrompt];
        case ActivityType.Whiteboard:
            return [ActivityItemType.Slate];
        default:
            return [];
    }
};

export const displayActivityItemsType: Dictionary<string> = {
    [ActivityItemType.Section]: 'Section',
    [ActivityItemType.Text]: 'Text',
    [ActivityItemType.ShortText]: 'Short Answer',
    [ActivityItemType.LongText]: 'Long Answer',
    [ActivityItemType.SingleSelect]: 'Single select',
    [ActivityItemType.MultiSelect]: 'Multi-select',
    [ActivityItemType.Numeric]: 'Numeric',
    [ActivityItemType.Number]: 'Number',
    [ActivityItemType.Date]: 'Date',
    [ActivityItemType.Video]: 'Video',
    [ActivityItemType.Image]: 'Image',
    [ActivityItemType.Attachment]: 'Attach file',
    [ActivityItemType.File]: 'File Upload',
    [ActivityItemType.Slide]: 'Slide',
    [ActivityItemType.Slider]: 'Slider',
    [ActivityItemType.StarVoting]: 'Star Voting',
    [ActivityItemType.Table]: 'Table',
    [ActivityItemType.TopX]: 'Top X',
    [VoteActivityItemType.VoteItem]: 'Vote Item',
    [VoteActivityItemType.Parameter]: 'Parameter',
    [ActivityItemType.Unknown]: '',
};

export const displayActivityItemsIcons = {
    [ActivityItemType.Text]: 'icon-chat',
    [ActivityItemType.ShortText]: 'icon-text-fields',
    [ActivityItemType.LongText]: 'icon-chat',
    [ActivityItemType.SingleSelect]: 'icon-single-select',
    [ActivityItemType.MultiSelect]: 'icon-check-square',
    [ActivityItemType.Number]: 'icon-looks-one',
    [ActivityItemType.Numeric]: 'icon-percent',
    [ActivityItemType.Date]: 'icon-calendar',
    [ActivityItemType.Video]: 'icon-video',
    [ActivityItemType.Image]: 'icon-image',
    [ActivityItemType.Attachment]: 'icon-attachment',
    [ActivityItemType.File]: 'icon-file',
    [ActivityItemType.Slide]: '',
    [ActivityItemType.Slider]: 'icon-linear-scale',
    [ActivityItemType.StarVoting]: 'icon-star-outline',
    [ActivityItemType.TopX]: 'icon-open-in-full',
    [ActivityItemType.Unknown]: '',
};

export const optionsFilesFieldName = {
    [ActivityItemType.File]: 'defaultResponse',
    [ActivityItemType.Image]: 'images',
    [ActivityItemType.Video]: 'videos',
    [ActivityItemType.Attachment]: 'attachments',
};

export const optionsFilesFolder = {
    [ActivityItemType.Image]: activityItemsDBFilePaths.SessionImages,
    [ActivityItemType.Attachment]: activityItemsDBFilePaths.SessionAttachments,
};
