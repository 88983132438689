import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { map, Observable } from 'rxjs';

import { SessionsInvitationsTab } from '../../session-invite-dialog.constants';
import { SessionInviteDialogStore } from '../../session-invite-dialog.store';

@Component({
    selector: 'accenture-session-invite-tabs',
    templateUrl: './session-invite-tabs.component.html',
    styleUrls: ['./session-invite-tabs.component.scss'],
    providers: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionInviteTabsComponent {
    tabs = [
        { title: 'By Email', tab: SessionsInvitationsTab.ByEmail },
        { title: 'Using a Link', tab: SessionsInvitationsTab.ByLink },
    ];

    activeTab$ = this.sessionInviteDialogStore.activeTab$;

    isAdminPage$ = this.sessionInviteDialogStore.isAdminPage$;

    filteredTabs$: Observable<{ title: string; tab: SessionsInvitationsTab }[]> = this.isAdminPage$.pipe(
        map((isAdmin) => (isAdmin ? [this.tabs[0]] : this.tabs)),
    );

    constructor(private sessionInviteDialogStore: SessionInviteDialogStore) {}

    setActiveTab(tab: SessionsInvitationsTab): void {
        this.sessionInviteDialogStore.setActiveTab(tab);
    }
}
