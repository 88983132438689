export const bookSessionAsMeetingNotification = {
    title: 'Session Meeting',
    cancelled: 'Session Meeting has been cancelled.',
    error: 'Problem encountered while booking the meeting.',
    updated: 'Session Meeting schedule has been changed to',
    booked: 'Meeting scheduled on ',
};

export const bookSessionAsMeetingModal = {
    title: 'Book a Meeting',
};

export const bookSessionAsMeetingEmail = {
    subjectPrefix: 'ThinkTank Session:',
    contentPrefix: 'Join meeting using the link below:',
};
