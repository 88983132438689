import { FieldValue, Timestamp } from 'firebase/firestore';

import { NodeTimestamp } from './node-timestamp';

export class LinkAccess extends NodeTimestamp {
    id?: string;
    //TODO: DELETE projectId
    projectId?: string;
    password: string;
    projectName?: string;
    sessionName?: string;
    sessionId?: string;
    templateId?: string;
    templateType?: string;
    publicAccessId?: string;
    activityId?: string;
    activityName?: string;
    activityType?: string;
    activityVisible?: boolean;
}

export interface LinkAccessPasswords {
    [id: string]: LinkAccessPassword;
}

export interface LinkAccessPassword {
    password: string;
    created: Timestamp | FieldValue;
    updated: Timestamp | FieldValue;
}

export interface LinkAccessAndPassword {
    linkAccessId?: string;
    linkAccessPassword?: string;
    path?: string;
    qrCodeImageUrl?: string;
}
