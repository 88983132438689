import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ProjectEffects } from './project.effects';
import { PROJECT_FEATURE_KEY, projectReducer } from './project.reducer';
import { ProjectDataService } from './project-data.service';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(PROJECT_FEATURE_KEY, projectReducer),
        EffectsModule.forFeature([ProjectEffects]),
    ],
    exports: [],
    providers: [
        ProjectDataService
    ],
})
export class ProjectModule {}
