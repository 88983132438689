import { Injectable } from '@angular/core';
import firebase from 'firebase/compat';
import { firstValueFrom, map, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AccessControlRole, DBPathHelper, ParentType, TeamMember, TemplateRole, User } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import OrderByDirection = firebase.firestore.OrderByDirection;

@Injectable({
    providedIn: 'root',
})
export class TeamMemberService {
    constructor(private firestoreService: FirestoreService) {}

    addToProject(projectId: string, user: User, role: AccessControlRole): Promise<void> {
        return this.add(ParentType.Projects, projectId, user, role);
    }

    // TODO: Delete after project deprecation
    async inviteTeamMembers(
        projectId: string,
        sessionId: string,
        emails: string[],
        role: AccessControlRole,
    ): Promise<void> {
        const env = window.location.origin;

        return this.firestoreService.cloudFunctionCallable('accessProvisioningNew', {
            role,
            env,
            emails,
            comment: '',
            parentType: ParentType.Sessions,
            projectIds: [projectId],
            sessionIds: [sessionId],
        });
    }

    async inviteTeamMembersNew(sessionId: string, emails: string[], role: AccessControlRole): Promise<void> {
        const env = window.location.origin;

        return this.firestoreService.cloudFunctionCallable('accessProvisioningNew', {
            role,
            env,
            emails,
            comment: '',
            parentType: ParentType.Sessions,
            sessionIds: [sessionId],
        });
    }

    async inviteTeamMembersToTemplate(
        parentType: ParentType,
        parentId: string,
        emailsToInvite: string[],
        inviteMessage: string,
    ): Promise<void> {
        const env = window.location.origin;

        await this.firestoreService.cloudFunctionCallable('accessProvisioningNew', {
            env,
            parentType,
            role: TemplateRole.Collaborator,
            emails: emailsToInvite,
            comment: inviteMessage,
            templateIds: [parentId],
        });
    }

    async addTeamMemberToSession(
        parentType: ParentType,
        parentId: string,
        sessionId: string,
        user: User,
        role: AccessControlRole,
    ): Promise<void> {
        const teamMember = this.buildTeamMember(sessionId, user, role);

        return this.firestoreService.set(
            DBPathHelper.getSessionTeamMemberPath(parentType, parentId, sessionId, user.id),
            {
                ...teamMember,
                created: this.firestoreService.timestamp,
            },
        );
    }

    async addTeamMemberToSessionNew(
        parentType: ParentType,
        sessionId: string,
        user: User,
        role: AccessControlRole,
    ): Promise<void> {
        const teamMember = this.buildTeamMember(sessionId, user, role);

        return this.firestoreService.set(DBPathHelper.getSessionTeamMemberPathNew(parentType, sessionId, user.id), {
            ...teamMember,
            created: this.firestoreService.timestamp,
        });
    }

    buildTeamMember(parentId: string, user: User, role: AccessControlRole): TeamMember {
        return {
            parentId,
            role,
            userId: user.id,
            displayName: user.displayName,
            email: user.email,
            shortName: user.displayName.toLowerCase(),
            imageUrl: user.imageUrl || '',
            created: this.firestoreService.timestamp,
            updated: this.firestoreService.timestamp,
            type: user.type,
            status: user.status,
            lastLogin: user.lastLogin,
        } as TeamMember;
    }

    //TODO: Delete after project deprecation
    async changeProjectRole(projectId: string, userId: string, role: AccessControlRole): Promise<void> {
        const url = window.location.href;
        const env = window.location.origin;

        await this.firestoreService.cloudFunctionCallable('updateUserAccessRole', {
            role,
            url,
            env,
            userId,
            projectId,
            parentType: ParentType.Projects,
        });
    }

    //TODO: Delete after libs/shared/shared-session/src/lib/session-team-tt9/session-team-tt9-facade.ts cleanup
    async changeUserSessionRole(
        projectId: string,
        sessionId: string,
        userId: string,
        role: AccessControlRole,
    ): Promise<void> {
        const url = window.location.href;
        const env = window.location.origin;

        await this.firestoreService.cloudFunctionCallable('updateUserAccessRole', {
            projectId,
            role,
            url,
            env,
            userId,
            sessionId,
            parentType: ParentType.Projects,
        });
    }

    async changeSessionRole(sessionId: string, userId: string, role: AccessControlRole): Promise<void> {
        const env = window.location.origin;
        const url = `${env}/dashboard/sessions/${sessionId}`;

        await this.firestoreService.cloudFunctionCallable('updateUserAccessRole', {
            sessionId,
            role,
            url,
            env,
            userId,
            parentType: ParentType.Sessions,
        });
    }

    // TODO: Remove in TT9-7525
    async deleteUserFromProject(projectId: string, userId: string): Promise<void> {
        await this.firestoreService.cloudFunctionCallable('deleteTeamMember', {
            projectId,
            userId,
            parentType: ParentType.Projects,
        });
    }

    //TODO: Delete after cleanup: libs/shared/shared-session/src/lib/session-team-tt9/session-team-tt9-facade.ts
    // libs/admin/user-admin/src/lib/user-sessions-drilldown/user-sessions-drilldown-facade.ts
    // libs/admin/user-admin/src/lib/project-sessions/project-sessions-facade.ts
    async removeUserFromSession(projectId: string, sessionId: string, userId: string): Promise<void> {
        await this.firestoreService.cloudFunctionCallable('deleteTeamMember', {
            projectId,
            sessionId,
            userId,
            parentType: ParentType.Sessions,
        });
    }

    async deleteUserFromSession(sessionId: string, userId: string, parentType: string): Promise<void> {
        await this.firestoreService.cloudFunctionCallable('deleteTeamMember', {
            sessionId,
            userId,
            parentType,
        });
    }

    async deleteUserFromTemplate(parentType: ParentType, parentId: string, userId: string): Promise<void> {
        // For immediately redirecting after deleting to avoid errors
        await this.firestoreService.updateDoc(DBPathHelper.getTeamMemberPath(parentType, parentId, userId), {
            deleted: true,
        });

        await this.firestoreService.cloudFunctionCallable('deleteTeamMember', {
            parentType,
            parentId,
            userId,
        });
    }

    // TODO: Delete after project deprecation
    getProjectTeamMembers(
        projectId: string,
        sortByField?: keyof TeamMember,
        sortDirection: OrderByDirection = 'asc',
    ): Observable<TeamMember[]> {
        return this.getTeamMembers(ParentType.Projects, projectId, sortByField, sortDirection);
    }

    getTeamMembersLength(projectId: string, sessionId: string): Observable<number> {
        const path = !!sessionId
            ? DBPathHelper.getSessionTeamMemberPath(ParentType.Projects, projectId, sessionId)
            : DBPathHelper.getTeamMemberPath(ParentType.Projects, projectId);
        return this.firestoreService.getCollectionDocumentsCount(path);
    }

    getTeamMembersLengthNew(sessionId: string): Observable<number> {
        const path = DBPathHelper.getSessionTeamMemberPathNew(ParentType.Sessions, sessionId);
        return this.firestoreService.getCollectionDocumentsCount(path);
    }

    async getTeamMemberAttributesInSessions(
        projectId: string,
        sessionIds: string[],
        userId: string,
    ): Promise<Partial<TeamMember>[]> {
        const teamMemberInSessionsArray = [];
        for (const sessionId of sessionIds) {
            const teamMemberInSessions = await firstValueFrom(this.getSessionTeamMember(projectId, sessionId, userId));
            teamMemberInSessionsArray.push(teamMemberInSessions);
        }

        return teamMemberInSessionsArray.map((teamMember) => {
            return {
                parentId: teamMember?.parentId,
                attributes: teamMember?.attributes,
            };
        });
    }

    // TODO: Delete after project deprecation
    getSessionTeamMembers(projectId: string, sessionId: string): Observable<TeamMember[]> {
        return this.firestoreService
            .getCollection(`projects/${projectId}/sessions/${sessionId}/teamMembers`)
            .pipe(map((teamMembers) => teamMembers.map((teamMember) => new TeamMember(teamMember))));
    }

    getSessionTeamMembersNew(
        sessionId: string,
        sortByField?: keyof TeamMember,
        sortDirection: OrderByDirection = 'asc',
    ): Observable<TeamMember[]> {
        return this.getTeamMembers(ParentType.Sessions, sessionId, sortByField, sortDirection);
    }

    getSessionOnLineTeamMembers(projectId: string, sessionId: string): Observable<TeamMember[]> {
        return this.firestoreService.getCollection(`projects/${projectId}/sessions/${sessionId}/teamMembers`).pipe(
            map((teamMembers: TeamMember[]) => {
                return teamMembers.filter((teamMember) => teamMember.isOnline === true);
            }),
        );
    }
    //TODO: Delete after project deprecation
    getSessionTeamMembersWithoutCaching(projectId: string, sessionId: string): Observable<TeamMember[]> {
        return this.firestoreService
            .getCollectionWithoutCaching(`projects/${projectId}/sessions/${sessionId}/teamMembers`)
            .pipe(map((teamMembers) => teamMembers.map((teamMember) => new TeamMember(teamMember))));
    }

    getSessionTeamMembersWithoutCachingNew(sessionId: string): Observable<TeamMember[]> {
        return this.firestoreService
            .getCollectionWithoutCaching(DBPathHelper.getTeamMembersPath(sessionId))
            .pipe(map((teamMembers) => teamMembers.map((teamMember) => new TeamMember(teamMember))));
    }

    //TODO Remove in https://thinktankco.atlassian.net/browse/TT9-6456
    getSessionTeamMember(projectId: string, sessionId: string, userId: string): Observable<TeamMember | null> {
        return this.firestoreService
            .getDocument(`projects/${projectId}/sessions/${sessionId}/teamMembers/${userId}`)
            .pipe(
                map((teamMember) => (teamMember ? new TeamMember(teamMember) : null)),
                catchError(() => of(null)),
            );
    }

    getSessionTeamMemberNew(sessionId: string, userId: string): Observable<TeamMember | null> {
        return this.firestoreService.getDocument(`sessions/${sessionId}/teamMembers/${userId}`).pipe(
            map((teamMember) => (teamMember ? new TeamMember(teamMember) : null)),
            catchError(() => of(null)),
        );
    }

    getSessionTeamMembersByParent(
        parentType: ParentType,
        parentId: string,
        sessionId: string,
    ): Observable<TeamMember[]> {
        const path = [ParentType.ProjectTemplates, ParentType.Templates, ParentType.PublicSessionTemplates].includes(
            parentType,
        )
            ? DBPathHelper.getTeamMemberPath(parentType, parentId)
            : DBPathHelper.getSessionTeamMemberPath(parentType, parentId, sessionId);
        return this.firestoreService
            .getCollection(path)
            .pipe(map((teamMembers) => teamMembers.map((teamMember) => new TeamMember(teamMember))));
    }

    getSessionTeamMemberByParent(
        parentType: ParentType,
        parentId: string,
        sessionId: string,
        userId: string,
    ): Observable<TeamMember | null> {
        const path = [ParentType.Templates, ParentType.PublicSessionTemplates].includes(parentType)
            ? DBPathHelper.getTeamMemberPath(parentType, parentId, userId)
            : DBPathHelper.getSessionTeamMemberPath(parentType, parentId, sessionId, userId);
        return this.firestoreService
            .getDocument(path)
            .pipe(map((teamMember) => (teamMember ? new TeamMember(teamMember) : null)));
    }

    getTemplateTeamMembers(projectId: string): Observable<TeamMember[]> {
        return this.getTeamMembers(ParentType.Templates, projectId);
    }

    getProjectTeamMember(projectId: string, userId: string): Observable<TeamMember | null> {
        return this.get(ParentType.Projects, projectId, userId);
    }

    getProjectTemplateTeamMember(projectId: string, userId: string): Observable<TeamMember | null> {
        return this.get(ParentType.ProjectTemplates, projectId, userId);
    }

    getTeamMemberByParentType(parentType: ParentType, parentId: string, userId: string): Observable<TeamMember> {
        return this.get(parentType, parentId, userId);
    }

    getTeamMembersByParent(
        parentType: ParentType,
        parentId: string,
        sortByField?: keyof TeamMember,
        sortDirection: OrderByDirection = 'asc',
    ): Observable<TeamMember[]> {
        return this.getTeamMembers(parentType, parentId, sortByField, sortDirection);
    }

    getTeamMembersByRole(parentType: ParentType, parentId: string, role: AccessControlRole): Observable<TeamMember[]> {
        return this.firestoreService
            .getDocumentsByPropertyWithoutCaching(DBPathHelper.getTeamMemberPath(parentType, parentId), 'role', role)
            .pipe(
                map((teamMembers) => teamMembers.map((teamMember) => (teamMember ? new TeamMember(teamMember) : null))),
            );
    }

    updateProjectTeamMember(projectId: string, userId: string, data: Partial<TeamMember>): Promise<void> {
        return this.update(ParentType.Projects, projectId, userId, data);
    }

    updateSessionTeamMember(
        projectId: string,
        sessionId: string,
        userId: string,
        data: Partial<TeamMember>,
    ): Promise<void> {
        const path = `projects/${projectId}/sessions/${sessionId}/teamMembers/${userId}`;

        return this.firestoreService.update(path, data);
    }

    updateSessionTeamMemberNew(sessionId: string, userId: string, data: Partial<TeamMember>): Promise<void> {
        const path = `sessions/${sessionId}/teamMembers/${userId}`;

        return this.firestoreService.update(path, data);
    }

    clearOnlineState(projectId: string, sessionId: string, userId: string): Promise<void> {
        const batch = this.firestoreService.createBatch();
        batch.update(this.firestoreService.getDocumentRef<TeamMember>(`projects/${projectId}/teamMembers/${userId}`), {
            isOnline: false,
        });

        if (sessionId) {
            batch.update(
                this.firestoreService.getDocumentRef<TeamMember>(
                    `projects/${projectId}/sessions/${sessionId}/teamMembers/${userId}`,
                ),
                { isOnline: false },
            );
        }

        return batch.commit();
    }

    getTeamMemberByParentTypeAndId(
        parentType: ParentType,
        userId: string,
        sessionId: string,
    ): Observable<TeamMember | null> {
        if (parentType === ParentType.Sessions && !!sessionId) {
            return this.getSessionTeamMemberNew(sessionId, userId);
        }

        return this.get(parentType, sessionId, userId);
    }

    private get(parentType: ParentType, parentId: string, userId: string): Observable<TeamMember | null> {
        return this.firestoreService
            .getDocument(DBPathHelper.getTeamMemberPath(parentType, parentId, userId))
            .pipe(map((teamMember) => (teamMember ? new TeamMember(teamMember) : null)));
    }

    private update(parentType: ParentType, parentId: string, userId: string, data: Partial<TeamMember>): Promise<void> {
        const path = DBPathHelper.getTeamMemberPath(parentType, parentId, userId);

        return this.firestoreService.updateDoc(path, data);
    }

    private add(parentType: ParentType, parentId: string, user: User, role: AccessControlRole): Promise<void> {
        const teamMember = this.buildTeamMember(parentId, user, role);

        return this.firestoreService.addDocumentWithKey<TeamMember>(
            `${DBPathHelper.getTeamMemberPath(parentType, parentId)}`,
            user.id,
            teamMember,
        );
    }

    private getTeamMembers(
        parentType: ParentType,
        parentId: string,
        sortByField?: keyof TeamMember,
        sortDirection: OrderByDirection = 'asc',
    ): Observable<TeamMember[]> {
        return this.firestoreService
            .getCollection(`${DBPathHelper.getTeamMemberPath(parentType, parentId)}`, sortByField, sortDirection)
            .pipe(map((teamMembers) => teamMembers.map((teamMember) => new TeamMember(teamMember))));
    }
}
