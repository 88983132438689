export enum KeyboardNavigationKey {
    ARROW_LEFT = 'ArrowLeft',
    ARROW_RIGHT = 'ArrowRight',
    ARROW_UP = 'ArrowUp',
    ARROW_DOWN = 'ArrowDown',
    PAGE_UP = 'PageUp',
    PAGE_DOWN = 'PageDown',
    DELETE = 'Delete',
    SHIFT = 'Shift',
    ENTER = 'Enter',
    F5 = 'F5',
    R = 'r',
    E = 'e',
    T = 't',
    F = 'f',
}
