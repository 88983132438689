import { Dictionary } from '../dictionary';
import { NodeTemplate, NodeType } from '../node-template';
import { Activity, ActivityType } from './';

export class Proompt extends NodeTemplate implements Activity {
    type = ActivityType.Proompt;

    sessionId?: string;
    projectId?: string;
    templateId?: string;
    visible?: boolean;
    sequence: string;
    sectionId?: string;

    sourceActivityId?: string;
    targetActivityId?: string;
    tags?: Dictionary<string>;

    dndDisabled?: boolean;

    constructor(id: string | undefined, proompt: Partial<Proompt>) {
        super(id, proompt, NodeType.Activity);

        this.sessionId = proompt.sessionId ?? '';
        this.projectId = proompt.projectId ?? '';
        this.templateId = proompt.templateId ?? '';
        this.visible = proompt.visible ?? false;
        this.sequence = proompt.sequence ?? '';
        this.sectionId = proompt.sectionId;
        this.sourceActivityId = proompt.sourceActivityId ?? '';
        this.targetActivityId = proompt.targetActivityId ?? '';
        this.tags = (proompt.tags as Dictionary<string>) ?? {};

        this.dndDisabled = !!proompt.dndDisabled;
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            projectId: this.projectId,
            sessionId: this.sessionId,
            sectionId: this.sectionId,
            templateId: this.templateId,
            sourceActivityId: this.sourceActivityId,
            targetActivityId: this.targetActivityId,
            visible: this.visible,
            sequence: this.sequence,
            type: this.type,
            tags: this.tags,
            created: this.created,
            updated: this.updated,
        };
    }
}
