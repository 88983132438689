import { AccessControlRole } from './access-control';
import { ActivityType } from './activities';
import { Dictionary } from './dictionary';
import { NodeTimestamp } from './node-timestamp';
import { ParentType } from './parent-type';
import { TemplateStatus } from './template';

export class FavoriteAccess extends NodeTimestamp {
    id?: string;
    templateId: string;
    templateType: ParentType;
    name: string;

    description?: string;
    imageUrl?: string;
    status?: TemplateStatus;
    activityType?: ActivityType;

    ownerId: string; // template owner
    ownerDisplayName?: string;
    ownerImageUrl?: string;

    userId: string; // user that added to favorites

    approved?: boolean;
    draftTemplateId?: string;
    publicAccessId?: string;
    creatorNotes?: string;
    hideWelcomeSnackBar?: boolean;
    featured?: boolean;
    timesUsed?: number;
    sessions?: Dictionary<any>;
    tags?: Dictionary<string>;
    clients?: Dictionary<string>;
    phases?: Dictionary<string>;
    subPhases?: Dictionary<string>;
    likes?: Dictionary<boolean>;
    hidden?: boolean;
    metadata?: Dictionary<string>;
    teamMembersIds?: string[]; // PublicAccess collaborators
    role: AccessControlRole; // user's role in private template

    constructor(data: any) {
        super(data);
        this.templateId = data.templateId;
        this.name = data.name;
        this.templateType = data.templateType;
        this.name = data.name;
        this.status = data.status ?? undefined;
        this.activityType = data.activityType ?? undefined;
        this.ownerId = data.ownerId ?? '';
        this.ownerDisplayName = data.ownerDisplayName ?? '';
        this.ownerImageUrl = data.ownerImageUrl ?? undefined;
        this.imageUrl = data.imageUrl ?? undefined;
        this.userId = data.userId ?? undefined;
        this.description = data.description ?? '';
        this.approved = data.approved ?? false;
        this.creatorNotes = data.creatorNotes ?? '';
        this.featured = data.featured ?? false;
        this.timesUsed = data.timesUsed ?? 0;
        this.sessions = data.sessions ?? undefined;
        this.tags = data.tags ?? undefined;
        this.clients = data.clients ?? undefined;
        this.phases = data.phases ?? undefined;
        this.subPhases = data.subPhases ?? undefined;
        this.likes = data.likes ?? undefined;
        this.hidden = data.hidden ?? false;
        this.metadata = data.metadata ?? undefined;
        this.draftTemplateId = data.draftTemplateId ?? undefined;
        this.publicAccessId = data.publicAccessId ?? undefined;
        this.hideWelcomeSnackBar = data.hideWelcomeSnackBar ?? undefined;
        this.teamMembersIds = data.teamMembersIds ?? undefined;
        this.role = data.role ?? undefined;
    }
}
