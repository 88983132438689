import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'accenture-emails-import-warning-dialog',
    templateUrl: './emails-import-warning-dialog.component.html',
    styleUrls: ['./emails-import-warning-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailsImportWarningDialogComponent {
    readonly title = 'Team members import';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { emails: string[] },
        public dialogRef: MatDialogRef<EmailsImportWarningDialogComponent>,
    ) {}

    close(): void {
        this.dialogRef.close();
    }
}
