<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="panelTrigger"
  [cdkConnectedOverlayOpen]="panelOpened"
  [cdkConnectedOverlayWidth]="customWidth ? customWidth + 'px' : '480px'"
  [cdkConnectedOverlayDisableClose]="overlayDisableClose"
  [cdkConnectedOverlayPanelClass]="'tt9-side-panel-container'"
  [cdkConnectedOverlayHeight]="customHeight ? customHeight + 'px' : '100vh'"
  (detach)="closePanel.emit()"
>
  <div
    class="tt9-side-panel"
    accentureClickOutside
    [accentureOutsideDisabled]="outsideClickDisabled"
    (accentureClickOutside)="closePanel.emit()"
  >
    <div
      *ngIf="useDefaultTemplate"
      class="tt9-side-panel-header prevent-outside-click"
      [class.secondary]="secondaryHeader"
    >
      <button
        *ngIf="showBackArrow"
        class="tt9-btn-icon-filled mr-8"
        (click)="$event.stopPropagation(); navigateBack.emit()"
      >
        <accenture-icon>icon-chevron-left</accenture-icon>
      </button>

      <div>
        <h6 class="tt9-h2">{{ panelTitle }}</h6>
        <div *ngIf="isShowAiConcierge" class="d-flex">
          <span class="ai-concierge-label">+ AI Concierge</span>
          <accenture-icon>icon-concierge-figma</accenture-icon>
        </div>
      </div>
      <div class="spacer"></div>

      <ng-content select="[header-actions]"></ng-content>

      <button
        *ngIf="!hideCloseBtn"
        class="tt9-btn-icon-filled"
        [ngClass]="{
          'active secondary-btn': secondaryHeader
        }"
        (click)="closePanel.emit()"
      >
        <accenture-icon>icon-close</accenture-icon>
      </button>
    </div>

    <div
      [ngClass]="{
        'tt9-side-panel-body': useDefaultTemplate,
        'empty-template': !useDefaultTemplate
      }"
    >
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>
