import { allSelectedValue, SortOrders } from '@accenture/shared/data';

export enum FilterBrainstormResponsesOptions {
    All = 'ALL',
    Liked = 'LIKED',
    Commented = 'COMMENTED',
    Bookmarked = 'BOOKMARKED',
}

export const filterBrainstormResponsesValues = {
    [FilterBrainstormResponsesOptions.All]: 'All',
    [FilterBrainstormResponsesOptions.Liked]: 'Liked',
    [FilterBrainstormResponsesOptions.Commented]: 'Commented',
    [FilterBrainstormResponsesOptions.Bookmarked]: 'Bookmarked',
};

export enum SortBrainstormResponsesOptions {
    Added = 'TIME_ADDED',
    Alphabetical = 'ALPHABETICAL',
    Likes = 'LIKES',
    Comments = 'COMMENTS',
}

export const sortBrainstormResponsesValues = {
    [SortBrainstormResponsesOptions.Added]: 'Time Added',
    [SortBrainstormResponsesOptions.Alphabetical]: 'Alphabetical',
    [SortBrainstormResponsesOptions.Likes]: 'Likes',
    [SortBrainstormResponsesOptions.Comments]: 'Comments',
};

export interface BrainstormFilterObject {
    filterOption?: FilterBrainstormResponsesOptions;
    sortOrder?: SortOrders;
    sortOption?: SortBrainstormResponsesOptions;
}

export const initialBrainstormFilterObject: BrainstormFilterObject = {
    filterOption: FilterBrainstormResponsesOptions.All,
    sortOrder: SortOrders.Asc,
    sortOption: SortBrainstormResponsesOptions.Added,
};

export interface BrainstormWorldCloudFilterObject {
    selectedGroups: typeof allSelectedValue | string[];
    filter: FilterBrainstormWordCloudOptions;
}

export enum FilterBrainstormWordCloudOptions {
    All = 'ALL',
    Bookmarked = 'BOOKMARKED',
    NotBookmarked = 'NOT BOOKMARKED',
}

export const initialBrainstormWordCloudFilterObject: BrainstormWorldCloudFilterObject = {
    selectedGroups: allSelectedValue,
    filter: FilterBrainstormWordCloudOptions.All,
};

export const filterBrainstormWordCloudValues = {
    [FilterBrainstormWordCloudOptions.All]: 'All',
    [FilterBrainstormWordCloudOptions.Bookmarked]: 'Bookmarked',
    [FilterBrainstormWordCloudOptions.NotBookmarked]: 'Not Bookmarked',
};
