import { ChangeDetectionStrategy, Component } from '@angular/core';

import { LoadedDescription } from '@accenture/shared/ui';

import { AddProjectDialogFacade } from './add-project-dialog-facade';

@Component({
    selector: 'accenture-add-project-dialog',
    templateUrl: './add-project-dialog.component.html',
    styleUrls: ['./add-project-dialog.component.scss'],
    providers: [AddProjectDialogFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

//TODO DELETE WHEN COLLECTION FEATURE WILL BE FINISHED
export class AddProjectDialogComponent {
    loaderText: LoadedDescription;
    vm$ = this.facade.vm$;

    constructor(private facade: AddProjectDialogFacade) {}

    closeCurrentDialog(): void {
        this.facade.closeCurrentDialog();
    }

    updateSearchValue(searchValue: string): void {
        this.facade.updateSearchValue(searchValue);
    }

    updateSelectedTemplateId(selectedTemplateId: string): void {
        this.facade.updateSelectedTemplateId(selectedTemplateId);
    }

    createProject(): void {
        this.facade.createProject();
    }
}
