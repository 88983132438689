import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, map, startWith } from 'rxjs';

import { OptionsStore } from '@accenture/erp-deployment/shared/domain';
import { FileType, SessionOptions } from '@accenture/shared/data';

export interface SessionFormModel {
    currentOptionToDisplay: SessionOptions | null;
}

export const defaultModelValues: SessionFormModel = {
    currentOptionToDisplay: null,
};

@Injectable()
export class SessionFormFacade {
    private optionsStore = inject(OptionsStore);

    vm$ = this.buildViewModel();

    private sessionImage$ = new BehaviorSubject<FileType>(undefined);

    setSessionImage(sessionImage: FileType): void {
        this.sessionImage$.next(sessionImage);
    }

    backToSessionForm(): void {
        this.optionsStore.setCurrentOptionToDisplay(null);
    }

    resetOptions(): void {
        this.optionsStore.resetSessionOptions();
        this.optionsStore.resetSessionOptionsToCreate();
        this.optionsStore.setCurrentOptionToDisplay(null);
    }

    private buildViewModel() {
        return this.optionsStore.currentOptionToDisplay$.pipe(
            distinctUntilChanged(),
            map((currentOptionToDisplay) => {
                return {
                    currentOptionToDisplay,
                };
            }),
            startWith(defaultModelValues),
        );
    }
}
