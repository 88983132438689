import { Inject, Injectable } from '@angular/core';
import { app, authentication } from '@microsoft/teams-js';
import jwt_decode from 'jwt-decode';

import { FredEnvironment } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

interface TokenResopnse {
    token: string;
    azure_access_token: string;
}

interface TokenData {
    email: string;
    firstName: string;
    lastName: string;
    azureParameters: {
        clientId: string;
        clientSecret: string;
        tenancyId: string;
        token: string;
    };
}

@Injectable({
    providedIn: 'root',
})
export class TeamsService {
    public inTeams = false;

    constructor(
        @Inject('environment') private environment: FredEnvironment,
        private firestoreService: FirestoreService,
    ) {
        this.initialize();
    }

    async initialize(): Promise<boolean> {
        try {
            await app.initialize();
            this.inTeams = true;
        } catch (error) {
            console.info('Not in MS Teams.');
        }
        return this.inTeams;
    }

    async getToken(): Promise<string> {
        return authentication.getAuthToken();
    }

    async authenticate(): Promise<any> {
        if (!this.inTeams) {
            return;
        }

        const teamsAuthToken = await this.getToken();
        const user = this.decodeTeamsToken(teamsAuthToken);

        return this.firestoreService.cloudFunctionCallable('generateIdToken', user as any);
    }

    decodeTeamsToken(token: string): TokenData {
        const data: any = jwt_decode(token);
        const { teams, sso } = this.environment;

        const clientId = teams?.clientId || sso.clientId;
        const clientSecret = teams?.clientSecret || sso.clientSecret;

        return {
            email: data.upn.toLowerCase(),
            firstName: data.given_name,
            lastName: data.family_name,
            azureParameters: {
                clientId,
                clientSecret,
                tenancyId: data.tid,
                token: token,
            },
        };
    }
}
