<ng-container *ngIf="vm$ | async as vm">
  <main class="maintenance-wrapper">
    <div class="maintenance-container">
      <section class="d-flex-column-center mb-24">
        <img src="/assets/shared-ui/svg/maintenance.svg" />
      </section>
      <section class="d-flex-column-center">
        <header class="mb-16 maintenance-header">
          <h3 class="tt9-h3">{{ vm.title }}</h3>
        </header>
        <article class="tt9-h4 tt9-color-neutral-500 maintenance-message">
          {{ vm.message }}
        </article>
      </section>
    </div>
  </main>
</ng-container>
