import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isEqual } from 'lodash';
import { distinctUntilChanged, Observable, of } from 'rxjs';

import {
    Activity,
    ActivityType,
    BrainstormTab,
    ChartType,
    DBPathHelper,
    ParentType,
    SessionFocus,
    SessionFocusSteps,
} from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

import { NavigateParams, RedirectionScreen } from '../models';

@Injectable({
    providedIn: 'root',
})
export class SessionFocusService {
    constructor(private firestoreService: FirestoreService, private router: Router) {}

    // TODO: Delete after project deprecation
    getSessionFocus(projectId: string, sessionId: string, parentType?: ParentType): Observable<SessionFocus | null> {
        return this.firestoreService
            .getDocument<SessionFocus>(DBPathHelper.getSessionFocusPath(projectId, sessionId, parentType))
            .pipe(distinctUntilChanged(isEqual));
    }

    getSessionFocusNew(
        sessionId: string,
        parentType: ParentType = ParentType.Sessions,
    ): Observable<SessionFocus | null> {
        if (
            [
                ParentType.ActivityTemplates,
                ParentType.Templates,
                ParentType.PublicSessionTemplates,
                ParentType.PublicActivityTemplates,
            ].includes(parentType)
        ) {
            return of(null);
        }
        return this.firestoreService
            .getDocument<SessionFocus>(DBPathHelper.getSessionFocusPathNew(sessionId))
            .pipe(distinctUntilChanged(isEqual));
    }

    // TODO: Delete after project deprecation
    async setSessionFocus(projectId: string, sessionId: string, sessionFocus: SessionFocus): Promise<void> {
        sessionFocus.updated = this.firestoreService.timestamp;
        await this.firestoreService.set(DBPathHelper.getSessionFocusPath(projectId, sessionId), sessionFocus);
    }

    async setSessionFocusNew(sessionId: string, sessionFocus: SessionFocus): Promise<void> {
        sessionFocus.updated = this.firestoreService.timestamp;
        await this.firestoreService.set(DBPathHelper.getSessionFocusPathNew(sessionId), sessionFocus);
    }

    async deleteSessionFocus(sessionId: string): Promise<void> {
        await this.firestoreService.delete(DBPathHelper.getSessionFocusPathNew(sessionId));
    }

    redirectToWaitingScreen(projectId: string, sessionId: string): Promise<boolean> {
        const urlArray = ['project', projectId, 'session', sessionId, 'waiting-screen'];

        return this.router.navigate(urlArray);
    }

    redirectToWaitingScreenNew(sessionId: string): Promise<boolean> {
        const urlArray = ['session', sessionId, 'waiting-screen'];

        return this.router.navigate(urlArray);
    }

    redirectToSessionDashboardWaitingScreen(sessionId: string): Promise<boolean> {
        const urlArray = ['dashboard', 'sessions', sessionId, 'waiting-screen'];

        return this.router.navigate(urlArray);
    }

    getNextActivity(focusActivity: Activity | undefined, activities: Activity[]): Activity | undefined {
        const currentActivityIndex = activities?.findIndex((activity) => activity.id === focusActivity.id);

        return activities?.[currentActivityIndex + 1];
    }

    getActivityRedirectUrl(projectId: string, sessionId: string, focus: SessionFocus): NavigateParams {
        let urlParts: string[] = [];
        let params = { queryParams: { ...focus.focusMapIds } };
        switch (focus.activityType) {
            case ActivityType.Vote:
                urlParts = this.redirectVoteUrl(projectId, sessionId, focus);
                break;
            case ActivityType.QuickPoll:
                params
                    = focus.focusStep === SessionFocusSteps.PollSummary
                        ? { queryParams: { activeTab: focus.activeTab } as { activeTab: ChartType } }
                        : { queryParams: {} };
                urlParts = this.redirectPollUrl(projectId, sessionId, focus);
                break;
            case ActivityType.Present:
                urlParts = this.redirectPresent(projectId, sessionId, focus);
                break;
            case ActivityType.Brainstorm:
                urlParts = this.redirectBrainStorm(projectId, sessionId, focus);
                break;
            case ActivityType.Table:
                urlParts = this.redirectTableUrl(projectId, sessionId, focus);
                break;
            case ActivityType.Spark:
                urlParts = this.redirectSpark(projectId, sessionId, focus);
                break;

            case ActivityType.LiveShare:
                urlParts = this.redirectLiveshare(projectId, sessionId, focus);
                break;

            case ActivityType.Whiteboard:
                urlParts = this.redirectWhiteboard(projectId, sessionId, focus);
                break;

            case ActivityType.Diagram:
                urlParts = this.redirectDiagram(projectId, sessionId, focus);
                break;

            default:
                console.error('Focus is wrong!');
        }

        const url = urlParts.join('/');
        return { url, params };
    }

    private redirectVoteUrl(projectId: string, sessionId: string, focus: SessionFocus): string[] {
        const isVoteSummary = focus.focusStep === SessionFocusSteps.VoteSummary;
        const url = [
            `project/${projectId}/session/${sessionId}`,
            isVoteSummary ? 'vote-summary' : 'vote',
            focus.activityId,
        ];

        if (isVoteSummary && focus?.activeTab) {
            url.push(focus.activeTab);
        }

        return url;
    }

    private redirectVoteUrlNew(sessionId: string, focus: SessionFocus): string[] {
        const isVoteSummary = focus.focusStep === SessionFocusSteps.VoteSummary;
        const url = [`session/${sessionId}`, isVoteSummary ? 'vote-summary' : 'vote', focus.activityId];

        if (isVoteSummary && focus?.activeTab) {
            url.push(focus.activeTab);
        }

        return url;
    }

    getActivityRedirectUrlNew(sessionId: string, focus: SessionFocus): NavigateParams {
        let urlParts: string[] = [];
        let params = { queryParams: { ...focus.focusMapIds } };
        switch (focus.activityType) {
            case ActivityType.Vote:
                urlParts = this.redirectVoteUrlNew(sessionId, focus);
                break;
            case ActivityType.QuickPoll:
                params
                    = focus.focusStep === SessionFocusSteps.PollSummary
                        ? { queryParams: { activeTab: focus.activeTab } as { activeTab: ChartType } }
                        : { queryParams: {} };
                urlParts = this.redirectPollUrlNew(sessionId, focus);
                break;
            case ActivityType.Present:
                urlParts = this.redirectPresentNew(sessionId, focus);
                break;
            case ActivityType.Brainstorm:
                urlParts = this.redirectBrainStormNew(sessionId, focus);
                break;
            case ActivityType.Table:
                urlParts = this.redirectTableUrlNew(sessionId, focus);
                break;
            case ActivityType.Spark:
                urlParts = this.redirectSparkNew(sessionId, focus);
                break;

            case ActivityType.LiveShare:
                urlParts = this.redirectLiveshareNew(sessionId, focus);
                break;
            default:
                console.error('Focus is wrong!');
        }

        const url = urlParts.join('/');
        return { url, params };
    }

    private redirectPollUrl(projectId: string, sessionId: string, focus: SessionFocus): string[] {
        return [
            `project/${projectId}/session/${sessionId}`,
            'poll',
            focus.activityId,
            ...(focus.focusStep === SessionFocusSteps.PollSummary ? ['summary'] : []),
        ];
    }

    private redirectPollUrlNew(sessionId: string, focus: SessionFocus): string[] {
        return [
            `session/${sessionId}`,
            'poll',
            focus.activityId,
            ...(focus.focusStep === SessionFocusSteps.PollSummary ? ['summary'] : []),
        ];
    }

    private redirectTableUrl(projectId: string, sessionId: string, focus: SessionFocus): string[] {
        const step = {
            [SessionFocusSteps.Table]: RedirectionScreen.Default,
            [SessionFocusSteps.TableValidate]: RedirectionScreen.Validation,
            [SessionFocusSteps.TableSummary]: RedirectionScreen.Summary,
        }[focus.focusStep];
        return [`project/${projectId}/session/${sessionId}`, 'table', focus.activityId, step];
    }

    private redirectTableUrlNew(sessionId: string, focus: SessionFocus): string[] {
        const step = {
            [SessionFocusSteps.Table]: RedirectionScreen.Default,
            [SessionFocusSteps.TableValidate]: RedirectionScreen.Validation,
            [SessionFocusSteps.TableSummary]: RedirectionScreen.Summary,
        }[focus.focusStep];
        return [`session/${sessionId}`, 'table', focus.activityId, step];
    }

    private redirectPresent(projectId: string, sessionId: string, focus: SessionFocus): string[] {
        return [`project/${projectId}/session/${sessionId}`, 'present', focus.activityId];
    }

    private redirectPresentNew(sessionId: string, focus: SessionFocus): string[] {
        return [`session/${sessionId}`, 'present', focus.activityId];
    }

    private redirectBrainStorm(projectId: string, sessionId: string, focus: SessionFocus): string[] {
        // TODO: Will be refactored in TT9-4065
        return [`project/${projectId}/session/${sessionId}`, 'brainstorm', focus.activityId, BrainstormTab.Responses];
    }

    private redirectBrainStormNew(sessionId: string, focus: SessionFocus): string[] {
        return [`session/${sessionId}`, 'brainstorm', focus.activityId, BrainstormTab.Responses];
    }

    private redirectSpark(projectId: string, sessionId: string, focus: SessionFocus): string[] {
        return [`project/${projectId}/session/${sessionId}`, 'spark', focus.activityId];
    }

    private redirectSparkNew(sessionId: string, focus: SessionFocus): string[] {
        return [`session/${sessionId}`, 'spark', focus.activityId];
    }

    private redirectLiveshare(projectId: string, sessionId: string, focus: SessionFocus): string[] {
        return [`project/${projectId}/session/${sessionId}`, 'liveshare', focus.activityId];
    }

    private redirectLiveshareNew(sessionId: string, focus: SessionFocus): string[] {
        return [`session/${sessionId}`, 'liveshare', focus.activityId];
    }

    private redirectWhiteboard(projectId: string, sessionId: string, focus: SessionFocus): string[] {
        return [`project/${projectId}/session/${sessionId}`, 'whiteboard', focus.activityId];
    }

    private redirectDiagram(projectId: string, sessionId: string, focus: SessionFocus): string[] {
        return [`project/${projectId}/session/${sessionId}`, 'diagram', focus.activityId];
    }
}
