import { Injectable } from '@angular/core';

import { BotActions } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable({
    providedIn: 'root',
})
export class AIGenerateService {
    constructor(private firestoreService: FirestoreService) {}

    async aiGenerateData<T, D>(
        prompt: string,
        botAction: BotActions,
        projectId: string,
        userId: string,
    ): Promise<{ notification: string; project: T; sessions?: D[] }> {
        return await this.firestoreService.cloudFunctionCallable<{ notification: string; project: T; sessions: D[] }>(
            'aiGenerateData',
            {
                prompt,
                botAction,
                projectId,
                userId,
            },
        );
    }

    async aiSaveGeneratedData<T>(
        dataToSave: T,
        botAction: BotActions,
        projectId: string,
        userId: string,
    ): Promise<{ notification: { title: string; message: string }; projectId?: string }> {
        return await this.firestoreService.cloudFunctionCallable('aiGeneratedDataSave', {
            dataToSave,
            botAction,
            projectId,
            userId,
        });
    }

    async aiGenerateActivityData(
        prompt: string,
        action: BotActions,
        sessionId: string,
        activityId: string,
        projectId: string,
        userId: string,
    ): Promise<{ notification: string; count?: number }> {
        return await this.firestoreService.cloudFunctionCallable(
            'aiAutoBuilder',
            {
                userId,
                projectId,
                sessionId,
                activityId,
                action,
                prompt,
            },
        );
    }
}
