import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { isUndefined } from 'lodash';
import { combineLatest, firstValueFrom, Observable, of } from 'rxjs';
import { catchError, map, skipWhile, switchMap } from 'rxjs/operators';

import { AppState, isHomePage, selectAuthenticatedUserId } from '@accenture/global-store';
import { notFoundUrl, ParentType, publicParentTypes, TeamMember } from '@accenture/shared/data';

import { ProjectService } from '../services/project.service';
import { PublicTemplateService } from '../services/public-template.service';
import { SessionService } from '../services/session.service';
import { TeamMemberService } from '../services/team-member.service';

@Injectable()
export class TeamMemberGuard {
    constructor(
        private router: Router,
        private store: Store<AppState>,
        private teamMemberService: TeamMemberService,
        private publicTemplateService: PublicTemplateService,
        private projectService: ProjectService,
        private sessionService: SessionService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const { projectId, sessionId, templateId, activityId, collectionId } = route.params;
        const { parentType } = route.data;

        if (publicParentTypes.includes(parentType)) {
            return this.verifyUserAccessByOwnerId(projectId || sessionId || templateId || activityId);
        }

        if (parentType === ParentType.ActivityTemplates) {
            return this.verifyUserAccessByParentId(parentType, activityId);
        }

        if (parentType === ParentType.Templates) {
            return this.verifyUserAccessByParentId(parentType, templateId);
        }

        // TODO: REMOVE WHEN COLLECTIONS WILL BE IMPLEMENTED
        if (parentType === ParentType.Projects) {
            return this.checkIsProjectMarkedAsDeleted(projectId);
        }

        if (parentType === ParentType.Collections) {
            // TODO: update with checks
            return of(true);
        }

        if (!!sessionId) {
            return this.verifyUserAccessBySessionId(parentType, projectId, sessionId);
        }

        return this.verifyUserAccessByParentId(parentType, projectId);
    }

    private checkIsProjectMarkedAsDeleted(projectId: string): Observable<boolean> {
        return this.projectService.getProject(ParentType.Projects, projectId).pipe(
            map(project => {
                if (!project || project?.markedForDelete) {
                    throw 'Project not found';
                }

                if (!!project?.markedForDelete) {
                    this.redirectToHome();
                    return false;
                }
            }),
            switchMap(() => this.verifyUserAccessByParentId(ParentType.Projects, projectId)),
            catchError(() => {
                this.router.navigateByUrl(notFoundUrl);
                return of(false);
            }),
        );
    }

    private verifyUserAccessBySessionId(
        parentType: ParentType,
        projectId: string,
        sessionId: string,
    ): Observable<boolean> {
        return combineLatest([
            this.store.select(selectAuthenticatedUserId),
            this.sessionService.getSession(parentType, projectId, sessionId),
        ]).pipe(
            skipWhile(([userId]) => isUndefined(userId)),
            switchMap(([userId, session]) => {
                if (!session || session?.markedForDelete) {
                    throw 'Session not found';
                }

                if (!userId || !session?.projectId) {
                    return this.preventNavigation();
                }

                return this.checkTeamMember(parentType, session.projectId, userId);
            }),
            catchError(() => {
                this.router.navigateByUrl(notFoundUrl);
                return of(false);
            }),
        );
    }

    private verifyUserAccessByParentId(parentType: ParentType, parentId: string): Observable<boolean> {
        return this.store.pipe(
            select(selectAuthenticatedUserId),
            skipWhile(userId => isUndefined(userId)),
            switchMap((userId: string | undefined) => {
                if (!userId) {
                    return this.preventNavigation();
                }
                return this.checkTeamMember(parentType, parentId, userId);
            }),
        );
    }

    private verifyUserAccessByOwnerId(parentId: string): Observable<boolean> {
        return this.store.select(selectAuthenticatedUserId).pipe(
            switchMap(userId =>
                combineLatest([
                    this.publicTemplateService.getIsPublicAccessExistByTemplateIdAndUserId(parentId, userId),
                    this.publicTemplateService.getIsPublicAccessExistByTemplateIdAndCollaboratorId(parentId, userId),
                ]).pipe(
                    catchError(() => this.router.navigateByUrl(notFoundUrl)),
                    map(([existByOwner, existByCollaborator]: [boolean, boolean]) => {
                        return existByOwner || existByCollaborator || false;
                    }),
                ),
            ),
        );
    }

    private checkTeamMember(parentType: ParentType, parentId: string, userId: string): Observable<boolean> {
        const teamMember$
            = parentType === ParentType.Projects
                ? this.teamMemberService.getProjectTeamMember(parentId, userId)
                : parentType === ParentType.ProjectTemplates
                ? this.teamMemberService.getProjectTemplateTeamMember(parentId, userId)
                : this.teamMemberService.getTeamMemberByParentType(parentType, parentId, userId);
        return teamMember$.pipe(
            catchError(() => this.router.navigateByUrl(notFoundUrl)),
            map((teamMember: TeamMember) => {
                if (!teamMember) {
                    this.router.navigateByUrl(notFoundUrl);
                    throw 'Team Member not found';
                }

                return true;
            }),
            catchError(() => {
                this.router.navigateByUrl(notFoundUrl);
                return of(false);
            }),
        );
    }

    private async redirectToHome(): Promise<void> {
        const isOnHomePage = await firstValueFrom(this.store.select(isHomePage));
        if (!isOnHomePage) {
            this.router.navigate(['home']);
        }
    }

    private preventNavigation(): Observable<boolean> {
        this.router.navigateByUrl(notFoundUrl);
        return of(false);
    }
}
