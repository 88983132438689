<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading">
    <div class="activities-header">
      <h2 *ngIf="!vm.isSearchShown" class="tt9-h2 activities-title">
        Activities <span *ngIf="vm.activitiesCount" class="activities-count">{{ vm.activitiesCount }}</span>
      </h2>

      <div *ngIf="vm.isSearchShown" class="activities-search expanded">
        <mat-form-field appearance="outline" class="tt9-form-field no-label no-margin width-100" floatLabel="always">
          <input
            #search
            matInput
            [formControl]="searchControl"
            [placeholder]="inputPlaceholders.search"
            (input)="filterActivities(search.value)"
          />
          <accenture-icon matPrefix>icon-search</accenture-icon>
          <accenture-icon *ngIf="vm.searchValue" matSuffix (click)="filterActivities('')">icon-close</accenture-icon>
        </mat-form-field>
      </div>

      <div *ngIf="vm.isSessionEditor || vm.activities - count" class="activities-header-buttons">
        <div class="activities-panel" #activitiesList>
          <button
            *ngIf="vm.activitiesCount > 0"
            class="tt9-btn-icon-filled"
            matTooltipPosition="above"
            matTooltipClass="tt9-arrow-tooltip-below"
            [matTooltip]="tooltipTexts.search"
            [class.active]="vm.isSearchShown"
            (click)="toggleShowSearch(activitiesList, vm.isSearchShown)"
          >
            <accenture-icon>icon-search</accenture-icon>
          </button>
        </div>

        <accenture-ai-auto-builder-button [displayMode]="'button'"></accenture-ai-auto-builder-button>
        <button class="tt9-btn btn-icon" (click)="toggleActivitiesPanel(true, $event)">
          <accenture-icon>icon-add</accenture-icon>
          <span>Create Activity</span>
        </button>

        <button *ngIf="vm.activitiesCount > 0" class="tt9-btn-default btn-icon" (click)="openAddConnectionDialog()">
          <accenture-icon>icon-connection</accenture-icon>
          <span>Create Connections</span>
        </button>
      </div>
    </div>

    <div #tableElement class="activities-list-wrapper" [ngClass]="{ 'panel-opened': vm.isActivitiesPanelOpened }">
      <!-- Table for desktops and tablets -->
      <ng-container *ngIf="isVisibleForDesktopsAndTablets(vm.isAllHandsetsScreen, vm.isSessionEditor)">
        <div class="activities-list-header">
          <div class="d-flex activities-list-left-alignment">
            <div class="activities-list-cell short-cell tt9-body-2">#</div>
            <div
              class="activities-list-cell type-cell tt9-body-2"
              [ngClass]="{ 'adjusted-width': vm.isTabletPortrait && vm.isSessionEditor }"
            >
              Type
            </div>
            <div [ngClass]="{ 'fixed-cell': !vm.isSessionEditor }" class="activities-list-cell wide-cell tt9-body-2">
              Name & Description
            </div>
          </div>
          <div class="activities-list-right-alignment">
            <div *ngIf="vm.isSessionEditor" class="activities-list-cell medium-cell tt9-body-2">Connections</div>
            <div
              *ngIf="vm.isSessionEditor"
              class="activities-list-cell actions-cell"
              [ngClass]="{
                'column-actions-active': vm.isSessionEditor
              }"
            ></div>
          </div>
        </div>
        <div
          *ngIf="vm.activities.length > 0; else noActivities"
          class="activities-list-body"
          cdkDropList
          id="session-activities-list"
          [cdkDropListData]="vm.activities"
          [cdkDropListSortingDisabled]="true"
          (cdkDropListDropped)="dropped($event)"
        >
          <ng-container *ngFor="let activity of vm.activities; trackBy: trackById; let i = index">
            <!-- Drag'n'drop target placeholders (show place of dragged element to move to) -->
            <div
              *ngIf="showPlaceholderBefore(i, 'session-activities-list')"
              class="tt9-dnd-placeholder-before activities-list-placeholder-before"
            ></div>
            <!-- /Drag'n'drop target placeholders (show place of dragged element to move to) -->

            <div
              #activityRow
              [id]="'activity' + activity.id"
              class="activities-list-row"
              [ngClass]="{
                focused: vm.sessionFocus?.activityId === activity.id,
                hidden: !activity.visible,
                disabled: isNavigationDisabled(vm.session?.selfNavigate, vm.isSessionEditor),
                preview: selectedActivityForPreview?.id === activity.id
              }"
              cdkDrag
              [cdkDragPreviewContainer]="'parent'"
              [cdkDragData]="activity"
              [cdkDragDisabled]="activity.dndDisabled || !vm.isSessionEditor"
              (mouseenter)="setDragHoverIndex(i, 'session-activities-list')"
              (mouseleave)="setDragHoverIndex(undefined, 'session-activities-list')"
              (cdkDragStarted)="dragStarted($event, i)"
              (cdkDragEnded)="dragEnded($event, i)"
              (click)="navigateToActivity($event, activity, vm.session, vm.isSessionEditor, vm.isPublicTemplate)"
            >
              <!-- Drag'n'drop preview (current moving element) -->
              <div *cdkDragPreview class="tt9-dnd-preview-content">
                <p class="text-overflow-ellipsis">{{ activity.name }}</p>
              </div>
              <!-- /Drag'n'drop preview (current moving element) -->

              <!-- Drag'n'drop initial placeholder (show and disable element that is currently moving) -->
              <div *cdkDragPlaceholder class="tt9-dnd-initial-placeholder-wrapper">
                <div class="tt9-dnd-initial-placeholder" [style]="{ height: activityRow.offsetHeight + 'px' }"></div>
              </div>
              <!-- /Drag'n'drop initial placeholder (show and disable element that is currently moving) -->

              <div
                *ngIf="vm.isSessionEditor"
                class="add-activity-line"
                [style.width]="tableElement.clientWidth + 'px'"
                (click)="addActivityBetween(activity.sequence, vm.activities[i + 1]?.sequence, i + 1, $event)"
              >
                <div class="add-activity-btn">
                  <accenture-icon>icon-add</accenture-icon>
                </div>
              </div>

              <div class="d-flex" [class.activities-list-left-alignment]="vm.isSessionEditor">
                <div class="activities-list-cell short-cell tt9-body-2">
                  <span class="activities-list-cell-count">{{ i + 1 }}</span>
                </div>

                <div
                  class="activities-list-cell type-cell tt9-body-1"
                  [ngClass]="{ 'adjusted-width': vm.isTabletPortrait && vm.isSessionEditor }"
                >
                  <span class="d-flex activities-list-type" [class.activities-list-type-leader]="vm.isSessionEditor">
                    <accenture-icon
                      class="activity-icon mr-8"
                      [class.show]="!vm.isSessionEditor"
                      [colorType]="
                        getIconColor(
                          isNavigationDisabled(vm.session?.selfNavigate, vm.isSessionEditor),
                          vm.sessionFocus?.activityId === activity.id
                        )
                      "
                      >{{ activity.type | displayActivityIcon : vm.activityConfigurationsMap }}</accenture-icon
                    >
                    <accenture-icon
                      *ngIf="vm.isSessionEditor"
                      class="drag-handler mr-8"
                      [colorType]="activity.dndDisabled ? iconColor.Default : iconColor.Secondary"
                      cdkDragHandle
                      >icon-drag-vertical</accenture-icon
                    >
                    <span *ngIf="!(vm.isTabletPortrait && vm.isSessionEditor)">{{
                      activity.type | displayActivityType : vm.activityConfigurationsMap
                    }}</span>
                  </span>
                </div>

                <div class="d-flex-column activities-list-cell wide-cell tt9-body-medium-1">
                  <div
                    #activityName
                    accentureTooltip
                    class="text-overflow-ellipsis-2 activities-list-cell-name h3"
                    matTooltipPosition="below"
                    matTooltipClass="tt9-arrow-tooltip-above"
                    [matTooltip]="activity.name"
                    [matTooltipDisabled]="isDisabledTooltip(activityName)"
                  >
                    {{ activity.name }}
                  </div>
                  <div
                    #activityDescription
                    accentureTooltip
                    class="text-overflow-ellipsis-2 activities-list-cell-description body-small"
                    matTooltipPosition="below"
                    matTooltipClass="tt9-arrow-tooltip-above"
                    [matTooltip]="activity.description"
                    [matTooltipDisabled]="isDisabledTooltip(activityDescription)"
                  >
                    {{ activity.description }}
                  </div>
                </div>

                <div
                  *ngIf="vm.isSessionEditor && activity.type === activityType.Proompt"
                  class="activities-list-cell medium-cell tt9-body-2 d-flex-column"
                >
                  <p class="text-overflow-ellipsis width-100">
                    {{ getActivityName(vm.activities, activity.targetActivityId) }}
                  </p>
                </div>
              </div>

              <div class="d-flex-between" [class.activities-list-right-alignment]="vm.isSessionEditor">
                <div *ngIf="vm.isSessionEditor" class="activities-list-cell medium-cell tt9-body-2 d-flex-column">
                  <div
                    *ngIf="isDataTakenFromCurrentSession(vm.connectionsByDestinationId[activity.id])"
                    class="width-100 d-flex pb-4"
                  >
                    <accenture-icon [size]="iconSize.Small" [colorType]="iconColor.Secondary">icon-home</accenture-icon>
                    <div
                      class="activities-list-cell-connection text-overflow-ellipsis width-100 pl-8"
                      matTooltipPosition="above"
                      matTooltipClass="tt9-arrow-tooltip-below activity-list-tooltip"
                      [matTooltip]="
                        getConnectionSessionLabel(vm.connectionsByDestinationId[activity.id], vm.sessionNamesById)
                      "
                    >
                      {{ getConnectionSessionLabel(vm.connectionsByDestinationId[activity.id], vm.sessionNamesById) }}
                    </div>
                  </div>

                  <ng-container *ngIf="isDataTakenFromCurrentSession(vm.connectionsByDestinationId[activity.id])">
                    <div
                      *ngIf="vm.connectionsByDestinationId[activity.id]?.length"
                      class="width-100"
                      accentureTooltip
                      [accentureTooltipDefaultBehavior]="true"
                      [accentureTooltipPosition]="TooltipPosition.Top"
                      [accentureTooltipData]="{
                        sourceSession: isDataTakenFromCurrentSession(vm.connectionsByDestinationId[activity.id])
                          ? getConnectionSessionLabel(vm.connectionsByDestinationId[activity.id], vm.sessionNamesById)
                          : '',
                        connectionsIds: vm.connectionsByDestinationId[activity.id]?.[0]?.sourceIds || [],
                        activitiesById: vm.activitiesById || {},
                        connectionCriteria: getConnectionCriteriaLabel(vm.connectionsByDestinationId[activity.id]) || '',
                      }"
                      [accentureTooltipTemplate]="dataFlowTooltipComponent"
                    >
                      <ng-container
                        *ngFor="let connectionId of vm.connectionsByDestinationId[activity.id]?.[0]?.sourceIds; trackBy: trackById; let first = first;"
                      >
                        <div *ngIf="first" class="width-100 d-flex">
                          <accenture-icon [size]="iconSize.Small" [colorType]="iconColor.Secondary"
                            >icon-corner-left-up</accenture-icon
                          >
                          <div class="activities-list-cell-connection text-overflow-ellipsis width-100 pl-8">
                            {{vm.activitiesById?.[connectionId]?.name}}
                          </div>
                        </div>
                      </ng-container>
                      <div *ngIf="vm.connectionsByDestinationId[activity.id]?.[0]?.sourceIds?.length > 1" class="pl-24">
                        ...
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="!isDataTakenFromCurrentSession(vm.connectionsByDestinationId[activity.id])">
                    <div
                      *ngIf="vm.connectionsByDestinationId[activity.id]?.length"
                      class="width-100"
                      accentureTooltip
                      matTooltipPosition="above"
                      [accentureTooltipDefaultBehavior]="true"
                      [accentureTooltipPosition]="TooltipPosition.Top"
                      [accentureTooltipTemplate]="dataFlowTooltipComponent"
                      [accentureTooltipData]="{
                        sourceSession: isDataTakenFromCurrentSession(vm.connectionsByDestinationId[activity.id])
                          ? getConnectionSessionLabel(vm.connectionsByDestinationId[activity.id], vm.sessionNamesById)
                          : '',
                        connectionsIds: vm.connectionsByDestinationId[activity.id]?.[0]?.sourceIds || [],
                        activitiesById: vm.activitiesById || {},
                        connectionCriteria: getConnectionCriteriaLabel(vm.connectionsByDestinationId[activity.id]) || '',
                      }"
                    >
                      <ng-container
                        *ngFor="let connectionId of vm.connectionsByDestinationId[activity.id]?.[0]?.sourceIds; trackBy: trackById; let i = index"
                      >
                        <div *ngIf="i === 0" class="width-100 d-flex">
                          <accenture-icon [size]="iconSize.Small" [colorType]="iconColor.Secondary"
                            >icon-corner-left-up</accenture-icon
                          >
                          <div class="activities-list-cell-connection text-overflow-ellipsis width-100 pl-8">
                            {{vm.activitiesById?.[connectionId]?.name}}
                          </div>
                        </div>
                      </ng-container>
                      <div *ngIf="vm.connectionsByDestinationId[activity.id]?.[0]?.sourceIds?.length > 1" class="pl-24">
                        ...
                      </div>
                    </div>
                  </ng-container>

                  <div *ngIf="vm.connectionsByDestinationId[activity.id]?.length" class="width-100 d-flex pt-4">
                    <accenture-icon [size]="iconSize.Small" [colorType]="iconColor.Secondary">icon-done</accenture-icon>
                    <div class="activities-list-cell-connection text-overflow-ellipsis width-100 pl-8">
                      {{ getConnectionCriteriaLabel(vm.connectionsByDestinationId[activity.id]) }}
                    </div>
                  </div>
                </div>

                <div class="activities-list-cell actions-cell d-flex-center-end">
                  <ng-container *ngIf="!vm.isSessionEditor" data-actions="actions">
                    <div class="activities-list-cell short-cell">
                      <button
                        *ngIf="vm.sessionFocus?.activityId === activity.id"
                        class="tt9-btn-icon"
                        (click)="$event.stopPropagation()"
                      >
                        <accenture-icon
                          class="action-button-icon cursor-default"
                          [colorType]="
                            isNavigationDisabled(vm.session?.selfNavigate, vm.isSessionEditor)
                              ? iconColor.Disabled
                              : iconColor.Focus
                          "
                          >icon-focus</accenture-icon
                        >
                      </button>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="vm.isSessionEditor" data-actions="actions">
                    <div class="activities-list-cell short-cell">
                      <button
                        *ngIf="vm.connectionsByDestinationId[activity.id]?.length"
                        class="tt9-btn-icon"
                        (click)="$event.stopPropagation()"
                      >
                        <accenture-icon class="action-button-icon cursor-default">icon-connection</accenture-icon>
                      </button>
                    </div>

                    <div *ngIf="vm.isShowGetLinks" class="activities-list-cell short-cell">
                      <button
                        class="tt9-btn-icon"
                        matTooltipPosition="above"
                        matTooltipClass="tt9-arrow-tooltip-below"
                        [matTooltip]="activity.visible ? tooltipTexts.copyInvite : tooltipTexts.inviteDisabled"
                        [disabled]="!activity.visible"
                        (click)="getLink($event, activity)"
                      >
                        <accenture-icon class="action-button-icon">{{
                          activity.visible ? "icon-link" : "icon-link-off"
                        }}</accenture-icon>
                      </button>
                    </div>

                    <div *ngIf="vm.sessionFocus?.activityId === activity.id" class="activities-list-cell short-cell">
                      <button class="tt9-btn-icon" (click)="$event.stopPropagation()">
                        <accenture-icon class="action-button-icon cursor-default" [colorType]="iconColor.Focus"
                          >icon-focus</accenture-icon
                        >
                      </button>
                    </div>

                    <div *ngIf="vm.sessionFocus?.activityId !== activity.id" class="activities-list-cell short-cell">
                      <button
                        class="tt9-btn-icon"
                        matTooltipPosition="above"
                        matTooltipClass="tt9-arrow-tooltip-below"
                        [matTooltip]="activity.visible ? tooltipText.hideActivity : tooltipText.showActivity"
                        (click)="updateActivityVisibility($event, !activity.visible, activity.id)"
                      >
                        <accenture-icon class="action-button-icon" [colorType]="iconColor.Secondary">{{
                          activity.visible ? "icon-visibility" : "icon-visibility-off"
                        }}</accenture-icon>
                      </button>
                    </div>

                    <div *ngIf="!vm.isTabletPortrait" class="activities-list-cell short-cell">
                      <button
                        class="tt9-btn-icon"
                        matTooltipPosition="above"
                        matTooltipClass="tt9-arrow-tooltip-below"
                        [matTooltip]="tooltipTexts.moveToActivityEditor"
                        [class.disabled]="isNavigationDisabled(vm.session?.selfNavigate, vm.isSessionEditor)"
                        (click)="
                          navigateToActivity(
                            $event,
                            activity,
                            vm.session,
                            vm.isSessionEditor,
                            vm.isPublicTemplate,
                            true
                          )
                        "
                      >
                        <accenture-icon class="action-button-icon">icon-edit</accenture-icon>
                      </button>
                    </div>

                    <div class="activities-list-cell short-cell">
                      <button
                        class="tt9-btn-icon"
                        [disabled]="activity.dndDisabled"
                        [matMenuTriggerFor]="activityOptions"
                        (click)="$event.stopPropagation()"
                      >
                        <accenture-icon>icon-more</accenture-icon>
                      </button>
                    </div>
                    <mat-menu #activityOptions="matMenu" class="tt9-menu">
                      <button
                        *ngIf="!vm.isTemplate && vm.isUserTemplateCreator"
                        mat-menu-item
                        (click)="saveAsTemplate($event, activity.id)"
                      >
                        <accenture-icon class="action-button-icon" [colorType]="iconColor.Secondary"
                          >icon-save</accenture-icon
                        >
                        Save as template
                      </button>
                      <button
                        *ngIf="vm.isTabletPortrait"
                        mat-menu-item
                        (click)="
                          navigateToActivity(
                            $event,
                            activity,
                            vm.session,
                            vm.isSessionEditor,
                            vm.isPublicTemplate,
                            true
                          )
                        "
                      >
                        <accenture-icon class="action-button-icon" [colorType]="iconColor.Secondary"
                          >icon-edit</accenture-icon
                        >
                        Edit Activity
                      </button>
                      <button mat-menu-item (click)="toggleActivityPreviewPanel(true, activity, $event)">
                        <accenture-icon class="action-button-icon" [colorType]="iconColor.Secondary"
                          >icon-open-in-full</accenture-icon
                        >
                        Open activity preview
                      </button>
                      <button
                        *ngIf="vm.sessionFocus?.activityId === activity.id"
                        mat-menu-item
                        (click)="updateActivityVisibility($event, !activity.visible, activity.id)"
                      >
                        <accenture-icon class="action-button-icon" [colorType]="iconColor.Secondary"
                          >icon-visibility-off</accenture-icon
                        >
                        Hide activity
                      </button>
                      <button mat-menu-item (click)="duplicate(activity, vm.activities[i + 1]?.sequence)">
                        <accenture-icon class="action-button-icon" [colorType]="iconColor.Secondary"
                          >icon-duplicate</accenture-icon
                        >
                        Duplicate activity
                      </button>
                      <button mat-menu-item (click)="deleteActivity($event, activity)">
                        <accenture-icon class="action-button-icon" [colorType]="iconColor.Secondary"
                          >icon-delete</accenture-icon
                        >
                        Delete activity
                      </button>
                    </mat-menu>
                  </ng-container>
                </div>
              </div>
            </div>

            <!-- Drag'n'drop target placeholders (show place of dragged element to move to) -->
            <div
              *ngIf="showPlaceholderAfter(i, 'session-activities-list')"
              class="tt9-dnd-placeholder-after placeholder-small"
            ></div>
            <!-- /Drag'n'drop target placeholders (show place of dragged element to move to) -->
          </ng-container>
        </div>
        <ng-template #noActivities>
          <div *ngIf="!vm.searchValue; else noSearchResult" class="placeholder-message d-flex-center">
            Click Create Activity to get started and build your session
          </div>
          <ng-template #noSearchResult>
            <div class="no-search-result">
              <accenture-empty-screen
                class="m-auto"
                [imageUrl]="emptyScreenImageUrlActivities"
                [title]="emptyScreenTitles.noActivitiesMatch"
              />
            </div>
          </ng-template>
        </ng-template>
      </ng-container>
      <!-- /Table for desktops and tablets -->

      <!-- Table for handsets -->
      <ng-container *ngIf="vm.isAllHandsetsScreen && vm.isSessionParticipant">
        <div
          *ngFor="let activity of vm.activities; trackBy: trackById; let i = index; let isLast = last"
          class="activities-list-row-handsets"
          [ngClass]="{ last: isLast }"
          [class.disabled]="isNavigationDisabled(vm.session?.selfNavigate, vm.isSessionEditor)"
          (click)="navigateToActivity($event, activity, vm.session, vm.isSessionEditor, vm.isPublicTemplate)"
        >
          <div class="d-flex">
            <div class="activities-list-cell short-cell tt9-body-2">
              {{ i + 1 }}
            </div>

            <div class="activities-list-cell short-cell tt9-body-1">
              <accenture-icon [colorType]="iconColor.Primary">{{
                activity.type | displayActivityIcon : vm.activityConfigurationsMap
              }}</accenture-icon>
            </div>

            <div class="activities-list-cell wide-cell tt9-body-medium-1">
              <span [class.text-overflow-ellipsis-2]="!vm.isAllHandsetsScreen">{{ activity.name }}</span>
            </div>
          </div>

          <div *ngIf="!!activity.description" class="d-flex">
            <div class="activities-list-cell short-cell"></div>
            <div class="activities-list-cell short-cell"></div>
            <div
              class="activities-list-cell wide-cell tt9-body-1"
              [class.activity-description]="!!activity.description"
            >
              <span [class.text-overflow-ellipsis-2]="!vm.isAllHandsetsScreen">{{ activity.description }}</span>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- /Table for handsets -->
    </div>
    <!-- Create Activity side panel -->
    <accenture-side-panel
      *ngIf="vm.isActivitiesPanelOpened"
      #origin
      cdkOverlayOrigin
      [panelTrigger]="origin"
      [useDefaultTemplate]="false"
      [customWidth]="600"
      [panelOpened]="vm.isActivitiesPanelOpened"
      (closePanel)="toggleActivitiesPanel(false)"
    >
      <accenture-activity-creator-manager
        [sequenceData]="getSequenceData()"
        (activityCreated)="updateSequenceData($event, vm.activities)"
        (panelClosed)="toggleActivitiesPanel(false)"
      ></accenture-activity-creator-manager>
    </accenture-side-panel>
    <!-- Create Activity side panel -->

    <!-- Activity preview side panel -->
    <accenture-side-panel
      *ngIf="vm.isActivityPreviewOpened"
      #origin
      cdkOverlayOrigin
      [panelTrigger]="origin"
      [useDefaultTemplate]="false"
      [panelOpened]="vm.isActivityPreviewOpened"
      (closePanel)="toggleActivityPreviewPanel(false)"
    >
      <accenture-activity-preview
        [activityId]="selectedActivityForPreview?.id"
        (closePanelClicked)="toggleActivityPreviewPanel(false)"
        (navigateToActivityClicked)="
          navigateToActivity($event, selectedActivityForPreview, vm.session, vm.isSessionEditor, vm.isPublicTemplate)
        "
      ></accenture-activity-preview>
    </accenture-side-panel>
    <!-- Activity preview side panel -->
  </ng-container>
</ng-container>
