<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading; description: loaderText">
    <accenture-base-dialog [title]="'Select session template'">
      <div class="content-container">
        <accenture-add-session-table
          [templates]="vm.templates"
          [selectedTemplatesMap]="vm.selectedTemplatesMap"
          (selectedTemplates)="updateSelectedTemplates($event)"
          (searchValue)="updateSearchValue($event)"
        ></accenture-add-session-table>
      </div>

      <div class="footer d-flex-end">
        <button class="tt9-btn-default btn-icon back-btn" (click)="closeDialog()">
          <accenture-icon>icon-chevron-left</accenture-icon>
          Back
        </button>

        <button class="tt9-btn-default ml-16" (click)="addSessions()" [disabled]="!vm.selectedTemplates.length">
          Save
        </button>
      </div>
    </accenture-base-dialog>
  </ng-container>
</ng-container>
