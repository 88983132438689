import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

import { AuthenticationTab, NavigationTab } from '@accenture/shared/data';

export interface FredHeaderState {
    tab: NavigationTab | undefined;
    authenticationTab: AuthenticationTab | undefined;
}

@Injectable({
    providedIn: 'root',
})
export class FredHeaderStore extends ComponentStore<FredHeaderState> {
    constructor() {
        super({
            tab: NavigationTab.Dashboard,
            authenticationTab: AuthenticationTab.Login,
        });
    }

    readonly tab$: Observable<NavigationTab> = this.select(state => state.tab);
    readonly authenticationTab$: Observable<AuthenticationTab> = this.select(state => state.authenticationTab);

    readonly setTab = this.updater((state, tab: NavigationTab) => ({
        ...state,
        tab,
    }));

    readonly setAuthenticationTab = this.updater((state, authenticationTab: AuthenticationTab) => ({
        ...state,
        authenticationTab,
    }));
}
