import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, map, Observable } from 'rxjs';

import {
    DBPathHelper,
    Dictionary,
    ParentType,
    SessionThreadMessageType,
    sessionThreadNotifications,
    SessionThreadReaction,
    SessionThreadUserConfiguration,
} from '@accenture/shared/data';
import { SessionThreadMessage } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import { sortByDateAsc } from '@accenture/shared/util';

import { ReactionsService } from './reactions.service';

@Injectable({
    providedIn: 'root',
})
export class SessionThreadsService {
    readonly deletedLike = this.firestoreService.deleteField;

    readonly currentlyTypedText$ = new BehaviorSubject<Dictionary<string>>({});
    readonly isSessionThreadOpen$ = new BehaviorSubject<boolean>(false);

    constructor(private firestoreService: FirestoreService, private reactionService: ReactionsService) {}

    setCurrentlyTypedText(value: string, parentId: string): void {
        this.currentlyTypedText$.next({
            ...this.currentlyTypedText$.getValue(),
            [parentId]: value,
        });
    }

    appendCurrentlyTypedText(value: string, parentId: string): void {
        const currentValue = this.currentlyTypedText$.getValue();
        this.currentlyTypedText$.next({
            ...currentValue,
            [parentId]: `${currentValue[parentId] || ''}${value}`,
        });
    }

    getCurrentlyTypedText(parentId: string): Observable<string> {
        return this.currentlyTypedText$.asObservable().pipe(
            map(map => map[parentId] || ''),
            distinctUntilChanged(),
        );
    }

    clearCurrentlyTypedText(): void {
        this.currentlyTypedText$.next({});
    }

    getSessionThreadsMessages(sessionId: string, parentId?: string): Observable<SessionThreadMessage[]> {
        return this.firestoreService
            .getDocumentsByMultipleProperties(
                DBPathHelper.getSessionThreadsPath(ParentType.Sessions, sessionId),
                new Map([
                    ['sessionId', sessionId],
                    ['parentId', parentId || sessionId],
                ]),
            )
            .pipe(
                map((messages: SessionThreadMessage[]) =>
                    messages
                        .map(message => {
                            const updatedMessage = new SessionThreadMessage(message);
                            const reactions = Object.fromEntries(Object.entries(updatedMessage.reactions).filter(([_, value]) => !!value?.updated))
                            message.reactions = reactions || {}

                            updatedMessage.reactionsCount = this.reactionService.getReactions(message.reactions);
                            return updatedMessage;
                        })
                        .sort(sortByDateAsc()),
                ),
            );
    }

    //TODO delete when collections feature completed https://thinktankco.atlassian.net/browse/TT9-6456
    getProjectSessionThreadsMessages(
        projectId: string,
        sessionId: string,
        parentId?: string,
    ): Observable<SessionThreadMessage[]> {
        return this.firestoreService
            .getDocumentsByMultipleProperties(
                DBPathHelper.getSessionThreadsPath(ParentType.Projects, projectId),
                new Map([
                    ['sessionId', sessionId],
                    ['parentId', parentId || sessionId],
                ]),
            )
            .pipe(
                map((messages: SessionThreadMessage[]) =>
                    messages
                        .map(message => {
                            const updatedMessage = new SessionThreadMessage(message);
                            const reactions = Object.fromEntries(Object.entries(updatedMessage.reactions).filter(([_, value]) => !!value?.updated))
                            message.reactions = reactions || {}

                            updatedMessage.reactionsCount = this.reactionService.getReactions(message.reactions);
                            return updatedMessage;
                        })
                        .sort(sortByDateAsc()),
                ),
            );
    }

    async addNewSessionThreadMessageNew(
        parentId: string,
        newSessionThreadMessage: SessionThreadMessage,
    ): Promise<string> {
        return this.firestoreService.addDocument(DBPathHelper.getSessionThreadsPath(ParentType.Sessions, parentId), {
            ...newSessionThreadMessage.createSerializableObject(),
            created: this.firestoreService.timestamp,
            updated: this.firestoreService.timestamp,
        });
    }

    //TODO delete when collections feature completed https://thinktankco.atlassian.net/browse/TT9-6456
    async addNewSessionThreadMessage(
        projectId: string,
        newSessionThreadMessage: SessionThreadMessage,
    ): Promise<string> {
        return this.firestoreService.addDocument(DBPathHelper.getSessionThreadsPath(ParentType.Projects, projectId), {
            ...newSessionThreadMessage.createSerializableObject(),
            created: this.firestoreService.timestamp,
            updated: this.firestoreService.timestamp,
        });
    }

    async deleteSessionThreadMessageNew(sessionId: string, sessionThreadMessageId: string): Promise<void> {
        await this.firestoreService.delete(
            DBPathHelper.getSessionThreadsPath(ParentType.Sessions, sessionId, sessionThreadMessageId),
        );
    }

    //TODO delete when collections feature completed https://thinktankco.atlassian.net/browse/TT9-6456
    async deleteSessionThreadMessage(projectId: string, sessionThreadMessageId: string): Promise<void> {
        await this.firestoreService.delete(
            DBPathHelper.getSessionThreadsPath(ParentType.Projects, projectId, sessionThreadMessageId),
        );
    }

    async updateSessionThreadMessageNew(
        sessionId: string,
        sessionThreadMessageId: string,
        message: Partial<SessionThreadMessage>,
    ) {
        await this.firestoreService.update(
            DBPathHelper.getSessionThreadsPath(ParentType.Sessions, sessionId, sessionThreadMessageId),
            {
                ...message,
                updated: this.firestoreService.timestamp,
            },
        );
    }

    //TODO delete when collections feature completed https://thinktankco.atlassian.net/browse/TT9-6456
    async updateSessionThreadMessage(
        projectId: string,
        sessionThreadMessageId: string,
        message: Partial<SessionThreadMessage>,
    ) {
        await this.firestoreService.update(
            DBPathHelper.getSessionThreadsPath(ParentType.Projects, projectId, sessionThreadMessageId),
            {
                ...message,
                updated: this.firestoreService.timestamp,
            },
        );
    }

    async changeMessageCommentsCountNew(
        sessionId: string,
        sessionThreadMessageId: string,
        diff: number,
    ): Promise<void> {
        await this.firestoreService.update(
            DBPathHelper.getSessionThreadsPath(ParentType.Sessions, sessionId, sessionThreadMessageId),
            { commentsCount: this.firestoreService.changeCounterValue(diff) },
        );
    }

    //TODO delete when collections feature completed https://thinktankco.atlassian.net/browse/TT9-6456
    async changeMessageCommentsCount(projectId: string, sessionThreadMessageId: string, diff: number): Promise<void> {
        await this.firestoreService.update(
            DBPathHelper.getSessionThreadsPath(ParentType.Projects, projectId, sessionThreadMessageId),
            { commentsCount: this.firestoreService.changeCounterValue(diff) },
        );
    }

    async removeReactionNew(sessionId: string, reactionId: string): Promise<undefined> {
        await this.firestoreService.delete(
            DBPathHelper.getSessionThreadsReactionsPath(ParentType.Sessions, sessionId, reactionId),
        );
    }

    //TODO delete when collections feature completed https://thinktankco.atlassian.net/browse/TT9-6456
    async removeReaction(projectId: string, reactionId: string): Promise<undefined> {
        await this.firestoreService.delete(
            DBPathHelper.getSessionThreadsReactionsPath(ParentType.Projects, projectId, reactionId),
        );
    }

    async createReactionNew(sessionId: string, reaction: SessionThreadReaction): Promise<string> {
        return this.firestoreService.addDocument(
            DBPathHelper.getSessionThreadsReactionsPath(ParentType.Sessions, sessionId),
            {
                ...reaction.createSerializableObject(),
                created: this.firestoreService.timestamp,
                updated: this.firestoreService.timestamp,
            },
        );
    }

    //TODO delete when collections feature completed https://thinktankco.atlassian.net/browse/TT9-6456
    async createReaction(projectId: string, reaction: SessionThreadReaction): Promise<string> {
        return this.firestoreService.addDocument(
            DBPathHelper.getSessionThreadsReactionsPath(ParentType.Projects, projectId),
            {
                ...reaction.createSerializableObject(),
                created: this.firestoreService.timestamp,
                updated: this.firestoreService.timestamp,
            },
        );
    }

    async updateReactionNew(sessionId: string, reaction: SessionThreadReaction): Promise<void> {
        await this.firestoreService.update(
            DBPathHelper.getSessionThreadsReactionsPath(ParentType.Sessions, sessionId),
            {
                ...reaction.createSerializableObject(),
                updated: this.firestoreService.timestamp,
            },
        );
    }

    async updateReaction(projectId: string, reaction: SessionThreadReaction): Promise<void> {
        await this.firestoreService.update(
            DBPathHelper.getSessionThreadsReactionsPath(ParentType.Projects, projectId),
            {
                ...reaction.createSerializableObject(),
                updated: this.firestoreService.timestamp,
            },
        );
    }

    // TODO: remove after collections will be implemented
    async addNewSessionThreadNotification(
        projectId: string,
        sessionId: string,
        type: SessionThreadMessageType,
    ): Promise<void> {
        await this.firestoreService.addDocument(DBPathHelper.getSessionThreadsPath(ParentType.Projects, projectId), {
            ...new SessionThreadMessage({
                sessionId,
                type,
                parentId: sessionId,
                value: sessionThreadNotifications[type],
            }).createSerializableObject(),
            created: this.firestoreService.timestamp,
            updated: this.firestoreService.timestamp,
        });
    }

    async addNewSessionThreadNotificationNew(sessionId: string, type: SessionThreadMessageType): Promise<void> {
        await this.firestoreService.addDocument(DBPathHelper.getSessionThreadsPath(ParentType.Sessions, sessionId), {
            ...new SessionThreadMessage({
                sessionId,
                type,
                parentId: sessionId,
                value: sessionThreadNotifications[type],
            }).createSerializableObject(),
            created: this.firestoreService.timestamp,
            updated: this.firestoreService.timestamp,
        });
    }

    getSessionThreadUserConfig(userId: string): Observable<SessionThreadUserConfiguration> {
        return this.firestoreService.getDocument(DBPathHelper.getSessionThreadUserConfigPath(userId));
    }

    async updateSessionThreadUserConfig(userId: string, data: Partial<SessionThreadUserConfiguration>): Promise<void> {
        await this.firestoreService.update(DBPathHelper.getSessionThreadUserConfigPath(userId), data);
    }

    getSessionThreadStatus(): Observable<boolean> {
        return this.isSessionThreadOpen$.asObservable().pipe(distinctUntilChanged());
    }

    setSessionThreadStatus(isOpened: boolean): void {
        this.isSessionThreadOpen$.next(isOpened);
    }
}
