import { Injectable } from '@angular/core';

import { EmailMessagesBatch } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable({
    providedIn: 'root',
})
export class EmailService {
    constructor(private firestoreService: FirestoreService) {}

    async addEmailMessage(message: EmailMessagesBatch): Promise<void> {
        await this.firestoreService.addDocument('/emailMessages', {
            ...message,
            created: this.firestoreService.timestamp,
        });
    }
}
