import { flatten, groupBy, mapValues } from 'lodash';

import {
    ActivityItemType,
    allAttributeId,
    allSelectedValue,
    defaultAttributeId,
    Dictionary,
    ParameterExtrasType,
    VoteResponse,
    VoteSummaryFilterSelection,
    VoteSummaryResponse,
} from '../../';

// TODO: handle dependency between shared/data and shared/util
// placed here to avoid d3 dependencies in CF and circular dependencies between shared/data and shared/util
const mean = (values: number[]): number => {
    let count = 0;
    let sum = 0;

    values.forEach((value: number) => {
        if (value != null && (value = +value) >= value) {
            ++count;
            sum += value;
        }
    });

    return count ? sum / count : undefined;
};

const deviation = (values: number[]) => {
    const v = variance(values);
    return v ? Math.sqrt(v) : v;
};

const variance = (values: number[]) => {
    let count = 0;
    let delta;
    let mean = 0;
    let sum = 0;

    values.forEach(value => {
        if (value != null && (value = +value) >= value) {
            delta = value - mean;
            mean += delta / ++count;
            sum += delta * (value - mean);
        }
    });

    return count > 1 ? sum / (count - 1) : undefined;
};

export class VoteTransformation {
    static getSummaryResponses(
        responses: VoteResponse[],
        parameters: VoteSummaryFilterSelection,
        attributes: VoteSummaryFilterSelection,
        includeAbstainedUsers: boolean,
        showAllAttributes: boolean,
        parameterTypesMap: Dictionary<ActivityItemType>,
        useAttributes?: boolean,
        attributesHidden?: boolean,
    ): Dictionary<Dictionary<Dictionary<VoteSummaryResponse | undefined>>> {
        return mapValues(groupBy(responses, 'parameterId'), (parameterResponses, parameterId) => {
            if (
                parameters !== allSelectedValue
                && !parameters.includes(parameterId)
                && !parameters.includes(parameterId + ParameterExtrasType.stdDev)
            ) {
                return {};
            }

            return mapValues(groupBy(parameterResponses, 'voteItemId'), (voteItemResponses, voteItemId) => {
                const filteredResponses = useAttributes
                    ? voteItemResponses.filter(response => {
                          return (
                              attributes === allSelectedValue
                              || attributesHidden
                              || attributes.some(attribute => response?.attributes?.[attribute])
                          );
                      })
                    : voteItemResponses.filter(responses => responses.attributes[defaultAttributeId]);

                const groupByAttributesFilteredResponses = filteredResponses.reduce((acc, el) => {
                    const [attributeId] = Object.keys(el.attributes);
                    const responses = acc[attributeId] || [];
                    return {
                        ...acc,
                        [attributeId]: [...responses, el],
                    };
                }, {});

                return {
                    [allAttributeId]: showAllAttributes
                        ? ({
                              parameterId,
                              voteItemId,
                              attributes: {
                                  [allAttributeId]: true,
                              },
                              summaryValue: this.getSummaryResponseValue(
                                  filteredResponses,
                                  parameterTypesMap,
                                  includeAbstainedUsers,
                              ),
                              abstained: includeAbstainedUsers
                                  ? this.getAbstainedValue(filteredResponses, parameterTypesMap)
                                  : undefined,
                          } as unknown as VoteSummaryResponse)
                        : undefined,
                    ...(useAttributes && attributesHidden
                        ? {}
                        : mapValues(groupByAttributesFilteredResponses, (usersResponses, attributeId) => {
                              return {
                                  parameterId,
                                  voteItemId,
                                  attributes: {
                                      [attributeId]: true,
                                  },
                                  summaryValue: this.getSummaryResponseValue(
                                      usersResponses,
                                      parameterTypesMap,
                                      includeAbstainedUsers,
                                  ),
                                  abstained: includeAbstainedUsers
                                      ? this.getAbstainedValue(usersResponses, parameterTypesMap)
                                      : undefined,
                              } as VoteSummaryResponse;
                          })),
                };
            });
        });
    }

    private static getSummaryResponseValue(
        allResponses: VoteResponse[],
        parameterTypesMap: Dictionary<ActivityItemType>,
        includeAbstainedUsers = false,
    ): any {
        if (!parameterTypesMap) {
            return;
        }
        const parameterType = parameterTypesMap[allResponses[0]?.parameterId];

        const responses = allResponses.filter(response => response.value);
        const responsesValues = responses.map(response => response.value);

        switch (parameterType) {
            case ActivityItemType.TopX:
                return responsesValues.length > 0 ? responsesValues.length : undefined;

            case ActivityItemType.StarVoting:
            case ActivityItemType.Slider:
            case ActivityItemType.Numeric:
                return {
                    avg: mean(responsesValues as number[])?.toFixed(2),
                    stdDev: deviation(responsesValues as number[])?.toFixed(2),
                };

            case ActivityItemType.SingleSelect: {
                const allGivenOptions = flatten(responsesValues as string[][] | string[]);
                return mapValues(
                    allGivenOptions.reduce((acc, optionId) => {
                        if (!acc[optionId]) {
                            acc[optionId] = 0;
                        }
                        acc[optionId]++;
                        return acc;
                    }, {} as Dictionary<number>),
                    givenOptionCount =>
                        +(
                            (givenOptionCount / (includeAbstainedUsers ? allResponses.length : responses.length))
                            * 100
                        ).toFixed(2),
                );
            }
            case ActivityItemType.MultiSelect: {
                const allGivenOptions = flatten(responsesValues as string[][] | string[]);
                const allGivenResponses = flatten(responses.map(response => response.value) as string[][] | string[]);
                return mapValues(
                    allGivenOptions.reduce((acc, optionId) => {
                        if (!acc[optionId]) {
                            acc[optionId] = 0;
                        }
                        acc[optionId]++;
                        return acc;
                    }, {} as Dictionary<number>),
                    givenOptionCount => +((givenOptionCount / allGivenResponses.length) * 100).toFixed(2),
                );
            }

            case ActivityItemType.Text:
                return responsesValues?.length ? responsesValues : undefined;

            default:
                return responsesValues;
        }
    }

    private static getAbstainedValue(
        allResponses: VoteResponse[],
        parameterTypesMap: Dictionary<ActivityItemType>,
    ): number {
        if (!parameterTypesMap) {
            return undefined;
        }
        const parameterType = parameterTypesMap[allResponses[0]?.parameterId];
        const responses = allResponses.filter(response => response.value);
        switch (parameterType) {
            case ActivityItemType.SingleSelect:
            case ActivityItemType.MultiSelect:
                return +(((allResponses.length - responses.length) / allResponses.length) * 100).toFixed(2);
            default:
                return undefined;
        }
    }
}
