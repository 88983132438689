import { CdkDragDrop, CdkDragMove, CdkDragStart, moveItemInArray } from '@angular/cdk/drag-drop';
import { isNil } from 'lodash';

import { SequenceGeneration } from '@accenture/shared/util';

export class AccentureTableUtils<T extends { sequence: string }> {
    public dragColumnIndex: number | undefined;
    public dragColumnId: string | undefined;
    public dragColumnHoverIndex: number | undefined;
    public dragRowIndex: number | undefined;
    public dragRowId: string | undefined;
    public dragRowHoverIndex: number | undefined;
    public addColumnBetweenIndex: number | undefined;
    public addRowBetweenIndex: number | undefined;
    public columnHoverIndex: number | undefined;
    public rowHoverIndex: number | undefined;
    public thWidth: number | undefined;
    public maxColumnWidth = 720;
    public minColumnWidth = 280;
    public minAttributesColumnWidth = 100;

    public isDragActive(): boolean {
        return !isNil(this.dragColumnIndex) || !isNil(this.dragRowIndex);
    }

    // 1 - is minimum size of data columns for add row button
    public getAddRowColspans(dragCellVisible: boolean, numCellVisible: boolean): number {
        return 1 + +!!dragCellVisible + +!!numCellVisible;
    }

    // [cdkDropListData]="items" is required
    // [cdkDragData]="item.id" is required
    public droppedColumn(event: CdkDragDrop<T[], T[], string>): void {
        if (!isNil(this.dragColumnIndex) && !isNil(this.dragColumnHoverIndex)) {
            const {
                item: { data: itemId },
                previousContainer: { data: sortingArray },
            } = event;
            const sequence = this.getMoveItemSequence(sortingArray, this.dragColumnIndex, this.dragColumnHoverIndex);
            moveItemInArray(sortingArray, this.dragColumnIndex, this.dragColumnHoverIndex);
            this.afterColumnDropped(itemId, sequence);
        }
        this.resetDragParams();
    }

    // [cdkDropListData]="items" is required
    // [cdkDragData]="item.id" is required
    public droppedRow(event: CdkDragDrop<T[], T[], string>): void {
        if (!isNil(this.dragRowIndex) && !isNil(this.dragRowHoverIndex)) {
            const {
                item: { data: itemId },
                previousContainer: { data: sortingArray },
            } = event;
            const sequence = this.getMoveItemSequence(sortingArray, this.dragRowIndex, this.dragRowHoverIndex);
            moveItemInArray(sortingArray, this.dragRowIndex, this.dragRowHoverIndex);
            this.afterRowDropped(itemId, sequence);
        }
        this.resetDragParams();
    }

    public updateTableColumnWidth(
        event: CdkDragMove<T>,
        tableColumnElement: HTMLElement,
        customMinWidth?: number,
    ): void {
        const { width, right } = tableColumnElement.getBoundingClientRect();
        this.thWidth = width + (event.event as MouseEvent).clientX - right;
        tableColumnElement.style.maxWidth = `${this.thWidth}px`;
        tableColumnElement.style.minWidth = `${this.thWidth}px`;

        if (this.thWidth > this.maxColumnWidth) {
            tableColumnElement.style.maxWidth = `${this.maxColumnWidth}px`;
            tableColumnElement.style.minWidth = `${this.maxColumnWidth}px`;
        }

        const minColumnWidth = customMinWidth || this.minColumnWidth;

        if (this.thWidth < minColumnWidth) {
            tableColumnElement.style.maxWidth = `${minColumnWidth}px`;
            tableColumnElement.style.minWidth = `${minColumnWidth}px`;
        }
    }

    public isLineHideLeft(firstColumnElement: HTMLElement, tableColumnElement: HTMLElement): boolean {
        if (!firstColumnElement || !tableColumnElement) {
            return false;
        }

        const firstColumnElPosition = firstColumnElement.getBoundingClientRect();
        const tableColumnEllPosition = tableColumnElement.getBoundingClientRect();

        return firstColumnElPosition.right > tableColumnEllPosition.right;
    }

    public isElScrollToBottom(element: HTMLElement): boolean {
        return Math.abs(element.scrollHeight - element.clientHeight - element.scrollTop) < 1;
    }

    public setColumnHover(index: number | undefined): void {
        if (!isNil(this.dragColumnIndex)) {
            this.dragColumnHoverIndex = index;
            return;
        }
        this.columnHoverIndex = index;
    }

    public setTableColumnIndex(index: number | undefined): void {
        this.columnHoverIndex = index;
    }

    // [cdkDragData]="item.id" is required
    public setDragColumnStart(event: CdkDragStart<string>, index: number): void {
        this.dragColumnIndex = index;
        this.dragColumnId = event.source.data;
        this.afterColumnDragStarted(this.dragColumnId);
    }

    public setDragColumnEnd(): void {
        this.afterColumnDragEnded(this.dragColumnId);
        setTimeout(() => this.resetDragParams());
    }

    public setDragRowHover(index: number | undefined): void {
        if (!isNil(this.dragRowIndex)) {
            this.dragRowHoverIndex = index;
        }
    }

    public setRowHover(index: number | undefined): void {
        this.rowHoverIndex = index;
    }

    public setDragRowStart(event: CdkDragStart<string>, index: number): void {
        this.dragRowIndex = index;
        this.dragRowId = event.source.data;
        this.afterRowDragStarted(this.dragRowId);
    }

    public setDragRowEnd(): void {
        this.afterRowDragEnded(this.dragRowId);
        setTimeout(() => this.resetDragParams());
    }

    public setAddColumnBetween(index: number | undefined): void {
        this.addColumnBetweenIndex = index;
    }

    public setAddRowBetween(index: number | undefined): void {
        this.addRowBetweenIndex = index;
    }

    public showPlaceholderBefore(index: number): boolean {
        return !isNil(this.dragColumnIndex) && this.dragColumnHoverIndex === index && this.dragColumnIndex > index;
    }

    public showPlaceholderAfter(index: number): boolean {
        return !isNil(this.dragColumnIndex) && this.dragColumnHoverIndex === index && this.dragColumnIndex < index;
    }

    public showPlaceholderBeforeRow(index: number): boolean {
        return !isNil(this.dragRowIndex) && this.dragRowHoverIndex === index && this.dragRowIndex > index;
    }

    public showPlaceholderAfterRow(index: number): boolean {
        return !isNil(this.dragRowIndex) && this.dragRowHoverIndex === index && this.dragRowIndex < index;
    }

    public showInitialPlaceholder(index: number): boolean {
        return !isNil(this.dragColumnIndex) && this.dragColumnIndex === index;
    }

    public addColumn(initialArray: T[], index?: number): void {
        const sequence = this.getAddItemSequence(initialArray, index);
        this.afterColumnAdded(sequence);
    }

    public addRow(initialArray: T[], index?: number, tableElement?: HTMLElement): void {
        const sequence = this.getAddItemSequence(initialArray, index);
        this.afterRowAdded(sequence);

        if (tableElement) {
            setTimeout(() => {
                tableElement.scrollTop = tableElement?.scrollHeight;
            }, 500);
        }
    }

    protected afterColumnDragStarted(itemId: string): void {
        // to be overwritten in component
    }

    protected afterColumnDragEnded(itemId: string): void {
        // to be overwritten in component
    }

    protected afterColumnDropped(itemId: string, sequence: string): void {
        // to be overwritten in component
    }

    protected afterRowDragStarted(itemId: string): void {
        // to be overwritten in component
    }

    protected afterRowDragEnded(itemId: string): void {
        // to be overwritten in component
    }

    protected afterRowDropped(itemId: string, sequence: string): void {
        // to be overwritten in component
    }

    protected afterColumnAdded(sequence: string): void {
        // to be overwritten in component
    }

    protected afterRowAdded(sequence: string): void {
        // to be overwritten in component
    }

    private resetDragParams(): void {
        this.dragColumnHoverIndex = undefined;
        this.dragColumnIndex = undefined;
        this.dragRowHoverIndex = undefined;
        this.dragRowIndex = undefined;
    }

    private getMoveItemSequence(initialArray: T[], previousIndex: number, currentIndex: number): string {
        let sequence = initialArray[previousIndex].sequence;
        switch (previousIndex !== currentIndex) {
            case currentIndex == 0: {
                sequence = SequenceGeneration.beforeFirst(initialArray[0].sequence);
                break;
            }
            case currentIndex === initialArray.length - 1: {
                sequence = SequenceGeneration.afterLast(initialArray[currentIndex].sequence);
                break;
            }
            case previousIndex > currentIndex: {
                sequence = SequenceGeneration.between(
                    initialArray[currentIndex - 1].sequence,
                    initialArray[currentIndex].sequence,
                );
                break;
            }
            case previousIndex < currentIndex: {
                sequence = SequenceGeneration.between(
                    initialArray[currentIndex].sequence,
                    initialArray[currentIndex + 1].sequence,
                );
                break;
            }
            default:
                break;
        }
        return sequence;
    }

    private getAddItemSequence(initialArray: T[], index?: number): string {
        return !isNil(index)
            ? index === 0
                ? SequenceGeneration.beforeFirst(initialArray[0].sequence)
                : SequenceGeneration.between(initialArray[index - 1].sequence, initialArray[index].sequence)
            : initialArray.length
            ? SequenceGeneration.afterLast(initialArray[initialArray.length - 1].sequence)
            : SequenceGeneration.initial();
    }
}
