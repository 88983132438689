import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { validationMessages } from '@accenture/shared/data';

interface InputData {
    message: string;
    onAccept: (message: string) => void;
}

@Component({
    selector: 'accenture-bot-edit-reply',
    templateUrl: './bot-edit-reply.component.html',
    styleUrls: ['./bot-edit-reply.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BotEditReplyComponent {
    validationMessages = validationMessages;
    onAccept: (message: string) => void;
    message: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: InputData,
        private dialogRef: MatDialogRef<BotEditReplyComponent>,
    ) {
        const { onAccept, message } = data;
        this.message = message;
        this.onAccept = onAccept;
    }

    save(message: string): void {
        this.onAccept(message);
        this.close();
    }

    close(): void {
        this.dialogRef.close();
    }
}
