import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { isNull, isUndefined, omitBy } from 'lodash';
import { BehaviorSubject, map, Observable, switchMap } from 'rxjs';

import {
    DBPathHelper,
    ExportType,
    FileType,
    lazyLoadingUsersLimit,
    User,
    UserFilters,
    UserQueryFilters,
    UserRole,
    UsersSummaryExportData,
    UserStatus,
    UserSummary,
    UserType,
} from '@accenture/shared/data';

import { FirestoreService } from './firestore.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    isGuestFirstLogin$ = new BehaviorSubject(false);

    constructor(private firestoreService: FirestoreService, private angularFireAuth: AngularFireAuth) {}

    getUser(id: string): Observable<User> {
        return this.firestoreService
            .getDocument<Record<string, unknown>>(DBPathHelper.getUserPath(id))
            .pipe(map((user: any) => (user ? new User(id, user) : null)));
    }

    async getUsersSummaryByFilters(filters: UserQueryFilters): Promise<{ users: UserSummary[]; lastUserId: string }> {
        try {
            return this.firestoreService.cloudFunctionCallable('getUsersSummaryByFilters', { filters });
        } catch {
            return {
                users: [],
                lastUserId: '',
            };
        }
    }

    // Not in use, left here fo possible future improvements
    getUsersSummaryByFiltersRealtime(
        filters: UserQueryFilters,
        lastUserId?: any,
    ): Observable<{ users: UserSummary[]; lastUserId?: any }> {
        const sortDirection = this.firestoreService.getSortingDirection(filters.sortOrder);
        return this.firestoreService
            .getDocumentsByQuery('users', (ref) => {
                let query = ref.orderBy(filters.sortBy, sortDirection);

                if (filters.roles.length) {
                    query = query.where('globalRole', 'in', filters.roles);
                }

                if (filters.statuses.length) {
                    query = query.where('status', 'in', filters.statuses);
                }

                if (filters.types.length) {
                    query = query.where('type', 'in', filters.types);
                }

                if (lastUserId) {
                    query.startAfter(lastUserId);
                }

                return query.limit(lazyLoadingUsersLimit);
            })
            .snapshotChanges()
            .pipe(
                map((usersSnapshots) => ({
                    users: usersSnapshots.map(({ payload }) => new UserSummary(payload.doc.id, payload.doc.data())),
                    lastUserId: usersSnapshots[usersSnapshots.length - 1].payload.doc,
                })),
            );
    }

    async downloadAnExportUserReport(data: UsersSummaryExportData): Promise<FileType> {
        return await this.firestoreService.cloudFunctionCallable('exportData', {
            ...data,
            exportType: ExportType.UserReport,
        });
    }

    async downloadAnExportUserReportNew(data: UsersSummaryExportData): Promise<FileType> {
        return await this.firestoreService.cloudFunctionCallable('exportDataTt9', {
            ...data,
            exportType: ExportType.UserReport,
        });
    }

    getUserApiToken(): Observable<string> {
        return this.angularFireAuth.idToken;
    }

    getAdminUserFilterOptions(userId: string): Observable<UserFilters> {
        return this.firestoreService.getDocument<UserFilters>(`users/${userId}/filters/adminFilters`);
    }

    async updateAdminUserFilterOptions(userId: string, filterOptions: Partial<UserFilters>): Promise<void> {
        await this.firestoreService.upsert(`users/${userId}/filters/adminFilters`, filterOptions);
    }

    verifyUserExists(id: string, userData: Partial<User>): Observable<User> {
        return this.getUser(id).pipe(
            switchMap(async (user) => {
                if (user) {
                    const userUpdates = this.buildUserUpdates(user, userData);
                    if (Object.keys(userUpdates).length > 0) {
                        // Update the user object
                        await this.firestoreService.updateDoc<Partial<User>>(`users/${user.id}`, userUpdates);
                    }

                    return new User(id, { ...user, ...userUpdates });
                } else {
                    // User does not exist, create it
                    await this.createUser(id, userData.email, userData.firstName, userData.lastName);
                    return new User(id, userData);
                }
            }),
        );
    }

    async createUser(
        id: string,
        email: string,
        firstName: string,
        lastName: string,
        guest?: boolean,
    ): Promise<Partial<User>> {
        const newUser = omitBy(
            {
                id,
                email,
                firstName,
                lastName,
                guest,
                type: email
                    ? email.includes('@accenture')
                        ? UserType.AccentureUser
                        : UserType.GeneralUser
                    : UserType.Guest,
            } as User,
            (result) => isUndefined(result) || isNull(result),
        );

        await this.firestoreService.set(`users/${id}`, {
            ...newUser,
            created: this.firestoreService.timestamp,
            updated: this.firestoreService.timestamp,
        });

        return newUser;
    }

    async updateUserStatus(userId: string, status: UserStatus): Promise<string> {
        await this.firestoreService.update(`/users/${userId}`, {
            status,
        });
        return userId;
    }

    async updateUserRole(userId: string, globalRole: UserRole): Promise<void> {
        return this.firestoreService.update(DBPathHelper.getUserPath(userId), {
            globalRole,
        });
    }

    setLastLogin(id: string): Promise<void> {
        return this.firestoreService.update(`users/${id}`, {
            lastLogin: this.firestoreService.timestamp,
        });
    }

    async logout(): Promise<void> {
        try {
            await this.firestoreService.cloudFunctionCallable('revokeTokens', {});
            await this.angularFireAuth.signOut();
        } catch {
            console.error('Log out failed');
        }
    }

    async updateUserData(userId: string, user: Partial<User>): Promise<void> {
        const userData = this.firestoreService.replaceEmptyFields(user);
        userData['updated'] = this.firestoreService.timestamp;
        return await this.firestoreService.safeUpdate<Partial<User>>(`users/${userId}`, userData);
    }

    async updateUserAfterLogin(userId: string): Promise<void> {
        return await this.firestoreService.safeUpdate<User>(`users/${userId}`, {
            lastLogin: this.firestoreService.timestamp,
            status: UserStatus.Active,
            emailVerified: true,
        } as User);
    }

    async verifyUserEmail(uid: string): Promise<void> {
        try {
            await this.firestoreService.cloudFunctionCallable('verifyUserEmail', {
                uid,
            });
        } catch (error) {
            console.error('User email verification step failed');
        }
    }

    async updateUserStatusAuth(uid: string, status: UserStatus): Promise<void> {
        await this.firestoreService.cloudFunctionCallable('updateUserStatus', {
            uid,
            status,
        });
    }

    private buildUserUpdates(user: User, userData: Partial<User>): User {
        const userUpdates = {
            updated: this.firestoreService.timestamp,
        } as User;
        if (userData.email && userData.email !== user.email) {
            userUpdates['email'] = userData.email;
        }
        if (userData.firstName && userData.firstName !== user.firstName) {
            userUpdates['firstName'] = userData.firstName;
        }
        if (userData.lastName && userData.lastName !== user.lastName) {
            userUpdates['lastName'] = userData.lastName;
        }

        return userUpdates;
    }

    async downloadAnExportProjectReport(data: UsersSummaryExportData): Promise<FileType> {
        return await this.firestoreService.cloudFunctionCallable('exportData', {
            ...data,
            exportType: ExportType.ProjectReport,
        });
    }
}
