import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Dictionary, emptyScreenTitles, inputPlaceholders, UserAccess } from '@accenture/shared/data';
import { trackById } from '@accenture/shared/util';

@Component({
    selector: 'accenture-add-activity-templates-table',
    templateUrl: './add-activity-templates-table.component.html',
    styleUrls: ['./add-activity-templates-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddActivityTemplatesTableComponent implements OnChanges, OnDestroy {
    @Input() templates: UserAccess[] = [];
    @Input() selectedTemplatesMap: Dictionary<boolean> = {};

    @Output() searchValue = new EventEmitter<string>();
    @Output() selectedTemplates = new EventEmitter<Dictionary<boolean>>();

    inputPlaceholders = inputPlaceholders;
    emptyScreenTitles = emptyScreenTitles;
    isAllTemplatesSelected = false;
    searchControl = new FormControl<string>('', { nonNullable: true });
    trackById = trackById;

    emitSearchValue(value: string): void {
        const searchValue = value.trim().toLowerCase();

        this.searchControl.patchValue(searchValue);
        this.searchValue.emit(searchValue);
    }

    resetSearchValue(): void {
        this.searchControl.patchValue('');
        this.emitSearchValue('');
    }

    toggleTemplatesSelection(template: UserAccess, { checked }: { checked: boolean }): void {
        this.selectedTemplates.emit({ [template.templateId]: checked });
    }

    toggleAllTemplatesSelection({ checked }: { checked: boolean }): void {
        const selectedTemplatesMap: Dictionary<boolean> = {};

        this.templates.forEach(template => (selectedTemplatesMap[template.templateId] = checked));
        this.selectedTemplates.emit(selectedTemplatesMap);
    }

    ngOnChanges(): void {
        this.updateAllTemplatesSelectedStatus();
    }

    ngOnDestroy(): void {
        this.emitSearchValue('');
    }

    private updateAllTemplatesSelectedStatus(): void {
        this.isAllTemplatesSelected = !this.templates.filter(
            template => !this.selectedTemplatesMap[template.templateId],
        ).length;
    }
}
