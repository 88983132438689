import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Activity, ActivityType, buttonLabels, tooltipTexts } from '@accenture/shared/data';
import { ScrollPosition } from '@accenture/shared/ui';
import { trackById } from '@accenture/shared/util';

import { SequenceData } from '../models/sequence-data';
import { SessionActivityCreatorManagerFacade } from './session-activity-creator-manager-facade';

@Component({
    selector: 'accenture-session-activity-creator-manager',
    templateUrl: './session-activity-creator-manager.component.html',
    styleUrls: ['./session-activity-creator-manager.component.scss'],
    providers: [SessionActivityCreatorManagerFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionActivityCreatorManagerComponent {
    @Input() sequenceData?: SequenceData;

    @Output() activityCreated = new EventEmitter<string>();
    @Output() panelClosed = new EventEmitter<void>();

    protected readonly trackById = trackById;
    protected readonly tooltipTexts = tooltipTexts;
    protected readonly buttonLabels = buttonLabels;

    vm$ = this.facade.vm$;

    isListScrolled = false;

    constructor(private readonly facade: SessionActivityCreatorManagerFacade) {}

    closePanel(): void {
        this.panelClosed.emit();
    }

    async createActivity(activityType: ActivityType, activities: Activity[], sequence?: SequenceData): Promise<void> {
        const newActivityId = await this.facade.createActivity(activityType, activities, sequence);
        this.activityCreated.emit(newActivityId);
    }

    createActivityByTemplate(activities: Activity[], sequence?: SequenceData) {
        const completeCallback = () => this.activityCreated.emit();

        this.facade.openCreateActivityByTemplateDialog(activities, completeCallback, sequence);
    }

    setScrollPosition(event: ScrollPosition): void {
        this.isListScrolled = event !== ScrollPosition.Start;
    }

    openGenerateByAiDialog(): void {
        this.facade.openGenerateByAiDialog();
    }
}
