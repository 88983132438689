<div class="template-search">
  <!-- search -->
  <div class="width-100 mt-16">
    <mat-form-field appearance="outline" floatLabel="always" class="tt9-form-field no-label no-margin width-100">
      <input
        #search
        matInput
        [placeholder]="inputPlaceholders.search"
        [formControl]="searchControl"
        (input)="emitSearchValue(search.value)"
      />
      <accenture-icon matPrefix>icon-search</accenture-icon>
      <accenture-icon *ngIf="search.value" matSuffix (click)="resetSearchValue()">icon-close</accenture-icon>
    </mat-form-field>
  </div>
  <!-- /search -->
</div>

<!--table-->
<div *ngIf="templates.length" class="tt9-select-table-container">
  <table class="tt9-select-table">
    <thead>
      <tr class="row-sticky horizontal-shadow">
        <th class="cell-select">
          <mat-checkbox
            class="tt9-checkbox"
            color="primary"
            [checked]="isAllTemplatesSelected"
            (change)="toggleAllTemplatesSelection($event)"
          ></mat-checkbox>
        </th>
        <th class="tt9-body-2 short-cell">Template name</th>
        <th class="tt9-body-2 short-cell">Description</th>
        <th class="tt9-body-2 resizable-width">Creator</th>
        <th class="tt9-body-2 cell-date">Date created</th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngFor="let template of templates; trackBy: trackById">
        <ng-container *ngTemplateOutlet="rowTemplate; context: { template: this.template }"></ng-container>
      </ng-container>
    </tbody>
  </table>
</div>
<!--/table-->

<!-- empty screen -->
<ng-container *ngIf="!templates.length">
  <ng-container *ngTemplateOutlet="emptyScreen"></ng-container>
</ng-container>
<!-- /empty screen -->

<!-- row template -->
<ng-template #rowTemplate let-template="template">
  <tr>
    <td class="cell-select">
      <mat-checkbox
        color="primary"
        class="tt9-checkbox"
        [checked]="selectedTemplatesMap[template.templateId]"
        (change)="toggleTemplatesSelection(template, $event)"
      ></mat-checkbox>
    </td>

    <td class="short-cell">
      <span
        class="tt9-body-1 text-overflow-ellipsis-2"
        matTooltipPosition="above"
        matTooltipClass="tt9-arrow-tooltip-below-right"
        [matTooltip]="template.name"
        >{{ template.name }}</span
      >
    </td>

    <td class="short-cell">
      <span
        class="tt9-body-1 text-overflow-ellipsis-2"
        matTooltipPosition="above"
        matTooltipClass="tt9-arrow-tooltip-below-right"
        [matTooltip]="template.description"
        >{{ template.description }}</span
      >
    </td>

    <td class="resizable-width">
      <span
        class="tt9-body-1 text-overflow-ellipsis-2"
        [matTooltip]="template.ownerDisplayName"
        matTooltipPosition="above"
        matTooltipClass="tt9-arrow-tooltip-below-right"
        >{{ template.ownerDisplayName }}</span
      >
    </td>

    <td class="tt9-body-1 resizable-width">
      {{ template.created.toMillis() | date : "mediumDate" }}
    </td>
  </tr>
</ng-template>
<!-- /row template -->

<!-- empty screen template -->
<ng-template #emptyScreen>
  <span class="body text-neutral empty-screen">{{ emptyScreenTitles.noTemplatesFound }}</span>
</ng-template>
<!-- /empty screen template -->
