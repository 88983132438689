import { removeEmptyKeys } from '@accenture/shared/util';

import { Activity, ActivityType } from '../activities';
import { ActivityItem, ActivityItemType, TableStatus } from '../activity-items';
import { InAppNotificationType } from './in-app-notification-type';
import { BaseInAppNotification } from './in-app-notifications';

export class InAppActivityItemStatusChangeNotification extends BaseInAppNotification {
    projectId: string;
    sessionId: string;
    activityId: string;
    activityName: string;
    activityType: ActivityType;
    step: TableStatus;
    activityItemId: string;
    activityItemTitle: string;
    activityItemType: ActivityItemType;

    constructor(notification: any);
    constructor(
        userId: string,
        projectId: string,
        sessionId: string,
        activity: Activity,
        activityStep: TableStatus,
        activityItem: ActivityItem,
    );
    constructor(
        notificationOrUserId: any,
        projectId?: string,
        sessionId?: string,
        activity?: Activity,
        step?: TableStatus,
        activityItem?: ActivityItem,
    ) {
        if (typeof notificationOrUserId === 'object') {
            super(notificationOrUserId as BaseInAppNotification);

            this.type = InAppNotificationType.ActivityItemStepChange;
            this.projectId = notificationOrUserId.projectId;
            this.sessionId = notificationOrUserId.sessionId;
            this.activityId = notificationOrUserId.activityId;
            this.activityName = notificationOrUserId.activityName;
            this.activityType = notificationOrUserId.activityType;
            this.step = notificationOrUserId.step;
            this.activityItemId = notificationOrUserId.activityItemId;
            this.activityItemTitle = notificationOrUserId.activityItemTitle;
            this.activityItemType = notificationOrUserId.activityItemType;
        } else {
            super(InAppNotificationType.ActivityItemStepChange, notificationOrUserId);

            this.projectId = projectId;
            this.sessionId = sessionId;
            this.activityId = activity.id;
            this.activityName = activity.name;
            this.activityType = activity.type;
            this.step = step;
            this.activityItemId = activityItem.id;
            this.activityItemTitle = activityItem.title;
            this.activityItemType = activityItem.type;
        }
    }

    createSerializableObject(): Record<string, unknown> {
        return removeEmptyKeys({
            type: this.type,
            userId: this.userId,
            unread: this.unread,
            projectId: this.projectId,
            sessionId: this.sessionId,
            activityId: this.activityId,
            activityName: this.activityName,
            activityType: this.activityType,
            activityItemId: this.activityItemId,
            activityItemTitle: this.activityItemTitle,
            activityItemType: this.activityItemType,
            step: this.step,
            created: this.created,
            updated: this.updated,
        });
    }
}
