import { Pipe, PipeTransform } from '@angular/core';

import { DataFlowConnection } from '@accenture/shared/data';

@Pipe({
    name: 'isActivityConnectionSourceSessionVisible',
    pure: true,
})
export class isActivityConnectionSourceSessionVisiblePipe implements PipeTransform {
    transform(connections: DataFlowConnection[]): boolean {
        const connection = connections?.[0];
        if (!connection) return false;
        const isDataFromActivity = connections?.[0].destinationSessionId !== connections?.[0].sourceSessionId;
        return isDataFromActivity;
    }
}
