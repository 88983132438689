import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { groupBy } from 'lodash';
import {
    BehaviorSubject,
    combineLatest,
    distinctUntilChanged,
    map,
    Observable,
    of,
    startWith,
    switchMap,
    tap,
} from 'rxjs';

import {
    ActivityService,
    copyTemplateErrorTitle,
    copyTemplateProgressMessage,
    copyTemplateProgressTitle,
    FavoriteTemplateService,
    FiltersService,
    LinkAccessService,
    projectCreateFromTemplateErrorTitle,
    projectCreateProgressFromTemplateMessage,
    projectCreateProgressFromTemplateTitle,
    projectCreateSuccessFromTemplateMessage,
    projectCreateSuccessFromTemplateTitle,
    ProjectService,
    PublicTemplateService,
    savePublicActivityTmpAsTemplateInProgress,
    savePublicActivityTmpAsTemplateSuccessfully,
    savePublicProjectTmpAsTemplateInProgress,
    savePublicProjectTmpAsTemplateSuccess,
    savePublicSessionTmpAsTemplateInProgress,
    savePublicSessionTmpAsTemplateSuccess,
    savePublicTemplateTitleError,
    savePublicTemplateTitleProgress,
    savePublicTemplateTitleSuccess,
    SessionService,
    TemplateService,
} from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUserId, selectFeatureToggle } from '@accenture/global-store';
import {
    DefaultFilterObject,
    deletingTemplateErrorSnackbarTitle,
    deletingTemplateInProgressSnackbarTitle,
    errorMessageSnackbarText,
    FavoriteAccess,
    FeatureToggleName,
    initialDefaultFilterObject,
    ParentType,
    parentTypeToDisplay,
    PublicAccess,
    routerLinksMap,
    TemplateAccessType,
    templateDeletedSnackbarTitle,
    TemplateTab,
    templateTabValue,
} from '@accenture/shared/data';
import { ConfirmationDialogComponent, DialogService, SnackbarService, SnackBarTypes } from '@accenture/shared/ui';
import { ComingSoonService } from '@accenture/shared/ui';

import { UseTemplateStore } from '../component-stores/use-template.store';
import { PublishTemplateDialogComponent } from '../publish-template-dialog/publish-template-dialog.component';
import { templateEditorWelcomeSnackBarTitle } from '../template-editor-welcome-snackbar/constants';
import { TemplateEditorWelcomeSnackbarComponent } from '../template-editor-welcome-snackbar/template-editor-welcome-snackbar.component';
import {
    confirmationDeletionMessages,
    projectTemplateHasBeenDeletedSnackbarText,
    projectTemplateIsBeingDeletedSnackbarText,
} from '../templates-list/constants';
import { UseTemplateDialogComponent } from '../use-template-dialog/use-template-dialog.component';

export interface FavoriteTemplatesViewModel {
    activeFavoriteTab: TemplateTab;
    areThereAnyFavoriteTemplates: boolean;
    favoriteTemplatesOfCurrentTab: FavoriteAccess[];
    filteredFavoriteTemplates: FavoriteAccess[];
    isFiltersApplied: boolean;
    isFilterAndSortPanelOpened: boolean;
    isTemplateTeamPanelOpened: boolean;
    searchValue: string;
    isLoading: boolean;
    previewedTemplate: PublicAccess | FavoriteAccess;
    userId: string;
    isEditPending: boolean;
    showDeepLinks: boolean;
}

const defaultViewModel = {
    activeFavoriteTab: TemplateTab.All,
    areThereAnyFavoriteTemplates: false,
    favoriteTemplatesOfCurrentTab: [],
    filteredFavoriteTemplates: [],
    isFiltersApplied: false,
    isFilterAndSortPanelOpened: false,
    isTemplateTeamPanelOpened: false,
    searchValue: '',
    isLoading: true,
    previewedTemplate: null,
    userId: '',
    isEditPending: false,
    showDeepLinks: false,
} as FavoriteTemplatesViewModel;

@Injectable()
export class FavoriteTemplatesFacade implements OnDestroy {
    private areThereAnyFavoriteTemplates: boolean;
    private parentTypeToDisplay = parentTypeToDisplay;
    private userId: string;

    private isLoading$ = new BehaviorSubject<boolean>(false);
    private isFilterAndSortPanelOpened$ = new BehaviorSubject<boolean>(false);
    private isTemplateTeamPanelOpened$ = new BehaviorSubject<boolean>(false);
    private searchValue$ = new BehaviorSubject<string>('');
    private editingTemplatePending$ = new BehaviorSubject(false);

    vm$ = this.buildViewModel();

    constructor(
        private store: Store<AppState>,
        private favoriteTemplateService: FavoriteTemplateService,
        private dialogService: DialogService,
        private templateService: TemplateService,
        private snackbarService: SnackbarService,
        private sessionService: SessionService,
        private activityService: ActivityService,
        private useTemplateStore: UseTemplateStore,
        private router: Router,
        private filtersService: FiltersService,
        private projectService: ProjectService,
        private publicTemplateService: PublicTemplateService,
        private comingSoonService: ComingSoonService,
        private linkAccessService: LinkAccessService,
    ) {
        // set coming soon page visibility
        this.comingSoonService.useComingSoonPage = true;
    }

    openForPreview(template: FavoriteAccess): void {
        this.useTemplateStore.setPreviewTemplate(template);
    }

    openTeamMembersPanel(): void {
        this.isTemplateTeamPanelOpened$.next(true);
    }

    closeTeamMembersPanel(): void {
        this.isTemplateTeamPanelOpened$.next(false);
    }

    setSearchValue(value: string): void {
        this.searchValue$.next(value);
    }

    toggleFilterAndSortPanel(opened: boolean): void {
        this.isFilterAndSortPanelOpened$.next(opened);
    }

    setFavoriteTab(favoriteTab: TemplateTab): void {
        if (!this.userId) {
            return;
        }

        this.filtersService.setFavoriteTemplateViewTab(favoriteTab);
        this.favoriteTemplateService.updateFavoriteFilters(this.userId, {
            tab: favoriteTab,
        });
    }

    openPublishDialog(template: FavoriteAccess): void {
        this.dialogService.open(PublishTemplateDialogComponent, {
            width: '768px',
            panelClass: 'tt9-modal',
            data: template,
        });
    }

    useTemplate(templateType: ParentType, templateId: string, publicAccessId: string): void {
        if (publicAccessId) {
            this.useTemplateStore.setUsePublicAccessId(publicAccessId);
        }
        this.useTemplateStore.setUseTemplateId(templateId);
        this.useTemplateStore.setUseTemplateType(templateType);
        this.useTemplateStore.setSelectedProjectId('');
        this.useTemplateStore.setIsNewProject(false);

        switch (templateType) {
            case ParentType.Projects:
            case ParentType.ProjectTemplates:
            case ParentType.PublicProjectTemplates: {
                this.createProjectFromTemplate(templateId, publicAccessId);
                break;
            }
            case ParentType.Templates:
            case ParentType.ActivityTemplates:
            case ParentType.PublicActivityTemplates:
            case ParentType.PublicSessionTemplates: {
                this.openUseTemplateModal();
                break;
            }
        }
    }

    copyLinkAccessToClipboard(template: TemplateAccessType): void {
        this.linkAccessService.copyProjectTemplateLinkAccessToClipboard(template);
    }

    async saveAsTemplate(template: FavoriteAccess): Promise<void> {
        const parentTabType = template.templateType;

        switch (parentTabType) {
            case ParentType.PublicProjectTemplates:
                this.savePublicProjectTmpAsTemplate(template);
                break;
            case ParentType.PublicSessionTemplates:
                this.savePublicSessionTmpAsTemplate(template);
                break;
            case ParentType.PublicActivityTemplates:
                this.savePublicActivityTmpAsTemplate(template);
                break;
            default:
                break;
        }
    }

    async removeFromFavorite(template: FavoriteAccess, publicAccessId: string): Promise<void> {
        try {
            this.isLoading$.next(true);
            await this.favoriteTemplateService.onDeleteFavoriteTemplate(template, publicAccessId);

            if (!this.areThereAnyFavoriteTemplates) {
                this.setFavoriteTab(TemplateTab.All);
            }
        } catch {
            console.error('Error while updating favorite template');
        } finally {
            this.isLoading$.next(false);
        }
    }

    async deleteTemplateConfirm(template: FavoriteAccess): Promise<void> {
        const templateToDelete = `${this.parentTypeToDisplay[template.templateType]} template`;

        this.dialogService.open(ConfirmationDialogComponent, {
            width: '444px',
            panelClass: 'tt9-modal',
            title: `Delete ${template.name} ${templateToDelete}?`,
            confirmBtnText: 'Delete',
            cancelBtnText: 'Cancel',
            isWarning: true,
            text: confirmationDeletionMessages(templateToDelete),
            confirm: () => this.deleteTemplate(template, templateToDelete),
        });
    }

    async deleteTemplate(template: FavoriteAccess, templateToDelete: string): Promise<void> {
        try {
            this.templateService.deletingTemplateIds$.next([
                ...this.templateService.deletingTemplateIds$.getValue(),
                template.templateId,
            ]);
            this.snackbarService.showInfoSnackBar(
                deletingTemplateInProgressSnackbarTitle,
                projectTemplateIsBeingDeletedSnackbarText(templateToDelete),
            );
            switch (template.templateType) {
                case ParentType.ProjectTemplates:
                    await this.projectService.deleteProject(template.templateId, ParentType.ProjectTemplates);
                    break;
                case ParentType.Templates:
                    await this.sessionService.deleteSession(template.templateType, template.id, template.templateId);
                    break;
                case ParentType.ActivityTemplates: {
                    await this.activityService.deleteActivity(
                        template.templateType,
                        template.templateId,
                        template.templateId,
                    );
                    break;
                }
            }

            this.snackbarService.showSuccessSnackBar(
                templateDeletedSnackbarTitle,
                projectTemplateHasBeenDeletedSnackbarText(templateToDelete),
            );
        } catch (e) {
            console.error(deletingTemplateErrorSnackbarTitle);
            this.snackbarService.showSuccessSnackBar(
                deletingTemplateErrorSnackbarTitle,
                `${templateToDelete} has not been deleted`,
            );
            this.templateService.deletingTemplateIds$.next(
                this.templateService.deletingTemplateIds$
                    .getValue()
                    .filter(templateId => templateId !== template.templateId),
            );
        }
    }

    async editTemplate(favoriteAccess: FavoriteAccess): Promise<void> {
        if (favoriteAccess.draftTemplateId) {
            this.navigateToEditingTemplate(favoriteAccess);
            return;
        }

        const snackBarRef = this.snackbarService.showInfoSnackBar(
            copyTemplateProgressTitle,
            copyTemplateProgressMessage,
        );
        this.editingTemplatePending$.next(true);
        try {
            const newTemplateId = await this.publicTemplateService.createEditablePublicTemplate(
                favoriteAccess.templateId,
                favoriteAccess.templateType,
                this.userId,
                true,
            );
            snackBarRef.dismiss();
            this.navigateToEditingTemplate(favoriteAccess, newTemplateId);
        } catch (e) {
            console.error(copyTemplateErrorTitle);
            this.snackbarService.showErrorSnackBar(copyTemplateErrorTitle, errorMessageSnackbarText);
        }
        this.editingTemplatePending$.next(false);
    }

    private buildViewModel(): Observable<FavoriteTemplatesViewModel> {
        return combineLatest([
            this.store.select(selectAuthenticatedUserId),
            this.store.select(selectFeatureToggle(FeatureToggleName.ShowDeepLinks)),
        ]).pipe(
            switchMap(([userId, showDeepLinks]) => {
                this.userId = userId;

                return combineLatest([
                    this.filtersService.favoriteTemplatesViewTab$,
                    this.getFavoriteTemplatesByUserId(userId),
                    this.getAllFavoriteTemplatesFilters(),
                    this.isFilterAndSortPanelOpened$.asObservable().pipe(distinctUntilChanged()),
                    this.isTemplateTeamPanelOpened$.asObservable().pipe(distinctUntilChanged()),
                    this.searchValue$.asObservable().pipe(
                        map((value: string) => value.trim().toLowerCase()),
                        distinctUntilChanged(),
                    ),
                    this.useTemplateStore.previewTemplate$,
                    this.editingTemplatePending$.asObservable().pipe(distinctUntilChanged()),
                    this.isLoading$.asObservable().pipe(distinctUntilChanged()),
                ]).pipe(
                    map(
                        ([
                            activeFavoriteTab,
                            allFavoriteTemplates,
                            favoriteFilters,
                            isFilterAndSortPanelOpened,
                            isTemplateTeamPanelOpened,
                            searchValue,
                            previewedTemplate,
                            isEditPending,
                            isLoading,
                        ]) => {
                            this.areThereAnyFavoriteTemplates = !!allFavoriteTemplates?.length;

                            const favoriteTemplatesOfCurrentTab = this.getTemplatesByTab(
                                allFavoriteTemplates,
                                activeFavoriteTab,
                            );
                            const filteredFavoriteTemplates = this.filtersService.filterAndSortFavoriteTemplates(
                                favoriteTemplatesOfCurrentTab,
                                favoriteFilters,
                                searchValue,
                                userId,
                            );

                            return {
                                showDeepLinks,
                                activeFavoriteTab,
                                favoriteTemplatesOfCurrentTab,
                                filteredFavoriteTemplates,
                                isFilterAndSortPanelOpened,
                                isTemplateTeamPanelOpened,
                                searchValue,
                                isLoading,
                                userId,
                                previewedTemplate,
                                isEditPending,
                                areThereAnyFavoriteTemplates: !!allFavoriteTemplates?.length,
                                isFiltersApplied: this.filtersService.isFiltersApplied(
                                    favoriteFilters,
                                    initialDefaultFilterObject,
                                ),
                            };
                        },
                    ),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    // Duplicate methods will be refactoring

    private async navigateToEditingTemplate(favoriteAccess: FavoriteAccess, newTemplateId?: string): Promise<void> {
        const redirectUrl = this.getEditingTemplateLink(favoriteAccess, newTemplateId);

        await this.router.navigateByUrl(redirectUrl);

        if (!favoriteAccess?.hideWelcomeSnackBar) {
            this.snackbarService.showCustomSnackBar(
                TemplateEditorWelcomeSnackbarComponent,
                {
                    title: templateEditorWelcomeSnackBarTitle,
                    isVisibleDoNotShowAgain: true,
                    onClickDoNotShowAgain: (value: boolean) => {
                        this.hideWelcomeSnackbar(favoriteAccess, value);
                    },
                },
                SnackBarTypes.Info,
                0,
            );
        }
    }

    private async createProjectFromTemplate(templateId: string, publicAccessId?: string): Promise<void> {
        this.showInfoCreateProjectFromTemplateSnackBar();

        try {
            const createdProjectId = await this.projectService.createProjectFromProjectTemplate(
                publicAccessId ? ParentType.PublicProjectTemplates : ParentType.ProjectTemplates,
                templateId,
                this.userId,
                true,
                publicAccessId,
            );

            if (createdProjectId) {
                this.router.navigate(['/project', `${createdProjectId}`]);
            }

            this.showSuccessCreateProjectFromTemplateSnackBar();
        } catch (e) {
            console.error('Error when creating a project');
            this.showErrorCreateProjectFromTemplateSnackBar();
        }
    }

    private getAllFavoriteTemplatesFilters(): Observable<DefaultFilterObject> {
        if (!this.userId) {
            return of({});
        }

        return this.favoriteTemplateService.getCurrentUserAllFavoriteTemplatesFilters(this.userId).pipe(
            tap(filterAndSort => {
                this.filtersService.setFavoriteTemplateViewTab(filterAndSort?.tab || TemplateTab.All);
            }),
            switchMap(allFilters =>
                this.filtersService.favoriteTemplatesViewTab$.pipe(
                    distinctUntilChanged(),
                    map(activeTemplateTab => {
                        return {
                            ...initialDefaultFilterObject,
                            ...(allFilters?.[templateTabValue[activeTemplateTab]] || {}),
                        };
                    }),
                ),
            ),
        );
    }

    private getParentType(templateTab: TemplateTab): string[] {
        switch (templateTab) {
            case TemplateTab.Projects:
                return [ParentType.PublicProjectTemplates, ParentType.ProjectTemplates];
            case TemplateTab.Sessions:
                return [ParentType.PublicSessionTemplates, ParentType.Templates];
            case TemplateTab.Activities:
                return [ParentType.PublicActivityTemplates, ParentType.ActivityTemplates];
        }
    }

    private getTemplatesByTab(
        allFavoriteTemplates: FavoriteAccess[],
        activeTemplateTab: TemplateTab,
    ): FavoriteAccess[] {
        const templatesByType = {
            [templateTabValue[TemplateTab.All]]: allFavoriteTemplates,
            ...groupBy(allFavoriteTemplates, 'templateType'),
        };
        if (activeTemplateTab === TemplateTab.All) {
            return templatesByType[templateTabValue[TemplateTab.All]] || [];
        }

        const templatesToDisplay = this.getParentType(activeTemplateTab).reduce((acc, type) => {
            const templates = templatesByType[type] || [];
            acc.push(...templates);

            return acc;
        }, []);

        return templatesToDisplay || [];
    }

    private openUseTemplateModal(): void {
        this.dialogService.open(UseTemplateDialogComponent, {
            width: 'auto',
            panelClass: 'tt9-modal',
        });
    }

    private getFavoriteTemplatesByUserId(userId: string): Observable<FavoriteAccess[]> {
        if (!userId) {
            return of([]);
        }
        return combineLatest([
            this.favoriteTemplateService.getFavoriteTemplatesByUserId(userId),
            this.templateService.deletingTemplateIds$,
        ]).pipe(
            map(([allTemplates, deletingTemplateIds]) =>
                allTemplates?.filter(template => !deletingTemplateIds.includes(template.templateId)),
            ),
        );
    }

    private showInfoCreateProjectFromTemplateSnackBar(): void {
        this.snackbarService.showInfoSnackBar(
            projectCreateProgressFromTemplateTitle,
            projectCreateProgressFromTemplateMessage,
        );
    }

    private showSuccessCreateProjectFromTemplateSnackBar(): void {
        this.snackbarService.showSuccessSnackBar(
            projectCreateSuccessFromTemplateTitle,
            projectCreateSuccessFromTemplateMessage,
        );
    }

    private showErrorCreateProjectFromTemplateSnackBar(): void {
        this.snackbarService.showErrorSnackBar(projectCreateFromTemplateErrorTitle, errorMessageSnackbarText);
    }

    private async savePublicProjectTmpAsTemplate(template: FavoriteAccess): Promise<void> {
        this.snackbarService.showInfoSnackBar(
            savePublicTemplateTitleProgress,
            savePublicProjectTmpAsTemplateInProgress,
        );
        try {
            const projectTmpId = await this.projectService.saveAsTemplate(
                template.templateId,
                ParentType.PublicProjectTemplates,
                true,
            );
            this.snackbarService.showSuccessSnackBar(
                savePublicTemplateTitleSuccess,
                savePublicProjectTmpAsTemplateSuccess,
            );
            const redirectUrl = this.getTemplateLink(template, projectTmpId);
            this.router.navigateByUrl(redirectUrl);
        } catch (e) {
            console.error(savePublicTemplateTitleError);
            this.snackbarService.showErrorSnackBar(savePublicTemplateTitleError, errorMessageSnackbarText);
        }
    }

    private async savePublicActivityTmpAsTemplate(template: FavoriteAccess): Promise<void> {
        this.snackbarService.showInfoSnackBar(
            savePublicTemplateTitleProgress,
            savePublicActivityTmpAsTemplateInProgress,
        );
        try {
            const activityTmpId = await this.activityService.saveAsTemplate(
                template.templateId,
                template.templateId,
                ParentType.PublicActivityTemplates,
                true,
            );
            const redirectUrl = this.getTemplateLink(template, activityTmpId);
            this.router.navigateByUrl(redirectUrl);
            this.snackbarService.showSuccessSnackBar(
                savePublicTemplateTitleSuccess,
                savePublicActivityTmpAsTemplateSuccessfully,
            );
        } catch (e) {
            console.error(savePublicTemplateTitleError);
            this.snackbarService.showErrorSnackBar(savePublicTemplateTitleError, errorMessageSnackbarText);
        }
    }

    private async savePublicSessionTmpAsTemplate(template: FavoriteAccess): Promise<void> {
        this.snackbarService.showInfoSnackBar(
            savePublicTemplateTitleProgress,
            savePublicSessionTmpAsTemplateInProgress,
        );
        try {
            const sessionTmpId = await this.sessionService.saveAsTemplate(
                template.templateId,
                template.templateId,
                ParentType.Templates,
                ParentType.PublicSessionTemplates,
                true,
            );
            const redirectUrl = this.getTemplateLink(template, sessionTmpId);
            this.router.navigateByUrl(redirectUrl);
            this.snackbarService.showSuccessSnackBar(
                savePublicTemplateTitleSuccess,
                savePublicSessionTmpAsTemplateSuccess,
            );
        } catch (e) {
            console.error(savePublicTemplateTitleError);
            this.snackbarService.showErrorSnackBar(savePublicTemplateTitleError, errorMessageSnackbarText);
        }
    }

    private getTemplateLink(template: FavoriteAccess, templateId?: string): string {
        if (template.templateType === ParentType.PublicActivityTemplates) {
            return `/${routerLinksMap[ParentType.ActivityTemplates]}/${template.activityType.toLowerCase()}-edit/${
                templateId || template.templateId
            }`;
        }

        if (template.templateType === ParentType.PublicSessionTemplates) {
            return `/${routerLinksMap[ParentType.Templates]}/${templateId || template.templateId}`;
        }

        return `/${routerLinksMap[ParentType.ProjectTemplates]}/${templateId || template.templateId}`;
    }

    private getEditingTemplateLink(template: FavoriteAccess, templateId?: string): string {
        if (template.templateType === ParentType.PublicActivityTemplates) {
            return `/${
                routerLinksMap[ParentType.PublicActivityTemplates]
            }/${template.activityType.toLowerCase()}-edit/${templateId || template.draftTemplateId}`;
        }

        if (template.templateType === ParentType.PublicSessionTemplates) {
            return `/${routerLinksMap[ParentType.PublicSessionTemplates]}/${templateId || template.draftTemplateId}`;
        }

        return `/${routerLinksMap[ParentType.PublicProjectTemplates]}/${templateId || template.draftTemplateId}`;
    }

    private async hideWelcomeSnackbar(favoriteAccess: FavoriteAccess, value: boolean): Promise<void> {
        await this.publicTemplateService.updateTemplateSnackBarVisibility(
            favoriteAccess.publicAccessId,
            value,
            favoriteAccess.id,
        );
    }

    ngOnDestroy(): void {
        // reset coming soon page visibility
        this.openForPreview(null);
        this.comingSoonService.useComingSoonPage = false;
    }
}
