import { Injectable } from '@angular/core';
import { combineLatest, map, Observable, of, switchMap } from 'rxjs';

import { Attribute, AttributeClass } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable()
export class AttributesDataService {
    constructor(private firestoreService: FirestoreService) {}

    getDefaultAttributeClasses(): Observable<AttributeClass[]> {
        return this.firestoreService.getCollection<AttributeClass>('/attributeClasses').pipe(
            switchMap(classes =>
                combineLatest([of(classes), ...classes.map(({ id }) => this.getDefaultAttributesByClassId(id))]),
            ),
            map(([classes, ...attributes]: any[]) =>
                classes.map((attributeClass, index) => ({
                    ...attributeClass,
                    attributes: attributes[index],
                })),
            ),
        );
    }

    private getDefaultAttributesByClassId(classId: string): Observable<Attribute[]> {
        return this.firestoreService
            .getCollection<Attribute>(`/attributeClasses/${classId}/attributes`)
            .pipe(map(attributes => attributes.sort((a, b) => a.name.localeCompare(b.name))));
    }
}
