import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Observable, tap } from 'rxjs';

import {
    AuthenticationTab,
    NavigationTab,
    Session,
    TeamMember,
    tooltipTexts,
    User,
    UserStatus,
} from '@accenture/shared/data';
import { SharedMaterialModule } from '@accenture/shared/material';
import { IconColor, IconSize, SharedUiModule } from '@accenture/shared/ui';

import { BotChatRecorderComponent } from '../bot-chat-recorder/bot-chat-recorder.component';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';
import { InAppNotificationsComponent } from '../in-app-notifications/in-app-notifications.component';
import { accessSupportUrl, viewGuidesUrl } from './constants';
import { FredHeaderFacade } from './fred-header-facade';

@Component({
    selector: 'accenture-fred-header',
    templateUrl: './fred-header.component.html',
    styleUrls: ['./fred-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [FredHeaderFacade],
    standalone: true,
    imports: [
        CommonModule,
        RouterLink,
        SharedMaterialModule,
        SharedUiModule,
        BreadcrumbsComponent,
        InAppNotificationsComponent,
        BotChatRecorderComponent,
    ],
})
export class FredHeaderComponent {
    @Input() isLogin: boolean;
    @Output() toggleSidebarEvent = new EventEmitter<void>();

    loggedInAsGuestTooltipText = tooltipTexts.loggedInAsGuest;
    menuOpen: boolean;
    helpMenuOpen: boolean;
    botOpen: boolean;
    notificationsPanelOpened: boolean;

    private readonly facade: FredHeaderFacade = inject(FredHeaderFacade);
    vm$ = this.facade.vm$.pipe(
        tap((res) => {
            if (res.authError && res.authError === UserStatus.Deactivated) {
                this.facade.navigateDeactivation();
            }
        }),
    );

    projectAndSessionInfo$: Observable<{
        session: Session;
        teamMember: TeamMember;
    }> = this.facade.projectAndSessionInfo();
    isSessionMenuOpen = false;
    accessSupportUrl = accessSupportUrl;
    viewGuidesUrl = viewGuidesUrl;
    iconSmallSize = IconSize.Small;
    iconColor = IconColor;
    sendFeedbackIconColor = IconColor.Secondary;
    accessSupportIconColor = IconColor.Secondary;

    toggleSidebar() {
        this.toggleSidebarEvent.emit();
    }

    isDashboard(tab: NavigationTab): boolean {
        return tab === NavigationTab.Dashboard;
    }

    isTemplates(tab: NavigationTab): boolean {
        return tab === NavigationTab.Templates;
    }

    //TODO DELETE WHEN COLLECTION FEATURE WILL BE FINISHED
    isProjects(tab: NavigationTab): boolean {
        return tab === NavigationTab.Projects;
    }

    isLoginTab(tab: AuthenticationTab): boolean {
        return tab === AuthenticationTab.Login;
    }

    isSingUp(tab: AuthenticationTab): boolean {
        return tab === AuthenticationTab.SingUp;
    }

    navigateBack(): void {
        this.facade.navigateBack();
        return;
    }

    logout(): void {
        this.facade.logout();
    }

    setDashboard(): void {
        this.facade.navigateHome(NavigationTab.Dashboard);
    }

    setTemplates(): void {
        this.facade.navigateHome(NavigationTab.Templates);
    }

    setProjects(): void {
        this.facade.navigateHome(NavigationTab.Projects);
    }

    setCollections(): void {
        this.facade.navigateHome(NavigationTab.Collections);
    }

    setSessions(): void {
        this.facade.navigateHome(NavigationTab.Sessions);
    }

    setLogin(): void {
        this.facade.navigateAuthentication(AuthenticationTab.Login);
    }

    setSingUp(): void {
        this.facade.navigateAuthentication(AuthenticationTab.SingUp);
    }

    openContactSupportDialog(): void {
        this.facade.openContactSupportDialog();
    }

    updateUser(user: Partial<User>): void {
        this.facade.updateUser(user);
    }

    markAllNotificationsAsRead(): void {
        this.facade.markAllNotificationsAsRead();
    }

    deleteAllNotifications(): void {
        this.facade.deleteAllNotifications();
    }

    openTermsOfUse(): void {
        this.facade.openTermsOfUse();
    }

    toggleHelpMenu(helpMenuAvailable: boolean): void {
        if (!helpMenuAvailable) {
            return;
        }

        this.helpMenuOpen = !this.helpMenuOpen;
    }
}
