import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';

import { SearchFilterOptions } from '@accenture/shared/data';
import { trackById } from '@accenture/shared/util';

import { DisplaySessionSelectRole, SessionDropdownFilter, SessionSelectEnum } from '../../constants';

@Component({
    selector: 'accenture-session-role-dropdown',
    templateUrl: './session-role-dropdown.component.html',
    styleUrls: ['./session-role-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionRoleDropdownComponent {
    @Input() appearance!: MatFormFieldAppearance;
    @Input() floatLabel!: 'auto' | 'always';
    @Input() isSearch?: boolean;
    @Input() activeFilterOption?: SearchFilterOptions;

    @Output() selectionClick = new EventEmitter();

    trackById = trackById;
    sessionDropdownFilter = SessionDropdownFilter;
    displaySessionSelectRole = DisplaySessionSelectRole;
    searchFilterOptions = Object.values(SearchFilterOptions);

    get defaultValue(): SearchFilterOptions | SessionSelectEnum | undefined {
        return this.isSearch ? this.activeFilterOption : SessionSelectEnum.All;
    }
}
