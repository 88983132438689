import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { groupBy, mapValues } from 'lodash';
import { BehaviorSubject, combineLatest, map, Observable, startWith, tap } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

import { FiltersService, SessionOptionsService, SessionService } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUserId } from '@accenture/global-store';
import {
    DefaultFilterObject,
    Dictionary,
    initialDefaultFilterObject,
    Phase,
    SessionOptions,
    SessionOptionsType,
    sessionsSortOptionsValues,
    SubPhase,
    Tag,
} from '@accenture/shared/data';
import { sortAlphabeticByCase } from '@accenture/shared/util';

export interface SessionsFiltersAndSortNewViewModel {
    filtersAndSort: DefaultFilterObject;

    filteredTags: Tag[];
    filteredPhases: Phase[];
    filteredSubPhases: SubPhase[];

    tagsMap: Dictionary<string>;
    subPhasesMap: Dictionary<string>;
    sortOptionsValues: Dictionary<string>;
    isAllCollectionsPanelOpened: boolean;
    isLoading: boolean;
}

export const sessionsViewModel = {
    filtersAndSort: initialDefaultFilterObject,
    filteredTags: [],
    filteredPhases: [],
    filteredSubPhases: [],
    tagsMap: {},
    phasesMap: {},
    subPhasesMap: {},
    sortOptionsValues: sessionsSortOptionsValues,
    isAllCollectionsPanelOpened: false,
    isLoading: true,
};

@Injectable()
export class SessionsFiltersAndSortNewFacade {
    autocompleteValues$ = new BehaviorSubject<Dictionary<string>>({});
    isAllCollectionsPanelOpened$ = new BehaviorSubject<boolean>(false);

    vm$ = this.buildViewModel();

    private userId: string;

    constructor(
        private store: Store<AppState>,
        private sessionService: SessionService,
        private sessionOptionsService: SessionOptionsService,
        private filtersService: FiltersService,
    ) {}

    applyFiltersAndSort(filters: DefaultFilterObject): void {
        this.sessionService.updateSessionsFilters(this.userId, filters);
    }

    setOptionType(optionType: SessionOptions): void {
        this.filtersService.sessionOptionType$.next(optionType);
        this.isAllCollectionsPanelOpened$.next(true);
    }

    toggleOptionsPanel(opened: boolean): void {
        this.isAllCollectionsPanelOpened$.next(opened);
    }

    private buildViewModel(): Observable<SessionsFiltersAndSortNewViewModel> {
        return combineLatest([
            this.store.select(selectAuthenticatedUserId),
            this.sessionOptionsService.getOptions(SessionOptions.Tag),
            this.sessionOptionsService.getOptions(SessionOptions.Phase),
            this.sessionOptionsService.getOptions(SessionOptions.SubPhase),
        ]).pipe(
            switchMap(([userId, tags, phases, subPhases]) => {
                this.setActualData(userId);
                const filters$ = this.getSessionsFilters();
                return combineLatest([
                    filters$.pipe(
                        map((filtersAndSort) => ({
                            ...initialDefaultFilterObject,
                            ...filtersAndSort,
                        })),
                    ),
                    this.autocompleteValues$.asObservable().pipe(distinctUntilChanged()),
                    this.isAllCollectionsPanelOpened$.asObservable().pipe(distinctUntilChanged()),
                ]).pipe(
                    map(([filtersAndSort, autocompleteValues, isAllCollectionsPanelOpened]) => {
                        return {
                            filtersAndSort,
                            isAllCollectionsPanelOpened,
                            filteredTags: this.filterSelectData(filtersAndSort.tags, tags, autocompleteValues.tag),
                            filteredPhases: this.filterSelectData(
                                filtersAndSort.phases,
                                phases,
                                autocompleteValues.phase,
                            ),
                            filteredSubPhases: this.filterSelectData(
                                filtersAndSort.subPhases,
                                subPhases,
                                autocompleteValues.subPhase,
                            ),
                            tagsMap: mapValues(groupBy(tags, 'id'), (tags) => tags[0].name),
                            phasesMap: mapValues(groupBy(phases, 'id'), (phases) => phases[0].name),
                            subPhasesMap: mapValues(groupBy(subPhases, 'id'), (subPhases) => subPhases[0].name),
                            sortOptionsValues: sessionsSortOptionsValues,
                            isLoading: false,
                        };
                    }),
                );
            }),
            startWith(sessionsViewModel),
        );
    }

    private setActualData(userId: string): void {
        this.userId = userId;
    }

    private getSessionsFilters(): Observable<DefaultFilterObject> {
        return this.store.select(selectAuthenticatedUserId).pipe(
            tap((userId) => (this.userId = userId)),
            switchMap((userId) => this.sessionService.getUserSessionsFilters(userId)),
        );
    }

    private filterSelectData(currentData: string[], data: SessionOptionsType[], value = ''): SessionOptionsType[] {
        const filteredData = data.filter((tag) => {
            const tagName = tag?.name?.toLowerCase();
            return tagName?.includes(value?.toLowerCase()) && !currentData?.includes(tag.id);
        });
        return sortAlphabeticByCase(filteredData);
    }
}
