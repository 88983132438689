import { Dictionary } from '../dictionary';
import { Tag } from '../tag';

// TODO: create a common approach for session/collection options
export enum CollectionOptions {
    Tag = 'tags',
}

export const collectionOptionsTitle = {
    [CollectionOptions.Tag]: 'Tags',
};

export const newCollectionOptionText = {
    [CollectionOptions.Tag]: '(New tag)',
};

export const collectionOptionsDialogTitle = {
    [CollectionOptions.Tag]: 'All tags',
};

export const collectionOptionsArray = [CollectionOptions.Tag];

export interface SelectedCollectionOptions {
    tags: Dictionary<string>;
}

export type CollectionOptionsType = Tag;

export interface CollectionOptionsSelection {
    optionType: CollectionOptions;
    selectedOption: Partial<CollectionOptionsType>;
    removed?: boolean;
}
