<accenture-base-snack-bar
  [title]="'Session invite warning!'"
  [iconClass]="data.iconClass"
  [iconName]="data.iconName"
  (snackbarClosed)="close()"
>
  <p class="tt9-body-2">Some invites have not been sent</p>

  <div class="d-flex-end mt-16">
    <button [class]="data.actionButtonClass" (click)="showDetails()">View Details</button>
  </div>
</accenture-base-snack-bar>
