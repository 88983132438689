import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

import { Collection, CollectionSession, DBPathHelper, UserCollection } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable()
export class CollectionDataService {
    constructor(private firestoreService: FirestoreService) {}

    getCollectionById(collectionId: string): Observable<Collection> {
        return this.firestoreService.getDocument(DBPathHelper.getCollectionPath(collectionId));
    }

    getSessionsByCollectionId(collectionId: string): Observable<CollectionSession[]> {
        return this.firestoreService.getDocumentsByPropertyWithoutCaching(
            DBPathHelper.getCollectionSessionsPath(),
            'collectionId',
            collectionId,
        );
    }

    getUserCollection(collectionId: string, userId: string): Observable<UserCollection> {
        return this.firestoreService
            .getDocumentsByMultipleProperties<UserCollection>(
                DBPathHelper.getUserCollectionsPath(),
                new Map([
                    ['collectionId', collectionId],
                    ['userId', userId],
                ]),
            )
            .pipe(
                map((userCollections: UserCollection[]) => {
                    if (userCollections?.length > 1) {
                        throw 'Data error: User Collection is duplicated';
                    }

                    if (userCollections?.length === 0) {
                        throw 'Data error: User Collection does not exist';
                    }

                    return userCollections[0];
                }),
                catchError((e) => {
                    console.error(e);
                    return of(null);
                }),
            );
    }
}
