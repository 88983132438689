import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { isUndefined } from 'lodash';
import { Observable, of, zip } from 'rxjs';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';

import { AppState, selectAuthenticatedUserId } from '@accenture/global-store';
import { Activity, notFoundUrl, ParentType, Session, TeamMember } from '@accenture/shared/data';

import { ActivityService } from '../services/activity.service';
import { SessionService } from '../services/session.service';
import { TeamMemberService } from '../services/team-member.service';

@Injectable()
export class ActivityNewGuard {
    constructor(
        private router: Router,
        private store: Store<AppState>,
        private activityService: ActivityService,
        private teamMemberService: TeamMemberService,
        private sessionService: SessionService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const { activityId, sessionId } = this.getParams(route);

        if (sessionId && activityId) {
            return this.resolveRoute(activityId, sessionId, route.queryParams);
        }

        return of(false);
    }

    private resolveRoute(activityId: string, sessionId: string, queryParams?: Params): Observable<boolean> {
        return this.store.select(selectAuthenticatedUserId).pipe(
            skipWhile(userId => isUndefined(userId)),
            take(1),
            switchMap(userId =>
                zip([
                    this.activityService.getSessionActivityById(sessionId, activityId),
                    this.sessionService.getSessionNew(ParentType.Sessions, sessionId),
                    this.teamMemberService.getSessionTeamMemberNew(sessionId, userId),
                ]),
            ),
            take(1),
            map(([activity, session, teamMember]: [Activity, Session, TeamMember]) => {
                if (!activity || (teamMember?.isSessionParticipant && activity && !activity.visible)) {
                    this.router.navigateByUrl(notFoundUrl);
                    return false;
                }

                if (!teamMember) {
                    return false;
                }

                if (teamMember?.isSessionLeader) {
                    return true;
                }
                return true;
            }),
        );
    }

    private getParams(route: ActivatedRouteSnapshot): Params {
        if (route.children?.length) {
            return this.getParams(route.children[0]);
        }

        return route.params;
    }
}
