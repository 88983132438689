import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { unset } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DefaultFilterObject, Dictionary, ParentType, Template, TemplateTab } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import { sortByDateDesc } from '@accenture/shared/util';

import { ProjectService } from './project.service';
import { SessionService } from './session.service';
@Injectable({
    providedIn: 'root',
})
export class TemplateService {
    deletingTemplateIds$ = new BehaviorSubject<string[]>([]);

    constructor(
        private firestoreService: FirestoreService,
        private projectService: ProjectService,
        private sessionService: SessionService,
        private router: Router,
    ) {}

    getTemplate(templateId: string): Observable<Template> {
        return this.firestoreService
            .getDocument<Template>(`/templates/${templateId}`)
            .pipe(map((template) => (template?.id ? new Template(template.id, template) : ({} as Template))));
    }

    getTemplates(): Observable<Template[]> {
        return this.firestoreService
            .getCollection<Template>('/templates')
            .pipe(
                map((templates) =>
                    templates.map((template) => new Template(template.id, template)).sort(sortByDateDesc('updated')),
                ),
            );
    }

    updateDescription(templateId: string, value: string): Promise<void> {
        return this.firestoreService.upsert(`/templates/${templateId}`, {
            description: value,
            updated: this.firestoreService.timestamp,
        });
    }

    updateName(templateId: string, value: string): Promise<void> {
        return this.firestoreService.upsert(`/templates/${templateId}`, {
            name: value,
            updated: this.firestoreService.timestamp,
        });
    }

    getCurrentUserAllTemplatesFilters(
        userId: string | undefined,
    ): Observable<Dictionary<DefaultFilterObject> & { tab?: TemplateTab; homePageTab?: TemplateTab }> {
        return this.firestoreService
            .getDocument<Dictionary<DefaultFilterObject>>(`/users/${userId}/filters/templatesFilters`)
            .pipe(
                map((filter: Dictionary<DefaultFilterObject>) => {
                    unset(filter, 'id');
                    return filter;
                }),
            );
    }

    getCurrentUserAllTemplatesStoreFilters(
        userId: string | undefined,
    ): Observable<Dictionary<DefaultFilterObject> & { tab?: TemplateTab }> {
        return this.firestoreService
            .getDocument<Dictionary<DefaultFilterObject>>(`/users/${userId}/filters/templatesStoreFilters`)
            .pipe(
                map((filter: Dictionary<DefaultFilterObject>) => {
                    unset(filter, 'id');
                    return filter;
                }),
            );
    }

    async updateTemplatesFilters(
        userId: string | undefined,
        data: DefaultFilterObject & { tab?: TemplateTab; homePageTab?: TemplateTab },
    ): Promise<void> {
        await this.firestoreService.upsert(`users/${userId}/filters/templatesFilters`, data);
    }

    async updateTemplatesStoreFilters(
        userId: string | undefined,
        data: DefaultFilterObject & { tab?: TemplateTab },
    ): Promise<void> {
        await this.firestoreService.upsert(`users/${userId}/filters/templatesStoreFilters`, data);
    }

    async resetTemplatesStoreFilters(userId: string | undefined, parentTypeTab: ParentType): Promise<void> {
        await this.firestoreService.upsert(`users/${userId}/filters/templatesStoreFilters`, {
            [parentTypeTab]: this.firestoreService.deleteField,
        });
    }

    async updateOptionsFilters(
        userId: string | undefined,
        optionType: string,
        data: string[],
        parentType: ParentType,
    ): Promise<void> {
        await this.firestoreService.upsert(`/users/${userId}/filters/templatesFilters`, {
            [parentType]: {
                [optionType]: data,
            },
        });
    }

    async updateTemplatesStoreOptionsFilters(
        userId: string,
        optionType: string,
        data: string[],
        parentType: ParentType,
    ): Promise<void> {
        await this.firestoreService.upsert(`/users/${userId}/filters/templatesStoreFilters`, {
            [parentType]: {
                [optionType]: data,
            },
        });
    }

    // TODO: remove when projects is no longer used
    async createProjectFromPublicProjectTemplate(publicAccessId: string, templateId: string, userId): Promise<void> {
        const createdProjectId = await this.projectService.createProjectFromProjectTemplate(
            ParentType.PublicProjectTemplates,
            templateId,
            userId,
            true,
            publicAccessId,
        );

        if (createdProjectId) {
            this.router.navigate(['/project', `${createdProjectId}`]);
        }
    }

    async createSessionFromPublicSessionTemplate(publicAccessId: string, templateId: string, userId): Promise<void> {
        const createdSessionId = await this.sessionService.createSessionFromSessionTemplate(
            ParentType.PublicSessionTemplates,
            templateId,
            userId,
            true,
            publicAccessId,
        );

        if (createdSessionId) {
            this.router.navigate(['/session', `${createdSessionId}`]);
        }
    }
}
