import { createFeatureSelector, createSelector } from '@ngrx/store';

import { User } from '@accenture/shared/data';

import { AUTH_FEATURE_KEY, AuthenticatedUserState } from './auth.reducer';

export const selectAuthState = createFeatureSelector<AuthenticatedUserState>(AUTH_FEATURE_KEY);

export const selectAuthStateError = createSelector(selectAuthState, authStateData => authStateData?.error);

export const selectAuthenticatedUserState = createSelector(selectAuthState, authStateData => authStateData?.user);

export const selectAuthenticatedUser = createSelector(
    selectAuthState,
    authStateData => authStateData?.user || ({} as User),
);

export const selectUserIsAuthenticated = createSelector(
    selectAuthState,
    authStateData => authStateData?.isAuthenticated,
);

export const selectAuthenticatedUserId = createSelector(
    selectAuthenticatedUser,
    authenticatedUser => authenticatedUser?.id,
);
