import { Injectable } from '@angular/core';

import { BotActions, UserCollection } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable({
    providedIn: 'root',
})
export class AIGenerateService {
    constructor(private firestoreService: FirestoreService) {}

    async aiGenerateData<D>(
        prompt: string,
        botAction: BotActions,
        userId: string,
    ): Promise<{ notification: string; session?: D }> {
        return await this.firestoreService.cloudFunctionCallable<{ notification: string; session: D }>(
            'aiGenerateData',
            {
                prompt,
                botAction,
                userId,
            },
        );
    }

    async aiSaveGeneratedData<T>(
        dataToSave: T,
        botAction: BotActions,
        userId: string,
        userCollection?: UserCollection,
    ): Promise<{ notification: { title: string; message: string }; sessionId?: string }> {
        return await this.firestoreService.cloudFunctionCallable('aiGeneratedDataSave', {
            dataToSave,
            botAction,
            userId,
            userCollection,
        });
    }

    async aiGenerateActivityData(
        prompt: string,
        action: BotActions,
        sessionId: string,
        activityId: string,
        userId: string,
    ): Promise<{ notification: string; count?: number }> {
        return await this.firestoreService.cloudFunctionCallable('aiAutoBuilder', {
            userId,
            sessionId,
            activityId,
            action,
            prompt,
        });
    }
}
