import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { breakpoints } from '@accenture/shared/data';
import { AcnBreakpointObserver } from '@accenture/shared/material';

@Injectable({
    providedIn: 'root',
})
export class ComingSoonService {
    private useComingSoonPage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private breakpointsObserver: AcnBreakpointObserver) {}

    get showComingSoonPage$(): Observable<boolean> {
        return combineLatest([
            this.useComingSoonPage$,
            this.breakpointsObserver.getIsMatches(breakpoints.screenAllHandsets),
        ]).pipe(map(([useComingSoonPage, isMobileScreen]) => useComingSoonPage && isMobileScreen));
    }

    set useComingSoonPage(value: boolean) {
        this.useComingSoonPage$.next(value);
    }
}
