import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

import { TeamMemberService } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUserId, selectSessionIdNew } from '@accenture/global-store';
import { SessionRole, TeamMember } from '@accenture/shared/data';

import { SessionsInvitationsByEmailStepNew } from '../../session-invite-dialog-tt9.constants';
import { SelectedForInvitationMemberData } from '../../session-invite-dialog-tt9.models';
import { SessionInviteDialogStore } from '../../session-invite-dialog-tt9.store';

export interface InvitationFormViewModel {
    sessionMemberEmails: string[];
    blacklistLeaderEmails: string[];
    blacklistParticipantEmails: string[];
    isLoading: boolean;
}

const defaultViewModel = {
    sessionMemberEmails: [],
    blacklistLeaderEmails: [],
    blacklistParticipantEmails: [],
    isLoading: true,
};

@Injectable()
export class InvitationFormFacade {
    vm$ = this.buildViewModel();

    private messageValue$ = new BehaviorSubject<string>('');

    constructor(
        private store: Store<AppState>,
        private sessionInviteDialogStore: SessionInviteDialogStore,
        private teamMemberService: TeamMemberService,
    ) {}

    setMessageValue(value: string): void {
        this.messageValue$.next(value);
    }

    setEmails(emails: string[], role: string): void {
        const newSelectInvitationMemberData: SelectedForInvitationMemberData = emails.reduce((data, email) => {
            data[email] = role;

            return data;
        }, {});

        this.sessionInviteDialogStore.addSelectedForInvitationMemberData(newSelectInvitationMemberData);
    }

    removeEmails(emails: string[]): void {
        this.sessionInviteDialogStore.removeSelectedForInvitationMemberData(emails);
    }

    getEmailsByRole(role: SessionRole.Leader | SessionRole.Participant): Observable<string[]> {
        const flowMap = {
            [SessionRole.Leader]: this.sessionInviteDialogStore.leaderEmails$,
            [SessionRole.Participant]: this.sessionInviteDialogStore.participantEmails$,
        };

        return flowMap[role];
    }

    openMemberPanel(role: SessionRole.Leader | SessionRole.Participant): void {
        this.sessionInviteDialogStore.setActiveInvitationRole(role);
        this.sessionInviteDialogStore.setInviteByEmailActiveStep(SessionsInvitationsByEmailStepNew.SelectMembers);
    }

    private buildViewModel(): Observable<InvitationFormViewModel> {
        return combineLatest([
            this.store.select(selectAuthenticatedUserId),
            this.store.select(selectSessionIdNew),
        ]).pipe(
            switchMap(([userId, { sessionId }]) => {
                if (!userId) {
                    return of({
                        ...defaultViewModel,
                        isLoading: false,
                    });
                }

                return combineLatest({
                    sessionMemberEmails: this.getSessionActiveTeamMembersEmails(sessionId),
                    currentLeaderEmails: this.getEmailsByRole(SessionRole.Leader),
                    currentParticipantEmails: this.getEmailsByRole(SessionRole.Participant),
                }).pipe(
                    map((data) => {
                        return {
                            sessionMemberEmails: data.sessionMemberEmails,
                            blacklistLeaderEmails: [...data.sessionMemberEmails, ...data.currentParticipantEmails],
                            blacklistParticipantEmails: [...data.sessionMemberEmails, ...data.currentLeaderEmails],
                            isLoading: false,
                        };
                    }),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    private getSessionActiveTeamMembersEmails(sessionId: string): Observable<string[]> {
        if (sessionId) {
            return this.teamMemberService.getSessionTeamMembersNew(sessionId).pipe(
                map((teamMembers: TeamMember[]) => {
                    return teamMembers.map((user) => user.email);
                }),
            );
        } else {
            return of([]);
        }
    }
}
