import { ActivityType } from '../activities';
import { ActivityItemType, VoteActivityItemType } from '../activity-items';
import { ParentType } from '../parent-type';

export interface DeleteResponsesData {
    activityType: ActivityType;
    parentType?: ParentType;
    parentId: string;
    activityItemId?: string;
    activityItemType?: VoteActivityItemType | ActivityItemType;
    attributes?: string[];
    attributeClassesIds?: string[];
    activityId?: string;
}
