export interface TermsOfUseConfig {
    text: string;
    title: string;
}

export interface SupportConfig {
    email: string;
    contactSupportDialogTitle: string;
    contactSupportDialogSubTitle: string;
}
