import { createAction, props } from '@ngrx/store';

import { AttributeClass } from '@accenture/shared/data';

export const getAttributeClasses = createAction('[Attributes] Get Attribute Classes Data');

export const getAttributeClassesSuccess = createAction(
    '[Attributes] Get Attribute Classes Data Success',
    props<{ attributeClasses: AttributeClass[] }>(),
);
