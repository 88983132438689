import { ChangeDetectionStrategy, Component } from '@angular/core';

import { SessionsInvitationsTab } from '../../session-invite-dialog.constants';
import { SessionInviteDialogStore } from '../../session-invite-dialog.store';

@Component({
    selector: 'accenture-session-invite-tabs',
    templateUrl: './session-invite-tabs.component.html',
    styleUrls: ['./session-invite-tabs.component.scss'],
    providers: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionInviteTabsComponent {
    tabs = [
        { title: 'By Email', tab: SessionsInvitationsTab.ByEmail },
        { title: 'Using a Link', tab: SessionsInvitationsTab.ByLink },
    ];

    activeTab$ = this.sessionInviteDialogStore.activeTab$;

    constructor(private sessionInviteDialogStore: SessionInviteDialogStore) {}

    setActiveTab(tab: SessionsInvitationsTab): void {
        this.sessionInviteDialogStore.setActiveTab(tab);
    }
}
