import { Activity } from './activities';
import { Dictionary } from './dictionary';
import { DuplicateType } from './duplicate';
import { FavoriteAccess } from './favorite-access';
import { NodeType } from './node-template';
import { Project } from './project';
import { PublicAccess } from './public-access';
import { Session } from './session';
import { UserAccess } from './user-access';

export enum ParentType {
    Collections = 'collections',
    Projects = 'projects',
    Sessions = 'sessions',
    Activities = 'activities',

    ProjectTemplates = 'projectTemplates',
    Templates = 'templates', // session templates
    ActivityTemplates = 'activityTemplates',

    PublicProjectTemplates = 'publicProjectTemplates',
    PublicSessionTemplates = 'publicSessionTemplates',
    PublicActivityTemplates = 'publicActivityTemplates',
}

export type ParentNode = Project | Session | Activity;

export type PublicParentType =
    | ParentType.PublicProjectTemplates
    | ParentType.PublicSessionTemplates
    | ParentType.PublicActivityTemplates;

export type TemplateAccessType = UserAccess | PublicAccess | FavoriteAccess;

export const publishTemplateType = {
    [ParentType.ProjectTemplates]: ParentType.PublicProjectTemplates,
    [ParentType.Templates]: ParentType.PublicSessionTemplates,
    [ParentType.ActivityTemplates]: ParentType.PublicActivityTemplates,
};

export const privateParentTypes = [ParentType.ActivityTemplates, ParentType.Templates, ParentType.ProjectTemplates];

export const publicParentTypes = [
    ParentType.PublicActivityTemplates,
    ParentType.PublicSessionTemplates,
    ParentType.PublicProjectTemplates,
];

export const projectParentTypes = [ParentType.Projects, ParentType.ProjectTemplates, ParentType.PublicProjectTemplates];

export const noneTemplateTypes = [ParentType.Projects, ParentType.Sessions, ParentType.Activities];

export const templateTypes = [
    ParentType.Templates,
    ParentType.PublicSessionTemplates,
    ParentType.ActivityTemplates,
    ParentType.PublicActivityTemplates,
];

export const publishTemplateDuplicateType = {
    [ParentType.ProjectTemplates]: DuplicateType.ProjectTemplates,
    [ParentType.Templates]: DuplicateType.Templates,
    [ParentType.ActivityTemplates]: DuplicateType.ActivityTemplates,
};

export const editableCopyDuplicateType = {
    [ParentType.PublicProjectTemplates]: DuplicateType.ProjectTemplates,
    [ParentType.PublicSessionTemplates]: DuplicateType.Templates,
    [ParentType.PublicActivityTemplates]: DuplicateType.ActivityTemplates,
};

export const parentTypeToDisplay = {
    [ParentType.ProjectTemplates]: 'Project',
    [ParentType.Projects]: 'Project',
    [NodeType.Project]: 'Project',
    [ParentType.PublicProjectTemplates]: 'Project',
    [ParentType.Templates]: 'Session',
    [ParentType.Sessions]: 'Session',
    [NodeType.Session]: 'Session',
    [ParentType.PublicSessionTemplates]: 'Session',
    [ParentType.ActivityTemplates]: 'Activity',
    [NodeType.Activity]: 'Activity',
    [ParentType.PublicActivityTemplates]: 'Activity',
};

export const routerLinksMap: Dictionary<string> = {
    [ParentType.Projects]: 'project',
    [ParentType.Sessions]: 'session',
    [NodeType.Project]: 'public-project-template',
    [ParentType.ProjectTemplates]: 'project-template',
    [ParentType.PublicProjectTemplates]: 'public-project-template',

    [ParentType.Templates]: 'session-template',
    [ParentType.PublicSessionTemplates]: 'public-session-template',
    [NodeType.Session]: 'public-session-template',

    [ParentType.ActivityTemplates]: 'activity-template',
    [ParentType.PublicActivityTemplates]: 'public-activity-template',
    [NodeType.Activity]: 'public-activity-template',

    [ParentType.Collections]: 'collection',
};
