import { Pipe, PipeTransform } from '@angular/core';

import { UserRole, userRolesMap } from '@accenture/shared/data';

@Pipe({
    name: 'displayUserGlobalRole',
})
export class DisplayUserGlobalRolePipe implements PipeTransform {
    transform(role: UserRole): string {
        return userRolesMap[role] || userRolesMap[UserRole.Empty];
    }
}
