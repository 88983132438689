import { ModuleWithProviders, NgModule } from '@angular/core';

import { ApplicationConfigurationModule } from './state/application-configuration/application-configuration.module';
import { AttributesModule } from './state/attributes/attributes.module';
import { AuthModule } from './state/auth/auth.module';
import { CollectionModule } from './state/collection/collection.module';
import { ProjectModule } from './state/project/project.module';
import { PublicTemplateModule } from './state/public-template/public-template.module';
import { RouterModule } from './state/router/router.module';
import { SessionModule } from './state/session/session.module';

@NgModule({
    imports: [
        AuthModule,
        RouterModule,
        AttributesModule,
        ApplicationConfigurationModule,
        ProjectModule,
        SessionModule,
        PublicTemplateModule,
        CollectionModule,
    ],
})
export class GlobalStoreModule {
    static forRoot(): ModuleWithProviders<GlobalStoreModule> {
        return {
            ngModule: GlobalStoreModule,
        };
    }
}
