import { Timestamp } from 'firebase/firestore';

import { removeEmptyKeys } from '@accenture/shared/util';

import { CollectionRole, SessionRole } from '../access-control';
import { Dictionary } from '../dictionary';
import { NodeTimestamp } from '../node-timestamp';

export class CollectionSession extends NodeTimestamp {
    id?: string;
    userId: string;
    collectionId: string;
    collectionName: string;
    imageUrl?: string;
    imageId?: string;
    sessionId: string;
    sessionRole: SessionRole;
    role: CollectionRole;
    sessionName: string;
    sessionDescription?: string;
    sessionPhase?: Dictionary<string>;
    sessionSubPhase?: Dictionary<string>;
    tags?: Dictionary<string>;
    clients?: Dictionary<string>;
    clientId?: string;

    markedForDelete?: Timestamp;
    deletedByUserId?: string;

    constructor(data: Partial<CollectionSession>) {
        super(data);
        this.id = data.id ?? '';
        this.userId = data.userId ?? '';
        this.imageUrl = data.imageUrl ?? '';
        this.imageId = data.imageId ?? '';
        this.collectionId = data.collectionId ?? '';
        this.collectionName = data.collectionName ?? '';
        this.sessionId = data.sessionId ?? '';
        this.sessionRole = data.sessionRole ?? SessionRole.Participant;
        this.sessionPhase = (data.sessionPhase as Dictionary<string>) ?? {};
        this.sessionSubPhase = (data.sessionSubPhase as Dictionary<string>) ?? {};
        this.role = data.role ?? CollectionRole.Owner; // TODO: update with default role
        this.sessionName = data.sessionName ?? '';
        this.sessionDescription = data.sessionDescription ?? '';
        this.tags = data.tags ?? {};
        this.clients = data.clients ?? {};
        this.clientId = data.clientId ?? '';
    }

    createSerializableObject(): Record<string, unknown> {
        return removeEmptyKeys({
            userId: this.userId,
            collectionId: this.collectionId,
            collectionName: this.collectionName,
            sessionId: this.sessionId,
            sessionRole: this.sessionRole,
            sessionPhase: this.sessionPhase,
            sessionSubPhase: this.sessionSubPhase,
            imageId: this.imageId,
            imageUrl: this.imageUrl,
            role: this.role,
            sessionName: this.sessionName,
            sessionDescription: this.sessionDescription,
            tags: this.tags,
            clients: this.clients,
            created: this.created,
            updated: this.updated,
        });
    }
}
