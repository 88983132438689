import { Injectable } from '@angular/core';

import { DBPathHelper, Log } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable({
    providedIn: 'root',
})
export class LoggerService {
    constructor(private firestoreService: FirestoreService) {}

    async addLog(logData: Partial<Log>): Promise<void> {
        const log = new Log(logData).createSerializableObject();

        await this.firestoreService.addDocument(DBPathHelper.getLogsPath(), {
            ...log,
            created: this.firestoreService.timestamp,
        });
    }
}
