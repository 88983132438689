/* eslint-disable max-len */
import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ChatModule } from '@progress/kendo-angular-conversational-ui';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { TreeViewModule } from '@progress/kendo-angular-treeview';

import { SharedMaterialModule } from '@accenture/shared/material';
import {
    EmailInvitationFormComponent,
    ImportButtonComponent,
    ImportButtonTT9Component,
    SharedSessionModule,
    TemplateTeamMembersPanelComponent,
} from '@accenture/shared/shared-session';
import { SharedUiModule } from '@accenture/shared/ui';

import { ActivitiesListTT9Component } from './activities-list-tt9/activities-list-tt9.component';
import { ActivityEditDataFlowConnectionDialogComponent } from './activity-data-flow-connection-dialog/activity-edit-data-flow-connection-dialog.component';
import { ActivityDataFlowTooltipComponent } from './activity-data-flow-tooltip/activity-data-flow-tooltip.component';
import { ActivityPreviewComponent } from './activity-preview/activity-preview.component';
import { ActivityPreviewTT9Component } from './activity-preview-tt9/activity-preview-tt9.component';
import { AddActivityTemplateToSessionDialogComponent } from './add-activity-template-to-session-dialog/add-activity-template-to-session-dialog.component';
import { AddActivityTemplateToSessionDialogTT9Component } from './add-activity-template-to-session-dialog-tt9/add-activity-template-to-session-dialog-tt9.component';
import { AddProjectDialogComponent } from './add-project-dialog/add-project-dialog.component';
import { AddProjectTableComponent } from './add-project-table/add-project-table.component';
import { AddSessionDialogComponent } from './add-session-dialog/add-session-dialog.component';
import { AddSessionTableComponent } from './add-session-table/add-session-table.component';
import { AddSessionToProjectDialogComponent } from './add-session-to-project-dialog/add-session-to-project-dialog.component';
import { AddSessionTt9DialogComponent } from './add-session-tt9-dialog/add-session-tt9-dialog.component';
import { AiMakeNotesButtonComponent } from './ai-make-notes-button/ai-make-notes-button.component'; // TODO remove once project is removed
import { AiNarrateButtonComponent } from './ai-narrate-button/ai-narrate-button.component';
import { AnalyzeResponsesDialogComponent } from './analyze-responses-dialog/analyze-responses-dialog.component';
import { AttributesClassEditDialogComponent } from './attributes-edit-dialog/attributes-edit-dialog.component';
import { AttributesEditorComponent } from './attributes-editor/attributes-editor.component';
import { BotEditReplyComponent } from './bot-edit-reply/bot-edit-reply.component';
import { CollectionFiltersAndSortComponent } from './collection-filter-and-sort/collection-filters-and-sort.component';
import { CollectionOptionsComponent } from './collection-options/collection-options.component';
import { CollectionOptionsFiltersChipsComponent } from './collection-options-filters-chips/collection-options-filters-chips.component';
import { ContactSupportDialogComponent } from './contact-support-dialog/contact-support-dialog.component';
import { CreateCollectionDialogComponent } from './create-collection-dialog/create-collection-dialog.component';
import { CreateProjectDialogComponent } from './create-project-dialog/create-project-dialog.component';
import { CreateSessionDialogComponent } from './create-session-dialog/create-session-dialog.component';
import { CreateSessionDialogTT9Component } from './create-session-dialog-tt9/create-session-dialog-tt9.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DefaultFiltersAndSortComponent } from './default-filters-and-sort/default-filters-and-sort.component';
import { DefaultOptionsFiltersChipsComponent } from './default-options-filters-chips/default-options-filters-chips.component';
import { DefaultSessionsOptionsFiltersChipsComponent } from './default-session-options-filters-chips/default-session-options-filters-chips.component';
import { DesignerHeaderComponent } from './designer-header/designer-header.component';
import { EditCollectionDialogComponent } from './edit-collection-dialog/edit-collection-dialog.component';
import { EditProjectDialogComponent } from './edit-project-dialog/edit-project-dialog.component';
import { EditSessionDialogComponent } from './edit-session-dialog/edit-session-dialog.component';
import { EditSessionDialogNewComponent } from './edit-session-dialog-new/edit-session-dialog-new.component';
import { FavoriteTemplatesComponent } from './favorite-templates/favorite-templates.component';
import { FavoriteTemplatesFiltersAndSortComponent } from './favorite-templates-filters-and-sort/favorite-templates-filters-and-sort.component';
import { FavoriteTemplatesOptionsFiltersChipsComponent } from './favorite-templates-options-filters-chips/favorite-templates-options-filters-chips.component';
import { FeaturedTemplateCardComponent } from './featured-template-card/featured-template-card.component';
import { MoveToCollectionDialogComponent } from './move-to-collection-dialog/move-to-collection-dialog.component';
import { OptionsFiltersChipsComponent } from './options-filters-chips/options-filters-chips.component';
import { ParentOptionsComponent } from './parent-options/parent-options.component';
import { ProjectOptionsComponent } from './project-options/project-options.component';
import { ProjectsListComponent } from './projects-list/projects-list.component';
import { PromptLibraryDialogComponent } from './prompt-library-dialog/prompt-library-dialog.component';
import { PublishTemplateDialogComponent } from './publish-template-dialog/publish-template-dialog.component';
import { RemoveFromCollectionDialogueComponent } from './remove-from-collection-dialogue/remove-from-collection-dialogue.component';
import { InvitationFormBulkInviteComponent } from './roster-panels-and-invite/members-invitations-dialog/components/invitation-form-bulk-invite/invitation-form-bulk-invite.component';
import { MembersInvitationsDialogComponent } from './roster-panels-and-invite/members-invitations-dialog/members-invitations-dialog.component';
import { NoEmailsDialogComponent } from './roster-panels-and-invite/no-emails-dialog/no-emails-dialog.component';
import { SessionDeleteSnackBarComponent } from './roster-panels-and-invite/session-delete-snackbar/session-delete-snack-bar.component';
import { SelectSessionSourceTypeDialogComponent } from './select-session-source-type-dialog/select-session-source-type-dialog.component';
import { SessionFormComponent } from './session-form/session-form.component';
import { SessionOptionsComponent } from './session-options/session-options.component';
import { SessionsFiltersAndSortComponent } from './sessions-filters-and-sort/sessions-filters-and-sort.component';
import { SessionsFiltersAndSortNewComponent } from './sessions-filters-and-sort-new/sessions-filters-and-sort-new.component';
import { SessionsFiltersSortComponent } from './sessions-filters-sort/sessions-filters-sort.component';
import { SessionsListComponent } from './sessions-list/sessions-list.component';
import { TemplateCardComponent } from './template-card/template-card.component';
import { TemplateCardTT9Component } from './template-card-tt9/template-card-tt9.component';
import { TemplateEditorWelcomeSnackbarComponent } from './template-editor-welcome-snackbar/template-editor-welcome-snackbar.component';
import { TemplatePreviewComponent } from './template-preview/template-preview.component';
import { TemplateStoreProjectCreationSnackbarComponent } from './template-store-project-creation-snackbar/template-store-project-creation-snackbar.component';
import { TemplateStoreQuickFiltersComponent } from './template-store-quick-filters/template-store-quick-filters.component';
import { TemplatesComponent } from './templates/templates.component';
import { TemplatesListComponent } from './templates-list/templates-list.component';
import { TemplatesStoreComponent } from './templates-store/templates-store.component';
import { TemplatesStoreFiltersAndSortComponent } from './templates-store-filters-and-sort/templates-store-filters-and-sort.component';
import { TemplatesStoreOptionsFiltersChipsComponent } from './templates-store-options-filters-chips/templates-store-options-filters-chips.component';
import { UseTemplateDialogComponent } from './use-template-dialog/use-template-dialog.component';
import { UseTemplateDialogTT9Component } from './use-template-dialog-tt9/use-template-dialog-tt9.component';

@NgModule({
    imports: [
        CommonModule,
        SharedUiModule,
        SharedMaterialModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        TreeViewModule,
        LayoutModule,
        ChatModule,
        SharedSessionModule,
        ImportButtonComponent,
        ImportButtonTT9Component,
        DialogsModule,
        ButtonsModule,
        TemplateTeamMembersPanelComponent,
        EmailInvitationFormComponent,
        InputsModule,
        PickerModule,
    ],
    declarations: [
        TemplateCardTT9Component,
        FeaturedTemplateCardComponent,
        DesignerHeaderComponent,
        TemplatesComponent,
        ProjectsListComponent,
        ParentOptionsComponent,
        DefaultFiltersAndSortComponent,
        DashboardComponent,
        OptionsFiltersChipsComponent,
        MembersInvitationsDialogComponent,
        ActivityEditDataFlowConnectionDialogComponent,
        AddSessionDialogComponent,
        AddSessionTableComponent,
        AttributesEditorComponent,
        ProjectOptionsComponent,
        AttributesClassEditDialogComponent,
        AddProjectDialogComponent,
        AddProjectTableComponent,
        TemplatesListComponent,
        TemplatesStoreComponent,
        DefaultOptionsFiltersChipsComponent,
        AddSessionToProjectDialogComponent,
        EditProjectDialogComponent,
        UseTemplateDialogComponent,
        PublishTemplateDialogComponent,
        AddActivityTemplateToSessionDialogComponent,
        AddActivityTemplateToSessionDialogTT9Component,
        CreateProjectDialogComponent,
        CreateSessionDialogComponent,
        CreateSessionDialogTT9Component,
        EditSessionDialogComponent,
        AnalyzeResponsesDialogComponent,
        ContactSupportDialogComponent,
        SessionsFiltersAndSortComponent,
        SessionOptionsComponent,
        DefaultSessionsOptionsFiltersChipsComponent,
        TemplatesStoreFiltersAndSortComponent,
        TemplatesStoreOptionsFiltersChipsComponent,
        TemplatePreviewComponent,
        TemplateStoreQuickFiltersComponent,
        FavoriteTemplatesComponent,
        TemplateEditorWelcomeSnackbarComponent,
        TemplateCardComponent,
        FavoriteTemplatesFiltersAndSortComponent,
        FavoriteTemplatesOptionsFiltersChipsComponent,

        AiMakeNotesButtonComponent, // TODO remove once project is removed
        ActivityDataFlowTooltipComponent,
        ActivityPreviewComponent,
        SessionDeleteSnackBarComponent,
        NoEmailsDialogComponent,
        PromptLibraryDialogComponent,
        InvitationFormBulkInviteComponent,
        PublishTemplateDialogComponent,
        TemplateStoreProjectCreationSnackbarComponent,
        BotEditReplyComponent,
        SessionsListComponent,
        SelectSessionSourceTypeDialogComponent,
        EditSessionDialogNewComponent,
        SessionsFiltersAndSortNewComponent,
        CreateCollectionDialogComponent,
        CollectionOptionsComponent,
        ActivitiesListTT9Component,
        MoveToCollectionDialogComponent,
        EditCollectionDialogComponent,
        ActivityPreviewTT9Component,
        CollectionFiltersAndSortComponent,
        CollectionOptionsFiltersChipsComponent,
        AddSessionTt9DialogComponent,
        SessionsFiltersSortComponent,
        UseTemplateDialogTT9Component,
        SessionFormComponent,
        RemoveFromCollectionDialogueComponent,
        AiNarrateButtonComponent,
    ],
    exports: [
        CreateSessionDialogTT9Component,
        TemplateCardTT9Component,
        FeaturedTemplateCardComponent,
        DesignerHeaderComponent,
        TemplatesComponent,
        ProjectsListComponent,
        ParentOptionsComponent,
        DashboardComponent,
        OptionsFiltersChipsComponent,
        MembersInvitationsDialogComponent,
        ActivityEditDataFlowConnectionDialogComponent,
        AddSessionDialogComponent,
        EditSessionDialogComponent,
        AddSessionTableComponent,
        AttributesEditorComponent,
        ProjectOptionsComponent,
        UseTemplateDialogComponent,
        PublishTemplateDialogComponent,
        CreateProjectDialogComponent,
        CreateSessionDialogComponent,
        AnalyzeResponsesDialogComponent,
        ContactSupportDialogComponent,
        SessionsFiltersAndSortComponent,
        TemplatesStoreFiltersAndSortComponent,
        TemplatesStoreOptionsFiltersChipsComponent,
        TemplatePreviewComponent,
        TemplateCardComponent,
        FavoriteTemplatesFiltersAndSortComponent,
        FavoriteTemplatesOptionsFiltersChipsComponent,
        AiMakeNotesButtonComponent, // TODO remove once project is removed
        ActivityPreviewComponent,
        ActivityDataFlowTooltipComponent,
        SessionDeleteSnackBarComponent,
        NoEmailsDialogComponent,
        PromptLibraryDialogComponent,
        SessionsListComponent,
        SelectSessionSourceTypeDialogComponent,
        EditSessionDialogNewComponent,
        SessionsFiltersAndSortNewComponent,
        CollectionOptionsComponent,
        ActivitiesListTT9Component,
        MoveToCollectionDialogComponent,
        EditCollectionDialogComponent,
        ActivityPreviewTT9Component,
        CollectionFiltersAndSortComponent,
        AddSessionTt9DialogComponent,
        SessionsFiltersSortComponent,
        UseTemplateDialogTT9Component,
        SessionFormComponent,
        DefaultFiltersAndSortComponent,
        AiNarrateButtonComponent,
    ],
})
export class UiModule {}
