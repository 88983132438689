import { Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Inject({})
export class UIBlockerService {
    private _loadingSubject = new BehaviorSubject<boolean>(false);

    readonly showLoader = this._loadingSubject.asObservable().pipe(distinctUntilChanged());

    toggleLoader(show: boolean): void {
        this._loadingSubject.next(show);
    }
}
