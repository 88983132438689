<ng-container *ngIf="vm$ | async as vm">
  <div class="templates-store-quick-filters d-flex-between">
    <div *accentureLoaded="!vm.isLoading" class="templates-store-quick-filters-container d-flex">
      <!-- sorting chips -->
      <div class="quick-sort">
        <mat-chip-listbox hideSingleSelectionIndicator class="tt9-chip">
          <mat-chip-option
            *ngFor="let sortOption of vm.sortOptionsValues | keyvalue; trackBy: trackByKey"
            [class.selected]="isSortOptionSelected(vm.filtersAndSort, sortOption.key)"
            (click)="onSortOptionSelected(vm.filtersAndSort, sortOption.key)"
          >
            <span
              matTooltipPosition="above"
              matTooltipClass="tt9-arrow-tooltip-below-right"
              [matTooltip]="sortOption.value"
            >
              {{ sortOption.value }}
            </span>
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
      <!-- sorting chips -->

      <div class="divider"></div>

      <!-- filters chips -->
      <div class="filters">
        <mat-chip-listbox hideSingleSelectionIndicator class="tt9-chip">
          <mat-chip-option
            *ngFor="let templateType of templateTypesList; trackBy: trackById"
            [class.selected]="isTemplateTypeSelected(vm.filtersAndSort, templateType)"
            (click)="onTemplateTypeSelected(vm.filtersAndSort, templateType)"
          >
            <span
              matTooltipPosition="above"
              matTooltipClass="tt9-arrow-tooltip-below-right"
              [matTooltip]="displayTemplateType[templateType]"
            >
              {{ displayTemplateType[templateType] }}
            </span>
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
      <!-- filters chips -->

      <div *ngIf="isTagsVisible(vm.parentTypeTab)" class="project-tag-filter" class="divider"></div>

      <!-- filter for projects/session chips -->
      <div *ngIf="isTagsVisible(vm.parentTypeTab)" class="project-tag-filter">
        <div *ngIf="vm.filteredTags?.length">
          <mat-chip-listbox hideSingleSelectionIndicator class="tt9-chip">
            <mat-chip-option
              *ngFor="let option of vm.filteredTags; trackBy: trackById"
              [class.selected]="isOptionSelected(vm.filtersAndSort.tags || [], option.id)"
              (click)="onSelectOption(vm.filtersAndSort.tags || [], option)"
            >
              <span
                matTooltipPosition="above"
                matTooltipClass="tt9-arrow-tooltip-below-right"
                [matTooltip]="option.name"
              >
                {{ option.name }}
              </span>
            </mat-chip-option>
          </mat-chip-listbox>
        </div>
      </div>
      <!-- filter for projects chips -->
    </div>

    <!-- reset form controls values link -->
    <div class="templates-store-quick-filters-control-buttons d-flex tt9-chip">
      <mat-chip
        *ngIf="vm.filteredTags?.length && isTagsVisible(vm.parentTypeTab)"
        class="more-chip"
        (click)="openAllTagsPanel($event)"
      >
        <span class="d-flex">
          More
          <accenture-icon class="more-icon ml-8">icon-more</accenture-icon>
        </span>
      </mat-chip>
      <button
        class="tt9-btn-ghost btn-icon reset-all-button"
        [disabled]="!vm.isFiltersApplied"
        (click)="resetFiltersAndSortForm()"
      >
        Reset All
        <accenture-icon>icon-close</accenture-icon>
      </button>
    </div>
    <!-- / reset form controls values link -->
  </div>

  <!-- Filter and Sort side panel -->
  <accenture-side-panel
    *ngIf="isFilterAndSortPanelOpened"
    cdkOverlayOrigin
    #origin="cdkOverlayOrigin"
    [panelTrigger]="origin"
    [panelTitle]="'Filter & Sort'"
    [panelOpened]="isFilterAndSortPanelOpened"
    (closePanel)="closeFilterAndSortPanel()"
  >
    <accenture-templates-store-filters-and-sort></accenture-templates-store-filters-and-sort>
  </accenture-side-panel>
  <!-- / Filter and Sort side panel -->

  <!-- Tags side panel -->
  <accenture-side-panel
    *ngIf="vm.isAllTagsPanelOpened"
    cdkOverlayOrigin
    #origin="cdkOverlayOrigin"
    [panelTrigger]="origin"
    [panelTitle]="allTagsPanelTitle"
    [panelOpened]="vm.isAllTagsPanelOpened"
    [showBackArrow]="true"
    (closePanel)="closeAllTagsPanel()"
    (navigateBack)="navigateToFilterPanel()"
  >
    <accenture-templates-store-options-filters-chips
      (click)="$event.stopPropagation()"
    ></accenture-templates-store-options-filters-chips>
  </accenture-side-panel>
  <!-- / Tags side panel -->
</ng-container>
