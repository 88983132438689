import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, distinctUntilChanged, Observable } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';

import { SessionRecordingsService } from '@accenture/activity/shared/domain';
import { AppState, selectSessionId } from '@accenture/global-store';
import { SessionRecordingConsent } from '@accenture/shared/data';

import { SessionRecorderStore } from '../session-recorder-store';

export interface SessionRecorderCreateDisclaimerViewModel {
    isLoading: boolean;
    consents: SessionRecordingConsent[];
}

const initialState: SessionRecorderCreateDisclaimerViewModel = {
    isLoading: true,
    consents: [],
};

@Injectable()
export class SessionRecorderCreateDisclaimerFacade {
    private isLoading$ = new BehaviorSubject<boolean>(false);

    vm$ = this.buildViewModel();

    constructor(
        private sessionRecordingsService: SessionRecordingsService,
        private store: Store<AppState>,
        private sessionRecorderStore: SessionRecorderStore,
    ) {}

    sessionId: string;
    disclaimerId: string;
    userId: string;

    private buildViewModel(): Observable<SessionRecorderCreateDisclaimerViewModel> {
        const data$ = combineLatest([this.store.select(selectSessionId), this.sessionRecorderStore.disclaimerId$]).pipe(
            tap(([sessionId, disclaimerId]) => {
                this.sessionId = sessionId;
                this.disclaimerId = disclaimerId;
            }),
            switchMap(() =>
                combineLatest([this.getConsents()]).pipe(
                    tap(() => {
                        this.isLoading$.next(false);
                    }),
                ),
            ),
        );

        return combineLatest([data$, this.isLoading$.asObservable().pipe(distinctUntilChanged())]).pipe(
            map(([[consents], isLoading]) => {
                return {
                    consents,
                    isLoading,
                };
            }),
            startWith(initialState),
        );
    }

    private getConsents(): Observable<SessionRecordingConsent[]> {
        return this.sessionRecordingsService.getConsentsByDisclaimer(this.sessionId, this.disclaimerId);
    }
}
