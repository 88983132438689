import { createAction, props } from '@ngrx/store';

import {
    Activity,
    Attribute,
    AttributeClass,
    CollectionsDisplayMode,
    ParentType,
    Session,
    TeamMember
} from '@accenture/shared/data';

export const getSessionData = createAction(
    '[Sessions] Get Session Data',
    props<{ parentType: ParentType; sessionId: string }>(),
);

export const getSessionDataSuccess = createAction(
    '[Sessions] Get Session Data Success',
    props<{ session: Session; attributeClasses: AttributeClass[]; attributes: Attribute[] }>(),
);

export const getSessionTeamMemberData = createAction(
    '[Sessions] Get Session Team Member',
    props<{ parentType: ParentType; sessionId: string }>(),
);

export const getSessionTeamMemberDataSuccess = createAction(
    '[Sessions] Get Session Team Member Success',
    props<{ teamMember: TeamMember }>(),
);

export const getSessionActivitiesData = createAction(
    '[Sessions] Get Session Activities',
    props<{ parentType: ParentType; sessionId: string }>(),
);

export const getSessionActivitiesDataSuccess = createAction(
    '[Sessions] Get Session Activities Success',
    props<{ activities: Activity[] }>(),
);

export const setTeamMemberOfflineStateSession = createAction(
    '[Session] Set Team Member Offline',
    props<{ sessionId: string }>(),
);

export const clearSessionSubscriptions = createAction('[Sessions] Clear Sessions Store');

export const getSessionDataError = createAction('[Sessions] Get Session Data Error');

export const getTeamMemberError = createAction('[Sessions] Get Session Team Member Error');

export const teamMemberOnlineStateSessionChangedSuccess = createAction(
    '[Session] Team Member Online State Changed Success',
);

export const teamMemberOnlineStateSessionChangedError = createAction(
    '[Session] Team Member Online State Changed Error',
);

export const setSessionsDisplayMode = createAction(
    '[Sessions] Set Sessions Display Mode',
    props<{ displayMode: CollectionsDisplayMode }>(),
);
