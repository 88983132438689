import { Inject, Injectable } from '@angular/core';
import { Timestamp } from '@firebase/firestore';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';

import {
    LinkAccessService,
    MeetingService,
    SessionService,
    TeamMemberService,
} from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    selectAuthenticatedUser,
    selectFeatureToggle,
    selectParentTeamMember,
    selectProjectId,
    selectSessionId,
    selectSessionTeamMember,
} from '@accenture/global-store';
import {
    FeatureToggleName,
    FredEnvironment,
    LinkAccess,
    MsGraphOnlineMeeting,
    ParentType,
    RouteContexts,
    Session,
    TeamMember,
    TeamsAppRoutePaths,
} from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import { MsGraphService, MsIdentityService } from '@accenture/shared/microsoft';
import { DialogService, SnackbarService } from '@accenture/shared/ui';

import { SessionBookMeetingDialogComponent } from '../dialogs';
import { bookSessionAsMeetingEmail, bookSessionAsMeetingModal, bookSessionAsMeetingNotification } from './constants';

export interface BookMeetingButtonModel {
    session: Session;
    members: TeamMember[];
    linkAccess: LinkAccess;
    available: boolean;
    isLoading: boolean;
}

export const initialState: BookMeetingButtonModel = {
    session: {} as Session,
    members: [],
    linkAccess: {} as LinkAccess,
    available: true,
    isLoading: false,
};

@Injectable()
export class BookMeetingButtonFacade {
    private isLoading$ = new BehaviorSubject<boolean>(false);
    vm$ = this.buildViewModel();

    userId?: string;
    projectId?: string;
    sessionId?: string;
    linkAccessId: string;

    constructor(
        @Inject('environment') private environment: FredEnvironment,
        private store: Store<AppState>,
        private msGraphService: MsGraphService,
        private msIdentityService: MsIdentityService,
        private firestoreService: FirestoreService,
        private sessionService: SessionService,
        private teamMemberService: TeamMemberService,
        private linkAccessService: LinkAccessService,
        private meetingService: MeetingService,
        private dialogService: DialogService,
        private snackbarService: SnackbarService,
    ) {}

    openBookMeetingDialog(session: Session, members: TeamMember[]): void {
        this.dialogService.open(SessionBookMeetingDialogComponent, {
            title: bookSessionAsMeetingModal.title,
            width: '768px',
            cancelButtonText: 'Cancel',
            panelClass: 'tt9-modal',
            onAccept: (proposedDateTime, duration) => this.bookMeeting(proposedDateTime, duration, session, members),
        });
    }

    async refreshMeetingDetails(session: Session): Promise<void> {
        const { id, projectId, meeting } = session;
        if (!meeting) return;

        const { meetingId, eventId } = meeting;

        this.isLoading$.next(true);
        try {
            let payload;
            let message: string;
            const token = await this.msIdentityService.refreshAccessToken();

            const isCancelled = await this.msGraphService.isMeetingCancelled(token, eventId);

            if (!isCancelled) {
                const onlineMeeting = await this.msGraphService.getMeetingDetails(token, meetingId);

                const { startDateTime, joinWebUrl } = onlineMeeting;

                const updatedStartDateTime = Timestamp.fromDate(new Date(startDateTime));

                if (!updatedStartDateTime.isEqual(meeting.startDateTime)) {
                    payload = {
                        meeting: {
                            joinWebUrl,
                            meetingId,
                            eventId,
                            startDateTime: updatedStartDateTime,
                        },
                    };
                    message = `${bookSessionAsMeetingNotification.updated} ${moment(
                        updatedStartDateTime.toDate(),
                    ).format('LLLL')}.`;
                }
            } else {
                payload = { meeting: this.firestoreService.deleteField };
                message = bookSessionAsMeetingNotification.cancelled;
            }

            if (!!payload) {
                await this.sessionService.updateSessionDocumentWithMerge(ParentType.Projects, projectId, id, payload);
                this.snackbarService.showInfoSnackBar(bookSessionAsMeetingNotification.title, message);
            }
        } catch (error) {
            console.error(error);
        } finally {
            this.isLoading$.next(false);
        }
    }

    async composeEmailContent(sessionName: string): Promise<string> {
        const { projectId, sessionId, linkAccessId } = this;

        let link: string;
        let password: string;

        if (linkAccessId) {
            link = `${window.location.host}/authentication/join-session/${linkAccessId}`;
            password = await firstValueFrom(
                this.linkAccessService.getLinkAccessOriginalPassword(this.projectId, linkAccessId),
            );
        } else {
            const linkAccessId = await this.linkAccessService.addLinkAccess({
                projectId,
                sessionId,
                sessionName,
            });

            link = `${window.location.host}/authentication/join-session/${linkAccessId}`;
            password = Math.random().toString(36).slice(2, 10);
            await this.linkAccessService.updateLinkAccessPassword(linkAccessId, password);
            await this.linkAccessService.updateLinkAccessOriginalPassword(this.projectId, this.linkAccessId, password);
        }

        const content = `
            ${bookSessionAsMeetingEmail.contentPrefix}
            ${link}
            Password: ${password}
        `;

        return content;
    }

    async attachTeamsApp(
        token: string,
        onlineMeeting: MsGraphOnlineMeeting,
        participants: TeamMember[],
    ): Promise<void> {
        const { userId, projectId, sessionId } = this;

        const { host, appStoreAppId, tabEntityId } = this.environment.teams;
        const { threadId } = onlineMeeting.chatInfo;

        const meetingId = btoa(threadId);

        const data = {
            projectId,
            sessionId,
            ...(host && { host }),
            apiResults: {
                onlineMeeting,
            },
            context: {
                chat: {
                    id: threadId,
                },
                meeting: {
                    id: meetingId,
                },
            },
            id: meetingId,
            ownerId: userId,
            participantIds: participants.map(participant => participant.id),
            path: TeamsAppRoutePaths.Meeting,
            type: RouteContexts.Meeting,
        };

        await this.meetingService.addMeeting(meetingId, data);

        await this.msGraphService.attachTeamsAppToMeeting(token, threadId, meetingId, appStoreAppId, tabEntityId, host);
    }

    async attachTT9(token: string, chatId: string): Promise<void> {
        const sessionUrl = window.location.toString();
        await this.msGraphService.attachTt9ToMeeting(token, chatId, sessionUrl, sessionUrl);
    }

    async bookMeeting(
        proposedDateTime: Date,
        duration: number,
        session: Session,
        members: TeamMember[],
    ): Promise<void> {
        try {
            this.isLoading$.next(true);
            const { projectId, sessionId } = this;

            const { name } = session;
            const subject = `${bookSessionAsMeetingEmail.subjectPrefix} ${name}`;
            const content = await this.composeEmailContent(name);
            const recipients = members.map(member => member.email);

            const token = await this.msIdentityService.refreshAccessToken();

            const { id, onlineMeeting } = await this.msGraphService.createMeeting(
                token,
                subject,
                content,
                proposedDateTime.toISOString(),
                recipients,
                duration,
            );

            const { startDateTime, chatInfo, joinWebUrl } = onlineMeeting;
            const { threadId } = chatInfo;

            const meetingId = btoa(threadId);

            const meetingDateTime = Timestamp.fromDate(new Date(startDateTime));

            await this.sessionService.updateSessionDocumentWithMerge(ParentType.Projects, projectId, sessionId, {
                meeting: {
                    meetingId,
                    joinWebUrl,
                    eventId: id,
                    startDateTime: meetingDateTime,
                },
            });

            const useTeamsApp = !!this.environment.teams?.appStoreAppId;

            if (useTeamsApp) {
                await this.attachTeamsApp(token, onlineMeeting, members);
            } else {
                await this.attachTT9(token, threadId);
            }

            this.snackbarService.showSuccessSnackBar(
                bookSessionAsMeetingNotification.title,
                `${bookSessionAsMeetingNotification.booked} ${moment(meetingDateTime.toDate()).format('LLLL')}.`,
            );
        } catch (error) {
            this.snackbarService.showErrorSnackBar(
                bookSessionAsMeetingNotification.title,
                bookSessionAsMeetingNotification.error,
            );
            console.error(error);
        } finally {
            this.isLoading$.next(false);
        }
    }

    private buildViewModel(): Observable<BookMeetingButtonModel> {
        const session$ = combineLatest([
            this.store.select(selectProjectId),
            this.store.select(selectSessionId),
            this.store.select(selectAuthenticatedUser),
        ]).pipe(
            tap(([projectId, sessionId, user]) => {
                this.projectId = projectId;
                this.sessionId = sessionId;
                this.userId = user.id;
            }),
            switchMap(() =>
                combineLatest([
                    this.sessionService
                        .getSessionsByIds(ParentType.Projects, this.projectId, [this.sessionId])
                        .pipe(map(sessions => sessions[0])),
                    this.teamMemberService.getSessionTeamMembers(this.projectId, this.sessionId),
                    this.linkAccessService.getLinkAccessBySession(this.sessionId),
                ]).pipe(
                    tap(([, , linkAccess]) => {
                        this.linkAccessId = linkAccess?.id;
                        this.isLoading$.next(false);
                    }),
                ),
            ),
        );

        return combineLatest([
            session$,
            this.store.select(selectFeatureToggle(FeatureToggleName.BookSessionMeeting)),
            this.store.select(selectParentTeamMember),
            this.store.select(selectSessionTeamMember),

            this.isLoading$.asObservable().pipe(distinctUntilChanged()),
        ]).pipe(
            map(([[session, members, linkAccess], enabled, projectAccess, sessionAccess, isLoading]) => {
                const { isProjectAdmin } = projectAccess;
                const { isSessionLeader } = sessionAccess;
                const { meeting } = session;

                const sso = this.msIdentityService.isSsoLoggedIn();

                return {
                    session,
                    members,
                    linkAccess,
                    isLoading,
                    available: enabled && (!!meeting || ((isSessionLeader || isProjectAdmin) && sso)),
                };
            }),
            startWith(initialState),
        );
    }
}
