import { ActivityType } from '../activities';
import { ActivityItemType } from '../activity-items';
import { ExportType } from './export-type';

export class InvalidExportTypeError extends Error {
    constructor(public exportType: ExportType) {
        super('Invalid export type.');
    }
}

export class InvalidActivityTypeError extends Error {
    constructor(public activityType: ActivityType) {
        super('Invalid activity type.');
    }
}

export class InvalidActivityItemTypeError extends Error {
    constructor(public activityItemType: ActivityItemType) {
        super('Invalid activity item type.');
    }
}
