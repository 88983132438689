import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { determineHintClass, SessionRecordingMediaType, validationMessages } from '@accenture/shared/data';
import { SharedMaterialModule } from '@accenture/shared/material';
import { DialogService, SharedUiModule } from '@accenture/shared/ui';

import { SessionRecorderListViewTranscriptionFacade } from './session-recorder-list-view-transcription-facade';

interface TranscriptionModel {
    recordingId: string;
    transcription: string;
    mediaType: SessionRecordingMediaType;
}

@Component({
    selector: 'accenture-session-recorder-list-view-transcription',
    standalone: true,
    templateUrl: './session-recorder-list-view-transcription.component.html',
    styleUrls: ['./session-recorder-list-view-transcription.component.scss'],
    providers: [SessionRecorderListViewTranscriptionFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, SharedMaterialModule, SharedUiModule],
})
export class SessionRecorderListViewTranscriptionComponent {
    vm$ = this.facade.vm$;
    loaderText!: string;
    mediaTypes = SessionRecordingMediaType;

    constructor(
        @Inject(MAT_DIALOG_DATA) public transcriptionData: TranscriptionModel,
        private facade: SessionRecorderListViewTranscriptionFacade,
        private dialogService: DialogService,
    ) {}

    title = 'Session Recording Transcription';
    validationMessages = validationMessages;
    determineHintClass = determineHintClass;
    placeholderPromptText = 'Transcription goes here...';
    transcription = this.transcriptionData.transcription;
    recordingId = this.transcriptionData.recordingId;
    mediaType = this.transcriptionData.mediaType;

    async saveChanges(transcription: string): Promise<void> {
        this.loaderText = 'Saving...';
        await this.facade.save(this.title, this.recordingId, transcription);
        this.transcription = transcription;
    }

    async downloadFile(transcription: string): Promise<void> {
        this.loaderText = 'Saving...';
        await this.facade.save(this.title, this.recordingId, transcription);
        this.loaderText = 'Preparing media...';
        await this.facade.download(this.title, this.recordingId);
        this.dialogService.close();
    }

    async reRunTranscription(): Promise<void> {
        this.loaderText = 'Re-running transcription...';
        this.transcription = await this.facade.transcribe(this.title, this.recordingId);
    }

    closeDialog() {
        this.dialogService.close();
    }
}
