import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable, tap } from 'rxjs';

import {
    emptyScreenImageUrlTemplates,
    emptyScreenTitles,
    inputPlaceholders,
    NavigationTab,
    noTemplatesYetMap,
    TemplateTab,
    tooltipTexts,
    UserAccess,
} from '@accenture/shared/data';
import { trackById, trackByValue } from '@accenture/shared/util';

import { TemplatesListFacade, TemplatesListViewModel } from './templates-list-facade';

@Component({
    selector: 'accenture-templates-list',
    templateUrl: './templates-list.component.html',
    styleUrls: ['./templates-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [TemplatesListFacade],
})
export class TemplatesListComponent {
    vm$: Observable<TemplatesListViewModel> = this.getInitialViewModel();

    trackById = trackById;
    menuOpened = false;
    trackByValue = trackByValue;
    emptyScreenImageUrlTemplates = emptyScreenImageUrlTemplates;
    inputPlaceholders = inputPlaceholders;
    tooltipTexts = tooltipTexts;
    templateTabs = [TemplateTab.All, TemplateTab.Projects, TemplateTab.Sessions, TemplateTab.Activities];
    templateTab = TemplateTab;
    searchControl = new FormControl<string>('', { nonNullable: true });
    hasVisibleTemplates = false;
    hoveredCardIndex$ = new BehaviorSubject<number | undefined>(undefined);

    readonly hoveredCardIndex = this.hoveredCardIndex$.asObservable();

    constructor(private facade: TemplatesListFacade) {}

    showActions(templatesLength: boolean, isSearchValue: boolean): boolean {
        return templatesLength || (!templatesLength && isSearchValue);
    }

    async onUpdateFavorite(template: UserAccess): Promise<void> {
        await this.facade.onUpdateFavorite(template);
    }

    async onDeleteTemplate(template: UserAccess): Promise<void> {
        await this.facade.deleteTemplateConfirm(template);
    }

    onOpenPublishDialog(template: UserAccess): void {
        this.facade.onOpenPublishDialog(template);
    }

    hoverTemplateCard(index: number): void {
        this.hoveredCardIndex$.next(index);
    }

    unhoverTemplateCard(index: number): void {
        if (this.hoveredCardIndex$.getValue() === index && !this.menuOpened) {
            this.resetTemplateCardHover();
        }
    }

    getEmptyScreenTitle(vm: TemplatesListViewModel): string {
        switch (true) {
            case !vm.allTemplates?.length:
                return noTemplatesYetMap[TemplateTab.All];
            case !vm.templatesOfCurrentTab?.length:
                return noTemplatesYetMap[vm.activeTemplateTab];
            case !!vm.searchValue === true && vm.isFiltersApplied === true:
                return emptyScreenTitles.noTemplatesMatchAndFound;
            case !!vm.searchValue === false && vm.isFiltersApplied === true:
                return emptyScreenTitles.noTemplatesMatch;
            case !!vm.searchValue === true && vm.isFiltersApplied === false:
                return emptyScreenTitles.noTemplatesFound;
            case !!vm.searchValue === false && vm.isFiltersApplied === false:
                return noTemplatesYetMap[vm.activeTemplateTab];
        }
    }

    setTemplateTab(tab: TemplateTab): void {
        this.facade.setTemplateTab(tab);
        this.resetTemplateCardHover();
    }

    setSearchValue(value: string): void {
        this.facade.setSearchValue(value);
    }

    navigateToTemplatesPage(): void {
        this.facade.navigateHome(NavigationTab.Templates);
    }

    toggleFilterAndSortPanel(opened: boolean, event?: Event): void {
        this.facade.toggleFilterAndSortPanel(opened);
        if (event) {
            event.stopPropagation();
        }
    }

    clearSearchValue(event: Event): void {
        event.stopPropagation();
        this.setSearchValue('');
    }

    useTemplate(template: UserAccess): void {
        this.facade.useTemplate(template.templateType, template.templateId);
    }

    private resetTemplateCardHover(): void {
        this.hoveredCardIndex$.next(undefined);
    }

    private updateEmptyTemplateListStatus(templates: UserAccess[]): void {
        this.hasVisibleTemplates = !!templates.length;
    }

    private updateSearchValue(searchValue: string, templatesOfCurrentTab: UserAccess[]): void {
        this.searchControl.patchValue(searchValue);
        this.searchControl[templatesOfCurrentTab.length ? 'enable' : 'disable']();
    }

    private getInitialViewModel(): Observable<TemplatesListViewModel> {
        return this.facade.vm$.pipe(
            tap(viewModel => {
                this.updateSearchValue(viewModel.searchValue, viewModel.templatesOfCurrentTab);
                this.updateEmptyTemplateListStatus(viewModel.templates);
            }),
        );
    }
}
