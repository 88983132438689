import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DBPathHelper, OwnershipHistory, ParentType } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable({
    providedIn: 'root',
})
export class OwnershipService {
    constructor(private firestoreService: FirestoreService) {}

    getOwnershipHistoryDisplay(parentType: ParentType, parentId: string): Observable<OwnershipHistory[]> {
        return this.getOwnershipHistory(parentType, parentId).pipe(
            map((ownershipHistory) =>
                ownershipHistory.sort((a, b) => b.fromDate?.toDate().getTime() - a.fromDate?.toDate().getTime()),
            ),
        );
    }

    getOwnershipHistory(parentType: ParentType, parentId: string): Observable<OwnershipHistory[]> {
        return this.firestoreService.getCollection<OwnershipHistory>(
            DBPathHelper.getOwnershipHistoryPath(parentType, parentId),
        );
    }

    getIsOwnershipHistoryExist(parentType: ParentType, parentId: string): Observable<boolean> {
        return this.firestoreService.getDocumentsExist(
            DBPathHelper.getOwnershipHistoryPath(parentType, parentId),
            new Map([]),
        );
    }

    getLastOwnershipHistory(parentType: ParentType, parentId: string): Observable<OwnershipHistory | undefined> {
        return this.getOwnershipHistory(parentType, parentId).pipe(
            map((ownershipHistory) => {
                if (!ownershipHistory.length) {
                    return undefined;
                }

                const lastOwnershipHistory = ownershipHistory.sort(
                    (a, b) => b.fromDate.seconds - a.fromDate.seconds,
                )[0];

                return new OwnershipHistory(lastOwnershipHistory);
            }),
        );
    }
}
