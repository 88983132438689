import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { AIGenerateService } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUser, selectRouterParams, selectUserCollection } from '@accenture/global-store';
import {
    aiBuilderCloseText,
    aiBuilderReturnText,
    aiGenerationErrorTitle,
    aiGenerationSessionSaveErrorTitle,
    BotActions,
    BuilderStep,
    errorSnackbarText,
    ParentType,
    sessionGenerationDoneSnackbarTitle,
    UserCollection,
} from '@accenture/shared/data';
import { ConfirmationDialogComponent, DialogService, SessionBluePrint, SnackbarService } from '@accenture/shared/ui';

import { AiGenerateModalTt9Component } from './ai-generate-modal-tt9.component';

export interface AiGenerateModel {
    currentStep: BuilderStep;
    isLoading: boolean;
    previewData: SessionBluePrint | null;
}

const defaultViewModel: AiGenerateModel = {
    currentStep: BuilderStep.Entry,
    isLoading: false,
    previewData: null,
};

@Injectable()
export class AiGenerateModalTt9Facade {
    private isLoading$ = new BehaviorSubject<boolean>(false);
    private currentStep$ = new BehaviorSubject<BuilderStep>(BuilderStep.Entry);
    private previewData$ = new BehaviorSubject<SessionBluePrint | null>(null);

    userId!: string;

    vm$ = this.buildViewModel();

    userCollection: UserCollection;

    constructor(
        private aiGenerateService: AIGenerateService,
        private store: Store<AppState>,
        private dialogRef: MatDialogRef<AiGenerateModalTt9Component>,
        private dialogService: DialogService,
        private snackbarService: SnackbarService,
    ) {}

    async generate(prompt: string, botAction: BotActions): Promise<void> {
        const snackbarTitle = botAction === BotActions.CreateSession ? sessionGenerationDoneSnackbarTitle : '';
        this.isLoading$.next(true);
        try {
            const { userId } = this;

            // Get generated data (without saving)
            const { notification, session } = await this.aiGenerateService.aiGenerateData<SessionBluePrint>(
                prompt,
                botAction,
                userId,
            );

            if (!session) {
                throw new Error();
            }

            this.previewData$.next(session);

            if (notification) {
                this.snackbarService.showSuccessSnackBar(snackbarTitle, notification);
            }

            this.moveToStep(BuilderStep.Preview);
        } catch (e) {
            this.snackbarService.showErrorSnackBar(aiGenerationErrorTitle, errorSnackbarText);
        } finally {
            this.isLoading$.next(false);
        }
    }

    async saveData(botAction: BotActions): Promise<void> {
        this.isLoading$.next(true);

        try {
            const previewData = this.previewData$.getValue();
            const dataToSave = botAction === BotActions.CreateSession ? previewData : '';

            // Save generated data
            const { notification, sessionId } = await this.aiGenerateService.aiSaveGeneratedData<SessionBluePrint>(
                dataToSave as SessionBluePrint,
                botAction,
                this.userId,
                this.userCollection,
            );

            if (notification) {
                this.snackbarService.showSuccessSnackBar(notification.title, notification.message);
            }
        } catch (e) {
            const title = aiGenerationSessionSaveErrorTitle;
            this.snackbarService.showErrorSnackBar(title, errorSnackbarText);
        } finally {
            this.isLoading$.next(false);
            this.closeDialog();
        }
    }

    moveToStep(step: BuilderStep): void {
        this.currentStep$.next(step);
    }

    moveToEntryStep(botAction: BotActions): void {
        const type = ParentType.Sessions;
        this.dialogService.open(ConfirmationDialogComponent, {
            title: aiBuilderReturnText.title,
            confirmBtnText: 'Return',
            cancelBtnText: 'Cancel',
            width: '444px',
            text: aiBuilderReturnText.text.replace('{type}', type),
            isWarning: true,
            confirm: async () => {
                this.moveToStep(BuilderStep.Entry);
            },
        });
    }

    openConfirmationToCloseDialog(botAction: BotActions): void {
        const type = ParentType.Sessions;
        this.dialogService.open(ConfirmationDialogComponent, {
            title: aiBuilderCloseText.title,
            confirmBtnText: 'Close',
            cancelBtnText: 'Cancel',
            width: '444px',
            text: aiBuilderCloseText.text.replace('{type}', type),
            isWarning: true,
            confirm: async () => {
                this.closeDialog();
            },
        });
    }

    closeDialogWithConfirmation(isPreviewStep?: boolean, botAction?: BotActions): void {
        if (isPreviewStep) {
            this.openConfirmationToCloseDialog(botAction);
        } else {
            this.closeDialog();
        }
    }

    backToPreviousModal(): void {
        this.closeDialog();
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    private buildViewModel(): Observable<AiGenerateModel> {
        return combineLatest([
            this.store.select(selectAuthenticatedUser),
            this.isLoading$.asObservable().pipe(distinctUntilChanged()),
            this.currentStep$.asObservable().pipe(distinctUntilChanged()),
            this.previewData$.asObservable().pipe(distinctUntilChanged()),
            this.store.select(selectRouterParams),
            this.store.select(selectUserCollection),
        ]).pipe(
            map(([user, isLoading, currentStep, previewData, queryParams, userCollection]) => {
                this.userId = user.id;
                this.userCollection = queryParams.collectionId ? userCollection : undefined;
                return { currentStep, previewData, isLoading };
            }),
            startWith(defaultViewModel),
        );
    }
}
