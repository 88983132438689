<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading">
    <ng-container *ngFor="let role of projectRoleList">
      <ng-container
        *ngTemplateOutlet="
          userInput;
          context: {
            control: inviteForm.get(role),
            adminMessageControl: inviteForm.controls.adminMessage,
            memberMessageControl: inviteForm.controls.memberMessage,
            projectMemberEmails: vm.projectMemberEmails,
            role: role
          }
        "
      ></ng-container>
    </ng-container>
  </ng-container>

  <ng-template
    #userInput
    let-control="control"
    let-projectMemberEmails="projectMemberEmails"
    let-adminMessageControl="adminMessageControl"
    let-memberMessageControl="memberMessageControl"
    let-role="role"
  >
    <div class="invitations-form" [formGroup]="inviteForm">
      <ng-container *ngIf="role === projectRole.Admin">
        <p class="tt9-body-2 mb-8">
          {{ invitationText.inviteAdmins }}
        </p>

        <h2 class="h3">{{ invitationText.admins }}</h2>

        <p class="tt9-body-2">{{ invitationText.inviteAdminsInfo }}</p>
      </ng-container>

      <ng-container *ngIf="role === projectRole.Member">
        <h2 class="h3 pt-32">{{ invitationText.members }}</h2>

        <p class="tt9-body-2">
          {{ invitationText.inviteMembers }}
        </p>
      </ng-container>

      <div class="d-flex-column flex-gap-16">
        <accenture-email-invitation-form
          [emailListName]="role | displayUserAccessRole"
          [blackListEmails]="getBlackListEmails(projectMemberEmails, role)"
          [formControlName]="role"
          [hideChips]="role === projectRole.Member"
          [isNonAccentureAvailableRole]="role === projectRole.Member"
        ></accenture-email-invitation-form>

        <ng-container *ngIf="inviteForm.controls[role].value.length && role === projectRole.Member">
          <div formArrayName="sessionGroup">
            <ng-container *ngFor="let sessionGroup of sessionGroups.controls; trackBy: trackByEmail; let i = index">
              <div [formGroupName]="i" class="d-flex-center-between">
                <div class="invitations-form-email text-overflow-ellipsis-2">{{ sessionGroup.value.email }}</div>

                <div class="invitations-form-session">
                  <mat-form-field appearance="outline" class="tt9-form-field custom-width" floatLabel="always">
                    <mat-select
                      multiple
                      placeholder="Select session"
                      formControlName="sessionsIds"
                      [disableOptionCentering]="true"
                      [panelClass]="['tt9-select-panel-with-checkboxes', 'tt9-select-panel-custom']"
                      (selectionChange)="setSessionInvitesValues(i)"
                    >
                      <mat-select-trigger *ngIf="isAllSelected(i, vm.sessions.length)">
                        All sessions
                      </mat-select-trigger>

                      <mat-checkbox
                        color="primary"
                        class="tt9-checkbox select-all"
                        [checked]="isAllSelected(i, vm.sessions.length)"
                        (change)="toggleSelectAll($event.checked, i, vm.sessions)"
                      >
                        All sessions
                      </mat-checkbox>

                      <mat-option *ngFor="let session of vm.sessions; trackBy: trackById" [value]="session.id">
                        <div class="d-flex">
                          <span
                            #sessionName
                            class="text-overflow-ellipsis"
                            matTooltipClass="tt9-arrow-tooltip-below"
                            matTooltipPosition="above"
                            [matTooltip]="session.name"
                            [matTooltipDisabled]="isDisabledTooltip(sessionName)"
                            >{{ session.name }}
                          </span>
                        </div>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="invitations-form-role">
                  <mat-form-field appearance="outline" class="tt9-form-field custom-width" floatLabel="always">
                    <mat-select
                      [placeholder]="'Project member'"
                      formControlName="role"
                      [disableOptionCentering]="true"
                      [panelClass]="['tt9-select-panel-custom']"
                      (selectionChange)="setSessionInvitesValues()"
                    >
                      <mat-option *ngFor="let role of sessionRoleList; trackBy: trackById" [value]="role">
                        Session {{ role | displayUserAccessRole | lowercase }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <accenture-icon
                  [colorType]="iconColor.Secondary"
                  (click)="removeEmail($event, sessionGroup.value.email, role)"
                >
                  icon-close
                </accenture-icon>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>

      <mat-form-field
        appearance="outline"
        class="textarea tt9-form-field mat-form-field-appearance-outline invitations-form-textarea"
      >
        <textarea
          #textInvite
          matInput
          [placeholder]="inputPlaceholders.inviteText"
          [maxlength]="characterLimitLarge"
          [formControl]="role === projectRole.Admin ? adminMessageControl : memberMessageControl"
        ></textarea>
        <mat-hint class="mat-hint-limit" [class]="determineHintClass(textInvite.value.length, characterLimitLarge)">{{
          characterLimitText(textInvite.value.length, characterLimitLarge)
        }}</mat-hint>
      </mat-form-field>
    </div>
  </ng-template>
</ng-container>
