import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Activity, ActivityFactory, DBPathHelper, DuplicateType, ParentType } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import { sortBySequenceAsc } from '@accenture/shared/util';

@Injectable({
    providedIn: 'root',
})
export class ActivityService {
    constructor(private firestoreService: FirestoreService) {}

    getActivityById(projectId: string, activityId: string): Observable<Activity> {
        return this.firestoreService
            .getDocument<Activity>(`projects/${projectId}/activities/${activityId}`)
            .pipe(catchError(() => of(null)));
    }

    getSessionActivityById(sessionId: string, activityId: string): Observable<Activity> {
        return this.firestoreService
            .getDocument<Activity>(DBPathHelper.getActivityPath(ParentType.Sessions, sessionId, activityId))
            .pipe(catchError(() => of(null)));
    }

    getActivitiesByMultipleProperties(
        parentType: ParentType,
        parentId: string,
        fields: Map<string, string>,
    ): Observable<Activity[]> {
        return this.firestoreService
            .getDocumentsByMultipleProperties<Activity>(
                DBPathHelper.getActivityPath(parentType, parentId),
                fields,
                'sequence',
            )
            .pipe(
                map((activities: Activity[]) =>
                    sortBySequenceAsc(
                        activities
                            .map(({ id, ...activity }) => ActivityFactory.create(id as string, activity as Activity))
                            .filter(activity => !!activity),
                    ),
                ),
            );
    }

    hasUserResponsesByActivityId(projectId: string, activityId: string, userId: string): Observable<boolean> {
        return this.firestoreService
            .getDocumentsByMultipleProperties(
                `projects/${projectId}/responses`,
                new Map([
                    ['activityId', activityId],
                    ['userId', userId],
                ]),
            )
            .pipe(map(responses => !!responses.length));
    }

    async deleteActivity(parentType: ParentType, parentId: string, activityId: string): Promise<void> {
        await this.firestoreService.cloudFunctionCallable('deleteActivity', {
            activityId,
            parentType,
            parentId,
        });
    }

    async addLikeToActivityTemplate(userAccessId: string, activityTemplateId: string, userId: string): Promise<void> {
        const data = {
            likes: {
                [userId]: true,
            },
        };
        await this.firestoreService.update(`/userAccess/${userAccessId}`, data);
        await this.firestoreService.update(`/activityTemplates/${activityTemplateId}`, data);
    }

    async removeLikeFromActivityTemplate(
        userAccessId: string,
        activityTemplateId: string,
        userId: string,
    ): Promise<void> {
        const data = {
            likes: {
                [userId]: this.firestoreService.deleteField,
            },
        };
        await this.firestoreService.update(`/userAccess/${userAccessId}`, data);
        await this.firestoreService.update(`/activityTemplates/${activityTemplateId}`, data);
    }

    async saveAsTemplate(
        parentId: string,
        activityId: string | undefined,
        parentType = ParentType.Projects,
        saveResponses = false,
    ): Promise<string> {
        const dictionary = await this.firestoreService.cloudFunctionCallable('duplicateData', {
            saveResponses,
            from: {
                parentType,
                parentId,
                activityId,
            },
            to: {
                parentType: ParentType.ActivityTemplates,
            },
            type: DuplicateType.Activity,
        });
        return dictionary[parentId];
    }
}
