import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Timestamp } from 'firebase/firestore';
import { BehaviorSubject, combineLatest, from, map, Observable, of, startWith, switchMap, take, tap } from 'rxjs';

import { InAppNotificationsService } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUser } from '@accenture/global-store';
import {
    defaultInAppNotificationFilters,
    InAppNotification,
    InAppNotificationFilters,
    InAppNotificationStatusFilterType,
    inAppNotificationsTypeToFilterTypeMap,
    InAppNotificationTypeFilterType,
    inAppNotificationTypeFilterTypeInOrder,
    lazyLoadingInAppNotificationsLimit,
    User,
} from '@accenture/shared/data';
import { UserService } from '@accenture/shared/data-access';

export interface InAppNotificationsViewModel {
    notifications: InAppNotification[];
    allLoaded: boolean;
    searchValue: string;
    filters: InAppNotificationFilters;
    filtersApplied: boolean;
    isLoading: boolean;
}

const initialViewModel: InAppNotificationsViewModel = {
    notifications: [],
    allLoaded: false,
    searchValue: '',
    filters: defaultInAppNotificationFilters,
    filtersApplied: false,
    isLoading: true,
};

@Injectable()
export class InAppNotificationsFacade {
    private readonly store: Store<AppState> = inject(Store<AppState>);
    private readonly inAppNotificationsService: InAppNotificationsService = inject(InAppNotificationsService);
    private readonly userService: UserService = inject(UserService);

    private readonly isLoading$ = new BehaviorSubject<boolean>(true);
    private user: User;

    vm$ = this.buildViewModel();

    get isUserAdminOrTemplateCreator(): boolean {
        return this.user?.roles?.admin || this.user?.roles?.templateCreator;
    }

    setSearchValue(value: string): void {
        this.inAppNotificationsService.searchValue$.next(value);
    }

    markNotificationAsRead(notificationId: string): void {
        this.inAppNotificationsService.markNotificationAsRead(notificationId);
    }

    deleteNotification(notificationId: string): void {
        this.inAppNotificationsService.deleteNotification(notificationId);
    }

    updateFilters(filters: Partial<InAppNotificationFilters>): void {
        this.inAppNotificationsService.updateFilters(this.user.id, filters);
    }

    async getNotifications(reset?: boolean): Promise<InAppNotification | undefined> {
        this.isLoading$.next(true);
        const result = await this.inAppNotificationsService.loadNotifications(this.user.id, reset);
        this.isLoading$.next(false);
        return result;
    }

    private buildViewModel(): Observable<InAppNotificationsViewModel> {
        const initializeData$ = this.store.select(selectAuthenticatedUser).pipe(
            take(1),
            tap((user: User) => (this.user = user)),
            switchMap(() => from(this.getNotifications(true))),
            tap((firstNotification: InAppNotification | undefined) => {
                // write first notification timestamp to read all notifications starting from first loaded
                if (firstNotification) {
                    this.updateUserLastViewedNotificationDateCreated(firstNotification.created as Timestamp);
                }
            }),
        );
        const notificationsData$ = initializeData$.pipe(
            switchMap((firstNotification: InAppNotification) => {
                const notifications$ = combineLatest({
                    // subscribe to new notifications while panel is opened
                    newNotifications: this.getUnseenNotifications(firstNotification),
                    // get lazy loaded notifications, that was created before panel opened
                    loadedNotifications: this.inAppNotificationsService.notifications$,
                }).pipe(
                    map(({ newNotifications, loadedNotifications }) => [...newNotifications, ...loadedNotifications]),
                );
                return combineLatest({
                    notifications: notifications$,
                    searchValue: this.inAppNotificationsService.searchValue$.asObservable(),
                    filters: this.inAppNotificationsService.getFilters(this.user.id),
                });
            }),
            switchMap(({ notifications, searchValue, filters }) =>
                combineLatest({
                    notifications: from(this.getFilteredNotifications(notifications, searchValue, filters)),
                    searchValue: of(searchValue),
                    filters: of(filters),
                }),
            ),
        );
        return combineLatest({
            notificationsData: notificationsData$,
            isLoading: this.isLoading$.asObservable(),
        }).pipe(
            map(({ notificationsData: { notifications, searchValue, filters }, isLoading }) => ({
                notifications,
                searchValue,
                filters,
                isLoading,
                filtersApplied: this.areFiltersApplied(filters),
                allLoaded: this.inAppNotificationsService.allLoaded,
                notificationTypeFilterTypes: this.isUserAdminOrTemplateCreator
                    ? [...inAppNotificationTypeFilterTypeInOrder, InAppNotificationTypeFilterType.TemplateRelated]
                    : inAppNotificationTypeFilterTypeInOrder,
            })),
            startWith(initialViewModel),
        );
    }

    private getUnseenNotifications(firstNotification?: InAppNotification): Observable<InAppNotification[]> {
        return this.inAppNotificationsService
            .getUnseenNotifications(this.user.id, firstNotification?.created as Timestamp)
            .pipe(
                tap((notifications: InAppNotification[]) => {
                    // write first notification timestamp to read all notifications when they appears while panel is opened
                    if (notifications.length) {
                        this.updateUserLastViewedNotificationDateCreated(notifications[0].created as Timestamp);
                    }
                }),
            );
    }

    private updateUserLastViewedNotificationDateCreated(lastViewedNotificationDateCreated: Timestamp): void {
        this.userService.updateUserData(this.user.id, {
            lastViewedNotificationDateCreated,
        });
    }

    private async getFilteredNotifications(
        notifications: InAppNotification[],
        rawSearchValue: string,
        filter: InAppNotificationFilters,
    ): Promise<InAppNotification[]> {
        const searchValue = (rawSearchValue || '').trim().toLowerCase();

        const areAllStatusesSelected
            = Object.keys(InAppNotificationStatusFilterType).every(
                key => !!filter[InAppNotificationStatusFilterType[key]],
            )
            || Object.keys(InAppNotificationStatusFilterType).every(
                key => !filter[InAppNotificationStatusFilterType[key]],
            );

        const areAllTypesSelected
            = Object.keys(InAppNotificationTypeFilterType).every(key => !!filter[InAppNotificationTypeFilterType[key]])
            || Object.keys(InAppNotificationTypeFilterType).every(key => !filter[InAppNotificationTypeFilterType[key]]);

        const result = notifications.filter(({ sessionName, projectName, activityName, unread, type }: any) => {
            const isStatusMatches
                = areAllStatusesSelected || (filter[InAppNotificationStatusFilterType.Unread] ? unread : !unread);
            const isTypeMatches = areAllTypesSelected || filter[inAppNotificationsTypeToFilterTypeMap[type]];

            if (!isStatusMatches || !isTypeMatches) {
                return false;
            }

            if (!searchValue) {
                return true;
            }

            return (
                (isStatusMatches && isTypeMatches && sessionName?.toLowerCase()?.includes(searchValue))
                || projectName?.toLowerCase()?.includes(searchValue)
                || activityName?.toLowerCase()?.includes(searchValue)
            );
        });

        if (result.length < lazyLoadingInAppNotificationsLimit) {
            await this.getNotifications();
        }

        return result;
    }

    private areFiltersApplied(filters: InAppNotificationFilters): boolean {
        return Object.keys(defaultInAppNotificationFilters).some(
            filterKey => defaultInAppNotificationFilters[filterKey] !== filters[filterKey],
        );
    }
}
