import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { tap } from 'rxjs';

import { alwaysError } from '@accenture/activity/shared/domain';
import { noClientSelected } from '@accenture/erp-deployment/shared/domain';
import {
    characterLimitMedium,
    characterLimitText,
    CollectionsSortObject,
    determineHintClass,
    Dictionary,
    newProjectOptionText,
    OptionsForSortingByNumberOfUses,
    ProjectOptions,
    projectOptionsTitle,
    ProjectOptionsType,
    SelectedProjectOptions,
    SessionOptionsType,
    validationMessages,
} from '@accenture/shared/data';
import { alphabeticalKeyValueOrder, trackById } from '@accenture/shared/util';

import { optionPlaceholderText } from './project-options.constants';
import { ProjectOptionsFacade, ProjectOptionsViewModel } from './project-options-facade';

type OptionsFormGroup = FormGroup<{
    [ProjectOptions.Tag]: FormControl<string>;
    [ProjectOptions.Client]: FormControl<string>;
}>;

// TODO: REMOVE AFTER COLLECTIONS WILL BE IMPLEMENTED
@Component({
    selector: 'accenture-project-options',
    templateUrl: './project-options.component.html',
    styleUrls: ['./project-options.component.scss'],
    providers: [ProjectOptionsFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectOptionsComponent {
    @Input() isCreateProjectModal: boolean;

    vm$ = this.facade.vm$.pipe(
        tap(vm => {
            const projectOptionsLength = Object.keys(vm.selectedProjectOptions?.clients || {}).length;

            if (projectOptionsLength) {
                this.optionsFormGroup.controls.clients.disable();
            } else if (!this.isCreateProjectModal && !projectOptionsLength && !vm.hasClientChanged) {
                this.optionsFormGroup.controls.clients.setValue(noClientSelected);
                this.optionsFormGroup.controls.clients.disable();
            } else {
                this.optionsFormGroup.controls.clients.setValue('');
                this.optionsFormGroup.controls.clients.enable();
            }
        }),
    );

    filteredOptions: SessionOptionsType[] = []; // options for autocomplete

    projectOptions = ProjectOptions;
    projectOptionsTitle = projectOptionsTitle;
    optionPlaceholderText = optionPlaceholderText;
    newOptionText = newProjectOptionText;
    alphabeticalKeyValueOrder = alphabeticalKeyValueOrder;
    optionsFormGroup!: OptionsFormGroup;
    characterLimitMedium = characterLimitMedium;
    characterLimitText = characterLimitText;
    determineHintClass = determineHintClass;
    validationMessages = validationMessages;
    noClientSelected = noClientSelected;
    collectionToCount = OptionsForSortingByNumberOfUses;

    trackById = trackById;

    constructor(private facade: ProjectOptionsFacade, private fb: FormBuilder) {
        this.createFormGroup();
    }

    isClientsOption(projectOption: ProjectOptions): boolean {
        return projectOption === ProjectOptions.Client;
    }

    hasSelectedOptions(projectOptions: Dictionary<string>): boolean {
        return !!Object.keys(projectOptions || {}).length;
    }

    hasSelectedClientsOptionsInEditModal(
        projectOptions: Dictionary<string>,
        projectOption: ProjectOptions,
        hasClientChanged: boolean,
    ): boolean {
        return (
            !Object.keys(projectOptions || {}).length
            && !this.isCreateProjectModal
            && !hasClientChanged
            && this.isClientsOption(projectOption)
        );
    }

    hasMultiplyValues({ clients }: SelectedProjectOptions, projectOption: ProjectOptions): boolean {
        switch (projectOption) {
            case ProjectOptions.Client:
                return !!Object.keys(clients || {}).length;
            default:
                return false;
        }
    }

    displayEmptyOption(): null {
        // for matAutocomplete to display if we reset control value
        return null;
    }

    getOptionsData({ clients, tags, currentOptionToDisplay }: ProjectOptionsViewModel): ProjectOptionsType[] {
        switch (currentOptionToDisplay) {
            case ProjectOptions.Client:
                return clients;
            case ProjectOptions.Tag:
                return tags;
        }
    }

    isNewOptionShown(projectOption: ProjectOptions, selectedOptions: SelectedProjectOptions, value: string): boolean {
        const searchValue = value?.toLowerCase().trim();
        const optionsToDisplay = this.facade.getFilteredOptions(
            projectOption,
            selectedOptions[projectOption],
            searchValue,
        );
        this.filteredOptions = optionsToDisplay.filteredOptions;

        return !optionsToDisplay.isOptionExist;
    }

    getControl(projectOption: ProjectOptions.Client | ProjectOptions.Tag): FormControl<string> {
        return this.optionsFormGroup.controls[projectOption];
    }

    showOptionChips(optionType: ProjectOptions, event?: Event): void {
        this.facade.showOptionChips(optionType);

        if (event) {
            event.stopPropagation();
        }
    }

    updateSelectedOptions(data: {
        option: ProjectOptionsType;
        isSelected: boolean;
        selectedOption?: ProjectOptionsType;
    }): void {
        const option = {
            [data.option.id]: data.option.name,
        } as Dictionary<string>;

        this.facade.updateProjectOptions(option);
    }

    updateFilters(filterObject: CollectionsSortObject): void {
        this.facade.updateFilters(filterObject);
    }

    removeOptionValue(projectOptions: ProjectOptions, selectedOption?: KeyValue<string, string>): void {
        if (selectedOption) {
            const option = {
                [selectedOption.key]: selectedOption.value,
            } as Dictionary<string>;

            this.facade.updateProjectOptions(option, projectOptions);
        }

        if (projectOptions === ProjectOptions.Client) {
            this.optionsFormGroup.controls.clients.setValue('');
            this.optionsFormGroup.controls.clients.enable();
            this.optionsFormGroup.controls.clients.markAsTouched();
            this.facade.setHasClientChanged(true);
        }
    }

    updateOptionValue(selectedOption: ProjectOptionsType, projectOptions: ProjectOptions): void {
        const option = {
            [selectedOption.id]: selectedOption.name,
        } as Dictionary<string>;

        this.facade.updateProjectOptions(option, projectOptions);
    }

    addNewOption(projectOption: ProjectOptions): void {
        const controlValue = this.getControl(projectOption).value?.trim();
        if (!controlValue) {
            return;
        }

        this.facade.addNewOption(projectOption, controlValue);
    }

    private createFormGroup(): void {
        this.optionsFormGroup = this.fb.group(
            {
                tags: new FormControl(''),
                clients: new FormControl('', {
                    validators: [Validators.required, alwaysError],
                }),
            },
            {
                updateOn: 'blur',
            },
        );
    }
}
