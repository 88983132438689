import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import {
    characterLimitMedium,
    characterLimitText,
    CollectionsSortObject,
    determineHintClass,
    Dictionary,
    newOptionText,
    OptionsForSortingByNumberOfUses,
    SelectedSessionOptions,
    SessionOptions,
    sessionOptionsArray,
    sessionOptionsTitle,
    SessionOptionsType,
} from '@accenture/shared/data';
import { alphabeticalKeyValueOrder, trackById } from '@accenture/shared/util';

import { SessionOptionsFacade, SessionOptionsViewModel } from './session-options-facade';

@Component({
    selector: 'accenture-session-options',
    templateUrl: './session-options.component.html',
    styleUrls: ['./session-options.component.scss'],
    providers: [SessionOptionsFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionOptionsComponent {
    vm$ = this.facade.vm$;

    filteredOptions: SessionOptionsType[] = []; // options for autocomplete

    trackById = trackById;
    sessionOptions = SessionOptions;
    sessionOptionsTitle = sessionOptionsTitle;
    newOptionText = newOptionText;
    alphabeticalKeyValueOrder = alphabeticalKeyValueOrder;
    optionsFormGroup!: FormGroup<Dictionary<FormControl<string>>>;
    characterLimitMedium = characterLimitMedium;
    characterLimitText = characterLimitText;
    determineHintClass = determineHintClass;
    collectionToCount = OptionsForSortingByNumberOfUses;

    constructor(private facade: SessionOptionsFacade, private fb: FormBuilder) {
        this.createFormGroup();
    }

    displayEmptyOption(): null {
        // for matAutocomplete to display if we reset control value
        return null;
    }

    hasMultiplyValues({ phase, subPhase }: SelectedSessionOptions, sessionOption: SessionOptions): boolean {
        switch (sessionOption) {
            case SessionOptions.Phase:
                return !!Object.keys(phase || {}).length;
            case SessionOptions.SubPhase:
                return !!Object.keys(subPhase || {}).length;
            default:
                return false;
        }
    }

    getOptionsData({
        phaseOptions,
        subPhaseOptions,
        tags,
        currentOptionToDisplay,
    }: SessionOptionsViewModel): SessionOptionsType[] {
        switch (currentOptionToDisplay) {
            case SessionOptions.Phase:
                return phaseOptions;
            case SessionOptions.Tag:
                return tags;
            case SessionOptions.SubPhase:
                return subPhaseOptions;
        }
    }

    isNewOptionShown(sessionOption: SessionOptions, selectedOptions: SelectedSessionOptions, value: string): boolean {
        const searchValue = value?.toLowerCase().trim();
        const optionsToDisplay = this.facade.getFilteredOptions(
            sessionOption,
            selectedOptions[sessionOption],
            searchValue,
        );
        this.filteredOptions = optionsToDisplay.filteredOptions;

        return !optionsToDisplay.isOptionExist;
    }

    getControl(sessionOption: SessionOptions): FormControl<string> {
        return this.optionsFormGroup.get(sessionOption) as FormControl<string>;
    }

    showOptionChips(optionType: SessionOptions, event?: Event): void {
        this.facade.showOptionChips(optionType);

        if (event) {
            event.stopPropagation();
        }
    }

    updateSelectedOptions(data: {
        option: SessionOptionsType;
        isSelected: boolean;
        selectedOption?: SessionOptionsType;
    }): void {
        const option = {
            [data.option.id]: data.option.name,
        } as Dictionary<string>;

        this.facade.updateSessionOptions(option);
    }

    updateFilters(filterObject: CollectionsSortObject): void {
        this.facade.updateFilters(filterObject);
    }

    removeOptionValue(selectedOption: KeyValue<string, string>, sessionOptions: SessionOptions): void {
        const option = {
            [selectedOption.key]: selectedOption.value,
        } as Dictionary<string>;

        this.facade.updateSessionOptions(option, sessionOptions);
    }

    updateOptionValue(selectedOption: SessionOptionsType, sessionOptions: SessionOptions): void {
        const option = {
            [selectedOption.id]: selectedOption.name,
        } as Dictionary<string>;

        this.facade.updateSessionOptions(option, sessionOptions);
    }

    addNewOption(sessionOption: SessionOptions): void {
        const controlValue = this.getControl(sessionOption).value.trim();
        if (!controlValue) {
            return;
        }

        this.facade.addNewOption(sessionOption, controlValue);
    }

    private createFormGroup(): void {
        this.optionsFormGroup = this.fb.group({});

        sessionOptionsArray.forEach(element => this.optionsFormGroup.addControl(element, new FormControl<string>('')));
    }
}
