<ng-container *ngIf="vm$ | async as vm">
  <ng-container *ngIf="!vm.isLoading">
    <button
      *ngIf="vm.available"
      class="tt9-btn-icon-filled"
      matTooltipPosition="left"
      matTooltipClass="tt9-arrow-tooltip-right"
      [matTooltip]="tooltip(vm.session)"
      (click)="bookMeeting(vm.session, vm.members)"
    >
      <accenture-icon>icon-calendar</accenture-icon>
    </button>
  </ng-container>
  <div *ngIf="vm.isLoading" class="loading">
    <accenture-loading [loaderSize]="loaderSize"></accenture-loading>
  </div>
</ng-container>
