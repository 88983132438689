import { Injectable } from '@angular/core';
import { Timestamp } from 'firebase/firestore';
import { intersection, isEmpty } from 'lodash';
import { BehaviorSubject } from 'rxjs';

import {
    ActivityOptions,
    CollectionOptions,
    CollectionSortOptions,
    CollectionsSortObject,
    DefaultFilterObject,
    Dictionary,
    FavoriteAccess,
    FavoriteTemplateType,
    initialDefaultCollectionSortObject,
    initialDefaultFilterObject,
    OptionsForSortingByNumberOfUses,
    ParentType,
    ProjectOptions,
    ProjectOptionsType,
    PublicAccess,
    publicParentTypes,
    PublicTemplateRoleForFilter,
    Session,
    SessionFilter,
    SessionOptions,
    SessionOptionsType,
    sortByNumberOfUsesValue,
    SortOptions,
    SortOrders,
    TemplateRole,
    TemplatesTab,
    TemplateTab,
    TemplateType,
    UserAccess,
    UserAccessSession,
    UserCollection,
    UserSession,
    UserTemplate,
} from '@accenture/shared/data';
import {
    compareNumbers,
    compareObjectLengths,
    compareStrings,
    compareTimestampsByValue,
    sortByAlphabetic,
    sortByDateAndDirection,
    sortByDateAsc,
    sortByObjectKeysCountAsc,
} from '@accenture/shared/util';

@Injectable({
    providedIn: 'root',
})
export class FiltersService {
    templatesViewTab$ = new BehaviorSubject<TemplatesTab>(TemplatesTab.MyTemplates);
    sessionOptionType$ = new BehaviorSubject<SessionOptions>(SessionOptions.Tag);

    private myTemplatesViewTab = new BehaviorSubject<TemplateTab>(TemplateTab.All);
    readonly myTemplatesViewTab$ = this.myTemplatesViewTab.asObservable();

    private templatesStoreViewTab = new BehaviorSubject<TemplateTab>(TemplateTab.All);
    readonly templatesStoreViewTab$ = this.templatesStoreViewTab.asObservable();

    private favoriteTemplatesViewTab = new BehaviorSubject<TemplateTab>(TemplateTab.All);
    readonly favoriteTemplatesViewTab$ = this.favoriteTemplatesViewTab.asObservable();

    optionType = new BehaviorSubject<ProjectOptions | SessionOptions | ActivityOptions | CollectionOptions | null>(
        null,
    );

    readonly optionType$ = this.optionType.asObservable();

    setMyTemplatesViewTab(tab: TemplateTab): void {
        this.myTemplatesViewTab.next(tab);
    }

    setTemplateStoreViewTab(tab: TemplateTab): void {
        this.templatesStoreViewTab.next(tab);
    }

    setFavoriteTemplateViewTab(tab: TemplateTab): void {
        this.favoriteTemplatesViewTab.next(tab);
    }

    // // TODO: this method will be delete when the collection is completed
    filterAndSort(collection: UserAccess[], filterObject: DefaultFilterObject, searchValue: string): UserAccess[] {
        const sortOption = filterObject.sortOption || SortOptions.LastViewed;
        let sortedAndFilteredCollection: UserAccess[] = collection.filter(
            (collectionItem) =>
                !(
                    !collectionItem.name?.toLowerCase()?.includes(searchValue)
                    || collectionItem.created?.valueOf() < filterObject.fromDate?.valueOf()
                    || collectionItem.created?.valueOf() > filterObject.toDate?.valueOf()
                ),
        );

        if (filterObject.roles?.length) {
            sortedAndFilteredCollection = sortedAndFilteredCollection.filter((collectionItem: UserAccess) => {
                return filterObject.roles.includes(collectionItem.role);
            });
        }

        if (filterObject.activityTypes?.length) {
            sortedAndFilteredCollection = sortedAndFilteredCollection.filter((collectionItem: UserAccess) => {
                return filterObject.activityTypes.includes(collectionItem.activityType);
            });
        }

        if (filterObject.tags && filterObject.tags.length) {
            sortedAndFilteredCollection = sortedAndFilteredCollection.filter((collectionItem: UserAccess) => {
                const tagsIds = Object.keys(collectionItem?.tags || {});

                return tagsIds.reduce((acc: boolean, id: string) => {
                    if (filterObject.tags.includes(id)) {
                        acc = true;
                    }

                    return acc;
                }, false);
            });
        }

        if (filterObject.clients && filterObject.clients.length) {
            sortedAndFilteredCollection = sortedAndFilteredCollection.filter((collectionItem: UserAccess) => {
                const clientsIds = Object.keys(collectionItem?.clients || {});

                return clientsIds.reduce((acc: boolean, id: string) => {
                    if (filterObject.clients.includes(id)) {
                        acc = true;
                    }

                    return acc;
                }, false);
            });
        }

        if (filterObject.phases && filterObject.phases.length) {
            sortedAndFilteredCollection = sortedAndFilteredCollection.filter((collectionItem: UserAccess) => {
                const phasesIds = Object.keys(collectionItem?.phases || {});

                return phasesIds.reduce((acc: boolean, id: string) => {
                    if (filterObject.phases.includes(id)) {
                        acc = true;
                    }

                    return acc;
                }, false);
            });
        }

        if (filterObject.subPhases && filterObject.subPhases.length) {
            sortedAndFilteredCollection = sortedAndFilteredCollection.filter((collectionItem: UserAccess) => {
                const subPhasesIds = Object.keys(collectionItem?.subPhases || {});

                return subPhasesIds.reduce((acc: boolean, id: string) => {
                    if (filterObject.subPhases.includes(id)) {
                        acc = true;
                    }

                    return acc;
                }, false);
            });
        }

        switch (sortOption) {
            case SortOptions.Created:
                sortedAndFilteredCollection = sortedAndFilteredCollection.sort(sortByDateAsc('created'));
                break;
            case SortOptions.Updated:
                sortedAndFilteredCollection = sortedAndFilteredCollection.sort(sortByDateAsc('updated'));
                break;
            case SortOptions.Name:
                sortedAndFilteredCollection = sortByAlphabetic(sortedAndFilteredCollection, 'name');
                break;
            case SortOptions.Likes:
                sortedAndFilteredCollection = sortByObjectKeysCountAsc(sortedAndFilteredCollection, 'likes');
                break;
            case SortOptions.LastViewed:
                sortedAndFilteredCollection = sortedAndFilteredCollection.sort(sortByDateAsc('lastViewed'));
                break;
        }

        return filterObject.sortOrder === SortOrders.Dsc
            ? sortedAndFilteredCollection.reverse()
            : sortedAndFilteredCollection;
    }

    filterAndSortNew(
        collection: UserTemplate[],
        filterObject: DefaultFilterObject,
        searchValue: string,
    ): UserTemplate[] {
        const sortOption = filterObject.sortOption || SortOptions.LastViewed;
        let sortedAndFilteredCollection: UserTemplate[] = collection.filter(
            (collectionItem) =>
                !(
                    !collectionItem.name?.toLowerCase()?.includes(searchValue)
                    || collectionItem.created?.valueOf() < filterObject.fromDate?.valueOf()
                    || collectionItem.created?.valueOf() > filterObject.toDate?.valueOf()
                ),
        );

        if (filterObject.roles?.length) {
            sortedAndFilteredCollection = sortedAndFilteredCollection.filter((collectionItem: UserTemplate) => {
                return filterObject.roles.includes(collectionItem.role);
            });
        }

        if (filterObject.activityTypes?.length) {
            sortedAndFilteredCollection = sortedAndFilteredCollection.filter((collectionItem: UserTemplate) => {
                return filterObject.activityTypes.includes(collectionItem.activityType);
            });
        }

        if (filterObject.tags && filterObject.tags.length) {
            sortedAndFilteredCollection = sortedAndFilteredCollection.filter((collectionItem: UserTemplate) => {
                const tagsIds = Object.keys(collectionItem?.tags || {});

                return tagsIds.reduce((acc: boolean, id: string) => {
                    if (filterObject.tags.includes(id)) {
                        acc = true;
                    }

                    return acc;
                }, false);
            });
        }

        if (filterObject.clients && filterObject.clients.length) {
            sortedAndFilteredCollection = sortedAndFilteredCollection.filter((collectionItem: UserTemplate) => {
                const clientsIds = Object.keys(collectionItem?.clients || {});

                return clientsIds.reduce((acc: boolean, id: string) => {
                    if (filterObject.clients.includes(id)) {
                        acc = true;
                    }

                    return acc;
                }, false);
            });
        }

        if (filterObject.phases && filterObject.phases.length) {
            sortedAndFilteredCollection = sortedAndFilteredCollection.filter((collectionItem: UserTemplate) => {
                const phasesIds = Object.keys(collectionItem?.phases || {});

                return phasesIds.reduce((acc: boolean, id: string) => {
                    if (filterObject.phases.includes(id)) {
                        acc = true;
                    }

                    return acc;
                }, false);
            });
        }

        if (filterObject.subPhases && filterObject.subPhases.length) {
            sortedAndFilteredCollection = sortedAndFilteredCollection.filter((collectionItem: UserTemplate) => {
                const subPhasesIds = Object.keys(collectionItem?.subPhases || {});

                return subPhasesIds.reduce((acc: boolean, id: string) => {
                    if (filterObject.subPhases.includes(id)) {
                        acc = true;
                    }

                    return acc;
                }, false);
            });
        }

        switch (sortOption) {
            case SortOptions.Created:
                sortedAndFilteredCollection = sortedAndFilteredCollection.sort(sortByDateAsc('created'));
                break;
            case SortOptions.Updated:
                sortedAndFilteredCollection = sortedAndFilteredCollection.sort(sortByDateAsc('updated'));
                break;
            case SortOptions.Name:
                sortedAndFilteredCollection = sortByAlphabetic(sortedAndFilteredCollection, 'name');
                break;
            case SortOptions.Likes:
                sortedAndFilteredCollection = sortByObjectKeysCountAsc(sortedAndFilteredCollection, 'likes');
                break;
            case SortOptions.LastViewed:
                sortedAndFilteredCollection = sortedAndFilteredCollection.sort(sortByDateAsc('lastViewed'));
                break;
        }

        return filterObject.sortOrder === SortOrders.Dsc
            ? sortedAndFilteredCollection.reverse()
            : sortedAndFilteredCollection;
    }

    filterAndSortTemplatesStore(
        collection: PublicAccess[],
        filterObject: DefaultFilterObject,
        searchValue: string,
        userId?: string,
    ): PublicAccess[] {
        const sortOption = filterObject.sortOption || SortOptions.LastViewed;
        const direction = filterObject.sortOrder || SortOrders.Asc;

        // filtering by multiple options
        let sortedAndFilteredCollection: PublicAccess[] = collection.filter((collectionItem) => {
            const isFilteredByOwner = filterObject?.currentUserTemplates ? collectionItem.ownerId === userId : true;
            const isSearchValue = collectionItem.name?.toLowerCase()?.includes(searchValue);
            const isEqualFromDate
                = !filterObject.fromDate || collectionItem.created?.valueOf() > filterObject.fromDate?.valueOf();
            const isEqualToDate
                = !filterObject.toDate || collectionItem.created?.valueOf() < filterObject.toDate?.valueOf();
            const isEqualTemplateTypes
                = !filterObject.templateTypes?.length
                || (filterObject.templateTypes.includes(TemplateType.Approved) && collectionItem.approved)
                || (filterObject.templateTypes.includes(TemplateType.Community) && !collectionItem.approved);
            const isEqualTemplateRoles
                = !filterObject.templateRoles?.length
                || (filterObject.templateRoles.includes(TemplateRole.Owner) && collectionItem.ownerId === userId)
                || (filterObject.templateRoles.includes(TemplateRole.Collaborator)
                    && collectionItem.teamMembersIds?.includes(userId))
                || (filterObject.templateRoles.includes(PublicTemplateRoleForFilter.Viewer)
                    && collectionItem.ownerId !== userId
                    && !collectionItem.teamMembersIds?.includes(userId));
            const isEqualActivityTypes
                = !filterObject.activityTypes?.length || filterObject.activityTypes.includes(collectionItem.activityType);
            const isEqualPhases
                = !filterObject.phases?.length
                || !!intersection(Object.keys(collectionItem?.phases || {}), filterObject.phases)?.length;
            const isEqualSubPhases
                = !filterObject.subPhases?.length
                || !!intersection(Object.keys(collectionItem?.subPhases || {}), filterObject.subPhases)?.length;
            const isEqualTags
                = !filterObject.tags?.length
                || !!intersection(Object.keys(collectionItem?.tags || {}), filterObject.tags)?.length;

            return (
                isSearchValue
                && isFilteredByOwner
                && isEqualFromDate
                && isEqualToDate
                && isEqualTemplateTypes
                && isEqualTemplateRoles
                && isEqualActivityTypes
                && isEqualPhases
                && isEqualSubPhases
                && isEqualTags
            );
        });

        // sorting with own draft and featured flag in priority
        sortedAndFilteredCollection = sortedAndFilteredCollection.sort((a, b) => {
            const isOwnersDraft = (element: PublicAccess) =>
                +((element.draftTemplateId && element.ownerId === userId) || false);
            const draftPriorityResult = isOwnersDraft(b) - isOwnersDraft(a);
            const featuredPriorityResult = +(b.featured || false) - +(a.featured || false);
            let compareResult = 0;
            switch (sortOption) {
                case SortOptions.Created:
                    compareResult = compareTimestampsByValue(a.created, b.created);
                    break;
                case SortOptions.Updated:
                    compareResult = compareTimestampsByValue(a.updated, b.updated);
                    break;
                case SortOptions.LastViewed:
                    compareResult = compareTimestampsByValue(a.lastViewed, b.lastViewed);
                    break;
                case SortOptions.Name:
                    compareResult = compareStrings(a.name, b.name);
                    break;
                case SortOptions.Likes:
                    compareResult = compareObjectLengths(a.likes, b.likes);
                    break;
                case SortOptions.TimesUsed:
                    compareResult = compareNumbers(a.timesUsed, b.timesUsed);
                    break;
                case SortOptions.MostFavorite:
                    compareResult = compareNumbers(a.favoritesCount, b.favoritesCount);
                    break;
                default:
                    compareResult = 0;
                    break;
            }
            return draftPriorityResult || featuredPriorityResult || compareResult * (direction === 'DSC' ? -1 : 1);
        });

        return sortedAndFilteredCollection;
    }

    // TODO: Delete when the collection feature is finished.
    filterAndSortFavoriteTemplates(
        collection: FavoriteAccess[],
        filterObject: DefaultFilterObject,
        searchValue: string,
        userId?: string,
    ): FavoriteAccess[] {
        const direction = filterObject.sortOrder || SortOrders.Asc;

        // filtering by multiple options
        const sortedAndFilteredCollection: FavoriteAccess[] = collection
            .filter((collectionItem) => {
                const isSearchValue = collectionItem.name?.toLowerCase()?.includes(searchValue);
                const isMyTemplate = [
                    ParentType.ProjectTemplates,
                    ParentType.Templates,
                    ParentType.ActivityTemplates,
                ].includes(collectionItem.templateType);
                const isEqualFromDate
                    = !filterObject.fromDate || collectionItem.created?.valueOf() > filterObject.fromDate?.valueOf();
                const isEqualToDate
                    = !filterObject.toDate || collectionItem.created?.valueOf() < filterObject.toDate?.valueOf();
                const isEqualTemplateTypes
                    = !filterObject.templateTypes?.length
                    || (filterObject.templateTypes.includes(FavoriteTemplateType.Approved)
                        && collectionItem.approved
                        && this.isPublicTemplate(collectionItem))
                    || (filterObject.templateTypes.includes(FavoriteTemplateType.Community)
                        && !collectionItem.approved
                        && this.isPublicTemplate(collectionItem))
                    || (filterObject.templateTypes.includes(FavoriteTemplateType.MyTemplate) && isMyTemplate);
                const isEqualActivityTypes
                    = !filterObject.activityTypes?.length
                    || filterObject.activityTypes.includes(collectionItem.activityType);
                const isEqualTemplateRoles
                    = !filterObject.templateRoles?.length
                    || (filterObject.templateRoles.includes(TemplateRole.Owner) && collectionItem.ownerId === userId)
                    || this.showTemplateCollaborators(filterObject.templateRoles, collectionItem, userId)
                    || this.showTemplateViewers(filterObject.templateRoles, collectionItem, userId);
                const isEqualPhases
                    = !filterObject.phases?.length
                    || !!intersection(Object.keys(collectionItem?.phases || {}), filterObject.phases)?.length;
                const isEqualSubPhases
                    = !filterObject.subPhases?.length
                    || !!intersection(Object.keys(collectionItem?.subPhases || {}), filterObject.subPhases)?.length;
                const isEqualTags
                    = !filterObject.tags?.length
                    || !!intersection(Object.keys(collectionItem?.tags || {}), filterObject.tags)?.length;

                return (
                    isSearchValue
                    && isEqualFromDate
                    && isEqualToDate
                    && isEqualTemplateTypes
                    && isEqualActivityTypes
                    && isEqualTemplateRoles
                    && isEqualPhases
                    && isEqualSubPhases
                    && isEqualTags
                );
            })
            .sort((a, b) => {
                // sorting with own draft and featured flag in priority
                const isOwnersDraft = (element: FavoriteAccess) =>
                    +((element.draftTemplateId && element.ownerId === userId) || false);
                const draftPriorityResult = isOwnersDraft(b) - isOwnersDraft(a);
                const featuredPriorityResult = +(b.featured || false) - +(a.featured || false);
                let compareResult = 0;

                switch (filterObject.sortOption) {
                    case SortOptions.Updated:
                        compareResult = compareTimestampsByValue(a.updated, b.updated);
                        break;
                    case SortOptions.Name:
                        compareResult = compareStrings(a.name, b.name);
                        break;
                    default:
                        compareResult = 0;
                        break;
                }
                return (
                    draftPriorityResult
                    || featuredPriorityResult
                    || compareResult * (direction === SortOrders.Dsc ? -1 : 1)
                );
            });

        return sortedAndFilteredCollection;
    }

    filterAndSortFavoriteTemplatesNew(
        collection: FavoriteAccess[],
        filterObject: DefaultFilterObject,
        searchValue: string,
        userId?: string,
    ): FavoriteAccess[] {
        const direction = filterObject.sortOrder || SortOrders.Asc;

        // filtering by multiple options
        const sortedAndFilteredCollection: FavoriteAccess[] = collection
            .filter((collectionItem) => {
                const isSearchValue = collectionItem.name?.toLowerCase()?.includes(searchValue);

                const isMyTemplate = [ParentType.Templates, ParentType.ActivityTemplates].includes(
                    collectionItem.templateType,
                );
                const isEqualFromDate
                    = !filterObject.fromDate || collectionItem.created?.valueOf() > filterObject.fromDate?.valueOf();

                const isEqualToDate
                    = !filterObject.toDate || collectionItem.created?.valueOf() < filterObject.toDate?.valueOf();
                const isEqualTemplateTypes
                    = !filterObject.templateTypes?.length
                    || (filterObject.templateTypes.includes(FavoriteTemplateType.Approved)
                        && collectionItem.approved
                        && this.isPublicTemplate(collectionItem))
                    || (filterObject.templateTypes.includes(FavoriteTemplateType.Community)
                        && !collectionItem.approved
                        && this.isPublicTemplate(collectionItem))
                    || (filterObject.templateTypes.includes(FavoriteTemplateType.MyTemplate) && isMyTemplate);
                const isEqualActivityTypes
                    = !filterObject.activityTypes?.length
                    || filterObject.activityTypes.includes(collectionItem.activityType);
                const isEqualTemplateRoles
                    = !filterObject.templateRoles?.length
                    || (filterObject.templateRoles.includes(TemplateRole.Owner) && collectionItem.ownerId === userId)
                    || this.showTemplateCollaborators(filterObject.templateRoles, collectionItem, userId)
                    || this.showTemplateViewers(filterObject.templateRoles, collectionItem, userId);
                const isEqualPhases
                    = !filterObject.phases?.length
                    || !!intersection(Object.keys(collectionItem?.phases || {}), filterObject.phases)?.length;
                const isEqualSubPhases
                    = !filterObject.subPhases?.length
                    || !!intersection(Object.keys(collectionItem?.subPhases || {}), filterObject.subPhases)?.length;
                const isEqualTags
                    = !filterObject.tags?.length
                    || !!intersection(Object.keys(collectionItem?.tags || {}), filterObject.tags)?.length;

                return (
                    isSearchValue
                    && isEqualFromDate
                    && isEqualToDate
                    && isEqualTemplateTypes
                    && isEqualActivityTypes
                    && isEqualTemplateRoles
                    && isEqualPhases
                    && isEqualSubPhases
                    && isEqualTags
                );
            })

            .sort((a, b) => {
                // sorting with own draft and featured flag in priority
                const isOwnersDraft = (element: FavoriteAccess) =>
                    +((element.draftTemplateId && element.ownerId === userId) || false);
                const draftPriorityResult = isOwnersDraft(b) - isOwnersDraft(a);
                const featuredPriorityResult = +(b.featured || false) - +(a.featured || false);
                let compareResult = 0;

                switch (filterObject.sortOption) {
                    case SortOptions.Updated:
                        compareResult = compareTimestampsByValue(a.updated, b.updated);
                        break;
                    case SortOptions.Name:
                        compareResult = compareStrings(a.name, b.name);
                        break;
                    default:
                        compareResult = 0;
                        break;
                }
                return (
                    draftPriorityResult
                    || featuredPriorityResult
                    || compareResult * (direction === SortOrders.Dsc ? -1 : 1)
                );
            });

        return sortedAndFilteredCollection;
    }

    // TODO: Delete when the collection feature is finished.
    filterFavoriteTemplates(templates: FavoriteAccess[]): FavoriteAccess[] {
        return templates.filter(
            (item) => item.templateType !== 'projectTemplates' && item.templateType !== 'publicProjectTemplates',
        );
    }

    sortOptionsData(
        options: SessionOptionsType[],
        filterObject: CollectionsSortObject,
        optionForSortingByNumberOfUses: OptionsForSortingByNumberOfUses,
    ): SessionOptionsType[] {
        const sortByNumberOfUsesFieldName = sortByNumberOfUsesValue[optionForSortingByNumberOfUses];
        const sortedOptions
            = filterObject?.sortOption === CollectionSortOptions.Name
                ? sortByAlphabetic([...options], 'name')
                : [...options].sort(
                      (a, b) =>
                          (a[sortByNumberOfUsesFieldName]?.length || 0) - (b[sortByNumberOfUsesFieldName]?.length || 0),
                  );

        return filterObject?.sortOrder === SortOrders.Asc ? sortedOptions : sortedOptions.reverse();
    }

    isFiltersApplied<T = DefaultFilterObject>(
        filtersAndSort: T,
        initialFilterObject = initialDefaultFilterObject,
    ): boolean {
        const filtersAndSortToCheck = { ...filtersAndSort, ...initialDefaultCollectionSortObject };

        for (const filtersAndSortField of Object.keys(filtersAndSortToCheck)) {
            const defaultValue = initialFilterObject[filtersAndSortField];
            const isNotInitialValue = filtersAndSortToCheck[filtersAndSortField] !== defaultValue;
            const isNotEmpty = typeof defaultValue !== 'boolean' ? !isEmpty(filtersAndSort[filtersAndSortField]) : true;
            const isFiltersHasValue = isNotInitialValue && isNotEmpty;

            if (isFiltersHasValue) {
                return true;
            }
        }

        return false;
    }

    filterAndSortSessions(
        sessions: Session[],
        filterObject: DefaultFilterObject,
        userSessionsAccess: Dictionary<UserAccessSession>,
        searchValue: string,
    ): Session[] {
        const sortOption = filterObject.sortOption || SortOptions.LastViewed;

        let createdFrom;
        if (filterObject.fromDate) {
            const date = filterObject.fromDate.toDate();
            date.setHours(0, 0, 0);
            createdFrom = Timestamp.fromDate(date);
        }

        let lastUpdateFrom;
        if (filterObject.startDate) {
            const date = filterObject.startDate.toDate();
            date.setHours(0, 0, 0);
            lastUpdateFrom = Timestamp.fromDate(date);
        }

        let createdTo;
        if (filterObject.toDate) {
            const date = filterObject.toDate.toDate();
            date.setHours(23, 59, 59);
            createdTo = Timestamp.fromDate(date);
        }

        let lastUpdateTo;
        if (filterObject.endDate) {
            const date = filterObject.endDate.toDate();
            date.setHours(23, 59, 59);
            lastUpdateTo = Timestamp.fromDate(date);
        }

        let sortedAndFilteredCollection: Session[] = sessions.filter(
            (item: Session) =>
                !(
                    !item.name?.toLowerCase()?.includes(searchValue)
                    || item.created?.valueOf() <= createdFrom?.valueOf()
                    || item.created?.valueOf() >= createdTo?.valueOf()
                    || item.startDate?.valueOf() <= lastUpdateFrom?.valueOf()
                    || item.endDate?.valueOf() >= lastUpdateTo?.valueOf()
                    || (!item.startDate && filterObject.startDate)
                    || (!item.endDate && filterObject.endDate)
                ),
        );

        if (filterObject.roles?.length) {
            sortedAndFilteredCollection = sortedAndFilteredCollection.filter((collectionItem: Session) => {
                return filterObject.roles.includes(userSessionsAccess?.[collectionItem.id]?.role);
            });
        }

        if (filterObject.tags && filterObject.tags.length) {
            sortedAndFilteredCollection = sortedAndFilteredCollection.filter((collectionItem: Session) => {
                const tagsIds = Object.keys(collectionItem?.tags || {});

                return tagsIds.reduce((acc: boolean, id: string) => {
                    if (filterObject.tags.includes(id)) {
                        acc = true;
                    }

                    return acc;
                }, false);
            });
        }

        if (filterObject.phases && filterObject.phases.length) {
            sortedAndFilteredCollection = sortedAndFilteredCollection.filter((collectionItem: Session) => {
                const phasesIds = Object.keys(collectionItem?.phase || {});

                return phasesIds.reduce((acc: boolean, id: string) => {
                    if (filterObject.phases.includes(id)) {
                        acc = true;
                    }

                    return acc;
                }, false);
            });
        }

        if (filterObject.subPhases && filterObject.subPhases.length) {
            sortedAndFilteredCollection = sortedAndFilteredCollection.filter((collectionItem: Session) => {
                const subPhasesIds = Object.keys(collectionItem?.subPhase || {});

                return subPhasesIds.reduce((acc: boolean, id: string) => {
                    if (filterObject.subPhases.includes(id)) {
                        acc = true;
                    }

                    return acc;
                }, false);
            });
        }

        if (filterObject.statuses?.length) {
            sortedAndFilteredCollection = sortedAndFilteredCollection.filter((collectionItem: Session) => {
                return filterObject.statuses.includes(collectionItem?.status);
            });
        }

        switch (sortOption) {
            case SortOptions.Created:
                sortedAndFilteredCollection = sortedAndFilteredCollection.sort(sortByDateAsc('created'));
                break;
            case SortOptions.Updated:
                sortedAndFilteredCollection = sortedAndFilteredCollection.sort(sortByDateAsc('updated'));
                break;
            case SortOptions.StartDate:
                sortedAndFilteredCollection = sortedAndFilteredCollection.sort(
                    sortByDateAndDirection('startDate', filterObject.sortOrder),
                );
                break;
            case SortOptions.EndDate:
                sortedAndFilteredCollection = sortedAndFilteredCollection.sort(
                    sortByDateAndDirection('endDate', filterObject.sortOrder),
                );
                break;
            case SortOptions.Name:
                sortedAndFilteredCollection = sortByAlphabetic(sortedAndFilteredCollection, 'name');
                break;
            case SortOptions.Likes:
                sortedAndFilteredCollection = sortByObjectKeysCountAsc(sortedAndFilteredCollection, 'likes');
                break;
            case SortOptions.LastViewed:
                sortedAndFilteredCollection = sortedAndFilteredCollection.sort(sortByDateAsc('lastViewed'));
                break;
        }

        return filterObject.sortOrder === SortOrders.Dsc
            ? sortedAndFilteredCollection.reverse()
            : sortedAndFilteredCollection;
    }

    filterAndSortUserSessionsCollection(
        userSession: UserSession[],
        filterObject: DefaultFilterObject,
        searchValue: string,
        roleFilter?: string,
    ): UserSession[] {
        const sortOption = filterObject.sortOption || SortOptions.LastViewed;

        let lastUpdateFrom;
        if (filterObject.startDate) {
            const date = filterObject.startDate.toDate();
            date.setHours(0, 0, 0);
            lastUpdateFrom = Timestamp.fromDate(date);
        }

        let lastUpdateTo;
        if (filterObject.endDate) {
            const date = filterObject.endDate.toDate();
            date.setHours(23, 59, 59);
            lastUpdateTo = Timestamp.fromDate(date);
        }

        let sortedAndFilteredSession: UserSession[] = userSession.filter(
            (sessionItem: UserSession) =>
                !(
                    !sessionItem.name?.toLowerCase()?.includes(searchValue)
                    || sessionItem.created?.valueOf() < filterObject.fromDate?.valueOf()
                    || sessionItem.created?.valueOf() > filterObject.toDate?.valueOf()
                    //this might be changed if it will work like fromDate
                    || sessionItem.startDate?.valueOf() <= lastUpdateFrom?.valueOf()
                    || sessionItem.endDate?.valueOf() >= lastUpdateTo?.valueOf()
                    || (!sessionItem.startDate && filterObject.startDate)
                    || (!sessionItem.endDate && filterObject.endDate)
                ),
        );

        if (!roleFilter.includes(SessionFilter.All)) {
            sortedAndFilteredSession = sortedAndFilteredSession.filter((sessionItem: UserSession) => {
                return sessionItem?.role?.includes(roleFilter);
            });
        }

        if (filterObject.tags && filterObject.tags.length) {
            sortedAndFilteredSession = sortedAndFilteredSession.filter((sessionItem: UserSession) => {
                const tagsIds = Object.keys(sessionItem?.tags || {});

                return tagsIds.reduce((acc: boolean, id: string) => {
                    if (filterObject.tags.includes(id)) {
                        acc = true;
                    }

                    return acc;
                }, false);
            });
        }

        if (filterObject.phases && filterObject.phases.length) {
            sortedAndFilteredSession = sortedAndFilteredSession.filter((sessionItem: UserSession) => {
                const phasesIds = Object.keys(sessionItem?.phase || {});

                return phasesIds.reduce((acc: boolean, id: string) => {
                    if (filterObject.phases.includes(id)) {
                        acc = true;
                    }

                    return acc;
                }, false);
            });
        }

        if (filterObject.subPhases && filterObject.subPhases.length) {
            sortedAndFilteredSession = sortedAndFilteredSession.filter((sessionItem: UserSession) => {
                const subPhasesIds = Object.keys(sessionItem?.subPhase || {});

                return subPhasesIds.reduce((acc: boolean, id: string) => {
                    if (filterObject.subPhases.includes(id)) {
                        acc = true;
                    }

                    return acc;
                }, false);
            });
        }

        if (filterObject.clientNames && filterObject.clientNames.length) {
            sortedAndFilteredSession = sortedAndFilteredSession.filter((sessionItem: UserSession) => {
                return filterObject.clientNames.includes(sessionItem?.clientId);
            });
        }

        if (filterObject.projectNames && filterObject.projectNames.length) {
            sortedAndFilteredSession = sortedAndFilteredSession.filter((sessionItem: UserSession) => {
                return filterObject.projectNames.includes(sessionItem?.projectId);
            });
        }

        switch (sortOption) {
            case SortOptions.Name:
                sortedAndFilteredSession = sortByAlphabetic(sortedAndFilteredSession, 'name');
                break;
            case SortOptions.Created:
                sortedAndFilteredSession = sortedAndFilteredSession.sort(sortByDateAsc('created'));
                break;
            case SortOptions.Updated:
                sortedAndFilteredSession = sortedAndFilteredSession.sort(sortByDateAsc('updated'));
                break;
            case SortOptions.StartDate:
                sortedAndFilteredSession = sortedAndFilteredSession.sort(
                    sortByDateAndDirection('startDate', filterObject.sortOrder),
                );
                break;
            case SortOptions.EndDate:
                sortedAndFilteredSession = sortedAndFilteredSession.sort(
                    sortByDateAndDirection('endDate', filterObject.sortOrder),
                );
                break;
            case SortOptions.LastViewed:
                sortedAndFilteredSession = sortedAndFilteredSession.sort(sortByDateAsc('lastViewed'));
                break;
        }
        return filterObject.sortOrder === SortOrders.Dsc
            ? sortedAndFilteredSession.reverse()
            : sortedAndFilteredSession;
    }

    filterAndSortSessionsInAdministration(
        sessions: Session[],
        filterObject: DefaultFilterObject,
        searchValue: string,
    ): Session[] {
        const sortOption = filterObject.sortOption || SortOptions.LastViewed;

        let createdFrom;
        if (filterObject.fromDate) {
            const date = filterObject.fromDate.toDate();
            date.setHours(0, 0, 0);
            createdFrom = Timestamp.fromDate(date);
        }

        let lastUpdateFrom;
        if (filterObject.startDate) {
            const date = filterObject.startDate.toDate();
            date.setHours(0, 0, 0);
            lastUpdateFrom = Timestamp.fromDate(date);
        }

        let createdTo;
        if (filterObject.toDate) {
            const date = filterObject.toDate.toDate();
            date.setHours(23, 59, 59);
            createdTo = Timestamp.fromDate(date);
        }

        let lastUpdateTo;
        if (filterObject.endDate) {
            const date = filterObject.endDate.toDate();
            date.setHours(23, 59, 59);
            lastUpdateTo = Timestamp.fromDate(date);
        }

        let sortedAndFilteredCollection: Session[] = sessions.filter(
            (item: Session) =>
                !(
                    !item.name?.toLowerCase()?.includes(searchValue)
                    || item.created?.valueOf() <= createdFrom?.valueOf()
                    || item.created?.valueOf() >= createdTo?.valueOf()
                    || item.updated?.valueOf() <= lastUpdateFrom?.valueOf()
                    || item.updated?.valueOf() >= lastUpdateTo?.valueOf()
                ),
        );

        if (filterObject.tags && filterObject.tags.length) {
            sortedAndFilteredCollection = sortedAndFilteredCollection.filter((collectionItem: Session) => {
                const tagsIds = Object.keys(collectionItem?.tags || {});

                return tagsIds.reduce((acc: boolean, id: string) => {
                    if (filterObject.tags.includes(id)) {
                        acc = true;
                    }

                    return acc;
                }, false);
            });
        }

        if (filterObject.statuses?.length) {
            sortedAndFilteredCollection = sortedAndFilteredCollection.filter((collectionItem: Session) => {
                return filterObject.statuses.includes(collectionItem?.status);
            });
        }

        switch (sortOption) {
            case SortOptions.Created:
                sortedAndFilteredCollection = sortedAndFilteredCollection.sort(sortByDateAsc('created'));
                break;
            case SortOptions.Updated:
                sortedAndFilteredCollection = sortedAndFilteredCollection.sort(sortByDateAsc('updated'));
                break;
            case SortOptions.Name:
                sortedAndFilteredCollection = sortByAlphabetic(sortedAndFilteredCollection, 'name');
                break;
        }

        return filterObject.sortOrder === SortOrders.Dsc
            ? sortedAndFilteredCollection.reverse()
            : sortedAndFilteredCollection;
    }

    filterAndSortUserSession(
        collection: (UserAccessSession | Session)[],
        filterObject: DefaultFilterObject,
        searchValue: string,
    ): (UserAccessSession | Session)[] {
        let sortedAndFilteredCollection: (UserAccessSession | Session)[] = collection.filter((collectionItem) => {
            return (
                collectionItem.name?.toLowerCase()?.includes(searchValue)
                || collectionItem.id?.toLowerCase()?.includes(searchValue)
            );
        });

        if (filterObject.roles?.length) {
            sortedAndFilteredCollection = sortedAndFilteredCollection.filter((collectionItem: UserAccessSession) => {
                return filterObject.roles.includes(collectionItem.role);
            });
        }

        sortedAndFilteredCollection = sortByAlphabetic(sortedAndFilteredCollection, 'name');

        return filterObject.sortOrder === SortOrders.Dsc
            ? sortedAndFilteredCollection.reverse()
            : sortedAndFilteredCollection;
    }

    filterAndSortCollections(
        collection: UserCollection[],
        filterObject: DefaultFilterObject,
        searchValue: string,
    ): UserCollection[] {
        const sortOption = filterObject.sortOption || SortOptions.LastViewed;
        let sortedAndFilteredCollection: UserCollection[] = collection.filter(
            (collectionItem) =>
                !(
                    !collectionItem.name?.toLowerCase()?.includes(searchValue)
                    || collectionItem.created?.valueOf() < filterObject.fromDate?.valueOf()
                    || collectionItem.created?.valueOf() > filterObject.toDate?.valueOf()
                ),
        );
        switch (sortOption) {
            case SortOptions.Created:
                sortedAndFilteredCollection = sortedAndFilteredCollection.sort(sortByDateAsc('created'));
                break;
            case SortOptions.Updated:
                sortedAndFilteredCollection = sortedAndFilteredCollection.sort(sortByDateAsc('updated'));
                break;
            case SortOptions.Name:
                sortedAndFilteredCollection = sortByAlphabetic(sortedAndFilteredCollection, 'name');
                break;
            case SortOptions.LastViewed:
                sortedAndFilteredCollection = sortedAndFilteredCollection.sort(sortByDateAsc('lastViewed'));
                break;
        }
        return filterObject.sortOrder === SortOrders.Dsc
            ? sortedAndFilteredCollection.reverse()
            : sortedAndFilteredCollection;
    }

    private isPublicTemplate(collectionItem: FavoriteAccess): boolean {
        return publicParentTypes.includes(collectionItem.templateType);
    }

    private showTemplateCollaborators(
        roleFilter: (TemplateRole | PublicTemplateRoleForFilter)[],
        collectionItem: FavoriteAccess,
        userId: string,
    ): boolean {
        if (!roleFilter.includes(TemplateRole.Collaborator)) {
            return false;
        }

        if (this.isPublicTemplate(collectionItem)) {
            return collectionItem.teamMembersIds?.includes(userId);
        }

        return collectionItem.role === TemplateRole.Collaborator;
    }

    private showTemplateViewers(
        roleFilter: (TemplateRole | PublicTemplateRoleForFilter)[],
        collectionItem: FavoriteAccess,
        userId: string,
    ): boolean {
        if (!roleFilter.includes(PublicTemplateRoleForFilter.Viewer)) {
            return false;
        }

        return (
            collectionItem.ownerId !== userId
            && !collectionItem.teamMembersIds?.includes(userId)
            && !collectionItem.role
        );
    }

    // filter data to show results with submittedSearchValue in search elements and
    // results that are owned by the user if isOwned is true
    filterSearch(data: any[], isOwned: boolean, submittedSearchValue: string, userId: string): any[] {
        const filteredData = data
            .filter((d) => {
                if (!isOwned) {
                    return true; // return unfiltered array
                }
                return d?.creatorId === userId || d?.ownerId === userId;
            })
            .filter((item) => {
                // search elements
                if (
                    item?.name?.toLowerCase().includes(submittedSearchValue.toLowerCase())
                    || item?.description?.toLowerCase().includes(submittedSearchValue.toLowerCase())
                    || item?.creatorName?.toLowerCase().includes(submittedSearchValue.toLowerCase())
                    || item?.ownerDisplayName?.toLowerCase().includes(submittedSearchValue.toLowerCase())
                    || (item?.phase && this.getDictionaryValueBySearch(item.phase, submittedSearchValue))
                    || (item?.subPhase && this.getDictionaryValueBySearch(item.subPhase, submittedSearchValue))
                    || (item?.client && this.getDictionaryValueBySearch(item.client, submittedSearchValue))
                    || (item?.project && this.getDictionaryValueBySearch(item.project, submittedSearchValue))
                ) {
                    return item;
                }
            });
        return filteredData;
    }

    // check if value of data matches/includes submittedSearchValue
    private getDictionaryValueBySearch(keyValPair: Dictionary<string>, submittedSearchValue: string): boolean {
        return Object.values(keyValPair).some((value: string) => {
            if (value.toLowerCase().includes(submittedSearchValue.toLowerCase())) {
                return value;
            }
        });
    }
}
