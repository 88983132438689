import { inject, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

import { AttributeService } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectActivityIdAndParentIds, selectAttributeClasses } from '@accenture/global-store';
import { Attribute, AttributeClass, ParentType } from '@accenture/shared/data';
import { ConfirmationDialogComponent, DialogService } from '@accenture/shared/ui';
import { SequenceGeneration } from '@accenture/shared/util';

import { AttributesClassEditDialogComponent } from '../attributes-edit-dialog/attributes-edit-dialog.component';
import {
    deleteAttributeGroup,
    editAttributeGroupText,
    newAttributeGroupText,
} from '../attributes-edit-dialog/constants';

export interface AttributesEditorViewModel {
    attributeClasses: AttributeClass[];
    activeAttributeClassId?: string;
    searchValue: string;
    activeAttributes: Attribute[];
    defaultAttributeClasses: any;
    isLoading: boolean;
}

const defaultViewModel = {
    attributeClasses: [],
    activeAttributeClassId: undefined,
    searchValue: '',
    activeAttributes: [],
    defaultAttributeClasses: [],
    isLoading: true,
};

@UntilDestroy()
@Injectable()
export class AttributesEditorFacade {
    vm$ = this.buildViewModel();

    private isLoading$ = new BehaviorSubject<boolean>(false);
    private parentType!: ParentType;
    private parentId!: string;
    private searchValue$ = new BehaviorSubject<string>('');

    private attributeService = inject(AttributeService);
    private dialogService = inject(DialogService);

    afterCreateAttributeModalWasClosed$ = new Subject<boolean>();

    constructor(private store: Store<AppState>) {}

    async addDefaultAttributeClass(attributeClass: AttributeClass, lastSequence: string | null): Promise<void> {
        const sequence = lastSequence ? SequenceGeneration.afterLast(lastSequence) : SequenceGeneration.initial();

        await this.attributeService.addDefaultAttributeClass(this.parentType, this.parentId, {
            ...attributeClass,
            sequence,
        });
    }

    openEditAttributeDialog(attributeClassId: string | null, lastSequence: string | null): void {
        this.dialogService
            .open(AttributesClassEditDialogComponent, {
                attributeClassId: attributeClassId || null,
                title: attributeClassId ? editAttributeGroupText.name : newAttributeGroupText.title,
                lastSequence,
                width: '768px',
                panelClass: 'tt9-modal',
            })
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe(() => this.afterCreateAttributeModalWasClosed$.next(true));
    }

    openDeleteAttributeClassConfirmationDialog(attributeClassId: string): void {
        this.dialogService.open(ConfirmationDialogComponent, {
            title: deleteAttributeGroup.title,
            confirmBtnText: 'Delete',
            cancelBtnText: 'Cancel',
            width: '444px',
            isWarning: true,
            text: deleteAttributeGroup.text,
            confirm: () => this.deleteAttributeClass(attributeClassId),
        });
    }

    setActiveClass(classId: string): void {
        this.attributeService.setActiveClass(classId);
        this.filterAttributes('');
    }

    filterAttributes(searchValue: string): void {
        this.searchValue$.next(searchValue);
    }

    closeDialog(): void {
        this.dialogService.close();
    }

    private buildViewModel(): Observable<AttributesEditorViewModel> {
        return this.store.select(selectActivityIdAndParentIds).pipe(
            switchMap(({ parentType, parentId }) => {
                this.parentType = parentType;
                this.parentId = parentId;
                return combineLatest([
                    this.attributeService.getAttributeClasses(ParentType.Sessions, this.parentId),
                    this.attributeService.activeAttributeClassId$.asObservable(),
                    this.attributeService.getAttributes(ParentType.Sessions, this.parentId),
                    this.getSearchValue(),
                    this.store.select(selectAttributeClasses),
                    this.isLoading$,
                ]);
            }),
            map(
                ([
                    attributeClasses,
                    activeAttributeClassId,
                    activeAttributes,
                    searchValue,
                    defaultAttributeClasses,
                    isLoading,
                ]) => {
                    const attributeClassesIds = attributeClasses.map((attributeClass) => attributeClass.id);
                    const filteredDefaultAttributeClasses = (defaultAttributeClasses || []).filter(
                        (defaultAttributeClass) => !attributeClassesIds.includes(defaultAttributeClass.id),
                    );
                    if (!activeAttributeClassId) {
                        activeAttributeClassId = attributeClasses[0]?.id;
                    }
                    const filteredAttributes = activeAttributes.filter(
                        (attribute) =>
                            attribute.classId === activeAttributeClassId
                            && attribute.name.toLowerCase().includes(searchValue),
                    );

                    return {
                        attributeClasses,
                        activeAttributeClassId,
                        searchValue,
                        isLoading,
                        activeAttributes: filteredAttributes,
                        defaultAttributeClasses: filteredDefaultAttributeClasses,
                    };
                },
            ),
            startWith(defaultViewModel),
        );
    }

    private async deleteAttributeClass(attributeClassId: string): Promise<void> {
        this.isLoading$.next(true);
        await this.attributeService.deleteAttributeClass(this.parentType, this.parentId, attributeClassId);
        if (this.attributeService.activeAttributeClassId$.value === attributeClassId) {
            this.setActiveClass(null);
            this.afterCreateAttributeModalWasClosed$.next(false);
        }
        this.isLoading$.next(false);
    }

    private getSearchValue(): Observable<string> {
        return this.searchValue$.asObservable().pipe(map((value: string) => value.trim().toLowerCase()));
    }
}
