import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataFlowConnection, DBPathHelper, Dictionary, ParentType, VoteSummaryResponse } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import { sortByDateAsc } from '@accenture/shared/util';

@Injectable({
    providedIn: 'root',
})
export class DataFlowService {
    constructor(private firestoreService: FirestoreService) {}

    getDataFlowConnectionsBySourceActivityId(
        parentType: ParentType,
        parentId: string,
        activityId: string,
    ): Observable<DataFlowConnection[]> {
        const path = DBPathHelper.getDataFlowConnectionPath(parentType, parentId);
        return this.firestoreService.getDocumentsByArrayContains(path, 'sourceIds', activityId);
    }

    getDataFlowConnectionsByProperty(
        parentType: ParentType,
        parentId: string,
        propertyName: string,
        propertyValue: string,
    ): Observable<DataFlowConnection[]> {
        return this.firestoreService.getDocumentsByProperty(
            DBPathHelper.getDataFlowConnectionPath(parentType, parentId),
            propertyName,
            propertyValue,
        );
    }

    getTemplateDataFlowConnections(parentType: ParentType, parentId: string): Observable<DataFlowConnection[]> {
        return this.firestoreService
            .getCollection<DataFlowConnection>(DBPathHelper.getDataFlowConnectionPath(parentType, parentId))
            .pipe(
                map((items: DataFlowConnection[]) =>
                    items.map((item) => new DataFlowConnection(item)).sort(sortByDateAsc('updated')),
                ),
            );
    }

    // TODO: Delete after project deprecation
    getProjectDataFlowConnections(projectId: string): Observable<DataFlowConnection[]> {
        return this.firestoreService.getCollection<DataFlowConnection>(`projects/${projectId}/dataFlowConnections`);
    }

    getDataFlowConnections(parentType: ParentType, parentId: string): Observable<DataFlowConnection[]> {
        return this.firestoreService.getCollection<DataFlowConnection>(
            DBPathHelper.getDataFlowConnectionPath(parentType, parentId),
        );
    }

    // TODO: Delete after project deprecation
    async runDataFlow(
        activityId: string,
        projectId: string,
        summaryResponses?: Dictionary<Dictionary<Dictionary<VoteSummaryResponse | undefined>>>,
    ): Promise<Record<string, unknown>> {
        return this.firestoreService.cloudFunctionCallable('runDataFlow', {
            activityId,
            projectId,
            summaryResponses,
        });
    }

    async runDataFlowNew(
        activityId: string,
        sessionId: string,
        summaryResponses?: Dictionary<Dictionary<Dictionary<VoteSummaryResponse | undefined>>>,
    ): Promise<Record<string, unknown>> {
        return this.firestoreService.cloudFunctionCallable('runDataFlowNew', {
            activityId,
            sessionId,
            summaryResponses,
        });
    }

    async updateBatchDataFlow(parentType: ParentType, parentId: string, dataFlow: DataFlowConnection[]): Promise<void> {
        const dataToWrite = dataFlow.reduce((acc, item) => {
            const id = this.firestoreService.getPushId();
            const data = {
                ...item,
                updated: this.firestoreService.timestamp,
                created: item.created || this.firestoreService.timestamp,
            };
            acc.push({
                path: DBPathHelper.getDataFlowConnectionPath(parentType, parentId, id),
                data,
            });
            return acc;
        }, []);

        await this.firestoreService.writeBatch(dataToWrite);
    }

    async deleteDataFlowConnection(connectionId: string, parentType: ParentType, parentId: string): Promise<void> {
        await this.firestoreService.delete(DBPathHelper.getDataFlowConnectionPath(parentType, parentId, connectionId));
    }

    async deleteDataFlowConnections(connectionIds: string[], parentType: ParentType, parentId: string): Promise<void> {
        const batchData = [];

        connectionIds.forEach((id: string) => {
            batchData.push({ path: DBPathHelper.getDataFlowConnectionPath(parentType, parentId, id) });
        });

        await this.firestoreService.deleteBatch(batchData);
    }
}
