export enum DropContainers {
    Response = 'response',
    Question = 'question',
    Group = 'group',
    ResponsesContainer = 'responsesContainer',
}

export const hintsForSingleResponse = {
    [DropContainers.Response]: 'Drop to make it a Comment',
    [DropContainers.Question]: 'Drop to move as a Response',
    [DropContainers.Group]: 'Drop to group it',
};

export const hintsForMultipleResponses = {
    [DropContainers.Response]: 'Drop to make them Comments',
    [DropContainers.Question]: 'Drop to move as Responses',
    [DropContainers.Group]: 'Drop to group them',
};

export enum BrainstormDragDataType {
    Response = 'RESPONSE',
    Comment = 'COMMENT',
}

export interface BrainstormDragData {
    type: BrainstormDragDataType;
    data: Set<string>;
}

export const brainstormSnackBarTitles = {
    [BrainstormDragDataType.Response]: 'You can drag responses to',
    [BrainstormDragDataType.Comment]: 'You can drag comments to',
};

export const dragHintsForResponses = [
    'another response as comments',
    'another topic as responses',
    'a group to tag them',
];

export const dragHintsForComments = ['another response as comments', 'empty space as new responses'];

export const hintsForSingleComment = {
    [DropContainers.Response]: 'Drop to make it a comment',
    [DropContainers.ResponsesContainer]: 'Drop to create a new response',
};

export const hintsForMultipleComments = {
    [DropContainers.Response]: 'Drop to make them comments',
    [DropContainers.ResponsesContainer]: 'Drop to create new responses',
};
