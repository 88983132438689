import { Message } from '@progress/kendo-angular-conversational-ui';
import { Timestamp } from 'firebase/firestore';

import { BotContext } from './user';

export enum BotChatExchangeTypes {
    Bot = 'BOT',
    User = 'USER',
    BluePrint = 'BLUEPRINT',
    Notes = 'NOTES',
    Notification = 'NOTIFICATION',
}

export interface BotChatExchange {
    id: string;
    type: BotChatExchangeTypes;
    message: string;
    created: Timestamp;
    context?: BotContext;
    action?: string;
    blueprint: any;
    prompt: string;
    attachmentId?: string;
}

export type BotMessage = Message & BotChatExchange;

export interface BotPrompt {
    text: string;
    greeting: string;
    action: string;
}

export enum BotActions {
    Message = 'MESSAGE',
    GenerateProject = 'GENERATE_PROJECT',
    GenerateSession = 'GENERATE_SESSION',
    GenerateActivity = 'GENERATE_ACTIVITY',
    DataFlow = 'DATA_FLOW',
    CreateProject = 'CREATE_PROJECT',
    CreateSession = 'CREATE_SESSION',
    CreateActivity = 'CREATE_ACTIVITY',
    CreateActivityItem = 'CREATE_ACTIVITY_ITEM',
    SummarizeProject = 'SUMMARIZE_PROJECT',
    SummarizeSession = 'SUMMARIZE_SESSION',
    SummarizeActivity = 'SUMMARIZE_ACTIVITY',
    FacilitateSession = 'FACILITATE_SESSION',
    FacilitateActivity = 'FACILITATE_ACTIVITY',
    Zap = 'ZAP',
    Information = 'INFORMATION',
    FocusActivity = 'FOCUS_ACTIVITY',
    Help = 'HELP',
    Embed = 'EMBED_FILE',
    Transcribe = 'TRANSCRIBE',
    PlotChart = 'PLOT_CHART',
    BrainstormGroupResponses = 'BRAINSTORM_GROUP_RESPONSES',
    BrainstormMergeResponses = 'BRAINSTORM_MERGE_RESPONSES',
    PresentAnalyzeSlide = 'PRESENT_ANALYZE_SLIDE',
    Navigate = 'NAVIGATE',
    Search = 'SEARCH',
    ThreadPost = 'SESSION_THREAD_POST',
    Invite = 'INVITE_USER',
}

export const BotSuggestedActions = {
    [BotActions.CreateProject]: {
        value: 'Create Project!',
        type: BotActions.CreateProject,
    },
    [BotActions.GenerateProject]: {
        value: 'Generate Again',
        type: BotActions.GenerateProject,
    },
    [BotActions.CreateSession]: {
        value: 'Create Session',
        type: BotActions.CreateSession,
    },
    [BotActions.GenerateSession]: {
        value: 'Generate Again',
        type: BotActions.GenerateSession,
    },
    [BotActions.CreateActivity]: {
        value: 'Create Activity',
        type: BotActions.CreateActivity,
    },
    [BotActions.GenerateActivity]: {
        value: 'Generate Again',
        type: BotActions.GenerateActivity,
    },
};

export interface BotChatResponse {
    message?: string;
    speak?: string;
    navigateTo?: BotContext;
    notification?: string;
}

export enum BuilderStep {
    Entry = 'ENTRY',
    Preview = 'PREVIEW',
}

export const builderSteps = [
    {
        id: BuilderStep.Entry,
        label: 'Prompt Entry',
    },
    {
        id: BuilderStep.Preview,
        label: 'Preview',
    },
];

export const aiBuilderReturnText = {
    title: 'Return to Prompt Entry',
    text: 'Are you sure you want to return to the Prompt Entry? The generated {type} will not be saved, so you will have to specify prompts again.',
};

export const aiBuilderCloseText = {
    title: 'Close window',
    text: 'Are you sure you want to close this window? The generated {type} will not be saved.',
};

export const aiBuilderSessionPlaceholders = {
    aiGenerateObjectives: {
        subTitle: 'What is the main goal or purpose of your session? What specific outcomes do you want to achieve?',
        placeholder:
            'For example: this is a strategic planning initiative and we are looking to capture ideas and align key stakeholders on the strategy for next fiscal year.',
    },

    aiGenerateAudience: {
        subTitle: 'Who is the audience for this session? How many activities do you need for the session?',
        placeholder:
            'For example: the audience is finance stakeholders at a large life sciences organization. We envision 4 different sessions as part of this project – one for North America, EMEA. APAC and Global.',
    },
    aiGenerateAgenda: {
        subTitle: 'How do you want your session to flow? What activity types would you like to use? ',
        placeholder:
            'For example: I would like to start with an overview presentation then move to a brainstorm to capture new revenue generating opportunities. Finally, I would like to prioritize those opportunities using a vote and end the session by capturing any actions items and key decisions in a table.',
    },
};

export const aiBuilderProjectPlaceholders = {
    aiGenerateObjectives: {
        subTitle: 'What is the main goal or purpose of your project? What specific outcomes do you want to achieve?',
        placeholder:
            'For example: this is a strategic planning initiative and we are looking to capture ideas and align key stakeholders on the strategy for next fiscal year.',
    },
    aiGenerateAudience: {
        subTitle: 'Who is the audience for this project? How many discrete sessions do you need for the project?',
        placeholder:
            'For example: the audience is finance stakeholders at a large life sciences organization. We envision 4 different sessions as part of this project – one for North America, EMEA. APAC and Global.',
    },
    aiGenerateAgenda: {
        subTitle: 'How do you want your session(s) to flow? What activity types would you like to use?',
        placeholder:
            'For example: I would like to start with an overview presentation then move to a brainstorm to capture new revenue generating opportunities. Finally, I would like to prioritize those opportunities using a vote and end the session by capturing any actions items and key decisions in a table.',
    },
};

export const betaHint
    = 'ThinkTank’s AI Project & Session Builder is currently in Beta. We look forward to your feedback to continue to enhance and fully release this capability. Please be as detailed as possible to enhance the accuracy of the generated content.';

export const betaHintActivity
    = 'Thinktank’s AI Activity Builder is currently in Beta.  We look forward to your feedback to continue to enhance and fully release this capability. Please be as detailed as possible to enhance the accuracy of the generated content.';
