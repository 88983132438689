import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, tap } from 'rxjs';

import { fromToDateValidator } from '@accenture/erp-deployment/shared/domain';
import {
    characterLimitMedium,
    characterLimitText,
    characterLimitXL,
    determineHintClass,
    FileType,
    inputPlaceholders,
    SessionOptions,
    sessionOptionsDialogTitle,
    validationErrors,
    validationMessages,
} from '@accenture/shared/data';
import { ImageInputMode, ImageInputPlaceholdersTypes } from '@accenture/shared/ui';

import { EditSessionDialogFacade, EditSessionDialogViewModel } from './edit-session-dialog-facade';

type SessionEditForm = FormGroup<{
    sessionName: FormControl<string>;
    sessionDescription: FormControl<string>;
    startDate: FormControl<Date | null>;
    endDate: FormControl<Date | null>;
}>;

@Component({
    selector: 'accenture-edit-session-dialog',
    templateUrl: './edit-session-dialog.component.html',
    styleUrls: ['./edit-session-dialog.component.scss'],
    providers: [EditSessionDialogFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditSessionDialogComponent implements OnDestroy {
    vm$: Observable<EditSessionDialogViewModel> = this.facade.vm$.pipe(
        tap(({ session, updateSessionEvent }) => {
            if (updateSessionEvent && session?.id) {
                this.sessionEditForm.patchValue({
                    sessionName: session.name,
                    sessionDescription: session.description,
                    startDate: session.startDate?.toDate(),
                    endDate: session.endDate?.toDate(),
                });
                this.facade.sessionWasUpdated();
            }
        }),
    );

    sessionEditForm: SessionEditForm = this.formBuilder.group(
        {
            sessionName: new FormControl('', { nonNullable: true, validators: Validators.required }),
            sessionDescription: new FormControl('', { nonNullable: true }),
            startDate: new FormControl(null),
            endDate: new FormControl(null),
        },
        {
            updateOn: 'change',
            validators: fromToDateValidator('startDate', 'endDate'),
        },
    );

    imageInputMode = ImageInputMode.Default;
    imageInputPlaceholder = ImageInputPlaceholdersTypes.Session;

    validationErrors = validationErrors;
    validationMessages = validationMessages;
    inputPlaceholders = inputPlaceholders;

    characterLimitMedium = characterLimitMedium;
    descriptionCharacterLimit = characterLimitXL;
    characterLimitText = characterLimitText;
    determineHintClass = determineHintClass;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string;
            cancelButtonText: string;
            cancelButtonIcon?: string;
            backToSelectSourceTypeDialog: boolean;
        },
        private facade: EditSessionDialogFacade,
        private formBuilder: FormBuilder,
    ) {}

    get sessionNameControl(): FormControl<string> {
        return this.sessionEditForm.controls.sessionName;
    }

    get sessionDescriptionControl(): FormControl<string> {
        return this.sessionEditForm.controls.sessionDescription;
    }

    get sessionStartDateControl(): FormControl<Date | null> {
        return this.sessionEditForm.controls.startDate;
    }

    get sessionEndDateControl(): FormControl<Date | null> {
        return this.sessionEditForm.controls.endDate;
    }

    get isEndDateGreaterStartDateErrorVisibility(): boolean {
        const toDateControl = this.sessionEditForm && this.sessionEndDateControl;
        return toDateControl && toDateControl.hasError('invalidDate');
    }

    clearDateInput(controlName: string, event: Event): void {
        event.stopPropagation();
        this.sessionEditForm.get(controlName).reset();
    }

    getTitleToDisplay(currentOptionToDisplay: SessionOptions | null): string {
        return currentOptionToDisplay ? sessionOptionsDialogTitle[currentOptionToDisplay] : this.data.title;
    }

    setSessionImage(sessionImage: FileType): void {
        this.facade.setSessionImage(sessionImage);
    }

    deleteSessionImage(): void {
        const sessionImage = {} as FileType;

        this.facade.setSessionImage(sessionImage);
    }

    createOrUpdateSession(name: string, description: string, startDate: Date, endDate: Date): void {
        if (startDate || endDate) {
            this.facade.createOrUpdateSession({ name, description, startDate, endDate });
        } else {
            this.facade.createOrUpdateSession({ name, description });
        }
    }

    backToSessionDialog(): void {
        this.facade.backToSessionDialog();
    }

    closeDialog(): void {
        this.facade.closeDialog(this.data.backToSelectSourceTypeDialog);
    }

    updateSessionForm(): void {
        this.facade.updateSessionForm();
    }

    ngOnDestroy(): void {
        this.facade.resetOptions();
    }
}
