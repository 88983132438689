import { removeEmptyKeys } from '@accenture/shared/util';

import { SessionRole } from '../access-control';
import { Session } from '../session';
import { User } from '../user';
import { InAppNotificationType } from './in-app-notification-type';
import { BaseInAppNotification } from './in-app-notifications';

export class InAppSessionInviteNotification extends BaseInAppNotification {
    projectId: string;
    sessionId: string;
    sessionName: string;
    invitingUserId?: string;
    invitingUserDisplayName: string;
    role: SessionRole;

    constructor(notification: any);
    constructor(userId: string, projectId: string, session: Session, role: SessionRole, user: Partial<User>);
    constructor(
        notificationOrUserId: any,
        projectId?: string,
        session?: Session,
        role?: SessionRole,
        user?: Partial<User>,
    ) {
        if (typeof notificationOrUserId === 'object') {
            super(notificationOrUserId as BaseInAppNotification);

            this.id = notificationOrUserId.id;
            this.type = InAppNotificationType.SessionInvite;
            this.projectId = notificationOrUserId.projectId;
            this.sessionId = notificationOrUserId.sessionId;
            this.sessionName = notificationOrUserId.sessionName;
            this.invitingUserId = notificationOrUserId.invitingUserId;
            this.invitingUserDisplayName = notificationOrUserId.invitingUserDisplayName;
            this.role = notificationOrUserId.role;
        } else {
            super(InAppNotificationType.SessionInvite, notificationOrUserId);

            this.projectId = projectId;
            this.sessionId = session.id;
            this.sessionName = session.name;
            this.invitingUserId = user.id;
            this.invitingUserDisplayName = user.displayName;
            this.role = role;
        }
    }

    createSerializableObject(): Record<string, unknown> {
        return removeEmptyKeys({
            type: this.type,
            userId: this.userId,
            unread: this.unread,
            projectId: this.projectId,
            sessionId: this.sessionId,
            sessionName: this.sessionName,
            invitingUserId: this.invitingUserId,
            invitingUserDisplayName: this.invitingUserDisplayName,
            role: this.role,
            created: this.created,
            updated: this.updated,
        });
    }
}
