<div class="dialog-header">
  <div class="d-flex">
    <p class="tt9-h1 text-overflow-ellipsis-1">{{ data.title }}</p>
    <mat-chip *ngIf="data.isGenerateActivity" class="tt9-tag-chip info-chip" matTooltipClass="tt9-arrow-tooltip-above" [matTooltip]="betaHintActivity">
      BETA
    </mat-chip>
  </div>
  <button class="tt9-btn-icon-filled" (click)="close()">
    <accenture-icon>icon-close</accenture-icon>
  </button>
</div>

  <div class="d-flex-column">
    <div class="p-32" [formGroup]="aiPromptsForm">
      <p class="tt9-body-2 tt9-color-neutral-400 mb-8">{{ headerActivity }}</p>
      <mat-form-field appearance="outline" class="mat-form-field tt9-form-field textarea width-100">
        <textarea
          matInput
          #userInput
          cdkTextareaAutosize
          [formControlName]="data.isGenerateActivity ? 'generateActivityControl' : 'summarizeControl'"
          #autosize="cdkTextareaAutosize"
          rows="3"
          cdkAutosizeMaxRows="5"
          type="text"
          [placeholder]="placeholderPromptText"
          class="summary-textarea"
          [maxLength]="characterLimitLarge"
          [ngClass]="{
            'max-length': characterLimitLarge === userInput.value.length
          }"
        ></textarea>
        <mat-hint
          class="mat-hint-limit tt9-helper-text"
          [class]="determineHintClass(userInput.value.length, characterLimitLarge)"
          >{{ characterLimitText(userInput.value.length, characterLimitLarge) }}
        </mat-hint>
        <mat-error>{{ validationMessages.required }}</mat-error>
      </mat-form-field>
    </div>
    <div class="footer d-flex-end">
      <div class="d-flex">
        <button *ngIf="data.isGenerateActivity" class="tt9-btn-default btn-icon mr-8" (click)="close()">
          <accenture-icon>icon-chevron-left-small</accenture-icon>
          <span>Back</span>
        </button>
        <button class="tt9-btn" (click)="accept(userInput.value)" [disabled]="!aiPromptsForm.valid && data.isGenerateActivity">
          <span>{{ data.confirm || "Generate" }}</span>
        </button>
      </div>
    </div>
  </div>
