import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { InvitedUser } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable({
    providedIn: 'root',
})
export class InvitedUsersService {
    constructor(private firestoreService: FirestoreService) {}

    getInvitedUsersEmails(userId: string): Observable<string[]> {
        return this.firestoreService
            .getDocumentsByPropertyWithoutCaching<InvitedUser>('invitedUsers', 'invitingUserId', userId)
            .pipe(map(invitedUsers => invitedUsers.map(user => user.email)));
    }

    setInvitedUsers(invitedUsers: InvitedUser[]): Promise<void> {
        const batchData: { path: string; data: InvitedUser }[] = [];

        for (const invitedUser of invitedUsers) {
            batchData.push({
                path: `invitedUsers/${this.firestoreService.getPushId()}`,
                data: invitedUser,
            });
        }

        return this.firestoreService.writeBatch(batchData);
    }
}
