export enum LinkAccessUrl {
    Session = 'join-session',
    Activity = 'join-activity',
    ProjectTemplate = 'create-project',
    SessionTemplate = 'create-session',
}

export const linkAccessUrls = [
    LinkAccessUrl.Session,
    LinkAccessUrl.Activity,
    LinkAccessUrl.ProjectTemplate,
    LinkAccessUrl.SessionTemplate,
];
