import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';

import {
    FavoriteTemplateService,
    FiltersService,
    PublicTemplateService,
} from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    selectAuthenticatedUser,
    selectRouterQueryParams,
} from '@accenture/global-store';
import { TemplatesTab, User } from '@accenture/shared/data';
export interface TemplatesViewModel {
    user: User;
    activeTemplateTab: TemplatesTab;
    publicTemplatesLength: number;
    favoriteTemplatesLength: number;
    isLoading: boolean;
}

const defaultViewModel = {
    user: {} as User,
    activeTemplateTab: null,
    publicTemplatesLength: 0,
    favoriteTemplatesLength: 0,
    isLoading: true,
} as TemplatesViewModel;

@Injectable()
export class TemplatesFacade {
    vm$ = this.buildViewModel();

    constructor(
        private store: Store<AppState>,
        private publicTemplateService: PublicTemplateService,
        private favoriteTemplateService: FavoriteTemplateService,
        private filtersService: FiltersService,
        private router: Router,
    ) {}

    setTemplatesTab(templatesTab: TemplatesTab): void {
        this.filtersService.templatesViewTab$.next(templatesTab);
        this.router.navigate(['/home'], { queryParams: { templatesTab }, queryParamsHandling: 'merge' });
        return;
    }

    private buildViewModel(): Observable<TemplatesViewModel> {
        return combineLatest([this.store.select(selectAuthenticatedUser), this.selectActiveTemplateTab()]).pipe(
            switchMap(([user]) => {
                return combineLatest([
                    this.filtersService.templatesViewTab$,
                    this.publicTemplateService.getPublicTemplates(),
                    this.favoriteTemplateService.getFavoriteTemplatesByUserId(user?.id as string),
                ]).pipe(
                    map(([activeTemplateTab, publicTemplates, favoriteTemplates]) => {
                        return {
                            user,
                            activeTemplateTab,
                            isLoading: false,
                            publicTemplatesLength: publicTemplates?.length,
                            favoriteTemplatesLength: favoriteTemplates?.length,
                        };
                    }),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    private selectActiveTemplateTab(): Observable<{ readonly templatesTab?: string }> {
        return this.store.select(selectRouterQueryParams).pipe(
            tap(({ templatesTab }) => {
                if (templatesTab) {
                    this.filtersService.templatesViewTab$.next(templatesTab);
                }
            }),
        );
    }
}
