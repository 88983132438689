import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import firebase from 'firebase/compat/app';
import { Timestamp } from 'firebase/firestore';
import { isEqual } from 'lodash';
import { Observable, Subject, tap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fromToDateValidator } from '@accenture/erp-deployment/shared/domain';
import {
    AccessControlRole,
    ActivityOptions,
    Client,
    DefaultFilterObject,
    displayRole,
    initialDefaultFilterObject,
    initialTemplatesFilterObject,
    inputPlaceholders,
    NavigationTab,
    optionsPanelTitle,
    ParentType,
    Phase,
    ProjectOptions,
    ProjectRole,
    SessionOptions,
    SortOptions,
    SortOrders,
    sortOrdersValues,
    SubPhase,
    Tag,
    TemplateRole,
    TemplateTab,
    templateTabValue,
    tooltipTexts,
} from '@accenture/shared/data';
import { trackById, trackByValue } from '@accenture/shared/util';

import { projectRolesListForFilter } from '../projects-list/constants';
import { templateRolesListForFilter } from '../templates-list/constants';
import { DefaultFiltersAndSortFacade, DefaultFiltersAndSortViewModel } from './default-filters-and-sort-facade';

type FiltersAndSortForm = FormGroup<{
    roles: FormControl<string[]>;
    activityTypes: FormControl<string[]>;
    tags: FormControl<string[]>;
    clients: FormControl<string[]>;
    phases: FormControl<string[]>;
    subPhases: FormControl<string[]>;
    fromDate: FormControl<Date | null>;
    toDate: FormControl<Date | null>;
    sortOption: FormControl<SortOptions | null>;
    sortOrder: FormControl<SortOrders | null>;
}>;

@Component({
    selector: 'accenture-default-filters-and-sort',
    templateUrl: './default-filters-and-sort.component.html',
    styleUrls: ['./default-filters-and-sort.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DefaultFiltersAndSortFacade],
})
export class DefaultFiltersAndSortComponent implements OnInit, OnDestroy {
    vm$: Observable<DefaultFiltersAndSortViewModel> = this.facade.vm$.pipe(
        tap(({ filtersAndSort }) => this.updateFormValues(filtersAndSort)),
    );

    trackById = trackById;
    trackByValue = trackByValue;
    filtersAndSortForm: FiltersAndSortForm;
    projectOptions = ProjectOptions;
    sessionOptions = SessionOptions;
    parentTypeList = ParentType;
    roleDisplayName = displayRole;
    sortOrdersValues = sortOrdersValues;
    inputPlaceholders = inputPlaceholders;
    tooltipTexts = tooltipTexts;
    optionPanelTitle: string;
    templateTabValue = templateTabValue;
    templateTab = TemplateTab;

    private pickedDate: Date;
    private onDestroy = new Subject<void>();

    constructor(private fb: FormBuilder, private facade: DefaultFiltersAndSortFacade) {}

    get isToDateGrFromDateErrorVisibility(): boolean {
        const toDateControl = this.filtersAndSortForm && this.filtersAndSortForm.get('toDate');

        return toDateControl && toDateControl.hasError('invalidDate');
    }

    get resetAllLinkDisabled(): boolean {
        return isEqual(this.filtersAndSortForm.value, initialDefaultFilterObject);
    }

    isAbleFiltersByRole(parentType: ParentType): boolean {
        const availableTemplatePages = Object.values(templateTabValue);
        const availableProjectPages = [ParentType.Projects];

        return [...availableTemplatePages, ...availableProjectPages].includes(parentType);
    }

    getRolesList(parentType: ParentType): ProjectRole[] | TemplateRole[] {
        return parentType === ParentType.Projects ? projectRolesListForFilter : templateRolesListForFilter;
    }

    initializeFiltersAndSortForm(): void {
        this.filtersAndSortForm = this.fb.group(
            {
                roles: new FormControl({ value: [], disabled: false }, { nonNullable: true }),
                activityTypes: new FormControl({ value: [], disabled: false }, { nonNullable: true }),
                tags: new FormControl({ value: [], disabled: false }, { nonNullable: true }),
                clients: new FormControl({ value: [], disabled: false }, { nonNullable: true }),
                phases: new FormControl({ value: [], disabled: false }, { nonNullable: true }),
                subPhases: new FormControl({ value: [], disabled: false }, { nonNullable: true }),
                fromDate: new FormControl(null),
                toDate: new FormControl(null),
                sortOption: new FormControl({ value: null, disabled: false }),
                sortOrder: new FormControl({ value: null, disabled: false }),
            },
            {
                updateOn: 'blur',
                validators: fromToDateValidator('fromDate', 'toDate'),
            },
        );
    }

    toggleOptionsPanel(opened: boolean): void {
        this.facade.toggleOptionsPanel(opened);
    }

    navigateToFilterPanel(): void {
        this.toggleOptionsPanel(false);
    }

    clearDateInput(controlName: string, event: Event): void {
        event.stopPropagation();
        this.filtersAndSortForm.get(controlName).reset();
    }

    resetFiltersAndSortForm(navigationTab: NavigationTab): void {
        const resetValue
            = navigationTab === NavigationTab.Templates ? initialTemplatesFilterObject : initialDefaultFilterObject;

        this.filtersAndSortForm.reset({
            ...resetValue,
            fromDate: resetValue.fromDate?.toDate() || null,
            toDate: resetValue.toDate?.toDate() || null,
        });
    }

    setTag(option: Tag): void {
        const control = this.filtersAndSortForm.controls.tags;
        const tags = control.value || [];

        control.setValue([...tags, option.id]);
        this.updateTagOptions('');
    }

    setClient(option: Client): void {
        const control = this.filtersAndSortForm.controls.clients;
        const clients = control.value || [];

        control.setValue([...clients, option.id]);
        this.updateClientOptions('');
    }

    setSubPhase(option: SubPhase): void {
        const control = this.filtersAndSortForm.controls.subPhases;
        const subPhases = control.value || [];

        control.setValue([...subPhases, option.id]);
        this.updateSubPhaseOptions('');
    }

    setPhase(option: Phase): void {
        const control = this.filtersAndSortForm.controls.phases;
        const phases = control.value || [];

        control.setValue([...phases, option.id]);
        this.updatePhaseOptions('');
    }

    openFilterAndSortPanel(event: Event, optionType: ProjectOptions | SessionOptions | ActivityOptions): void {
        this.optionPanelTitle = optionsPanelTitle[optionType];
        this.facade.setOptionType(optionType);

        if (event) {
            event.stopPropagation();
        }
    }

    updateTagOptions(value: string): void {
        this.facade.autocompleteValues$.next({
            ...this.facade.autocompleteValues$.getValue(),
            tag: value,
        });
    }

    updateClientOptions(value: string): void {
        this.facade.autocompleteValues$.next({
            ...this.facade.autocompleteValues$.getValue(),
            client: value,
        });
    }

    updateSubPhaseOptions(value: string): void {
        this.facade.autocompleteValues$.next({
            ...this.facade.autocompleteValues$.getValue(),
            subPhase: value,
        });
    }

    updatePhaseOptions(value: string): void {
        this.facade.autocompleteValues$.next({
            ...this.facade.autocompleteValues$.getValue(),
            phase: value,
        });
    }

    removeSelectedRole(selectedRole: AccessControlRole): void {
        const control = this.filtersAndSortForm.controls.roles;
        const value = control.value.filter(role => role !== selectedRole);

        control.setValue(value);
    }

    removeSelectedActivityType(selectedActivityType: AccessControlRole): void {
        const control = this.filtersAndSortForm.controls.activityTypes;
        const value = control.value.filter(activityType => activityType !== selectedActivityType);

        control.setValue(value);
    }

    removeSelectedTag(selectedTagId: string): void {
        const control = this.filtersAndSortForm.controls.tags;
        const value = control.value.filter((tagId: string) => tagId !== selectedTagId);

        control.setValue(value);
        this.updateTagOptions('');
    }

    removeSelectedClient(selectedClientId: string): void {
        const control = this.filtersAndSortForm.controls.clients;
        const value = control.value.filter((clientId: string) => clientId !== selectedClientId);

        control.setValue(value);
        this.updateClientOptions('');
    }

    removeSelectedPhase(selectedPhaseId: string): void {
        const control = this.filtersAndSortForm.controls.phases;
        const value = control.value.filter((phaseId: string) => phaseId !== selectedPhaseId);

        control.setValue(value);
        this.updatePhaseOptions('');
    }

    removeSelectedSubPhase(selectedSubPhasesId: string): void {
        const control = this.filtersAndSortForm.controls.subPhases;
        const value = control.value.filter((subPhasesId: string) => subPhasesId !== selectedSubPhasesId);

        control.setValue(value);
        this.updateSubPhaseOptions('');
    }

    ngOnInit(): void {
        this.initializeFiltersAndSortForm();
        this.filtersAndSortForm.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe(formData => {
            const pickedDate = formData.toDate ? new Date(formData.toDate) : null;
            const fromDate = formData.fromDate ? firebase.firestore.Timestamp.fromDate(formData.fromDate) : null;

            formData.tags = formData.tags?.length ? formData.tags : null;
            formData.clients = formData.clients?.length ? formData.clients : null;
            formData.phases = formData.phases?.length ? formData.phases : null;
            formData.subPhases = formData.subPhases?.length ? formData.subPhases : null;

            if (!isEqual(this.pickedDate, pickedDate)) {
                pickedDate?.setDate(pickedDate.getDate() + 1);
                pickedDate?.setSeconds(pickedDate.getSeconds() - 1);
                this.pickedDate = pickedDate;
            }

            const toDate = pickedDate ? firebase.firestore.Timestamp.fromDate(pickedDate) : null;
            const filtersData = {
                ...formData,
                toDate: toDate as Timestamp,
                fromDate: fromDate as Timestamp,
            };
            this.facade.applyFiltersAndSort(filtersData);
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    private updateFormValues(filtersAndSort: DefaultFilterObject): void {
        this.filtersAndSortForm.patchValue(
            {
                ...filtersAndSort,
                fromDate: filtersAndSort.fromDate?.toDate() ?? null,
                toDate: filtersAndSort.toDate?.toDate() ?? null,
            },
            { emitEvent: false },
        );
        this.pickedDate = filtersAndSort?.toDate?.toDate();
    }
}
