import { Pipe, PipeTransform } from '@angular/core';

import { ActivityConfigurationsMap, ActivityType } from '@accenture/shared/data';

@Pipe({
    name: 'displayActivityIcon',
})
export class DisplayActivityIconPipe implements PipeTransform {
    transform(type: ActivityType, activityConfigurationsMap: ActivityConfigurationsMap): string {
        return (activityConfigurationsMap || {})[type]?.icon;
    }
}
