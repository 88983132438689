import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { groupBy } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';

import { ActivityItemService, ActivityService, TableColumnService } from '@accenture/activity/shared/domain';
import {
    DataFlowService,
    SessionService,
    UserAccessService,
} from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    selectActivityIdAndParentIds,
    selectAuthenticatedUserId,
    selectSessions,
} from '@accenture/global-store';
import {
    Activity,
    ActivityItem,
    ActivityItemType,
    DataFlowAvailableActivityTypes,
    DataFlowConnection,
    Dictionary,
    ParentType,
    Session,
    SessionRole,
    TableColumn,
    UserAccess,
} from '@accenture/shared/data';
import { DialogService, LoadedDescription } from '@accenture/shared/ui';

export interface SessionsDataFlowConnectionDialogFacadeState {
    activities: Activity[];
    activityItems: Dictionary<ActivityItem[]>;
    connections: DataFlowConnection[];
    currentSession: Session | undefined;
    sessions: Session[];
    isLoading: boolean;
    loaderDescription: string;
    columns: Dictionary<TableColumn[]> | undefined;
}

const defaultViewModel: SessionsDataFlowConnectionDialogFacadeState = {
    activities: [],
    activityItems: {} as Dictionary<ActivityItem[]>,
    currentSession: {} as Session,
    connections: [],
    sessions: [],
    isLoading: false,
    loaderDescription: '',
    columns: undefined,
};

@Injectable()
export class SessionsDataFlowConnectionDialogFacade {
    vm$ = this.buildViewModel();

    private isSavingProgress$ = new BehaviorSubject<boolean>(false);

    private parentType!: ParentType;
    private parentId!: string;

    constructor(
        private store: Store<AppState>,
        private activityItemService: ActivityItemService,
        private dataFlowService: DataFlowService,
        private activityService: ActivityService,
        private sessionService: SessionService,
        private dialogService: DialogService,
        private userAccessService: UserAccessService,
        private tableColumnService: TableColumnService,
    ) {}

    async saveDataFlowData(data: DataFlowConnection[]): Promise<void> {
        this.setLoadingStatus(true);
        await this.dataFlowService.updateBatchDataFlow(this.parentType, this.parentId, data);
        this.setLoadingStatus(false);

        this.dialogService.close();
    }

    private buildViewModel(): Observable<SessionsDataFlowConnectionDialogFacadeState> {
        return combineLatest([
            this.store.select(selectAuthenticatedUserId),
            this.store.pipe(select(selectActivityIdAndParentIds)),
        ]).pipe(
            switchMap(([userId, { projectId, sessionId, parentType, parentId, activityId }]) => {
                if (!parentId || !userId) {
                    return of({
                        ...defaultViewModel,
                        isLoading: false,
                    });
                }

                this.parentType = parentType;
                this.parentId = parentId;

                return combineLatest({
                    activityItems: this.getActivityItems(parentType, parentId),
                    activities: this.getActivities(parentType, parentId),
                    sessions: this.getSessions(parentType, parentId),
                    connections: this.getDataFlowConnections(parentType, parentId),
                    userAssignment: this.getUserAssignmentByParentType(parentType, parentId, userId),
                    columns: this.getColumns(parentType, parentId),
                    isLoading: this.isSavingProgress$,
                }).pipe(
                    map(data => {
                        const filteredSessions = this.getFilteredSessions(data.sessions, data.userAssignment);
                        const currentSession = sessionId
                            ? data.sessions.find(session => session.id === sessionId)
                            : undefined;

                        return {
                            projectId,
                            currentSession,
                            columns: data.columns,
                            activities: data.activities,
                            activityItems: data.activityItems,
                            connections: data.connections,
                            isLoading: data.isLoading,
                            loaderDescription: LoadedDescription.Saving,
                            sessions: filteredSessions,
                        };
                    }),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    private getActivityItems(parentType: ParentType, parentId: string): Observable<Dictionary<ActivityItem[]>> {
        return this.activityItemService
            .getAllActivityItems(parentType, parentId)
            .pipe(map(activityItems => groupBy(activityItems, 'activityId')));
    }

    private getColumns(parentType: ParentType, parentId: string): Observable<Dictionary<TableColumn[]>> {
        return this.tableColumnService.getColumns(parentType, parentId).pipe(
            map((tableColumns: TableColumn[]) => {
                const textColumns = tableColumns.filter(column => column?.type === ActivityItemType.Text);
                return groupBy(textColumns, 'activityItemId');
            }),
        );
    }

    private getActivities(parentType: ParentType, parentId: string): Observable<Activity[]> {
        return this.activityService
            .getAllParentActivities(parentType, parentId)
            .pipe(
                map(activities =>
                    activities.filter(activity => DataFlowAvailableActivityTypes.includes(activity.type)),
                ),
            );
    }

    private getDataFlowConnections(parentType: ParentType, parentId: string): Observable<DataFlowConnection[]> {
        return this.dataFlowService.getDataFlowConnections(parentType, parentId);
    }

    private getSessions(parentType: ParentType, parentId: string): Observable<Session[]> {
        return parentType === ParentType.Templates
            ? this.sessionService.getSession(parentType, parentId, parentId).pipe(map(session => [session]))
            : this.store.select(selectSessions);
    }

    private getUserAssignmentByParentType(
        parentType: ParentType,
        parentId: string,
        userId: string,
    ): Observable<UserAccess> {
        return this.userAccessService.getUserAssignmentByParentType(parentType, parentId, userId);
    }

    private setLoadingStatus(isLoading: boolean): void {
        this.isSavingProgress$.next(isLoading);
    }

    private getFilteredSessions(sessions: Session[], userAssignment: UserAccess): Session[] {
        if (this.parentType === ParentType.Templates) {
            return sessions;
        }

        return sessions.filter(session => {
            const userAssignmentSessions = userAssignment.sessions;
            const sessionId = session.id || '';

            return (
                userAssignmentSessions && sessionId && userAssignmentSessions[sessionId]?.role === SessionRole.Leader
            );
        });
    }
}
