import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable } from 'rxjs';
import { map, startWith, switchMap, take } from 'rxjs/operators';

import { ActivityService } from '@accenture/activity/shared/domain';
import {
    OptionsStore,
    saveActivityToSessionInfoSnackBar,
    saveActivityToSessionSuccessSnackBar,
    SessionOptionsService,
    SessionService,
    TeamMemberService,
    UserAccessService,
} from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    selectActivityIdAndParentIds,
    selectAuthenticatedUser,
    selectSessions,
} from '@accenture/global-store';
import {
    addingTemplateErrorSnackbarTitle,
    addingTemplateInProgressSnackbarTitle,
    CollectionOptions,
    errorMessageSnackbarText,
    FileType,
    ParentType,
    ProjectOptions,
    routerLinksMap,
    SelectedSessionOptions,
    Session,
    SessionOptions,
    SessionRole,
    SessionStatus,
    templateAddedSnackbarTitle,
    TemplateRole,
    User,
    UserAccess,
} from '@accenture/shared/data';
import { DialogService, SnackbarService, SnackBarTypes } from '@accenture/shared/ui';
import { SequenceGeneration } from '@accenture/shared/util';

import { UseTemplateStore } from '../component-stores/use-template.store';
import { UseTemplateDialogComponent } from '../use-template-dialog/use-template-dialog.component';
import { CreateSessionDialogComponent } from './create-session-dialog.component';

export interface CreateSessionDialogViewModel {
    sessionImage: FileType;
    currentOptionToDisplay: SessionOptions | ProjectOptions | CollectionOptions | null;
    isLoading: boolean;
}

const defaultViewModel: CreateSessionDialogViewModel = {
    sessionImage: {} as FileType,
    currentOptionToDisplay: null,
    isLoading: false,
};

@Injectable()
export class CreateSessionDialogFacade {
    private isLoading$ = new BehaviorSubject<boolean>(false);
    private sessionImage$ = new BehaviorSubject<FileType>({} as FileType);
    private parentType!: ParentType;
    private selectedProjectId!: string;
    private useTemplateId!: string;
    private usePublicAccessId!: string;
    private useTemplateType!: ParentType;
    private user!: User;

    vm$ = this.buildViewModel();

    constructor(
        private store: Store<AppState>,
        private sessionService: SessionService,
        private teamMemberService: TeamMemberService,
        private userAccessService: UserAccessService,
        private useTemplateStore: UseTemplateStore,
        private dialogRef: MatDialogRef<CreateSessionDialogComponent>,
        private dialogService: DialogService,
        private router: Router,
        private snackbarService: SnackbarService,
        private activityService: ActivityService,
        private optionsStore: OptionsStore,
        private sessionOptionsService: SessionOptionsService,
    ) {}

    closeDialog(): void {
        this.dialogRef.close();
    }

    openUseTemplateModal(): void {
        this.closeDialog();
        this.dialogService.open(UseTemplateDialogComponent, {
            width: 'auto',
            panelClass: 'tt9-modal',
        });
    }

    setCurrentOptionToDisplay(currentOptionToDisplay: SessionOptions | null): void {
        this.optionsStore.setCurrentOptionToDisplay(currentOptionToDisplay);
    }

    setSessionImage(projectImage: FileType): void {
        this.sessionImage$.next(projectImage);
    }

    resetSessionOptions(): void {
        this.optionsStore.resetSessionOptions();
        this.optionsStore.resetSessionOptionsToCreate();
        this.optionsStore.setCurrentOptionToDisplay(null);
    }

    async createSession(formValue: {
        name: string;
        description?: string;
        startDate?: Date;
        endDate?: Date;
    }): Promise<void> {
        this.snackbarService.showSnackBar(
            addingTemplateInProgressSnackbarTitle,
            saveActivityToSessionInfoSnackBar,
            SnackBarTypes.Info,
        );
        try {
            const sessionImage = this.sessionImage$.value;
            const sessionOptions = await firstValueFrom(this.optionsStore.selectedSessionOptions$.pipe(take(1)));
            const projectSessions = await firstValueFrom(this.store.select(selectSessions).pipe(take(1)));
            const lastSessionSequence = !!projectSessions[projectSessions.length - 1]
                ? projectSessions[projectSessions.length - 1].sequence
                : null;
            const sequence = lastSessionSequence
                ? SequenceGeneration.afterLast(lastSessionSequence)
                : SequenceGeneration.initial();

            const role = this.parentType === ParentType.ProjectTemplates ? TemplateRole.Owner : SessionRole.Leader;
            const session = <Session>{
                sequence,
                name: formValue.name,
                description: formValue.description || '',
                projectId: this.selectedProjectId,
                imageUrl: sessionImage.url || '',
                imageId: sessionImage.id || '',
                status: SessionStatus.Draft,
                creatorId: this.user.id,
                creatorName: this.user.displayName,
                creatorImage: this.user.imageUrl,
                teamMembersCount: 1,
                phase: sessionOptions?.phase || {},
                subPhase: sessionOptions?.subPhase || {},
                tags: sessionOptions?.tags || {},
                access: {
                    [this.user.id as string]: {
                        role,
                        displayName: this.user.displayName,
                    },
                },
            };

            const sessionId = await this.sessionService.createSession(
                ParentType.Projects,
                this.selectedProjectId,
                session,
                formValue.startDate,
                formValue.endDate,
            );
            const sessionData = {
                sessions: {
                    [sessionId]: {
                        role,
                        name: formValue.name,
                        description: formValue.description || '',
                        imageUrl: sessionImage.url || '',
                    },
                },
            } as Partial<UserAccess>;

            this.updateOptions(sessionOptions, sessionId);

            await this.teamMemberService.addTeamMemberToSession(
                ParentType.Projects,
                this.selectedProjectId,
                sessionId,
                this.user,
                role,
            );

            if (this.parentType === ParentType.ProjectTemplates) {
                await this.userAccessService.updateProjectTemplateAssignmentByProjectId(
                    this.selectedProjectId,
                    this.user.id as string,
                    sessionData,
                );
            } else {
                await this.userAccessService.updateProjectAssignmentByProjectId(
                    this.selectedProjectId,
                    this.user.id as string,
                    sessionData,
                );
            }

            await this.addActivitiesToSession(sessionId);
            const parentRoute = routerLinksMap[ParentType.Projects];
            this.router.navigate([parentRoute, this.selectedProjectId, 'session', sessionId]);
        } catch (e) {
            console.error(e);
            this.snackbarService.showSnackBar(null, errorMessageSnackbarText, SnackBarTypes.Error, true);
        }
    }

    async addActivitiesToSession(sessionId: string): Promise<void> {
        try {
            await this.activityService.addActivityToSession(
                this.useTemplateId,
                this.selectedProjectId,
                sessionId,
                this.user.id,
                ParentType.Projects,
                this.useTemplateType,
                this.usePublicAccessId,
                undefined,
                true,
            );

            this.snackbarService.showSnackBar(
                templateAddedSnackbarTitle,
                saveActivityToSessionSuccessSnackBar,
                SnackBarTypes.Success,
                true,
            );
        } catch (e) {
            console.error(e);
            this.snackbarService.showSnackBar(
                addingTemplateErrorSnackbarTitle,
                errorMessageSnackbarText,
                SnackBarTypes.Error,
                true,
            );
        }

        this.resetSessionOptions();
    }

    private buildViewModel(): Observable<CreateSessionDialogViewModel> {
        return combineLatest([
            this.store.pipe(select(selectActivityIdAndParentIds)),
            this.store.pipe(select(selectAuthenticatedUser)),
            this.useTemplateStore.selectedProjectId$,
            this.useTemplateStore.useTemplateId$,
            this.useTemplateStore.useTemplateType$,
            this.useTemplateStore.usePublicAccessId$,
            this.optionsStore.currentOptionToDisplay$,
        ]).pipe(
            switchMap(
                ([
                    { parentType },
                    user,
                    selectedProjectId,
                    useTemplateId,
                    useTemplateType,
                    usePublicAccessId,
                    currentOptionToDisplay,
                ]) => {
                    this.parentType = parentType;
                    this.user = user;
                    this.selectedProjectId = selectedProjectId;
                    this.useTemplateId = useTemplateId;
                    this.useTemplateType = useTemplateType;
                    this.usePublicAccessId = usePublicAccessId;

                    return combineLatest([this.isLoading$.asObservable(), this.sessionImage$.asObservable()]).pipe(
                        map(([isLoading, sessionImage]) => {
                            return {
                                isLoading,
                                sessionImage,
                                currentOptionToDisplay,
                            };
                        }),
                    );
                },
            ),
            startWith(defaultViewModel),
        );
    }

    private async updateOptions(selectedSessionOptions: SelectedSessionOptions, sessionId: string): Promise<void> {
        const optionsToCreateIds = await firstValueFrom(this.optionsStore.sessionOptionsToCreateIds$.pipe(take(1)));

        await this.sessionOptionsService.updateOptions(selectedSessionOptions, sessionId, {}, optionsToCreateIds);
    }
}
