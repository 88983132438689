import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';

import { emptyScreenTitles, EmptyScreenType, inputPlaceholders, SessionRole } from '@accenture/shared/data';
import { isTooltipDisabled } from '@accenture/shared/util';

import { ProjectMembersFacade, ProjectMemberViewModel } from './project-members-facade';

@UntilDestroy()
@Component({
    selector: 'accenture-project-members',
    templateUrl: './project-members.component.html',
    styleUrls: ['./project-members.component.scss'],
    providers: [ProjectMembersFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectMembersComponent implements OnInit {
    title = 'Project members';
    addProjectMemberTyping = data => data as ProjectMemberViewModel;

    vm$ = this.facade.vm$;

    searchControl = new FormControl<string>('', { nonNullable: true });

    protected readonly inputPlaceholders = inputPlaceholders;
    protected readonly emptyScreenTitles = emptyScreenTitles;
    protected readonly emptyScreenType = EmptyScreenType;
    protected readonly isTooltipDisabled = isTooltipDisabled;

    constructor(private facade: ProjectMembersFacade) {}

    trackByUserId = (_: number, memberViewModel: ProjectMemberViewModel): string => {
        return memberViewModel.user.id;
    };

    ngOnInit(): void {
        this.subscribeOnSearchUpdate();
    }

    clearFilter(): void {
        this.searchControl.reset();
    }

    backOnInviteStep(): void {
        this.facade.backOnInviteStep();
    }

    emitCheckboxToggle(checkboxElement: MatCheckbox, event: Event, isCheckboxDisable: boolean): void {
        if (isCheckboxDisable) {
            return;
        }

        event.stopPropagation();
        checkboxElement.toggle();
        checkboxElement.change.emit({ checked: checkboxElement.checked } as MatCheckboxChange);
    }

    toggleMemberSelection(
        email: string,
        status: boolean,
        role: SessionRole.Leader | SessionRole.Participant,
    ): Promise<void> {
        if (status) {
            return this.facade.selectMember(email, role);
        }

        return this.facade.unselectMember(email);
    }

    private subscribeOnSearchUpdate(): void {
        this.searchControl.valueChanges
            .pipe(
                distinctUntilChanged(),
                map(value => {
                    const timedValue = value.trimStart().toLowerCase();

                    this.searchControl.patchValue(timedValue);
                    return timedValue;
                }),
                distinctUntilChanged(),
                debounceTime(200),
                untilDestroyed(this),
                tap(value => this.facade.setSearchValue(value)),
            )
            .subscribe();
    }
}
