import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, of, skipWhile } from 'rxjs';
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { notFoundUrl, TeamMember } from '@accenture/shared/data';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { DialogService } from '@accenture/shared/ui';

import { selectAuthenticatedUserId } from '../auth/auth.selectors';
import * as publicTemplateActions from './public-template.actions';
import { selectPublicTeamMember } from './public-template.selectors';
import { PublicTemplateDataService } from './public-template-data.service';

@Injectable()
export class PublicTemplateEffects {
    public getParentTeamMember$ = createEffect(() =>
        this.actions$.pipe(
            ofType(publicTemplateActions.getParentTeamMember),
            withLatestFrom(this.store.select(selectAuthenticatedUserId)),
            switchMap(([{ parentType, parentId }, userId]) =>
                this.publicTemplateDataService.getTeamMemberByParentType(parentType, parentId, userId).pipe(
                    map((teamMember: TeamMember) => {
                        const teamMemberData = publicTemplateActions.getParentTeamMemberSuccess({ teamMember });
                        if (!teamMember || !!teamMember?.deleted) {
                            this.dialogService.close();
                            this.router.navigateByUrl(notFoundUrl);
                        }
                        return teamMemberData;
                    }),
                    catchError(() => {
                        this.router.navigateByUrl(notFoundUrl);
                        return of(publicTemplateActions.getParentTeamMemberError());
                    }),
                ),
            ),
        ),
    );

    public setPublicTeamMemberOnlineState$ = createEffect(() =>
        this.actions$.pipe(
            ofType(publicTemplateActions.setPublicTeamMemberOnlineState),
            switchMap(({ templateId, parentType, isOnline }) =>
                this.store.select(selectPublicTeamMember).pipe(
                    skipWhile(teamMember => !teamMember),
                    take(1),
                    switchMap(async teamMember => {
                        if (teamMember?.isOnline !== isOnline) {
                            await this.publicTemplateDataService.updateParentTeamMember(
                                templateId,
                                teamMember.userId,
                                { isOnline },
                                parentType,
                            );
                        }
                        return publicTemplateActions.publicTeamMemberOnlineStateChangedSuccess();
                    }),
                    catchError(() => of(publicTemplateActions.publicTeamMemberOnlineStateChangedError())),
                ),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private publicTemplateDataService: PublicTemplateDataService,
        private store: Store,
        private router: Router,
        private dialogService: DialogService,
    ) {}
}
