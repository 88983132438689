import { NodeTemplate, NodeType } from '../node-template';
import { Activity, ActivityType } from './';

export enum DiagramTypes {
    MindMap = 'MIND_MAP',
    FlowChart = 'FLOWCHART',
}

export class Diagram extends NodeTemplate implements Activity {
    type = ActivityType.Diagram;

    sessionId?: string;
    projectId?: string;
    templateId?: string;
    visible?: boolean;
    sequence: string;

    diagramType?: DiagramTypes;
    locked?: boolean;
    freeForAll?: boolean;

    constructor(id: string | undefined, diagram: Partial<Diagram>) {
        super(id, diagram, NodeType.Activity);

        this.sessionId = diagram.sessionId ?? '';
        this.projectId = diagram.projectId ?? '';
        this.templateId = diagram.templateId ?? '';
        this.visible = diagram.visible ?? false;
        this.sequence = diagram.sequence ?? '';
        this.diagramType = diagram.diagramType ?? DiagramTypes.FlowChart;
        this.locked = diagram.locked ?? false;
        this.freeForAll = diagram.freeForAll ?? false;
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            projectId: this.projectId,
            sessionId: this.sessionId,
            templateId: this.templateId,
            visible: this.visible,
            sequence: this.sequence,
            type: this.type,
            created: this.created,
            updated: this.updated,
            diagramType: this.diagramType,
            locked: this.locked,
            freeForAll: this.freeForAll,
        };
    }
}
