import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DataFlowConnection } from '@accenture/shared/data';
import { ActivityEditDataFlowConnectionDialogFacade } from './activity-edit-data-flow-connection-dialog-facade';

@Component({
    selector: 'accenture-vote-edit-data-flow-connection-dialog',
    templateUrl: './activity-edit-data-flow-connection-dialog.component.html',
    styleUrls: ['./activity-edit-data-flow-connection-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ActivityEditDataFlowConnectionDialogFacade],
})
export class ActivityEditDataFlowConnectionDialogComponent {
    vm$ = this.facade.vm$;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { title: string },
        private facade: ActivityEditDataFlowConnectionDialogFacade,
    ) {}

    async saveData(
        data: {
            dataFlowArray: DataFlowConnection[];
            generatedDestinationId?: string;
            destinationType?: string;
            destinationName?: string;
        },
        connectionData: DataFlowConnection,
    ): Promise<void> {
        if (Object.keys(connectionData || {}).length) {
            await this.facade.updateDataFlowConnection(connectionData.id, data.dataFlowArray);
        } else {
            await this.facade.saveDataFlowData(data.dataFlowArray);
        }
    }
}
