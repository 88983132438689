import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import firebase from 'firebase/compat/app';
import { Timestamp } from 'firebase/firestore';
import { isEqual } from 'lodash';
import { Observable, Subject, tap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fromToDateValidator } from '@accenture/erp-deployment/shared/domain';
import {
    AccessControlRole,
    DefaultFilterObject,
    favoriteTemplatesSortOptionsValues,
    favoriteTemplateTypeToDisplay,
    initialDefaultFilterObject,
    inputPlaceholders,
    optionsPanelTitle,
    ParentType,
    Phase,
    ProjectOptions,
    PublicTemplateRoleForFilter,
    SessionOptions,
    SortOptions,
    SortOrders,
    sortOrdersValues,
    SubPhase,
    Tag,
    TemplateRole,
    TemplateTab,
    templateTabValue,
    tooltipTexts,
} from '@accenture/shared/data';
import { trackById, trackByValue } from '@accenture/shared/util';

import { favoriteTemplateTypesListForFilter, templateRolesListForFilter, templateRoleToDisplay } from './constants';
import {
    FavoriteTemplatesFiltersAndSortFacade,
    FavoriteTemplatesFiltersAndSortViewModel,
} from './favorite-templates-filters-and-sort-facade';

type FiltersAndSortForm = FormGroup<{
    templateTypes: FormControl<AccessControlRole[]>;
    templateRoles: FormControl<(TemplateRole | PublicTemplateRoleForFilter)[]>;
    activityTypes: FormControl<AccessControlRole[]>;
    tags: FormControl<string[]>;
    phases: FormControl<string[]>;
    subPhases: FormControl<string[]>;
    fromDate: FormControl<Date | null>;
    toDate: FormControl<Date | null>;
    sortOption: FormControl<SortOptions>;
    sortOrder: FormControl<SortOrders>;
}>;

@Component({
    selector: 'accenture-favorite-templates-filters-and-sort',
    templateUrl: './favorite-templates-filters-and-sort.component.html',
    styleUrls: ['./favorite-templates-filters-and-sort.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [FavoriteTemplatesFiltersAndSortFacade],
})
export class FavoriteTemplatesFiltersAndSortComponent implements OnInit, OnDestroy {
    vm$: Observable<FavoriteTemplatesFiltersAndSortViewModel> = this.facade.vm$.pipe(
        tap(({ filtersAndSort }) => this.updateFormValues(filtersAndSort)),
    );

    trackById = trackById;
    trackByValue = trackByValue;
    filtersAndSortForm: FiltersAndSortForm;
    projectOptions = ProjectOptions;
    sessionOptions = SessionOptions;
    parentTypeList = ParentType;
    sortOptionsValues = favoriteTemplatesSortOptionsValues;
    sortOrdersValues = sortOrdersValues;
    inputPlaceholders = inputPlaceholders;
    tooltipTexts = tooltipTexts;
    optionPanelTitle: string;

    templateTypesList = favoriteTemplateTypesListForFilter;
    templateRolesList = templateRolesListForFilter;
    displayTemplateType = favoriteTemplateTypeToDisplay;
    displayTemplateRole = templateRoleToDisplay;
    templateTab = TemplateTab;

    protected readonly templateTabValue = templateTabValue;
    protected readonly TemplateTab = TemplateTab;

    private pickedDate: Date;
    private onDestroy = new Subject<void>();

    constructor(private fb: FormBuilder, private facade: FavoriteTemplatesFiltersAndSortFacade) {}

    get isToDateGrFromDateErrorVisibility(): boolean {
        const toDateControl = this.filtersAndSortForm && this.filtersAndSortForm.get('toDate');

        return toDateControl && toDateControl.hasError('invalidDate');
    }

    initializeFiltersAndSortForm(): void {
        this.filtersAndSortForm = this.fb.group(
            {
                templateTypes: new FormControl({ value: [], disabled: false }, { nonNullable: true }),
                templateRoles: new FormControl({ value: [], disabled: false }, { nonNullable: true }),
                activityTypes: new FormControl({ value: [], disabled: false }, { nonNullable: true }),
                tags: new FormControl({ value: [], disabled: false }, { nonNullable: true }),
                phases: new FormControl({ value: [], disabled: false }, { nonNullable: true }),
                subPhases: new FormControl({ value: [], disabled: false }, { nonNullable: true }),
                fromDate: new FormControl(null),
                toDate: new FormControl(null),
                sortOption: new FormControl({ value: null, disabled: false }),
                sortOrder: new FormControl({ value: null, disabled: false }),
            },
            {
                updateOn: 'blur',
                validators: fromToDateValidator('fromDate', 'toDate'),
            },
        );
    }

    toggleOptionsPanel(opened: boolean): void {
        this.facade.toggleOptionsPanel(opened);
    }

    navigateToFilterPanel(): void {
        this.toggleOptionsPanel(false);
    }

    clearDateInput(controlName: string, event: Event): void {
        event.stopPropagation();
        this.filtersAndSortForm.get(controlName).reset();
    }

    resetFiltersAndSortForm(): void {
        this.filtersAndSortForm.reset({
            ...initialDefaultFilterObject,
            templateTypes: (initialDefaultFilterObject?.templateTypes || []) as AccessControlRole[],
            templateRoles: (initialDefaultFilterObject?.templateRoles || []) as (
                | TemplateRole
                | PublicTemplateRoleForFilter
            )[],
            activityTypes: (initialDefaultFilterObject?.activityTypes || []) as AccessControlRole[],
            fromDate: initialDefaultFilterObject?.fromDate?.toDate() || null,
            toDate: initialDefaultFilterObject?.toDate?.toDate() || null,
        });
    }

    setTag(option: Tag): void {
        const control = this.filtersAndSortForm.controls.tags;
        const tags = control.value || [];

        control.setValue([...tags, option.id]);
        this.updateTagOptions('');
    }

    setSubPhase(option: SubPhase): void {
        const control = this.filtersAndSortForm.controls.subPhases;
        const subPhases = control.value || [];

        control.setValue([...subPhases, option.id]);
        this.updateSubPhaseOptions('');
    }

    setPhase(option: Phase): void {
        const control = this.filtersAndSortForm.controls.phases;
        const phases = control.value || [];

        control.setValue([...phases, option.id]);
        this.updatePhaseOptions('');
    }

    openFilterAndSortPanel(event: Event, optionType: ProjectOptions | SessionOptions): void {
        this.optionPanelTitle = optionsPanelTitle[optionType];
        this.facade.setOptionType(optionType);

        if (event) {
            event.stopPropagation();
        }
    }

    updateTagOptions(value: string): void {
        this.facade.autocompleteValues$.next({
            ...this.facade.autocompleteValues$.getValue(),
            tag: value,
        });
    }

    updateSubPhaseOptions(value: string): void {
        this.facade.autocompleteValues$.next({
            ...this.facade.autocompleteValues$.getValue(),
            subPhase: value,
        });
    }

    updatePhaseOptions(value: string): void {
        this.facade.autocompleteValues$.next({
            ...this.facade.autocompleteValues$.getValue(),
            phase: value,
        });
    }

    removeSelectedTemplateType(selectedTemplateType: AccessControlRole): void {
        const control = this.filtersAndSortForm.controls.templateTypes;
        const value = control.value.filter((templateType: AccessControlRole) => templateType !== selectedTemplateType);

        control.setValue(value);
    }

    removeSelectedActivityType(selectedActivityType: AccessControlRole): void {
        const control = this.filtersAndSortForm.controls.activityTypes;
        const value = control.value.filter((activityType: AccessControlRole) => activityType !== selectedActivityType);

        control.setValue(value);
    }

    removeSelectedTemplateRole(selectedTemplateRole: TemplateRole | PublicTemplateRoleForFilter): void {
        const control = this.filtersAndSortForm.controls.templateRoles;
        const value = control.value.filter(
            (templateRole: TemplateRole | PublicTemplateRoleForFilter) => templateRole !== selectedTemplateRole,
        );

        control.setValue(value);
    }

    removeSelectedTag(selectedTagId: string): void {
        const control = this.filtersAndSortForm.controls.tags;
        const value = control.value.filter((tagId: string) => tagId !== selectedTagId);

        control.setValue(value);
        this.updateTagOptions('');
    }

    removeSelectedPhase(selectedPhaseId: string): void {
        const control = this.filtersAndSortForm.controls.phases;
        const value = control.value.filter((phaseId: string) => phaseId !== selectedPhaseId);

        control.setValue(value);
        this.updatePhaseOptions('');
    }

    removeSelectedSubPhase(selectedSubPhasesId: string): void {
        const control = this.filtersAndSortForm.controls.subPhases;
        const value = control.value.filter((subPhasesId: string) => subPhasesId !== selectedSubPhasesId);

        control.setValue(value);
        this.updateSubPhaseOptions('');
    }

    ngOnInit(): void {
        this.initializeFiltersAndSortForm();
        this.filtersAndSortForm.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe(formData => {
            const pickedDate = formData.toDate ? new Date(formData.toDate) : null;
            const fromDate = formData.fromDate ? firebase.firestore.Timestamp.fromDate(formData.fromDate) : null;

            formData.tags = formData.tags?.length ? formData.tags : null;
            formData.phases = formData.phases?.length ? formData.phases : null;
            formData.subPhases = formData.subPhases?.length ? formData.subPhases : null;

            if (!isEqual(this.pickedDate, pickedDate)) {
                pickedDate?.setDate(pickedDate.getDate() + 1);
                pickedDate?.setSeconds(pickedDate.getSeconds() - 1);
                this.pickedDate = pickedDate;
            }

            const toDate = pickedDate ? firebase.firestore.Timestamp.fromDate(pickedDate) : null;
            const filtersData = {
                ...formData,
                toDate: toDate as Timestamp,
                fromDate: fromDate as Timestamp,
            };

            this.facade.applyFiltersAndSort(filtersData);
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    private updateFormValues(filtersAndSort: DefaultFilterObject): void {
        this.filtersAndSortForm.patchValue(
            {
                ...filtersAndSort,
                templateTypes: (filtersAndSort?.templateTypes || []) as AccessControlRole[],
                templateRoles: (filtersAndSort?.templateRoles || []) as (TemplateRole | PublicTemplateRoleForFilter)[],
                activityTypes: (filtersAndSort?.activityTypes || []) as AccessControlRole[],
                fromDate: filtersAndSort.fromDate?.toDate() ?? null,
                toDate: filtersAndSort.toDate?.toDate() ?? null,
            },
            { emitEvent: false },
        );
        this.pickedDate = filtersAndSort?.toDate?.toDate();
    }
}
