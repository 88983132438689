export enum ImageInputMode {
    Default = 'default',
    Icon = 'icon',
    Button = 'button',
    Circular = 'circular',
}

export enum ImageInputPlaceholdersTypes {
    Project = 'PROJECT',
    Collection = 'COLLECTION',
    Session = 'SESSION',
    Image = 'IMAGE',
    Default = 'DEFAULT',
}

export const imageInputPlaceholdersUrl = {
    [ImageInputPlaceholdersTypes.Project]: '/assets/shared-ui/svg/description-project.svg',
    [ImageInputPlaceholdersTypes.Collection]: '/assets/shared-ui/svg/description-collection.svg',
    [ImageInputPlaceholdersTypes.Session]: '/assets/shared-ui/svg/description-session.svg',
    [ImageInputPlaceholdersTypes.Image]: '/assets/shared-ui/svg/description-image.svg',
    [ImageInputPlaceholdersTypes.Default]: '/assets/shared-ui/svg/description-image.svg',
};
