import { Pipe, PipeTransform } from '@angular/core';

import { User, UserClaim } from '@accenture/shared/data';

@Pipe({
    name: 'getUserClaims',
    standalone: true,
})
export class GetUserClaimsPipe implements PipeTransform {
    transform(user: User): UserClaim[] {
        if (user.globalAdmin) {
            return [UserClaim.Admin];
        }
        if (user.isAccenture) {
            return [UserClaim.ProjectCreator, UserClaim.TemplateCreator];
        }
        return [];
    }
}
