<ng-container>
  <div
    class="tt9-navigation-card-template template-cards"
    [ngClass]="{
      hover: hoveredCardIndex === templateIndex,
      'fixed-card': !isTemplatesPage,
      'card-creator-details': isPublic(template.templateType)
    }"
  >
    <div class="tt9-navigation-card-template-image card-image">
      <div
        *ngIf="template.imageUrl"
        class="tt9-navigation-card-template-image-filtered"
        [class.inactive]="isDraft(template, userId)"
        [style.background-image]="getImageUrl(template.imageUrl)"
      ></div>

      <img
        *ngIf="!template.imageUrl"
        class="tt9-navigation-card-image-empty"
        alt="Template Image"
        [src]="getEmptyImage(template.templateType, template.activityType)"
      />
      <div *ngIf="isFeatured(template)" class="tt9-navigation-card-template-image-top-badges">
        <div class="tt9-badge featured">Featured</div>
      </div>

      <div class="tt9-navigation-card-template-image-bottom-badges">
        <div *ngIf="isAllTemplateTab" class="tt9-badge" [ngClass]="template.templateType">
          {{ template.templateType | displayParentType }}
        </div>
        <div *ngIf="isPublished(template)" class="tt9-badge" [ngClass]="template?.status">
          {{ template?.status | displayTemplateStatus }}
        </div>

        <div *ngIf="isDraft(template, userId)" class="tt9-badge unsaved">
          <accenture-icon class="mr-4" [size]="smallIconSize">icon-alert-triangle</accenture-icon>
          Unsaved changes
        </div>
      </div>
    </div>

    <div class="tt9-navigation-card-content">
      <p class="tt9-body-medium-2 text-overflow-ellipsis-2 mt-4">{{ template.name }}</p>

      <div *ngIf="isPublic(template.templateType)" class="d-flex">
        <div class="tt9-userpic small mr-4">
          <img *ngIf="template.ownerImageUrl" [src]="template.ownerImageUrl" [alt]="template.ownerDisplayName" />

          <ng-container *ngIf="!template.ownerImageUrl">{{ template.ownerDisplayName | userInitials }}</ng-container>
        </div>

        <span class="tt9-body-medium-2 text-overflow-ellipsis-1">{{ template.ownerDisplayName }}</span>
      </div>

      <div class="tt9-navigation-card-footer d-flex-between">
        <span class="tt9-caption-3 tt9-color-neutral-500">
          {{ getTemplateUpdateDate(template) | date : "MMM dd, yyyy" }}
        </span>

        <div class="d-flex">
          <div *ngIf="isApproved(template)" class="d-flex-center mr-8">
            <img class="accenture-approve-img" [src]="accentureApproveImgPath" />
          </div>

          <button
            class="tt9-btn-icon-filled btn-rounded favorite-btn"
            [ngClass]="{
              selected: isFavorite
            }"
            (click)="updateFavorite.emit(template)"
          >
            <accenture-icon>
              {{ isFavorite ? "icon-star" : "icon-star-outline" }}
            </accenture-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="action-buttons" [class.hover]="hoveredCardIndex === templateIndex">
      <button
        matMenuTrigger
        class="tt9-btn-icon-filled btn-rounded menu-btn"
        [matMenuTriggerFor]="actionsMenu"
        (menuOpened)="openMenu.emit()"
        (menuClosed)="closeMenu.emit()"
      >
        <accenture-icon> icon-more </accenture-icon>
      </button>
      <mat-menu xPosition="before" #actionsMenu="matMenu" class="tt9-menu">
        <ng-container *ngIf="isPublic(template.templateType)">
          <button mat-menu-item (click)="useTemplate.emit(template)">
            <accenture-icon>icon-create-template</accenture-icon>
            Use Template
          </button>
          <button mat-menu-item (click)="saveAsTemplate.emit(template)">
            <accenture-icon>icon-save</accenture-icon>
            {{ saveToMyTemplatesLabel }}
          </button>
          <button *ngIf="canEdit(userId)" mat-menu-item (click)="editTemplate.emit(template)">
            <accenture-icon>icon-edit</accenture-icon>
            Edit
          </button>
          <button
            *ngIf="showDeepLinks && isPublicSessionTemplate(template.templateType)"
            mat-menu-item
            (click)="copyLinkAccessToClipboard.emit(template)"
          >
            <accenture-icon>icon-link</accenture-icon>
            {{ copyLinkToClipboardLabel }}
          </button>
        </ng-container>

        <ng-container *ngIf="!isPublic(template.templateType)">
          <button
            mat-menu-item
            [routerLink]="getTemplateLink(template.templateType, template.templateId, template.activityType)"
          >
            <accenture-icon>icon-edit</accenture-icon>
            Edit
          </button>
          <button mat-menu-item (click)="openPublishDialog.emit(template)">
            <accenture-icon>icon-share</accenture-icon>
            Publish
          </button>
          <button *ngIf="template.ownerId === userId" mat-menu-item (click)="deleteTemplate.emit(template)">
            <accenture-icon>icon-delete</accenture-icon>
            Delete
          </button>
        </ng-container>
      </mat-menu>
      <button
        *ngIf="isPublic(template.templateType)"
        class="tt9-btn d-flex-center"
        (click)="openForPreview.emit({ template, event: $event })"
      >
        Preview
      </button>
      <button
        *ngIf="!isPublic(template.templateType)"
        class="tt9-btn use-template-btn"
        (click)="useTemplate.emit(template)"
      >
        Use Template
      </button>
    </div>
  </div>
</ng-container>
