import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, map, Observable, of } from 'rxjs';
import { distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';

import { AIPromptService } from '@accenture/erp-deployment/shared/domain';
import { ActivityType, AnalyzePrompt } from '@accenture/shared/data';
import { errorSnackbarText } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import { SnackbarService, SnackBarTypes } from '@accenture/shared/ui';

import { AnalyzeResponsesDialogComponent } from './analyze-responses-dialog.component';

export enum DisplayState {
    ChoosePrompt = 'CHOOSE_PROMPT',
    ShowResults = 'SHOW_RESULTS',
}

export interface AnalysisResults {
    analysis: string[];
    rawAnalysis: string;
}

const defaultAnalysisResults = {
    analysis: [],
    rawAnalysis: '',
};

export interface AnalyzeResponsesDialogViewModel {
    analyzePrompts: AnalyzePrompt[];
    results: AnalysisResults;
    displayState: DisplayState;
    isAnalyzing: boolean;
    error: string;
    isLoading: boolean;
}

const defaultViewModel = {
    analyzePrompts: [],
    results: defaultAnalysisResults,
    displayState: DisplayState.ChoosePrompt,
    isAnalyzing: false,
    error: '',
    isLoading: true,
};

@Injectable()
export class AnalyzeResponsesDialogFacade {
    title = 'Analyze responses';

    private isAnalyzing$ = new BehaviorSubject<boolean>(false);
    private analysis$ = new BehaviorSubject<AnalysisResults>(defaultAnalysisResults);
    private activityType$ = new BehaviorSubject<ActivityType>(null);
    private displayState$ = new BehaviorSubject<DisplayState>(DisplayState.ChoosePrompt);
    private error$ = new BehaviorSubject<string>('');
    vm$ = this.buildViewModel();
    constructor(
        private firestoreService: FirestoreService,
        private analyzePromptService: AIPromptService,
        private dialogRef: MatDialogRef<AnalyzeResponsesDialogComponent>,
        private snackbarService: SnackbarService,
    ) {}

    async analyzeResponses(
        topicText: string,
        promptId: string,
        projectId: string,
        selectedQuestionId: string,
    ): Promise<void> {
        this.updateDisplayState(DisplayState.ShowResults);
        this.isAnalyzing$.next(true);
        this.analysis$.next(defaultAnalysisResults);

        try {
            const { result } = await this.firestoreService.cloudFunctionCallable<any>('analyzeResponses', {
                projectId,
                activityItemId: selectedQuestionId,
                promptId,
            });

            this.analysis$.next({
                rawAnalysis: result.join('\n'),
                analysis: result,
            });
            this.snackbarService.showSnackBar(
                this.title,
                `${result.length} items received.`,
                SnackBarTypes.Success,
                true,
                false,
            );
        } catch (e) {
            console.error('AnalyzeResponsesDialogFacade::analyzeResponses - server error', e);
            this.error$.next('Unable to analyze the current set of responses, please try again later');
            this.snackbarService.showSnackBar(this.title, errorSnackbarText, SnackBarTypes.Error, true, false);
        }

        this.isAnalyzing$.next(false);
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    updateActivityType(activityType: ActivityType): void {
        this.activityType$.next(activityType);
    }

    updateDisplayState(displayState: DisplayState): void {
        this.displayState$.next(displayState);
    }

    resetError(): void {
        this.error$.next('');
    }

    private buildViewModel(): Observable<AnalyzeResponsesDialogViewModel> {
        return combineLatest([
            this.getAnalyzePrompts$(),
            this.analysis$.asObservable(),
            this.isAnalyzing$.asObservable().pipe(distinctUntilChanged()),
            this.displayState$.asObservable().pipe(distinctUntilChanged()),
            this.error$.asObservable().pipe(distinctUntilChanged()),
        ]).pipe(
            map(([analyzePrompts, results, isAnalyzing, displayState, error]) => {
                return {
                    analyzePrompts,
                    results,
                    isAnalyzing,
                    displayState,
                    error,
                    isLoading: false,
                };
            }),
            startWith(defaultViewModel),
        );
    }

    private getAnalyzePrompts$(): Observable<AnalyzePrompt[]> {
        return this.activityType$
            .asObservable()
            .pipe(
                switchMap(activityType =>
                    activityType ? this.analyzePromptService.getAnalyzePrompts(activityType) : of([]),
                ),
            );
    }
}
