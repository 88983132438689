import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataFlowConnection, DBPathHelper, ParentType, TableColumn } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import { SequenceGeneration } from '@accenture/shared/util';

@Injectable({
    providedIn: 'root',
})
export class TableColumnService {
    constructor(private firestoreService: FirestoreService) {}

    async removeColumnOption(
        parentType: ParentType,
        parentId: string,
        columnId: string,
        optionId: string,
    ): Promise<void> {
        await this.firestoreService.update(DBPathHelper.getTableColumnPath(parentType, parentId, columnId), {
            choices: {
                [optionId]: this.firestoreService.deleteField,
            },
        });
    }

    getColumns(parentType: ParentType, parentId: string, activityItemId?: string): Observable<TableColumn[]> {
        const path = DBPathHelper.getTableColumnPath(parentType, parentId);
        const tableColumnObjects$ = activityItemId
            ? this.firestoreService.getDocumentsByProperty<TableColumn>(
                  path,
                  'activityItemId',
                  activityItemId,
                  'sequence',
              )
            : this.firestoreService.getCollection<TableColumn>(path);

        return tableColumnObjects$.pipe(
            map(tableColumnObjects => {
                return tableColumnObjects.map(column => new TableColumn(column));
            }),
        );
    }

    async addColumns(parentType: ParentType, parentId: string, data: Partial<TableColumn>[]): Promise<void> {
        const batchData = [];
        let sequence = SequenceGeneration.initial();
        data.forEach(column => {
            batchData.push({
                path: DBPathHelper.getTableColumnPath(parentType, parentId, this.firestoreService.getPushId()),
                data: {
                    ...column,
                    sequence,
                    created: this.firestoreService.timestamp,
                    updated: this.firestoreService.timestamp,
                },
            });
            sequence = SequenceGeneration.afterLast(sequence);
        });
        await this.firestoreService.setBatch(batchData);
    }

    async addColumn(parentType: ParentType, parentId: string, column: Partial<TableColumn>): Promise<string> {
        return await this.firestoreService.addDocument(DBPathHelper.getTableColumnPath(parentType, parentId), {
            ...column,
            created: this.firestoreService.timestamp,
            updated: this.firestoreService.timestamp,
        });
    }

    async updateColumn(
        parentType: ParentType,
        parentId: string,
        columnId: string,
        tableColumnFields: Partial<TableColumn>,
    ): Promise<void> {
        await this.firestoreService.update(
            DBPathHelper.getTableColumnPath(parentType, parentId, columnId),
            this.firestoreService.replaceEmptyFields(tableColumnFields),
        );
    }

    async deleteColumn(parentType: ParentType, parentId: string, columnId: string): Promise<void> {
        return this.firestoreService.delete(DBPathHelper.getTableColumnPath(parentType, parentId, columnId));
    }

    async deleteDataFlowConnectionByColumn(
        parentType: ParentType,
        parentId: string,
        activityId: string,
        activityItemId: string,
    ): Promise<void> {
        const dataFlowConnections = await firstValueFrom(
            this.firestoreService.getDocumentsByProperty<DataFlowConnection>(
                DBPathHelper.getDataFlowConnectionPath(parentType, parentId),
                'destinationId',
                activityId,
            ),
        );

        if (!dataFlowConnections.length) {
            return;
        }

        const connectionByColumn = dataFlowConnections.find(
            connection => connection.destinationColumn?.id === activityItemId,
        );

        if (connectionByColumn?.id) {
            await this.firestoreService.delete(
                DBPathHelper.getDataFlowConnectionPath(parentType, parentId, connectionByColumn.id),
            );
        }
    }
}
