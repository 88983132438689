import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Activity, Dictionary } from '@accenture/shared/data';
import { LoadedDescription } from '@accenture/shared/ui';

import { SequenceData } from '../../models';
import { AddActivityTemplatesDialogFacade } from './add-activity-templates-dialog-facade';

interface AddActivityTemplatesDialogData {
    activities: Activity[];
    completeCallback?: () => void;
    sequenceData?: SequenceData;
}

@Component({
    selector: 'accenture-add-activity-templates-dialog',
    templateUrl: './add-activity-templates-dialog.component.html',
    styleUrls: ['./add-activity-templates-dialog.component.scss'],
    providers: [AddActivityTemplatesDialogFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddActivityTemplatesDialogComponent {
    loaderText!: LoadedDescription;
    vm$ = this.facade.vm$;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: AddActivityTemplatesDialogData,
        private facade: AddActivityTemplatesDialogFacade,
    ) {}

    updateSearchValue(searchValue: string): void {
        this.facade.updateSearchValue(searchValue);
    }

    updateSelectedTemplates(selectedTemplatesMap: Dictionary<boolean>): void {
        this.facade.updateSelectedTemplates(selectedTemplatesMap);
    }

    closeDialog(): void {
        this.facade.closeDialog();
    }

    async addActivities(): Promise<void> {
        this.loaderText = LoadedDescription.AddingActivitiesToProject;

        await this.facade.addActivitiesToSession(this.data.activities, this.data.sequenceData);

        if (this.data.completeCallback) {
            this.data.completeCallback();
        }
    }
}
