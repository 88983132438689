import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';

import { Session, tooltipTexts } from '@accenture/shared/data';
import { IconColor, IconSize } from '@accenture/shared/ui';

import {
    SessionsEditorToolsPanelFacade,
    SessionsEditorToolsPanelViewModel,
} from './sessions-editor-tools-panel-facade';

@Component({
    selector: 'accenture-sessions-editor-tools-panel',
    templateUrl: './sessions-editor-tools-panel.component.html',
    styleUrls: ['./sessions-editor-tools-panel.component.scss'],
    providers: [SessionsEditorToolsPanelFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionsEditorToolsPanelComponent {
    vm$: Observable<SessionsEditorToolsPanelViewModel> = this.facade.vm$;

    tooltipTexts = tooltipTexts;
    iconSize = IconSize;
    iconColor = IconColor;

    constructor(private facade: SessionsEditorToolsPanelFacade) {}

    openEditSessionDialog(event: Event): void {
        this.facade.openEditSessionDialog();
        event.stopPropagation();
    }

    openAttributesEditor(event: Event): void {
        this.facade.openAttributesEditor();
        event.stopPropagation();
    }

    navigateToSessionTeam(event?: Event): void {
        this.facade.navigateToSessionTeam();
        event?.stopPropagation();
    }

    toggleDisplayAgenda(checked: boolean): void {
        this.facade.toggleDisplayAgenda(checked);
    }

    toggleSelfNavigate(checked: boolean): void {
        this.facade.toggleSelfNavigate(checked);
    }

    updateSessionThreads(checked: boolean): void {
        this.facade.updateSessionThreads(checked);
    }

    updateSessionThreadIsAnonymous(checked: boolean): void {
        this.facade.updateSessionThreadIsAnonymous(checked);
    }

    exportReport(event: Event): void {
        this.facade.exportReport();
        event.stopPropagation();
    }

    saveAsTemplate(event: Event): void {
        this.facade.openSaveAsTemplateDialog();
        event.stopPropagation();
    }

    deleteSession(event: Event, session: Session): void {
        this.facade.deleteSessionConfirmation(session);
        event.stopPropagation();
    }
}
