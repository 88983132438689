import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Timestamp } from 'firebase/firestore';
import { Observable } from 'rxjs';

import { Activity, ParentType, Project, PublicParentType, Template, tooltipTexts } from '@accenture/shared/data';
import { IconColor, IconSize } from '@accenture/shared/ui';
import { trackById } from '@accenture/shared/util';

import { accentureApproveBtnImgPath } from './constants';
import { TemplatePreviewFacade, TemplatePreviewModel } from './template-preview-facade';

@Component({
    selector: 'accenture-template-preview',
    templateUrl: './template-preview.component.html',
    styleUrls: ['./template-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [TemplatePreviewFacade],
})
export class TemplatePreviewComponent {
    vm$: Observable<TemplatePreviewModel> = this.facade.vm$;

    @Input() isTemplateOwner: boolean;
    @Input() isEditPending: boolean;
    @Input() isFavoriteTemplate: boolean;
    @Input() hasUnsavedChanges: boolean;
    @Input() canEditTemplate: boolean;
    @Output() useTemplateAction = new EventEmitter();
    @Output() saveToMyTemplatesAction = new EventEmitter();
    @Output() addToFavoritesAction = new EventEmitter();
    @Output() editTemplateAction = new EventEmitter();
    @Output() toggleTeamMembersPanel = new EventEmitter();

    trackById = trackById;
    accentureApproveBtnImgPath = accentureApproveBtnImgPath;
    iconColor = IconColor;
    iconSize = IconSize;
    imageError = false;
    tooltipTexts = tooltipTexts;

    constructor(private facade: TemplatePreviewFacade) {}

    openTeamMembersPanel(event: Event): void {
        event?.stopPropagation();
        this.toggleTeamMembersPanel.emit();
    }

    setImageError(): void {
        this.imageError = true;
    }

    isPublicActivityTemplate(templateType: PublicParentType): boolean {
        return templateType === ParentType.PublicActivityTemplates;
    }

    isPublicSessionTemplate(templateType: PublicParentType): boolean {
        return templateType === ParentType.PublicSessionTemplates;
    }

    getTemplateUpdateDate(template: Project | Template | Activity): Date {
        const updated = template.updated as Timestamp;
        return updated?.toDate ? updated.toDate() : new Date();
    }

    getImageUrl(imageUrl?: string): string {
        return imageUrl ? `url(${imageUrl})` : '';
    }

    getContentLength(vm: TemplatePreviewModel): string {
        return vm.templateType === ParentType.PublicProjectTemplates
            ? `${vm.sessions.length} session${vm.sessions.length === 1 ? '' : 's'}`
            : `${vm.activities.length} activit${vm.activities.length === 1 ? 'y' : 'ies'}`;
    }

    useTemplate(): void {
        this.useTemplateAction.emit();
    }

    saveToMyTemplates(): void {
        this.saveToMyTemplatesAction.emit();
    }

    addToFavorites(): void {
        this.addToFavoritesAction.emit();
    }

    editTemplate(): void {
        this.editTemplateAction.emit();
    }

    closePanel(): void {
        this.facade.closePanel();
    }
}
