import { createAction, props } from '@ngrx/store';

import { User } from '@accenture/shared/data';

export const retrieveAuthenticatedUser = createAction(
    '[Auth] Retrieve Authenticated User',
    props<{ firebaseUserId: string; }>(),
);

export const retrieveAuthenticatedUserSuccess = createAction(
    '[Auth] Retrieve Authenticated User Success',
    props<{ user: User }>(),
);

export const retrieveAuthenticatedUserFailure = createAction(
    '[Auth] Retrieve Authenticated User Failure',
    props<{ message: string }>(),
);

export const setUserIsAuthenticated = createAction(
    '[Auth] Set User is Authenticated',
    props<{ isAuthenticated: boolean }>(),
);

export const signOut = createAction('[Auth] Sign Out');

export const signOutSuccess = createAction('[Auth] Sign Out Success');
