import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, tap } from 'rxjs';

import {
    emptyScreenTitles,
    EmptyScreenType,
    inputPlaceholders,
    noItemsFoundImageUrl,
    ParentType,
} from '@accenture/shared/data';
import { LoadedDescription } from '@accenture/shared/ui';
import { trackById } from '@accenture/shared/util';

import { addActivityTemplateToSessionTitle } from '../use-template-dialog-tt9/constants';
import {
    AddActivityTemplateToSessionDialogTT9Facade,
    AddActivityTemplateToSessionDialogViewModel,
} from './add-activity-template-to-session-dialog-tt9-facade';

@Component({
    selector: 'accenture-add-activity-template-to-session-dialog-tt9',
    templateUrl: './add-activity-template-to-session-dialog-tt9.component.html',
    styleUrls: ['./add-activity-template-to-session-dialog-tt9.component.scss'],
    providers: [AddActivityTemplateToSessionDialogTT9Facade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddActivityTemplateToSessionDialogTT9Component {
    vm$: Observable<AddActivityTemplateToSessionDialogViewModel> = this.facade.vm$.pipe(
        tap(({ searchValue }) => this.searchControl.patchValue(searchValue)),
    );
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private facade: AddActivityTemplateToSessionDialogTT9Facade,
    ) {}

    loaderText: LoadedDescription;
    inputPlaceholders = inputPlaceholders;
    emptyScreenType = EmptyScreenType;
    emptyScreenTitles = emptyScreenTitles;
    emptyScreenPicture = noItemsFoundImageUrl;
    title = addActivityTemplateToSessionTitle;
    selectedTemplateId = '';
    searchControl = new FormControl<string>('', { nonNullable: true });
    trackById = trackById;

    closeDialog(): void {
        this.facade.closeDialog();
    }

    updateSearchValue(searchValue: string): void {
        this.facade.updateSearchValue(searchValue);
    }

    updateSelectedSession(sessionId: string): void {
        this.selectedTemplateId = sessionId;
        this.facade.updateSelectedTemplates(sessionId);
    }

    openUseTemplateModal(): void {
        this.facade.closeDialog();
        this.facade.openUseTemplateModal();
    }

    openCreateSessionModal(): void {
        this.facade.closeDialog();
        this.facade.openCreateSessionModal();
    }

    addTemplateToSession(selectedSessionId: string, templateType: ParentType): void {
        switch (templateType) {
            case ParentType.ActivityTemplates:
                this.addActivityTemplateToSession(selectedSessionId);
                break;
            case ParentType.PublicActivityTemplates:
                this.addPublicActivityTemplateToSession(selectedSessionId);
                break;
        }
    }

    addActivityTemplateToSession(selectedSessionId: string): void {
        this.facade.addActivityTemplateToSession(selectedSessionId);
    }

    addPublicActivityTemplateToSession(selectedSessionId: string): void {
        this.facade.addPublicActivityTemplateToSession(selectedSessionId);
    }

    resetSearchValue(): void {
        this.searchControl.patchValue('');
        this.facade.updateSearchValue('');
    }
}
