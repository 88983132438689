import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    signal,
} from '@angular/core';
import { NgxMicRecorderService } from 'ngx-mic-recorder';
import { firstValueFrom } from 'rxjs';

import { tooltipTexts } from '@accenture/shared/data';
import { LoaderSize } from '@accenture/shared/ui';

import { startRecording, stopRecording } from '../bot-chat/constants';
import { AiNarrateButtonFacade } from './ai-narrate-button-facade';

@Component({
    selector: 'accenture-ai-narrate-button',
    templateUrl: './ai-narrate-button.component.html',
    styleUrls: ['./ai-narrate-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [AiNarrateButtonFacade],
})
export class AiNarrateButtonComponent {
    recorder!: NgxMicRecorderService;
    @Input() onRecordStart: () => void;
    @Output() onRecordStop = new EventEmitter<string>();

    constructor(private facade: AiNarrateButtonFacade, private cdr: ChangeDetectorRef) {}

    vm$ = this.facade.vm$;
    loaderSize = LoaderSize.Small;
    tooltip = signal(tooltipTexts.narrate);

    isRecording(): boolean {
        return !!this.recorder;
    }

    async record(): Promise<void> {
        if (this.recorder) {
            const recording = await firstValueFrom(this.recorder.recordedBlobAsMp3$);
            this.recorder.stopRecording();
            delete this.recorder;
            const stop = new Audio(stopRecording);
            await stop.play();
            this.tooltip.set(tooltipTexts.transcribing);
            const feedback = await this.facade.transcribeRecording(recording);
            this.tooltip.set(tooltipTexts.narrate);
            this.onRecordStop.emit(feedback || '');
        } else {
            await this.onRecordStart();
            const start = new Audio(startRecording);
            await start.play();
            this.recorder = new NgxMicRecorderService();
            this.recorder.startRecording();
            this.tooltip.set(tooltipTexts.quieten);
        }
        this.cdr.detectChanges();
    }
}
