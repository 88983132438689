import { Timestamp } from 'firebase/firestore';

import { ProjectRole, SessionRole } from './access-control';
import { BaseFilterAntSortOptions, initialBaseFilterOptionsObject } from './default-filters-and-sort';
import { SortOrders } from './sort-orders';
import { UserStatus, UserType } from './user';

export const lazyLoadingTeamMembersLimit = 20;

export enum TeamMemberFilterType {
    TableValidationFeedback = 'tableValidationFeedback',
}

export const initialTeamMemberFilterAntSortMap: { [key in TeamMemberFilterType]: BaseFilterAntSortOptions } = {
    [TeamMemberFilterType.TableValidationFeedback]: initialBaseFilterOptionsObject,
};

export const projectRolesListForFilter = [ProjectRole.Admin, ProjectRole.Member];

export enum TeamMembersFilterSortBy {
    Name = 'displayName',
    Date = 'created',
    Role = 'role', // for fetching data from DB
}

export enum TeamMembersProjectFilterSortBy {
    Name = 'displayName',
    Email = 'email',
    LastLogin = 'lastLogin',
}

export enum TeamMembersSessionFilterSortBy {
    Name = 'displayName',
    Email = 'email',
    LastLogin = 'lastLogin',
}

//TODO: remove after project deprecation
export const projectTeamMembersSortByMap = {
    [TeamMembersProjectFilterSortBy.Name]: 'Name',
    [TeamMembersProjectFilterSortBy.Email]: 'Email',
    [TeamMembersProjectFilterSortBy.LastLogin]: 'Last login',
};

export const sessionTeamMembersSortByMap = {
    [TeamMembersSessionFilterSortBy.Name]: 'Name',
    [TeamMembersSessionFilterSortBy.Email]: 'Email',
    [TeamMembersSessionFilterSortBy.LastLogin]: 'Last login',
};

export const teamMembersSortByMap = {
    [TeamMembersFilterSortBy.Name]: 'User name',
    [TeamMembersFilterSortBy.Date]: 'Date added',
};

export const sessionRolesListForFilter = [SessionRole.Leader, SessionRole.Participant];

export interface TeamMemberFilters {
    sessionRoles: SessionRole[];
    projectRoles: ProjectRole[];
    statuses: UserStatus[];
    types: UserType[];
    sortBy: TeamMembersFilterSortBy;
    sortOrder: SortOrders;
}

export const initialTeamMemberFilters: TeamMemberFilters = {
    sessionRoles: [],
    projectRoles: [],
    statuses: [],
    types: [],
    sortBy: TeamMembersFilterSortBy.Date,
    sortOrder: SortOrders.Asc,
};

export interface TeamMemberProjectFilters {
    roles?: ProjectRole[];
    ownership?: boolean;
    lastLoginFrom?: Timestamp;
    lastLoginTo?: Timestamp;
    sortBy?: TeamMembersProjectFilterSortBy;
    sortOrder?: SortOrders;
}

export interface TeamMemberSessionFilters {
    roles?: SessionRole[];
    ownership?: boolean;
    lastLoginFrom?: Timestamp;
    lastLoginTo?: Timestamp;
    sortBy?: TeamMembersSessionFilterSortBy;
    sortOrder?: SortOrders;
}

//TODO: remove after project deprecation
export const initialUserProjectFilters: TeamMemberProjectFilters = {
    roles: [],
    ownership: false,
    lastLoginFrom: null,
    lastLoginTo: null,
    sortBy: TeamMembersProjectFilterSortBy.Name,
    sortOrder: SortOrders.Asc,
};

export const initialUserSessionsFilters: TeamMemberSessionFilters = {
    roles: [],
    ownership: false,
    lastLoginFrom: null,
    lastLoginTo: null,
    sortBy: TeamMembersSessionFilterSortBy.Name,
    sortOrder: SortOrders.Asc,
};
