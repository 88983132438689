import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'accenture-info-dialog',
    templateUrl: './info-dialog.component.html',
    styleUrls: ['./info-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<InfoDialogComponent>,
        private sanitizer: DomSanitizer,
    ) {}

    get safeHTML(): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(this.data?.text || '');
    }

    closeDialog(): void {
        this.dialogRef.close();
    }
}
