import { FieldValue, Timestamp } from 'firebase/firestore';

import { ActivityType } from './activities';
import { Dictionary } from './dictionary';
import { LinkAccessPasswords } from './link-access';
import { NodeTimestamp } from './node-timestamp';
import { PublicParentType } from './parent-type';

export interface PublicAccess extends NodeTimestamp {
    id?: string;
    templateId: string;
    templateType: PublicParentType;
    activityType?: ActivityType;

    timesUsed?: number;
    ownerId?: string;
    ownerDisplayName?: string;
    ownerImageUrl?: string;
    imageUrl?: string;
    name?: string;
    description?: string;
    sessions?: Dictionary<any>;
    tags?: Dictionary<string>;
    clients?: Dictionary<string>;
    phases?: Dictionary<string>;
    subPhases?: Dictionary<string>;
    likes?: Dictionary<boolean>;
    approved?: boolean;
    hidden?: boolean;
    metadata?: Dictionary<string>;
    creatorNotes?: string;
    favoritesCount?: number;
    featured?: boolean;
    draftTemplateId?: string;
    hideWelcomeSnackBar?: boolean;
    linkAccessPasswords?: LinkAccessPasswords;
    lastViewed?: Timestamp | FieldValue;
    teamMembersIds?: string[]; // no roles here -  only collaborators who can edit also
}
