import { ParameterExtrasType } from '../activities';
import { defaultAttributeClassId, defaultAttributeId } from '../attribute';
import { Dictionary } from '../dictionary';
import { BaseResponse } from './response';

export class VoteResponse extends BaseResponse {
    voteItemId: string;
    parameterId: string;
    attributes?: Dictionary<boolean>; // {[attributeId]: true}
    attributeClasses?: Dictionary<boolean>; // {[attributeClassId]: true}

    // add needed other fields here
    constructor(response: VoteResponse) {
        super(response);
        this.voteItemId = response?.voteItemId;
        this.parameterId = response?.parameterId;
        this.attributes = response?.attributes ?? {
            [defaultAttributeId]: true,
        };
        this.attributeClasses = response?.attributeClasses ?? {
            [defaultAttributeClassId]: true,
        };
    }
}

export type VoteSummaryNumericResponseValue = { [key in ParameterExtrasType]: number };

export class VoteSummaryResponse extends VoteResponse {
    summaryValue: string[] | VoteSummaryNumericResponseValue | number;
    abstained?: number;
}
