import { Injectable } from '@angular/core';
import { unset } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    CollectionOptions,
    collectionOptionsArray,
    CollectionOptionsType,
    CollectionsSortObject,
    DBPathHelper,
    ParentType,
    SelectedCollectionOptions,
} from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable({
    providedIn: 'root',
})
export class CollectionOptionsService {
    constructor(private firestoreService: FirestoreService) {}

    getNewOptionId(): string {
        return this.firestoreService.getPushId();
    }

    getOptionPath(type: CollectionOptions, id?: string): string {
        // Keeping this open for other option types that can be added for collections
        return DBPathHelper.getTagsPath(id);
    }

    getOptions(type: CollectionOptions): Observable<CollectionOptionsType[]> {
        return this.firestoreService.getDocumentsByMultipleProperties<CollectionOptionsType>(
            this.getOptionPath(type),
            new Map<string, any>([['type', type === CollectionOptions.Tag ? ParentType.Collections : '']]),
            'name',
        );
    }

    addOption(type: CollectionOptions, name: string, collectionId: string): Promise<string> {
        const newOption = { name, collections: [collectionId], updated: this.firestoreService.timestamp };

        return this.firestoreService.addDocument(this.getOptionPath(type), newOption);
    }

    getCurrentUserCollectionsSorting(userId: string | undefined): Observable<CollectionsSortObject> {
        return this.firestoreService
            .getDocument<CollectionsSortObject>(`/users/${userId}/filters/collectionsFilters`)
            .pipe(
                map((filter: CollectionsSortObject) => {
                    unset(filter, 'id');
                    return filter;
                }),
            );
    }

    async updateCollectionsSorting(userId: string | undefined, data: CollectionsSortObject): Promise<void> {
        await this.firestoreService.upsert(`users/${userId}/filters/collectionsFilters`, data);
    }

    async updateOptions(
        selectedCollectionOptions: SelectedCollectionOptions,
        collectionId: string,
        optionsToRemoveIds?: { [key: string]: string[] },
        optionsToCreateIds?: { [key: string]: string[] },
    ): Promise<void> {
        const batchToUpdate = [];

        collectionOptionsArray.forEach((type) => {
            let option = {
                collections: [collectionId],
                created: this.firestoreService.timestamp,
                updated: this.firestoreService.timestamp,
            } as CollectionOptionsType;

            if (CollectionOptions.Tag === type) {
                option = {
                    ...option,
                    type: ParentType.Collections,
                };
            }

            Object.keys(selectedCollectionOptions[type] || {}).forEach((optionId) => {
                // create new option
                if ((optionsToCreateIds?.[type] || []).includes(optionId)) {
                    option = {
                        ...option,
                        name: selectedCollectionOptions[type][optionId],
                    };

                    batchToUpdate.push({
                        path: this.getOptionPath(type, optionId),
                        data: option,
                    });
                } else {
                    // update option
                    batchToUpdate.push({
                        path: this.getOptionPath(type, optionId),
                        data: {
                            collections: this.firestoreService.arrayUnion(collectionId),
                            updated: this.firestoreService.timestamp,
                        },
                    });
                }
            });

            if (optionsToRemoveIds) {
                (optionsToRemoveIds[type] || []).forEach((optionId) => {
                    batchToUpdate.push({
                        path: this.getOptionPath(type, optionId),
                        data: {
                            collections: this.firestoreService.arrayRemove(collectionId),
                            updated: this.firestoreService.timestamp,
                        },
                    });
                });
            }
        });

        await this.firestoreService.writeBatch(batchToUpdate);
    }
}
