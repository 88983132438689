<mat-form-field class="tt9-form-field no-label no-margin width-100" [appearance]="appearance" [floatLabel]="floatLabel">
  <mat-select
    [value]="defaultValue"
    [panelClass]="['tt9-select-panel-custom']"
    [ngClass]="{ 'tt9-body-2': isSearch }"
    (selectionChange)="selectionClick.emit($event)"
  >
    <ng-container *ngIf="!isSearch; else searchOptions">
      <mat-option *ngFor="let items of sessionDropdownFilter; trackBy: trackById" [value]="items">
        <span>{{ displaySessionSelectRole[items] }}</span>
      </mat-option>
    </ng-container>

    <ng-template #searchOptions>
      <mat-option *ngFor="let items of searchFilterOptions; trackBy: trackById" class="tt9-body-1" [value]="items">
        <span>{{ items }}</span>
      </mat-option>
    </ng-template>
  </mat-select>
</mat-form-field>
