import { Timestamp } from 'firebase/firestore';

export class Comment {
    id?: string;
    sessionId?: string;
    responseId: string;
    activityItemId: string;
    userId?: string;
    userDisplayName?: string;
    userImageUrl?: string;
    value: string;
    activityId: string;
    created: Timestamp;
    updated: Timestamp;

    constructor(comment: Partial<Comment>) {
        this.id = comment.id ?? '';
        this.sessionId = comment.sessionId ?? '';
        this.responseId = comment.responseId ?? '';
        this.activityItemId = comment.activityItemId ?? '';
        this.userId = comment.userId ?? '';
        this.userDisplayName = comment.userDisplayName ?? '';
        this.userImageUrl = comment.userImageUrl ?? '';
        this.value = comment.value ?? '';
        this.activityId = comment.activityId ?? '';
        this.created = comment.created ?? Timestamp.now();
        this.updated = comment.updated ?? Timestamp.now();
    }

    createSerializableObject(): Comment {
        return {
            responseId: this.responseId,
            sessionId: this.sessionId,
            activityItemId: this.activityItemId,
            userId: this.userId,
            userDisplayName: this.userDisplayName,
            userImageUrl: this.userImageUrl,
            value: this.value,
            created: this.created,
            updated: this.updated,
            activityId: this.activityId,
        } as Comment;
    }
}
