import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { fromToDateValidator } from '@accenture/erp-deployment/shared/domain';
import {
    characterLimitMedium,
    characterLimitText,
    characterLimitXL,
    determineHintClass,
    FileType,
    inputPlaceholders,
    SessionOptions,
    sessionOptionsDialogTitle,
    validationMessages,
} from '@accenture/shared/data';
import { ImageInputMode, ImageInputPlaceholdersTypes } from '@accenture/shared/ui';

import { createSessionToUseActivityTemplate } from '../use-template-dialog-tt9/constants';
import { CreateSessionDialogTT9Facade } from './create-session-dialog-tt9-facade';

type SessionForm = FormGroup<{
    name: FormControl<string>;
    description: FormControl<string>;
    startDate: FormControl<Date | null>;
    endDate: FormControl<Date | null>;
}>;

@Component({
    selector: 'accenture-create-session-dialog-tt9',
    templateUrl: './create-session-dialog-tt9.component.html',
    styleUrls: ['./create-session-dialog-tt9.component.scss'],
    providers: [CreateSessionDialogTT9Facade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateSessionDialogTT9Component {
    vm$ = this.facade.vm$;

    imageInputMode = ImageInputMode;
    sessionForm: SessionForm = this.formBuilder.group(
        {
            name: new FormControl('', { nonNullable: true, validators: Validators.required }),
            description: new FormControl('', { nonNullable: true }),
            startDate: new FormControl(null),
            endDate: new FormControl(null),
        },
        {
            validators: fromToDateValidator('startDate', 'endDate'),
        },
    );
    // subTitle = useTemplateStepTwo;
    imageInputPlaceholder = ImageInputPlaceholdersTypes.Session;
    validationMessages = validationMessages;
    inputPlaceholders = inputPlaceholders;
    characterLimitMedium = characterLimitMedium;
    descriptionCharacterLimit = characterLimitXL;
    characterLimitText = characterLimitText;
    determineHintClass = determineHintClass;

    constructor(private facade: CreateSessionDialogTT9Facade, private formBuilder: FormBuilder) {}

    get sessionStartDateControl(): FormControl<Date | null> {
        return this.sessionForm.controls.startDate;
    }

    get sessionEndDateControl(): FormControl<Date | null> {
        return this.sessionForm.controls.endDate;
    }

    get isEndDateGreaterStartDateErrorVisibility(): boolean {
        const toDateControl = this.sessionForm && this.sessionEndDateControl;
        return toDateControl && toDateControl.hasError('invalidDate');
    }

    clearDateInput(controlName: string, event: Event): void {
        event.stopPropagation();
        this.sessionForm.get(controlName).reset();
    }

    getTitle(currentOptionToDisplay: SessionOptions | null): string {
        return currentOptionToDisplay
            ? sessionOptionsDialogTitle[currentOptionToDisplay]
            : createSessionToUseActivityTemplate;
    }

    closeDialog(): void {
        this.facade.closeDialog();
    }

    setSessionImage(sessionImage: FileType): void {
        this.facade.setSessionImage(sessionImage);
    }

    deleteSessionImage(): void {
        const projectImage = {} as FileType;

        this.facade.setSessionImage(projectImage);
    }

    createSession(): void {
        this.closeDialog();
        const { name, description, startDate, endDate } = this.sessionForm.value;

        if (startDate || endDate) {
            this.facade.createSession({ name, description, startDate, endDate });
        } else {
            this.facade.createSession({ name, description });
        }
    }

    openUseTemplateModal(currentOptionToDisplay: SessionOptions | null): void {
        if (currentOptionToDisplay) {
            this.facade.setCurrentOptionToDisplay(null);
        } else {
            this.facade.openUseTemplateModal();
        }
    }
}
