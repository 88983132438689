import { FieldValue, Timestamp } from 'firebase/firestore';

import { CollectionRole, SessionRole } from './access-control';
import { Dictionary } from './dictionary';
import { NodeTimestamp } from './node-timestamp';

export interface UserSession extends NodeTimestamp {
    id?: string;
    userId?: string;
    sessionId?: string;
    creatorId?: string;
    creatorImage?: string;
    creatorName?: string;
    clientId?: string;
    projectId?: string;
    tags?: Dictionary<string>;
    phase?: Dictionary<string>;
    subPhase?: Dictionary<string>;
    imageUrl?: string;
    name?: string;
    description?: string;
    role: SessionRole | CollectionRole;
    status?: string;
    sequence?: string;
    startDate?: Timestamp;
    endDate?: Timestamp;
    lastViewed?: Timestamp | FieldValue;

    markedForDelete?: Timestamp;
    deletedByUserId?: string;

    //added fields in showing of folder icon for sessions tied w/ collections
    collectionId?: string;
    collectionName?: string;
    color?: string;
}
