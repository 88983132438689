import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'displayObjectValues',
})
export class DisplayObjectValuesPipe implements PipeTransform {
    transform(object: object | Map<any, string>): unknown {
        return Object.values(object || {})
            .join(', ')
            .trim();
    }
}
