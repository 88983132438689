import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { isUndefined } from 'lodash';
import { Observable, of, zip } from 'rxjs';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';

import { AppState, selectAuthenticatedUserId } from '@accenture/global-store';
import {
    Activity,
    LinkAccess,
    notFoundUrl,
    ParentType,
    Project,
    Session,
    SessionFocus,
    TeamMember,
} from '@accenture/shared/data';

import { ActivityService } from '../services/activity.service';
import { LinkAccessService } from '../services/link-access.service';
import { ProjectService } from '../services/project.service';
import { SessionService } from '../services/session.service';
import { SessionFocusService } from '../services/session-focus.service';
import { TeamMemberService } from '../services/team-member.service';
import { getRouterParams, isActiveActivityLinkAccess } from '../utils';

@Injectable()
export class SessionGuard {
    constructor(
        private router: Router,
        private store: Store<AppState>,
        private sessionFocusService: SessionFocusService,
        private sessionService: SessionService,
        private projectService: ProjectService,
        private teamMemberService: TeamMemberService,
        private activityService: ActivityService,
        private linkAccessService: LinkAccessService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const { projectId, sessionId, activityId } = getRouterParams(route);

        if (projectId && sessionId) {
            return this.resolveRouteByFocusState(projectId, sessionId, activityId, state, route.queryParams);
        }

        return of(false);
    }

    private resolveRouteByFocusState(
        projectId: string,
        sessionId: string,
        activityId: string,
        state: RouterStateSnapshot,
        queryParams?: Params,
    ): Observable<boolean> {
        const activityIdFromLinkAccess = queryParams?.activityIdFromLinkAccess;

        return this.store.select(selectAuthenticatedUserId).pipe(
            skipWhile(userId => isUndefined(userId)),
            take(1),
            switchMap(userId =>
                zip([
                    this.sessionService.getSession(ParentType.Projects, projectId, sessionId),
                    this.sessionFocusService.getSessionFocus(projectId, sessionId),
                    this.teamMemberService.getSessionTeamMember(projectId, sessionId, userId),
                    this.projectService.getProject(ParentType.Projects, projectId),
                    activityId ? this.activityService.getActivityById(projectId, activityId) : of(null),
                    activityIdFromLinkAccess
                        ? this.linkAccessService.getLinkAccessByActivity(activityIdFromLinkAccess)
                        : of(null),
                ]),
            ),
            map(
                ([session, focus, teamMember, project, activity, activityLinkAccess]: [
                    Session,
                    SessionFocus | undefined,
                    TeamMember,
                    Project,
                    Activity | null,
                    LinkAccess | null,
                ]) => {
                    const fromActiveLink = isActiveActivityLinkAccess(activityLinkAccess);

                    if (!!project?.markedForDelete) {
                        this.router.navigate(['home']);
                        return false;
                    }

                    if (!!session?.markedForDelete || !session || !teamMember) {
                        this.router.navigateByUrl(notFoundUrl);
                        return false;
                    }

                    if (teamMember?.isSessionLeader || session.selfNavigate || fromActiveLink) {
                        return true;
                    }

                    if (teamMember?.isSessionParticipant && activity && !activity.visible) {
                        this.router.navigateByUrl(notFoundUrl);
                        return false;
                    }

                    if (!focus) {
                        if (!teamMember?.isSessionLeader && !session.displayAgenda) {
                            this.redirectToWaitingScreen(projectId, sessionId, state);
                        }

                        return true;
                    }

                    // To forbid navigation to Session page for Participant when displayAgenda is disabled.
                    const urlParams = this.sessionFocusService.getActivityRedirectUrl(projectId, sessionId, focus);
                    if (`/${urlParams.url.split('?')[0]}` !== state.url.split('?')[0]) {
                        this.router.navigate([urlParams.url], urlParams.params);
                        return false;
                    }
                    return true;
                },
            ),
        );
    }

    private redirectToWaitingScreen(projectId: string, sessionId: string, state: RouterStateSnapshot): boolean {
        const urlArray = ['project', projectId, 'session', sessionId, 'waiting-screen'];

        if (state.url.includes(urlArray.join('/'))) {
            return true;
        }
        this.router.navigate(urlArray);
        return false;
    }
}
