import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { Dictionary } from '@accenture/shared/data';

export class VoteFormGroup extends FormGroup {
    remainingValueChanges = new BehaviorSubject<Dictionary<Dictionary<number>>>({});

    voteTopXValidatorValueChanges = new BehaviorSubject<Dictionary<Dictionary<number>>>({});

    selectedVoteItemsCountChanges = new BehaviorSubject<Dictionary<Dictionary<number>>>({});

    voteFormErrorsMap$ = new BehaviorSubject<Dictionary<Dictionary<boolean>>>({});

    isFormValidated$ = new BehaviorSubject<boolean>(false);

    get voteTopXValidatorValue(): Dictionary<Dictionary<number>> {
        return this.voteTopXValidatorValueChanges.getValue();
    }

    get remainingValue(): Dictionary<Dictionary<number>> {
        return this.remainingValueChanges.getValue();
    }

    get selectedVoteItemsCount(): Dictionary<Dictionary<number>> {
        return this.selectedVoteItemsCountChanges.getValue();
    }

    get voteFormErrorsMap(): Dictionary<Dictionary<boolean>> {
        return this.voteFormErrorsMap$.getValue();
    }

    setVoteTopXValidatorValue(voteTopXValidatorValue: number, parameterId: string, attributeId: string): void {
        this.voteTopXValidatorValueChanges.next({
            ...this.voteTopXValidatorValue,
            [parameterId]: {
                ...this.voteTopXValidatorValue[parameterId],
                [attributeId]: voteTopXValidatorValue,
            },
        });
    }

    setRemainingValue(remainingValue: number, parameterId: string, attributeId: string): void {
        this.remainingValueChanges.next({
            ...this.remainingValue,
            [parameterId]: {
                ...this.remainingValue[parameterId],
                [attributeId]: remainingValue,
            },
        });
    }

    setSelectedVoteItemsCount(selectedVoteItemsCount: number, parameterId: string, attributeId: string): void {
        this.selectedVoteItemsCountChanges.next({
            ...this.selectedVoteItemsCount,
            [parameterId]: {
                ...this.selectedVoteItemsCount[parameterId],
                [attributeId]: selectedVoteItemsCount,
            },
        });
    }

    setVoteFormErrorsMap(hasError: boolean, parameterId: string, attributeId: string): void {
        this.voteFormErrorsMap$.next({
            ...this.voteFormErrorsMap,
            [parameterId]: {
                ...this.voteFormErrorsMap[parameterId],
                [attributeId]: hasError,
            },
        });
    }

    setIsFormValidated(isValidated: boolean): void {
        this.isFormValidated$.next(isValidated);
    }
}
