import { AccessControlRole } from '../access-control';
import { NodeTimestamp } from '../node-timestamp';
import { Project } from '../project';
import { Session } from '../session';
import { User } from '../user';
import { InAppNotificationType } from './in-app-notification-type';

export const lazyLoadingInAppNotificationsLimit = 20;

export interface InAppNotificationData {
    user?: User;
    project?: Project;
    session?: Session;
    role?: AccessControlRole;
}

export abstract class BaseInAppNotification extends NodeTimestamp {
    id: string | undefined;
    type: InAppNotificationType;
    userId: string;
    unread: boolean;

    constructor(notification: BaseInAppNotification);
    constructor(type: InAppNotificationType, userId: string);
    constructor(notificationOrType: BaseInAppNotification | InAppNotificationType, userId?: string) {
        if (typeof notificationOrType === 'object') {
            super(notificationOrType);

            this.id = notificationOrType.id;
            this.type = notificationOrType.type;
            this.userId = notificationOrType.userId;
            this.unread = notificationOrType.unread;
        } else {
            super({});

            this.type = notificationOrType;
            this.userId = userId;
            this.unread = true;
        }
    }

    abstract createSerializableObject(): Record<string, unknown>;
}
