import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Observable, of, switchMap } from 'rxjs';

import { Attribute, AttributeClass, DBPathHelper, Dictionary, ParentType } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import { sortBySequenceAsc } from '@accenture/shared/util';

@Injectable({
    providedIn: 'root',
})
export class AttributeService {
    activeAttributeClassId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(private firestoreService: FirestoreService) {}

    addAttribute(parentType: ParentType, parentId: string, attribute: Attribute): Promise<string> {
        return this.firestoreService.addDocument(DBPathHelper.getAttributesPath(parentType, parentId), attribute);
    }

    async saveUserAttributes(sessionId: string, teamMemberId: string, attributes: Dictionary<string[]>): Promise<void> {
        await this.firestoreService.update(
            `${DBPathHelper.getSessionPathNew(ParentType.Sessions, sessionId)}/teamMembers/${teamMemberId}/`,
            {
                attributes,
            },
        );
    }

    async updateUserAttributesInSessions(
        sessionIds: string[],
        teamMemberId: string,
        attributes: Dictionary<string[]>,
    ): Promise<void> {
        const batchData = [];

        for (const sessionId of sessionIds) {
            batchData.push({
                path: `sessions/${sessionId}/teamMembers/${teamMemberId}/`,
                data: { attributes },
            });
        }

        await this.firestoreService.writeBatch(batchData);
    }

    async addDefaultAttributeClass(
        parentType: ParentType,
        parentId: string,
        defaultAttributeObject: AttributeClass,
    ): Promise<void> {
        const attributeClass = {
            name: defaultAttributeObject.name,
            sequence: defaultAttributeObject.sequence,
        };
        await this.firestoreService.addDocumentWithKey(
            DBPathHelper.getAttributesClassesPath(parentType, parentId),
            defaultAttributeObject.id,
            attributeClass,
        );

        const attributes = defaultAttributeObject.attributes || [];
        const batchData = [];

        for (const attribute of attributes) {
            const data = {
                classId: defaultAttributeObject.id,
                name: attribute.name || '',
                sequence: attribute.sequence || '',
            };
            batchData.push({
                path: DBPathHelper.getAttributesPath(parentType, parentId, attribute.id),
                data,
            });
        }

        await this.firestoreService.writeBatch(batchData);
    }

    async addAttributeClass(
        parentType: ParentType,
        parentId: string,
        attributeObject: Partial<AttributeClass>,
    ): Promise<string> {
        const classId = this.firestoreService.getPushId();
        const attributeClass = {
            name: attributeObject.name,
            sequence: attributeObject.sequence,
        };

        await this.firestoreService.addDocumentWithKey(
            DBPathHelper.getAttributesClassesPath(parentType, parentId),
            classId,
            attributeClass,
        );

        const attributes = attributeObject.attributes || [];
        const batchData = [];

        for (const attribute of attributes) {
            const data = {
                classId: classId,
                name: attribute.name || '',
                sequence: attribute.sequence || '',
            };
            batchData.push({
                path: DBPathHelper.getAttributesPath(parentType, parentId, attribute.id),
                data,
            });
        }
        await this.firestoreService.writeBatch(batchData);

        return classId;
    }

    async updateAttributeClass(
        parentType: ParentType,
        parentId: string,
        attributeClassId: string,
        attributeObject: Partial<AttributeClass>,
        deleteAttributes: Attribute[],
    ): Promise<void> {
        if (deleteAttributes.length) {
            await this.deleteAttributes(parentType, parentId, deleteAttributes, false);
        }

        const batchData = [];
        const attributeClass = {
            name: attributeObject.name,
            sequence: attributeObject.sequence,
        };
        batchData.push({
            path: DBPathHelper.getAttributesClassesPath(parentType, parentId, attributeClassId),
            data: attributeClass,
        });

        const attributes = attributeObject.attributes || [];

        for (const attribute of attributes) {
            const data = {
                classId: attributeClassId,
                name: attribute.name || '',
                sequence: attribute.sequence || '',
            };

            batchData.push({
                path: DBPathHelper.getAttributesPath(parentType, parentId, attribute.id),
                data,
            });
        }

        await this.firestoreService.writeBatch(batchData);
    }

    getDefaultAttributesByClassId(classId: string): Observable<Attribute[]> {
        return this.firestoreService
            .getCollection<Attribute>(`/attributeClasses/${classId}/attributes`)
            .pipe(map((attributes) => attributes.sort((a, b) => a.name.localeCompare(b.name))));
    }

    getDefaultAttributeClasses(): Observable<AttributeClass[]> {
        return this.firestoreService.getCollection<AttributeClass>('/attributeClasses').pipe(
            switchMap((classes) =>
                combineLatest([of(classes), ...classes.map(({ id }) => this.getDefaultAttributesByClassId(id))]),
            ),
            map(([classes, ...attributes]: any[]) =>
                classes.map((attributeClass, index) => ({
                    ...attributeClass,
                    attributes: attributes[index],
                })),
            ),
        );
    }

    getAttributesByClassId(
        parentType: ParentType,
        parentId: string,
        attributeClassId: string,
    ): Observable<Attribute[]> {
        return this.firestoreService.getDocumentsByProperty<Attribute>(
            DBPathHelper.getAttributesPath(parentType, parentId),
            'classId',
            attributeClassId,
        );
    }

    getAttributesClassById(
        parentType: ParentType,
        parentId: string,
        attributeClassId: string,
    ): Observable<AttributeClass> {
        return this.firestoreService.getDocument<AttributeClass>(
            DBPathHelper.getAttributesClassesPath(parentType, parentId, attributeClassId),
        );
    }

    getAttributeClassWithAttributes(
        parentType: ParentType,
        parentId: string,
        attributeClassId: string,
    ): Observable<{ attributes: Attribute[]; attributeClass: AttributeClass }> {
        if (!parentType || !parentId || !attributeClassId) {
            return of({
                attributes: [] as Attribute[],
                attributeClass: {
                    name: 'New attribute group',
                } as AttributeClass,
            });
        }

        return combineLatest([
            this.getAttributesByClassId(parentType, parentId, attributeClassId),
            this.getAttributesClassById(parentType, parentId, attributeClassId),
        ]).pipe(
            map(([attributes, attributeClass]) => ({
                attributes: sortBySequenceAsc(attributes),
                attributeClass,
            })),
        );
    }

    deleteAttributes(
        parentType: ParentType,
        parentId: string,
        attributes: Attribute[],
        deleteOnlyResponses?: boolean,
    ): Promise<void> {
        return this.firestoreService.cloudFunctionCallable('deleteAttributes', {
            attributes,
            parentType,
            parentId,
            deleteOnlyResponses,
        });
    }

    deleteAttributeClass(parentType: ParentType, parentId: string, attributeClassId: string): Promise<void> {
        return this.firestoreService.cloudFunctionCallable('deleteAttributeClass', {
            attributeClassId,
            parentType,
            parentId,
        });
    }

    getAttributes(parentType: ParentType, parentId: string): Observable<Attribute[]> {
        return this.firestoreService
            .getCollection<Attribute>(DBPathHelper.getAttributesPath(parentType, parentId))
            .pipe(map((attributes) => sortBySequenceAsc(attributes)));
    }

    getAttributeClasses(parentType: ParentType, parentId: string): Observable<AttributeClass[]> {
        return this.firestoreService
            .getCollection<AttributeClass>(DBPathHelper.getAttributesClassesPath(parentType, parentId))
            .pipe(map((classes) => sortBySequenceAsc(classes)));
    }

    getAttributeClassesWithAttributes(parentType: ParentType, parentId: string): Observable<AttributeClass[]> {
        return combineLatest([
            this.getAttributeClasses(parentType, parentId),
            this.getAttributes(parentType, parentId),
        ]).pipe(
            map(([attributeClasses, attributes]) => {
                const attributeClassesWithAttributes = attributeClasses.map((attributeClass) => {
                    attributeClass.attributes = attributes.filter(
                        (attribute) => attribute.classId === attributeClass.id,
                    );
                    return attributeClass;
                });
                return attributeClassesWithAttributes;
            }),
        );
    }

    updateAttributeName(parentType: ParentType, parentId: string, attribute: Attribute): Promise<void> {
        return this.firestoreService.updateDoc<Attribute>(`${parentType}/${parentId}/attributes/${attribute.id}`, {
            name: attribute.name,
        });
    }

    updateClassName(parentType: ParentType, parentId: string, attributeClass: AttributeClass): Promise<void> {
        return this.firestoreService.updateDoc<AttributeClass>(
            `${parentType}/${parentId}/attributeClasses/${attributeClass.id}`,
            {
                name: attributeClass.name,
            },
        );
    }

    setActiveClass(classId: string | null): void {
        this.activeAttributeClassId$.next(classId);
    }
}
