import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, Inject, Injector, Input, OnInit } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormControlDirective,
    FormControlName,
    FormGroupDirective,
    FormsModule,
    NG_VALUE_ACCESSOR,
    NgControl,
    NgModel,
    ReactiveFormsModule,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isEqual } from 'lodash';
import { debounceTime, Observable } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';

import {
    characterLimitMedium,
    characterLimitText,
    determineHintClass,
    inputPlaceholders,
    validationErrors,
    validationMessages,
} from '@accenture/shared/data';
import { SharedMaterialModule } from '@accenture/shared/material';
import { SharedUiModule } from '@accenture/shared/ui';
import { trackByValue } from '@accenture/shared/util';

import { EmailInvitationFormFacade } from './email-invitation-form-facade';

@UntilDestroy()
@Component({
    selector: 'accenture-email-invitation-form',
    templateUrl: './email-invitation-form.component.html',
    styleUrls: ['./email-invitation-form.component.scss'],
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedMaterialModule, SharedUiModule],
    providers: [
        EmailInvitationFormFacade,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EmailInvitationFormComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailInvitationFormComponent implements OnInit, ControlValueAccessor {
    @Input() emailListName = '';
    @Input() blackListEmails: string[] = [];
    @Input() isNonAccentureAvailableRole = false;
    @Input() hideChips = false;
    @Input() restrictedEmailsInvitationText?: string;

    control!: FormControl<string[]>;
    emailsInputControl = new FormControl<string>('', { nonNullable: true });
    emailsAutoComplete$ = this.getEmailsAutoComplete();

    validationMessages = validationMessages;
    validationErrors = validationErrors;
    inputPlaceholders = inputPlaceholders;
    characterLimitMedium = characterLimitMedium;
    trackByValue = trackByValue;
    characterLimitText = characterLimitText;
    determineHintClass = determineHintClass;

    constructor(@Inject(Injector) private injector: Injector, private readonly facade: EmailInvitationFormFacade) {}

    ngOnInit(): void {
        this.setComponentControl();
    }

    registerOnChange(): void {
        this.emailsInputControl.markAsTouched();
    }

    registerOnTouched(): void {
        this.emailsInputControl.markAsTouched();
    }

    writeValue(): void {
        this.emailsInputControl.markAsTouched();
    }

    getRestrictedEmailMessage(): string {
        return this.restrictedEmailsInvitationText
            ? validationMessages.restrictedEmail(this.restrictedEmailsInvitationText)
            : validationMessages.restrictedEmail('Invitation');
    }

    removeChip(email: string): void {
        this.removeEmails([email]);
    }

    async addChip(): Promise<void> {
        const control = this.emailsInputControl;

        if (!control.value) {
            this.control.setErrors({});
            return;
        }

        const { errorEmails, correctEmails, invalidEmail, nonAcnEmail } = await this.facade.parseEmails(
            control,
            this.control.value,
            this.blackListEmails,
            this.isNonAccentureAvailableRole,
        );

        this.addEmails(correctEmails);

        switch (true) {
            case nonAcnEmail: {
                this.updateFormControl(control, errorEmails.join(' '), {
                    [this.validationErrors.nonAcnEmail]: true,
                });
                break;
            }
            case invalidEmail: {
                this.updateFormControl(control, errorEmails.join(' '), {
                    [this.validationErrors.invalidEmail]: true,
                });
                break;
            }
            case !!errorEmails.length: {
                this.updateFormControl(control, errorEmails.join(' '), {
                    [this.validationErrors.restrictedEmail]: true,
                });
                break;
            }
            default: {
                control.setValue('');
                this.control.setErrors({ [validationErrors.pattern]: true });
                break;
            }
        }
    }

    private getEmailsAutoComplete(): Observable<string[]> {
        const emailsValue$ = this.emailsInputControl.valueChanges.pipe(
            debounceTime(200),
            distinctUntilChanged(isEqual),
        );

        return this.facade.getEmailsAutoComplete(emailsValue$);
    }

    private updateFormControl(control: AbstractControl, value: string, error: { [errorName: string]: boolean }): void {
        control.setValue(value);
        control.setErrors(error);
        this.control.setErrors(null);
        control.markAsTouched();
    }

    private addEmails(emails: string[]): void {
        const newEmails: string[] = [...new Set([...emails, ...this.control.value])];

        this.control.setValue(newEmails);
    }

    private removeEmails(emails: string[]): void {
        const currentEmailsSet = new Set(this.control.value);

        emails.forEach(email => currentEmailsSet.delete(email));

        this.control.setValue([...currentEmailsSet]);
    }

    private setComponentControl(): void {
        const injectedControl = this.injector.get(NgControl);

        switch (injectedControl.constructor) {
            case NgModel: {
                const { control, update } = injectedControl as NgModel;

                this.control = control;

                this.control.valueChanges
                    .pipe(
                        tap(emails => update.emit([...new Set(emails)])),
                        untilDestroyed(this),
                    )
                    .subscribe();
                break;
            }
            case FormControlName: {
                this.control = this.injector.get(FormGroupDirective).getControl(injectedControl as FormControlName);
                break;
            }
            default: {
                this.control = (injectedControl as FormControlDirective).form as FormControl;
                break;
            }
        }
    }
}
