import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Action, Message } from '@progress/kendo-angular-conversational-ui';
import {
    BehaviorSubject,
    combineLatest,
    distinctUntilChanged,
    firstValueFrom,
    map,
    Observable,
    of,
    startWith,
    switchMap,
    tap,
} from 'rxjs';

import { ActivityService } from '@accenture/activity/shared/domain';
import {
    BotChatService,
    BotGreeting,
    BotName,
    BotThinking,
    ChatBot,
    ChatUser,
    SessionService,
} from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    selectAuthenticatedUser,
    selectRouterParams,
    selectRouterQueryParams,
    selectSessionTeamMemberData,
} from '@accenture/global-store';
import {
    Activity,
    BotActions,
    BotChatExchange,
    BotChatExchangeTypes,
    BotChatResponse,
    BotContext,
    BotMessage,
    BotSuggestedActions,
    errorSnackbarText,
    ParentType,
    PromptType,
    Session,
    StoragePathHelper,
} from '@accenture/shared/data';
import {
    FilestackService,
    FilestackUploadResult,
    FilestackUploadType,
    FileUploadService,
    FirestoreService,
} from '@accenture/shared/data-access';
import { DialogService, SnackbarService } from '@accenture/shared/ui';

import { BotEditReplyComponent } from '../bot-edit-reply/bot-edit-reply.component';
import { PromptLibraryDialogComponent } from '../prompt-library-dialog/prompt-library-dialog.component';

export interface BotChatModel {
    context: BotContext;
    conversation: Message[];
    activityType: string;
    isLoading: boolean;
    isThinking: boolean;
}

const defaultViewModel: BotChatModel = {
    context: {},
    conversation: [],
    activityType: null,
    isLoading: true,
    isThinking: false,
};

@Injectable()
export class BotChatFacade {
    private isLoading$ = new BehaviorSubject<boolean>(false);
    isThinking$ = new BehaviorSubject<boolean>(false);

    userId!: string;
    context: BotContext = {};
    activityType: string;

    isSessionLeader = false;

    isThinking = false;
    vm$ = this.buildViewModel();

    constructor(
        private firestoreService: FirestoreService,
        private botChatService: BotChatService,
        private store: Store<AppState>,
        private snackbarService: SnackbarService,
        private sessionService: SessionService,
        private activityService: ActivityService,
        private dialogService: DialogService,
        private fileUploadService: FileUploadService,
        private filestackService: FilestackService,
        private router: Router,
    ) {}

    async navigate(location: BotContext): Promise<void> {
        const { sessionId, activityId } = location;

        const path = ['session', sessionId];

        if (activityId) {
            const { type } = await firstValueFrom(
                this.activityService.getActivity(ParentType.Sessions, sessionId, activityId),
            );
            path.push(type.toLowerCase(), activityId);
        }
        this.router.navigate(path);
    }

    async process(action: string, prompt = '', messageId?: string): Promise<void> {
        this.isThinking$.next(true);
        try {
            const { context } = this;
            const { notification, navigateTo } = await this.firestoreService.cloudFunctionCallable<BotChatResponse>(
                'aiConciergeChat',
                {
                    action,
                    prompt,
                    context,
                    userId: this.userId,
                    ...(messageId && { messageId }),
                },
            );

            if (navigateTo) {
                await this.navigate(navigateTo);
            }

            if (notification) {
                this.snackbarService.showSuccessSnackBar(BotName, notification);
            }
        } catch (e) {
            console.error('AI Concierge Call Error', e);
            this.snackbarService.showErrorSnackBar(BotName, errorSnackbarText);
        } finally {
            this.isThinking$.next(false);
        }
    }

    async sendMessage(message: string): Promise<string> {
        return await this.botChatService.sendMessage(this.userId, this.context, message);
    }

    getPrompt(setEntry: (text: string) => void): void {
        this.dialogService.open(PromptLibraryDialogComponent, {
            title: 'Prompt Library',
            panelClass: 'tt9-modal',
            width: '1000px',
            promptType: PromptType.Concierge,
            onSelectPrompt: (prompt: string) => setEntry(prompt),
        });
    }

    getBotActions(action: string): Action[] {
        switch (action) {
            case BotActions.GenerateSession:
                return [BotSuggestedActions[BotActions.GenerateSession], BotSuggestedActions[BotActions.CreateSession]];
            case BotActions.GenerateActivity:
                return [
                    BotSuggestedActions[BotActions.GenerateActivity],
                    BotSuggestedActions[BotActions.CreateActivity],
                ];
            default:
                return [];
        }
    }

    async deleteMessage(messageId: string): Promise<void> {
        await this.botChatService.deleteMessage(this.userId, messageId);
    }

    editMessage(message: BotMessage): void {
        const { id } = message;

        this.dialogService.open(BotEditReplyComponent, {
            message: message.text,
            panelClass: 'tt9-modal',
            width: '768px',
            onAccept: (content) => this.updateMessage(id, content),
        });
    }

    async updateMessage(messageId: string, message: string): Promise<void> {
        await this.botChatService.updateMessage(this.userId, messageId, message);
    }

    uploadFile(): Promise<void> {
        const { userId } = this;
        return new Promise((resolve, reject) => {
            this.filestackService.uploadFiles({
                userId: this.userId,
                uploadType: FilestackUploadType.AiReferenceDocument,
                onUploadStarted: () => this.isThinking$.next(true),
                onFileUploadFinished: () => {
                    this.snackbarService.showSuccessSnackBar(
                        `Embedding Document`,
                        `Processing the uploaded file into the AI's knowledge base.`,
                        true,
                    );
                },
                onUploadFailed: () => {
                    this.snackbarService.showErrorSnackBar(`Upload Failed`, `The upload failed`, true);
                    this.isThinking$.next(false);
                },
                onUploadDone: async (uploadResult: FilestackUploadResult[]) => {
                    const fileUrl = uploadResult[0].filestackFileUrl;
                    const fileName = uploadResult[0].fileName;
                    try {
                        if (fileName && fileUrl) {
                            const { context } = this;
                            await this.firestoreService.cloudFunctionCallable<BotChatResponse>('aiConciergeChat', {
                                context,
                                userId,
                                fileName,
                                fileUrl,
                                action: BotActions.Embed,
                            });
                            this.isThinking$.next(false);
                            resolve();
                        }
                        this.isThinking$.next(false);
                        reject();
                    } catch (e) {
                        console.error('Import failed or completed with warnings');
                        this.isThinking$.next(false);
                        reject(e);
                    }
                },
            });
        });
    }

    async uploadRecording(data: Blob): Promise<string> {
        let response: string;
        this.isThinking$.next(true);
        const fileName = `talk_${new Date().toJSON().slice(0, 10)}.mp3`;

        const { userId } = this;

        const fileUrl = await this.fileUploadService.uploadFile(
            StoragePathHelper.getUserUploadsPath(userId, fileName),
            new File([data], fileName),
        );

        try {
            const { context } = this;
            const { speak, navigateTo } = await this.firestoreService.cloudFunctionCallable<BotChatResponse>(
                'aiConciergeChat',
                {
                    action: BotActions.Transcribe,
                    context,
                    userId,
                    fileUrl,
                },
            );

            if (navigateTo) {
                await this.navigate(navigateTo);
            }
            response = speak;
        } catch (e) {
            console.error('AI Concierge Call Error', e);
            this.snackbarService.showErrorSnackBar(BotName, errorSnackbarText);
        } finally {
            this.isThinking$.next(false);
        }

        return response;
    }

    async whiteboardPaste(fileUrl): Promise<void> {
        this.isThinking$.next(true);

        const { userId } = this;

        try {
            const { context } = this;
            await this.firestoreService.cloudFunctionCallable<BotChatResponse>('aiConciergeChat', {
                userId,
                fileUrl,
                action: BotActions.Draw,
                context,
            });
        } catch (e) {
            console.error('AI Concierge Call Error', e);
            this.snackbarService.showErrorSnackBar(BotName, errorSnackbarText);
        } finally {
            this.isThinking$.next(false);
        }
    }

    private formatMessages(messages: BotChatExchange[]): Message[] {
        BotActions;
        return messages.map(
            (message) =>
                ({
                    author: message.type === BotChatExchangeTypes.User ? ChatUser : ChatBot,
                    text: message.message,
                    timestamp: message.created?.toDate(),
                    suggestedActions: this.getBotActions(message.action).map((_) => ({
                        title: _.value,
                        type: _.type,
                        value: message.id,
                    })),
                    type: message.type,
                    id: message.id,
                    action: message.action,
                } as Message),
        );
    }

    private getConversation(): Observable<Message[]> {
        const { userId, context } = this;
        const { sessionId, activityId } = context;

        const conversation$ = userId ? this.botChatService.getConversation(userId) : of([]);
        const session$ = sessionId
            ? this.sessionService.getSessionNew(ParentType.Sessions, sessionId)
            : of({} as Session);
        const activity$ = activityId
            ? this.activityService.getActivity(ParentType.Sessions, sessionId, activityId)
            : of({} as Activity);

        return combineLatest([conversation$, session$, activity$]).pipe(
            map(([messages, session, activity]) => {
                this.activityType = activity.type;
                return [BotGreeting].concat(this.formatMessages(messages)).concat(this.isThinking ? [BotThinking] : []);
            }),
        );
    }

    private buildViewModel(): Observable<BotChatModel> {
        const conversation$ = combineLatest([
            this.store.select(selectAuthenticatedUser),
            this.store.select(selectSessionTeamMemberData),
            this.isThinking$.asObservable().pipe(distinctUntilChanged()),
            this.store.select(selectRouterParams),
            this.store.select(selectRouterQueryParams),
        ]).pipe(
            tap(([user, sessionAccess, isThinking, routerParms, queryParms]) => {
                this.isThinking = isThinking;
                const { id } = user;
                this.userId = id;

                const { sessionId, activityId } = routerParms || {};

                const { topicId, tableId, slideId } = queryParms || {};

                const activityItemId = topicId || tableId || slideId;
                this.context = {
                    ...(!!sessionId && { sessionId }),
                    ...(!!activityId && { activityId }),
                    ...(!!activityItemId && { activityItemId }),
                };

                this.isSessionLeader = sessionAccess.isSessionLeader;
            }),
            switchMap(() =>
                combineLatest([this.getConversation()]).pipe(
                    tap(() => {
                        this.isLoading$.next(false);
                    }),
                ),
            ),
        );

        return combineLatest([conversation$, this.isLoading$.asObservable().pipe(distinctUntilChanged())]).pipe(
            map(([[conversation], isLoading]) => {
                const { context, activityType, isThinking } = this;
                return {
                    activityType,
                    context,
                    conversation,
                    isLoading,
                    isThinking,
                };
            }),
            startWith(defaultViewModel),
        );
    }
}
