<ng-container *ngIf="vm$ | async as vm">
  <button
    class="tt9-btn-icon-filled"
    *ngIf="vm.available"
    matTooltipPosition="left"
    matTooltipClass="tt9-arrow-tooltip-right"
    [matTooltip]="tooltip()"
    (click)="summarize()"
  >
    <accenture-icon>icon-summarize</accenture-icon>
  </button>
</ng-container>
