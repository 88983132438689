import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import {
    Activity,
    ActivityFactory,
    Attribute,
    AttributeClass,
    DBPathHelper,
    ParentType,
    Session,
    TeamMember,
} from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import { sortBySequenceAsc } from '@accenture/shared/util';

@Injectable()
export class SessionDataService {
    constructor(private firestoreService: FirestoreService) {}

    getSession(parentType: ParentType, sessionId: string): Observable<Session> {
        return this.firestoreService
            .getDocument<Record<string, unknown>>(this.getSessionPath(parentType, sessionId))
            .pipe(map((data: any) => this.constructSession(data)));
    }

    private getSessionPath(parentType: ParentType, sessionId?: string): string {
        return DBPathHelper.getSessionPathNew(parentType, sessionId);
    }

    private constructSession(data: Record<string, unknown>): Session {
        if (data) {
            const id = typeof data.id === 'string' ? data.id : '';
            return new Session(id, data);
        }
        return null;
    }

    clearOnlineState(sessionId: string, userId: string): Promise<void> {
        const batch = this.firestoreService.createBatch();
        batch.update(this.firestoreService.getDocumentRef<TeamMember>(`sessions/${sessionId}/teamMembers/${userId}`), {
            isOnline: false,
        });

        if (sessionId) {
            batch.update(
                this.firestoreService.getDocumentRef<TeamMember>(`sessions/${sessionId}/teamMembers/${userId}`),
                { isOnline: false },
            );
        }

        return batch.commit();
    }

    getSessionTeamMember(parentType: ParentType, sessionId: string, userId: string): Observable<TeamMember | null> {
        return this.firestoreService
            .getDocument(DBPathHelper.getTeamMemberPath(parentType, sessionId, userId))
            .pipe(map((teamMember) => (teamMember ? new TeamMember(teamMember) : null)));
    }

    getActivities(parentType: ParentType, parentId: string): Observable<Activity[]> {
        return this.firestoreService
            .getCollection<Activity>(DBPathHelper.getActivityPath(parentType, parentId))
            .pipe(
                map((activities: Activity[]) =>
                    sortBySequenceAsc(
                        activities
                            .map(({ id, ...activity }) => ActivityFactory.create(id as string, activity as Activity))
                            .filter((activity) => !!activity),
                    ),
                ),
            );
    }

    getAttributeClasses(parentType: ParentType, parentId: string): Observable<AttributeClass[]> {
        return this.firestoreService
            .getCollection<AttributeClass>(DBPathHelper.getAttributesClassesPath(parentType, parentId))
            .pipe(map((attributeClasses) => sortBySequenceAsc(attributeClasses)));
    }

    getAttributes(parentType: ParentType, parentId: string): Observable<Attribute[]> {
        return this.firestoreService.getCollection<Attribute>(DBPathHelper.getAttributesPath(parentType, parentId));
    }
}
