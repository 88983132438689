import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';

import { AppState, isAdminPage } from '@accenture/global-store';
import { SessionRole } from '@accenture/shared/data';

import { SessionsInvitationsByEmailStepNew, SessionsInvitationsTabNew } from './session-invite-dialog-tt9.constants';
import { SelectedForInvitationMemberData } from './session-invite-dialog-tt9.models';

export interface SessionInviteDialogState {
    activeTab: SessionsInvitationsTabNew;
    inviteByEmailActiveStep: SessionsInvitationsByEmailStepNew;
    selectedForInvitationMemberData: SelectedForInvitationMemberData;
    activeInvitationRole: SessionRole.Leader | SessionRole.Participant | undefined;
    isAdminPage: boolean;
}

const initialState: SessionInviteDialogState = {
    activeTab: SessionsInvitationsTabNew.ByEmail,
    inviteByEmailActiveStep: SessionsInvitationsByEmailStepNew.Invite,
    selectedForInvitationMemberData: {},
    activeInvitationRole: undefined,
    isAdminPage: false,
};

@Injectable()
export class SessionInviteDialogStore extends ComponentStore<SessionInviteDialogState> {
    constructor(private store: Store<AppState>) {
        super(cloneDeep(initialState));
    }

    readonly activeTab$ = this.select((state) => state.activeTab);
    readonly inviteByEmailActiveStep$ = this.select((state) => state.inviteByEmailActiveStep);
    readonly selectedForInvitationMemberData$ = this.select((state) => state.selectedForInvitationMemberData);
    readonly activeInvitationRole$ = this.select((state) => state.activeInvitationRole);
    readonly isAdminPage$: Observable<boolean> = this.store.select(isAdminPage);

    readonly leaderEmails$ = this.select((state) => {
        return Object.entries(state.selectedForInvitationMemberData).reduce((emails: string[], [email, role]) => {
            return role === SessionRole.Leader ? [...emails, email] : emails;
        }, []);
    });
    readonly participantEmails$ = this.select((state) => {
        return Object.entries(state.selectedForInvitationMemberData).reduce((emails: string[], [email, role]) => {
            return role === SessionRole.Participant ? [...emails, email] : emails;
        }, []);
    });

    readonly setActiveTab = this.updater((state, activeTab: SessionsInvitationsTabNew) => {
        return {
            ...state,
            activeTab,
        };
    });

    readonly setInviteByEmailActiveStep = this.updater(
        (state, inviteByEmailActiveStep: SessionsInvitationsByEmailStepNew) => {
            return {
                ...state,
                inviteByEmailActiveStep,
            };
        },
    );

    readonly setSelectedForInvitationMemberData = this.updater(
        (state, selectedForInvitationMemberData: SelectedForInvitationMemberData) => {
            return {
                ...state,
                selectedForInvitationMemberData,
            };
        },
    );
    readonly addSelectedForInvitationMemberData = this.updater(
        (state, selectedForInvitationMemberData: SelectedForInvitationMemberData) => {
            return {
                ...state,
                selectedForInvitationMemberData: {
                    ...state.selectedForInvitationMemberData,
                    ...selectedForInvitationMemberData,
                },
            };
        },
    );

    readonly removeSelectedForInvitationMemberData = this.updater((state, emails: string[]) => {
        const selectedForInvitationMemberData = { ...state.selectedForInvitationMemberData };

        emails.forEach((email) => delete selectedForInvitationMemberData[email]);

        return {
            ...state,
            selectedForInvitationMemberData: selectedForInvitationMemberData,
        };
    });
    readonly setActiveInvitationRole = this.updater(
        (state, activeInvitationRole: SessionRole.Leader | SessionRole.Participant | undefined) => {
            return {
                ...state,
                activeInvitationRole,
            };
        },
    );
}
