import { Timestamp } from 'firebase/firestore';

import { removeEmptyKeys } from '@accenture/shared/util';

import { ParentType } from './parent-type';

export enum LogType {
    Info = 'INFO',
    Error = 'ERROR',
}

export enum FeaturedType {
    Focus = 'FOCUS',
}

export class Log {
    id?: string;
    logType: LogType;
    featuredType: FeaturedType;
    activityId?: string;
    sessionId?: string;
    parentId?: string;
    parentType?: ParentType;
    message: string;
    created: Timestamp;

    constructor(log: Partial<Log>) {
        this.id = log.id ?? '';
        this.logType = log.logType ?? LogType.Info;
        this.featuredType = log.featuredType ?? FeaturedType.Focus;
        this.activityId = log.activityId ?? '';
        this.sessionId = log.sessionId ?? '';
        this.parentId = log.parentId ?? '';
        this.parentType = log.parentType ?? ParentType.Projects;
        this.message = log.message ?? '';
        this.created = log.created ?? Timestamp.now();
    }

    createSerializableObject(): Record<string, unknown> {
        return removeEmptyKeys({
            logType: this.logType,
            featuredType: this.featuredType,
            activityId: this.activityId,
            sessionId: this.sessionId,
            parentId: this.parentId,
            parentType: this.parentType,
            message: this.message,
            created: this.created,
        });
    }
}
