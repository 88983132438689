import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';

import { CollectionsService } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUserId } from '@accenture/global-store';
import {
    CollectionSession,
    errorSnackbarText,
    moveToCollectionDoneText,
    moveToCollectionDoneTitle,
    moveToCollectionErrorTitle,
    moveToCollectionText,
    moveToCollectionTitle,
    UserAccess,
    UserCollection,
} from '@accenture/shared/data';
import { DialogService, SnackbarService } from '@accenture/shared/ui';

import { CreateCollectionDialogComponent } from '../create-collection-dialog/create-collection-dialog.component';
import { MoveToCollectionDialogComponent } from './move-to-collection-dialog.component';

export interface CreateSessionSourceTypeDialogModel {
    userCollections: UserCollection[];
    collectionSession: CollectionSession;
    filteredCollections: UserCollection[];
    isLoading: boolean;
}

const defaultViewModel: CreateSessionSourceTypeDialogModel = {
    userCollections: [],
    filteredCollections: [],
    collectionSession: {} as CollectionSession,
    isLoading: true,
};

@Injectable()
export class MoveToCollectionDialogFacade {
    private sessionId$ = new BehaviorSubject<string>(null);
    private sessionData$ = new BehaviorSubject<{ session: CollectionSession | UserAccess; isCollectionPage?: boolean }>(
        null,
    );
    private selectedCollection$ = new BehaviorSubject<UserCollection>(null);

    private userId!: string;
    private sessionId!: string;

    autocompleteValue$ = new BehaviorSubject<string>('');

    vm$ = this.buildViewModel();

    constructor(
        private store: Store<AppState>,
        private dialogService: DialogService,
        private snackbarService: SnackbarService,
        private collectionsService: CollectionsService,
        private dialogRef: MatDialogRef<MoveToCollectionDialogComponent>,
    ) {}

    // TODO: will be update with userSession type
    updateSessionData(data: { session: CollectionSession | UserAccess; isCollectionPage?: boolean }): void {
        this.sessionData$.next(data);
        this.sessionId$.next(data.isCollectionPage ? data.session.sessionId : data.session.id);
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    getCollectionSessionData(
        sessionData: { session: CollectionSession | UserAccess; isCollectionPage?: boolean },
        userId: string,
    ): Observable<CollectionSession> {
        if (sessionData.isCollectionPage) {
            return of(sessionData.session as CollectionSession);
        }

        return this.collectionsService.getCollectionSessionDocument(userId, sessionData.session.id);
    }

    getCollectionsAutoComplete(inputValue: string, userCollections: UserCollection[]): UserCollection[] {
        const searchValue = inputValue?.trim()?.toLowerCase() || '';

        if (!searchValue) {
            return userCollections;
        }

        return userCollections.filter((userCollection: UserCollection) => {
            const name = userCollection.name.trim().toLowerCase();

            return name.includes(searchValue) && name !== searchValue;
        });
    }

    async moveToCollection(): Promise<void> {
        const selectedCollection = this.selectedCollection$.getValue();
        this.snackbarService.showInfoSnackBar(moveToCollectionTitle, moveToCollectionText(selectedCollection.name));

        try {
            await this.collectionsService.moveToCollection(selectedCollection, this.userId, this.sessionId);
            this.snackbarService.showSuccessSnackBar(
                moveToCollectionDoneTitle,
                moveToCollectionDoneText(selectedCollection.name),
            );
        } catch (err) {
            this.snackbarService.showErrorSnackBar(moveToCollectionErrorTitle, errorSnackbarText);
            console.error('Moving Session error');
        }
    }

    createNewCollection(): void {
        // TODO: need to clarify requirements
        this.dialogService.open(CreateCollectionDialogComponent, {
            panelClass: 'tt9-modal',
            width: '768px',
        });
    }

    setSelectedCollection(collection: UserCollection): void {
        this.selectedCollection$.next(collection);
    }

    private buildViewModel(): Observable<CreateSessionSourceTypeDialogModel> {
        return combineLatest([
            this.sessionId$.asObservable().pipe(distinctUntilChanged()),
            this.sessionData$.asObservable().pipe(distinctUntilChanged()),
            this.store.select(selectAuthenticatedUserId),
        ]).pipe(
            switchMap(([sessionId, data, userId]) => {
                return combineLatest([
                    of(sessionId),
                    of(data.session),
                    this.getCollectionSessionData(data, userId),
                    this.collectionsService.getCollectionAssignmentsByUserId(userId),
                    this.autocompleteValue$.asObservable().pipe(distinctUntilChanged()),
                ]).pipe(
                    map(([sessionId, session, collectionSession, userCollections, autocompleteValue]) => {
                        this.userId = userId;
                        this.sessionId = sessionId;
                        const filteredCollections = this.getCollectionsAutoComplete(autocompleteValue, userCollections);
                        return {
                            userCollections,
                            collectionSession,
                            filteredCollections,
                            sessionName: (session as CollectionSession).sessionName || (session as UserAccess).name,
                            isLoading: false,
                        } as CreateSessionSourceTypeDialogModel;
                    }),
                );
            }),
            startWith(defaultViewModel),
        );
    }
}
