import { Injectable, Injector } from '@angular/core';
import { ComponentType, GlobalPositionStrategy, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { LOADED_DATA } from '../directives/loaded/loaded-token';

@Injectable({
    providedIn: 'root',
})
export class LoadedService {
    constructor(private overlay: Overlay, private injector: Injector) {}

    show<T>(component: ComponentType<T>, backdrop = true, data = {}): OverlayRef {
        const positionStrategy = this.getPositionStrategy();
        const overlayConfig = new OverlayConfig({
            hasBackdrop: true,
            backdropClass: backdrop ? 'cdk-overlay-dark-backdrop' : '',
            positionStrategy,
        });
        const overlayRef = this.overlay.create(overlayConfig);
        const injector = this.getInjector(data);
        const portal = new ComponentPortal(component, null, injector);

        overlayRef.attach(portal);

        return overlayRef;
    }

    private getPositionStrategy(): GlobalPositionStrategy {
        return this.overlay.position().global().centerHorizontally().centerVertically();
    }

    private getInjector(config: any): Injector {
        return Injector.create({
            parent: this.injector,
            providers: [{ provide: LOADED_DATA, useValue: config }],
        });
    }
}
