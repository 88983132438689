import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';

import { ActivityService } from '@accenture/activity/shared/domain';
import {
    saveActivityToSessionInfoSnackBar,
    saveActivityToSessionSuccessSnackBar,
    UserAccessService,
} from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    selectAuthenticatedUser,
    selectParentType,
    selectProjectId,
    selectSessionId,
} from '@accenture/global-store';
import {
    Activity,
    addingTemplateErrorSnackbarTitle,
    addingTemplateInProgressSnackbarTitle,
    Dictionary,
    errorMessageSnackbarText,
    ParentType,
    templateAddedSnackbarTitle,
    User,
    UserAccess,
} from '@accenture/shared/data';
import { SnackbarService, SnackBarTypes } from '@accenture/shared/ui';
import { SequenceGeneration } from '@accenture/shared/util';

import { SequenceData } from '../../models/sequence-data';
import { AddActivityTemplatesDialogComponent } from './add-activity-templates-dialog.component';

export interface AddActivityTemplatesDialogViewModel {
    templates: UserAccess[];
    selectedTemplates: UserAccess[];
    selectedTemplatesMap: Dictionary<boolean>;
    isLoading: boolean;
}

const defaultViewModel: AddActivityTemplatesDialogViewModel = {
    templates: [],
    selectedTemplates: [],
    selectedTemplatesMap: {},
    isLoading: true,
};

@Injectable()
export class AddActivityTemplatesDialogFacade {
    private userId!: string;
    private sessionId!: string;
    private projectId!: string;
    private parentType!: ParentType;
    private searchValue$ = new BehaviorSubject('');
    private selectedTemplatesMap$ = new BehaviorSubject<Dictionary<boolean>>({});
    private isLoading$ = new BehaviorSubject<boolean>(false);
    private selectedTemplatesMap!: Dictionary<boolean>;

    vm$ = this.buildViewModel();

    constructor(
        private store: Store<AppState>,
        private userAccessService: UserAccessService,
        private activityService: ActivityService,
        private dialogRef: MatDialogRef<AddActivityTemplatesDialogComponent>,
        private snackbarService: SnackbarService,
    ) {}

    async addActivitiesToSession(activities: Activity[], sequenceData?: SequenceData): Promise<void> {
        this.snackbarService.showSnackBar(
            addingTemplateInProgressSnackbarTitle,
            saveActivityToSessionInfoSnackBar,
            SnackBarTypes.Info,
        );
        const selectedTemplatesIds = Object.keys(this.selectedTemplatesMap || {}).filter(
            id => !!this.selectedTemplatesMap[id],
        );
        const templates$ = this.activityService.getActivityTemplatesByIds(selectedTemplatesIds);
        const templates = await firstValueFrom(templates$);
        const templatesWithSequence = this.updateTemplatesSequence(activities, templates, sequenceData);

        try {
            for (const template of templatesWithSequence) {
                await this.activityService.addActivityToSession(
                    template.id,
                    this.projectId,
                    this.sessionId,
                    this.userId,
                    this.parentType,
                    ParentType.ActivityTemplates,
                    undefined,
                    template,
                    true,
                );
            }
            this.snackbarService.showSuccessSnackBar(templateAddedSnackbarTitle, saveActivityToSessionSuccessSnackBar);
        } catch (e) {
            console.error(e);
            this.snackbarService.showErrorSnackBar(addingTemplateErrorSnackbarTitle, errorMessageSnackbarText);
        }

        this.dialogRef.close();
    }

    updateSearchValue(value: string): void {
        this.searchValue$.next(value);
    }

    updateSelectedTemplates(selectedTemplatesMap: Dictionary<boolean>): void {
        this.selectedTemplatesMap$.next({
            ...this.selectedTemplatesMap$.getValue(),
            ...selectedTemplatesMap,
        });
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    private buildViewModel(): Observable<AddActivityTemplatesDialogViewModel> {
        return combineLatest([
            this.store.select(selectSessionId),
            this.store.select(selectProjectId),
            this.store.select(selectParentType),
            this.getUser(),
        ]).pipe(
            switchMap(([sessionId, projectId, parentType, user]) => {
                this.sessionId = sessionId;
                this.projectId = projectId;
                this.parentType = parentType;

                return combineLatest([
                    this.userAccessService.getTemplatesActivityAssignmentsByUserId(user.id),
                    this.searchValue$,
                    this.selectedTemplatesMap$,
                    this.isLoading$,
                ]).pipe(
                    map(([templates, searchValue, selectedTemplatesMap, isLoading]) => {
                        this.selectedTemplatesMap = selectedTemplatesMap;

                        return {
                            selectedTemplatesMap,
                            isLoading,
                            selectedTemplates: this.getSelectedTemplates(templates),
                            templates: this.getFilteredTemplates(templates, searchValue),
                        };
                    }),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    private getUser(): Observable<User> {
        return this.store.pipe(select(selectAuthenticatedUser), tap(this.setCurrentUser.bind(this)));
    }

    private setCurrentUser(user: User): void {
        this.userId = user.id;
    }

    private getSelectedTemplates(templates: UserAccess[]): UserAccess[] {
        const selectedTemplatesMap = this.selectedTemplatesMap$.getValue();

        return templates.filter(template => {
            return template.templateId && selectedTemplatesMap[template.templateId];
        });
    }

    private getFilteredTemplates(templates: UserAccess[], searchValue: string): UserAccess[] {
        return templates.filter(template => {
            const hasSearchName = template.name?.toLowerCase().includes(searchValue);
            const hasSearchDescription = template.description?.toLowerCase().includes(searchValue);

            return hasSearchName || hasSearchDescription;
        });
    }

    private updateTemplatesSequence(
        templates: Activity[],
        newTemplates: Activity[],
        sequenceData?: SequenceData,
    ): Activity[] {
        const updatedTemplates = [...templates];
        const sequenceDataBuffer = { ...sequenceData } as SequenceData;

        return newTemplates.map(template => {
            template.sequence = this.getTemplateSequence(updatedTemplates, sequenceDataBuffer);
            updatedTemplates.push(template);

            if (sequenceData) {
                sequenceDataBuffer.previousSequence = template.sequence;
            }

            return template;
        });
    }

    private getTemplateSequence(activities: Activity[], sequenceData?: SequenceData): string {
        if (activities.length === 0) {
            return SequenceGeneration.initial();
        }

        if (!sequenceData) {
            return SequenceGeneration.afterLast(activities[activities.length - 1].sequence);
        }

        const previousSequence = sequenceData.previousSequence ?? activities[activities.length - 1].sequence;
        if (previousSequence !== '') {
            return sequenceData.nextSequence
                ? SequenceGeneration.between(previousSequence, sequenceData.nextSequence)
                : SequenceGeneration.afterLast(previousSequence);
        }

        return SequenceGeneration.initial();
    }
}
