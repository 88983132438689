<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading; description: loaderText">
    <div class="dialog-header">
      <div class="d-flex">
        <p class="tt9-h1 text-overflow-ellipsis-1">{{ data.title }}</p>
        <mat-chip
          class="tt9-tag-chip info-chip info-chip-new"
          matTooltipClass="tt9-arrow-tooltip-above"
          [matTooltip]="betaHint"
        >
          BETA
        </mat-chip>
      </div>

      <button class="tt9-btn-icon-filled" (click)="closeModal(vm.currentStep)">
        <accenture-icon>icon-close</accenture-icon>
      </button>
    </div>

    <div *ngIf="isEntryStep(vm.currentStep)" class="mx-32 mt-32">
      <p class="tt9-body-medium-2 mb-8">ThinkTank’s AI engagement builder is in an experimental Beta format</p>
      <p class="tt9-body-2">Please be as detailed as possible to enhance the accuracy of the generated content.</p>
    </div>
    <ul class="modal-breadcrumbs">
      <li
        *ngFor="let step of builderSteps; trackBy: trackById"
        class="modal-breadcrumbs-item tt9-caption-2"
        [class.active]="step.id === vm.currentStep"
      >
        {{ step.label }}
      </li>
    </ul>

    <div class="content-container">
      <div *ngIf="isEntryStep(vm.currentStep)" [formGroup]="aiPromptsForm">
        <p class="tt9-body-medium-1 mb-8 question-required">Purpose & Objectives</p>
        <p class="tt9-body-2 mb-16">
          {{ currentLabel.aiGenerateObjectives.subTitle }}
        </p>
        <mat-form-field
          appearance="outline"
          class="tt9-form-field no-label textarea textarea-auto-size width-100 mb-32"
        >
          <textarea
            #objectivesInput
            matInput
            required
            cdkTextareaAutosize
            formControlName="objectivesControl"
            [cdkAutosizeMinRows]="3"
            [cdkAutosizeMaxRows]="5"
            [maxlength]="characterLimit"
            [placeholder]="currentLabel.aiGenerateObjectives.placeholder"
          ></textarea>
          <mat-hint class="mat-hint-limit" [class]="determineHintClass(objectivesInput?.value?.length, characterLimit)">
            {{ characterLimitText(objectivesInput?.value?.length, characterLimit) }}
          </mat-hint>
          <mat-error>{{ validationMessages.required }}</mat-error>
        </mat-form-field>

        <p class="tt9-body-medium-1 mb-8">Audience & Duration</p>
        <p class="tt9-body-2 mb-16">
          {{ currentLabel.aiGenerateAudience.subTitle }}
        </p>
        <mat-form-field
          appearance="outline"
          class="tt9-form-field no-label textarea textarea-auto-size mb-32 width-100"
        >
          <textarea
            #audienceInput
            matInput
            cdkTextareaAutosize
            formControlName="audienceControl"
            [cdkAutosizeMinRows]="3"
            [cdkAutosizeMaxRows]="5"
            [maxlength]="characterLimit"
            [placeholder]="currentLabel.aiGenerateAudience.placeholder"
          ></textarea>
          <mat-hint class="mat-hint-limit" [class]="determineHintClass(audienceInput?.value?.length, characterLimit)">
            {{ characterLimitText(audienceInput?.value?.length, characterLimit) }}
          </mat-hint>
        </mat-form-field>

        <p class="tt9-body-medium-1 mb-8">Agenda & Key Activities</p>
        <p class="tt9-body-2 mb-16">
          {{ currentLabel.aiGenerateAgenda.subTitle }}
        </p>
        <mat-form-field appearance="outline" class="tt9-form-field textarea no-label textarea-auto-size width-100">
          <textarea
            #flowInput
            matInput
            cdkTextareaAutosize
            formControlName="flowControl"
            [cdkAutosizeMinRows]="3"
            [cdkAutosizeMaxRows]="5"
            [maxlength]="characterLimit"
            [placeholder]="currentLabel.aiGenerateAgenda.placeholder"
          ></textarea>
          <mat-hint class="mat-hint-limit" [class]="determineHintClass(flowInput?.value?.length, characterLimit)">
            {{ characterLimitText(flowInput?.value?.length, characterLimit) }}
          </mat-hint>
        </mat-form-field>
      </div>

      <ng-container *ngIf="isPreviewStep(vm.currentStep)">
        <ng-container *ngIf="vm.previewData?.name">
          <ng-container
            *ngTemplateOutlet="items; context: { data: vm.previewData, parentType: nodeType.Session }"
          ></ng-container>
        </ng-container>

        <ng-container *ngFor="let session of vm.previewData; trackBy: trackById; let i = index">
          <ng-container
            *ngTemplateOutlet="
              items;
              context: {
                data: session,
                iconVisible: !!vm.previewData?.name,
                parentType: nodeType.Session,
                index: vm.previewData?.length > 1 ? i + 1 : undefined
              }
            "
          ></ng-container>
        </ng-container>
      </ng-container>
    </div>

    <div class="footer">
      <div class="d-flex">
        <button *ngIf="isEntryStep(vm.currentStep)" class="tt9-btn-default mr-8" (click)="closeModal(vm.currentStep)">
          <span>Cancel</span>
        </button>
        <button
          *ngIf="isPreviewStep(vm.currentStep)"
          class="tt9-btn-default btn-icon mr-8"
          (click)="backToPreviousModal(vm.currentStep)"
        >
          <accenture-icon>icon-chevron-left-small</accenture-icon>
          <span>Back</span>
        </button>
        <button
          *ngIf="isEntryStep(vm.currentStep)"
          class="tt9-btn"
          [disabled]="!aiPromptsForm.valid"
          (click)="generate()"
        >
          <span>Generate</span>
        </button>
        <button *ngIf="isPreviewStep(vm.currentStep)" class="tt9-btn" (click)="complete()">
          <span>Generate</span>
        </button>
      </div>
    </div>

    <ng-template #items let-data="data" let-iconVisible="iconVisible" let-parentType="parentType" let-index="index">
      <div class="preview-data">
        <p class="tt9-body-medium-1 type">
          <accenture-icon
            *ngIf="iconVisible"
            class="icon"
            [size]="iconSize.Small"
            [class.ml-16]="isActivityType(parentType)"
          >
            icon-corner-left-up
          </accenture-icon>
          {{ getLabelToDisplay(parentType, data?.type) }}
          <span *ngIf="index">&nbsp;{{ index }}</span
          >:
        </p>
        <p class="tt9-body-1 name">{{ data?.name }}</p>
      </div>

      <ng-container *ngFor="let activity of data?.activities">
        <ng-container
          *ngTemplateOutlet="
            items;
            context: {
              data: activity,
              iconVisible: true,
              parentType: nodeType.Activity
            }
          "
        ></ng-container>
      </ng-container>
    </ng-template>
  </ng-container>
</ng-container>
