<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading">
    <div class="projects-home" [class.home-page]="vm.isDashboardPage">
      <div class="projects-home-content">
        <div class="projects-header-container">
          <div class="projects-header pb-16" [ngClass]="{ 'pb-24': !canCreateProject(vm.user) }">
            <h2 class="tt9-h2 projects-title">My Projects</h2>

            <ng-container *ngIf="canCreateProject(vm.user)">
              <ng-container *ngIf="vm.isDashboardPage">
                <button *ngIf="vm.projects.length" class="tt9-btn btn-icon mr-8" (click)="openCreateProjectDialog()">
                  <accenture-icon>icon-add</accenture-icon>
                  <span>Create New</span>
                </button>

                <button class="tt9-btn-default" (click)="navigateToProjectsPage()">See All Projects</button>
              </ng-container>

              <button
                *ngIf="!vm.isDashboardPage && vm.isFiltersAndSortVisible"
                class="tt9-btn btn-icon"
                (click)="openCreateProjectDialog()"
              >
                <accenture-icon>icon-add</accenture-icon>
                <span>Create Project</span>
              </button>
            </ng-container>
          </div>

          <div *ngIf="vm.isFiltersAndSortVisible" class="projects-panel">
            <p *ngIf="!vm.isSearchShown" class="tt9-body-1 projects-number">{{ vm.user.projectsCount }} Projects</p>

            <div *ngIf="vm.isSearchShown" class="projects-search">
              <mat-form-field
                appearance="outline"
                class="tt9-form-field no-label no-margin width-100"
                floatLabel="always"
              >
                <input
                  #search
                  matInput
                  [formControl]="searchControl"
                  [placeholder]="inputPlaceholders.search"
                  (input)="filterProjects(search.value)"
                />
                <accenture-icon matPrefix>icon-search</accenture-icon>
                <accenture-icon *ngIf="vm.searchValue" matSuffix (click)="clearFilter($event)"
                  >icon-close</accenture-icon
                >
              </mat-form-field>
            </div>

            <button
              class="tt9-btn-icon-filled mr-8"
              matTooltipPosition="above"
              matTooltipClass="tt9-arrow-tooltip-below"
              [matTooltip]="tooltipTexts.projectCards"
              [class.active]="vm.activeTab === ProjectsViewTab.CardView"
              (click)="setActiveTab(ProjectsViewTab.CardView)"
            >
              <accenture-icon>icon-grid-module</accenture-icon>
            </button>
            <button
              class="tt9-btn-icon-filled mr-16"
              matTooltipPosition="above"
              matTooltipClass="tt9-arrow-tooltip-below"
              [matTooltip]="tooltipTexts.projectList"
              [class.active]="vm.activeTab === ProjectsViewTab.ListView"
              (click)="setActiveTab(ProjectsViewTab.ListView)"
            >
              <accenture-icon>icon-list</accenture-icon>
            </button>
            <button
              class="tt9-btn-icon-filled mr-8"
              matTooltipPosition="above"
              matTooltipClass="tt9-arrow-tooltip-below"
              [matTooltip]="tooltipTexts.search"
              [class.active]="vm.isSearchShown"
              (click)="toggleShowSearch()"
            >
              <accenture-icon>icon-search</accenture-icon>
            </button>

            <button
              *ngIf="vm.isDashboardPage"
              class="tt9-btn-icon-filled"
              matTooltipPosition="above"
              matTooltipClass="tt9-arrow-tooltip-below"
              [ngClass]="{ active: sortMenuOpen }"
              [matTooltip]="tooltipTexts.sortProjects"
              [matMenuTriggerFor]="sortOptions"
              (click)="sortMenuOpen = !sortMenuOpen"
            >
              <accenture-icon>icon-sort</accenture-icon>
            </button>

            <button
              *ngIf="!vm.isDashboardPage"
              class="tt9-btn-icon-filled tt9-btn-applied"
              matTooltipPosition="above"
              matTooltipClass="tt9-arrow-tooltip-below-right"
              [matTooltip]="tooltipTexts.sortProjects"
              (click)="toggleFilterAndSortPanel(true, $event)"
            >
              <accenture-icon>icon-filter</accenture-icon>
              <div *ngIf="vm.isFiltersApplied" class="applied"></div>
            </button>
          </div>
        </div>

        <div
          class="project-list"
          [ngClass]="{
            'dashboard-view': vm.isDashboardPage && !vm.emptyScreenConfig,
            'list-view': vm.activeTab === ProjectsViewTab.ListView,
            empty: !!vm.emptyScreenConfig,
          }"
        >
          <div *ngIf="vm.emptyScreenConfig" class="empty-container">
            <accenture-empty-screen
              [title]="vm.emptyScreenConfig.title"
              [imageUrl]="emptyScreenImage"
            ></accenture-empty-screen>

            <button
              *ngIf="!vm.isFiltersApplied && !vm.searchValue && canCreateProject(vm.user)"
              class="tt9-btn btn-rounded btn-with-border empty-screen-actions btn-medium-size"
              (click)="openCreateProjectDialog()"
            >
              <accenture-icon>icon-add</accenture-icon>
              <span *ngIf="vm.isDashboardPage">Create New</span>
              <span *ngIf="!vm.isDashboardPage">Create Project</span>
            </button>
          </div>

          <ng-container *ngIf="vm.activeTab === ProjectsViewTab.CardView">
            <div
              *ngFor="let project of vm.projects; trackBy: trackById"
              class="tt9-navigation-card project-card"
              [routerLink]="['/project', project.projectId]"
            >
              <div
                class="tt9-navigation-card-image project-card-image"
                [style.background-image]="getImageUrl(project.imageUrl)"
              >
                <img
                  *ngIf="!project.imageUrl"
                  class="tt9-navigation-card-image-empty"
                  alt="Project Image"
                  [src]="imageInputPlaceholdersUrl"
                />
              </div>

              <div class="tt9-navigation-card-content project-card-content">
                <p class="client">
                  <ng-container *ngIf="isProjectAdmin(project)">
                    <div *ngIf="(project.clients | keyvalue)?.length" class="clients tt9-column-title mr-16">
                      <span *ngFor="let client of project.clients | keyvalue as clients; trackBy: trackById">
                        {{ client?.value }}
                      </span>
                    </div>

                    <div *ngIf="!(project.clients | keyvalue)?.length" class="clients tt9-column-title mr-16">
                      <span>{{ noClientSelected }}</span>
                    </div>
                  </ng-container>
                </p>

                <p
                  #projectName
                  class="tt9-navigation-card-title"
                  matTooltipClass="tt9-arrow-tooltip-above-left"
                  matTooltipPosition="below"
                  [matTooltip]="project.name"
                  [matTooltipDisabled]="isDisabledTooltip(projectName)"
                >
                  {{ project.name }}
                </p>

                <p
                  #projectDescription
                  *ngIf="project?.description"
                  class="tt9-navigation-card-description"
                  matTooltipClass="tt9-arrow-tooltip-above-left"
                  matTooltipPosition="below"
                  [matTooltip]="project.description"
                  [matTooltipDisabled]="isDisabledTooltip(projectDescription)"
                >
                  {{ project.description }}
                </p>
              </div>

              <div class="tt9-navigation-card-footer d-flex-end">
                <p class="date tt9-caption-3 tt9-color-neutral-500">
                  {{ getProjectDate(project) | date : "MMM. d, y" }}
                </p>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="vm.activeTab === ProjectsViewTab.ListView">
            <div *ngIf="vm.projects?.length" class="projects-list-header d-flex tt9-input-text-small light-header">
              <div class="projects-list-cell header-cell wide-cell">Name & Description</div>
              <div class="projects-list-cell header-cell text-center">Client</div>
              <div class="projects-list-cell header-cell text-center">Last updated</div>
            </div>
            <div
              *ngFor="let project of vm.projects; trackBy: trackById"
              class="projects-list-row d-flex tt9-input-text-small"
              [routerLink]="['/project', project.projectId]"
            >
              <div class="projects-list-cell wide-cell d-flex">
                <div class="projects-list-image-container">
                  <div class="projects-list-image" [style.background-image]="getImageUrl(project?.imageUrl)">
                    <img
                      *ngIf="!project.imageUrl"
                      class="height-100"
                      alt="Project Image"
                      [src]="imageInputPlaceholdersUrl"
                    />
                  </div>
                </div>
                <div class="projects-list-info">
                  <h4
                    #projectName
                    accentureTooltip
                    class="tt9-button-text-large text-overflow-ellipsis-2"
                    matTooltipClass="tt9-arrow-tooltip-above-left"
                    matTooltipPosition="below"
                    [matTooltip]="project.name"
                    [matTooltipDisabled]="isDisabledTooltip(projectName)"
                  >
                    {{ project.name }}
                  </h4>
                  <p
                    #projectDescription
                    accentureTooltip
                    class="projects-list-info-description tt9-input-text-small text-overflow-ellipsis-2"
                    matTooltipClass="tt9-arrow-tooltip-above-left"
                    matTooltipPosition="below"
                    [matTooltip]="project.description"
                    [matTooltipDisabled]="isDisabledTooltip(projectDescription)"
                  >
                    {{ project.description }}
                  </p>
                </div>
              </div>
              <div class="projects-list-cell clients-cell text-center tt9-body-medium-1 text-overflow-ellipsis-1">
                {{ isProjectAdmin(project) ? getClientName(project.clients) : "—" }}
              </div>
              <div class="projects-list-cell text-center tt9-body-1">
                {{ getProjectDate(project) | date : "mediumDate" }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <!-- Filter and Sort side panel -->
    <accenture-side-panel
      *ngIf="vm.isFilterAndSortPanelOpened"
      cdkOverlayOrigin
      #origin="cdkOverlayOrigin"
      [panelTrigger]="origin"
      [panelTitle]="'Filter & Sort'"
      [panelOpened]="vm.isFilterAndSortPanelOpened"
      (closePanel)="toggleFilterAndSortPanel(false)"
    >
      <accenture-default-filters-and-sort></accenture-default-filters-and-sort>
    </accenture-side-panel>
    <!-- Filter and Sort side panel -->
  </ng-container>

  <mat-menu xPosition="before" #sortOptions="matMenu" class="tt9-menu" (closed)="sortMenuOpen = !sortMenuOpen">
    <form class="sort-menu" [formGroup]="sortForm" (click)="$event.stopPropagation()">
      <div>
        <label class="tt9-column-title">Sort by</label>

        <mat-radio-group class="d-flex-column" formControlName="sortOption">
          <mat-radio-button
            *ngFor="let sortOption of sortOptionsValues | keyvalue : preventKeyValueOrder"
            class="tt9-radio-button tt9-input-text mt-12"
            color="primary"
            [checked]="isSortOptionSelected(vm.filtersAndSort, 'sortOption', sortOption.key)"
            [value]="sortOption.key"
            (change)="changeSortOption(vm.user.id, 'sortOption', sortOption.key)"
          >
            {{ sortOption.value }}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="mt-12">
        <label class="tt9-column-title">Order</label>

        <mat-radio-group class="d-flex-column" formControlName="sortOrder">
          <mat-radio-button
            *ngFor="let sortOrder of sortOrdersValues | keyvalue"
            class="tt9-radio-button tt9-input-text mt-12"
            color="primary"
            [checked]="isSortOptionSelected(vm.filtersAndSort, 'sortOrder', sortOrder.key)"
            [value]="sortOrder.key"
            (change)="changeSortOption(vm.user.id, 'sortOrder', sortOrder.key)"
          >
            {{ sortOrder.value }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </form>
  </mat-menu>
</ng-container>
