import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AttributeClass } from '@accenture/shared/data';

import { AppState } from '../app.state';
import { ATTRIBUTES_FEATURE_KEY, AttributesState } from './attributes.reducer';

export const selectAttributeClassesState = createFeatureSelector<AttributesState>(ATTRIBUTES_FEATURE_KEY);

export const selectAttributeClasses = createSelector<AppState, [AttributesState], AttributeClass[]>(
    selectAttributeClassesState,
    (attributesStateData) => attributesStateData?.attributeClasses || [],
);
