import { createAction, props } from '@ngrx/store';
import { Dictionary } from 'lodash';

import {
    Activity,
    Attribute,
    AttributeClass,
    ParentType,
    Project,
    Session,
    TeamMember,
    TeamMemberSnackBarsVisibilityOption,
} from '@accenture/shared/data';

export const getProjectAndSessionsAndAttributes = createAction(
    '[Projects] Get Project, Sessions and Attributes Data',
    props<{ parentType: ParentType; projectId: string }>(),
);

export const getProjectAndSessionsAndAttributesSuccess = createAction(
    '[Projects] Get Project, Sessions and Attributes Data Success',
    props<{ project: Project; sessions: Session[]; attributes: Attribute[]; attributeClasses: AttributeClass[] }>(),
);

export const getProjectAndSessionsAndAttributesError = createAction(
    '[Projects] Get Project, Sessions and Attributes Data Error',
);

export const getSession = createAction(
    '[Projects] Get Session Data',
    props<{ parentType: ParentType; projectId: string; sessionId: string }>(),
);

export const getSessionSuccess = createAction('[Projects] Get Session Data Success', props<{ session: Session }>());

export const getSessionError = createAction('[Projects] Get Session Data Error');

export const getParentTeamMember = createAction(
    '[Projects] Get Team Member by Parent',
    props<{ parentType: ParentType; parentId: string }>(),
);

export const getParentTeamMemberSuccess = createAction(
    '[Projects] Get Parent Team Member Success',
    props<{ teamMember: TeamMember }>(),
);

export const getSessionTeamMember = createAction(
    '[Projects] Get Session Team Member',
    props<{ parentType: ParentType; parentId: string; sessionId: string }>(),
);

export const getSessionTeamMemberSuccess = createAction(
    '[Projects] Get Session Team Member Success',
    props<{ teamMember: TeamMember }>(),
);

export const getSessionTeamMemberSnackBarsVisibility = createAction(
    '[Projects] Get Session Team Member Snack bars',
    props<{ parentType: ParentType; parentId: string; sessionId: string }>(),
);

export const getSessionTeamMemberSnackBarsVisibilitySuccess = createAction(
    '[Projects] Get Session Team Member Snack bars Success',
    props<{ teamMemberSnackBarsVisibility: Dictionary<TeamMemberSnackBarsVisibilityOption> }>(),
);

export const getParentTeamMemberError = createAction('[Projects] Get Parent Team Member Error');

export const getSessionTeamMemberError = createAction('[Projects] Get Session Team Member Error');

export const getSessionActivities = createAction(
    '[Projects] Get Session Activities',
    props<{ parentType: ParentType; projectId: string; sessionId: string }>(),
);

export const getSessionActivitiesSuccess = createAction(
    '[Projects] Get Session Activities Success',
    props<{ sessionActivities: Activity[] }>(),
);

export const clearProjectStore = createAction('[Projects] Clear Project Store');

export const clearSessionTeamMember = createAction('[Projects] Clear Session Team Member');

export const clearProjectStoreSubscription = createAction('[Projects] Clear Project Store Subscription');

export const clearProjectStoreOfSessionSubscriptions = createAction(
    '[Projects] Clear Project Store Of Session Subscription',
);

export const setProjectTeamMemberOnlineState = createAction(
    '[Projects] Set Project Team Member Online',
    props<{ projectId: string; isOnline: boolean; sessionId?: string }>(),
);

export const setSessionTeamMemberOnlineState = createAction(
    '[Projects] Set Session Team Member Online',
    props<{ projectId: string; sessionId: string; isOnline: boolean }>(),
);

export const setTeamMemberOfflineState = createAction(
    '[Projects] Set Team Member Offline',
    props<{ projectId: string; sessionId: string }>(),
);

export const teamMemberOnlineStateChangedSuccess = createAction('[Projects] Team Member Online State Changed Success');

export const teamMemberOnlineStateChangedError = createAction('[Projects] Team Member Online State Changed Error');

export const setTemplateTeamMemberOnlineState = createAction(
    '[Templates] Set Template Team Member Online',
    props<{ parentType: ParentType; isOnline: boolean; templateId: string }>(),
);

export const templateTeamMemberOnlineStateChangedSuccess = createAction(
    '[Templates] Team Member Online State Changed Success',
);

export const templateTeamMemberOnlineStateChangedError = createAction(
    '[Templates] Team Member Online State Changed Error',
);
