export const characterLimitText = (currentAmount: number, totalLimit: number): string => {
    return currentAmount >= totalLimit
        ? 'Character limit has been reached'
        : `Character limit: ${currentAmount}/${totalLimit}`;
};
export const characterLimitMedium = 255;
export const characterLimitLarge = 512;
export const characterLimitXL = 1024;

export const determineHintClass = (currentAmount: number, totalLimit: number): string => {
    // 20 is the character limit border at which the styles need to change
    const charactersLeft = totalLimit - currentAmount;
    if (charactersLeft < 20 && charactersLeft !== 0) {
        return 'mat-hint-warning';
    } else if (charactersLeft === 0) {
        return 'mat-hint-error';
    } else {
        return 'mat-hint-default';
    }
};
