import { Timestamp } from 'firebase/firestore';

import { CollectionSortOptions } from './default-filters-and-sort';
import { SortOrders } from './sort-orders';

export const lazyLoadingProjectLimit = 20;

export enum ProjectFilterSortBy {
    Name = 'name',
    ProjectId = 'projectId',
    Updated = 'updated',
    Created = 'created',
}

export const projectSortByMap = {
    [ProjectFilterSortBy.Name]: 'Name',
    [ProjectFilterSortBy.Created]: 'Date created',
    [ProjectFilterSortBy.Updated]: 'Last updated',
};

export interface ProjectFilters {
    tags?: string[];
    clients?: string[];
    statuses?: string[];
    fromDate?: Timestamp;
    toDate?: Timestamp;
    startDate?: Timestamp;
    endDate?: Timestamp;
    sortBy?: ProjectFilterSortBy;
    sortOrder?: SortOrders;
}

export interface ProjectFilters {
    tags?: string[];
    clients?: string[];
    fromDate?: Timestamp;
    toDate?: Timestamp;
    startDate?: Timestamp;
    endDate?: Timestamp;
    sortBy?: ProjectFilterSortBy;
    sortOrder?: SortOrders;
    collectionsSortOption?: CollectionSortOptions;
    collectionsSortOrder?: SortOrders;
}

export const initialProjectFilters: ProjectFilters = {
    tags: [],
    clients: [],
    fromDate: null,
    toDate: null,
    startDate: null,
    endDate: null,
    sortBy: ProjectFilterSortBy.Name,
    sortOrder: SortOrders.Asc,
    collectionsSortOption: CollectionSortOptions.Name,
    collectionsSortOrder: SortOrders.Asc,
};

export interface ProjectQueryFilters extends ProjectFilters {
    search: string;
    searchOption: string;
    globalSearch: boolean;
    lastProjectId?: string;
}
