import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith, take } from 'rxjs/operators';

import {
    OptionsStore,
    ProjectOptionsService,
    ProjectService,
    TemplateAccessService,
} from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUser, selectParentType, selectProject } from '@accenture/global-store';
import {
    CollectionOptions,
    DBPathHelper,
    FileType,
    ParentType,
    Project,
    ProjectOptions,
    projectOptionsArray,
    projectOptionsDialogTitle,
    routerLinksMap,
    SelectedProjectOptions,
    SessionOptions,
    User,
} from '@accenture/shared/data';
import { LoadedDescription } from '@accenture/shared/ui';
import { removeNoValuesKeys } from '@accenture/shared/util';

import { UseTemplateStore } from '../component-stores/use-template.store';
import { EditProjectDialogComponent } from './edit-project-dialog.component';

export interface EditProjectDialogViewModel {
    project: Project;
    projectImage: FileType;
    loaderDescription: string;
    isLoading: boolean;
    projectHasUpdates: boolean;
    updateProjectEvent: boolean;
    isClientSelected: boolean;
    currentOptionToDisplay: SessionOptions | ProjectOptions | CollectionOptions | null;
    title: string;
    isPublicProjectTemplate: boolean;
    hasClientChanged: boolean;
}

const defaultModel = {
    project: {} as Project,
    projectImage: {} as FileType,
    loaderDescription: '',
    isLoading: true,
    projectHasUpdates: false,
    updateProjectEvent: true,
    isClientSelected: false,
    currentOptionToDisplay: null,
    title: '',
    isPublicProjectTemplate: false,
    hasClientChanged: false,
};

@Injectable()
export class EditProjectDialogFacade {
    private isLoading$ = new BehaviorSubject<boolean>(false);
    private projectImage$ = new BehaviorSubject<FileType>(undefined);
    private projectHasUpdates$ = new BehaviorSubject<boolean>(false);
    private updateProjectEvent$ = new BehaviorSubject<boolean>(true);

    vm$ = this.buildViewModel();

    private parentType: ParentType;
    private parentId: string;
    private user: User;
    private hasSelectedOptions = false;
    private savedProjectOptions!: SelectedProjectOptions;

    constructor(
        private store: Store<AppState>,
        private router: Router,
        private projectService: ProjectService,
        private projectOptionsService: ProjectOptionsService,
        private dialogRef: MatDialogRef<EditProjectDialogComponent>,
        private useTemplateStore: UseTemplateStore,
        private optionsStore: OptionsStore,
        private templateAccessService: TemplateAccessService,
    ) {}

    closeDialog(): void {
        this.dialogRef.close();
    }

    backToProject(): void {
        this.optionsStore.setCurrentOptionToDisplay(null);
        this.useTemplateStore.setOptionsChipsDisplay(false);
    }

    resetOptionsStore(): void {
        this.optionsStore.setCurrentOptionToDisplay(null);
        this.optionsStore.resetProjectOptions();
        this.optionsStore.resetProjectOptionsToCreate();
        this.optionsStore.resetHasClientChanged();
    }

    setProjectImage(image?: FileType): void {
        this.projectImage$.next(image);
    }

    updateProjectForm(): void {
        this.hasSelectedOptions = false;
        this.updateProjectEvent$.next(true);
        this.projectHasUpdates$.next(false);
        this.projectImage$.next(undefined);
        this.isLoading$.next(true);
    }

    projectWasUpdated(): void {
        setTimeout(() => this.updateProjectEvent$.next(false));
        setTimeout(() => this.isLoading$.next(false));
    }

    createOrUpdateProject(name: string, description: string): void {
        if (this.parentId) {
            this.updateProject(name, description);
        } else {
            this.createProject(name, description);
        }
    }

    private buildViewModel(): Observable<EditProjectDialogViewModel> {
        return combineLatest([
            this.store.select(selectAuthenticatedUser),
            this.store.select(selectParentType),
            this.store.select(selectProject).pipe(
                distinctUntilChanged((previousPayload: Project, currentPayload: Project) => {
                    if (isEqual(previousPayload, currentPayload)) {
                        return true;
                    }

                    this.projectHasUpdates$.next(true);
                    return false;
                }),
            ),
            this.isLoading$.asObservable().pipe(distinctUntilChanged()),
            this.projectImage$.asObservable().pipe(distinctUntilChanged()),
            this.projectHasUpdates$.asObservable().pipe(distinctUntilChanged()),
            this.updateProjectEvent$.asObservable().pipe(distinctUntilChanged()),
            this.optionsStore.currentOptionToDisplay$,
            this.optionsStore.selectedProjectClients$,
            this.optionsStore.hasClientChanged$,
        ]).pipe(
            map(
                ([
                    user,
                    parentType,
                    project,
                    isLoading,
                    projectImage,
                    projectHasUpdates,
                    updateProjectEvent,
                    currentOptionToDisplay,
                    selectedProjectClients,
                    hasClientChanged,
                ]) => {
                    this.parentType = parentType;
                    this.parentId = project?.id;
                    this.user = user;

                    if (!this.hasSelectedOptions) {
                        const options = {
                            clients: project?.clients || {},
                            tags: project?.tags || {},
                        };
                        this.savedProjectOptions = options;
                        this.optionsStore.setSelectedProjectOptions(options);
                        this.hasSelectedOptions = true;
                    }

                    const title = projectOptionsDialogTitle?.[currentOptionToDisplay] || '';

                    const oldProjectImage = {
                        url: project?.imageUrl,
                        id: project?.imageId,
                    } as FileType;

                    return {
                        title,
                        project,
                        projectHasUpdates,
                        updateProjectEvent,
                        isLoading,
                        currentOptionToDisplay,
                        hasClientChanged,
                        isClientSelected: !!Object.keys(selectedProjectClients).length,
                        projectImage: projectImage || oldProjectImage,
                        loaderDescription: (this.parentId
                            ? LoadedDescription.ProjectEditing
                            : LoadedDescription.ProjectCreating
                        ).toUpperCase(),
                        isPublicProjectTemplate: parentType === ParentType.PublicProjectTemplates,
                    };
                },
            ),
            startWith(defaultModel),
        );
    }

    private async createProject(name: string, description: string): Promise<void> {
        this.isLoading$.next(true);
        const projectOptions = await firstValueFrom(this.optionsStore.selectedProjectOptions$.pipe(take(1)));

        const projectImage = this.projectImage$.getValue();

        const projectData = removeNoValuesKeys<Project>(
            new Project('', {
                name,
                description,
                tags: projectOptions.tags || {},
                clients: projectOptions.clients || {},
                templates: {},
                imageUrl: projectImage?.url || null,
                imageId: projectImage?.id || null,
            }),
        );

        const projectId = await this.projectService.createProject(ParentType.Projects, projectData, this.user);

        await this.projectService.updateUserAccessOptions(this.parentType, projectId, this.user.id, projectOptions);

        await this.updateOptions(projectOptions, projectId);

        this.router.navigate([routerLinksMap[ParentType.Projects], projectId]);
        this.closeDialog();
    }

    private async updateProject(name: string, description: string): Promise<void> {
        this.isLoading$.next(true);
        const projectOptions = await firstValueFrom(this.optionsStore.selectedProjectOptions$.pipe(take(1)));

        const projectData = {
            name,
            description,
            tags: projectOptions.tags || {},
            clients: projectOptions.clients || {},
        } as Partial<Project>;

        const projectImage = this.projectImage$.getValue();
        if (projectImage) {
            projectData.imageUrl = projectImage.url || null;
            projectData.imageId = projectImage.id || null;
        }

        await this.projectService.updateProjectDocument(this.parentType, this.parentId, projectData);

        if (this.parentType === ParentType.Projects) {
            await this.projectService.updateUserAccessOptions(
                this.parentType,
                this.parentId,
                this.user.id,
                projectOptions,
            );
        }

        if (this.parentType === ParentType.ProjectTemplates) {
            await this.templateAccessService.updateTemplateAccess(
                DBPathHelper.getFavoriteAccessPath(),
                this.parentId,
                projectData,
            );
        }

        this.updateOptions(projectOptions, this.parentId);
        this.isLoading$.next(false);
        this.closeDialog();
    }

    private async updateOptions(selectedProjectOptions: SelectedProjectOptions, sessionId: string): Promise<void> {
        const optionsToCreateIds = await firstValueFrom(this.optionsStore.projectOptionsToCreateIds$.pipe(take(1)));

        const optionsToRemove = projectOptionsArray.reduce((acc, optionType) => {
            acc[optionType] = Object.keys(this.savedProjectOptions?.[optionType] || {}).filter(
                id => !Object.keys(selectedProjectOptions[optionType] || {}).includes(id),
            );

            return acc;
        }, {});

        await this.projectOptionsService.updateOptions(
            selectedProjectOptions,
            sessionId,
            optionsToRemove,
            optionsToCreateIds,
        );
    }
}
