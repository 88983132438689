import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { distinctUntilChanged, map, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AcnBreakpointObserver extends BreakpointObserver {
    getIsMatches(breakpoints: string | readonly string[]): Observable<boolean> {
        return this.observe(breakpoints).pipe(
            map(breakPointState => !!breakPointState.matches),
            distinctUntilChanged(),
        );
    }
}
