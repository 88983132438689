import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import {
    ActivityType,
    activityTypesToDisplay,
    aiBuilderSessionPlaceholders,
    betaHintNew,
    BotActions,
    BuilderStep,
    builderSteps,
    characterLimitText,
    characterLimitXL,
    determineHintClass,
    Dictionary,
    NodeType,
    parentTypeToDisplay,
    validationMessages,
} from '@accenture/shared/data';
import { IconColor, IconSize, LoadedDescription } from '@accenture/shared/ui';
import { trackById } from '@accenture/shared/util';

import { AiGenerateModalTt9Facade } from './ai-generate-modal-tt9-facade';

interface InputData {
    title: string;
    botAction: BotActions;
}

type AiPromptsForm = FormGroup<{
    objectivesControl: FormControl<string>;
    audienceControl: FormControl<string>;
    flowControl: FormControl<string>;
}>;

@Component({
    selector: 'accenture-ai-generate-modal-tt9',
    templateUrl: './ai-generate-modal-tt9.component.html',
    styleUrls: ['./ai-generate-modal-tt9.component.scss'],
    providers: [AiGenerateModalTt9Facade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiGenerateModalTt9Component {
    vm$ = this.facade.vm$;
    loaderText!: LoadedDescription;

    protected readonly aiBuilderSessionPlaceholders = aiBuilderSessionPlaceholders;

    builderSteps = builderSteps;
    characterLimit = characterLimitXL;
    characterLimitText = characterLimitText;
    determineHintClass = determineHintClass;
    validationMessages = validationMessages;
    trackById = trackById;
    iconSize = IconSize;
    iconColor = IconColor;
    betaHint = betaHintNew;
    nodeType = NodeType;

    aiPromptsForm: AiPromptsForm = new FormGroup({
        objectivesControl: new FormControl('', { validators: Validators.required, nonNullable: true }),
        audienceControl: new FormControl('', { nonNullable: true }),
        flowControl: new FormControl('', { nonNullable: true }),
    });

    constructor(@Inject(MAT_DIALOG_DATA) public data: InputData, private facade: AiGenerateModalTt9Facade) {}

    get isGenerateSession(): boolean {
        return this.data.botAction === BotActions.CreateSession;
    }

    get isGenerateActivity(): boolean {
        return this.data.botAction === BotActions.CreateActivity;
    }

    get currentLabel(): Dictionary<{ subTitle: string; placeholder: string }> {
        return aiBuilderSessionPlaceholders;
    }

    isActivityType(parentType: NodeType): boolean {
        return parentType === NodeType.Activity;
    }

    getLabelToDisplay(parentType: NodeType, type?: ActivityType): string {
        return activityTypesToDisplay[type] ?? parentTypeToDisplay[parentType];
    }

    backToPreviousModal(step: BuilderStep): void {
        if (this.isPreviewStep(step)) {
            this.facade.moveToEntryStep(this.data.botAction);
            return;
        }

        this.facade.backToPreviousModal();
    }

    isEntryStep(currentStep: BuilderStep): boolean {
        return currentStep === BuilderStep.Entry;
    }

    isPreviewStep(currentStep: BuilderStep): boolean {
        return currentStep === BuilderStep.Preview;
    }

    closeModal(step: BuilderStep): void {
        this.facade.closeDialogWithConfirmation(this.isPreviewStep(step), this.data.botAction);
    }

    buttonLabel(currentStep: BuilderStep): string {
        return currentStep === BuilderStep.Entry ? 'Cancel' : 'Back';
    }

    async generate(): Promise<void> {
        this.loaderText = LoadedDescription.Generating;
        await this.facade.generate(this.generatePrompt, this.data.botAction);
    }

    async complete(): Promise<void> {
        this.loaderText = LoadedDescription.Completing;
        await this.facade.saveData(this.data.botAction);
    }

    private get generatePrompt(): string {
        return `${
            this.aiPromptsForm.controls.objectivesControl.value
                ? `The purpose & objectives are: ${this.aiPromptsForm.controls.objectivesControl?.value}`
                : ''
        }\n
        ${
            this.aiPromptsForm.controls.audienceControl.value
                ? `Following are the intended primary stakeholders or participants: ${this.aiPromptsForm.controls.audienceControl.value}`
                : ''
        }\n
        ${
            this.aiPromptsForm.controls.flowControl.value
                ? `Following is the outline of how the activity(s) would flow: ${this.aiPromptsForm.controls.flowControl.value}`
                : ''
        }\n`;
    }
}
