import { ChangeDetectionStrategy, Component, HostListener, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, firstValueFrom, map, Observable, Subject } from 'rxjs';

import {
    AppState,
    isLinkAccessPage,
    isLogin,
    isProfilePage,
    selectAuthenticatedUserId,
    selectMaintenanceConfig,
    selectParentType,
    selectProjectIdAndSessionId,
    setTeamMemberOfflineState,
} from '@accenture/global-store';
import { MaintenanceConfig, ParentType } from '@accenture/shared/data';
import { ScrollPosition, UIBlockerService } from '@accenture/shared/ui';

@Component({
    selector: 'accenture-fred-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnDestroy {
    showLoader$: Observable<boolean>;
    headerStates$: Observable<{ isScrollSpyDisabled: boolean; isHeaderVisible: boolean; isHeaderHidden: boolean }>;

    collapseHeader = false;
    isChatBotVisible = false;
    onDestroy = new Subject<void>();

    constructor(uiBlockerService: UIBlockerService, private store: Store<AppState>, private router: Router) {
        this.showLoader$ = uiBlockerService.showLoader;
        this.headerStates$ = combineLatest([
            store.select(isProfilePage),
            store.select(isLogin),
            store.select(isLinkAccessPage),
            store.select(selectAuthenticatedUserId),
            store.select(selectMaintenanceConfig) as Observable<MaintenanceConfig>,
        ]).pipe(
            map(([isProfile, isLogin, isLinkAccessPage, userId, maintenanceConfig]) => ({
                isHeaderHidden: !!(isLinkAccessPage && !userId), // hide for link access pages if not authorized
                isHeaderVisible: !isLogin, // show all menu tabs or sign up and log in
                isScrollSpyDisabled: !isProfile,
                maintenanceActive: maintenanceConfig.maintenanceActive, // show is app under maintenance
            })),
        );
    }

    toggleChatBot(): void {
        this.isChatBotVisible = !this.isChatBotVisible;
    }

    @HostListener('window:beforeunload')
    setUserOffline(): void {
        this.setOffline();
        this.onDestroy.next();
    }

    ngOnDestroy(): void {
        this.setOffline();
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    onScrollPositionChange(position: ScrollPosition): void {
        this.collapseHeader = position !== ScrollPosition.Start;
    }

    private async setOffline(): Promise<void> {
        const parentType = await firstValueFrom(this.store.select(selectParentType));
        if (parentType !== ParentType.Projects) {
            return;
        }
        const { projectId, sessionId } = await firstValueFrom(this.store.select(selectProjectIdAndSessionId));
        this.store.dispatch(setTeamMemberOfflineState({ projectId, sessionId }));
    }
}
