import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';

import { IconColor, IconSize, SharedUiModule } from '@accenture/shared/ui';

@Component({
    selector: 'accenture-confirmation',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedUiModule, NgIf, RouterLink],
})
export class ConfirmComponent {
    @Input() title!: string;
    @Input() text!: string;
    @Input() iconName!: string;
    @Input() hideConfirmationButton?: boolean;
    @Input() iconSize?: IconSize;
    @Input() iconColor?: IconColor;

    defaultColor = IconColor.Secondary;
    defaultSize = IconSize.Huge;
}
