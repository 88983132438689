import { Action, createReducer, on } from '@ngrx/store';

import { ActivityConfiguration, Dictionary, FeatureToggles, MaintenanceConfig } from '@accenture/shared/data';

import {
    getApplicationConfiguration,
    getApplicationConfigurationError,
    getApplicationConfigurationSuccess,
} from './application-configuration.actions';

export const APPLICATION_CONFIGURATION_FEATURE_KEY = 'applicationConfiguration';

export interface ApplicationConfigurationState {
    activityConfigurations: ActivityConfiguration[] | undefined;
    featureToggles: FeatureToggles;
    timeouts: Dictionary<number>;
    maintenanceConfig: MaintenanceConfig;
    error: any;
}

const initialState: ApplicationConfigurationState = {
    activityConfigurations: undefined,
    featureToggles: {},
    timeouts: {},
    maintenanceConfig: {} as MaintenanceConfig,
    error: {},
};

const _applicationConfigurationReducer = createReducer(
    initialState,
    on(getApplicationConfiguration, state => ({ ...state })),
    on(
        getApplicationConfigurationSuccess,
        (state, { activityConfigurations, featureToggles, timeouts, maintenanceConfig }) => ({
            ...state,
            activityConfigurations,
            featureToggles,
            timeouts,
            maintenanceConfig,
        }),
    ),
    on(getApplicationConfigurationError, (state, { error }) => ({
        ...state,
        error,
    })),
);

export function applicationConfigurationReducer(state: any, action: Action) {
    return _applicationConfigurationReducer(state, action);
}
