export const screens = {
    'x-large-desktop': 1920,
    'large-desktop': 1600,
    'medium-desktop': 1440,
    'small-desktop': 1280,
    'tablet-landscape': 1024,
    'tablet-portrait': 768,
    'handset-landscape': 844,
    'handset-portrait': 390,
    'small-handset-landscape': 568,
    'small-handset-portrait': 320,
};

// can be added more
export const breakpoints = {
    // > 1280
    screenAllDesktops: `(min-width: ${screens['small-desktop']}px)`,

    // < 1279
    screenAllMobiles: `(max-width: ${screens['small-desktop'] - 1}px)`,

    // < 767 portrait && < 1023 landscape
    screenAllHandsets: [
        `(max-width: ${screens['tablet-portrait'] - 1}px) and (orientation: portrait)`,
        `(max-width: ${screens['tablet-landscape'] - 1}px) and (orientation: landscape)`,
    ],

    // 768-1279 portrait and 1024-1279 landscape
    screenTablet: [
        `(min-width: ${screens['tablet-portrait']}px) and (max-width: ${
            screens['small-desktop'] - 1
        }px) and (orientation: portrait)`,
        `(min-width: ${screens['tablet-landscape']}px) and (max-width: ${
            screens['small-desktop'] - 1
        }px) and (orientation: landscape)`,
    ],

    // 768-1279 portrait
    screenTabletPortrait: [
        `(min-width: ${screens['tablet-portrait']}px) and (max-width: ${
            screens['small-desktop'] - 1
        }px) and (orientation: portrait)`,
    ],

    // 390-767 portrait and 844-1023 landscape
    screenHandset: [
        `(min-width: ${screens['handset-portrait']}px) and (max-width: ${
            screens['tablet-portrait'] - 1
        }px) and (orientation: portrait)`,
        `(min-width: ${screens['handset-landscape']}px) and (max-width: ${
            screens['tablet-landscape'] - 1
        }px) and (orientation: landscape)`,
    ],

    // < 389 portrait and < 843 landscape
    screenSmallHandset: [
        `(min-width: ${screens['small-handset-portrait']}px) and (max-width: ${
            screens['handset-portrait'] - 1
        }px) and (orientation: portrait)`,
        `(min-width: ${screens['small-handset-landscape']}px) and (max-width: ${
            screens['handset-landscape'] - 1
        }px) and (orientation: landscape)`,
    ],

    // < 1279 portrait
    screenAllMobilesPortrait: [`(max-width: ${screens['small-desktop'] - 1}px) and (orientation: portrait)`],

    // < 768 portrait
    screenHandsetPortrait: [`(max-width: ${screens['tablet-portrait'] - 1}px) and (orientation: portrait)`],
};
