<div class="stars-container">
  <button
    *ngFor="let value of ratingValues"
    class="tt9-btn-ghost star"
    [ngClass]="colorIconClass(value)"
    [attr.index]="value"
    (click)="setRating(value)"
  >
    <accenture-icon class="star-icon" [size]="iconSize">icon-star</accenture-icon>
  </button>
</div>
