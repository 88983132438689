/* eslint-disable no-useless-escape */
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const urlValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
        return null;
    }

    const urlPattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!%^{}"<>`|\$&'\(\)\*\+,;=.]+$/;

    const isValid = urlPattern.test(control.value);

    return isValid ? null : { invalidUrl: { value: control.value } };
};
