import { Dictionary } from './dictionary';
import { NavigationTab, TemplatesTab } from './navigation-tabs';

export interface NavigationMenuItem {
    text: string;
    icon: string;
    tab: NavigationTab;
    queryParams?: Dictionary<string>;
}

export const baseNavigationMenuItems: NavigationMenuItem[] = [
    { text: NavigationTab.Home, icon: 'icon-home', tab: NavigationTab.Home },
    { text: NavigationTab.Sessions, icon: 'icon-file', tab: NavigationTab.Sessions },
    { text: NavigationTab.Collections, icon: 'icon-folder', tab: NavigationTab.Collections },
    {
        text: TemplatesTab.MyTemplates,
        icon: 'icon-template',
        tab: NavigationTab.Templates,
        queryParams: { templatesTab: TemplatesTab.MyTemplates },
    },
];
