export type SessionFocusStepType = SessionFocusSteps;

export enum SessionFocusSteps {
    Vote = 'VOTE',
    VoteSummary = 'VOTE_SUMMARY',
    Poll = 'POLL',
    PollSummary = 'POLL_SUMMARY',
    BrainstormResponses = 'BRAINSTORM_RESPONSES',
    Table = 'TABLE',
    TableValidate = 'TABLE_VALIDATE',
    TableSummary = 'TABLE_SUMMARY',
    Proompt = 'PROOMPT',
    Spark = 'SPARK',
    Whiteboard = 'WHITEBOARD',
}
