import { Security } from 'filestack-js';

import { Dictionary } from '@accenture/shared/data';

import { AntiVirusScanResult } from './anti-virus-scan-result';

export type FilestackUploadDoneFn = (uploadResult: FilestackUploadResult[]) => Promise<void>;

export type FilestackUploadStartedFn = () => void;

export type FilestackFileFinishedFn = () => void;

export type FilestackFileUploadStartedFn = () => void;

export type FilestackUploadFailedFn = (scanResult: AntiVirusScanResult) => void;

export enum FilestackStorage {
    GoogleStorage = 'GoogleStorage',
    FirebaseStorage = 'FirebaseStorage',
}

export enum FilestackUploadType {
    Files = 'Files',
    File = 'File',
    Images = 'Images',
    MultipleImages = 'MultipleImages',
    EXCELFiles = 'EXCELFiles',
    EXCELFile = 'EXCELFile',
    MediaFile = 'MediaFile',
    PresentationFiles = 'PresentationFiles',
    AiReferenceDocument = 'AiReferenceDocument',
}

export interface FilestackUploadOptions {
    onUploadDone: FilestackUploadDoneFn; // success upload callback
    onUploadStarted?: FilestackUploadStartedFn; // start upload callback
    onUploadFailed?: FilestackUploadFailedFn; // failed upload callback
    onFileUploadStarted?: FilestackFileUploadStartedFn;
    onFileUploadFinished?: FilestackFileFinishedFn;
    onCancel?: FilestackFileFinishedFn;
    storeTo?: FilestackStorage; // location where to store files; default = FilestackStorage.GoogleStorage
    uploadType?: FilestackUploadType; // images only or all allowed files; default = FilestackUploadType.Files
    fbFolder?: string; // folder name in firebase storage
    gsFolder?: string; // folder name in google storage
    customMetadata?: Dictionary<string>; // db data for firebase access rules
    userId: string;
    oldFile?: string;
}

export interface FilestackUploadResult {
    url: string;
    fileName?: string;
    filestackFileId?: string;
    filestackFileUrl?: string;
}

export interface FilestackIntitializationOptions {
    apiKey: string;
    security: Security;
    virusCheckWorkflowId: string;
}
