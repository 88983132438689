import { Pipe, PipeTransform } from '@angular/core';
import { isNull, isUndefined } from 'lodash';

import { Attribute, Dictionary } from '@accenture/shared/data';

@Pipe({
    name: 'displayActivityLabel',
})
export class DisplayActivityLabelPipe implements PipeTransform {
    transform(activityItemLabel: Dictionary<string> | undefined, attribute: Attribute | undefined): string {
        const attributeId: string | undefined = attribute?.id;
        const defaultLabel = activityItemLabel?.['default'] || '';

        if (!attributeId) {
            return defaultLabel;
        }

        const label = activityItemLabel?.[attributeId];

        return isNull(label) || isUndefined(label) ? defaultLabel : label;
    }
}
