import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Dictionary, starsCount } from '@accenture/shared/data';
import { IconSize } from '../../models';

const starsColorsCssClasses = {
    selected: 'star-selected',
    default: 'star-default',
};

@Component({
    selector: 'accenture-star-voting',
    templateUrl: './star-voting.component.html',
    styleUrls: ['./star-voting.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => StarVotingComponent),
            multi: true,
        },
    ],
})
export class StarVotingComponent implements ControlValueAccessor {
    readonly iconSize = IconSize.Medium;

    ratingValues: number[] = Array.from(new Array(starsCount)).map((_, index) => index + 1);

    @Input()
    set disabled(value: boolean) {
        this.setDisabledState(value);
    }
    get disabled(): boolean {
        return this._disabled;
    }
    private _disabled = false;

    @Input()
    set rating(value: number) {
        this.setRating(value);
    }
    get rating(): number {
        return this._rating;
    }
    private _rating = 0;

    private touched = false;
    private onChange = (value: number) => {
        // can be set via registerOnChange
    };
    private onTouched = () => {
        // can be set via registerOnTouched
    };

    colorIconClass(index: number): Dictionary<boolean> {
        const iconClass
            = this._rating >= starsCount - index + 1 ? starsColorsCssClasses.selected : starsColorsCssClasses.default;
        return {
            [iconClass]: true,
            disabled: this._disabled,
        };
    }

    setRating(value: number): void {
        this.markAsTouched();
        if (!this._disabled) {
            this._rating = starsCount + 1 - value;
            this.onChange(this._rating);
        }
    }

    writeValue(value: number): void {
        this._rating = value;
    }

    registerOnChange(onChange: (value: number) => void): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    markAsTouched(): void {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    setDisabledState(disabled: boolean): void {
        this._disabled = disabled;
    }
}
