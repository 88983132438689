import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

const cellFormatRegex = /^[A-Z]+\d+$/;
const cellMatchFormatRegex = /([A-Z]+)([0-9]+)/;
const unicodeLetterA = 65;
const lettersNumber = 26;

export const tableImportCellsRangeValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
    const topLeftCellControl = formGroup.get('topLeftCell');
    const bottomRightCellControl = formGroup.get('bottomRightCell');

    validateCell(topLeftCellControl, 'invalidCellFormat');
    validateCell(bottomRightCellControl, 'invalidCellFormat');
    isValidCells(topLeftCellControl, bottomRightCellControl);

    return topLeftCellControl?.errors || bottomRightCellControl?.errors ? { hasErrors: true } : null;
};

const validateCell = (control: AbstractControl, formatKey: string): void => {
    if (!control || !control.value) {
        control?.setErrors({ required: true });
    } else if (!cellFormatRegex.test(control.value)) {
        control?.setErrors({ [formatKey]: true });
    }
};

const getColumnNumber = (column: string): number => {
    let numericResult = 0;

    for (let i = 0; i < column.length; i++) {
        // Convert A=1, B=2, ..., Z=26
        const charCode = column.charCodeAt(i) - unicodeLetterA + 1; // add 1 to do "A" is represented as 1
        numericResult = numericResult * lettersNumber + charCode;
    }

    return numericResult;
};

const getLetterAndNumber = (cell: string): [string, number] | null => {
    const matches = cell.match(cellMatchFormatRegex);
    if (!matches) return null;

    const [, letter, number] = matches;
    return [letter, parseInt(number, 10)];
};

const isValidCells = (topLeftCellControl: AbstractControl, bottomRightCellControl: AbstractControl): boolean => {
    const startCell = topLeftCellControl.value;
    const endCell = bottomRightCellControl.value;

    if (!startCell || !endCell || !cellFormatRegex.test(startCell) || !cellFormatRegex.test(endCell)) {
        return;
    }

    const [startLetter, startNumber] = getLetterAndNumber(startCell) || ['', 0];
    const [endLetter, endNumber] = getLetterAndNumber(endCell) || ['', 0];

    const startLetterNumber = getColumnNumber(startLetter);
    const endLetterNumber = getColumnNumber(endLetter);

    const isCellPreceding = endLetterNumber >= startLetterNumber && endNumber >= startNumber;
    const errorsValue = !isCellPreceding ? { cellsNotPreceding: true } : null;

    topLeftCellControl?.setErrors(errorsValue);
    bottomRightCellControl?.setErrors(errorsValue);

    return isCellPreceding;
};
