import { Injectable } from '@angular/core';
import { unset } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    CollectionSortOptions,
    CollectionsSortObject,
    ParentType,
    ProjectOptions,
    projectOptionsArray,
    ProjectOptionsType,
    SelectedProjectOptions,
    SessionOptionsType,
    SortOrders,
} from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import { sortByAlphabetic } from '@accenture/shared/util';

@Injectable({
    providedIn: 'root',
})
export class ProjectOptionsService {
    constructor(private firestoreService: FirestoreService) {}

    getNewOptionId(): string {
        return this.firestoreService.getPushId();
    }

    getClientsPath(clientId?: string): string {
        return '/clients' + (clientId ? `/${clientId}` : '');
    }

    getTagsPath(tagId?: string): string {
        return '/tags' + (tagId ? `/${tagId}` : '');
    }

    getOptionPath(type: ProjectOptions, id?: string): string {
        switch (type) {
            case ProjectOptions.Client:
                return this.getClientsPath(id);
            case ProjectOptions.Tag:
                return this.getTagsPath(id);
            default:
                return this.getTagsPath(id);
        }
    }

    getOptions(type: ProjectOptions): Observable<ProjectOptionsType[]> {
        return this.firestoreService.getDocumentsByMultipleProperties<ProjectOptionsType>(
            this.getOptionPath(type),
            new Map<string, any>([['type', type === ProjectOptions.Tag ? ParentType.Projects : '']]),
            'name',
        );
    }

    addOption(type: ProjectOptions, name: string, projectId: string): Promise<string> {
        const newOption = { name, projects: [projectId], updated: this.firestoreService.timestamp };

        return this.firestoreService.addDocument(this.getOptionPath(type), newOption);
    }

    getCurrentUserCollectionsSorting(userId: string | undefined): Observable<CollectionsSortObject> {
        return this.firestoreService
            .getDocument<CollectionsSortObject>(`/users/${userId}/filters/collectionsFilters`)
            .pipe(
                map((filter: CollectionsSortObject) => {
                    unset(filter, 'id');
                    return filter;
                }),
            );
    }

    async updateCollectionsSorting(userId: string | undefined, data: CollectionsSortObject): Promise<void> {
        await this.firestoreService.upsert(`users/${userId}/filters/collectionsFilters`, data);
    }

    sortOptionsData(
        options: ProjectOptionsType[] | SessionOptionsType[],
        filterObject: CollectionsSortObject,
    ): ProjectOptionsType[] | SessionOptionsType[] {
        const sortedOptions: ProjectOptionsType[] | SessionOptionsType[]
            = filterObject?.sortOption === CollectionSortOptions.Name
                ? sortByAlphabetic(options, 'name')
                : options.sort((a, b) => (a.projects?.length || 0) - (b.projects?.length || 0));

        return filterObject?.sortOrder === SortOrders.Asc ? sortedOptions : sortedOptions.reverse();
    }

    async updateOptions(
        selectedProjectOptions: SelectedProjectOptions,
        projectId: string,
        optionsToRemoveIds?: { [key: string]: string[] },
        optionsToCreateIds?: { [key: string]: string[] },
    ): Promise<void> {
        const batchToUpdate = [];

        projectOptionsArray.forEach(type => {
            let option = {
                projects: [projectId],
                created: this.firestoreService.timestamp,
                updated: this.firestoreService.timestamp,
            } as ProjectOptionsType;

            if (ProjectOptions.Tag === type) {
                option = {
                    ...option,
                    type: ParentType.Projects,
                };
            }

            Object.keys(selectedProjectOptions[type] || {}).forEach(optionId => {
                // create new option
                if ((optionsToCreateIds?.[type] || []).includes(optionId)) {
                    option = {
                        ...option,
                        name: selectedProjectOptions[type][optionId],
                    };

                    batchToUpdate.push({
                        path: this.getOptionPath(type, optionId),
                        data: option,
                    });
                } else {
                    // update option
                    batchToUpdate.push({
                        path: this.getOptionPath(type, optionId),
                        data: {
                            projects: this.firestoreService.arrayUnion(projectId),
                            updated: this.firestoreService.timestamp,
                        },
                    });
                }
            });

            if (optionsToRemoveIds) {
                (optionsToRemoveIds[type] || []).forEach(optionId => {
                    batchToUpdate.push({
                        path: this.getOptionPath(type, optionId),
                        data: {
                            projects: this.firestoreService.arrayRemove(projectId),
                            updated: this.firestoreService.timestamp,
                        },
                    });
                });
            }
        });

        await this.firestoreService.writeBatch(batchToUpdate);
    }
}
