import { FieldValue, Timestamp } from 'firebase/firestore';

import { FileType } from './responses';

export interface ValidationFeedback {
    id?: string;
    activityItemId: string;
    responseId: string;
    activityId: string;
    sessionId: string;
    attributeId?: string;
    parentSectionId?: string;
    repeatedSectionId?: string;
    rowId?: string;
    columnId?: string;
    userId: string;
    userDisplayName: string;
    userImageUrl: string;
    agree: boolean;
    comment?: string;
    attachment?: FileType;
    created: Timestamp | FieldValue;
    updated: Timestamp | FieldValue;
}

export interface NotAccepted {
    id: string;
    activityId: string;
    sessionId: string;
    activityItemId: string;
    attributeId?: string;
    parentSectionId?: string;
    repeatedSectionId?: string;
    disagreeCount: number;
    revalidate?: boolean;
}

export interface FeedbackResult {
    numberOfAgreements: number;
    numberOfDisagreements: number;
    numberOfComments: number;
}

export enum OptionChangeNotAcceptedCount {
    increment = 'increment',
    decrement = 'decrement',
}
