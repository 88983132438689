import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import {
    DBPathHelper,
    FileType,
    NotAccepted,
    OptionChangeNotAcceptedCount,
    ParentType,
    TableResponse,
    User,
    ValidationFeedback,
} from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

import { FilesService } from './files.service';

@Injectable({
    providedIn: 'root',
})
export class ActivityValidationFeedbackService {
    constructor(private firestoreService: FirestoreService, private filesService: FilesService) {}
    // TODO: Delete after project  deprecation
    async addFeedback(projectId: string, validationFeedback: Partial<ValidationFeedback>): Promise<void> {
        await this.firestoreService.addDocument(
            DBPathHelper.getValidationFeedbackPath(ParentType.Projects, projectId),
            {
                ...validationFeedback,
                created: this.firestoreService.timestamp,
                updated: this.firestoreService.timestamp,
            },
        );
    }
    async addFeedbackNew(sessionId: string, validationFeedback: Partial<ValidationFeedback>): Promise<void> {
        await this.firestoreService.addDocument(
            DBPathHelper.getValidationFeedbackPath(ParentType.Sessions, sessionId),
            {
                ...validationFeedback,
                created: this.firestoreService.timestamp,
                updated: this.firestoreService.timestamp,
            },
        );
    }
    async addFeedbackWithAttachment(
        projectId: string,
        validationFeedback: Partial<ValidationFeedback>,
        attachment: FileType,
    ): Promise<void> {
        const fileId = this.firestoreService.getPushId();
        await this.filesService.createFileReference(fileId, attachment);
        validationFeedback.attachment = {
            ...attachment,
            id: fileId,
        };
        await this.addFeedback(projectId, validationFeedback);
    }
    // TODO: Delete after project  deprecation
    async updateFeedback(
        projectId: string,
        validationFeedbackId: string,
        validationFeedback: Partial<ValidationFeedback>,
    ): Promise<void> {
        delete validationFeedback.id;
        await this.firestoreService.update(
            DBPathHelper.getValidationFeedbackPath(ParentType.Projects, projectId, validationFeedbackId),
            {
                ...validationFeedback,
                updated: this.firestoreService.timestamp,
            },
        );
    }
    async updateFeedbackNew(
        sessionId: string,
        validationFeedbackId: string,
        validationFeedback: Partial<ValidationFeedback>,
    ): Promise<void> {
        delete validationFeedback.id;
        await this.firestoreService.update(
            DBPathHelper.getValidationFeedbackPath(ParentType.Sessions, sessionId, validationFeedbackId),
            {
                ...validationFeedback,
                updated: this.firestoreService.timestamp,
            },
        );
    }
    // TODO: Delete after project  deprecation
    async updateFeedbackWithAttachment(
        projectId: string,
        validationFeedbackId: string,
        validationFeedback: Partial<ValidationFeedback>,
        attachment: FileType,
    ): Promise<void> {
        const fileId = this.firestoreService.getPushId();
        await this.filesService.createFileReference(fileId, attachment);
        if (validationFeedback.attachment?.id && validationFeedback.attachment.id !== attachment.id) {
            this.filesService.decrementFilesCount(validationFeedback.attachment.id);
            validationFeedback.attachment = this.firestoreService.deleteField as any;
        }
        validationFeedback.attachment = {
            ...attachment,
            id: fileId,
        };
        await this.updateFeedback(projectId, validationFeedbackId, validationFeedback);
    }
    async updateFeedbackWithAttachmentNew(
        sessionId: string,
        validationFeedbackId: string,
        validationFeedback: Partial<ValidationFeedback>,
        attachment: FileType,
    ): Promise<void> {
        const fileId = this.firestoreService.getPushId();
        await this.filesService.createFileReference(fileId, attachment);
        if (validationFeedback.attachment?.id && validationFeedback.attachment.id !== attachment.id) {
            this.filesService.decrementFilesCount(validationFeedback.attachment.id);
            validationFeedback.attachment = this.firestoreService.deleteField as any;
        }
        validationFeedback.attachment = {
            ...attachment,
            id: fileId,
        };
        await this.updateFeedbackNew(sessionId, validationFeedbackId, validationFeedback);
    }
    // TODO: Delete after project  deprecation
    async deleteFeedback(projectId: string, validationFeedback: ValidationFeedback): Promise<void> {
        await this.firestoreService.delete(
            DBPathHelper.getValidationFeedbackPath(ParentType.Projects, projectId, validationFeedback.id),
        );
        if (validationFeedback.attachment?.id) {
            await this.filesService.decrementFilesCount(validationFeedback.attachment.id);
        }
    }
    async deleteFeedbackNew(sessionId: string, validationFeedback: ValidationFeedback): Promise<void> {
        await this.firestoreService.delete(
            DBPathHelper.getValidationFeedbackPath(ParentType.Sessions, sessionId, validationFeedback.id),
        );
        if (validationFeedback.attachment?.id) {
            await this.filesService.decrementFilesCount(validationFeedback.attachment.id);
        }
    }

    // TODO: Delete after project  deprecation
    async deleteFeedbacks(projectId: string, validationFeedbackIdIds: string[]): Promise<void> {
        if (!validationFeedbackIdIds.length) {
            return;
        }
        const batchData = [];
        validationFeedbackIdIds.forEach((validationFeedbackId: string) => {
            batchData.push({
                path: DBPathHelper.getValidationFeedbackPath(ParentType.Projects, projectId, validationFeedbackId),
            });
        });
        await this.firestoreService.deleteBatch(batchData);
    }

    async deleteFeedbacksNew(sessionId: string, validationFeedbackIdIds: string[]): Promise<void> {
        if (!validationFeedbackIdIds.length) {
            return;
        }

        const batchData = [];

        validationFeedbackIdIds.forEach((validationFeedbackId: string) => {
            batchData.push({
                path: DBPathHelper.getValidationFeedbackPath(ParentType.Sessions, sessionId, validationFeedbackId),
            });
        });

        await this.firestoreService.deleteBatch(batchData);
    }

    async deleteFeedbacksWithAttachments(projectId: string, validationFeedbacks: ValidationFeedback[]): Promise<void> {
        if (!validationFeedbacks.length) {
            return;
        }
        const batchData = [];
        const batchFilesData = [];
        validationFeedbacks.forEach((validationFeedback) => {
            const attachmentId = validationFeedback?.attachment?.id;
            batchData.push({
                path: DBPathHelper.getValidationFeedbackPath(ParentType.Projects, projectId, validationFeedback.id),
            });
            if (attachmentId) {
                batchFilesData.push({
                    path: DBPathHelper.getFilesRefsPath(attachmentId),
                    data: { count: this.firestoreService.changeCounterValue(-1) },
                });
            }
        });
        await this.firestoreService.writeBatch(batchFilesData);
        await this.firestoreService.deleteBatch(batchData);
    }
    async deleteFeedbacksWithAttachmentsNew(
        sessionId: string,
        validationFeedbacks: ValidationFeedback[],
    ): Promise<void> {
        if (!validationFeedbacks.length) {
            return;
        }
        const batchData = [];
        const batchFilesData = [];
        validationFeedbacks.forEach((validationFeedback) => {
            const attachmentId = validationFeedback?.attachment?.id;
            batchData.push({
                path: DBPathHelper.getValidationFeedbackPath(ParentType.Sessions, sessionId, validationFeedback.id),
            });
            if (attachmentId) {
                batchFilesData.push({
                    path: DBPathHelper.getFilesRefsPath(attachmentId),
                    data: { count: this.firestoreService.changeCounterValue(-1) },
                });
            }
        });
        await this.firestoreService.writeBatch(batchFilesData);
        await this.firestoreService.deleteBatch(batchData);
    }

    // TODO: Delete after project  deprecation
    getFeedbacksByActivityId(
        projectId: string,
        activityId: string,
        userId?: string | undefined,
    ): Observable<ValidationFeedback[]> {
        return this.firestoreService.getDocumentsByMultipleProperties<ValidationFeedback>(
            DBPathHelper.getValidationFeedbackPath(ParentType.Projects, projectId),
            new Map([
                ['activityId', activityId],
                ['userId', userId || ''],
            ]),
        );
    }

    getFeedbacksByActivityIdNew(
        sessionId: string,
        activityId: string,
        userId?: string | undefined,
    ): Observable<ValidationFeedback[]> {
        return this.firestoreService.getDocumentsByMultipleProperties<ValidationFeedback>(
            DBPathHelper.getValidationFeedbackPath(ParentType.Sessions, sessionId),
            new Map([
                ['activityId', activityId],
                ['userId', userId || ''],
            ]),
        );
    }

    getFeedbacksByActivityItemId(
        projectId: string,
        activityItemId: string,
        userId?: string | undefined,
    ): Observable<ValidationFeedback[]> {
        return this.firestoreService.getDocumentsByMultipleProperties<ValidationFeedback>(
            DBPathHelper.getValidationFeedbackPath(ParentType.Projects, projectId),
            new Map([
                ['activityItemId', activityItemId],
                ['userId', userId || ''],
            ]),
        );
    }

    getFeedbacksByActivityItemIdNew(
        sessionId: string,
        activityItemId: string,
        userId?: string | undefined,
    ): Observable<ValidationFeedback[]> {
        return this.firestoreService.getDocumentsByMultipleProperties<ValidationFeedback>(
            DBPathHelper.getValidationFeedbackPath(ParentType.Sessions, sessionId),
            new Map([
                ['activityItemId', activityItemId],
                ['userId', userId || ''],
            ]),
        );
    }

    // TODO: Delete after project  deprecation
    getFeedbacksByFieldAndActivityItemId(
        projectId: string,
        activityItemId: string,
        field: string,
        fieldId: string,
    ): Observable<ValidationFeedback[]> {
        return this.firestoreService.getDocumentsByMultiplePropertiesWithoutCaching<ValidationFeedback>(
            DBPathHelper.getValidationFeedbackPath(ParentType.Projects, projectId),
            new Map([
                ['activityItemId', activityItemId],
                [field, fieldId],
            ]),
        );
    }

    getFeedbacksByFieldAndActivityItemIdNew(
        sessionId: string,
        activityItemId: string,
        field: string,
        fieldId: string,
    ): Observable<ValidationFeedback[]> {
        return this.firestoreService.getDocumentsByMultiplePropertiesWithoutCaching<ValidationFeedback>(
            DBPathHelper.getValidationFeedbackPath(ParentType.Sessions, sessionId),
            new Map([
                ['activityItemId', activityItemId],
                [field, fieldId],
            ]),
        );
    }

    async addAgreementsByResponses(projectId: string, user: User, responses: TableResponse[]): Promise<void> {
        if (!responses.length) {
            return;
        }
        const batchData: { path: string; data: ValidationFeedback }[] = [];
        responses.forEach((response: TableResponse) => {
            const id = this.firestoreService.getPushId();
            batchData.push({
                path: DBPathHelper.getValidationFeedbackPath(ParentType.Projects, projectId, id),
                data: {
                    responseId: response.id,
                    rowId: response.rowId,
                    columnId: response.columnId,
                    sessionId: response.sessionId,
                    activityId: response.activityId,
                    activityItemId: response.activityItemId,
                    userId: user.id,
                    agree: true,
                    userDisplayName: user.displayName,
                    userImageUrl: user.imageUrl,
                    created: this.firestoreService.timestamp,
                    updated: this.firestoreService.timestamp,
                },
            });
        });
        await this.firestoreService.writeBatch(batchData);
    }
    async addAgreementsByResponsesNew(sessionId: string, user: User, responses: TableResponse[]): Promise<void> {
        if (!responses.length) {
            return;
        }
        const batchData: { path: string; data: ValidationFeedback }[] = [];
        responses.forEach((response: TableResponse) => {
            const id = this.firestoreService.getPushId();
            batchData.push({
                path: DBPathHelper.getValidationFeedbackPath(ParentType.Sessions, sessionId, id),
                data: {
                    responseId: response.id,
                    rowId: response.rowId,
                    columnId: response.columnId,
                    sessionId: response.sessionId,
                    activityId: response.activityId,
                    activityItemId: response.activityItemId,
                    userId: user.id,
                    agree: true,
                    userDisplayName: user.displayName,
                    userImageUrl: user.imageUrl,
                    created: this.firestoreService.timestamp,
                    updated: this.firestoreService.timestamp,
                },
            });
        });
        await this.firestoreService.writeBatch(batchData);
    }
}
