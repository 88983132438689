import { removeEmptyKeys } from '@accenture/shared/util';

import { Question } from '../question';
import { ActivityItemType } from './activity-item-type';

export class LiveShareResource extends Question {
    type = ActivityItemType.LiveShareResource;
    url: string;

    constructor(data: Partial<LiveShareResource>) {
        super(data);

        this.url = data.url ?? '';
    }

    createSerializableObject(): Record<string, unknown> {
        return removeEmptyKeys({
            ...super.createSerializableObject(),
            url: this.url,
        });
    }
}
