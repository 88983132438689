import { Timestamp } from 'firebase/firestore';

import { AccessControlRole, ProjectRole, SessionRole, TemplateRole } from './access-control';
import { Dictionary } from './dictionary';
import { NodeTimestamp } from './node-timestamp';
import { UserStatus, UserType } from './user';

export class TeamMember extends NodeTimestamp {
    id?: string;
    userId: string;
    displayName: string;
    imageUrl?: string;
    email: string;
    parentId: string;
    role: AccessControlRole;
    guest?: boolean;
    allowSessionResponseDragHints?: boolean;
    allowSessionCommentDragHints?: boolean;
    disabledFocusNotifications?: boolean;
    isUpdateActivityDialogDisabled?: boolean;
    attributes: Dictionary<string[]>;
    isOnline?: boolean;
    lastLogin: Timestamp;
    status: UserStatus;
    type: UserType;
    deleted?: boolean;

    constructor(data: Partial<TeamMember>, id?: string) {
        super(data);

        this.id = data.id ?? id;
        this.userId = data.userId ?? '';
        this.displayName = data.displayName ?? '';
        this.imageUrl = data.imageUrl ?? '';
        this.email = data.email ?? '';
        this.parentId = data.parentId ?? '';
        this.role = data.role!;
        this.guest = typeof data.guest === 'boolean' ? data.guest : false;
        this.allowSessionResponseDragHints
            = typeof data.allowSessionResponseDragHints === 'boolean' ? data.allowSessionResponseDragHints : true;
        this.allowSessionCommentDragHints
            = typeof data.allowSessionCommentDragHints === 'boolean' ? data.allowSessionCommentDragHints : true;
        this.disabledFocusNotifications
            = typeof data.disabledFocusNotifications === 'boolean' ? data.disabledFocusNotifications : false;
        this.isUpdateActivityDialogDisabled
            = typeof data.isUpdateActivityDialogDisabled === 'boolean' ? data.isUpdateActivityDialogDisabled : false;
        this.attributes = data.attributes || {};
        this.isOnline = data.isOnline || false;
        this.lastLogin = data.lastLogin ?? null;
        this.status = data.status ?? UserStatus.Inactive;
        this.type = data.type ?? UserType.Guest;
        this.deleted = data.deleted || undefined;
    }

    get isSessionLeader(): boolean {
        return this.role === SessionRole.Leader;
    }

    get isSessionParticipant(): boolean {
        return this.role === SessionRole.Participant;
    }

    get isSessionObserver(): boolean {
        return this.role === SessionRole.Observer;
    }

    get isProjectAdmin(): boolean {
        return this.role === ProjectRole.Admin;
    }

    get isProjectMember(): boolean {
        return this.role === ProjectRole.Member;
    }

    get isOwner(): boolean {
        return this.role === TemplateRole.Owner;
    }

    get isTemplateEditor(): boolean {
        return this.role === TemplateRole.Collaborator || this.role === TemplateRole.Owner;
    }

    get isCollaborator(): boolean {
        return this.role === TemplateRole.Collaborator;
    }

    get isSessionEditor(): boolean {
        return [SessionRole.Leader, TemplateRole.Owner, TemplateRole.Collaborator].includes(
            this.role as SessionRole | TemplateRole,
        );
    }
}

export class TeamMemberTableView extends TeamMember {
    sessionLeaderRoleCount?: number;
    participantRoleCount?: number;
    notInvitedCount?: number;
}

export enum TeamMemberSnackBarsVisibilityOptions {
    ValidationModeSnackBar = 'validationModeSnackBar',
    ValidationModeSnackBarDisabled = 'validationModeSnackBarDisabled',
    ValidationStepSnackBarInfoHintsDisabled = 'validationStepSnackBarInfoHintsDisabled',
}

export interface TeamMemberSnackBarsVisibilityOption {
    id?: string;
    value: Dictionary<boolean> | boolean;
}

export interface invitedTeamMember {
    role: ProjectRole;
    email: string;
}
