import { Timestamp } from 'firebase/firestore';

import { removeEmptyKeys } from '@accenture/shared/util';

import { Dictionary } from './dictionary';
import { NodeTemplate, NodeType } from './node-template';
import { NodeTimestamp } from './node-timestamp';
import { UpdatedByUser } from './user';

// TODO: Remove this once project-related code is deprecated.

export enum ProjectSearchCriteria {
    SessionId = 'sessionId',
    ProjectId = 'projectId',
    ProjectName = 'projectName',
    SessionName = 'sessionName',
}

export const projectSearchCriteriaMap = {
    [ProjectSearchCriteria.SessionId]: 'Session id',
    [ProjectSearchCriteria.ProjectId]: 'Project id',
    [ProjectSearchCriteria.ProjectName]: 'Project name',
    [ProjectSearchCriteria.SessionName]: 'Session name',
};

export const initialProjectSearchObject = {
    searchByControl: ProjectSearchCriteria.SessionId,
};

export class Project extends NodeTemplate {
    templates: Dictionary<string>; // [templateId]: [sessionId]
    clients?: Dictionary<string>;
    tags?: Dictionary<string>;
    likes?: Dictionary<boolean>;
    userId: string;
    creatorId: string;
    creatorName: string;
    creatorImage: string;
    hidden?: boolean; // default: true for projects created for Teams meetings
    metadata?: Dictionary<string>;
    favoritesCount?: number;
    sessionIds: string[];
    sessionNames: Dictionary<string>;

    // for public project templates
    timesUsed?: number;
    creatorNotes?: string;
    featured?: boolean;
    approved?: boolean;
    updatedBy?: UpdatedByUser;

    // administration
    disableChangeOwnership?: boolean;
    disableChangeOwnershipBy?: string;

    constructor(id: string, data: Partial<Project>) {
        super(id, data, NodeType.Project);
        this.templates = (data.templates as Dictionary<string>) ?? null;
        this.clients = (data.clients as Dictionary<string>) ?? {};
        this.tags = (data.tags as Dictionary<string>) ?? {};
        this.likes = data.likes ?? {};
        this.creatorId = data.creatorId ?? '';
        this.creatorName = data.creatorName ?? '';
        this.creatorImage = data.creatorImage ?? '';
        this.hidden = !!data.hidden;
        this.metadata = data.metadata ?? {};
        this.timesUsed = data.timesUsed ?? 0;
        this.favoritesCount = data.favoritesCount ?? 0;
        this.creatorNotes = data.creatorNotes ?? '';
        this.updatedBy = data.updatedBy ?? undefined;
        this.featured = data.featured;
        this.approved = data.approved;
        this.disableChangeOwnership = !!data.disableChangeOwnership;
        this.disableChangeOwnershipBy = data.disableChangeOwnershipBy ?? '';
        this.sessionIds = data.sessionIds ?? [];
        this.sessionNames = (data?.sessionNames as Dictionary<string>) ?? {};
    }

    createSerializableObject(): Record<string, unknown> {
        return removeEmptyKeys({
            name: this.name,
            description: this.description,
            imageUrl: this.imageUrl,
            imageId: this.imageId,
            templates: this.templates,
            clients: this.clients,
            tags: this.tags,
            likes: this.likes,
            creatorId: this.creatorId,
            creatorName: this.creatorName,
            creatorImage: this.creatorImage,
            hidden: this.hidden,
            metadata: this.metadata,
            created: this.created,
            updated: this.updated,
            timesUsed: this.timesUsed,
            favoritesCount: this.favoritesCount,
            creatorNotes: this.creatorNotes,
            featured: this.featured,
            approved: this.approved,
            sessionIds: this.sessionIds,
            sessionNames: this.sessionNames,
        });
    }
}
export class ProjectSummary extends NodeTimestamp {
    projectId: string;
    name: string;
    sessionId: string;
    clients?: Dictionary<string>;
    tags?: Dictionary<string>;
    sessionNames?: Dictionary<string>;
    created: Timestamp;
    updated: Timestamp;
    sessionIds?: string[];
    showMore = false;
    isOverflow = false;
    id: string;

    constructor(id: string, data: Partial<ProjectSummary>) {
        super(data);

        this.projectId = id;
        this.name = data.name;
        this.sessionId = data.sessionId;
        this.clients = (data.clients as Dictionary<string>) ?? {};
        this.tags = (data.tags as Dictionary<string>) ?? {};
        this.sessionNames = (data.sessionNames as Dictionary<string>) ?? {};
        this.created = data.created ?? null;
        this.updated = data.updated ?? null;
        this.sessionIds = data.sessionIds ?? [];
        this.showMore = data.showMore ?? false;
        this.isOverflow = data.isOverflow ?? false;
    }
}
