import { ActivityItem } from './activity-item';
import { ActivityItemType } from './activity-item-type';
import { Prompt } from './prompt';

export class Slate extends Prompt implements ActivityItem {
    type = ActivityItemType.Slate;

    constructor(data: Partial<Slate>) {
        super(data);
        this.id = data.id ?? '';
        this.title = data.title ?? '';
        this.sequence = data.sequence ?? '';
        this.sessionId = data.sessionId ?? '';
        this.activityId = data.activityId ?? '';
        this.default = data.default ?? '';
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            id: this.id,
            title: this.title,
            sequence: this.sequence,
            type: this.type,
            sessionId: this.sessionId,
            activityId: this.activityId,
            default: this.default,
            created: this.created,
            updated: this.updated,
        };
    }
}
