import { Dictionary } from '../dictionary';
import { NodeTemplate } from '../node-template';
import { UpdatedByUser } from '../user';
import { ActivityType } from './activity-type';

export interface ActivityCountdown {
    duration: number;
    label: string;
}

export interface Activity extends NodeTemplate {
    type: ActivityType;
    // TODO: DELETE
    projectId?: string;
    templateId?: string;
    sessionId?: string;
    sectionId?: string;
    likes?: Dictionary<boolean>;
    visible?: boolean;
    sequence: string;
    useAttributes?: boolean;
    selectedAttributes?: Dictionary<boolean>;
    displayNotes?: boolean;
    displaySlides?: boolean;
    allowDownloads?: boolean;
    enableResponses?: boolean;
    creatorId?: string;
    creatorName?: string;
    creatorImage?: string;
    favoritesCount?: number;
    tags?: Dictionary<string>;
    facilitationNotes?: string;
    votedUsers?: Dictionary<boolean>;
    countdown?: ActivityCountdown;
    countdownActive?: boolean;
    countdownLock?: boolean;

    // for public activity templates
    timesUsed?: number;
    creatorNotes?: string;
    featured?: boolean;
    approved?: boolean;
    dndDisabled?: boolean;
    updatedBy?: UpdatedByUser;

    createSerializableObject(): Record<string, unknown>;
}
