import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ExecuteActionEvent } from '@progress/kendo-angular-conversational-ui';
import { TextAreaComponent } from '@progress/kendo-angular-inputs';
import { marked } from 'marked';
import { NgxMicRecorderService } from 'ngx-mic-recorder';
import { firstValueFrom } from 'rxjs';

import { BotName, ChatUser } from '@accenture/erp-deployment/shared/domain';
import {
    ActivityType,
    BotActions,
    BotChatExchangeTypes,
    BotMessage,
    inputPlaceholders,
    tooltipTexts,
} from '@accenture/shared/data';
import { KeyCode } from '@accenture/shared/ui';
import { trackById } from '@accenture/shared/util';

import { BotChatFacade } from './bot-chat-facade';
import { startRecording, stopRecording } from './constants';

const activityTypesToGenerate = {
    [ActivityType.Vote]: 'Vote',
    [ActivityType.Table]: 'Table',
    [ActivityType.QuickPoll]: 'Quick Poll',
    [ActivityType.Brainstorm]: 'Brainstorm',
    [ActivityType.Present]: 'Presentation',
};

const GenerationPrompt = {
    [BotActions.GenerateProject]: () => 'use the following information to generate a project outline:',
    [BotActions.GenerateSession]: () => 'use the following information to generate a session outline:',
    [BotActions.GenerateActivity]: (activityType: string) =>
        `use the following information to generate a ${activityTypesToGenerate[activityType]} Activity:`,
    [BotActions.DataFlow]: () => 'use the following information to generate a list of items:',
    [BotActions.ThreadPost]: () =>
        'no prose. rephrase the following statement as an informative message to be sent to the session members:',
};

@Component({
    selector: 'accenture-bot-chat',
    templateUrl: './bot-chat.component.html',
    styleUrls: ['./bot-chat.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [BotChatFacade],
})
export class BotChatComponent {
    vm$ = this.facade.vm$;
    exchangeTypes = BotChatExchangeTypes;
    botActions = BotActions;
    activityType = ActivityType;
    tooltipTexts = tooltipTexts;
    promptMessage = inputPlaceholders.aiConciergeMessage;

    trackById = trackById;

    readonly chatUser = ChatUser;
    readonly botName = BotName;

    @ViewChild('messageEntry', { static: false })
    public messageEntry: TextAreaComponent;
    public focused = false;

    recorder!: NgxMicRecorderService;
    speaking = false;

    constructor(private facade: BotChatFacade, private sanitizer: DomSanitizer, private cdr: ChangeDetectorRef) {}

    renderMarkdown(text: string): string {
        return this.sanitizer.bypassSecurityTrustHtml(marked(text)) as string;
    }

    async inlineAction(event: ExecuteActionEvent): Promise<void> {
        this.messageEntry.disabled = true;
        const { type, value } = event.action;
        await this.facade.process(type, null, value);
        this.messageEntry.disabled = false;
        this.focusEntry();
    }

    async runCommand(action: string, messageId?: string): Promise<void> {
        this.messageEntry.disabled = true;
        await this.facade.process(action, null, messageId);
        this.messageEntry.disabled = false;
        this.focusEntry();
    }

    async runPrompt(action: string, text: string, activityType?: string): Promise<void> {
        const prompt = `
            ${GenerationPrompt[action]?.(activityType)}
            ${text}
        `;

        this.messageEntry.disabled = true;
        await this.facade.process(action, prompt);
        this.messageEntry.disabled = false;
        this.focusEntry();
    }

    async deleteMessage(messageId: string): Promise<void> {
        await this.facade.deleteMessage(messageId);
    }

    clipboardCopy(text: string): Promise<void> {
        return navigator.clipboard.writeText(text);
    }

    useAsPrompt(text: string): void {
        this.messageEntry.value = text;
    }

    openPromptLibrary(): void {
        this.facade.getPrompt((text: string) => {
            this.messageEntry.value = text;
            this.focusEntry();
        });
    }

    async clearChat(): Promise<void> {
        await this.facade.process(BotActions.Zap);
        this.focusEntry();
    }

    clearValue(): void {
        if (!this.focused) {
            this.messageEntry.value = null;
            this.focusEntry();
        }
    }

    async sendMessage(message?: string): Promise<void> {
        const text = message || this.messageEntry.value;

        if (!text) {
            return;
        }

        this.messageEntry.disabled = true;
        await this.facade.sendMessage(text);
        this.messageEntry.value = null;
        await this.facade.process(BotActions.Message, text);
        this.messageEntry.disabled = false;

        this.focusEntry();
    }

    onKeyDown(event: KeyboardEvent): void {
        if (event.keyCode === KeyCode.ENTER) {
            this.sendMessage();
        }
        event.stopPropagation();
    }

    public onFocus(): void {
        this.focused = true;
    }

    public onBlur(): void {
        this.focused = false;
    }

    focusEntry(): void {
        this.cdr.detectChanges();
        this.messageEntry.focus();
        this.onFocus();
    }

    async uploadFile(event: Event): Promise<void> {
        const target = event.target as HTMLInputElement;
        const file = target.files[0];

        const reader = new FileReader();

        reader.readAsArrayBuffer(file);

        reader.onloadend = data => {
            this.facade.uploadFile(file.name, file.type, data.target.result);
        };

        target.value = '';
    }

    isRecording(): boolean {
        return !!this.recorder;
    }

    async record(): Promise<void> {
        if (this.recorder) {
            const recording = await firstValueFrom(this.recorder.recordedBlobAsMp3$);
            this.recorder.stopRecording();
            delete this.recorder;
            const stop = new Audio(stopRecording);
            await stop.play();
            const feedback = await this.facade.uploadRecording(recording);
            this.speak(feedback);
        } else {
            const start = new Audio(startRecording);
            await start.play();
            this.recorder = new NgxMicRecorderService();
            this.recorder.startRecording();
        }
        this.cdr.detectChanges();
    }

    speak(feedback: string): void {
        const htmlString = marked(feedback);
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const walker = document.createTreeWalker(doc, NodeFilter.SHOW_TEXT);

        const textList = [];
        let currentNode = walker.currentNode;

        while (currentNode) {
            textList.push(currentNode.textContent);
            currentNode = walker.nextNode();
        }

        const statement = textList.join(' ');

        const utterance = new SpeechSynthesisUtterance(statement);
        utterance.onend = () => {
            this.speaking = false;
        };

        this.speaking = true;
        speechSynthesis.speak(utterance);
    }

    quieten(): void {
        speechSynthesis.cancel();
        this.speaking = false;
    }

    editMessage(message: BotMessage): void {
        this.facade.editMessage(message);
    }
}
