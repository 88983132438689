<ng-container>
  <div
    class="tt9-navigation-card-template template-cards"
    [ngClass]="{
      hover: hoveredCardIndex === templateIndex,
      'fixed-card': !isTemplatesPage,
      'card-creator-details': isPublic(template.templateType)
    }"
  >
    <div class="tt9-navigation-card-template-image card-image">
      <div
        *ngIf="template.imageUrl"
        class="tt9-navigation-card-template-image-filtered"
        [class.inactive]="isDraft(template, userId)"
        [style.background-image]="getImageUrl(template.imageUrl)"
      ></div>

      <img
        *ngIf="!template.imageUrl"
        class="tt9-navigation-card-image-empty"
        alt="Template Image"
        [src]="getEmptyImage(template.templateType, template.activityType)"
      />
    </div>
    <div class="action-buttons" [class.hover]="hoveredCardIndex === templateIndex">
      <button
        *ngIf="isPublic(template.templateType)"
        class="tt9-btn preview-btn d-flex-center"
        (click)="openForPreview.emit({ template, event: $event })"
      >
        Preview
      </button>
    </div>

    <div class="tt9-navigation-card-content">
      <p class="tt9-body-medium-2 text-overflow-ellipsis-2 mt-4">{{ template.name }}</p>
      <div class="tt9-navigation-card-footer d-flex-between">
        <p class="activity-type">
          {{ getTemplateType(template?.activityType) }}
        </p>

        <div *ngIf="isApproved(template)" class="d-flex-center mr-8">
          <img class="accenture-approve-img" [src]="accentureApproveImgPath" />
        </div>
      </div>
    </div>
  </div>
</ng-container>
