export const publishDialogDescription
    = 'Are you sure you want to publish all the changes made in the template? You will no longer have access to the previously published template, the update template will be displayed in Template Store.';

export const publishDialogDescriptionNote
    = 'The following users are now working on the template, their changes will be published as well: ';

export const cancelEditTemplateDialogDescriptionNote
    = 'The following users are now working on the template, their changes will be cancelled as well: ';

export const publishDialogTitle = 'Publish updated template';
