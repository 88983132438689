import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { PromptLibraryService } from '@accenture/erp-deployment/shared/domain';
import { PromptType } from '@accenture/shared/data';
import { PromptLibrary } from '@accenture/shared/data';

export interface PromptLibraryDialogViewModel {
    promptLibraryItems: PromptLibrary[];
    searchValue: string;
}

const defaultViewModel = {
    promptLibraryItems: [],
    searchValue: '',
} as PromptLibraryDialogViewModel;

@Injectable()
export class PromptLibraryDialogFacade {
    private promptType$ = new BehaviorSubject<string>('');
    private searchValue$ = new BehaviorSubject<string>('');

    vm$ = this.buildViewModel();

    constructor(private promptLibraryService: PromptLibraryService) {}

    filterPromptLibrary(searchValue: string): void {
        this.searchValue$.next(searchValue);
    }

    filterPromptLibraryByType(promptType?: PromptType): void {
        this.promptType$.next(promptType || '');
    }

    private buildViewModel(): Observable<PromptLibraryDialogViewModel> {
        return combineLatest([
            this.promptLibraryService.getAllPromptLibrary(),
            this.searchValue$.asObservable().pipe(
                map((value: string) => value.trim().toLocaleLowerCase()),
                distinctUntilChanged(),
            ),
            this.promptType$.asObservable(),
        ]).pipe(
            map(([promptLibrary, searchValue, promptType]: [PromptLibrary[], string, PromptType]) => {
                const filterPromptLibraryByType = promptType
                    ? promptLibrary.filter(promptLibraryItem => promptLibraryItem.type.includes(promptType))
                    : promptLibrary;

                const filteredBySearchValue = this.filterPromptLibraryBySearchValue(
                    filterPromptLibraryByType,
                    searchValue,
                );

                return {
                    searchValue,
                    promptLibraryItems: filteredBySearchValue,
                } as PromptLibraryDialogViewModel;
            }),
            startWith(defaultViewModel),
        );
    }

    private filterPromptLibraryBySearchValue(
        filterPromptLibraryByType: PromptLibrary[],
        searchValue: string,
    ): PromptLibrary[] {
        return filterPromptLibraryByType.filter(
            promptLibraryItem =>
                promptLibraryItem.useCase.toLocaleLowerCase().includes(searchValue)
                || promptLibraryItem.description.toLocaleLowerCase().includes(searchValue)
                || promptLibraryItem.promptText.toLocaleLowerCase().includes(searchValue),
        );
    }
}
