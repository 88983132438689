import { InAppNotificationType } from './in-app-notification-type';

export enum InAppNotificationStatusFilterType {
    Read = 'READ',
    Unread = 'UNREAD',
}

export enum InAppNotificationTypeFilterType {
    Invitations = 'INVITATIONS',
    RoleChanging = 'ROLE_CHANGING',
    ActivityRelated = 'ACTIVITY_CHANGING',
    TemplateRelated = 'TEMPLATE_RELATED',
    General = 'GENERAL',
}
export const inAppNotificationTypeFilterTypeInOrder: InAppNotificationTypeFilterType[] = [
    InAppNotificationTypeFilterType.Invitations,
    InAppNotificationTypeFilterType.RoleChanging,
    InAppNotificationTypeFilterType.ActivityRelated,
    InAppNotificationTypeFilterType.General
];

export interface InAppNotificationFilters {
    [InAppNotificationStatusFilterType.Read]: boolean;
    [InAppNotificationStatusFilterType.Unread]: boolean;
    [InAppNotificationTypeFilterType.Invitations]: boolean;
    [InAppNotificationTypeFilterType.RoleChanging]: boolean;
    [InAppNotificationTypeFilterType.ActivityRelated]: boolean;
    [InAppNotificationTypeFilterType.TemplateRelated]: boolean;
    [InAppNotificationTypeFilterType.General]: boolean;
}

export const defaultInAppNotificationFilters = {
    [InAppNotificationStatusFilterType.Read]: true,
    [InAppNotificationStatusFilterType.Unread]: true,
    [InAppNotificationTypeFilterType.Invitations]: true,
    [InAppNotificationTypeFilterType.RoleChanging]: true,
    [InAppNotificationTypeFilterType.ActivityRelated]: true,
    [InAppNotificationTypeFilterType.TemplateRelated]: true,
    [InAppNotificationTypeFilterType.General]: true,
};

export const inAppNotificationsTypeToFilterTypeMap: {
    [key in InAppNotificationType]: InAppNotificationTypeFilterType;
} = {
    [InAppNotificationType.ProjectInvite]: InAppNotificationTypeFilterType.Invitations,
    [InAppNotificationType.SessionInvite]: InAppNotificationTypeFilterType.Invitations,
    [InAppNotificationType.TemplateInvite]: InAppNotificationTypeFilterType.Invitations,
    [InAppNotificationType.TemplatePublish]: InAppNotificationTypeFilterType.TemplateRelated,
    [InAppNotificationType.UpdatePublishedTemplate]: InAppNotificationTypeFilterType.TemplateRelated,
    [InAppNotificationType.SessionBulkInvite]: InAppNotificationTypeFilterType.Invitations,
    [InAppNotificationType.ProjectRoleChange]: InAppNotificationTypeFilterType.RoleChanging,
    [InAppNotificationType.SessionRoleChange]: InAppNotificationTypeFilterType.RoleChanging,
    [InAppNotificationType.ActivityItemStepChange]: InAppNotificationTypeFilterType.ActivityRelated,
    [InAppNotificationType.VirusDetectedInUpload]: InAppNotificationTypeFilterType.General,
};
