import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';

import {
    CollectionOptionsType,
    collectionSortOptionsValues,
    DefaultCollectionSortObject,
    ProjectOptionsType,
    sortOrdersValues,
} from '@accenture/shared/data';
import { trackById } from '@accenture/shared/util';

import {
    CollectionOptionsFiltersChipsFacade,
    CollectionOptionsFiltersChipsViewModel,
} from './collection-options-filters-chips-facade';

@Component({
    selector: 'accenture-collection-options-filters-chips',
    templateUrl: './collection-options-filters-chips.component.html',
    styleUrls: ['./collection-options-filters-chips.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [CollectionOptionsFiltersChipsFacade],
})
export class CollectionOptionsFiltersChipsComponent {
    vm$: Observable<CollectionOptionsFiltersChipsViewModel> = this.facade.vm$;

    sortOrderOptions = sortOrdersValues;
    sortByOptions = collectionSortOptionsValues;

    trackById = trackById;

    constructor(private facade: CollectionOptionsFiltersChipsFacade) {}

    getOptionCount(option: CollectionOptionsType): number {
        return ((option as ProjectOptionsType).collections || []).length;
    }

    isOptionSelected(selectedOptions: string[], id: string): boolean {
        return Array.isArray(selectedOptions) ? selectedOptions.includes(id) : !!selectedOptions?.[id];
    }

    onSelectOption(selectedOptions: string[], option: CollectionOptionsType): void {
        const isSelected = this.isOptionSelected(selectedOptions, option.id);
        this.facade.applyOptionsFilter(option.id, isSelected);
    }

    updateCurrentSort(sortObject: DefaultCollectionSortObject): void {
        this.facade.updateCollectionSortOptions(sortObject);
    }
}
