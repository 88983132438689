import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Observable, tap } from 'rxjs';

import { AuthenticationTab, Session, TeamMember, tooltipTexts, User, UserStatus } from '@accenture/shared/data';
import { SharedMaterialModule } from '@accenture/shared/material';
import { IconColor, IconSize, SharedUiModule } from '@accenture/shared/ui';

import { BotChatRecorderComponent } from '../bot-chat-recorder/bot-chat-recorder.component';
import { BreadcrumbsTt9Component } from '../breadcrumbs-tt9/breadcrumbs-tt9.component';
import { InAppNotificationsComponent } from '../in-app-notifications/in-app-notifications.component';
import { accessSupportUrl, viewGuidesUrl } from './constants';
import { TopNavigationFacade } from './top-navigation-bar-facade';

@Component({
    selector: 'accenture-top-navigation-bar',
    templateUrl: './top-navigation-bar.component.html',
    styleUrls: ['./top-navigation-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [TopNavigationFacade],
    standalone: true,
    imports: [
        CommonModule,
        RouterLink,
        SharedMaterialModule,
        SharedUiModule,
        BreadcrumbsTt9Component,
        InAppNotificationsComponent,
        BotChatRecorderComponent,
    ],
})
export class TopNavigationBarComponent {
    @Input() isLogin!: boolean;
    @Output() toggleSidebarEvent = new EventEmitter<void>();

    loggedInAsGuestTooltipText = tooltipTexts.loggedInAsGuest;
    goToHomepageTooltipText = tooltipTexts.gotToHomePage;
    menuOpen!: boolean;
    helpMenuOpen!: boolean;
    botOpen!: boolean;
    notificationsPanelOpened!: boolean;

    private readonly facade: TopNavigationFacade = inject(TopNavigationFacade);
    vm$ = this.facade.vm$.pipe(
        tap((res) => {
            if (res.authError && res.authError === UserStatus.Deactivated) {
                this.facade.navigateDeactivation();
            }
        }),
    );

    SessionInfo$: Observable<{
        session: Session;
        teamMember: TeamMember;
    }> = this.facade.SessionInfo();
    isSessionMenuOpen = false;
    accessSupportUrl = accessSupportUrl;
    viewGuidesUrl = viewGuidesUrl;
    iconSmallSize = IconSize.Small;
    iconColor = IconColor;
    sendFeedbackIconColor = IconColor.Secondary;
    accessSupportIconColor = IconColor.Secondary;

    toggleSidebar() {
        this.toggleSidebarEvent.emit();
    }

    isLoginTab(tab: AuthenticationTab): boolean {
        return tab === AuthenticationTab.Login;
    }

    isSingUp(tab: AuthenticationTab): boolean {
        return tab === AuthenticationTab.SingUp;
    }

    navigateBack(): void {
        this.facade.navigateBack();
        return;
    }

    logout(): void {
        this.facade.logout();
    }

    setDashboard(): void {
        this.facade.navigateHome();
    }

    setLogin(): void {
        this.facade.navigateAuthentication(AuthenticationTab.Login);
    }

    setSingUp(): void {
        this.facade.navigateAuthentication(AuthenticationTab.SingUp);
    }

    openContactSupportDialog(): void {
        this.facade.openContactSupportDialog();
    }

    updateUser(user: Partial<User>): void {
        this.facade.updateUser(user);
    }

    markAllNotificationsAsRead(): void {
        this.facade.markAllNotificationsAsRead();
    }

    deleteAllNotifications(): void {
        this.facade.deleteAllNotifications();
    }

    openTermsOfUse(): void {
        this.facade.openTermsOfUse();
    }

    toggleHelpMenu(helpMenuAvailable: boolean): void {
        if (!helpMenuAvailable) {
            return;
        }

        this.helpMenuOpen = !this.helpMenuOpen;
    }
}
