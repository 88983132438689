import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { combineLatest, firstValueFrom, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { InvitedUsersService } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUserId, selectFeatureToggle } from '@accenture/global-store';
import { FeatureToggleName } from '@accenture/shared/data';

import { emailValidation } from './invitation-form.utils';

@Injectable()
export class EmailInvitationFormFacade {
    constructor(private readonly store: Store<AppState>, private readonly invitedUsersService: InvitedUsersService) {}

    async parseEmails(
        control: FormControl<string>,
        usedEmails: string[],
        blackListEmails: string[],
        isNonAccentureAvailableRole: boolean,
    ): Promise<ReturnType<typeof emailValidation>> {
        const isNonAccentureUsersEnabled
            = (await firstValueFrom(this.store.select(selectFeatureToggle(FeatureToggleName.ShowRegisterLink))))
            && isNonAccentureAvailableRole;
        return emailValidation(control, blackListEmails, usedEmails, isNonAccentureUsersEnabled);
    }

    getEmailsAutoComplete(inputValue$: Observable<string>): Observable<string[]> {
        return combineLatest({
            emailsValue: inputValue$,
            invitedUsersEmails: this.getInvitedUsersEmails(),
        }).pipe(
            map(({ emailsValue, invitedUsersEmails }) => {
                const searchValue = emailsValue.trim().toLowerCase() || '';

                if (!searchValue) {
                    return [];
                }

                return invitedUsersEmails.filter((email: string) => {
                    const userEmail = email.trim().toLowerCase();

                    return userEmail.includes(searchValue) && userEmail !== searchValue;
                });
            }),
        );
    }

    private getInvitedUsersEmails(): Observable<string[]> {
        return this.store
            .select(selectAuthenticatedUserId)
            .pipe(switchMap(userId => this.invitedUsersService.getInvitedUsersEmails(userId)));
    }
}
