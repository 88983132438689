import { Timestamp } from 'firebase/firestore';

import { ActivityItemType, TableColumnType } from '../activity-items';
import { BaseResponse } from './response';

export class TableResponse extends BaseResponse {
    type: TableColumnType;
    activityItemId: string;
    rowId?: string;
    columnId?: string;
    current?: boolean;

    constructor(data: Partial<TableResponse>) {
        super(data);
        this.type = data?.type;
        this.activityItemId = data?.activityItemId;
        this.rowId = data?.rowId;
        this.columnId = data?.columnId;
        this.current = data?.current;
        this.value = this.valueToInstance();
    }

    valueToInstance() {
        switch (this.type) {
            case ActivityItemType.Number:
                return this.value as number;
            case ActivityItemType.Date:
                return this.value instanceof Timestamp ? this.value.toDate() : this.value;
            case ActivityItemType.SingleSelect:
                return this.value as string;
            case ActivityItemType.MultiSelect:
                return this.value as string[];
            default:
                return this.value as string;
        }
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            ...super.createSerializableObject(),
            type: this.type,
            activityItemId: this.activityItemId,
            rowId: this.rowId,
            columnId: this.columnId,
            current: this.current,
            value: this.value,
        };
    }
}
