<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading">
    <div *ngIf="!vm.currentOptionToDisplay" [formGroup]="optionsFormGroup">
      <ng-container
        *ngTemplateOutlet="
          collectionOptionTemplate;
          context: {
            collectionOption: collectionOptions.Tag
          }
        "
      ></ng-container>
    </div>

    <accenture-options-filters-chips
      *ngIf="vm.currentOptionToDisplay"
      [sortCollectionsData]="vm.sortCollectionsData"
      [collectionToCount]="collectionToCount.Collections"
      [optionsData]="getOptionsData(vm)"
      [selectedOptions]="vm.selectedCollectionOptions[vm.currentOptionToDisplay]"
      (onSelectedOptionsChanged)="updateSelectedOptions($event)"
      (onSortValuesChanged)="updateFilters($event)"
    />
  </ng-container>

  <!-- Collection option template -->
  <ng-template #collectionOptionTemplate let-collectionOption="collectionOption">
    <div>
      <!-- collection option -->
      <mat-form-field ngDefaultControl appearance="outline" class="tt9-form-field no-margin width-100">
        <mat-label>{{ collectionOptionsTitle[collectionOption] }}</mat-label>
        <input
          #optionsInput
          matInput
          type="text"
          aria-label="{{ collectionOptionsTitle[collectionOption] }}"
          [formControl]="getControl(collectionOption)"
          [placeholder]="optionPlaceholderText[collectionOption]"
          [matAutocomplete]="optionAutocomplete"
          [attr.maxLength]="characterLimitMedium"
        />
        <mat-hint
          class="mat-hint-limit"
          [class]="determineHintClass(optionsInput.value.length, characterLimitMedium)"
          >{{ characterLimitText(optionsInput.value.length, characterLimitMedium) }}</mat-hint
        >
        <accenture-icon matSuffix (click)="showOptionChips(collectionOption, $event)">
          icon-label-outline
        </accenture-icon>

        <mat-autocomplete #optionAutocomplete="matAutocomplete" [displayWith]="displayEmptyOption">
          <ng-container *ngIf="optionsInput.value.trim()">
            <mat-option
              *ngIf="isNewOptionShown(collectionOption, vm.selectedCollectionOptions, optionsInput.value)"
              (click)="addNewOption(collectionOption)"
            >
              {{ optionsInput.value }} {{ newOptionText[collectionOption] }}
            </mat-option>

            <mat-option
              *ngFor="let filteredOption of filteredOptions; trackBy: trackById"
              [value]="filteredOption.name"
              (click)="updateOptionValue(filteredOption, collectionOption)"
            >
              {{ filteredOption.name }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
      <!-- /collection option -->

      <!-- collection option chips list -->
      <div *ngIf="hasSelectedOptions(vm.selectedCollectionOptions[collectionOption])" class="d-flex mt-24">
        {{ vm.selectedCollectionOptions[collectionOption]?.value }}
        <div class="width-100">
          <mat-chip-listbox hideSingleSelectionIndicator class="tt9-chip">
            <ng-container *ngIf="hasSelectedOptions(vm.selectedCollectionOptions[collectionOption])">
              <mat-chip-option
                *ngFor="
                  let option of vm.selectedCollectionOptions[collectionOption] | keyvalue : alphabeticalKeyValueOrder;
                  trackBy: trackById
                "
                (removed)="removeOptionValue(collectionOption, option)"
              >
                <span
                  matTooltipPosition="above"
                  matTooltipClass="tt9-arrow-tooltip-below-large"
                  [matTooltip]="option.value"
                  >{{ option.value | uppercase }}</span
                >
                <accenture-icon matChipRemove>icon-close</accenture-icon>
              </mat-chip-option>
            </ng-container>
          </mat-chip-listbox>
        </div>
      </div>
      <!-- /collection option chips list -->
    </div>
  </ng-template>
</ng-container>
