import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

import { ActivityService } from '@accenture/activity/shared/domain';
import { ProjectService } from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    isTeamPage,
    selectActivityIdAndParentIds,
    selectAdminRoute,
    selectIsAdministrationProject,
    selectProject,
    selectSession,
    selectUserId,
} from '@accenture/global-store';
import { Activity, ActivityType, ParentType, Project, Session, User } from '@accenture/shared/data';
import { UserService } from '@accenture/shared/data-access';

export interface BreadcrumbsModel {
    isLoading: boolean;
    project?: Project;
    session?: Session;
    activity?: Activity;
    user?: User;

    parentType?: ParentType;

    isProject: boolean;
    isProjectTemplate: boolean;
    isSessionTemplate: boolean;
    isActivityTemplate: boolean;
    isAdminUser: boolean;
    userProject?: Project;
    isTeamPage?: boolean;
}

const defaultModel = {
    isLoading: false,
    project: undefined,
    session: undefined,
    activity: undefined,
    user: undefined,

    parentType: undefined,

    isProject: false,
    isProjectTemplate: false,
    isSessionTemplate: false,
    isActivityTemplate: false,
    isAdminUser: false,
    userProject: undefined,
    isTeamPage: false,
};

@Injectable()
export class BreadcrumbsFacade {
    private parentType!: ParentType;
    private parentId!: string;
    private activityId!: string;
    private activityType!: ActivityType;

    vm$ = this.buildViewModel();

    constructor(
        private store: Store<AppState>,
        private activityService: ActivityService,
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        private projectService: ProjectService,
    ) {}

    navigateBack(): void {
        this.router.navigate(['../..'], { relativeTo: this.route });
    }

    navigateToActivityView(): void {
        this.router.navigate(['../..', this.activityType.toLowerCase(), this.activityId], { relativeTo: this.route });
    }

    private buildViewModel(): Observable<BreadcrumbsModel> {
        return combineLatest([
            this.store.select(selectActivityIdAndParentIds),
            this.store.select(selectUserId),
            this.store.select(selectAdminRoute),
            this.store.select(isTeamPage),
            this.store.select(selectIsAdministrationProject),
        ]).pipe(
            switchMap(
                ([
                    { parentType, parentId, projectId, activityId },
                    userId,
                    isAdministration,
                    isTeamPage,
                    isAdministrationProject,
                ]) => {
                    this.parentType = parentType;
                    this.parentId = parentId;
                    this.activityId = activityId;

                    return combineLatest([
                        this.getProject(),
                        this.getSession(),
                        this.getActivity(activityId),
                        this.getUser(userId),
                        this.getProjectForAdmin(projectId, isAdministration, isAdministrationProject),
                    ]).pipe(
                        map(([project, session, activity, user, userProject]) => {
                            return {
                                project,
                                session,
                                activity,
                                user,
                                userProject,
                                isTeamPage,
                                isAdministrationProject,
                                projectId,
                                parentType: this.parentType,
                                isProject: this.parentType === ParentType.Projects,
                                isSessionTemplate: [ParentType.Templates, ParentType.PublicSessionTemplates].includes(
                                    this.parentType,
                                ),
                                isActivityTemplate: [
                                    ParentType.ActivityTemplates,
                                    ParentType.PublicActivityTemplates,
                                ].includes(this.parentType),
                                isProjectTemplate: [
                                    ParentType.ProjectTemplates,
                                    ParentType.PublicProjectTemplates,
                                ].includes(this.parentType),
                                isAdminUser: isAdministration,
                                isLoading: false,
                            };
                        }),
                    );
                },
            ),
            startWith(defaultModel),
        );
    }

    private getProject(): Observable<Project | undefined> {
        return this.store.select(selectProject);
    }

    private getSession(): Observable<Session | undefined> {
        return this.store.select(selectSession);
    }

    private getActivity(activityId: string): Observable<Activity | undefined> {
        if (activityId) {
            return this.activityService.getActivity(this.parentType, this.parentId, activityId);
        }

        return of(undefined);
    }

    private getUser(userId: string): Observable<User | undefined> {
        if (userId) {
            return this.userService.getUser(userId);
        }
        return of(undefined);
    }

    private getProjectForAdmin(
        projectId: string,
        isAdministration: boolean,
        isAdministrationProject: boolean,
    ): Observable<Project | undefined> {
        if ((isAdministration || isAdministrationProject) && projectId) {
            return this.projectService.getProjectById(projectId);
        }
        return of(undefined);
    }
}
