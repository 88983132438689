import { Timestamp } from 'firebase/firestore';

import { NodeTimestamp } from './node-timestamp';

export enum NodeType {
    Project = 'project',
    Session = 'session',
    Activity = 'activity',
    Collection = 'collection',
}

export class NodeTemplate extends NodeTimestamp {
    id?: string;
    name: string;
    description: string;
    imageUrl?: string;
    imageId?: string;

    draft?: boolean;
    nodeType?: NodeType;
    markedForDelete?: Timestamp;
    deletedByUserId?: string;
    teamMembersIds?: string[]; // collaborators

    constructor(id: string | undefined, data: any, nodeType: NodeType) {
        super(data);

        this.nodeType = nodeType;

        this.id = id ?? '';
        this.name = data.name ?? '';
        this.description = data.description ?? '';
        this.imageUrl = data.imageUrl ?? '';
        this.imageId = data.imageId ?? '';

        this.draft = !!data.draft;
        this.markedForDelete = data.markedForDelete || null;
        this.teamMembersIds = data.teamMembersIds || null; // collaborators
    }
}
