import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
    selector: 'accenture-template-editor-welcome-snackbar',
    templateUrl: './template-editor-welcome-snackbar.component.html',
    styleUrls: ['./template-editor-welcome-snackbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateEditorWelcomeSnackbarComponent {
    constructor(
        public snackBarRef: MatSnackBarRef<TemplateEditorWelcomeSnackbarComponent>,
        @Inject(MAT_SNACK_BAR_DATA)
        public data: {
            iconClass: string;
            iconName: string;
            actionButtonClass: string;
            showClose?: boolean;
            title?: string;
            isVisibleDoNotShowAgain?: boolean;
            onClickDoNotShowAgain?: (value: boolean) => void;
        },
    ) {}

    onDoNotShowAgain(value: boolean): void {
        this.data.onClickDoNotShowAgain?.(value);
    }

    onClose(): void {
        this.snackBarRef.dismiss();
    }
}
