import { NodeTemplate, NodeType } from '../node-template';
import { Activity, ActivityType } from './';

export class Spark extends NodeTemplate implements Activity {
    type = ActivityType.Spark;

    sessionId?: string;
    projectId?: string;
    templateId?: string;
    visible?: boolean;
    sequence: string;

    useDefaultPrompt?: boolean;
    usePreviousActivityResults?: boolean;

    constructor(id: string | undefined, spark: Partial<Spark>) {
        super(id, spark, NodeType.Activity);

        this.sessionId = spark.sessionId ?? '';
        this.projectId = spark.projectId ?? '';
        this.templateId = spark.templateId ?? '';
        this.visible = spark.visible ?? false;
        this.sequence = spark.sequence ?? '';
        this.useDefaultPrompt = spark.useDefaultPrompt ?? false;
        this.usePreviousActivityResults = spark.usePreviousActivityResults ?? false;
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            projectId: this.projectId,
            sessionId: this.sessionId,
            templateId: this.templateId,
            visible: this.visible,
            sequence: this.sequence,
            useDefaultPrompt: this.useDefaultPrompt,
            usePreviousActivityresults: this.usePreviousActivityResults,
            type: this.type,
            created: this.created,
            updated: this.updated,
        };
    }
}
