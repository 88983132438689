import { Injectable } from '@angular/core';

import { ParentType } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable()
export class PresentDownloadService {
    constructor(private firestoreService: FirestoreService) {}

    async downloadPresentation(
        parentType: ParentType,
        parentId: string,
        activityId: string,
        filesUrls: string[],
        name: string,
    ): Promise<string> {
        const id = (await this.firestoreService.cloudFunctionCallable('downloadPresentation', {
            parentType,
            parentId,
            activityId,
            filesUrls,
            name,
        })) as string;

        return id;
    }
}
