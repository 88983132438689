import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef,
} from '@angular/material/dialog';

import { InvitationObject } from '@accenture/erp-deployment/shared/domain';
import { ProjectRole } from '@accenture/shared/data';
import { trackByValue } from '@accenture/shared/util';

import { MembersInvitationsDialogFacade } from './members-invitations-dialog-facade';

@Component({
    selector: 'accenture-members-invitations-dialog',
    templateUrl: './members-invitations-dialog.component.html',
    styleUrls: ['./members-invitations-dialog.component.scss'],
    providers: [MembersInvitationsDialogFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MembersInvitationsDialogComponent {
    projectRole = ProjectRole;
    trackByValue = trackByValue;

    vm$ = this.facade.vm$;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { title: string },
        public dialogRef: MatDialogRef<MembersInvitationsDialogComponent>,
        private facade: MembersInvitationsDialogFacade,
    ) {}

    setInvitationObject(invitationObject: InvitationObject): void {
        this.facade.setInvitationObject(invitationObject);
    }

    invite(): void {
        this.facade.invite();
    }
}
