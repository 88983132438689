import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { groupBy, mapValues } from 'lodash';
import { BehaviorSubject, combineLatest, map, Observable, startWith, tap } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

import {
    ActivityOptionsService,
    FiltersService,
    ProjectOptionsService,
    ProjectService,
    SessionOptionsService,
    SessionService,
    TemplateService,
} from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    selectActivityConfigurationsMap,
    selectActivityConfigurationsVisibleTypesList,
    selectAuthenticatedUserId,
    selectRouterQueryParams,
} from '@accenture/global-store';
import {
    ActivityConfigurationsMap,
    ActivityOptions,
    ActivityOptionsType,
    ActivityType,
    Client,
    DefaultFilterObject,
    Dictionary,
    initialDefaultFilterObject,
    initialTemplatesFilterObject,
    NavigationTab,
    ParentType,
    Phase,
    ProjectOptions,
    ProjectOptionsType,
    SessionOptions,
    SessionOptionsType,
    sortOptionsValues,
    SubPhase,
    Tag,
    templatesSortOptionsValues,
    TemplateTab,
    templateTabValue,
} from '@accenture/shared/data';
import { sortAlphabeticByCase } from '@accenture/shared/util';

export interface DefaultFiltersAndSortViewModel {
    filtersAndSort: DefaultFilterObject;

    filteredTags: Tag[];
    tagsMap: Dictionary<string>;

    filteredClients: Client[];
    clientsMap: Dictionary<string>;

    filteredPhases: Phase[];
    filteredSubPhases: SubPhase[];
    phasesMap: Dictionary<string>;
    subPhasesMap: Dictionary<string>;

    activityTypesList: ActivityType[];
    activityConfigurationsMap: ActivityConfigurationsMap;
    parentType: ParentType | string;
    navigationTab: NavigationTab;
    sortOptionsValues: Dictionary<string>;
    isAllCollectionsPanelOpened: boolean;
    isLoading: boolean;
}

export const defaultViewModel = {
    filtersAndSort: initialDefaultFilterObject,

    filteredTags: [],
    tagsMap: {},

    filteredPhases: [],
    filteredSubPhases: [],
    phasesMap: {},
    subPhasesMap: {},

    filteredClients: [],
    clientsMap: {},

    activityTypesList: [],
    activityConfigurationsMap: {},
    navigationTab: NavigationTab.Dashboard,
    parentType: ParentType.Projects,
    sortOptionsValues: sortOptionsValues,
    isAllCollectionsPanelOpened: false,
    isLoading: true,
};

@Injectable()
export class DefaultFiltersAndSortFacade {
    autocompleteValues$ = new BehaviorSubject<Dictionary<string>>({});
    isAllCollectionsPanelOpened$ = new BehaviorSubject<boolean>(false);

    vm$ = this.buildViewModel();

    private userId: string;
    private parentType: ParentType | string;

    constructor(
        private store: Store<AppState>,
        private templateService: TemplateService,
        private projectService: ProjectService,
        private sessionService: SessionService,
        private filtersService: FiltersService,
        private projectOptionsService: ProjectOptionsService,
        private sessionOptionsService: SessionOptionsService,
        private activityOptionsService: ActivityOptionsService,
    ) {}

    applyFiltersAndSort(filters: DefaultFilterObject): void {
        if (this.parentType === ParentType.Projects) {
            this.projectService.updateProjectsFilters(this.userId, filters);
            return;
        }

        this.templateService.updateTemplatesFilters(this.userId, { [this.parentType]: filters });
    }

    setOptionType(optionType: ProjectOptions | SessionOptions | ActivityOptions): void {
        this.filtersService.optionType.next(optionType);
        this.isAllCollectionsPanelOpened$.next(true);
    }

    toggleOptionsPanel(opened: boolean): void {
        this.isAllCollectionsPanelOpened$.next(opened);
    }

    private buildViewModel(): Observable<DefaultFiltersAndSortViewModel> {
        return combineLatest([
            this.store.select(selectAuthenticatedUserId),
            this.store.select(selectRouterQueryParams),
            this.store.select(selectActivityConfigurationsVisibleTypesList),
            this.store.select(selectActivityConfigurationsMap),
            this.filtersService.myTemplatesViewTab$.pipe(distinctUntilChanged()),
        ]).pipe(
            switchMap(([userId, routerQuery, activityTypesList, activityConfigurationsMap, templateTab]) => {
                this.userId = userId;
                this.parentType = this.getParentType(routerQuery.tab, templateTab);

                const filters$
                    = routerQuery.tab === NavigationTab.Templates
                        ? this.getAllTemplatesFilters(this.parentType)
                        : this.getProjectsFilters();
                return combineLatest([
                    filters$.pipe(
                        map(filtersAndSort => ({
                            ...this.getInitialDefaultFilterObject(routerQuery),
                            ...filtersAndSort,
                        })),
                    ),
                    this.getTags(),
                    this.sessionOptionsService.getOptions(SessionOptions.Phase),
                    this.sessionOptionsService.getOptions(SessionOptions.SubPhase),
                    this.projectOptionsService.getOptions(ProjectOptions.Client),
                    this.autocompleteValues$.asObservable().pipe(distinctUntilChanged()),
                    this.isAllCollectionsPanelOpened$.asObservable().pipe(distinctUntilChanged()),
                ]).pipe(
                    map(
                        ([
                            filtersAndSort,
                            tags,
                            phases,
                            subPhases,
                            clients,
                            autocompleteValues,
                            isAllCollectionsPanelOpened,
                        ]) => {
                            return {
                                filtersAndSort,
                                activityConfigurationsMap,
                                isAllCollectionsPanelOpened,
                                activityTypesList,
                                filteredClients: this.filterSelectedData(
                                    filtersAndSort.clients,
                                    clients as Client[],
                                    autocompleteValues.client,
                                ),
                                filteredPhases: this.filterSelectedData(
                                    filtersAndSort.phases,
                                    phases as Phase[],
                                    autocompleteValues.phase,
                                ),
                                filteredSubPhases: this.filterSelectedData(
                                    filtersAndSort.subPhases,
                                    subPhases as SubPhase[],
                                    autocompleteValues.subPhase,
                                ),
                                filteredTags: this.filterSelectedData(
                                    filtersAndSort.tags,
                                    tags as Tag[],
                                    autocompleteValues.tag,
                                ),
                                tagsMap: mapValues(groupBy(tags as Tag[], 'id'), tags => tags[0].name),
                                clientsMap: mapValues(groupBy(clients, 'id'), clients => clients[0].name),
                                phasesMap: mapValues(groupBy(phases, 'id'), phases => phases[0].name),
                                subPhasesMap: mapValues(groupBy(subPhases, 'id'), subPhases => subPhases[0].name),
                                sortOptionsValues:
                                    routerQuery.tab === NavigationTab.Templates
                                        ? templatesSortOptionsValues
                                        : sortOptionsValues,
                                navigationTab: routerQuery.tab,
                                parentType: this.parentType,
                                isLoading: false,
                            };
                        },
                    ),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    private getTags(): Observable<(ProjectOptionsType | SessionOptionsType | ActivityOptionsType)[]> {
        switch (this.parentType) {
            case ParentType.ProjectTemplates:
            case ParentType.Projects:
                return this.projectOptionsService.getOptions(ProjectOptions.Tag);
            case ParentType.Templates:
                return this.sessionOptionsService.getOptions(SessionOptions.Tag);
            default:
                return this.activityOptionsService.getOptions(ParentType.Activities);
        }
    }

    private getAllTemplatesFilters(parentType: ParentType | string): Observable<DefaultFilterObject> {
        return this.store.select(selectAuthenticatedUserId).pipe(
            tap(userId => (this.userId = userId)),
            switchMap(userId =>
                this.templateService
                    .getCurrentUserAllTemplatesFilters(userId)
                    .pipe(map(allFilters => (allFilters ? allFilters[parentType] : {}))),
            ),
        );
    }

    private getProjectsFilters(): Observable<DefaultFilterObject> {
        return this.store.select(selectAuthenticatedUserId).pipe(
            tap(userId => (this.userId = userId)),
            switchMap(userId => this.projectService.getCurrentUserProjectsFilters(userId)),
        );
    }

    private getParentType(routeTab: NavigationTab, templateTab?: TemplateTab): ParentType | string {
        switch (routeTab) {
            case NavigationTab.Projects:
                return ParentType.Projects;
            case NavigationTab.Templates:
                return templateTabValue[templateTab];
        }
    }

    private filterSelectedData(
        currentData: string[],
        data: (ProjectOptionsType | SessionOptionsType)[],
        value = '',
    ): (ProjectOptionsType | SessionOptionsType)[] {
        const filteredData = data.filter(tag => {
            const tagName = tag?.name?.toLowerCase();
            return tagName?.includes(value?.toLowerCase()) && !currentData?.includes(tag.id);
        });
        return sortAlphabeticByCase(filteredData);
    }

    private getInitialDefaultFilterObject(routerQuery: Params): DefaultFilterObject {
        return routerQuery.tab === NavigationTab.Templates ? initialTemplatesFilterObject : initialDefaultFilterObject;
    }
}
