import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, distinctUntilChanged, Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';

import { fileDownload } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUserId, selectSessionId } from '@accenture/global-store';
import { SessionRecordingSummary } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import { DialogService, SnackbarService } from '@accenture/shared/ui';

export interface SessionRecorderSummarizeViewModel {
    isLoading: boolean;
}

const initialState: SessionRecorderSummarizeViewModel = {
    isLoading: true,
};

@Injectable()
export class SessionRecorderSummarizeFacade {
    private isLoading$ = new BehaviorSubject<boolean>(false);
    snackBarTitle = 'Automated Minutes of Meeting';

    vm$ = this.buildViewModel();

    sessionId!: string;
    userId!: string;

    constructor(
        private store: Store<AppState>,
        private firestoreService: FirestoreService,
        private snackbarService: SnackbarService,
        private dialogService: DialogService,
    ) {}

    async summarize(prompt: string) {
        const { sessionId } = this;
        let response;
        this.isLoading$.next(true);

        try {
            response = await this.firestoreService.cloudFunctionCallable<SessionRecordingSummary>(
                'aiMinutesOfMeeting',
                {
                    sessionId,
                    prompt,
                },
            );
        } catch (error) {
            this.snackbarService.showErrorSnackBar(this.snackBarTitle, 'Error occurred when generating MoM');
            console.error(error);
        } finally {
            this.isLoading$.next(false);
        }
        return this.format(response?.summary);
    }

    format(minutesOfTheMeeting: SessionRecordingSummary): string {
        const { agenda = [], attendees = 0, summary = [], actionItems = [], notes = [] } = minutesOfTheMeeting;

        let lines = [];
        lines.push(`**Agenda:**`);
        lines = lines.concat(agenda.map((item) => `- ${item}`));
        lines.push('\n\n');
        lines.push(`**Attendees:** ${attendees}`);
        lines.push('\n\n');
        lines.push(`**Summary:**`);
        lines = lines.concat(summary.map((item) => `- ${item}`));
        lines.push('\n\n');
        lines.push(`**Action Items:**`);
        lines = lines.concat(actionItems.map((item) => `- ${item}`));
        lines.push('\n\n');
        lines.push(`**Notes:**`);
        lines = lines.concat(notes.map((item) => `- ${item}`));

        return lines.join('\n');
    }

    close(): void {
        this.dialogService.close();
    }

    async download(text: string): Promise<void> {
        const { sessionId, userId } = this;

        this.isLoading$.next(true);

        try {
            const { documentUrl } = await this.firestoreService.cloudFunctionCallable<any>('printToPdf', {
                sessionId,
                userId,
                text,
            });

            fileDownload(documentUrl);
        } catch (error) {
            this.snackbarService.showErrorSnackBar(this.snackBarTitle, 'Error generating PDF');
            console.error(error);
        } finally {
            this.isLoading$.next(false);
        }
    }

    private buildViewModel(): Observable<SessionRecorderSummarizeViewModel> {
        return combineLatest([
            this.isLoading$.asObservable().pipe(distinctUntilChanged()),
            this.store.select(selectSessionId),
            this.store.select(selectAuthenticatedUserId),
        ]).pipe(
            tap(([, sessionId, userId]) => {
                this.sessionId = sessionId;
                this.userId = userId;
            }),
            map(([isLoading]) => {
                return {
                    isLoading,
                };
            }),
            startWith(initialState),
        );
    }
}
