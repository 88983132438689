import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, catchError, combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';

import { FiltersService, ProjectService, UserAccessService } from '@accenture/erp-deployment/shared/domain';
import { AppState, isDashboardPage, selectAuthenticatedUser } from '@accenture/global-store';
import {
    DefaultFilterObject,
    EmptyScreenConfig,
    emptyScreenDescriptions,
    emptyScreenTitles,
    initialDefaultFilterObject,
    NavigationTab,
    ProjectRole,
    User,
    UserAccess,
} from '@accenture/shared/data';
import { ComingSoonService, DialogService } from '@accenture/shared/ui';

import { FredHeaderStore } from '../component-stores/fred-header.store';

export enum ProjectsViewTab {
    ListView,
    CardView,
}

export interface ProjectsListViewModel {
    activeTab: ProjectsViewTab;
    emptyScreenConfig: EmptyScreenConfig | null;
    projects: UserAccess[];
    filtersAndSort: DefaultFilterObject;
    hasError: boolean;
    hasProjectLikeAdmin: boolean;
    isFilterAndSortPanelOpened: boolean;
    isLoading: boolean;
    isDashboardPage: boolean;
    searchValue: string;
    isSearchShown: boolean;
    user: User;
    isFiltersAndSortVisible: boolean;
    isFiltersApplied: boolean;
}

const defaultViewModel: ProjectsListViewModel = {
    activeTab: ProjectsViewTab.CardView,
    emptyScreenConfig: null,
    projects: [],
    filtersAndSort: initialDefaultFilterObject,
    hasError: false,
    hasProjectLikeAdmin: false,
    isFilterAndSortPanelOpened: false,
    isLoading: true,
    isDashboardPage: true,
    searchValue: '',
    isSearchShown: false,
    user: {} as User,
    isFiltersAndSortVisible: false,
    isFiltersApplied: false,
};

@Injectable()
export class ProjectsListFacade implements OnDestroy {
    private searchValue$ = new BehaviorSubject<string>('');
    private isSearchShown$ = new BehaviorSubject<boolean>(false);
    private isFilterAndSortPanelOpened$ = new BehaviorSubject<boolean>(false);
    private activeTab$ = new BehaviorSubject<ProjectsViewTab>(ProjectsViewTab.CardView);
    private isLoading$ = new BehaviorSubject<boolean>(false);

    vm$ = this.buildViewModel();

    constructor(
        private store: Store<AppState>,
        private projectService: ProjectService,
        private filtersService: FiltersService,
        private userAccessService: UserAccessService,
        private dialogService: DialogService,
        private fredHeaderStore: FredHeaderStore,
        private router: Router,
        private comingSoonService: ComingSoonService,
    ) {}

    setActiveTab(tab: ProjectsViewTab): void {
        this.activeTab$.next(tab);
    }

    navigateHome(tab: NavigationTab): void {
        this.fredHeaderStore.setTab(tab);
        this.router.navigate(['/home'], { queryParams: { tab } });
    }

    applyFiltersAndSort(userId: string, filters: DefaultFilterObject): void {
        this.projectService.updateProjectsFilters(userId, filters);
    }

    filterProjects(searchValue: string): void {
        this.searchValue$.next(searchValue);
    }

    toggleShowSearch(): void {
        const isSearchShown = !this.isSearchShown$.getValue();
        this.isSearchShown$.next(isSearchShown);

        if (!isSearchShown) {
            this.searchValue$.next('');
        }
    }

    toggleFilterAndSortPanel(opened: boolean): void {
        this.isFilterAndSortPanelOpened$.next(opened);
    }

    openCreateProjectDialog(): void {
        // noop
    }

    private buildViewModel(): Observable<ProjectsListViewModel> {
        const user$ = this.store.select(selectAuthenticatedUser).pipe(filter((user) => !!user));
        const filterAndSort$ = user$.pipe(
            switchMap((user) => this.projectService.getCurrentUserProjectsFilters(user.id)),
        );
        const projects$ = combineLatest([user$, this.projectService.deletingProjectIds$]).pipe(
            switchMap(([user, deletingProjectIds]) =>
                this.userAccessService.getProjectAssignmentsByUserId(user.id).pipe(
                    map((userAccesses) =>
                        userAccesses.map((userAccess) => {
                            if (!userAccess.updated) {
                                userAccess.updated = userAccess.created;
                            }
                            if (!userAccess.lastViewed) {
                                userAccess.lastViewed = userAccess.updated;
                            }
                            return userAccess;
                        }),
                    ),
                    map((userAccesses) =>
                        userAccesses.filter((userAccess) => !deletingProjectIds.includes(userAccess.projectId)),
                    ),
                ),
            ),
        );

        const userViewData$ = combineLatest([
            this.activeTab$.asObservable().pipe(distinctUntilChanged()),
            this.searchValue$.asObservable().pipe(map((value: string) => value.trimStart().toLowerCase())),
            this.isFilterAndSortPanelOpened$.asObservable().pipe(distinctUntilChanged()),
            this.isSearchShown$.asObservable().pipe(distinctUntilChanged()),
            this.store.select(isDashboardPage).pipe(distinctUntilChanged()),
            this.fredHeaderStore.tab$.pipe(distinctUntilChanged()),
        ]);

        return combineLatest([user$, projects$, filterAndSort$, userViewData$, this.isLoading$]).pipe(
            map(
                ([
                    user,
                    projects,
                    filtersAndSort,
                    [activeTab, searchValue, isFilterAndSortPanelOpened, isSearchShown, isDashboardPage, headerTab],
                    isLoading,
                ]) => {
                    const isDashboard = isDashboardPage || headerTab === NavigationTab.Dashboard;
                    const currentUserFilters = filtersAndSort ?? initialDefaultFilterObject;
                    const appliedUserFilters = isDashboard
                        ? {
                              sortOption: currentUserFilters.sortOption,
                              sortOrder: currentUserFilters.sortOrder,
                          }
                        : currentUserFilters;
                    const visibleProjects = this.getVisibleProjects(
                        projects,
                        appliedUserFilters,
                        searchValue.replace(/^\s+|\s+$|\s+(?=\s)/g, ''),
                    );
                    const hasProjectLikeAdmin = projects.some((project) => project.role === ProjectRole.Admin);

                    // set coming soon page visibiity
                    if (!isDashboard) {
                        this.comingSoonService.useComingSoonPage = true;
                    }

                    return {
                        activeTab,
                        user,
                        searchValue,
                        isFilterAndSortPanelOpened,
                        isLoading,
                        hasProjectLikeAdmin,
                        isSearchShown,
                        isDashboardPage: isDashboard,
                        filtersAndSort: currentUserFilters,
                        projects: visibleProjects,
                        emptyScreenConfig: this.getEmptyScreenConfig(visibleProjects, searchValue, filtersAndSort),
                        hasError: false,
                        isFiltersAndSortVisible: this.isFiltersAndSortVisible(projects, searchValue, filtersAndSort),
                        isFiltersApplied: this.filtersService.isFiltersApplied(filtersAndSort),
                    };
                },
            ),
            startWith(defaultViewModel),
            catchError((err) => {
                console.log('Error retrieving data for facade, may be firestore rules related', err);
                return of({
                    ...defaultViewModel,
                    isLoading: false,
                    hasError: true,
                });
            }),
        );
    }

    private getVisibleProjects(
        projects: UserAccess[],
        currentUserFilters: DefaultFilterObject,
        searchValue: string,
    ): UserAccess[] {
        const visibleProjectsAfterSearch = projects.length
            ? this.filtersService.filterAndSort(projects, currentUserFilters, searchValue)
            : [];

        return visibleProjectsAfterSearch.filter(({ hidden }) => !hidden);
    }

    private getEmptyScreenConfig(
        projects: UserAccess[],
        searchValue: string,
        filtersAndSort: DefaultFilterObject,
    ): EmptyScreenConfig {
        const hasSearchCriteria = this.filtersService.isFiltersApplied(filtersAndSort) || !!searchValue;
        const noResponsesMatchSearchCriteria = hasSearchCriteria && !projects.length;
        const noProjectsCreated = !projects.length && !hasSearchCriteria && !searchValue;

        if (noResponsesMatchSearchCriteria) {
            return {
                title: searchValue ? emptyScreenTitles.noProjectsFound : emptyScreenTitles.noProjectsMatch,
                description: emptyScreenDescriptions.noMatchDefault,
            };
        }

        if (noProjectsCreated) {
            return {
                title: emptyScreenTitles.noProjectsYet,
                description: emptyScreenDescriptions.noMatchDefault,
            };
        }

        return null;
    }

    private isFiltersAndSortVisible(
        projects: UserAccess[],
        searchValue: string,
        filtersAndSort: DefaultFilterObject,
    ): boolean {
        const hasSearchCriteria = this.filtersService.isFiltersApplied(filtersAndSort) || !!searchValue;
        return !!projects.length || hasSearchCriteria;
    }

    ngOnDestroy(): void {
        // reset coming soon page visibility
        this.comingSoonService.useComingSoonPage = false;
    }
}
