export class Upvote {
    id: string;
    sessionId?: string;
    activityId: string;
    activityItemId: string;
    responseId: string;
    userId: string;

    constructor(upvote: Upvote) {
        this.id = upvote.id ?? '';
        this.sessionId = upvote.sessionId ?? '';
        this.activityId = upvote.activityId ?? '';
        this.activityItemId = upvote.activityItemId ?? '';
        this.responseId = upvote.responseId ?? '';
        this.userId = upvote.userId ?? '';
    }

    createSerializableObject(): Record<string, unknown> {
        return {};
    }
}
