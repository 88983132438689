import { Inject, Injectable } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

import { TeamMemberService, cancelEditTemplateAction } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectActivityIdAndParentIds, selectAuthenticatedUserId } from '@accenture/global-store';
import { ParentType, TeamMember } from '@accenture/shared/data';

import { cancelEditTemplateDialogDescriptionNote, publishDialogDescription, publishDialogDescriptionNote, publishDialogTitle } from './constants';
import { PublishTemplateConfirmationDialogComponent } from './publish-template-confirmation-dialog.component';

export interface PublishTemplateConfirmationDialogViewModel {
    teamMembers: TeamMember[];
    description: string;
    title: string;
    closeButtonTxt: string;
    confirmBtnText: string;
    isLoading: boolean;
    isWarning: boolean;
}

const defaultViewModel: PublishTemplateConfirmationDialogViewModel = {
    teamMembers: [],
    description: publishDialogDescription,
    title: '',
    closeButtonTxt: '',
    confirmBtnText: '',
    isLoading: true,
    isWarning: false,
};

@Injectable()
export class PublishTemplateConfirmationDialogFacade {
    private isLoading$ = new BehaviorSubject<boolean>(false);

    vm$ = this.buildViewModel();
    private parentType!: ParentType;
    private parentId!: string;

    constructor(
        private dialogRef: MatDialogRef<PublishTemplateConfirmationDialogComponent>,
        private teamMembersService: TeamMemberService,
        private store: Store<AppState>,
        @Inject(MAT_DIALOG_DATA) public data: Record<string, any>,
    ) {}

    closeDialog(): void {
        this.dialogRef.close();
    }

    async onPublish(): Promise<void> {
        // TODO: SnackBars on another task
        const confirmFn = this.data?.['confirm'];

        // TODO: pass online teamMembers or get from store (need to set in this case)
        if (typeof confirmFn === 'function') {
            confirmFn();
        }
        this.dialogRef.close();
    }

    private buildViewModel(): Observable<PublishTemplateConfirmationDialogViewModel> {
        return combineLatest([
            this.isLoading$,
            this.store.select(selectAuthenticatedUserId),
            this.store.select(selectActivityIdAndParentIds),
        ]).pipe(
            switchMap(([isLoading, userId, { parentType, parentId }]) => {
                this.parentType = parentType;
                this.parentId = parentId;

                return this.getOnlineTeamMembers(this.parentType, this.parentId, userId as string).pipe(
                    map(teamMembers => {
                        const description = this.data.text || this.getDescription(teamMembers?.length, this.data.isWarning);
                        
                        return {
                            teamMembers,
                            description,
                            isLoading,
                            title: this.data.title || publishDialogTitle,
                            closeButtonTxt: this.data.closeButtonTxt,
                            confirmBtnText: this.data.confirmBtnText,
                            isWarning: this.data.isWarning || false,
                        };
                    }),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    private getDescription(teamMembersLength: number, isWarning: boolean): string {
        return isWarning
                ? teamMembersLength ? `${cancelEditTemplateAction} ${cancelEditTemplateDialogDescriptionNote}` : cancelEditTemplateAction
                : teamMembersLength ? `${publishDialogDescription} ${publishDialogDescriptionNote}` : publishDialogDescription
    }

    private getOnlineTeamMembers(parentType: ParentType, projectId: string, userId: string): Observable<TeamMember[]> {
        return this.teamMembersService
            .getTeamMembersByParent(parentType, projectId)
            .pipe(
                map(teamMembers =>
                    teamMembers.filter(teamMember => !!teamMember.isOnline && teamMember.userId !== userId),
                ),
            );
    }
}
