import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import {
    characterLimitMedium,
    characterLimitText,
    characterLimitXL,
    collectionColors,
    CollectionOptions,
    DefaultCollectionColor,
    determineHintClass,
    displayCollectionColorsName,
    FileType,
    User,
    validationMessages,
} from '@accenture/shared/data';
import { ImageInputMode, ImageInputPlaceholdersTypes, LoadedDescription } from '@accenture/shared/ui';

import { CreateCollectionDialogFacade } from './create-collection-dialog-facade';

type OptionsFormGroup = FormGroup<{
    [CollectionOptions.Tag]: FormControl<string>;
}>;

type CollectionForm = FormGroup<{
    name: FormControl<string>;
    description: FormControl<string>;
    color: FormControl<string>;
    options: OptionsFormGroup;
}>;

@Component({
    selector: 'accenture-create-collection-dialog',
    templateUrl: './create-collection-dialog.component.html',
    styleUrls: ['./create-collection-dialog.component.scss'],
    providers: [CreateCollectionDialogFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateCollectionDialogComponent {
    vm$ = this.facade.vm$;

    loaderText = LoadedDescription.CollectionCreating.toUpperCase();
    imageInputMode = ImageInputMode;
    imageInputPlaceholder = ImageInputPlaceholdersTypes.Collection;

    characterLimitMedium = characterLimitMedium;
    descriptionCharacterLimit = characterLimitXL;
    displayCollectionColorsName = displayCollectionColorsName;
    collectionColors = collectionColors;
    characterLimitText = characterLimitText;
    determineHintClass = determineHintClass;
    validationMessages = validationMessages;

    collectionForm: CollectionForm;
    optionsForm: OptionsFormGroup;

    get selectedColor(): AbstractControl {
        return this.collectionForm.get('color') as AbstractControl;
    }

    constructor(private facade: CreateCollectionDialogFacade, private formBuilder: FormBuilder) {
        this.createForm();
    }

    setCollectionImage(collectionImage: FileType): void {
        this.facade.setCollectionImage(collectionImage);
    }

    deleteCollectionImage(): void {
        // this.facade.deleteCollectionImage();??? // TODO: check
        this.facade.setCollectionImage({} as FileType);
    }

    async createCollection(user: User, collectionImage: FileType): Promise<void> {
        const collectionDetails = this.collectionForm.value;
        await this.facade.createCollection(collectionDetails, user, collectionImage);
    }

    closeDialog(isChipsOpen?: boolean): void {
        isChipsOpen ? this.facade.backToCollectionData() : this.facade.closeDialog();
    }

    ngOnDestroy(): void {
        this.facade.deleteUnusedData();
    }

    private createForm() {
        this.optionsForm = this.formBuilder.group(
            {
                tags: new FormControl(''),
            },
            {
                updateOn: 'blur',
            },
        );

        this.collectionForm = this.formBuilder.group({
            name: new FormControl('', { nonNullable: true, validators: Validators.required }),
            description: new FormControl('', { nonNullable: true }),
            color: new FormControl(DefaultCollectionColor, { nonNullable: true }),
            options: this.optionsForm,
        });
    }
}
