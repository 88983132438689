import { FieldValue, Timestamp } from 'firebase/firestore';

import { sortBySequenceAsc } from '@accenture/shared/util';

import { Dictionary } from '../dictionary';
import { NodeTimestamp } from '../node-timestamp';
import { ResponseValue } from '../responses';
import { SessionFocusSteps } from '../session-focus-step-type';
import { ActivityItem } from './activity-item';
import { ActivityItemType } from './activity-item-type';
import { OptionsListItem } from './options-list-item';

export type TableColumnType =
    | ActivityItemType.Text
    | ActivityItemType.Number
    | ActivityItemType.SingleSelect
    | ActivityItemType.MultiSelect
    | ActivityItemType.Date
    | ActivityItemType.StarVoting
    | ActivityItemType.File
    | ActivityItemType.Attachment
    | ActivityItemType.Unknown;

export const minColumnWidth = 280;
export const batchLoadingRowsLimit = 20;

export class TableColumn extends NodeTimestamp {
    id?: string;
    sessionId?: string;
    activityId?: string;
    activityItemId?: string;
    type: TableColumnType;
    title: string;
    sequence: string;
    description?: string;
    width?: number;
    choices?: Dictionary<OptionsListItem>; // choices for single/multi selects
    options?: Dictionary<any>; // { enableRange, min, max } from numeric
    disableValidate?: boolean;
    dndDisabled?: boolean;
    isResizing?: boolean;

    constructor(data: Partial<TableColumn>) {
        super(data);
        this.id = data.id ?? '';
        this.sessionId = data.sessionId ?? '';
        this.activityId = data.activityId ?? '';
        this.activityItemId = data.activityItemId ?? '';
        this.type = data.type ?? ActivityItemType.Unknown;
        this.title = data.title ?? '';
        this.sequence = data.sequence ?? '';
        this.description = data.description ?? '';
        this.width = data.width ?? minColumnWidth;
        this.choices = data.choices ?? {};
        this.options = data.options ?? {};
        this.disableValidate = data.disableValidate ?? false;
        this.dndDisabled = data.dndDisabled ?? false;
        this.isResizing = data.isResizing ?? false;
    }

    get choicesArray(): OptionsListItem[] {
        const choices = Object.values(this.choices || {});

        return sortBySequenceAsc(choices);
    }
}

export enum TableStatus {
    Setup = 'SETUP',
    Validate = 'VALIDATION',
    Summary = 'SUMMARY',
}

export class TableRow extends NodeTimestamp {
    id?: string;
    sessionId?: string;
    activityId?: string;
    activityItemId?: string;
    sequence: string;
    disableValidate?: boolean;
    dndDisabled?: boolean;

    constructor(data: Partial<TableRow>) {
        super(data);
        this.id = data.id ?? '';
        this.sessionId = data.sessionId ?? '';
        this.activityId = data.activityId ?? '';
        this.activityItemId = data.activityItemId ?? '';
        this.sequence = data.sequence ?? '';
        this.disableValidate = data.disableValidate ?? false;
        this.dndDisabled = data.dndDisabled ?? false;
    }
}

export class Table extends NodeTimestamp implements ActivityItem {
    id?: string;
    title?: string;
    sequence: string;
    type = ActivityItemType.Table;
    sessionId?: string;
    activityId?: string;
    columns?: TableColumn[];
    responses?: { [columnId: string]: ResponseValue }[];
    numberedColumn?: boolean;
    validate?: boolean;
    validateRequired?: boolean;
    status?: TableStatus;
    usersCompletedValidation?: Dictionary<boolean>;
    dateStatusChangedToSummary?: FieldValue | Timestamp;
    validateChangedResponses?: boolean;
    dndDisabled?: boolean;
    lastFocusedStep?: SessionFocusSteps;

    constructor(data: Partial<Table>) {
        super(data);
        this.id = data.id ?? '';
        this.title = data.title ?? '';
        this.sequence = data.sequence ?? '';
        this.sessionId = data.sessionId ?? '';
        this.activityId = data.activityId ?? '';
        this.columns = data.columns ? data.columns.map(column => new TableColumn(column)) : [];
        this.numberedColumn = data.numberedColumn ?? false;
        this.validate = data.validate ?? false;
        this.validateRequired = data.validateRequired ?? false;
        this.status = data.status ?? TableStatus.Setup;
        this.usersCompletedValidation = data.usersCompletedValidation ?? {};
        this.dateStatusChangedToSummary = data.dateStatusChangedToSummary || undefined;
        this.validateChangedResponses = !!data.validateChangedResponses;
        this.dndDisabled = !!data.dndDisabled;
        this.lastFocusedStep = data.lastFocusedStep || undefined;
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            id: this.id,
            title: this.title,
            sequence: this.sequence,
            type: this.type,
            sessionId: this.sessionId,
            activityId: this.activityId,
            numberedColumn: this.numberedColumn,
            validate: this.validate,
            validateRequired: this.validateRequired,
            created: this.created,
            updated: this.updated,
            status: this.status,
            usersCompletedValidation: this.usersCompletedValidation,
            dateStatusChangedToSummary: this.dateStatusChangedToSummary,
            validateChangedResponses: this.validateChangedResponses,
            lastFocusedStep: this.lastFocusedStep,
        };
    }
}
