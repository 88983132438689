import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

import { DialogService } from '@accenture/shared/ui';

import { NoEmailsDialogComponent } from '../no-emails-dialog/no-emails-dialog.component';

@Component({
    selector: 'accenture-session-delete-snack-bar',
    templateUrl: './session-delete-snack-bar.component.html',
    styleUrls: ['./session-delete-snack-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionDeleteSnackBarComponent {
    constructor(
        private dialogService: DialogService,
        public snackBarRef: MatSnackBarRef<SessionDeleteSnackBarComponent>,
        @Inject(MAT_SNACK_BAR_DATA)
        public data: {
            actionButtonClass: string;
            iconClass: string;
            iconName: string;
            dataList?: string[];
        },
    ) {}

    close(): void {
        this.snackBarRef.dismiss();
    }

    showDetails(): void {
        this.snackBarRef.dismiss();
        this.showInfoPopup();
    }

    private showInfoPopup(): void {
        this.dialogService.open(NoEmailsDialogComponent, {
            listItems: this.data.dataList,
            width: '444px',
        });
    }
}
