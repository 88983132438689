<div *ngIf="vm$ | async as vm" class="team-members-container">
  <div *ngIf="vm.isActivityTemplate; else defaultSidePanelHeader" class="d-flex p-16">
    <button class="tt9-btn-icon-filled mr-8" (click)="closePanel.emit()">
      <accenture-icon>icon-chevron-left</accenture-icon>
    </button>

    <h6 class="tt9-h2">Template team</h6>

    <button
      class="tt9-btn-icon-filled ml-auto"
      matTooltipClass="tt9-arrow-tooltip-above"
      matTooltip="Add new members to the template"
      (click)="addMembers()"
    >
      <accenture-icon>icon-person-add</accenture-icon>
    </button>
  </div>

  <ng-template #defaultSidePanelHeader>
    <div class="tt9-side-panel-header">
      <button
        *ngIf="showBackArrow"
        class="tt9-btn-icon-filled mr-8"
        (click)="$event.stopPropagation(); navigateBack.emit()"
      >
        <accenture-icon>icon-chevron-left</accenture-icon>
      </button>

      <h6 class="tt9-h2">Template team</h6>

      <div class="spacer"></div>

      <button
        class="tt9-btn-icon-filled mr-8"
        matTooltipClass="tt9-arrow-tooltip-above-right"
        matTooltip="Add new members to the template"
        (click)="addMembers()"
      >
        <accenture-icon>icon-person-add</accenture-icon>
      </button>

      <button class="tt9-btn-icon-filled" (click)="closePanel.emit()">
        <accenture-icon>icon-close</accenture-icon>
      </button>
    </div>
  </ng-template>

  <div
    class="team-members-body"
    [ngClass]="{
      'activity-template-team-members-body': vm.isActivityTemplate
    }"
  >
    <!-- User search -->
    <div class="user-search-container">
      <mat-form-field class="user-search tt9-form-field no-label no-margin" appearance="outline" floatLabel="always">
        <input matInput placeholder="Search" [formControl]="searchControl" />

        <accenture-icon matPrefix>icon-search</accenture-icon>
        <accenture-icon *ngIf="searchControl.value" matSuffix (click)="clearSearch($event)">
          icon-close
        </accenture-icon>
      </mat-form-field>
    </div>
    <!-- /User search -->

    <ng-container *accentureLoaded="!vm.isLoading">
      <!-- Owner -->
      <ng-container
        *ngTemplateOutlet="
          userList;
          context: {
            users: vm.owner ? [vm.owner] : [],
            mainUserId: templateCreatorId,
            userRole: templateRoles.Owner,
            currentUserId: vm.currentUserId
          }
        "
      ></ng-container>
      <!-- Owner -->

      <!-- Collaborators list -->
      <ng-container
        *ngTemplateOutlet="
          userList;
          context: {
            users: vm.collaborators,
            mainUserId: vm.templateCreatorId,
            userRole: templateRoles.Collaborator,
            currentUserId: vm.currentUserId,
            userInProcessIds: vm.userInProcessIds
          }
        "
      ></ng-container>
      <!-- Participants list -->
    </ng-container>
  </div>
</div>

<ng-template
  #userList
  let-users="users"
  let-userRole="userRole"
  let-mainUserId="mainUserId"
  let-currentUserId="currentUserId"
  let-userInProcessIds="userInProcessIds"
>
  <div class="user-list" [ngClass]="{ activity: isActivity }">
    <span class="user-list-header tt9-body-medium-1"
      >{{ userRole | displayUserAccessRole }}{{ userRole === templateRoles.Owner ? "" : "s" }}</span
    >

    <div *ngFor="let user of users; trackBy: trackById">
      <div class="user-list-item">
        <div class="user-list-userpic">
          <div class="tt9-userpic small tt9-tooltip">
            <img *ngIf="user.imageUrl" [src]="user.imageUrl" [alt]="user.displayName" />
            <ng-container *ngIf="!user.imageUrl">{{ user.displayName | userInitials }}</ng-container>
          </div>
        </div>
        <div *ngIf="user.isOnline" class="userpic-online-badge"></div>

        <span
          class="user-list-item-username text-overflow-ellipsis tt9-body-medium-2"
          matTooltipPosition="above"
          matTooltipClass="tt9-arrow-tooltip-below-right tt9-arrow-tooltip-below-right-large"
          [matTooltip]="user.displayName"
          [matTooltipDisabled]="isTooltipHidden"
          (mouseover)="isDisabledTooltip($event)"
        >
          {{ user.displayName }}
        </span>

        <div
          *ngIf="user.id !== currentUserId && userRole === templateRoles.Collaborator"
          class="user-list-item-actions"
        >
          <div [matMenuTriggerFor]="userMenu">
            <accenture-icon> icon-more </accenture-icon>
          </div>

          <mat-menu #userMenu="matMenu" class="tt9-menu">
            <button
              mat-menu-item
              class="color-error"
              [disabled]="userInProcessIds.has(user.id)"
              (click)="deleteTeamMember(user.id)"
            >
              <accenture-icon>icon-delete</accenture-icon>
              Delete from template
            </button>
          </mat-menu>
        </div>
      </div>
    </div>

    <!-- Empty screen -->
    <span *ngIf="!users.length" class="tt9-input-text empty-screen">{{ emptyScreenTitle }}</span>
    <!-- Empty screen -->
  </div>
</ng-template>
