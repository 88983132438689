import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { Timestamp } from 'firebase/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    DBPathHelper,
    ParentType,
    UserTemplate,
} from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import WhereFilterOp = firebase.firestore.WhereFilterOp;

@Injectable({
    providedIn: 'root',
})
export class UserTemplateService {
    constructor(private firestoreService: FirestoreService) {}

    private static orderByUpdatedDesc(a: UserTemplate, b: UserTemplate): number {
        const aUpdated = a.updated as Timestamp;
        const bUpdated = b.updated as Timestamp;
        return bUpdated?.toMillis() - aUpdated?.toMillis();
    }

    private getAssignments(userId: string, orderByField: string, parentType?: ParentType): Observable<UserTemplate[]> {
        const query = new Map([['userId', userId]]);
        if (parentType) {
            query.set('templateType', parentType);
        }
        return this.firestoreService.getDocumentsByMultipleProperties('userTemplates', query, orderByField).pipe(
            map((assignments: UserTemplate[]) => assignments.sort(UserTemplateService.orderByUpdatedDesc)),
        );
    }

    getTemplatesAssignmentsByUserId(userId: string): Observable<UserTemplate[]> {
        return this.getAssignments(userId, 'templateId');
    }

    getAllUserTemplatesByUserId(userId: string): Observable<UserTemplate[]> {
        return this.firestoreService
            .getDocumentsByProperty<UserTemplate>(DBPathHelper.getUserTemplatesPath(), 'userId', userId)
            .pipe(
                map((userTemplates) =>
                    (userTemplates || [])
                        .filter((userTemplates) => !userTemplates?.markedForDelete)
                ),
            );
    }

    getTemplatesByUserIdNew(userId: string): Observable<UserTemplate[]> {
        const templatesTypes = [ParentType.Templates, ParentType.ActivityTemplates] as string[];
        const queries = [
            ['templateType', 'in', templatesTypes],
            ['userId', '==', userId],
        ] as [property: string, operatorsValue: WhereFilterOp, value: any][];

        return this.firestoreService
            .getDocumentsByCombinedProperty('userTemplates', queries)
            .pipe(
                map((assignments: UserTemplate[]) =>
                    assignments.filter((assignment) => !assignment.public).sort(UserTemplateService.orderByUpdatedDesc),
                ),
            );
    }
}
