import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

import {
    ProjectService,
    saveSessionToProjectInfoSnackBar,
    saveSessionToProjectSuccessSnackBar,
    UserAccessService,
} from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUserId, selectProjectId } from '@accenture/global-store';
import { Dictionary, errorMessageSnackbarText, ParentType, Project, UserAccess } from '@accenture/shared/data';
import { DialogService, SnackbarService, SnackBarTypes } from '@accenture/shared/ui';

import { AddSessionDialogComponent } from './add-session-dialog.component';

export interface AddSessionDialogViewModel {
    project: Project;
    templates: UserAccess[];
    selectedTemplates: UserAccess[];
    selectedTemplatesMap: Dictionary<boolean>;
    isLoading: boolean;
}

const defaultViewModel: AddSessionDialogViewModel = {
    project: {} as Project,
    templates: [],
    selectedTemplates: [],
    selectedTemplatesMap: {},
    isLoading: true,
};

@Injectable()
export class AddSessionDialogFacade {
    vm$ = this.buildViewModel();

    private searchValue$ = new BehaviorSubject('');
    private selectedTemplatesMap$ = new BehaviorSubject<Dictionary<boolean>>({});
    private isLoading$ = new BehaviorSubject<boolean>(false);
    private projectId: string;
    private userId!: string;
    private selectedTemplatesMap: Dictionary<boolean>;

    constructor(
        private store: Store<AppState>,
        private userAccessService: UserAccessService,
        private projectService: ProjectService,
        private dialogRef: MatDialogRef<AddSessionDialogComponent>,
        private snackbarService: SnackbarService,
        private dialogService: DialogService,
    ) {}

    updateSearchValue(value: string): void {
        this.searchValue$.next(value);
    }

    updateSelectedTemplates(selectedTemplatesMap: Dictionary<boolean>): void {
        this.selectedTemplatesMap$.next({
            ...this.selectedTemplatesMap$.getValue(),
            ...selectedTemplatesMap,
        });
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    async addSessionsToProject(): Promise<void> {
        this.showSaveAsTemplateInfoSnackBar();

        if (!this.projectId || !this.userId) {
            this.showSaveAsTemplateErrorSnackBar();
            this.dialogRef.close();
            return;
        }

        const selectedTemplatesIds = Object.keys(this.selectedTemplatesMap || {}).filter(
            (id) => !!this.selectedTemplatesMap[id],
        );

        const templates = {};
        selectedTemplatesIds.forEach((value) => {
            templates[value] = true;
        });

        try {
            for (const selectedTemplateId of selectedTemplatesIds) {
                await this.projectService.addSessionTemplatesToProject(
                    this.projectId,
                    selectedTemplateId,
                    this.userId,
                    ParentType.Projects,
                    ParentType.Templates,
                    undefined,
                    true,
                );
            }
            this.showSaveAsTemplateSuccessSnackBar();
        } catch {
            this.showSaveAsTemplateErrorSnackBar();
        }

        this.dialogRef.close();
    }

    private buildViewModel(): Observable<AddSessionDialogViewModel> {
        return combineLatest([this.store.select(selectProjectId), this.store.select(selectAuthenticatedUserId)]).pipe(
            switchMap(([projectId, userId]) => {
                this.projectId = projectId;
                this.userId = userId;

                return combineLatest([
                    this.projectService.getProjectById(projectId),
                    this.userAccessService.getTemplateAssignmentsByUserId(userId),
                    this.searchValue$,
                    this.selectedTemplatesMap$,
                    this.isLoading$,
                ]).pipe(
                    map(([project, templates, searchValue, selectedTemplatesMap, isLoading]) => {
                        this.selectedTemplatesMap = selectedTemplatesMap;

                        return {
                            project,
                            selectedTemplatesMap,
                            isLoading,
                            selectedTemplates: this.getSelectedTemplates(templates),
                            templates: this.getFilteredTemplates(templates, searchValue),
                        };
                    }),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    private getFilteredTemplates(templates: UserAccess[], searchValue: string): UserAccess[] {
        return templates.filter((template) => {
            const hasSearchName = template.name?.toLowerCase().includes(searchValue);
            const hasSearchDescription = template.description?.toLowerCase().includes(searchValue);

            return hasSearchName || hasSearchDescription;
        });
    }

    private getSelectedTemplates(templates: UserAccess[]): UserAccess[] {
        const selectedTemplatesMap = this.selectedTemplatesMap$.getValue();

        return templates.filter((template) => selectedTemplatesMap[template.templateId]);
    }

    private showSaveAsTemplateInfoSnackBar(): void {
        this.snackbarService.showSnackBar(null, saveSessionToProjectInfoSnackBar, SnackBarTypes.Info, true);
    }

    private showSaveAsTemplateSuccessSnackBar(): void {
        this.snackbarService.showSnackBar(null, saveSessionToProjectSuccessSnackBar, SnackBarTypes.Success, true);
    }

    private showSaveAsTemplateErrorSnackBar(): void {
        this.snackbarService.showSnackBar(null, errorMessageSnackbarText, SnackBarTypes.Error, true);
    }
}
