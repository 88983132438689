import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DBPathHelper, ParentType, Upvote } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable({
    providedIn: 'root',
})
export class ActivityUpvoteService {
    constructor(private firestoreService: FirestoreService) {}

    addUpvote(projectId: string, upvote: Upvote): Promise<void> {
        const fanOutWrites = [
            {
                path: `projects/${projectId}/upvotes/${upvote.responseId}_${upvote.userId}`,
                data: upvote,
            },
            {
                path: `projects/${projectId}/responses/${upvote.responseId}`,
                data: { upvotesCount: this.firestoreService.changeCounterValue(1) },
            },
        ];
        return this.firestoreService.writeBatch(fanOutWrites);
    }

    deleteUpvote(projectId: string, upvoteId: string): Promise<void> {
        const batch = this.firestoreService.createBatch();
        const db = this.firestoreService.firestoreRef.firestore;
        const responseId = upvoteId.split('_')[0];
        batch.delete(db.doc(`projects/${projectId}/upvotes/${upvoteId}`));
        batch.update(db.doc(`projects/${projectId}/responses/${responseId}`), {
            upvotesCount: this.firestoreService.changeCounterValue(-1),
        });
        return batch.commit();
    }

    // TODO remove ones project is removed
    async toggleUpvote(
        projectId: string,
        upvote: Upvote,
        upvotesLimit?: number,
        userUpvotesGiven?: number,
        userUpvoteResponseIds?: Set<string>,
    ): Promise<boolean> {
        const upvoteId = `${upvote.responseId}_${upvote.userId}`;
        const upvoteRef = await this.firestoreService.getDocumentRef(`projects/${projectId}/upvotes/${upvoteId}`);
        const responseRef = await this.firestoreService.getDocumentRef(
            `projects/${projectId}/responses/${upvote.responseId}`,
        );
        if (userUpvoteResponseIds && userUpvoteResponseIds.has(upvote.responseId)) {
            await responseRef.update('upvotesCount', this.firestoreService.changeCounterValue(-1));
            await upvoteRef.delete();
            return true;
        } else {
            if (!upvotesLimit || upvotesLimit > userUpvotesGiven) {
                await responseRef.update('upvotesCount', this.firestoreService.changeCounterValue(1));
                await upvoteRef.set(upvote);
                return true;
            }

            // nothing were updated - return false
            return false;
        }
    }

    async toggleUpvoteNew(
        parentType: ParentType,
        sessionId: string,
        upvote: Upvote,
        upvotesLimit?: number,
        userUpvotesGiven?: number,
        userUpvoteResponseIds?: Set<string>,
    ): Promise<boolean> {
        const upvoteId = `${upvote.responseId}_${upvote.userId}`;
        const upvoteRef = await this.firestoreService.getDocumentRef(
            DBPathHelper.getUpvotePath(parentType, sessionId, upvoteId),
        );
        const responseRef = await this.firestoreService.getDocumentRef(
            DBPathHelper.getResponsesPath(parentType, sessionId, upvote.responseId),
        );

        if (userUpvoteResponseIds && userUpvoteResponseIds.has(upvote.responseId)) {
            await responseRef.update('upvotesCount', this.firestoreService.changeCounterValue(-1));
            await upvoteRef.delete();
            return true;
        } else {
            if (!upvotesLimit || upvotesLimit > userUpvotesGiven) {
                await responseRef.update('upvotesCount', this.firestoreService.changeCounterValue(1));
                await upvoteRef.set(upvote);
                return true;
            }

            // nothing were updated - return false
            return false;
        }
    }
    getUpvotesForActivityItem(projectId: string, activityItemId: string): Observable<Upvote[]> {
        return this.firestoreService.getDocumentsByPropertyWithoutCaching<Upvote>(
            `projects/${projectId}/upvotes`,
            'activityItemId',
            activityItemId,
        );
    }

    getUpvotesForActivityItemNew(sessionId: string, activityItemId: string): Observable<Upvote[]> {
        return this.firestoreService.getDocumentsByPropertyWithoutCaching<Upvote>(
            DBPathHelper.getUpvotesPath(sessionId),
            'activityItemId',
            activityItemId,
        );
    }
    // TODO: Delete after project  deprecation
    getUpvotesForActivityItemByUser(projectId: string, activityItemId: string, userId: string): Observable<Upvote[]> {
        return this.firestoreService.getDocumentsByMultipleProperties<Upvote>(
            `projects/${projectId}/upvotes`,
            new Map([
                ['activityItemId', activityItemId],
                ['userId', userId],
            ]),
        );
    }

    getUpvotesForActivityItemByUserNew(
        parentType: ParentType,
        sessionId: string,
        activityItemId: string,
        userId: string,
    ): Observable<Upvote[]> {
        return this.firestoreService.getDocumentsByMultipleProperties<Upvote>(
            DBPathHelper.getUpvotePath(parentType, sessionId),
            new Map([
                ['activityItemId', activityItemId],
                ['userId', userId],
            ]),
        );
    }
}
