import { ChangeDetectionStrategy, Component } from '@angular/core';

import { addProjectImageUrl, buttonLabels, createProjectImageUrl, DialogSelectionOption } from '@accenture/shared/data';
import { LoadedDescription } from '@accenture/shared/ui';

import { UseTemplateDialogTT9Facade } from './use-template-dialog-facade-tt9';

@Component({
    selector: 'accenture-use-template-dialog-tt9',
    templateUrl: './use-template-dialog-tt9.component.html',
    styleUrls: ['./use-template-dialog-tt9.component.scss'],
    providers: [UseTemplateDialogTT9Facade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UseTemplateDialogTT9Component {
    vm$ = this.facade.vm$;

    loaderText!: LoadedDescription;
    createProjectImageUrl = createProjectImageUrl;
    addProjectImageUrl = addProjectImageUrl;
    buttonLabels = buttonLabels;

    constructor(private facade: UseTemplateDialogTT9Facade) {}

    closeDialog(): void {
        this.facade.closeDialog();
    }

    openCreateDialog(selectedProjectId: string): void {
        this.facade.closeDialog();
        if (selectedProjectId) {
            this.facade.openCreateSessionDialog();
        }
    }

    openSelectDialog(selectedProjectId: string): void {
        this.facade.closeDialog();
        if (selectedProjectId) {
            this.facade.openAddSessionDialog();
        }
    }

    getSelectionOptions(useTemplateId: string): DialogSelectionOption[] {
        return [
            {
                name: useTemplateId ? buttonLabels.createNewSession : buttonLabels.createNewProject,
                imageUrl: createProjectImageUrl,
                callBack: () => this.openCreateDialog(useTemplateId),
            },
            {
                name: useTemplateId ? buttonLabels.selectExistingSession : buttonLabels.selectExistingProject,
                imageUrl: addProjectImageUrl,
                callBack: () => this.openSelectDialog(useTemplateId),
            },
        ];
    }
}
