import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { catchError, map, Observable, of } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';

import { AppState, selectAuthenticatedUser } from '@accenture/global-store';
import { NavigationTab, User } from '@accenture/shared/data';
import { ComingSoonService } from '@accenture/shared/ui';

export interface DashboardViewModel {
    user: User;
    isLoading: boolean;
    hasError: boolean;
}

const defaultViewModel = {
    user: {},
    isLoading: true,
    hasError: false,
} as DashboardViewModel;

@Injectable()
export class DashboardFacade implements OnDestroy {
    vm$ = this.buildViewModel();

    constructor(private store: Store<AppState>, private router: Router, private comingSoonService: ComingSoonService) {
        // set coming soon page visibility
        this.comingSoonService.useComingSoonPage = true;
    }

    navigateToTemplatesPage(): void {
        this.router.navigate(['/home'], { queryParams: { tab: NavigationTab.Templates } });
    }

    private buildViewModel(): Observable<DashboardViewModel> {
        return this.store.select(selectAuthenticatedUser).pipe(
            filter(user => !!user?.id),
            map(user => ({
                user,
                isLoading: false,
                hasError: false,
            })),
            startWith(defaultViewModel),
            catchError(err => {
                console.log('Error retrieving data for facade, may be firestore rules related', err);
                return of({
                    ...defaultViewModel,
                    isLoading: false,
                    hasError: true,
                });
            }),
        );
    }

    ngOnDestroy(): void {
        // reset coming soon page visibility
        this.comingSoonService.useComingSoonPage = false;
    }
}
