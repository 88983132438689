import { ParentType } from '..';
import { NodeTimestamp } from './node-timestamp';

export interface Tag extends NodeTimestamp {
    id?: string;
    name: string;
    type?: ParentType;
    projects?: string[];
    sessions?: string[];
    collections?: string[];
    activities?: string[];
    default?: boolean;
}
