<ng-container *ngIf="vm$ | async as vm">
  <div *accentureLoaded="!vm.isLoading" class="activity-preview-container">
    <div class="tt9-side-panel-header">
      <h6 class="tt9-h2">Activity preview</h6>

      <div class="spacer"></div>

      <button class="tt9-btn-icon-filled" (click)="closePanel()">
        <accenture-icon>icon-close</accenture-icon>
      </button>
    </div>

    <div class="activity-preview">
      <div class="d-flex-column flex-gap-16">
        <div class="d-flex-column flex-gap-16">
          <!-- Focused Badge -->
          <ng-container *ngIf="vm.isFocused">
            <div class="activity-preview-badges-bar focused d-flex flex-gap-8">
              <div class="tt9-badge active">
                <accenture-icon class="mr-4" [size]="iconSize.Small"> icon-focus </accenture-icon>
                Focused
              </div>
            </div>
          </ng-container>
          <!-- Focused Badge -->

          <!-- Activity Badge -->
          <div class="d-flex-column flex-gap-8">
            <div class="activity-preview-badges-bar d-flex flex-gap-8">
              <div class="tt9-badge activity">
                <accenture-icon class="mr-4" [size]="iconSize.Small">
                  {{ vm.activity.type | displayActivityIcon : vm.activityConfigurationsMap }}
                </accenture-icon>
                {{ (vm.activity.type | displayActivityType : vm.activityConfigurationsMap) + " Activity" }}
              </div>
            </div>

            <h4 class="tt9-h4-medium">{{ vm.activity.name }}</h4>

            <p *ngIf="vm.activity.description" class="tt9-body-1">{{ vm.activity.description }}</p>
          </div>
          <!-- Activity Badge -->
        </div>
      </div>

      <!-- Content -->
      <div class="d-flex-column" *ngIf="showContentSection(vm)">
        <p class="tt9-categories tt9-color-neutral-500 mb-4 pb-16">CONTENT</p>
        <div class="activity-preview-content-list">
          <div
            *ngFor="let contentItem of vm.content; index as i; trackBy: trackById"
            class="activity-preview-content-item"
          >
            <div class="content-item-number tt9-body-2">{{ i + 1 }}</div>
            <div>
              <div class="content-item-name tt9-body-medium-1">{{ contentItem }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- Content -->

      <!-- TODO: update when working on dataFlowConnections -->
      <!-- Connections -->
      <div class="d-flex-column" *ngIf="vm.connections[vm.activity.id]">
        <p class="tt9-categories tt9-color-neutral-500 mb-4 pb-16">CONNECTIONS</p>
        <div class="activity-preview-connections">
          <div *ngIf="vm.connections[vm.activity.id] | isActivityConnectionSourceSessionVisible" class="mb-16">
            <h6 class="tt9-caption-1 tt9-color-neutral-500">Source session</h6>
            <p class="tt9-body-1 py-4">
              {{ vm.connections[vm.activity.id] | displayConnectionSessionLabel : vm.sessionNamesById }}
            </p>
          </div>

          <div *ngIf="vm.connections[vm.activity.id]?.length" class="mb-16">
            <h6 class="tt9-caption-1 tt9-color-neutral-500">Source activity</h6>
            <p
              class="tt9-body-1 py-4"
              *ngFor="
                let sourceActivityLabel of vm.connections[vm.activity.id]
                  | displayConnectionSourcesLabel : vm.activitiesById
              "
            >
              {{ sourceActivityLabel }}
            </p>
          </div>

          <div class="mb-16">
            <h6 class="tt9-caption-1 tt9-color-neutral-500">Criteria</h6>
            <p class="tt9-body-1 py-4">{{ vm.connections[vm.activity.id] | displayConnectionCriteriaLabel }}</p>
          </div>
        </div>
      </div>
      <!-- Connections -->

      <!-- Tags -->
      <div *ngIf="(vm.activity.tags | keyvalue)?.length" class="d-flex-column flex-gap-8">
        <p class="tt9-categories tt9-color-neutral-500 pb-16">TAGS</p>
        <div class="width-100">
          <mat-chip-listbox hideSingleSelectionIndicator class="tt9-chip">
            <mat-chip
              *ngFor="let tag of vm.activity.tags | keyvalue : alphabeticalKeyValueOrder; trackBy: trackById"
              class="inactive"
            >
              <span matTooltipPosition="above" matTooltipClass="tt9-arrow-tooltip-below-right" [matTooltip]="tag.value">
                {{ tag.value | uppercase }}
              </span>
            </mat-chip>
          </mat-chip-listbox>
        </div>
      </div>
      <!-- Tags -->

      <!-- Tool/Input -->
      <div class="activity-preview-tools-input d-flex-column flex-gap-8" *ngIf="showToolSection(vm)">
        <p class="tt9-categories tt9-color-neutral-500 pb-16">TOOL/INPUT</p>

        <ng-container *ngIf="vm.activity.importedFiles?.length">
          <ng-container *ngFor="let file of vm.activity.importedFiles; trackBy: trackById">
            <ng-container *ngTemplateOutlet="fileLink; context: { file }"></ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="vm.activity.presentationFile">
          <ng-container *ngTemplateOutlet="fileLink; context: { file: vm.activity.presentationFile }"></ng-container>
        </ng-container>

        <ng-template #fileLink let-file="file">
          <span class="file-link" (click)="downloadFile($event, file)">
            {{ file?.name }}
          </span>
        </ng-template>

        <ng-container *ngIf="vm.content && vm.activity.type === activityType.LiveShare">
          <ng-container *ngFor="let resource of vm.content; trackBy: trackById">
            <a [href]="resource" target="_blank" class="file-link">{{ resource }}</a>
          </ng-container>
        </ng-container>
      </div>
      <!-- Tool/Input -->
    </div>

    <div class="activity-preview-footer">
      <button class="tt9-btn activity-preview-action-btn" (click)="navigateToActivityClicked.emit()">
        Go To Activity
      </button>
    </div>
  </div>
</ng-container>
