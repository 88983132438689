<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading">
    <div class="session-tools">
      <button
        *ngIf="vm.teamMember?.isSessionLeader && vm.user.isAccenture"
        class="tt9-btn-icon-filled"
        matTooltipPosition="left"
        matTooltipClass="tt9-arrow-tooltip-right"
        [matTooltip]="tooltipTexts.editSession"
        (click)="openEditSessionDialog($event)"
      >
        <accenture-icon>icon-edit</accenture-icon>
      </button>

      <button
        *ngIf="vm.teamMember?.isSessionLeader"
        class="tt9-btn-icon-filled"
        matTooltipPosition="left"
        matTooltipClass="tt9-arrow-tooltip-right"
        [matTooltip]="tooltipTexts.manageSessionTeam"
        (click)="navigateToSessionTeam($event)"
      >
        <accenture-icon>icon-people-group</accenture-icon>
      </button>

      <div
        matTooltipPosition="left"
        matTooltipClass="tt9-arrow-tooltip-right"
        [matTooltip]="vm.isAnyActivityAttributes ? tooltipTexts.manageAttributes : tooltipTexts.noAttributesToManage"
      >
        <button
          class="tt9-btn-icon-filled"
          [disabled]="!vm.isAnyActivityAttributes"
          (click)="openAttributesEditor($event)"
        >
          <accenture-icon>icon-label-outline</accenture-icon>
        </button>
      </div>

      <button
        *ngIf="vm.teamMember?.isSessionLeader && vm.user.isTemplateCreator"
        #optionsMenuTrigger="matMenuTrigger"
        class="tt9-btn-icon-filled"
        matTooltipPosition="left"
        matTooltipClass="tt9-arrow-tooltip-right"
        [matTooltip]="tooltipTexts.collectionSettings"
        [matMenuTriggerFor]="optionsCollection"
        (menuOpened)="(optionsMenuTrigger.menuOpen)"
        (menuClosed)="(optionsMenuTrigger.menuOpen)"
      >
        <accenture-icon>icon-folder</accenture-icon>
      </button>

      <button
        *ngIf="vm.teamMember?.isSessionLeader && vm.user.isTemplateCreator"
        class="tt9-btn-icon-filled"
        matTooltipPosition="left"
        matTooltipClass="tt9-arrow-tooltip-right"
        [matTooltip]="tooltipTexts.saveSessionAsTemplate"
        (click)="saveAsTemplate($event)"
      >
        <accenture-icon>icon-save</accenture-icon>
      </button>

      <button
        *ngIf="vm.teamMember?.isSessionLeader"
        class="tt9-btn-icon-filled"
        matTooltipPosition="left"
        matTooltipClass="tt9-arrow-tooltip-right"
        [matTooltip]="tooltipTexts.exportSessionReport"
        (click)="exportReport($event)"
      >
        <accenture-icon>icon-file-upload</accenture-icon>
      </button>

      <accenture-ai-summarize-button *ngIf="vm.isSummarizeEnabled"></accenture-ai-summarize-button>

      <button
        *ngIf="vm.teamMember?.isSessionLeader"
        #optionsMenuTrigger="matMenuTrigger"
        class="tt9-btn-icon-filled"
        matTooltipPosition="left"
        matTooltipClass="tt9-arrow-tooltip-right"
        [matTooltip]="tooltipTexts.sessionOptions"
        [matMenuTriggerFor]="options"
        (menuOpened)="(optionsMenuTrigger.menuOpen)"
        (menuClosed)="(optionsMenuTrigger.menuOpen)"
      >
        <accenture-icon>icon-settings</accenture-icon>
      </button>

      <mat-menu class="tt9-menu" #options="matMenu" [xPosition]="'before'">
        <div class="session-setting-menu" (click)="$event.stopPropagation()">
          <mat-checkbox
            color="primary"
            class="tt9-checkbox mb-8"
            [checked]="!!vm.session?.displayAgenda"
            (change)="toggleDisplayAgenda($event.checked)"
          >
            Display agenda to participants
          </mat-checkbox>
          <mat-checkbox
            color="primary"
            class="tt9-checkbox mb-8"
            [disabled]="!vm.session?.displayAgenda"
            [checked]="!!vm.session?.selfNavigate"
            (change)="toggleSelfNavigate($event.checked)"
          >
            Allow participants to self-navigate
          </mat-checkbox>
          <mat-checkbox
            color="primary"
            class="tt9-checkbox mb-8"
            [checked]="!vm.session?.sessionThreadDisabled"
            (change)="updateSessionThreads(!$event.checked)"
          >
            Session thread
          </mat-checkbox>
          <mat-checkbox
            color="primary"
            class="tt9-checkbox"
            [disabled]="!!vm.session?.sessionThreadDisabled"
            [checked]="vm.session?.sessionThreadIsAnonymous"
            (change)="updateSessionThreadIsAnonymous($event.checked)"
          >
            Make thread anonymous
          </mat-checkbox>
        </div>
      </mat-menu>

      <mat-menu class="tt9-menu" #optionsCollection="matMenu" [xPosition]="'before'">
        <div class="session-collection-menu" (click)="$event.stopPropagation()">
          <div class="session-collection-setting">
            <accenture-icon [colorType]="iconColor.Secondary" class="mr-4">icon-folder-in</accenture-icon>
            <p>Move to another collection</p>
          </div>
          <div class="session-collection-setting">
            <accenture-icon [colorType]="iconColor.Secondary" class="mr-4">icon-folder-out</accenture-icon>
            <p>Remove from collection</p>
          </div>
        </div>
      </mat-menu>

      <button
        *ngIf="vm.teamMember?.isSessionLeader && vm.user.isAccenture"
        class="tt9-btn-icon-filled"
        matTooltipPosition="left"
        matTooltipClass="tt9-arrow-tooltip-right"
        [matTooltip]="tooltipTexts.deleteSession"
        (click)="deleteSession($event, vm.session)"
      >
        <accenture-icon>icon-delete</accenture-icon>
      </button>

      <accenture-ai-make-notes-button *ngIf="vm.isMakeNotesEnabled"></accenture-ai-make-notes-button>

      <accenture-book-meeting-button *ngIf="vm.isBookMeetingAvailable"></accenture-book-meeting-button>
    </div>
  </ng-container>
</ng-container>
