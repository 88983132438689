<div class="tt9-btn-icon">
  <accenture-icon
    class="emoji-icon"
    (click)="onclickEmoji(true)"
    [matMenuTriggerFor]="emojiMenu"
    [ngClass]="{ highlighted: emojiOnClickValue }"
  >
    {{ emojiIcon }}
  </accenture-icon>
</div>
<mat-menu
  class="emoji-menu"
  #emojiMenu="matMenu"
  [yPosition]="emojiMenuPositionY"
  [xPosition]="emojiMenuPositionX"
  (closed)="onClose(true)"
>
  <div (click)="$event.stopPropagation()">
    <emoji-mart set="twitter" title="Pick your emoji…" [darkMode]="false" (emojiSelect)="addEmoji($event)" />
  </div>
</mat-menu>
