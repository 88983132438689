export const tooltipTexts = {
    // General
    focus: 'Move participants to the current page',
    manageAttributes: 'Manage attributes',
    seeAttributes: 'See attributes',
    noAttributesToManage: 'No attributes are used in the current session',
    connectionsSetUp: 'Set up connections between sessions',
    moveToActivityEditor: 'Go to activity editor',
    activitySettings: 'Activity settings',
    activityPreview: 'Open activity preview',
    prepareFile: 'Please wait until the file is prepared',
    copyInvite: 'Copy invite to clipboard',
    inviteDisabled: 'Invite access disabled',
    zoomIn: 'Zoom in',
    zoomOut: 'Zoom out',
    gotToHomePage: 'Go to Homepage',

    // Brainstorm
    select: 'Select multiple responses or comments to organize, merge, and group',
    cardView: 'Show responses as cards',
    columnView: 'Show responses grouped in columns',
    wordCloudView: 'Show responses in a word cloud',
    selectToEnable: 'Select at least one response to enable',
    selectAll: 'Select all responses from the current topic',
    unselectResponses: 'Unselect all the selected responses',
    unselectComments: 'Unselect all the selected comments',
    group: 'Group the selected responses',
    ungroup: 'Ungroup the selected responses',
    bookmark: 'Bookmark the selected responses',
    moveTo: 'Move the selected responses to another topic',
    mergeTo: 'Move the selected responses as comments to another response',
    deleteResponses: 'Delete the selected responses from the current topic',
    mergeToComments: 'Move the selected comments to another response',
    promote: 'Promote the selected comments to responses',
    deleteComments: 'Delete the selected comments from the current topic',
    cancel: 'Close "Select" mode',
    moveData: 'Move data to the destination activity',
    moveDataDisabled: 'Set up connection from this activity to enable it',
    aiAssist: 'AI Assistance',
    aiAssistDisabled: 'Specify responses to use AI Assistance',
    suggestGroups: 'Suggest the groups',
    autoGroup: 'Auto-group the responses',
    clearGroupings: 'Clear the groupings of the responses',
    analyzeResponses: 'Analyze the topic with ThinkGPT',
    suggestMerges: 'Suggest the merges',
    closeAiPanel: 'Close AI Concierge',
    aiSummary: 'Summarize Brainstorm Topics',

    // Quick Poll
    summary: 'Summary',
    showResultsBar: 'Show results in a bar chart',
    showResultsDonut: 'Show results in a donut chart',

    // Filter and Search
    search: 'Search',
    filter: 'Filter',
    sort: 'Sort',
    filterAndSort: 'Filter & Sort',
    sortProjects: 'Sort projects',
    sortSessions: 'Sort sessions',
    sortResponses: 'Sort responses',
    sortUsers: 'Sort users',
    filterUsers: 'Filter users',
    filterAllTags: 'View all tags',
    filterAllClients: 'View all clients',
    filterAllPhases: 'View all phases',
    filterAllSubPhases: 'View all sub-phases',
    sortDisabled: 'Sort is activated upon searching and/or applying filters',
    filterAndSortCollections: 'Filter and sort collections',
    filterAndSortSessions: 'Filter and sort sessions',

    //Ownership History
    ownershipHistory: 'Ownership History',

    //Vote
    priorActivity: 'Get ballot items from a prior activity',
    voteImport: 'Only excel files can be uploaded. Please specify ballot items in the first column of the first sheet.',

    //Vote
    jiraIssueImport: 'Import Vote Items from Jira',

    //Vote Summary
    moveToEnabled: 'Move data to the destination activity',
    moveToDisabled: 'Set up connection from this activity to enable it',
    summaryTableView: 'Show vote results in a table format',
    summaryBarViewEnabled: 'Show vote results in a bar chart',
    summaryBarViewDisabled: 'No criteria to be displayed in a bar chart',
    summaryDonutViewEnabled: 'Show vote results in a donut chart',
    summaryDonutViewDisabled: 'No criteria to be displayed in a donut chart',
    summaryBubbleViewEnabled: 'Show vote results in a bubble chart',
    summaryBubbleViewDisabled: 'No criteria to be displayed in a bubble chart',
    summaryWordCloudViewEnabled: 'Show vote results in Word Cloud',
    summaryWordCloudViewDisabled: 'No criteria to be displayed in Word Cloud',
    options: 'Options',

    //Projects
    projectList: 'Show projects in a list',
    projectCards: 'Show projects as icons',
    projectConnections: 'Set up connections between sessions',
    editProject: 'Edit project information',
    manageProjectTeam: 'Manage project team',
    projectBulkInvite: 'Invite users to multiple sessions',
    saveProjectAsTemplate: 'Save project as template',
    deleteProject: 'Delete project',

    //Collections
    editCollection: 'Edit Collection',
    deleteCollection: 'Delete Collection',
    showAsList: 'Show collections in a list',
    showAsIcons: 'Show collections as icons',
    ungroupCollection: 'Ungroup Collection',
    collectionSettings: 'Collection settings',

    //Sessions
    sessionList: 'Show sessions in a list',
    sessionCards: 'Show sessions as icons',
    editSession: 'Edit session information',
    manageSessionTeam: 'Manage session team',
    sessionOptions: 'Session options',
    exportSessionReport: 'Export session report',
    saveSessionAsTemplate: 'Save session as template',
    deleteSession: 'Delete session',
    selectActivityTemplates: 'Select activity templates',
    generateActivityByAI: 'Generate activity using AI',
    aiTools: 'AI Tools',
    summarize: 'Summarize',
    generateFacilitionNotes: 'Generate facilitation notes',

    //Present
    showResponsesOnSlide: 'Pin responses on slides',
    downloadPresentation: 'Download presentation',

    //Tools panel
    expandCollapseResponses: 'Select the view of responses and comments',
    manageData: 'Manage data',

    //Profile
    editProfile: 'Edit Profile',

    // Templates
    filterTemplate: 'Filter templates',

    editProjectTemplate: 'Edit project template information',
    deleteProjectTemplate: 'Delete project template',

    editSessionTemplate: 'Edit session template information',
    deleteSessionTemplate: 'Delete session template',
    sessionTemplateOptions: 'Session template options',

    manageTemplatesTeam: 'Manage Template Team',

    //Table
    goBackToTablesList: 'Go back to the list of tables',
    agreeWithRow: 'Agree with row',
    agreeWithColumn: 'Agree with column',
    moveToTableSummary: 'Move everyone to summary. All the agreements and disagreements will be displayed on summary.',
    moveToTableEditor: 'Go to table editor',
    resetToSetup: 'Reset to Setup',

    //Table summary
    filterValidationResults: 'Filter validation results',
    numberOfAgreements: 'Number of users agreed with the response',
    numberOfDisagreements: 'Number of users disagreed with the response',
    numberOfAbstentions: 'Number of users abstained from validation',
    numberOfComments: 'Number of comments',
    changeCurrentResponse: 'Change the current response',
    changeCurrentResponseDisabled: 'Move everyone to summary to enable changing responses',

    //Guest chip
    loggedInAsGuest: 'You are logged in as a Guest. Your permissions are limited',

    //Prompt Library
    seePromptLibrary: 'See prompt library',

    //Concierge
    selectPrompt: 'Select a pre-configured prompt',
    embedFile: 'Add a reference document',
    clearConversation: 'Clear Conversation',
    resetMessage: 'Reset',
    sendMessage: 'Send',
    narrate: 'Narrate',
    quieten: 'Stop Talking',
};
