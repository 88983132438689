import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'accenture-base-dialog',
    templateUrl: './base-dialog.component.html',
    styleUrls: ['./base-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseDialogComponent {
    @Input() closeButtonVisible?: boolean = true;
    @Input() headerVisible?: boolean = true;
    @Input() title?: string;
    @Input() subTitle?: string;
    @Input() titleDescription?: string;
    @Input() noBorder?: boolean;
    @Output() dialogClosed = new EventEmitter<void>();

    constructor(public dialogRef: MatDialogRef<BaseDialogComponent>) {}

    closeDialog(): void {
        this.dialogClosed.emit();
        this.dialogRef.close();
    }
}
