import { ActivityType } from './activities';
import { ActivityItem, ActivityItemType, minColumnWidth, VoteActivityItemType } from './activity-items';
import { Dictionary } from './dictionary';
import { NodeTimestamp } from './node-timestamp';
import { FileType } from './responses';

export class Question extends NodeTimestamp implements ActivityItem {
    id: string;
    type: ActivityItemType;
    sequence: string;
    activityId?: string;
    sessionId?: string;
    sectionId?: string;
    label: Dictionary<string>;
    description?: string;
    voteType?: VoteActivityItemType;
    required: Dictionary<boolean>;
    visible: Dictionary<boolean>;
    bookmarked?: boolean;
    text?: string;
    options?: Dictionary<any>;
    choices?: Dictionary<any>;
    questions?: Question[];
    dndDisabled?: boolean;
    width?: number;
    isResizing?: boolean;
    image?: FileType;

    constructor(question: Partial<Question>) {
        super(question);

        this.id = question.id ?? '';
        this.type = question.type ?? ActivityItemType.Unknown;
        this.text = question.text ?? '';
        this.sequence = question.sequence ?? '';
        this.activityId = question.activityId ?? '';
        this.sessionId = question.sessionId ?? '';
        this.sectionId = question.sectionId || '';
        this.label = question.label ?? { default: '' };
        this.description = question.description ?? '';
        this.required = question.required ?? { default: false };
        this.visible = question.visible ?? { default: true };
        this.bookmarked = question.bookmarked ?? false;
        this.dndDisabled = question.dndDisabled ?? false;
        this.width = question.width ?? minColumnWidth;
        this.isResizing = question.isResizing ?? false;
        this.image = question.image ?? ({} as FileType);

        if (question.voteType) {
            this.voteType = question.voteType;
        }
    }

    createSerializableObject(): Record<string, unknown> {
        const result = {
            type: this.type,
            text: this.text,
            sequence: this.sequence,
            activityId: this.activityId,
            sessionId: this.sessionId,
            sectionId: this.sectionId,
            created: this.created,
            updated: this.updated,
            label: this.label,
            description: this.description,
            required: this.required,
            visible: this.visible,
            bookmarked: this.bookmarked,
            width: this.width,
        } as Record<string, unknown>;
        if (this.voteType) {
            result['voteType'] = this.voteType;
        }
        return result;
    }
}

export const defaultQuestionTypes = {
    [ActivityType.Vote]: {
        [VoteActivityItemType.VoteItem]: ActivityItemType.Text,
        [VoteActivityItemType.Parameter]: ActivityItemType.Slider,
    },
    [ActivityType.Brainstorm]: ActivityItemType.BrainstormTopic,
    [ActivityType.Present]: ActivityItemType.Unknown,
    [ActivityType.QuickPoll]: ActivityItemType.PollQuestion,
    [ActivityType.Table]: ActivityItemType.Table,
    [ActivityType.LiveShare]: ActivityItemType.LiveShareResource,
};

export const defaultQuestionLabels = {
    [ActivityType.Vote]: {
        [VoteActivityItemType.VoteItem]: 'New Ballot item',
        [VoteActivityItemType.Parameter]: 'New Vote criteria',
    },
    [ActivityType.Brainstorm]: 'New Topic',
    [ActivityType.Present]: 'Unknown',
    [ActivityType.Table]: 'New Table',
    [ActivityType.LiveShare]: 'New Resource',
};

export const noResponseTypes = [
    ActivityItemType.Section,
    ActivityItemType.Attachment,
    ActivityItemType.Image,
    ActivityItemType.Video,
];

export const mediaTypes = [ActivityItemType.Image, ActivityItemType.Video];

export const optionsTypes = [ActivityItemType.SingleSelect, ActivityItemType.MultiSelect];

export class QuestionWithReadableResponse extends Question {
    readableSequence?: string;

    constructor(question: Partial<QuestionWithReadableResponse>) {
        super(question);

        this.readableSequence = question.readableSequence ?? '';
    }
}
