import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { AIGenerateService } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUser, selectRouterParams } from '@accenture/global-store';
import {
    aiBuilderCloseText,
    aiBuilderReturnText,
    aiGenerationErrorTitle,
    aiGenerationProjectSaveErrorTitle,
    aiGenerationSessionSaveErrorTitle,
    BotActions,
    BuilderStep,
    errorSnackbarText,
    projectGenerationDoneSnackbarTitle,
    sessionGenerationDoneSnackbarTitle,
} from '@accenture/shared/data';
import {
    ConfirmationDialogComponent,
    DialogService,
    ProjectBluePrint,
    SessionBluePrint,
    SnackbarService,
} from '@accenture/shared/ui';

import { SelectSourceTypeDialogComponent } from '../select-source-type-dialog/select-source-type-dialog.component';
import { AiGenerateModalComponent } from './ai-generate-modal.component';

export interface AiGenerateModel {
    currentStep: BuilderStep;
    isLoading: boolean;
    previewData: ProjectBluePrint | null;
}

const defaultViewModel: AiGenerateModel = {
    currentStep: BuilderStep.Entry,
    isLoading: false,
    previewData: null,
};

@Injectable()
export class AiGenerateModalFacade {
    private isLoading$ = new BehaviorSubject<boolean>(false);
    private currentStep$ = new BehaviorSubject<BuilderStep>(BuilderStep.Entry);
    private previewData$ = new BehaviorSubject<ProjectBluePrint | null>(null);

    userId!: string;
    projectId!: string;

    vm$ = this.buildViewModel();

    constructor(
        private aiGenerateService: AIGenerateService,
        private store: Store<AppState>,
        private dialogRef: MatDialogRef<AiGenerateModalComponent>,
        private dialogService: DialogService,
        private snackbarService: SnackbarService,
        private router: Router,
    ) {}

    // function that handles project generation based on user input
    async generate(prompt: string, botAction: BotActions): Promise<void> {
        const snackbarTitle
            = botAction === BotActions.CreateProject
                ? projectGenerationDoneSnackbarTitle
                : botAction === BotActions.CreateSession
                ? sessionGenerationDoneSnackbarTitle
                : '';
        this.isLoading$.next(true);
        try {
            const { projectId, userId } = this;

            // Get generated data (without saving)
            const { notification, sessions, project } = await this.aiGenerateService.aiGenerateData<
                ProjectBluePrint,
                SessionBluePrint
            >(prompt, botAction, projectId, userId);
            if ((!project || !Object.keys(project || {}).length) && (!sessions || !sessions.length)) {
                throw new Error();
            }

            const data = project ? project : ({ sessions } as ProjectBluePrint);

            this.previewData$.next(data);

            if (notification) {
                this.snackbarService.showSuccessSnackBar(snackbarTitle, notification);
            }

            this.moveToStep(BuilderStep.Preview);
        } catch (e) {
            this.snackbarService.showErrorSnackBar(aiGenerationErrorTitle, errorSnackbarText);
        } finally {
            this.isLoading$.next(false);
        }
    }

    async saveData(botAction: BotActions): Promise<void> {
        this.isLoading$.next(true);

        try {
            const previewData = this.previewData$.getValue();
            const dataToSave
                = botAction === BotActions.CreateSession ? (previewData?.sessions as SessionBluePrint[]) : previewData;

            // Save generated data
            const { notification, projectId } = await this.aiGenerateService.aiSaveGeneratedData<
                ProjectBluePrint | SessionBluePrint[]
            >(dataToSave, botAction, this.projectId, this.userId);

            if (notification) {
                this.snackbarService.showSuccessSnackBar(notification.title, notification.message);
            }

            if (botAction === BotActions.CreateProject && !!projectId) {
                // the user is navigated to the newly generated project
                this.router.navigate([`/project/${projectId}`]);
            }
        } catch (e) {
            const title
                = botAction === BotActions.CreateProject
                    ? aiGenerationProjectSaveErrorTitle
                    : aiGenerationSessionSaveErrorTitle;
            this.snackbarService.showErrorSnackBar(title, errorSnackbarText);
        } finally {
            this.isLoading$.next(false);
            this.closeDialog();
        }
    }

    moveToStep(step: BuilderStep): void {
        this.currentStep$.next(step);
    }

    moveToEntryStep(botAction: BotActions): void {
        const type = botAction == BotActions.CreateSession ? 'sessions' : 'project';
        this.dialogService.open(ConfirmationDialogComponent, {
            title: aiBuilderReturnText.title,
            confirmBtnText: 'Return',
            cancelBtnText: 'Cancel',
            width: '444px',
            text: aiBuilderReturnText.text.replace('{type}', type),
            isWarning: true,
            confirm: async () => {
                this.moveToStep(BuilderStep.Entry);
            },
        });
    }

    openConfirmationToCloseDialog(botAction: BotActions): void {
        const type = botAction == BotActions.CreateSession ? 'sessions' : 'project';
        this.dialogService.open(ConfirmationDialogComponent, {
            title: aiBuilderCloseText.title,
            confirmBtnText: 'Close',
            cancelBtnText: 'Cancel',
            width: '444px',
            text: aiBuilderCloseText.text.replace('{type}', type),
            isWarning: true,
            confirm: async () => {
                this.closeDialog();
            },
        });
    }

    closeDialogWithConfirmation(isPreviewStep?: boolean, botAction?: BotActions): void {
        if (isPreviewStep) {
            this.openConfirmationToCloseDialog(botAction);
        } else {
            this.closeDialog();
        }
    }

    backToPreviousModal(): void {
        this.closeDialog();
        this.dialogService.open(SelectSourceTypeDialogComponent, {
            panelClass: 'tt9-modal',
            width: '768px',
        });
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    private buildViewModel(): Observable<AiGenerateModel> {
        return combineLatest([
            this.store.select(selectAuthenticatedUser),
            this.isLoading$.asObservable().pipe(distinctUntilChanged()),
            this.currentStep$.asObservable().pipe(distinctUntilChanged()),
            this.previewData$.asObservable().pipe(distinctUntilChanged()),
            this.store.select(selectRouterParams),
        ]).pipe(
            map(([user, isLoading, currentStep, previewData, queryParams]) => {
                this.userId = user.id;
                this.projectId = queryParams?.projectId;
                return { currentStep, previewData, isLoading };
            }),
            startWith(defaultViewModel),
        );
    }
}
