import { groupBy, mapValues } from 'lodash';

import { getRandomNumber } from '@accenture/shared/util';

import { Dictionary } from './dictionary';
import { NodeTimestamp } from './node-timestamp';

export enum GroupColors {
    Red = '#F54F2D',
    Orange = '#FE8535',
    Yellow = '#FFBA00',
    Blue = '#146BD0',
    LightBlue = '#40AEF2',
    Green = '#27AE60',
    LightGreen = '#6FC32B',
    Teal = '#01C5C4',
    Violet = '#5348CE',
    Purple = '#DD75EC',
}

export const displayGroupColorsName: Dictionary<string> = {
    [GroupColors.Red]: 'Red',
    [GroupColors.Orange]: 'Orange',
    [GroupColors.Yellow]: 'Yellow',
    [GroupColors.Blue]: 'Blue',
    [GroupColors.LightBlue]: 'Light Blue',
    [GroupColors.Green]: 'Green',
    [GroupColors.LightGreen]: 'Light Green',
    [GroupColors.Teal]: 'Teal',
    [GroupColors.Violet]: 'Violet',
    [GroupColors.Purple]: 'Purple',
};

// let number of colors for groups is N
// for each N groups colors should not repeat when created
// when setting the group color manually - all groups are available
export const getAvailableGroupColors = (groups: ActivityGroup[]): string[] => {
    const colorsArray = Object.values(GroupColors);
    const numberOfColorsFullyUsed = Math.floor(groups.length / colorsArray.length);
    const colorsUsageMap = mapValues(groupBy(groups, 'color'), groups => groups.length);
    return colorsArray.filter(color => (colorsUsageMap[color] || 0) <= numberOfColorsFullyUsed);
};

export const getRandomGroupColor = (availableColors?: string[]): string => {
    const colors = availableColors?.length ? availableColors : Object.values(GroupColors);
    return colors[getRandomNumber(colors.length - 1, 0)];
};

export const ungroupedId = 'ungrouped';
export const ungroupedGroup = {
    name: 'Ungrouped',
    id: ungroupedId,
    color: '#D9D9DD',
} as ActivityGroup;

export class ActivityGroup extends NodeTimestamp {
    id?: string;
    sequence: string;
    name: string;
    activityId: string;
    numberOfResponses?: Dictionary<number>;
    sessionId?: string;
    activityItemId?: string;
    description?: string;
    color?: string;

    constructor(data: Partial<ActivityGroup>) {
        super(data);

        this.id = data.id ?? '';
        this.sequence = data.sequence ?? '';
        this.name = data.name ?? '';
        this.activityId = data.activityId ?? '';
        this.sessionId = data.sessionId ?? '';
        this.activityItemId = data.activityItemId ?? '';
        this.description = data.description ?? '';
        this.color = data.color ?? '';
        this.numberOfResponses = data.numberOfResponses ?? {};
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            id: this.id,
            sequence: this.sequence,
            name: this.name,
            activityId: this.activityId,
            activityItemId: this.activityItemId,
            description: this.description,
            color: this.color,
            sessionId: this.sessionId,
            numberOfResponses: this.numberOfResponses,
        };
    }
}
