<div *ngIf="vm$ | async as vm" class="header-container" [class.login]="isLogin">
  <div class="header-container-wrapper">
    <mat-toolbar class="toolbar d-flex-between">
      <ng-container *ngIf="projectAndSessionInfo$ | async as projectAndSessionInfo">
        <div class="d-flex">
          <!-- back button for mobile -->
          <button
            *ngIf="vm.isMobileScreen"
            class="button-back d-flex-center"
            [ngClass]="{ 'hidden-all-handsets': !vm.isActivityPage }"
            (click)="navigateBack()"
          >
            <accenture-icon class="tt9-color-neutral-500"> icon-chevron-left </accenture-icon>
          </button>
          <!-- /back button for mobile -->

          <h3 *ngIf="!vm.isMobileScreen" class="h3 text-accent home-navigation" (click)="vm.user && setDashboard()">
            ThinkTank 9.0
          </h3>

          <accenture-breadcrumbs *ngIf="!vm.isMobileScreen" />
        </div>
      </ng-container>

      <div class="d-flex">
        <ng-container *ngIf="vm.user">
          <ul class="tabs">
            <li class="tab" [class.active]="isDashboard(vm.tab)" (click)="setDashboard()">
              <accenture-icon class="tab-icon"> icon-home </accenture-icon>
              <span class="tt9-body-2">Home</span>
            </li>
            <li class="tab" [class.active]="isTemplates(vm.tab)" (click)="setTemplates()">
              <accenture-icon class="tab-icon"> icon-template </accenture-icon>
              <span class="tt9-body-2">Templates</span>
            </li>
            <li class="tab" [class.active]="isProjects(vm.tab)" (click)="setProjects()">
              <accenture-icon class="tab-icon"> icon-folder </accenture-icon>
              <span class="tt9-body-2">Projects</span>
            </li>
          </ul>

          <!-- user menu -->
          <ng-container *ngIf="vm.dbUser">
            <div class="user-menu" [matMenuTriggerFor]="userMenu" (click)="menuOpen = !menuOpen">
              <div class="tt9-userpic">
                <img *ngIf="vm.dbUser.imageUrl" [src]="vm.dbUser.imageUrl" [alt]="vm.dbUser.displayName" />
                <ng-container *ngIf="!vm.dbUser.imageUrl">{{ vm.dbUser.displayName | userInitials }}</ng-container>
              </div>
              <span class="tt9-body-2 user-menu-name ml-4 visible-medium-large-desktop">{{
                vm.dbUser.displayName
              }}</span>
              <div
                *ngIf="vm.dbUser.guest"
                class="guest-chip tt9-caption-3"
                matTooltipClass="tt9-arrow-tooltip-above"
                [matTooltip]="loggedInAsGuestTooltipText"
                (click)="openGuestNotification($event)"
              >
                Guest
              </div>
              <accenture-icon class="user-menu-icon" [size]="iconSmallSize">{{
                menuOpen ? "icon-chevron-up-small" : "icon-chevron-down-small"
              }}</accenture-icon>
            </div>
            <mat-menu #userMenu="matMenu" class="tt9-menu" (closed)="menuOpen = false">
              <button mat-menu-item [routerLink]="['/profile']">Profile</button>
              <button mat-menu-item *ngIf="vm.dbUser?.roles?.admin" [routerLink]="['/admin/users']">
                Administration
              </button>
              <ng-container *ngIf="vm.isMobileScreen && vm.helpMenuAvailable">
                <button mat-menu-item (click)="openContactSupportDialog()">
                  <accenture-icon>icon-send-outline</accenture-icon>
                  Contact Support
                </button>
                <a [href]="accessSupportUrl" target="_blank" mat-menu-item class="menu-item-link">
                  <accenture-icon>icon-book-open</accenture-icon>
                  View Guides
                </a>
                <a [href]="viewGuidesUrl" target="_blank" mat-menu-item class="menu-item-link">
                  <accenture-icon>icon-help</accenture-icon>
                  Explore Help Center
                </a>
              </ng-container>
              <button mat-menu-item (click)="logout()">Logout</button>
            </mat-menu>
          </ng-container>
          <!-- /user menu -->

          <!-- in-app notifications -->
          <button
            class="tt9-btn-icon-filled btn-rounded tab-like-btn active accenture-disable-outside-click"
            [matBadge]="vm.notificationsCount"
            [matBadgeHidden]="!vm.notificationsCount"
            (click)="notificationsPanelOpened = true"
          >
            <accenture-icon>icon-bell</accenture-icon>
          </button>
          <!-- /in-app notifications -->

          <!-- help menu -->
          <button
            *ngIf="!vm.isMobileScreen"
            class="help-center-icon tt9-btn-icon-filled btn-rounded active ml-8"
            [matMenuTriggerFor]="vm.helpMenuAvailable ? helpMenu : null"
            (click)="toggleHelpMenu(vm.helpMenuAvailable)"
          >
            <accenture-icon>icon-question</accenture-icon>
          </button>
          <mat-menu #helpMenu="matMenu" class="tt9-menu" (closed)="helpMenuOpen = false">
            <a [href]="viewGuidesUrl" target="_blank" mat-menu-item class="menu-item-link">
              <accenture-icon>icon-book-open</accenture-icon>
              View Guides
            </a>
            <a [href]="accessSupportUrl" target="_blank" mat-menu-item class="menu-item-link">
              <accenture-icon>icon-help</accenture-icon>
              Explore Help Center
            </a>
            <button mat-menu-item (click)="openContactSupportDialog()">
              <accenture-icon>icon-send-outline</accenture-icon>
              Contact Support
            </button>
            <a (click)="openTermsOfUse()" mat-menu-item class="menu-item-link">
              <accenture-icon>icon-terms-of-use</accenture-icon>
              Terms Of Use
            </a>
          </mat-menu>
          <!-- /help menu -->

          <!-- ai concierge -->
          <div *ngIf="!!vm.dbUser && !!vm.showAiConcierge">
            <button
              *ngIf="!vm.screenHandset"
              class="tt9-btn-icon-filled btn-rounded active ml-8"
              (click)="toggleSidebar()"
            >
              <accenture-icon>icon-concierge-figma</accenture-icon>
            </button>
            <div *ngIf="!!vm.screenHandset">
              <accenture-bot-chat-recorder />
            </div>
          </div>
          <!-- /ai concierge -->
        </ng-container>

        <ng-container *ngIf="!vm.user">
          <ul class="tabs">
            <li class="tab" [class.active]="isLoginTab(vm.authenticationTab)" (click)="setLogin()">
              <accenture-icon class="tab-icon"> icon-log-in </accenture-icon>
              <span class="tt9-body-2">Log in</span>
            </li>
            <li class="tab" *ngIf="vm.showSignUp" [class.active]="isSingUp(vm.authenticationTab)" (click)="setSingUp()">
              <accenture-icon class="tab-icon"> icon-user-check </accenture-icon>
              <span class="tt9-body-2">Sign up</span>
            </li>
          </ul>
        </ng-container>
      </div>
    </mat-toolbar>
  </div>

  <accenture-side-panel
    *ngIf="notificationsPanelOpened"
    #origin="cdkOverlayOrigin"
    cdkOverlayOrigin
    panelTitle="Notifications"
    [panelTrigger]="origin"
    [panelOpened]="notificationsPanelOpened"
    (closePanel)="notificationsPanelOpened = false"
  >
    <ng-container header-actions>
      <button
        *ngIf="vm.totalNotificationsCount"
        class="tt9-btn-icon-filled mr-8"
        matTooltipPosition="below"
        matTooltipClass="tt9-arrow-tooltip-above"
        matTooltip="Delete all notifications"
        (click)="deleteAllNotifications()"
      >
        <accenture-icon>icon-delete-all</accenture-icon>
      </button>
      <button
        *ngIf="vm.totalNotificationsCount"
        class="tt9-btn-icon-filled mr-8"
        matTooltipPosition="below"
        matTooltipClass="tt9-arrow-tooltip-above"
        matTooltip="Mark all as read"
        (click)="markAllNotificationsAsRead()"
      >
        <accenture-icon>icon-double-check</accenture-icon>
      </button>
    </ng-container>
    <accenture-in-app-notifications (closePanel)="notificationsPanelOpened = false"></accenture-in-app-notifications>
  </accenture-side-panel>
</div>
