<accenture-base-snack-bar
  [title]="title"
  [iconClass]="data.iconClass"
  [iconName]="data.iconName"
  (snackbarClosed)="close()"
>
  <p class="tt9-body-2">{{ message }}</p>

  <div class="d-flex mt-8">
    <div class="spacer"></div>
    <button class="tt9-snack-bar-action-btn-notification" (click)="viewDetails()">View Details</button>
  </div>
</accenture-base-snack-bar>
