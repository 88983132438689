import { Dictionary } from '../dictionary';
import { NodeTemplate, NodeType } from '../node-template';
import { FileType } from '../responses';
import { UpdatedByUser } from '../user';
import { Activity } from './activity';
import { ActivityType } from './activity-type';

export enum BrainstormGroupingType {
    Activity = 'ACTIVITY',
    Topic = 'TOPIC',
}

export const DisplayBrainstormGroupingType = {
    [BrainstormGroupingType.Activity]: 'Entire Activity',
    [BrainstormGroupingType.Topic]: 'Individual Topic',
};

export const brainstormGrouping = [
    { name: 'Entire Activity', value: BrainstormGroupingType.Activity },
    { name: 'Individual Topics', value: BrainstormGroupingType.Topic },
];

// TODO: Will be refactored in TT9-4065
export enum BrainstormTab {
    Responses = 'responses',
}

export enum BrainstormContentTab {
    Topics = 'Topics',
    Groups = 'Groups',
}

export enum BrainstormViewType {
    Responses = 'responses',
    GroupedResponses = 'groupedResponses',
    WordCloud = 'wordCloud',
}
export enum BrainstormViewMode {
    Select = 'select',
    AiAssist = 'ai-assist',
}

export class Brainstorm extends NodeTemplate implements Activity {
    type = ActivityType.Brainstorm;

    sessionId?: string;
    projectId?: string;
    templateId?: string;
    visible?: boolean;
    sequence: string;
    importedFiles?: FileType[];

    viewAll?: boolean;
    displayOnlyIndividualLikes?: boolean;
    sectionId?: string;
    showNameTags?: boolean;
    anonymous?: boolean;
    groupingType?: BrainstormGroupingType;
    creatorId?: string;
    creatorName?: string;
    creatorImage?: string;
    favoritesCount?: number;
    tags?: Dictionary<string>;
    isExpandResponses?: boolean;

    // for public activity templates
    timesUsed?: number;
    creatorNotes?: string;
    updatedBy?: UpdatedByUser;

    dndDisabled?: boolean;

    constructor(id: string | undefined, activity: Partial<Brainstorm>) {
        super(id, activity, NodeType.Activity);

        this.projectId = activity.projectId ?? '';
        this.sessionId = activity.sessionId ?? '';
        this.templateId = activity.templateId ?? '';
        this.visible = activity.visible ?? false;
        this.sequence = activity.sequence ?? '';
        this.sectionId = activity.sectionId;
        this.importedFiles = activity.importedFiles ?? [];
        this.creatorId = activity.creatorId ?? '';
        this.creatorImage = activity.creatorImage ?? '';
        this.creatorName = activity.creatorName ?? '';
        this.favoritesCount = activity.favoritesCount ?? 0;
        this.isExpandResponses = activity.isExpandResponses ?? false;

        this.viewAll = activity.viewAll ?? true;
        this.displayOnlyIndividualLikes = activity.displayOnlyIndividualLikes ?? false;
        this.showNameTags = activity.showNameTags ?? false;
        this.anonymous = activity.anonymous ?? false;
        this.groupingType = activity.groupingType ?? BrainstormGroupingType.Activity;
        this.tags = (activity.tags as Dictionary<string>) ?? {};

        this.timesUsed = activity.timesUsed ?? 0;
        this.creatorNotes = activity.creatorNotes ?? '';
        this.updatedBy = activity.updatedBy ?? undefined;

        this.dndDisabled = !!activity.dndDisabled;
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            projectId: this.projectId,
            sessionId: this.sessionId,
            sectionId: this.sectionId,
            templateId: this.templateId,
            visible: this.visible,
            sequence: this.sequence,
            viewAll: this.viewAll,
            displayOnlyIndividualLikes: this.displayOnlyIndividualLikes,
            showNameTags: this.showNameTags,
            anonymous: this.anonymous,
            isExpandResponses: this.isExpandResponses,
            groupingType: this.groupingType,
            type: this.type,
            created: this.created,
            updated: this.updated,
            creatorId: this.creatorId,
            creatorImage: this.creatorImage,
            creatorName: this.creatorName,
            creatorNotes: this.creatorNotes,
            timesUsed: this.timesUsed,
            tags: this.tags,
        };
    }
}
