import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

import { SessionService, TeamMemberService } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectActivityIdAndParentIds, selectAuthenticatedUserId } from '@accenture/global-store';
import { ParentType, Session, TeamMember } from '@accenture/shared/data';
import { sortByDateDesc } from '@accenture/shared/util';

export interface InvitationFormViewModel {
    projectMemberEmails: string[];
    sessions: Session[];
    sessionIds: string[];
    isLoading: boolean;
}

const defaultViewModel = {
    projectMemberEmails: [],
    sessions: [],
    sessionIds: [],
    isLoading: true,
};

@Injectable()
export class InvitationFormBulkInviteFacade {
    vm$ = this.buildViewModel();

    constructor(
        private store: Store<AppState>,
        private teamMemberService: TeamMemberService,
        private sessionService: SessionService,
    ) {}

    private buildViewModel(): Observable<InvitationFormViewModel> {
        return combineLatest([
            this.store.select(selectAuthenticatedUserId),
            this.store.select(selectActivityIdAndParentIds),
        ]).pipe(
            switchMap(([userId, { projectId, parentType }]) => {
                if (!userId) {
                    return of({
                        ...defaultViewModel,
                        isLoading: false,
                    });
                }

                return combineLatest([
                    this.getProjectActiveTeamMembersEmails(projectId),
                    this.getSortedSessions(parentType, projectId),
                ]).pipe(
                    map(([projectMemberEmails, sessions]) => {
                        return {
                            projectMemberEmails,
                            sessions,
                            sessionIds: sessions.map(({ id }) => id),
                            isLoading: false,
                        };
                    }),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    private getProjectActiveTeamMembersEmails(projectId: string): Observable<string[]> {
        return this.teamMemberService.getProjectTeamMembers(projectId).pipe(
            map((teamMembers: TeamMember[]) => {
                return teamMembers.map(user => user.email);
            }),
        );
    }

    private getSortedSessions(parentType: ParentType, projectId: string): Observable<Session[]> {
        return this.sessionService
            .getAvailableSessions(parentType, projectId)
            .pipe(map(sessions => sessions.sort(sortByDateDesc('updated'))));
    }
}
