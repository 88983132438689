<div class="chat-bot-container d-flex-column" *ngIf="vm$ | async as vm">
  <div class="chat-bot-header">
    <h2>{{ botName }}</h2>
  </div>

  <kendo-chat
    class="kendo-chat-box"
    #aiChat
    messageBoxType="textarea"
    [messages]="vm.conversation"
    [user]="chatUser"
    (sendMessage)="sendMessage($event)"
    (executeAction)="inlineAction($event)"
  >
    <ng-template kendoChatMessageTemplate let-message>
      <div class="chat-message d-flex">
        <div class="chat-message-text" [innerHTML]="renderMarkdown(message.text)"></div>

        <accenture-icon
          *ngIf="!!message.type"
          class="navigation-icon navigation-hover-icon message-options-icon"
          [matMenuTriggerFor]="messageActions"
          >icon-more</accenture-icon
        >
        <mat-menu class="tt9-menu" #messageActions="matMenu">
          <button
            *ngIf="message.action === botActions.GenerateProject"
            mat-menu-item
            (click)="runCommand(botActions.GenerateProject, message.id)"
          >
            Generate Again
          </button>
          <button
            *ngIf="message.action === botActions.GenerateProject"
            mat-menu-item
            (click)="runCommand(botActions.CreateProject, message.id)"
          >
            Create Project
          </button>
          <button
            *ngIf="message.action === botActions.GenerateSession"
            mat-menu-item
            (click)="runCommand(botActions.GenerateSession, message.id)"
          >
            Generate Again
          </button>
          <button
            *ngIf="message.action === botActions.GenerateSession"
            mat-menu-item
            (click)="runCommand(botActions.CreateSession, message.id)"
          >
            Create Session
          </button>
          <button
            *ngIf="message.action === botActions.GenerateActivity"
            mat-menu-item
            (click)="runCommand(botActions.GenerateActivity, message.id)"
          >
            Generate Again
          </button>
          <button
            *ngIf="message.action === botActions.GenerateActivity"
            mat-menu-item
            (click)="runCommand(botActions.CreateActivity, message.id)"
          >
            Create Activity
          </button>
          <button *ngIf="message.type === exchangeTypes.Bot" [matMenuTriggerFor]="promptActions" mat-menu-item>
            Use to...
          </button>
          <button
            *ngIf="message.type !== exchangeTypes.User && !message.action"
            mat-menu-item
            (click)="editMessage(message)"
          >
            Edit
          </button>
          <button *ngIf="message.type !== exchangeTypes.User" mat-menu-item (click)="clipboardCopy(message.text)">
            Copy to Clipboard
          </button>
          <button *ngIf="message.type !== exchangeTypes.User && !speaking" mat-menu-item (click)="speak(message.text)">
            Say It!
          </button>
          <button *ngIf="message.type === exchangeTypes.User" mat-menu-item (click)="sendMessage(message.text)">
            Ask Again
          </button>
          <button *ngIf="message.type === exchangeTypes.User" mat-menu-item (click)="useAsPrompt(message.text)">
            Revise
          </button>
          <button mat-menu-item (click)="deleteMessage(message.id)">Delete Message</button>
        </mat-menu>
        <mat-menu class="tt9-menu" #promptActions="matMenu">
          <button mat-menu-item (click)="runPrompt(botActions.GenerateProject, message.text)">
            Generate a Project
          </button>
          <button
            *ngIf="!!vm.context.projectId"
            mat-menu-item
            (click)="runPrompt(botActions.GenerateSession, message.text)"
          >
            Generate a Session
          </button>
          <button
            *ngIf="!!vm.context.projectId && !!vm.context.sessionId"
            mat-menu-item
            [matMenuTriggerFor]="generateActivity"
          >
            Generate an Activity
          </button>
          <button
            *ngIf="!!vm.context.projectId && !!vm.context.sessionId && vm.activityType === activityType.Brainstorm"
            mat-menu-item
            (click)="runPrompt(botActions.DataFlow, message.text)"
          >
            Create Additional Topics
          </button>
          <button
            *ngIf="!!vm.context.projectId && !!vm.context.sessionId && vm.activityType === activityType.Vote"
            mat-menu-item
            (click)="runPrompt(botActions.DataFlow, message.text)"
          >
            Create Additional Ballot Items
          </button>
          <button
            *ngIf="!!vm.context.projectId && !!vm.context.sessionId && vm.activityType === activityType.QuickPoll"
            mat-menu-item
            (click)="runPrompt(botActions.DataFlow, message.text)"
          >
            Create Additional Choices
          </button>
          <button
            *ngIf="!!vm.context.projectId && !!vm.context.sessionId && vm.activityType === activityType.Table"
            mat-menu-item
            (click)="runPrompt(botActions.DataFlow, message.text)"
          >
            Create a Data table
          </button>
          <button *ngIf="!!vm.context.sessionId" mat-menu-item (click)="runPrompt(botActions.ThreadPost, message.text)">
            Send to Session Thread
          </button>
        </mat-menu>
        <mat-menu class="tt9-menu" #generateActivity="matMenu">
          <button mat-menu-item (click)="runPrompt(botActions.GenerateActivity, message.text, activityType.Brainstorm)">
            Brainstorm
          </button>
          <button mat-menu-item (click)="runPrompt(botActions.GenerateActivity, message.text, activityType.Vote)">
            Vote
          </button>
          <button mat-menu-item (click)="runPrompt(botActions.GenerateActivity, message.text, activityType.QuickPoll)">
            Quick Poll
          </button>
          <button mat-menu-item (click)="runPrompt(botActions.GenerateActivity, message.text, activityType.Present)">
            Presentation
          </button>
          <button mat-menu-item (click)="runPrompt(botActions.GenerateActivity, message.text, activityType.Table)">
            Table
          </button>
        </mat-menu>
      </div>
    </ng-template>

    <ng-template kendoChatMessageBoxTemplate>
      <kendo-textarea
        #messageEntry
        resizable="none"
        [placeholder]="promptMessage"
        (inputFocus)="onFocus()"
        (inputBlur)="onBlur()"
        (keydown)="onKeyDown($event)"
      >
        <kendo-textarea-suffix class="message-actions d-flex-center">
          <button
            class="tt9-btn-icon"
            matTooltipPosition="above"
            matTooltipClass="tt9-arrow-tooltip-below"
            [matTooltip]="tooltipTexts.selectPrompt"
            (click)="openPromptLibrary()"
          >
            <accenture-icon>icon-chat</accenture-icon>
          </button>
          <input
            #uploadInput
            class="file-upload"
            type="file"
            accept=".txt, .pdf, .docx, .xlsx, .mp3, .png"
            (change)="uploadFile($event)"
          />
          <button
            class="tt9-btn-icon"
            matTooltipPosition="above"
            matTooltipClass="tt9-arrow-tooltip-below"
            [matTooltip]="tooltipTexts.embedFile"
            (click)="uploadInput.click()"
          >
            <accenture-icon>icon-attachment</accenture-icon>
          </button>
          <button
            class="tt9-btn-icon"
            matTooltipPosition="above"
            matTooltipClass="tt9-arrow-tooltip-below"
            [matTooltip]="tooltipTexts.clearConversation"
            (click)="clearChat()"
          >
            <accenture-icon>icon-delete</accenture-icon>
          </button>
          <button
            *ngIf="speaking"
            class="tt9-btn-icon"
            matTooltipPosition="above"
            matTooltipClass="tt9-arrow-tooltip-below"
            [matTooltip]="tooltipTexts.quieten"
            (click)="quieten()"
          >
            <accenture-icon>icon-minus-circle</accenture-icon>
          </button>
          <span class="k-flex-1"></span>
          <button
            class="tt9-btn-icon"
            matTooltipPosition="above"
            matTooltipClass="tt9-arrow-tooltip-below"
            [matTooltip]="tooltipTexts.resetMessage"
            (click)="clearValue()"
          >
            <accenture-icon>icon-close</accenture-icon>
          </button>
          <kendo-input-separator class="k-text-base"></kendo-input-separator>
          <button
            class="tt9-btn-icon"
            matTooltipPosition="above"
            matTooltipClass="tt9-arrow-tooltip-below"
            [matTooltip]="tooltipTexts.sendMessage"
            (click)="sendMessage()"
          >
            <accenture-icon>icon-send-outline</accenture-icon>
          </button>
          <button class="tt9-btn-icon" (click)="record()">
            <accenture-icon
              *ngIf="!isRecording()"
              matTooltipPosition="above"
              matTooltipClass="tt9-arrow-tooltip-below"
              [matTooltip]="tooltipTexts.narrate"
              >icon-call-recording</accenture-icon
            >
            <accenture-icon *ngIf="isRecording()" class="recording">icon-power</accenture-icon>
          </button>
        </kendo-textarea-suffix>
      </kendo-textarea>
    </ng-template>
  </kendo-chat>
</div>
