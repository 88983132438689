import { Injectable } from '@angular/core';
import { Timestamp } from 'firebase/firestore';

import {
    SessionThreadMessageReaction,
    SessionThreadMessageReactionRender,
    SessionThreadMessageUserReaction,
    SessionThreadReactionType,
    SessionThreadReactionTypeIconsMap,
} from '@accenture/shared/data';
import { sortByDateAsc } from '@accenture/shared/util';

@Injectable({
    providedIn: 'root',
})
export class ReactionsService {
    public getReactions(reactions: SessionThreadMessageReaction): SessionThreadMessageReactionRender[] {
        const reactionsArray = Object.entries(SessionThreadReactionTypeIconsMap).reduce(
            (acc: SessionThreadMessageReactionRender[], [, value]: [SessionThreadReactionType, string | undefined]) => {
                return [
                    ...acc,
                    {
                        type: value,
                        count: this.getReactionCountByType(reactions, value),
                        userIds: this.getUserIdsByReactionType(reactions, value),
                        latestCreatedAt: this.getLatestTimestampByReactionType(reactions, value),
                    },
                ];
            },
            [],
        );

        return this.sortAndFilteredReactions(reactionsArray);
    }

    private sortAndFilteredReactions(
        reactions: SessionThreadMessageReactionRender[],
    ): SessionThreadMessageReactionRender[] {
        return reactions
            ?.filter((reaction) => reaction.count)
            .sort((a, b) => {
                const countDifference = b.count - a.count;
                if (countDifference !== 0) {
                    return countDifference;
                }
                // If counts are the same, compare by latestCreatedAt
                if (!a?.latestCreatedAt || !b?.latestCreatedAt) {
                    return 0;
                }
                return b?.latestCreatedAt.valueOf() > a?.latestCreatedAt.valueOf() ? -1 : 1;
            });
    }

    private getReactionCountByType(reactions: SessionThreadMessageReaction, type: string): number {
        return Object.values(reactions || {})?.filter(reaction => reaction?.type === type)?.length || 0;
    }

    private getUserIdsByReactionType(reactions: SessionThreadMessageReaction, type: string): string[] {
        return Object.keys(reactions || {})?.filter(userId => reactions?.[userId]?.type === type) || [];
    }

    private getLatestTimestampByReactionType(
        reactions: SessionThreadMessageReaction,
        type: string,
    ): Timestamp | string {
        const sortedUpdatedTimeStamps = Object.entries(reactions || {})
            ?.reduce((acc: SessionThreadMessageUserReaction[], [userId, userReaction]) => {
                if (reactions?.[userId]?.type === type) {
                    acc.push(userReaction);
                }
                return acc;
            }, [])
            ?.sort(sortByDateAsc('updated'));

        return (sortedUpdatedTimeStamps?.[0]?.updated as Timestamp) || ''; // only get the latest
    }
}
