import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, distinctUntilChanged, Observable, of, switchMap } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { SessionThreadsService } from '@accenture/activity/shared/domain';
import {
    CollectionsService,
    deleteSessionConfirmation,
    deleteSessionInfoSnackbar,
    FeatureToggleName,
    saveSessionAsTemplateConfirmation,
    saveSessionAsTemplateInfoSnackBarText,
    saveSessionAsTemplateSuccessSnackBarText,
    saveSessionExportReportErrorSnackBarTitle,
    saveSessionExportReportInfoSnackBar,
    saveSessionExportReportInfoSnackBarTitle,
    saveSessionExportReportSuccessSnackBar,
    saveSessionExportReportSuccessSnackBarTitle,
    saveSessionWithTableAsTemplateConfirmation,
    SessionService,
} from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    selectAuthenticatedUser,
    selectFeatureToggles,
    selectSessionData,
    selectSessionId,
    selectSessionTeamMemberData,
} from '@accenture/global-store';
import {
    Activity,
    ActivityType,
    CollectionSession,
    deletingSessionErrorSnackbarTitle,
    deletingSessionInProgressSnackbarTitle,
    errorMessageSnackbarText,
    ParentType,
    routerLinksMap,
    Session,
    sessionDeletedSnackbarTitle,
    sessionHasBeenDeletedSnackbarText,
    SessionThreadMessageType,
    TeamMember,
    templateCreatedSnackbarTitle,
    templateCreationErrorTitleSnackbarTitle,
    templateCreationInProgressSnackbarTitle,
    User,
} from '@accenture/shared/data';
import { ConfirmationDialogComponent, DialogService, SnackbarService, SnackBarTypes } from '@accenture/shared/ui';

import { AttributesEditorComponent } from '../attributes-editor/attributes-editor.component';
import { EditSessionDialogNewComponent } from '../edit-session-dialog-new/edit-session-dialog-new.component';

export interface SessionsEditorToolsPanelViewModel {
    session: Session;
    teamMember: TeamMember;
    user: User;
    isLoading: boolean;
    isSummarizeEnabled: boolean;
    isMakeNotesEnabled: boolean;
    isBookMeetingAvailable: boolean;
}

const initialState: SessionsEditorToolsPanelViewModel = {
    session: {} as Session,
    teamMember: {} as TeamMember,
    user: {} as User,
    isLoading: true,
    isSummarizeEnabled: false,
    isMakeNotesEnabled: false,
    isBookMeetingAvailable: false,
};

@Injectable()
export class SessionsEditorToolsPanelFacade {
    private isLoading$ = new BehaviorSubject<boolean>(false);

    vm$ = this.buildViewModel();

    private projectId!: string;
    private sessionId!: string;
    private sessionName!: string;
    private activities!: Activity[];
    private user!: User;
    private collectionId!: string;

    constructor(
        private store: Store<AppState>,
        private router: Router,
        private snackbarService: SnackbarService,
        private dialogService: DialogService,
        private sessionService: SessionService,
        private sessionThreadsService: SessionThreadsService,
        private collectionsService: CollectionsService,
    ) {}

    openAttributesEditor(): void {
        this.dialogService.open(AttributesEditorComponent, {
            width: '768px',
            panelClass: 'tt9-modal',
        });
    }

    openEditSessionDialog(): void {
        this.dialogService.open(EditSessionDialogNewComponent, {
            title: 'Edit session',
            width: '768px',
            cancelButtonText: 'Cancel',
            panelClass: 'tt9-modal',
        });
    }

    navigateToSessionTeam(): void {
        this.router.navigate([routerLinksMap[ParentType.Sessions], this.sessionId, 'team']);
    }

    toggleDisplayAgenda(displayAgenda: boolean): void {
        if (!displayAgenda) {
            this.updateSessionOptions({ displayAgenda, selfNavigate: false } as Partial<Session>);
        }
        this.updateSessionOptions({ displayAgenda } as Partial<Session>);
    }

    toggleSelfNavigate(selfNavigate: boolean): void {
        this.updateSessionOptions({ selfNavigate } as Partial<Session>);
    }

    async updateSessionThreads(checked: boolean): Promise<void> {
        await this.updateSessionOptions({
            sessionThreadDisabled: !!checked,
            ...(checked ? { sessionThreadIsAnonymous: false } : {}),
        } as Partial<Session>);
    }

    async updateSessionThreadIsAnonymous(checked: boolean): Promise<void> {
        await this.updateSessionOptions({
            sessionThreadIsAnonymous: checked,
        } as Partial<Session>);
        const sessionThreadNotificationType = checked
            ? SessionThreadMessageType.AnonymousON
            : SessionThreadMessageType.AnonymousOFF;

        //TODO update in https://thinktankco.atlassian.net/browse/TT9-6557
        // await this.sessionThreadsService.addNewSessionThreadNotification(
        //     this.projectId,
        //     this.sessionId,
        //     sessionThreadNotificationType,
        // );
    }

    //TODO update in https://thinktankco.atlassian.net/browse/TT9-6451
    async exportReport(): Promise<void> {
        const env = window.location.origin;
        const timezone = new Date().getTimezoneOffset();

        try {
            this.showSessionExportReportSnackBar();
            const report = await this.sessionService.downloadAnExportReportNew(this.sessionId, env, timezone);
            const a = document.createElement('a');

            a.setAttribute('href', report.url);
            a.setAttribute('download', report.name || '');
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            this.showSessionExportReportSuccessSnackBar();
        } catch (e) {
            this.showSessionExportReportErrorSnackBar();
        }
    }

    openSaveAsTemplateDialog(): void {
        const canSaveResponses = this.canSaveResponses();

        this.dialogService.open(ConfirmationDialogComponent, {
            title: 'Save as template',
            cancelBtnText: 'Cancel',
            width: '600px',
            confirmBtnText: 'Save',
            text: canSaveResponses ? saveSessionWithTableAsTemplateConfirmation : saveSessionAsTemplateConfirmation,
            isToggleVisible: canSaveResponses,
            confirm: async (saveResponses: boolean) => this.saveAsTemplate(saveResponses),
        });
    }

    deleteSessionConfirmation(session: Session): void {
        this.dialogService.open(ConfirmationDialogComponent, {
            width: '444px',
            panelClass: 'tt9-modal',
            title: `Delete ${this.sessionName} session`,
            cancelBtnText: 'Cancel',
            confirmBtnText: 'Delete',
            text: deleteSessionConfirmation,
            isWarning: true,
            confirm: () => this.deleteSession(session),
        });
    }

    private buildViewModel(): Observable<SessionsEditorToolsPanelViewModel> {
        return this.store.select(selectAuthenticatedUser).pipe(
            switchMap((user) =>
                combineLatest([
                    this.store.select(selectSessionId),
                    this.store.select(selectSessionData),
                    this.store.select(selectSessionTeamMemberData),

                    //TODO need to update in https://thinktankco.atlassian.net/browse/TT9-6471
                    of([] as Activity[]),
                    // this.store.select(selectSessionActivities),

                    this.isLoading$.asObservable().pipe(distinctUntilChanged()),
                    this.store.select(selectFeatureToggles),
                ]).pipe(
                    switchMap(([sessionId, session, teamMember, activities, isLoading, featureToggles]) => {
                        this.sessionId = sessionId;
                        this.sessionName = session?.name;
                        this.activities = activities;
                        this.user = user;

                        const isSummarizeEnabled = featureToggles[FeatureToggleName.AiSummarizeAvailable];
                        const isMakeNotesEnabled = featureToggles[FeatureToggleName.AiMakeNotesAvailable];
                        const isBookMeetingAvailable = featureToggles[FeatureToggleName.BookSessionMeeting];

                        return this.getCollectionId().pipe(
                            map((collectionId) => {
                                this.collectionId = collectionId || '';

                                return {
                                    session,
                                    teamMember,
                                    user,
                                    isLoading,
                                    collectionId,
                                    isSummarizeEnabled,
                                    isMakeNotesEnabled,
                                    isBookMeetingAvailable,
                                    isAnyActivityAttributes: activities.some((activity) => !!activity.useAttributes),
                                };
                            }),
                        );
                    }),
                ),
            ),
            startWith(initialState),
        );
    }

    async updateSessionOptions(data: Partial<Session>): Promise<void> {
        await this.sessionService.updateSessionDocumentNew(ParentType.Sessions, this.sessionId, data);
    }

    async deleteSession(session: Session): Promise<void> {
        this.sessionService.updateDeletingSessionsIds(session.id);
        this.snackbarService.showSnackBar(
            deletingSessionInProgressSnackbarTitle,
            deleteSessionInfoSnackbar,
            SnackBarTypes.Info,
            false,
        );

        this.collectionId
            ? this.router.navigate([`dashboard/collections/${this.collectionId}`])
            : this.router.navigate(['/dashboard', ParentType.Sessions]);

        try {
            await this.sessionService.deleteSessionNew(ParentType.Sessions, session.id, this.collectionId);

            this.snackbarService.showSnackBar(
                sessionDeletedSnackbarTitle,
                sessionHasBeenDeletedSnackbarText,
                SnackBarTypes.Success,
                true,
            );
        } catch (e) {
            console.error(e);
            this.snackbarService.showSnackBar(
                deletingSessionErrorSnackbarTitle,
                errorMessageSnackbarText,
                SnackBarTypes.Error,
                true,
            );
        }
        this.sessionService.removeSessionIdFromDeletingSessionsIds(this.sessionId);
    }

    private showSaveAsTemplateInfoSnackBar(): void {
        this.snackbarService.showSnackBar(
            templateCreationInProgressSnackbarTitle,
            saveSessionAsTemplateInfoSnackBarText,
            SnackBarTypes.Info,
            false,
        );
    }

    private showSaveAsTemplateSuccessSnackBar(): void {
        this.snackbarService.showSnackBar(
            templateCreatedSnackbarTitle,
            saveSessionAsTemplateSuccessSnackBarText,
            SnackBarTypes.Success,
            true,
        );
    }

    private showSaveAsTemplateErrorSnackBar(): void {
        this.snackbarService.showSnackBar(
            templateCreationErrorTitleSnackbarTitle,
            errorMessageSnackbarText,
            SnackBarTypes.Error,
            true,
        );
    }

    private showSessionExportReportSnackBar(): void {
        this.snackbarService.showSnackBar(
            saveSessionExportReportInfoSnackBarTitle,
            saveSessionExportReportInfoSnackBar,
            SnackBarTypes.Info,
            false,
        );
    }

    private showSessionExportReportSuccessSnackBar(): void {
        this.snackbarService.showSnackBar(
            saveSessionExportReportSuccessSnackBarTitle,
            saveSessionExportReportSuccessSnackBar,
            SnackBarTypes.Success,
            true,
        );
    }

    private showSessionExportReportErrorSnackBar(): void {
        this.snackbarService.showSnackBar(
            saveSessionExportReportErrorSnackBarTitle,
            errorMessageSnackbarText,
            SnackBarTypes.Error,
            true,
        );
    }

    private canSaveResponses(): boolean {
        return !!this.activities.find((activity) => activity?.type === ActivityType.Table);
    }

    //TODO update in https://thinktankco.atlassian.net/browse/TT9-6449
    private async saveAsTemplate(saveResponses: boolean): Promise<void> {
        // try {
        //     this.showSaveAsTemplateInfoSnackBar();
        //     await this.sessionService.saveAsTemplate(
        //         this.projectId,
        //         this.sessionId,
        //         ParentType.Templates,
        //         ParentType.Projects,
        //         saveResponses,
        //     );
        //     this.showSaveAsTemplateSuccessSnackBar();
        // } catch (e) {
        //     console.error('Save as template method has error');
        //     this.showSaveAsTemplateErrorSnackBar();
        // }
    }

    private getCollectionId(): Observable<string | null> {
        const userId = this.user?.id;

        if (!userId || !this.sessionId) {
            console.error('User ID or Session ID is missing.');
            return of(null);
        }

        return this.collectionsService.getCollectionSessionDocument(userId, this.sessionId).pipe(
            switchMap((collectionSession: CollectionSession | null) => {
                if (collectionSession) {
                    return of(collectionSession.collectionId);
                }
                return of(null);
            }),
        );
    }
}
