import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DBPathHelper, PromptLibrary } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable({
    providedIn: 'root',
})
export class PromptLibraryService {
    constructor(private firestoreService: FirestoreService) {}

    getPromptLibrary(promptLibraryId: string): Observable<PromptLibrary> {
        return this.firestoreService.getDocument(DBPathHelper.getPromptLibraryPath(promptLibraryId));
    }

    getAllPromptLibrary(): Observable<PromptLibrary[]> {
        return this.firestoreService.getCollection<PromptLibrary>(DBPathHelper.getPromptLibraryPath());
    }
}
