import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { AppState, isHomePage, selectFeatureToggles } from '@accenture/global-store';
import { BotActions, FeatureToggleName } from '@accenture/shared/data';
import { DialogService } from '@accenture/shared/ui';

import { AddProjectDialogComponent } from '../add-project-dialog/add-project-dialog.component';
import { AddSessionDialogComponent } from '../add-session-dialog/add-session-dialog.component';
import { AiGenerateModalComponent } from '../ai-generate-modal/ai-generate-modal.component';
import { CreateProjectDialogComponent } from '../create-project-dialog/create-project-dialog.component';
import { EditSessionDialogComponent } from '../edit-session-dialog/edit-session-dialog.component';
import { SelectSourceTypeDialogComponent } from './select-source-type-dialog.component';

export interface CreateSourceTypeDialogModel {
    dialogTitle: string;
    isLoading: boolean;
    showAiGenerate: boolean;
}

const defaultViewModel: CreateSourceTypeDialogModel = {
    dialogTitle: '',
    isLoading: true,
    showAiGenerate: true,
};

//TODO DELETE WHEN COLLECTION FEATURE WILL BE FINISHED
@Injectable()
export class SelectSourceTypeDialogFacade {
    vm$ = this.buildViewModel();
    private isHomePage!: boolean;

    constructor(
        private store: Store<AppState>,
        private dialogService: DialogService,
        private dialogRef: MatDialogRef<SelectSourceTypeDialogComponent>,
    ) {}

    createNewDialog(): void {
        if (this.isHomePage) {
            this.openCreateNewProjectDialog();
        } else {
            this.openCreateNewSessionDialog();
        }
    }

    selectTemplateDialog(): void {
        if (this.isHomePage) {
            this.openSelectProjectTemplateDialog();
        } else {
            this.openSelectSessionTemplateDialog();
        }
    }

    openAiGenerateDialog(): void {
        this.dialogRef.close();

        this.dialogService.open(AiGenerateModalComponent, {
            title: this.isHomePage ? 'Generate project using AI' : 'Generate session using AI',
            panelClass: 'tt9-modal',
            width: '768px',
            disableClose: true,
            botAction: this.isHomePage ? BotActions.CreateProject : BotActions.CreateSession,
        });
    }

    private buildViewModel(): Observable<CreateSourceTypeDialogModel> {
        return combineLatest([this.store.select(isHomePage), this.store.select(selectFeatureToggles)]).pipe(
            map(([isHomePage, featureToggles]) => {
                this.isHomePage = isHomePage;
                const showAiGenerate = featureToggles?.[FeatureToggleName.AiGenerateAvailable];
                return {
                    showAiGenerate,
                    isLoading: false,
                    dialogTitle: isHomePage ? 'Create project' : 'Create session',
                } as CreateSourceTypeDialogModel;
            }),
            startWith(defaultViewModel),
        );
    }

    private openCreateNewProjectDialog(): void {
        this.dialogRef.close();

        this.dialogService.open(CreateProjectDialogComponent, {
            createOnlyProject: true,
            title: 'Create project',
            width: '768px',
            panelClass: 'tt9-modal',
        });
    }

    private openCreateNewSessionDialog(): void {
        this.dialogRef.close();

        this.dialogService.open(EditSessionDialogComponent, {
            title: 'Create session',
            width: '768px',
            cancelButtonText: 'Back',
            cancelButtonIcon: 'icon-chevron-left',
            backToSelectSourceTypeDialog: true,
            panelClass: 'tt9-modal',
        });
    }

    private openSelectSessionTemplateDialog(): void {
        this.dialogRef.close();

        this.dialogService.open(AddSessionDialogComponent, {
            width: '768px',
            panelClass: 'tt9-modal',
        });
    }

    private openSelectProjectTemplateDialog(): void {
        this.dialogRef.close();

        this.dialogService.open(AddProjectDialogComponent, {
            width: '768px',
            panelClass: 'tt9-modal',
            cancelButtonName: 'Back',
        });
    }
}
