<div class="d-flex-center">
  <div class="manage-guest-account-snackbar-icon d-flex-center mr-16" [class]="data.iconClass">
    <accenture-icon>{{ data.iconName }}</accenture-icon>
  </div>

  <div class="snackbar-content d-flex-center-between">
    <div>
      <h6 class="tt9-body-medium-2">{{ title }}</h6>
      <p class="tt9-body-2">{{ message }}</p>
    </div>

    <div class="d-flex manage-guest-account-snackbar-buttons">
      <button [class]="data.actionButtonClass" (click)="close()">Continue As A Guest</button>
      <button class="tt9-snack-bar-accent-btn" (click)="goToLogin()">Go To Login</button>
    </div>
  </div>
</div>
