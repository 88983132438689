import { Activity } from '../activities';
import { ActivityItem } from '../activity-items';
import { ParentType } from '../parent-type';
import { Session } from '../session';
import { Template } from '../template';
import { UserCollection } from '../user-collection';
import { DuplicateType } from './duplicate-type';

export interface DuplicateDataIds {
    parentType: ParentType;
    parentId?: string;
    projectId?: string;
    publicAccessId?: string;
    sessionId?: string;
    activityId?: string;
    activityItemId?: string;
    sectionId?: string;
}

export interface DuplicateData {
    from: DuplicateDataIds;
    to: DuplicateDataIds;
    type: DuplicateType;

    userId: string;
    template?: Template;
    session?: Session;
    activity?: Activity;
    activityItem?: ActivityItem;
    creatorNotes?: string;

    visibleSessionsIds?: string[];

    saveResponses?: boolean;
    env?: string;

    userCollection?: UserCollection;
}
