import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'pureSlice',
})
export class PureSlicePipe implements PipeTransform {
    transform(value: any[] | null, sliceLength: number): any[] {
        return (value || []).slice(0, sliceLength);
    }
}
