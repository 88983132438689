import { BaseResponse } from './response';

export class PresentResponse extends BaseResponse {
    commentsCount?: number;
    upvotesCount?: number;
    isMarkOnSlide?: boolean;
    topPosition?: number;
    leftPosition?: number;
    slideIndex?: number;

    constructor(response: Partial<PresentResponse>) {
        super(response);
        this.commentsCount = response.commentsCount ?? 0;
        this.upvotesCount = response.upvotesCount ?? 0;
        this.isMarkOnSlide = response.isMarkOnSlide ?? false;
        this.topPosition = response.topPosition ?? 0;
        this.leftPosition = response.leftPosition ?? 0;
        this.slideIndex = response.slideIndex ?? undefined;
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            ...super.createSerializableObject(),
            commentsCount: this.commentsCount,
            upvotesCount: this.upvotesCount,
            isMarkOnSlide: this.isMarkOnSlide,
            topPosition: this.topPosition,
            leftPosition: this.leftPosition,
        };
    }
}
