import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, combineLatest, of, takeUntil } from 'rxjs';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Collection, NavigationTab, UserCollection } from '@accenture/shared/data';

import { selectAuthenticatedUserId } from '../auth/auth.selectors';
import { navigateHome } from '../router/router.actions';
import * as collectionsActions from './collection.actions';
import { CollectionDataService } from './collection-data.service';

@Injectable()
export class CollectionEffects {
    public getCollectionAndSessions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(collectionsActions.getCollectionAndSessions),
            switchMap(({ collectionId }) => {
                return combineLatest([
                    this.collectionService.getCollectionById(collectionId).pipe(
                        tap((collection) => {
                            if (!collection) {
                                this.store.dispatch(navigateHome({ navigationTab: NavigationTab.Collections }));
                            }
                        }),
                        catchError(() => {
                            this.store.dispatch(navigateHome({ navigationTab: NavigationTab.Collections }));
                            return of({} as Collection);
                        }),
                    ),
                    this.collectionService.getSessionsByCollectionId(collectionId),
                ]).pipe(
                    takeUntil(this.actions$.pipe(ofType(collectionsActions.clearCollectionStoreSubscription))),
                    map(([collection, sessions]) => {
                        return collectionsActions.getCollectionAndSessionsSuccess({
                            collection,
                            sessions,
                        });
                    }),
                    catchError(() => {
                        return of(collectionsActions.getCollectionAndSessionsError());
                    }),
                );
            }),
        ),
    );

    public getCollection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(collectionsActions.getCollection),
            switchMap(({ collectionId }) =>
                this.collectionService.getCollectionById(collectionId).pipe(
                    takeUntil(this.actions$.pipe(ofType(collectionsActions.clearCollectionStoreSubscription))),
                    map((collection: Collection) => {
                        if (!collection) {
                            this.router.navigate(['home']);
                        }
                        return collectionsActions.getCollectionSuccess({ collection });
                    }),
                    catchError(() => {
                        this.router.navigate(['home']);
                        return of(collectionsActions.getCollectionError());
                    }),
                ),
            ),
        ),
    );

    public getUserCollection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(collectionsActions.getUserCollection),
            withLatestFrom(this.store.select(selectAuthenticatedUserId)),
            switchMap(([{ collectionId }, userId]) =>
                this.collectionService.getUserCollection(collectionId, userId).pipe(
                    takeUntil(this.actions$.pipe(ofType(collectionsActions.clearCollectionStoreSubscription))),
                    map((userCollection: UserCollection) => {
                        if (!userCollection) {
                            this.router.navigate(['home']);
                        }
                        return collectionsActions.getUserCollectionSuccess({ userCollection });
                    }),
                    catchError(() => {
                        this.router.navigate(['home']);
                        return of(collectionsActions.getCollectionError());
                    }),
                ),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private collectionService: CollectionDataService,
        private store: Store,
        private router: Router,
    ) {}
}
