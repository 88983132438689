export const analyzeButtonTitle = 'Analyze with ThinkGPT';

export const snackBarDuration = 5000;

export const partColors = [
    '#F54F2D',
    '#01C5C4',
    '#5348CE',
    '#FFBA00',
    '#FE8535',
    '#6FC32B',
    '#27AE60',
    '#40AEF2',
    '#146BD0',
    '#DD75EC',
];

export const unusedColor = '#D9D9DD';

export const logoUrl = '/assets/erp-shared-ui/acn_logo.png';

export enum KeyCode {
    COMMAND = 91,
    ENTER = 13,
}

export enum SessionSelectEnum {
    All = 'All_SESSION',
    Leader = 'SESSION_LEADER',
    Participant = 'SESSION_PARTICIPANT',
}
export const SessionDropdownFilter = [SessionSelectEnum.All, SessionSelectEnum.Leader, SessionSelectEnum.Participant];

export const DisplaySessionSelectRole = {
    [SessionSelectEnum.All]: 'All Sessions',
    [SessionSelectEnum.Leader]: 'Session Leader',
    [SessionSelectEnum.Participant]: 'Session Participant',
};
