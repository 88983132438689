import { Pipe, PipeTransform } from '@angular/core';

import { InAppNotificationTypeFilterType } from '@accenture/shared/data';

export const displayInAppNotificationFilterType: { [key in InAppNotificationTypeFilterType as string]: string } = {
    [InAppNotificationTypeFilterType.Invitations]: 'Invitations',
    [InAppNotificationTypeFilterType.RoleChanging]: 'Role changing',
    [InAppNotificationTypeFilterType.ActivityRelated]: 'Activity related',
    [InAppNotificationTypeFilterType.TemplateRelated]: 'Template related',
    [InAppNotificationTypeFilterType.General]: 'General',
};

@Pipe({
    name: 'displayNotificationFilterType',
    standalone: true,
})
export class DisplayNotificationFilterTypePipe implements PipeTransform {
    transform(filterType: InAppNotificationTypeFilterType): unknown {
        return displayInAppNotificationFilterType[filterType];
    }
}
