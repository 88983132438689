import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    DBPathHelper,
    Dictionary,
    ParentType,
    TeamMember,
    TeamMemberSnackBarsVisibilityOption,
    TeamMemberSnackBarsVisibilityOptions,
} from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable({
    providedIn: 'root',
})
export class TeamMemberSnackBarsVisibilityService {
    constructor(private firestoreService: FirestoreService) {}

    async updateSessionTeamMemberSnackBarVisibility(
        userId: string,
        parentType: ParentType,
        parentId: string,
        sessionId: string,
        snackBarName: TeamMemberSnackBarsVisibilityOptions,
        data: TeamMemberSnackBarsVisibilityOption,
    ): Promise<void> {
        const dataPath = DBPathHelper.getSessionTeamMemberSnackBarsVisibilityPath(
            parentType,
            parentId,
            userId,
            sessionId,
            snackBarName,
        );

        await this.firestoreService.update(dataPath, data);
    }

    async updateSessionTeamMembersSnackBarVisibility(
        teamMembers: TeamMember[],
        parentType: ParentType,
        parentId: string,
        sessionId: string,
        snackBarName: TeamMemberSnackBarsVisibilityOptions,
        data: TeamMemberSnackBarsVisibilityOption,
    ): Promise<void> {
        const batchData = [];
        for (const teamMember of teamMembers) {
            batchData.push({
                path: DBPathHelper.getSessionTeamMemberSnackBarsVisibilityPath(
                    parentType,
                    parentId,
                    teamMember.userId,
                    sessionId,
                    snackBarName,
                ),
                data,
            });
        }
        await this.firestoreService.writeBatch(batchData);
    }

    getSessionTeamMemberSnackBarVisibility(
        userId: string,
        parentType: ParentType,
        parentId: string,
        sessionId: string,
        snackBarName: TeamMemberSnackBarsVisibilityOptions,
    ): Observable<TeamMemberSnackBarsVisibilityOption> {
        if (parentType === ParentType.PublicProjectTemplates) {
            return of(undefined);
        }
        const filterPath = DBPathHelper.getSessionTeamMemberSnackBarsVisibilityPath(
            parentType,
            parentId,
            userId,
            sessionId,
            snackBarName,
        );
        return this.firestoreService.getDocument(filterPath);
    }

    getSessionTeamMemberSnackBarsVisibility(
        userId: string,
        parentType: ParentType,
        parentId: string,
        sessionId: string,
    ): Observable<Dictionary<TeamMemberSnackBarsVisibilityOption>> {
        if (parentType === ParentType.PublicProjectTemplates) {
            return of(undefined);
        }

        const filterPath = DBPathHelper.getSessionTeamMemberSnackBarsVisibilityPath(
            parentType,
            parentId,
            userId,
            sessionId,
        );
        return this.firestoreService.getCollection(filterPath).pipe(
            map((snackBarsOptions: TeamMemberSnackBarsVisibilityOption[]) => {
                return snackBarsOptions.reduce((acc, data) => {
                    acc[data.id as string] = data;
                    return acc;
                }, {});
            }),
        );
    }
}
