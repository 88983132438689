import { Timestamp } from 'firebase/firestore';

import { MsGraphOnlineMeeting } from './msgraph';

export interface Meeting {
    apiResults: {
        onlineMeeting: MsGraphOnlineMeeting;
    };
    context: {
        chat: {
            id: string;
        };
        meeting: {
            id: string;
        };
    };
    host: string;
    id: string;
    ownerId: string;
    participantIds: string[];
    path: string;
    projectId: string;
    sessionId: string;
    type: string;
}

export interface MeetingReference {
    eventId: string;
    meetingId: string;
    startDateTime: Timestamp;
    joinWebUrl: string;
}
