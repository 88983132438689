import { removeEmptyKeys } from '@accenture/shared/util';

import { ProjectRole } from '../access-control';
import { Project } from '../project';
import { User } from '../user';
import { InAppNotificationType } from './in-app-notification-type';
import { BaseInAppNotification } from './in-app-notifications';

export class InAppSessionBulkInviteNotification extends BaseInAppNotification {
    projectId: string;
    projectName: string;
    invitingUserId?: string;
    invitingUserDisplayName?: string;
    role: ProjectRole;

    constructor(notification: any);
    constructor(userId: string, project: Project, role: ProjectRole, user: Partial<User>);
    constructor(notificationOrUserId: any, project?: Project, role?: ProjectRole, user?: Partial<User>) {
        if (typeof notificationOrUserId === 'object') {
            super(notificationOrUserId as BaseInAppNotification);

            this.id = notificationOrUserId.id;
            this.type = InAppNotificationType.SessionBulkInvite;
            this.projectId = notificationOrUserId.projectId;
            this.projectName = notificationOrUserId.projectName;
            this.invitingUserId = notificationOrUserId.invitingUserId;
            this.invitingUserDisplayName = notificationOrUserId.invitingUserDisplayName;
            this.role = notificationOrUserId.role;
        } else {
            super(InAppNotificationType.SessionBulkInvite, notificationOrUserId);

            this.projectId = project.id;
            this.projectName = project.name;
            this.invitingUserId = user.id;
            this.invitingUserDisplayName = user.displayName;
            this.role = role;
        }
    }

    createSerializableObject(): Record<string, unknown> {
        return removeEmptyKeys({
            type: this.type,
            userId: this.userId,
            unread: this.unread,
            projectId: this.projectId,
            projectName: this.projectName,
            invitingUserId: this.invitingUserId,
            invitingUserDisplayName: this.invitingUserDisplayName,
            role: this.role,
            created: this.created,
            updated: this.updated,
        });
    }
}
