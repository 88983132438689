import { Pipe, PipeTransform } from '@angular/core';

import { Dictionary, TableStatus } from '@accenture/shared/data';

const stepNamesMap: { [key in TableStatus as string]: string } = {
    [TableStatus.Setup]: 'setup',
    [TableStatus.Validate]: 'validation',
    [TableStatus.Summary]: 'summary',
};

@Pipe({
    name: 'displayNotificationStep',
    standalone: true,
})
export class DisplayNotificationStepPipe implements PipeTransform {
    transform(step: TableStatus): unknown {
        return stepNamesMap[step];
    }
}
