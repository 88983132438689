export enum LoadedDescription {
    DataLoading = 'Please wait...\nwe are preparing your data',
    Saving = 'saving...',
    DataMoving = 'data is moving...',
    ProjectCreating = 'Project will be created soon',
    ProjectTemplateCreating = 'Project Template will be created soon',
    ProjectEditing = 'Changes will be saved soon',
    ProjectsPreparing = 'Please wait...\nwe are preparing your projects',
    ProjectDeleting = 'Project will be deleted soon',
    ProjectTemplateDeleting = 'Project Template will be deleted soon',
    AddingSessionsToProject = 'Sessions will be added to the project soon',
    AddingActivitiesToProject = 'Activities will be added to the project soon',
    PropagatingValues = 'Data will be propagated soon',
    SessionDeleting = 'Session will be deleted soon',
    SessionCreating = 'Session will be created soon',
    ActivityDuplicating = 'Activity will be duplicated soon',
    ActivityDeleting = 'Activity will be deleted soon',
    ActivityItemDeleting = 'Activity item will be deleted soon',
    ActivityTemplateDeleting = 'Activity Template will be deleted soon',
    SessionTemplateDeleting = 'Session Template will be deleted soon',
    UploadingPresentation = 'Presentation will be uploaded soon',
    DownloadingAnExportReport = 'Export Report will be downloaded soon',
    UpdatingAttributes = 'Attributes Use will be changed soon',
    ChangesAreBeingApplied = 'Changes are being applied. Please wait...',
    ProomptRunning = 'Thinking...',
    RunningTask = 'On it...',
    ClearingData = 'Clearing...',
    Generating = 'Generating...',
    Completing = 'Completing...',
    Loading = 'Loading. Please wait...',
    CollectionCreating = 'Collection will be created soon',
    CollectionUpdating = 'Collection will be updated soon',
}
