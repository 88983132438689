<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading">
    <accenture-base-dialog [title]="data.title">
      <div class="dialog-member-invitations">
        <p class="tt9-body-2 tt9-color-neutral-500 mb-24">
          Invite collaborators by sending emails. They should log in via Accenture SSO or by specifying their email and
          password.
        </p>

        <div class="invitations-form" [formGroup]="inviteForm">
          <accenture-email-invitation-form
            class="mb-24"
            [emailListName]="'Collaborator'"
            [blackListEmails]="vm.teamMemberEmails"
            [formControlName]="collaborator"
            [restrictedEmailsInvitationText]="'Template'"
          ></accenture-email-invitation-form>

          <mat-form-field
            appearance="outline"
            class="textarea tt9-form-field mat-form-field-appearance-outline invitations-form-textarea"
          >
            <textarea
              #textInvite
              matInput
              [placeholder]="inviteText"
              [maxlength]="characterLimitLarge"
              formControlName="message"
              (input)="setMessageValue()"
            ></textarea>
            <mat-hint
              class="mat-hint-limit"
              [class]="determineHintClass(textInvite.value.length, characterLimitLarge)"
              >{{ characterLimitText(textInvite.value.length, characterLimitLarge) }}</mat-hint
            >
          </mat-form-field>
        </div>
      </div>

      <div class="accenture-dialog-actions">
        <button class="tt9-btn" [disabled]="!collaboratorControl.value.length" (click)="invite()">Invite</button>
      </div>
    </accenture-base-dialog>
  </ng-container>
</ng-container>
