import { Pipe, PipeTransform } from '@angular/core';

import { ActivityConfigurationsMap, ActivityType } from '@accenture/shared/data';

@Pipe({
    name: 'displayActivityType',
})
export class DisplayActivityTypePipe implements PipeTransform {
    transform(type: ActivityType, activityConfigurationsMap: ActivityConfigurationsMap): string {
        return (activityConfigurationsMap || {})[type]?.shortName || '';
    }
}
