import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, map, Observable, startWith, switchMap } from 'rxjs';

import { FiltersService, ProjectService, UserAccessService } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUserId } from '@accenture/global-store';
import {
    addingTemplateErrorSnackbarTitle,
    addingTemplateInProgressSnackbarTitle,
    DefaultFilterObject,
    errorMessageSnackbarText,
    ParentType,
    ProjectRole,
    routerLinksMap,
    templateAddedSnackbarTitle,
    templateHasBeenAddedToTheProjectSnackbarText,
    templateIsBeingAddedToTheProjectSnackbarText,
    UserAccess,
} from '@accenture/shared/data';
import { DialogService, SnackbarService, SnackBarTypes } from '@accenture/shared/ui';
import { sortByDateDesc } from '@accenture/shared/util';

import { UseTemplateStore } from '../component-stores/use-template.store';
import { CreateProjectDialogComponent } from '../create-project-dialog/create-project-dialog.component';
import {
    addSessionToActivityTitle,
    addSessionToProjectTitle,
    useTemplateStepOne,
} from '../use-template-dialog/constants';
import { UseTemplateDialogComponent } from '../use-template-dialog/use-template-dialog.component';
import { AddSessionToProjectDialogComponent } from './add-session-to-project-dialog.component';

export interface AddSessionToProjectDialogViewModel {
    projects: UserAccess[];
    searchValue: string;
    selectedProject: string;
    saveTemplateToProjectButtonName: string;
    subTitle: string;
    isLoading: boolean;
}

const defaultViewModel: AddSessionToProjectDialogViewModel = {
    projects: [],
    searchValue: '',
    selectedProject: '',
    saveTemplateToProjectButtonName: '',
    subTitle: '',
    isLoading: false,
};

@Injectable()
export class AddSessionToProjectDialogFacade {
    private searchValue$ = new BehaviorSubject<string>('');
    private selectedProject$ = new BehaviorSubject<string>('');
    private isLoading$ = new BehaviorSubject<boolean>(false);

    vm$ = this.buildViewModel();

    private userId!: string;
    private usePublicAccessId: string;
    private useTemplateId: string;
    private useTemplateType: ParentType;

    constructor(
        private store: Store<AppState>,
        private dialogRef: MatDialogRef<AddSessionToProjectDialogComponent>,
        private userAccessService: UserAccessService,
        private filtersService: FiltersService,
        private dialogService: DialogService,
        private useTemplateStore: UseTemplateStore,
        private projectService: ProjectService,
        private router: Router,
        private snackbarService: SnackbarService,
    ) {}

    closeDialog(): void {
        this.dialogRef.close();
    }

    updateSearchValue(value: string): void {
        this.searchValue$.next(value);
    }

    updateSelectedTemplates(value: string): void {
        this.selectedProject$.next(value);
    }

    openUseTemplateModal(): void {
        this.closeDialog();
        this.dialogService.open(UseTemplateDialogComponent, {
            width: 'auto',
            panelClass: 'tt9-modal',
        });
    }

    openCreateProjectDialog(): void {
        this.dialogService.open(CreateProjectDialogComponent, {
            title: 'Create project to use session template',
            width: 'auto',
            panelClass: 'tt9-modal',
        });
    }

    async saveTemplateToProject(selectedProject: string): Promise<void> {
        if (![ParentType.ActivityTemplates, ParentType.PublicActivityTemplates].includes(this.useTemplateType)) {
            this.snackbarService.showSnackBar(
                addingTemplateInProgressSnackbarTitle,
                templateIsBeingAddedToTheProjectSnackbarText,
                SnackBarTypes.Info,
                false,
            );
            this.closeDialog();
            try {
                await this.projectService.addSessionTemplatesToProject(
                    selectedProject,
                    this.useTemplateId,
                    this.userId,
                    ParentType.Projects,
                    this.useTemplateType,
                    this.usePublicAccessId,
                    true,
                );

                this.router.navigate([routerLinksMap[ParentType.Projects], selectedProject]);

                this.snackbarService.showSnackBar(
                    templateAddedSnackbarTitle,
                    templateHasBeenAddedToTheProjectSnackbarText,
                    SnackBarTypes.Success,
                    true,
                );
            } catch (e) {
                console.error(e);
                this.snackbarService.showSnackBar(
                    addingTemplateErrorSnackbarTitle,
                    errorMessageSnackbarText,
                    SnackBarTypes.Error,
                    true,
                );
            }
        } else {
            this.useTemplateStore.setSelectedProjectId(selectedProject);
            this.openUseTemplateModal();
        }
        this.closeDialog();
    }

    private buildViewModel(): Observable<AddSessionToProjectDialogViewModel> {
        return this.store.select(selectAuthenticatedUserId).pipe(
            switchMap(userId => {
                const projects$ = this.userAccessService.getProjectAssignmentsByUserId(userId);
                return combineLatest([
                    projects$,
                    this.searchValue$,
                    this.selectedProject$,
                    this.useTemplateStore.usePublicAccessId$,
                    this.useTemplateStore.useTemplateId$,
                    this.useTemplateStore.useTemplateType$,
                    this.isLoading$,
                ]).pipe(
                    map(
                        ([
                            projects,
                            searchValue,
                            selectedProject,
                            usePublicAccessId,
                            useTemplateId,
                            useTemplateType,
                            isLoading,
                        ]) => {
                            this.setActualIdsData(usePublicAccessId, useTemplateId, useTemplateType, userId);

                            const visibleProjects = this.getVisibleProjects(projects, {}, searchValue).filter(
                                project => project.role === ProjectRole.Admin,
                            );
                            return {
                                searchValue,
                                selectedProject,
                                isLoading,
                                saveTemplateToProjectButtonName:
                                    useTemplateType === ParentType.ActivityTemplates ? 'Next' : 'Select',
                                projects: visibleProjects.sort(sortByDateDesc('created')),
                                subTitle: useTemplateType === ParentType.ActivityTemplates ? useTemplateStepOne : '',
                                title:
                                    useTemplateType === ParentType.ActivityTemplates
                                        ? addSessionToActivityTitle
                                        : addSessionToProjectTitle,
                            };
                        },
                    ),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    private setActualIdsData(
        usePublicAccessId: string,
        useTemplateId: string,
        useTemplateType: ParentType,
        userId: string,
    ): void {
        this.usePublicAccessId = usePublicAccessId;
        this.useTemplateId = useTemplateId;
        this.useTemplateType = useTemplateType;
        this.userId = userId;
    }

    private getVisibleProjects(
        projects: UserAccess[],
        currentUserFilters: DefaultFilterObject,
        searchValue: string,
    ): UserAccess[] {
        const visibleProjectsAfterSearch = projects.length
            ? this.filtersService.filterAndSort(projects, currentUserFilters, searchValue)
            : [];

        return visibleProjectsAfterSearch.filter(({ hidden }) => !hidden);
    }
}
