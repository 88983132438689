import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { VoteFormGroup } from './../extended-forms/vote-form-group';

export const voteRequired
    = (parameterItemId: string, attributeId: string): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
        const voteFormGroup = control?.parent?.parent.parent as VoteFormGroup;
        const questionItemsGroup = control?.parent?.parent as FormGroup;

        const errors = Validators.required(control);
        const hasError = !!errors;

        voteFormGroup.setVoteFormErrorsMap(hasError, parameterItemId, attributeId);

        Object.values(questionItemsGroup.controls).forEach(voteItemControl => {
            const attributeControl = voteItemControl.get(attributeId);

            if (attributeControl?.invalid) {
                voteFormGroup.setVoteFormErrorsMap(true, parameterItemId, attributeId);
            }
        });

        return hasError ? errors : null;
    };
